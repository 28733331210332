import { config } from "src/config";
import { isWhiteLabeledDomain } from "./whitelabel";

export const isFree = () => {
  const free =
    config.freeValidDate &&
    new Date() < config.freeValidDate &&
    !isWhiteLabeledDomain();

  return free;
};
