import { useLazyQuery } from "@apollo/client";
import {
  Text,
  Divider,
  Heading,
  Box,
  Grid,
  GridItem,
  HStack,
  Center,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  toast,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "src/api";
import {
  GetNumTxnTypesResponse,
  Transaction as TransactionType,
} from "src/api/generated/types";
import WhiteBox from "src/components/styled/WhiteBox";
import { colors, other } from "src/theme";
import { isMobile } from "react-device-detect";
import { Transaction } from "../Transactions/Transaction";
import { Maybe } from "src/core";
import { BaseSimpleTransactionFields } from "src/api/fragments";
import { useClientById } from "src/hooks";

const MAX_PAGE_WIDTH = "58.8rem";

export const Metrics = () => {
  const { clientId } = useParams();
  const [_getNumTxnTypes, { data: numTxnTypesData, error }] = useLazyQuery<{
    getNumTxnTypes: GetNumTxnTypesResponse & {
      biggestGainsLosses: Array<BaseSimpleTransactionFields>;
    };
  }>(api.transactions.getNumTxnTypes, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    _getNumTxnTypes({
      variables: {
        clientId,
        gainsLossesPage: 0,
      },
    });
  }, []);

  const gasMetricsState = useMemo(() => {
    const data = numTxnTypesData?.getNumTxnTypes.gasMetrics || [];
    const years = data.map((d) => d.year);
    const fiatAmounts = data.map((d) => Number(d.fiatAmount) / 100);

    return {
      series: [
        {
          name: "Gas Paid",
          data: fiatAmounts,
        },
      ],
      options: {
        yaxis: {
          labels: {
            formatter: _formatGasMetrics,
          },
        },
        dataLabels: {
          formatter: _formatGasMetrics,
        },
        xaxis: {
          categories: years,
        },
      },
    };
  }, [numTxnTypesData]);

  if (
    !numTxnTypesData ||
    numTxnTypesData.getNumTxnTypes.numTxnTypes.length === 0
  ) {
    return null;
  }

  return (
    <Box marginTop="2rem">
      <Heading margin="0" size="md">
        Gas Paid
      </Heading>
      <WhiteBox border={other.boxBorder} maxW="58.8rem">
        {/* <Heading size="sm" margin="0" padding="0" w="100%" textAlign={"center"}>
          Gas Paid
        </Heading> */}
        <ReactApexChart
          options={gasMetricsState.options}
          series={gasMetricsState.series}
          tooltip={{
            formatter: _formatGasMetrics,
          }}
          type="bar"
          height="350"
        />
      </WhiteBox>
      <BiggestGainsLosses
        transactions={numTxnTypesData?.getNumTxnTypes.biggestGainsLosses}
      />
      <Heading margin="2rem 0" size="md">
        Transaction Metrics (All-time)
      </Heading>
      <WhiteBox border={other.boxBorder} maxW={MAX_PAGE_WIDTH} margin="0">
        <Box
          display="flex"
          flexDirection={"row"}
          flexWrap="wrap"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {numTxnTypesData.getNumTxnTypes.numTxnTypes.map((txnType) => (
            <HStack
              width={{ base: "100%", lg: "45%", xl: "30%" }}
              h={{ base: "2.5rem", xl: "3rem" }}
              key={txnType.reason}
              marginTop="1rem"
              marginRight="1rem"
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
            >
              <Text
                overflow="clip"
                fontWeight="500"
                float="left"
                w="2.5rem"
                textAlign={"left"}
                flexShrink={0}
              >
                {txnType.count}
              </Text>
              <Text
                isTruncated
                fontWeight="400"
                float="left"
                flex={1}
                whiteSpace="nowrap"
              >
                {txnType.reason}
              </Text>
            </HStack>
          ))}
        </Box>
      </WhiteBox>
    </Box>
  );
};

const _formatGasMetrics = (data: any) => {
  return `$${data.toLocaleString()}`;
};

const BiggestGainsLosses = ({
  transactions,
}: {
  transactions: Maybe<BaseSimpleTransactionFields[]>;
}) => {
  const { clientId } = useParams();
  const navigate = useNavigate();

  const { client } = useClientById(clientId);

  if (!transactions || transactions.length === 0) return null;

  return (
    <>
      <Heading margin="2rem 0" size="md">
        Biggest Gains / Losses
      </Heading>
      <WhiteBox
        padding="0"
        marginTop="0"
        maxW={MAX_PAGE_WIDTH}
        id="biggiesmalls"
      >
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th cursor="pointer">
                  <Center>
                    <Tooltip
                      zIndex={1401}
                      trigger={isMobile ? "hover" : "hover"} // we already have a click handler
                      bg={colors.black}
                      placement="top"
                      borderRadius="0.25rem"
                      padding={{ base: "0.5rem 1rem" }}
                    >
                      Status
                    </Tooltip>
                  </Center>
                </Th>
                <Th>
                  <Center>Gains/Losses</Center>
                </Th>
                <Th isNumeric>&nbsp;&nbsp;Date &#38; Time </Th>
              </Tr>
            </Thead>
            <Tbody>
              {transactions.map((t) => (
                <Transaction
                  timezone={client?.timezone || "UTC"}
                  transaction={t}
                  key={t.id}
                  limitedView
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </WhiteBox>
    </>
  );
};
