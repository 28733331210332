import {
  Box,
  Container,
  FormLabel,
  HStack,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import {
  ImportTypeEnum,
  Mutation,
  MutationCreateAccountArgs,
} from "src/api/generated/types";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useClientById, useMyToast } from "src/hooks";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { getDefaultValues, FormValues, schema } from "./form";
import { Button, useOnInvalidForm } from "src/components/styled";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { colors } from "src/theme";
import { Required } from "src/components/styled/Form/Required";
import { Maybe } from "src/core";
import {
  IntegrationOption,
  IntegrationProviderInfo,
} from "../AccountModal/types";
import { BaseAccountFields } from "src/api/fragments";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  mode: "existing_account" | "new_account";
  provider: string;
  accountId: Maybe<string>;
  option: Maybe<IntegrationOption>;
  integration: IntegrationProviderInfo;
  onSuccess: () => void;
  description?: JSX.Element;
};

function _AccountFileUploadModal({
  handleHide,
  provider,
  show: isVisible,
  option,
  integration,
  onSuccess,
  description,
}: Props) {
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();
  const onInvalidForm = useOnInvalidForm();
  const [createAccount, { loading }] = useMutation<
    { createAccount: BaseAccountFields },
    MutationCreateAccountArgs
  >(api.accounts.create);

  // Form hooks / fxns
  const values = useMemo(() => getDefaultValues(), []);
  AccountFileUploadModal;
  const formProps = useForm<FormValues>({
    defaultValues: values,
    resolver: yupResolver(schema),
  });

  const theme = useTheme();

  async function _onSubmit(values: FormValues) {
    try {
      if (!option) {
        return toast.show({
          message:
            "Sorry, cannot upload CSV because an option is not present. Message support and we can help you!",
          status: "error",
        });
      }

      const createAccountArgs: MutationCreateAccountArgs = {
        code: null,
        provider: option.provider,
        type: integration.type,
        redirectUrl: null,
        address: null,
        label: null,
        importType: ImportTypeEnum.FileUpload,
        fileObjectKey: values.fileObjectKey,
        clientId: clientId || null,
      };

      await createAccount({
        variables: createAccountArgs,
        refetchQueries: [
          api.accounts.me,
          api.clients.accounts,
          api.portfolio.get,
          api.clients.retrieve,
        ],
      });

      toast.show({
        message: `Successfully started importing transactions... May take a few minutes ⏰`,
        status: "success",
      });

      handleHide();

      if (onSuccess) onSuccess();
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  }

  return (
    <Modal
      title={`${integration?.name} File Upload`}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          marginTop="2rem"
          type="submit"
          variant="primary"
          onClick={formProps.handleSubmit(_onSubmit, onInvalidForm)}
        >
          Upload Transactions for {integration?.name}
        </Button>
      }
    >
      <FormProvider {...formProps}>
        <form>
          {description || (
            <Text color={theme.text} marginBottom="2rem">
              Upload the CSV file you got from {integration?.name} here and
              we'll import all your transactions!
            </Text>
          )}

          <Container padding="0" marginTop="1rem">
            {/* <Select
              control={formProps.control}
              label="Wallet/Exchange"
              selectProps={{
                isLoading: isLoadingSearchLedgerAccounts,
              }}
              options={options}
              name="account"
              isRequired
            /> */}

            <CSVUpload />
          </Container>
        </form>
      </FormProvider>
    </Modal>
  );
}

const CSVUpload = () => {
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();
  const formProps = useFormContext();
  const [uploadFile, { loading }] = useMutation<Pick<Mutation, "uploadFile">>(
    api.uploadFile
  );

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file || !clientId) return;

    try {
      // Do something with the files
      const result = await uploadFile({
        variables: { file, clientId },
      });

      const fileObjectKey = result.data?.uploadFile.objectFileKey || "";
      const fileName = result.data?.uploadFile.fileName || "";

      formProps.setValue("fileObjectKey", fileObjectKey);
      formProps.setValue("fileName", fileName);
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "application/csv": [".csv"],
    },
    onDropRejected: (errors) => {
      // console.log(err);
      const message = errors
        .map((e) => e.errors.map((e) => e.message))
        .join(" ");
      toast.show({
        message,
        status: "error",
      });
    },
  });

  const [fileObjectKey, fileName] = formProps.watch([
    "fileObjectKey",
    "fileName",
  ]);

  const hasFile = loading || (!!fileObjectKey && !!fileName);
  const theme = useTheme();

  return (
    <>
      <FormLabel color={theme.text} fontSize={14} marginBottom="6px">
        CSV File Upload <Required />
      </FormLabel>

      {hasFile ? (
        <HStack
          style={{
            padding: "1rem 1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 5,
            border: `1px solid ` + theme.border,
          }}
          bg={theme.secondaryBackground}
        >
          <i
            style={{
              fontSize: 28,
              color: colors.primary,
              marginRight: 5,
            }}
            className="fa-sharp fa-file-csv"
          />
          <Text color={theme.header} fontSize="sm">
            {loading ? (
              <>
                Uploading... <Spinner size="xs" />{" "}
              </>
            ) : (
              fileName || "Your File"
            )}
          </Text>
        </HStack>
      ) : (
        <div
          style={{
            padding: "2rem 1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 5,
            border: `1px dashed ` + colors.gray80,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Text color={theme.header} fontSize="md" fontWeight="semibold">
              Drop the files here ...
            </Text>
          ) : (
            <Box textAlign="center">
              <i
                style={{ color: colors.primary, fontSize: 40 }}
                className="fa-sharp fa-file-csv"
              />
              <Text
                color={theme.header}
                marginTop="1rem"
                fontWeight="bold"
                fontSize="md"
              >
                Drop{" "}
                {/* or <span style={{ color: colors.primary }}>browse</span>{" "} */}
                your file.
              </Text>
            </Box>
          )}
        </div>
      )}
    </>
  );
};

export const AccountFileUploadModal = connectModal({
  name: "AccountFileUploadModal",
})(_AccountFileUploadModal);
