import { useLazyQuery } from "@apollo/client";
import { Box, HStack, Image, Text, Tooltip, VStack } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { throttle, truncate } from "lodash";
import { isNil, uniq } from "lodash/fp";
import moment from "moment";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "src/api";
import { BaseSimpleTransactionFields } from "src/api/fragments";
import {
  CurrencyCodeEnum,
  LedgerTransactionReviewStatusEnum,
} from "src/api/generated/types";
import { ReviewStatusTag } from "src/components/Labels/ReviewStatusTag";
import { PROVIDER_TO_LOGO_URL } from "src/components/modals/AccountModal/constants";
import StatusTag from "src/components/styled/StatusTag";
import { hasValue } from "src/core";
import { useMe, useTransactionSearch } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { isReviewed } from "src/modules/ledger/transactions";
import { colors } from "src/theme";
import { toLuxonUTC, toTitleCase } from "src/utils";
import { formatNum, getCurrencySymbol } from "src/utils/helpers";

type TransactionProps = {
  transaction: BaseSimpleTransactionFields;
  limitedView?: boolean; // show a few columns
  onClick?: () => void;
  timezone: string;
  isLast: boolean;
};

const MAX_WIDTH = 35;

export function Transaction({
  transaction,
  limitedView,
  onClick,
  // UTC
  timezone = "UTC",
  isLast,
}: TransactionProps) {
  const [search, setSearchParams] = useSearchParams();
  const searchTransactionId = search.get("transactionId");
  const highlightTransactionId = search.get("highlightTransactionId");
  const theme = useTheme();

  const selected = searchTransactionId === transaction.id;
  const [_, { client: getTxnClient }] = useLazyQuery(api.transactions.retrieve);

  const { me } = useMe("cache-first");
  const isSuperUser = me?.isSuperuser || false;
  const { filters } = useTransactionSearch();
  const hasIncomeSort = filters.sortBy === "incomeSum";

  const _onClickTxn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    search.delete("transactionId"); // delete the current txn id
    setSearchParams(search);
    search.append("transactionId", transaction.id);
    setSearchParams(search);
  };

  const title = transaction.title || "";

  const _preloadTxn = useCallback(() => {
    console.log("[pre-loading txn]");
    getTxnClient
      .query({
        fetchPolicy: "network-only",
        query: api.transactions.retrieve,
        variables: { transactionId: transaction.id },
      })
      .then(console.log);
  }, [getTxnClient]);

  const _preloadTxnDebounced = useCallback(throttle(_preloadTxn, 1000), [
    _preloadTxn,
  ]);

  const label = getTxnLabelParts(transaction.title || "");
  const date = toLuxonUTC(new Date(transaction.createdAt)).setZone(timezone);
  const isChecked =
    transaction.reviewStatus === LedgerTransactionReviewStatusEnum.Reviewed;
  const isLarge = useIsLargeScreen();
  const showMissingBasis = transaction.isMissingBasis;
  const isHighGainLoss =
    !isNil(transaction.capGainsSum) &&
    new BigNumber(transaction.capGainsSum || 0).isGreaterThan(10000);

  return (
    <Box
      cursor="pointer"
      _hover={{
        backgroundColor: theme.secondaryBackground,
      }}
      onClick={onClick || _onClickTxn}
      padding="0.8rem 0.75rem"
      borderBottom={isLast ? "none" : `1px solid ${theme.border}`}
      onMouseOver={_preloadTxnDebounced}
    >
      <HStack>
        <HStack flex={4}>
          <VStack flex={1} alignItems="flex-start">
            <HStack alignItems="center" style={{ width: "100%" }}>
              <Image
                src={PROVIDER_TO_LOGO_URL[transaction.provider || ""] || ""}
                marginRight="0rem"
                width="1rem"
                height="1rem"
                display="inline"
                style={{ borderRadius: 3, position: "relative" }}
              />

              <Tooltip openDelay={1000} placement="bottom-start" label={title}>
                <VStack alignItems="flex-start">
                  <Text
                    marginTop="0 !important"
                    // isTruncated
                    textAlign="left"
                    fontSize={14}
                    maxW={300}
                    fontWeight="500"
                    color={theme.header}
                  >
                    {transaction.title}
                  </Text>
                  <Text
                    isTruncated
                    textAlign="left"
                    fontSize={13}
                    marginTop="0 !important"
                    color={theme.text}
                    maxW={300}
                  >
                    {transaction.description || "-"}
                  </Text>
                </VStack>
              </Tooltip>

              {showMissingBasis ? (
                <StatusTag
                  boxProps={{
                    style: {
                      marginLeft: 5,
                      display: "block",
                      padding: "3px 10px",
                    },
                  }}
                  infoMessage={`We are missing the purchase price for an asset in this transaction.${
                    isHighGainLoss
                      ? ""
                      : " The gain/loss is low for this transaction."
                  }`}
                  type={isHighGainLoss ? "error" : "none"}
                  label=""
                  iconStyle={{ fontSize: 14 }}
                  iconName="fa-sharp fa-circle-exclamation"
                />
              ) : null}
            </HStack>
          </VStack>
        </HStack>

        {isLarge && (
          <Box flex={1} alignItems="center" display="flex" textAlign="center">
            <ReviewStatusTag
              isReviewed={isReviewed(transaction)}
              capPriority={transaction.capPriority}
              type={transaction.processingType || null}
              transactionId={transaction.id}
              currentLabelUsed={transaction.labelUsed}
              needsRecalculate={
                transaction.needsReview?.needsRecalculate || false
              }
            />
          </Box>
        )}

        <Box
          flex={2}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TxnFinancialInfo transaction={transaction} />
        </Box>

        <Box
          flex={2}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Tooltip
            openDelay={500}
            label={moment(date.toJSDate()).format("MMM Do YYYY h:mma")}
          >
            <Text
              isTruncated
              textAlign="left"
              fontSize="sm"
              marginTop="0 !important"
              maxW="100%"
              color={theme.header}
            >
              {moment(date.toJSDate()).format("h:mma MMM Do")}
            </Text>
          </Tooltip>

          <Text
            isTruncated
            textAlign="left"
            fontSize="xs"
            marginTop="0 !important"
            maxW="100%"
            color={theme.text}
          >
            {moment(date.toJSDate()).fromNow()}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
}

const TxnFinancialInfo = ({
  transaction,
}: {
  transaction: BaseSimpleTransactionFields;
}): JSX.Element | null => {
  const capGainsSum =
    (transaction.capGainsSumSigned || transaction.capGainsSum) ?? null;
  const incomeSum = transaction.incomeSum ?? null;
  const capGainsDollars = new BigNumber(capGainsSum || 0).div(100);
  const incomeDollars = new BigNumber(incomeSum || 0).div(100);
  const currency = getCurrencySymbol(
    transaction.fiatCurrency || CurrencyCodeEnum.Usd
  );

  // cap gains sum if we have one
  if (capGainsDollars.isZero() && incomeDollars.isZero()) {
    const dollars = Number(capGainsSum) / 100;
    const isNeg = dollars < 0;
    const color = isNeg ? colors.negative : colors.positive;

    if (new BigNumber(dollars).dp(2).isZero()) {
      return (
        <HStack>
          <Tooltip label="No gain/loss.">
            <span style={{ visibility: "hidden" }}>Nothing</span>
          </Tooltip>
        </HStack>
      );
    }
  }

  if (incomeDollars.gt(0)) {
    const cashAmount = formatNum(incomeDollars.abs().toNumber(), true);
    const color = colors.positive;

    return (
      <Tooltip placement="bottom-end" label="Income Received.">
        <HStack>
          <span style={{ color: color, /*colors.black*/ fontWeight: "500" }}>
            {currency}
            {cashAmount}
          </span>

          <i
            className={"fa-sharp fa-sack-dollar"}
            // className="fa-sharp fa-circle-plus"
            style={{
              fontSize: 13,
              color,
            }}
          />
        </HStack>
      </Tooltip>
    );
  }

  if (!capGainsDollars.eq(0)) {
    const isNeg = capGainsDollars.lt(0);
    const cashAmount = formatNum(capGainsDollars.abs().toNumber(), true);
    const color = isNeg ? colors.negative : colors.positive;

    return (
      <Tooltip
        placement="bottom-end"
        label={isNeg ? "Capital loss." : "Capital gain"}
      >
        <HStack>
          <span style={{ color: color, /*colors.black*/ fontWeight: "500" }}>
            {isNeg ? "-" : "+"}
            {currency}
            {cashAmount}
          </span>

          <i
            className={
              isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"
            }
            style={{
              fontSize: 15,
              color,
            }}
          />
        </HStack>
      </Tooltip>
    );
  }

  return null;
};

const getTxnLabelParts = (input: string) => {
  if (!input) return null;

  const [firstPart, secondPart] = input.split("(");

  return {
    firstPart: firstPart.trim(),
    // replace all parentheses with commas
    tokenNames: secondPart?.replace(/\(|\)/g, "").trim() || "",
  };
};

// ex. ETH -> coinbase etc... with the wallet label. will leave that to a future version tho
const _getAccountLabel = (txn: BaseSimpleTransactionFields) => {
  const length = 10;

  const accounts = uniq([
    ...txn.transfers
      .map((t) => [
        t.fromAccount
          ? toTitleCase(truncate(t.fromAccount.description, { length: length }))
          : null,
        t.toAccount
          ? toTitleCase(truncate(t.toAccount.description, { length: length }))
          : null,
      ])
      .flat()
      .filter(hasValue),
    ...txn.fees
      .map((t) =>
        t.payerAccount
          ? toTitleCase(
              truncate(t.payerAccount.description, { length: length })
            )
          : null
      )
      .filter(hasValue),
  ]);

  if (accounts.length >= 1) {
    return accounts.join(", ");
  }

  return "--";
};
