import React, { useMemo, useState } from "react";
import { Button, Text } from "@chakra-ui/react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps, show } from "redux-modal";
import { Maybe } from "src/core";
import { useClientById } from "src/hooks";
import { DatePicker, Input, Select } from "src/components/styled";

type Props = InjectedProps & {
  clientId: Maybe<string>;
};

function _ComingSoonModal({ handleHide, show: isVisible }: Props) {
  return (
    <>
      {/* other referenced modals */}
      <Modal
        title="Coming Soon!"
        isVisible={isVisible}
        handleHide={handleHide}
        Footer={
          <Button width="100%" variant="primary" onClick={handleHide}>
            Dismiss
          </Button>
        }
      >
        <Text>
          Please let us know how much you want this feature, and we'll
          prioritize building it based on your input.
        </Text>
      </Modal>
    </>
  );
}

export const ComingSoonModal = connectModal({
  name: "ComingSoonModal",
})(_ComingSoonModal);
