import {
  Button,
  Text,
  UnorderedList,
  ListItem,
  ButtonGroup,
  VStack,
} from "@chakra-ui/react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { compose } from "lodash/fp";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { BaseFullTransactionFields } from "src/api/fragments";
import { Maybe } from "src/core";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  regularSelect: () => void;
  handleHideLabelsModal: () => void;
  transaction?: Maybe<BaseFullTransactionFields>;
};

function _WalletTransferWarningModal({
  handleHide,
  show: isVisible,
  regularSelect,
  handleHideLabelsModal,
  transaction,
}: Props) {
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const theme = useTheme();

  const _onClick = () => {
    _showModal("AccountModal", {
      location: "wallet_transfer_warning",
    });
    handleHideLabelsModal();
    handleHide();
  };

  return (
    <Modal
      marginTop="5rem"
      title={`Connect your Wallet/Exchange`}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <VStack w="100%">
          <Button
            variant="primary"
            style={{
              width: "100%",
            }}
            onClick={_onClick}
          >
            Connect Wallet/Exchange
          </Button>
          <Button
            variant="secondary"
            style={{
              width: "100%",
            }}
            onClick={() => {
              regularSelect();
              handleHide();
            }}
          >
            Label as Wallet Transfer
          </Button>
        </VStack>
      }
    >
      <Text color={theme.text}>
        Please connect your other wallet or exchange that you interacted with in
        this transaction. We'll automatically label all transfers for you and
        carry over your original purchase price, saving you time and effort.
      </Text>
    </Modal>
  );
}

export const WalletTransferWarningModal = connectModal({
  name: "WalletTransferWarningModal",
})(_WalletTransferWarningModal);
