import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Box,
  BoxProps,
  Button,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import FileIcon from "src/assets/icons/file-icon.png";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import {
  Query,
  QueryGetReportDownloadUrlArgs,
  QueryGetReportExportArgs,
  ReportExportTypeEnum,
  ReportTypeEnum,
} from "src/api/generated/types";
import { ActionSheet, Info, Input } from "src/components";
import WhiteBox from "src/components/styled/WhiteBox";
import WhiteBoxImage from "src/components/styled/WhiteBoxImage";
import { Maybe } from "src/core";
import { useMe, useMyToast } from "src/hooks";
import { useDownloadFile } from "src/hooks/common/useDownloadFile";
import { colors, other } from "src/theme";
import Fuse from "fuse.js";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { compose } from "lodash/fp";
import SecondaryText from "src/components/styled/SecondaryText";
import { BaseReportFields, BaseUserFields } from "src/api/fragments";
import { toLuxonUTC } from "src/utils";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";

export const ReportsTable = ({ maxWNum }: { maxWNum: number }) => {
  const isLarge = useIsLargeScreen();
  const { clientId } = useParams<{ clientId: string }>();
  const [fetchReports, { data: reportsData }] = useLazyQuery<{
    getReports?: BaseReportFields[];
  }>(api.reports.getReports);

  useEffect(() => {
    if (!clientId) return;
    void fetchReports({ variables: { clientId } });
  }, [clientId]);

  const reports = reportsData?.getReports || [];
  const theme = useTheme();

  if (!reports.length) {
    return null;
  }

  if (!isLarge) {
    return null;
  }

  return (
    <WhiteBox border={`1px solid ${theme.border}`} w="100%">
      <Heading color={theme.header} size="md" marginTop="0.5rem" paddingTop="0">
        Tax Reports History
      </Heading>

      {reports.map((report) => (
        <Report key={report.id} report={report} />
      ))}
    </WhiteBox>
  );
};

function Report({ report }: { report: BaseReportFields }) {
  const toast = useMyToast();
  const { download } = useDownloadFile();
  const theme = useTheme();

  const [fetchDownloadUrl] = useLazyQuery<
    { getReportDownloadUrl: Query["getReportDownloadUrl"] },
    QueryGetReportDownloadUrlArgs
  >(api.reports.getReportDownloadUrl, {
    fetchPolicy: "network-only",
  });

  const _downloadUrl = async (): Promise<void> => {
    try {
      const response = await fetchDownloadUrl({
        variables: { reportId: report.id },
      });

      const url = response?.data?.getReportDownloadUrl;

      if (url) download(url);
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred",
        status: "error",
      });
    }
  };

  const _getDateDescription = () => {
    if (report.startDate && report.endDate) {
      return (
        <HStack height="1.5rem">
          <Text color={theme.text} margin="0 !important" fontSize="sm">
            {toLuxonUTC(new Date(report.startDate)).toFormat("LLL dd, yyyy")} -{" "}
            {toLuxonUTC(new Date(report.endDate)).toFormat("LLL dd, yyyy")}{" "}
            <Info message="The transactions in this report are in between this date range." />
          </Text>
        </HStack>
      );
    }
    return null;
  };

  return (
    <Box borderBottom={`1px solid ${theme.border}`} padding="1rem 0">
      <HStack width="100%" alignItems="center" justifyContent="center">
        <Box
          width={50}
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <i
            className="fa-sharp fa-file-spreadsheet"
            style={{ color: colors.primary, fontSize: 30 }}
          />
        </Box>
        <Box flex={1} marginLeft="0.5rem">
          <HStack>
            <Heading color={theme.header} size="sm" margin="0" padding="0">
              {report.title}{" "}
            </Heading>
            <HStack
              bg={theme.secondaryBackground}
              border={`1px solid ${theme.border}`}
              borderRadius={7}
              padding="0.1rem 0.5rem"
            >
              <ReportIcon report={report} />{" "}
              <Text color={theme.header} fontSize="sm" fontWeight={500}>
                {report.type}
              </Text>
            </HStack>
          </HStack>
          <HStack marginTop="0.25rem">
            <Tooltip
              label={`We used the ${(
                report.costBasisAlgorithm || "FIFO"
              ).toUpperCase()} algorithm to generate this report.`}
            >
              <span>
                <Text
                  color={theme.text}
                  fontWeight="bold"
                  margin="0 !important"
                  fontSize="sm"
                >
                  {(report.costBasisAlgorithm || "FIFO").toUpperCase()}
                </Text>
              </span>
            </Tooltip>

            <Box color={theme.text} padding="0 0.25rem" fontSize={16}>
              |
            </Box>
            <Text color={theme.text} margin="0 !important" fontSize="sm">
              Generated on{" "}
              {toLuxonUTC(new Date(report.createdAt)).toFormat("LLL dd, yyyy")}{" "}
            </Text>
            <Box color={theme.text} padding="0 0.25rem" fontSize={16}>
              |
            </Box>
            {_getDateDescription()}
          </HStack>
        </Box>

        <Button
          leftIcon={
            <i
              style={{ fontSize: 16, color: theme.header }}
              className="fa-sharp fa-download"
            />
          }
          onClick={_downloadUrl}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bg={theme.secondaryBackground}
          _hover={{ bg: theme.ternaryBackground }}
          color={theme.header}
          padding="0.75rem 1.25rem"
          maxWidth="125px"
          fontSize="sm"
        >
          Download
        </Button>
      </HStack>
    </Box>
  );
}

const ReportIcon = ({ report }: { report: BaseReportFields }) => {
  const type = report.type;

  if (
    type === ReportTypeEnum.Irs8949 ||
    type === ReportTypeEnum.IrsSchedule1 ||
    type === ReportTypeEnum.IrsScheduleD
  ) {
    return (
      <img
        style={{
          width: 22,
          height: 22,
          objectFit: "contain",
          border: `2px solid ${colors.gray80}`,
          borderRadius: 3,
        }}
        src={require("src/assets/awaken/icons/irs.png")}
      />
    );
  }

  if (type === ReportTypeEnum.TurboTax) {
    return (
      <img
        style={{
          width: 15,
          height: 15,
          objectFit: "contain",
        }}
        src={require("src/assets/awaken/icons/turbotax.jpeg")}
      />
    );
  }

  return (
    <i
      className="fa-sharp fa-file-spreadsheet"
      style={{ color: colors.primary, fontSize: 15 }}
    />
  );
};
