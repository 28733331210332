import {
  Box,
  Center,
  Checkbox,
  HStack,
  Image,
  Td,
  Text,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { isEmpty, truncate } from "lodash";
import { compose, isNil, uniq } from "lodash/fp";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { BaseSimpleTransactionFields } from "src/api/fragments";
import StatusTag, { StatusTagType } from "src/components/styled/StatusTag";
import { hasValue } from "src/core";
import {
  ToastType,
  useMe,
  useTransactionById,
  useTransactionSearch,
} from "src/hooks";
import { colors, other } from "src/theme";
import { toLuxonUTC, toTitleCase } from "src/utils";
import { useSearchParams } from "react-router-dom";
import { primaryGreen } from "src/theme/colors";
import {
  CurrencyCodeEnum,
  LedgerTransactionReviewStatusEnum,
} from "src/api/generated/types";
import { PROVIDER_TO_LOGO_URL } from "src/components/modals/AccountModal/constants";
import Helpers, { formatNum, getCurrencySymbol } from "src/utils/helpers";
import { IncomeTag } from "src/components/styled/Transaction/IncomeTag";
import { ReviewStatusTag } from "src/components/Labels/ReviewStatusTag";
import BigNumber from "bignumber.js";
import moment from "moment";

type TransactionProps = {
  transaction: BaseSimpleTransactionFields;
  limitedView?: boolean; // show a few columns
  onClick?: () => void;
  timezone: string;
  isLast: boolean;
};

const MAX_WIDTH = 35;

export function Transaction({
  transaction,
  limitedView,
  onClick,
  // UTC
  timezone = "UTC",
  isLast,
}: TransactionProps) {
  const [search, setSearchParams] = useSearchParams();
  const searchTransactionId = search.get("transactionId");
  const highlightTransactionId = search.get("highlightTransactionId");

  const selected = searchTransactionId === transaction.id;
  const [isHighlighted, setHighlighted] = useState(
    highlightTransactionId === transaction.id
  );

  const { me } = useMe("cache-first");
  const isSuperUser = me?.isSuperuser || false;
  const { filters } = useTransactionSearch();
  const hasIncomeSort = filters.sortBy === "incomeSum";

  const _onClickTxn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    search.delete("transactionId"); // delete the current txn id
    setSearchParams(search);
    search.append("transactionId", transaction.id);
    setSearchParams(search);
  };

  const title = transaction.title || "";

  const accountLabel = useMemo(
    () => _getAccountLabel(transaction),
    [transaction]
  );

  const isChecked =
    transaction.reviewStatus === LedgerTransactionReviewStatusEnum.Reviewed;

  const showMissingBasis = transaction.isMissingBasis;

  useEffect(() => {
    if (isHighlighted) {
      setTimeout(() => setHighlighted(false), 5000);
    }
  }, [isHighlighted]);

  // console.log(selectedTransactionIds, isSelected, transaction.id);
  // if has a sum and that sum is greater than $100, it i red and should be looked at
  const isHighGainLoss =
    !isNil(transaction.capGainsSum) &&
    new BigNumber(transaction.capGainsSum || 0).isGreaterThan(10000);

  const label = getTxnLabelParts(transaction.title || "");
  const date = toLuxonUTC(new Date(transaction.createdAt)).setZone(timezone);

  return (
    <Box
      cursor="pointer"
      _hover={{
        backgroundColor: colors.gray90,
      }}
      onClick={onClick || _onClickTxn}
      backgroundColor={
        selected || isHighlighted ? colors.lightBlue80 : undefined
      }
      padding="0.8rem 0.75rem"
      borderBottom={isLast ? "none" : `1px solid ${colors.gray85}`}
    >
      <HStack>
        <HStack flex={1}>
          <VStack flex={1} alignItems="flex-start">
            <HStack alignItems="center" style={{ width: "100%" }}>
              <Image
                src={PROVIDER_TO_LOGO_URL[transaction.provider || ""] || ""}
                marginRight="0rem"
                width="1rem"
                height="1rem"
                display="inline"
                style={{ borderRadius: 3, position: "relative" }}
              />

              <Tooltip openDelay={1000} placement="bottom-start" label={title}>
                <VStack alignItems="flex-start">
                  <Text
                    marginTop="0 !important"
                    isTruncated
                    textAlign="left"
                    fontSize={14}
                    maxW={150}
                    fontWeight="500"
                  >
                    {label?.firstPart}
                  </Text>
                  <Text
                    isTruncated
                    textAlign="left"
                    fontSize={13}
                    marginTop="0 !important"
                    color={colors.gray30}
                    maxW={150}
                  >
                    {label?.tokenNames}
                  </Text>
                </VStack>
              </Tooltip>
            </HStack>
          </VStack>
        </HStack>

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <TxnFinancialInfo transaction={transaction} />

          <Text
            isTruncated
            textAlign="left"
            fontSize="xs"
            marginTop="0 !important"
            maxW="100%"
            color={colors.gray30}
          >
            {moment(date.toJSDate()).fromNow()}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
}

const TxnFinancialInfo = ({
  transaction,
}: {
  transaction: BaseSimpleTransactionFields;
}): JSX.Element | null => {
  const capGainsSum =
    (transaction.capGainsSumSigned || transaction.capGainsSum) ?? null;
  const incomeSum = transaction.incomeSum ?? null;
  const capGainsDollars = new BigNumber(capGainsSum || 0).div(100);
  const incomeDollars = new BigNumber(incomeSum || 0).div(100);
  const currency = getCurrencySymbol(
    transaction.fiatCurrency || CurrencyCodeEnum.Usd
  );

  // cap gains sum if we have one
  if (capGainsDollars.isZero() && incomeDollars.isZero()) {
    const dollars = Number(capGainsSum) / 100;
    const isNeg = dollars < 0;
    const color = isNeg ? colors.negative : colors.positive;

    if (new BigNumber(dollars).dp(2).isZero()) {
      return (
        <HStack>
          <Tooltip label="No gain/loss.">
            <span style={{ visibility: "hidden" }}>Nothing</span>
          </Tooltip>
        </HStack>
      );
    }
  }

  if (incomeDollars.gt(0)) {
    const cashAmount = formatNum(incomeDollars.abs().toNumber(), true);
    const color = colors.positive;

    return (
      <HStack>
        <span style={{ color: color, /*colors.black*/ fontWeight: "500" }}>
          {currency}
          {cashAmount}
        </span>
        <Tooltip placement="bottom-end" label="Income Received.">
          <i
            className={"fa-sharp fa-sack-dollar"}
            // className="fa-sharp fa-circle-plus"
            style={{
              fontSize: 13,
              color,
            }}
          />
        </Tooltip>
      </HStack>
    );
  }

  if (!capGainsDollars.eq(0)) {
    const isNeg = capGainsDollars.lt(0);
    const cashAmount = formatNum(capGainsDollars.abs().toNumber(), true);
    const color = isNeg ? colors.negative : colors.positive;

    return (
      <HStack>
        <span style={{ color: color, /*colors.black*/ fontWeight: "500" }}>
          {isNeg ? "-" : "+"}
          {currency}
          {cashAmount}
        </span>
        <Tooltip
          placement="bottom-end"
          label={isNeg ? "Capital loss." : "Capital gain"}
        >
          <i
            className={
              isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"
            }
            style={{
              fontSize: 15,
              color,
            }}
          />
        </Tooltip>
      </HStack>
    );
  }

  return null;
};

const getTxnLabelParts = (input: string) => {
  if (!input) return null;

  const [firstPart, secondPart] = input.split("(");

  return {
    firstPart: firstPart.trim(),
    // replace all parentheses with commas
    tokenNames: secondPart?.replace(/\(|\)/g, "").trim() || "",
  };
};

// ex. ETH -> coinbase etc... with the wallet label. will leave that to a future version tho
const _getAccountLabel = (txn: BaseSimpleTransactionFields) => {
  const length = 10;

  const accounts = uniq([
    ...txn.transfers
      .map((t) => [
        t.fromAccount
          ? toTitleCase(truncate(t.fromAccount.description, { length: length }))
          : null,
        t.toAccount
          ? toTitleCase(truncate(t.toAccount.description, { length: length }))
          : null,
      ])
      .flat()
      .filter(hasValue),
    ...txn.fees
      .map((t) =>
        t.payerAccount
          ? toTitleCase(
              truncate(t.payerAccount.description, { length: length })
            )
          : null
      )
      .filter(hasValue),
  ]);

  if (accounts.length >= 1) {
    return accounts.join(", ");
  }

  return "--";
};
