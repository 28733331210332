import { WatchQueryFetchPolicy, useQuery } from "@apollo/client";
import { isNil } from "lodash";
import { useMemo } from "react";
import { api } from "src/api";
import {
  PortfolioResponseV2,
  QueryGetPortfolioV2Args,
} from "src/api/generated/types";

type Props = {
  activeClientId: string;
  setIsLoadingInitialNFTs?: (isLoading: boolean) => void;
  fetchPolicy?: WatchQueryFetchPolicy | undefined;
  includedAccountIds?: string[];
};

export const useNFTPortfolio = ({
  activeClientId,
  setIsLoadingInitialNFTs,
  fetchPolicy,
  includedAccountIds,
}: Props) => {
  const nftPortfolioVariables = useMemo(
    (): QueryGetPortfolioV2Args => ({
      clientId: activeClientId,
      includeDefi: false,
      includeCoins: false,
      includeNFTs: true,
      onlyIncludedAccountIds:
        !isNil(includedAccountIds) && includedAccountIds.length > 0
          ? includedAccountIds
          : null,
    }),
    [activeClientId, includedAccountIds]
  );

  const {
    data: nftData,
    error: nftError,
    refetch: refetchNFTPortfolio,
    networkStatus: nftNetworkStatus,
    loading: nftLoading,
  } = useQuery<{
    getPortfolioV2: Pick<PortfolioResponseV2, "nfts" | "nftTotalValueCents">;
  }>(api.portfolio.getPortfolioV2NFTs, {
    skip: !activeClientId,
    variables: nftPortfolioVariables,
    fetchPolicy: fetchPolicy || "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (setIsLoadingInitialNFTs) setIsLoadingInitialNFTs(false);
    },
  });

  const nftTotalValueCents = useMemo(
    () => nftData?.getPortfolioV2?.nftTotalValueCents ?? null,
    [nftData?.getPortfolioV2?.nftTotalValueCents]
  );

  return {
    nftPortfolioVariables,
    refetchNFTPortfolio,
    nftData,
    nftError,
    nftNetworkStatus,
    nftLoading,
    nftTotalValueCents,
  };
};
