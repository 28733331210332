import { isMobile } from "react-device-detect";

function navigateExternally(url: string) {
  const open = window.open(url, isMobile ? undefined : "_blank");
  if (open) {
    open.focus();
  }
}

function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export { navigateExternally, toTitleCase };
export * from "./date";
