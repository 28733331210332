import { Box, Input, Text } from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import SecondaryText from "../SecondaryText";
import { useTheme } from "src/hooks/useTheme";

type PaginateProps = {
  onPageChange: any;
  totalPages: number;
  filters: Record<string, any>;
  totalTransactions: number;
  numPageTransactions: number;
  currentPage: number;
};

export default function Paginate({
  onPageChange,
  totalPages,
  filters,
  totalTransactions,
  numPageTransactions,
  currentPage,
}: PaginateProps) {
  const theme = useTheme();

  return (
    <Box
      w="100%"
      margin="2rem auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDir={"column"}
    >
      <ReactPaginate
        breakLabel="..."
        nextLabel={<i className="fa-sharp fa-chevron-right" />}
        onPageChange={onPageChange}
        pageRangeDisplayed={4}
        forcePage={currentPage}
        initialPage={filters.page || 0}
        pageCount={totalPages}
        previousLabel={<i className="fa-sharp fa-chevron-left" />}
        pageClassName={theme.theme === "light" ? "page-item" : "dark-page-item"}
        pageLinkClassName={
          theme.theme === "light" ? "page-link" : "dark-page-link"
        }
        previousClassName={
          theme.theme === "light" ? "page-item" : "dark-page-item"
        }
        previousLinkClassName={
          theme.theme === "light" ? "page-link" : "dark-page-link"
        }
        nextClassName={theme.theme === "light" ? "page-item" : "dark-page-item"}
        nextLinkClassName={
          theme.theme === "light" ? "page-link" : "dark-page-link"
        }
        breakClassName={
          theme.theme === "light" ? "page-item" : "dark-page-item"
        }
        breakLinkClassName={
          theme.theme === "light" ? "page-link" : "dark-page-link"
        }
        containerClassName="pagination"
        activeClassName="active"
      />
      {numPageTransactions ? (
        <SecondaryText
          marginTop="1rem"
          text={`Showing ${numPageTransactions} of ${totalTransactions} transactions`}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}
