import { FetchPolicy, useLazyQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import { isEmpty } from "lodash/fp";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import {
  ActiveSubscriptionDiscountCalculation,
  DiscountInfo,
  GetMyActiveSubscriptionResponse,
  Query,
  QueryGetEligibleDiscountsForTaxYearArgs,
  QueryGetMyActiveSubscriptionArgs,
  Subscription,
} from "src/api/generated/types";
import { CURRENT_TAX_YEAR } from "src/config";
import { Maybe } from "src/core";

export type UseActiveSubscriptionResponse = {
  totalAvailable: number;
  totalUsed: Maybe<number>;
  canDownload: boolean;
  hasSubscription: boolean;
  hasUnlimitedCX: boolean;
  startPeriod: Maybe<Date>;
  endPeriod: Maybe<Date>;
  subscription: Maybe<Subscription>;
  availableDiscounts: DiscountInfo[];
  loading: boolean;
  discountCalculation: Maybe<ActiveSubscriptionDiscountCalculation>;
};

export const useActiveSubscription = (
  clientId: string,
  cachePolicy: FetchPolicy = "no-cache"
): UseActiveSubscriptionResponse => {
  const [fetchActiveSubscription, { data, loading: loadingSubscription }] =
    useLazyQuery<
      Pick<Query, "getMyActiveSubscription">,
      QueryGetMyActiveSubscriptionArgs
    >(api.subscriptions.active, {
      fetchPolicy: cachePolicy,
      notifyOnNetworkStatusChange: true,
    });

  const [
    fetchEligibleDiscounts,
    { data: discountsData, loading: loadingDiscounts },
  ] = useLazyQuery<
    Pick<Query, "getEligibleDiscountsForTaxYear">,
    QueryGetEligibleDiscountsForTaxYearArgs
  >(api.checkouts.discounts, {
    fetchPolicy: cachePolicy,
  });

  useEffect(() => {
    if (!clientId) return;
    void fetchActiveSubscription({ variables: { clientId } });
    void fetchEligibleDiscounts({
      variables: { clientId, taxYear: CURRENT_TAX_YEAR },
    });
  }, [clientId]);

  const hasPaidForUnlimitedCX =
    data?.getMyActiveSubscription?.hasPaidForUnlimitedCX ?? false;

  if (!data?.getMyActiveSubscription) {
    return {
      totalAvailable: 0,
      loading: false,
      hasUnlimitedCX: false,
      totalUsed: null,
      canDownload: false,
      hasSubscription: false,
      subscription: null,
      startPeriod: null,
      endPeriod: null,
      availableDiscounts: discountsData?.getEligibleDiscountsForTaxYear ?? [],
      discountCalculation: null,
    };
  }

  const {
    maxAllowedForTaxYear,
    currentCountForTaxYear,
    hasSubscription,
    subscription,
  } = data.getMyActiveSubscription;

  const hasEnough =
    (currentCountForTaxYear || 0) <= (maxAllowedForTaxYear || 0);

  return {
    totalAvailable: maxAllowedForTaxYear || 0,
    totalUsed: currentCountForTaxYear,
    loading: loadingSubscription && !data,
    hasUnlimitedCX: hasPaidForUnlimitedCX,
    canDownload:
      currentCountForTaxYear >= 0 && hasEnough && hasSubscription === true,
    hasSubscription,
    subscription: subscription || null,
    startPeriod: subscription?.startPeriod || null,
    endPeriod: subscription?.endPeriod || null,
    availableDiscounts: discountsData?.getEligibleDiscountsForTaxYear ?? [],
    discountCalculation: data.getMyActiveSubscription.discountCalculation,
  };
};
