import { HStack, Box, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { compose } from "lodash/fp";
import { show } from "redux-modal";
import { colors, other } from "src/theme";
import WhiteBox from "../styled/WhiteBox";
import PlusImage from "src/assets/awaken/plus.png";
import WhiteBoxImage from "../styled/WhiteBoxImage";
import SwirlyArrow from "src/assets/awaken/icons/swirly-arrow.png";
import { useTheme } from "src/hooks/useTheme";

type AddAccountProps = {
  location: string;
  numAccounts: number;
};

export function AddAccount({ location, numAccounts }: AddAccountProps) {
  const { clientId } = useParams<{ clientId: string }>();

  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const { theme, background, header, border } = useTheme();

  const _onAddWallet = () => {
    _showModal("AccountModal", {
      location,
    });
  };

  const noAccounts = numAccounts === 0;

  return (
    <WhiteBox
      display="flex"
      w="100%"
      h="100%"
      marginTop="0"
      alignItems="center"
      cursor="pointer"
      onClick={_onAddWallet}
      minW="inherit"
      position="relative"
      padding={{ base: "1.35rem 0.5rem", lg: "1.35rem 1rem" }}
      border={`1px solid ${border}`}
      bg={background}
    >
      {/* {noAccounts && (
        <>
          <div
            style={{
              position: "absolute",
              bottom: -20,
              left: -15,
              // rotate css 70 degrees
              transform: "rotate(110deg)",
            }}
          >
            <img
              src={SwirlyArrow}
              style={{
                height: "auto",
                width: 30,
                objectFit: "contain",
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: -20,
              right: -15,
              // rotate css 70 degrees
              transform: "rotate(-70deg)",
            }}
          >
            <img
              src={SwirlyArrow}
              style={{
                height: "auto",
                width: 30,
                objectFit: "contain",
              }}
            />
          </div>
        </>
      )} */}
      <HStack width="100%" h="100%" alignItems="center" justifyContent="center">
        <WhiteBoxImage src={PlusImage} />
        <Box flex={1} marginLeft="0.5rem">
          <Text color={header} fontSize="md">
            Add New Account
          </Text>
          {/* <Text fontSize="sm" marginTop="0.25rem" color={colors.gray20}>
            Automated support for most major blockchains & exchanges!
          </Text> */}
        </Box>
      </HStack>
    </WhiteBox>
  );
}
