import {
  Box,
  Button,
  Container,
  Divider,
  FormLabel,
  HStack,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { useNavigate, useParams } from "react-router-dom";
import { Copy, Input, Textarea } from "src/components/styled";
import {
  Mutation,
  MutationGenerateBookkeepingInvoiceArgs,
  MutationRequestBookkeepingQuoteArgs,
  MutationUpdateBookkeepingQuoteArgs,
  UploadFileResponse,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import { BaseBookkeepingQuoteFields } from "src/api/fragments";
import { useDropzone } from "react-dropzone";
import { Required } from "src/components/styled/Form/Required";
import { isNil } from "lodash";

type Props = InjectedProps & {
  quote: Maybe<BaseBookkeepingQuoteFields>;
};

function _QuoteInvoiceModal({ handleHide, quote, show: isVisible }: Props) {
  const [generateBookkeepingInvoice, { loading }] = useMutation<
    Pick<Mutation, "generateBookkeepingInvoice">
  >(api.checkouts.generateBookkeepingInvoice);

  const toast = useMyToast();
  const theme = useTheme();
  const navigate = useNavigate();

  const [checkoutLink, setCheckoutLink] = useState<string | null>(null);

  // monthly price
  const [monthlyPrice, setMonthlyPrice] = useState(
    isNil(quote?.monthlyPriceCents) ? 0 : quote?.monthlyPriceCents || 0 / 100
  );
  // upfront price
  const [upfrontPrice, setUpfrontPrice] = useState(
    isNil(quote?.upfrontPriceCents) ? 0 : quote?.upfrontPriceCents || 0 / 100
  );

  const _onSubmit = async function () {
    if (!quote) {
      return;
    }

    try {
      const variables: MutationGenerateBookkeepingInvoiceArgs = {
        bookkeepingQuoteId: quote.id!,
        upfrontAmountCents: Math.ceil(upfrontPrice * 100),
        monthlyAmountCents: Math.ceil(monthlyPrice * 100),
      };

      const response = await generateBookkeepingInvoice({
        variables: variables,
        refetchQueries: [
          api.bookkeepingQuotes.retrieve,
          api.bookkeepingQuotes.listForClient,
        ],
      });

      const url = response.data?.generateBookkeepingInvoice;

      setCheckoutLink(url || "");

      toast.show({
        message:
          "Checkout link created! You can send it to a client now to pay.",
        status: "success",
      });
    } catch (e) {
      console.error(e);

      toast.show({
        message: "Error updating quote to client.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title={<>Bookkeeping Invoice</>}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        checkoutLink ? (
          <div />
        ) : (
          <Button
            width="100%"
            variant="primary"
            isLoading={loading}
            marginBottom="1rem"
            onClick={_onSubmit}
          >
            Generate Invoice Link
          </Button>
        )
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text fontSize="md" color={theme.text}>
          This will generate a payment link you can share with the client. The
          link will not be sent to the client automatically.
        </Text>

        <br />

        {/* number inputs for monthly + upfront prices */}
        {!checkoutLink ? (
          <>
            <Input
              label="Monthly Price (USD)"
              type="number"
              value={monthlyPrice}
              onChange={(e) => setMonthlyPrice(parseFloat(e.target.value))}
            />

            <Input
              label="Upfront Price (USD)"
              type="number"
              value={upfrontPrice}
              onChange={(e) => setUpfrontPrice(parseFloat(e.target.value))}
            />
          </>
        ) : (
          <div
            onClick={() => {
              // copy the link to the clipboard
              navigator.clipboard.writeText(checkoutLink);
              toast.show({
                message: "Link copied to clipboard!",
                status: "success",
              });
            }}
            style={{
              cursor: "pointer",
            }}
          >
            {/* make a UI for a user's referral link they can copy */}
            <div
              style={{
                padding: "10px 15px",
                backgroundColor: theme.secondaryBackground,
                borderRadius: 50,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {/* <div>
              <i className="fa-sharp fa-link" style={{ marginRight: 10 }} />
            </div> */}
              <Text
                fontSize="sm"
                style={{
                  flex: 1,
                  color: theme.header,
                  overflow: "hidden",
                }}
                noOfLines={1}
              >
                {checkoutLink}
              </Text>
              <i
                className="fa-sharp fa-copy"
                style={{
                  marginLeft: 10,
                  cursor: "pointer",
                  color: theme.header,
                }}
              />
            </div>

            <Text
              style={{ marginTop: 5, textAlign: "center", color: theme.text }}
              fontSize="xs"
            >
              Click to copy
            </Text>
          </div>
        )}

        <br />

        {checkoutLink ? (
          <Text fontSize="md" color={theme.text}>
            This link expires after 24 hours. You can always generate a new one
            if it expires.
          </Text>
        ) : null}
      </Container>
    </Modal>
  );
}

export const QuoteInvoiceModal = connectModal({
  name: "QuoteInvoiceModal",
})(_QuoteInvoiceModal);
