import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Image,
  Th,
  Text,
  Thead,
  Tr,
  Td,
  UnorderedList,
  ListItem,
  Heading,
} from "@chakra-ui/react";
import truncateMiddle from "truncate-middle";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import StatusTag from "../../../components/styled/StatusTag";
import { api } from "src/api";
import {
  CurrencyCodeEnum,
  Maybe,
  RecalculateSnapshotDiffTxn,
  RecalculateSummaryBreakdown,
  RecalculateSummaryDiffV1,
  RecalculateSummarySnapshotFee,
  RecalculateSummarySnapshotTransfer,
  RecalculateSummarySnapshotTxn,
} from "src/api/generated/types";
import { Info, Select, Option, Checkbox } from "src/components";
import { ReviewStatusTag } from "src/components/Labels";
import { PROVIDER_TO_LOGO_URL } from "src/components/modals/AccountModal/constants";
import SecondaryText from "src/components/styled/SecondaryText";
import WhiteBox from "src/components/styled/WhiteBox";
import {
  formatSummaryDate,
  getBreakdownYear,
  getRelevantBreakdown,
} from "src/modules/jobs";
import { D, isLoadingGQL } from "src/utils/helpers";
import Loading from "src/views/Loading";
import { intersection, isEqual, truncate } from "lodash";
import { colors, other } from "src/theme";
import {
  formatAutoReviewReason,
  getLink,
} from "src/modules/ledger/transactions";
import { useClientById, useMyToast } from "src/hooks";
import { CURRENT_TAX_YEAR_STR } from "src/config";
import BigNumber from "bignumber.js";
import _ from "lodash";
import { useTheme } from "src/hooks/useTheme";

const SORT_OPTIONS = [
  {
    label: "Cap Gains Change",
    value: "capGainsChange",
  },
  {
    label: "Date",
    value: "date",
  },
];

const getTxnReviewReason = (
  txn: Maybe<RecalculateSnapshotDiffTxn> | undefined
) =>
  !txn
    ? null
    : txn.label
    ? {
        reason: txn.label,
        type: "label",
      }
    : txn.ruleUsedType
    ? {
        reason: txn.ruleUsedType,
        type: "rule",
      }
    : txn.autoReviewReason
    ? {
        reason: txn.autoReviewReason,
        type: "autoReview",
      }
    : "";

function Diffs() {
  const { clientId, oldSummaryId, newSummaryId, year: _year } = useParams();
  const { client } = useClientById(clientId);
  const year: string = useMemo(() => _year || CURRENT_TAX_YEAR_STR, [_year]);
  const [sort, setSort] = useState<Maybe<Option>>(SORT_OPTIONS[0]);
  const [onlyShowRevieChange, setOnlyShowRevieChange] =
    useState<boolean>(false);

  const [
    getDiffV1,
    { data: diffData, networkStatus: diffNetworkStatus, error },
  ] = useLazyQuery<{
    getDiffV1: RecalculateSummaryDiffV1;
  }>(api.summaries.diff, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getDiffV1({
      variables: {
        clientId,
        oldRecalculateSummaryId: oldSummaryId,
        newRecalculateSummaryId: newSummaryId,
        year: Number(year),
      },
    });
  }, []);

  const { transactions, oldRelevantBreakdown, newRelevantBreakdown } =
    useMemo(() => {
      const transactionsStr = diffData?.getDiffV1.transactions;
      if (!transactionsStr?.length)
        return {
          transactions: [],
          oldRelevantBreakdown: null,
          newRelevantBreakdown: null,
        }; // no difference

      const oldBreakdowns = diffData?.getDiffV1.oldSummary.breakdowns || [];
      const newBreakdowns = diffData?.getDiffV1.newSummary.breakdowns || [];

      const oldRelevantBreakdown = getRelevantBreakdown(oldBreakdowns, year);
      const newRelevantBreakdown = getRelevantBreakdown(newBreakdowns, year); // // only show new breakdown if we can compare it against the old one

      let transactions = transactionsStr.filter((t) => {
        if (onlyShowRevieChange) {
          console.log("onlyShowReviewChange", onlyShowRevieChange);
          const newTxnReason = getTxnReviewReason(t.newTxn);
          const oldTxnReason = getTxnReviewReason(t.oldTxn);
          console.log(
            JSON.stringify(newTxnReason) !== JSON.stringify(oldTxnReason),
            "newTxnReason",
            newTxnReason,
            "oldTxnReason",
            oldTxnReason
          );
          return JSON.stringify(newTxnReason) !== JSON.stringify(oldTxnReason);
        } else {
          console.log("FALSE");
          return true;
        }
      });
      if (sort?.value === "capGainsChange") {
        transactions = _.sortBy(transactions, (txn) => {
          const diff = new BigNumber(txn.newTxn?.capGainsSumSigned || 0)
            .abs()
            .minus(new BigNumber(txn.oldTxn?.capGainsSumSigned || 0).abs())
            .abs()
            .toNumber();
          return diff;
        }).reverse();
      } else if (sort?.value === "date")
        transactions = _.sortBy(transactions, (txn) =>
          new Date(txn.newTxn?.createdAt || "").getTime()
        );

      return {
        transactions,
        oldRelevantBreakdown,
        newRelevantBreakdown,
      };
    }, [diffData, year, sort, onlyShowRevieChange]);

  const theme = useTheme();

  if (isLoadingGQL(diffNetworkStatus)) return <Loading />;

  if (error)
    return (
      <Box w="100%" color={colors.red50}>
        {error.message}
      </Box>
    );

  const same =
    oldRelevantBreakdown &&
    newRelevantBreakdown &&
    isEqual(oldRelevantBreakdown, newRelevantBreakdown) &&
    transactions.length === 0;

  return (
    <Box w="100%">
      <Box w="100%">
        <Select
          label="Sort by"
          value={sort}
          options={SORT_OPTIONS}
          selectProps={{
            onChange: (o) => setSort(o as Maybe<Option>),
          }}
          containerStyle={{
            width: "15rem",
          }}
        />
        <Checkbox
          placeholder={"Only show transactions with review changes"}
          infoMessage="Only show the transactions where the review reason changed between the old and new summary (e.g. went from 'NFT Sell' to 'Staking Deposit'"
          value={onlyShowRevieChange}
          onChange={() => setOnlyShowRevieChange(!onlyShowRevieChange)}
        />
      </Box>
      <WhiteBox padding="0">
        <TableContainer>
          <Table variant="simple" border={`1px solid ${theme.border}`}>
            <Thead>
              <Tr w="100%">
                <Th
                  color={theme.text}
                  borderBottom={`1px solid ${theme.border} !important`}
                >
                  {formatSummaryDate(
                    diffData?.getDiffV1.oldSummary.createdAt || new Date()
                  )}
                </Th>
                <Th
                  color={theme.text}
                  borderBottom={`1px solid ${theme.border} !important`}
                >
                  {formatSummaryDate(
                    diffData?.getDiffV1.newSummary.createdAt || new Date()
                  )}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {same ? (
                <Tr w="100%">
                  <Td borderBottom={`1px solid ${theme.border} !important`}>
                    <Text color={theme.text}>
                      These have no difference between them.
                    </Text>
                  </Td>
                  <Td borderBottom={`1px solid ${theme.border} !important`}>
                    {/* needed to keep even formatting */}
                    <Text color={theme.text} visibility="hidden">
                      These have no difference between them.
                    </Text>
                  </Td>
                </Tr>
              ) : (
                <>
                  <Tr>
                    <Td borderBottom={`1px solid ${theme.border} !important`}>
                      <Heading color={theme.header} size="sm">
                        Tax Summaries
                      </Heading>
                    </Td>
                    <Td borderBottom={`1px solid ${theme.border} !important`}>
                      {/* needed to keep even formatting */}
                      <Heading
                        color={theme.header}
                        visibility="hidden"
                        size="sm"
                      >
                        Tax Summaries
                      </Heading>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      w="50%"
                      maxW="50%"
                      borderBottom={`1px solid ${theme.border} !important`}
                    >
                      {/* FIXME: fix the currency at some point but people don't change currency often so it doesn't really matter */}
                      <BreakdownDiff
                        currency={client?.currency || CurrencyCodeEnum.Usd}
                        breakdown={oldRelevantBreakdown}
                      />
                    </Td>
                    <Td
                      w="50%"
                      maxW="50%"
                      borderBottom={`1px solid ${theme.border} !important`}
                    >
                      {/* FIXME: fix the currency at some point but people don't change currency often so it doesn't really matter */}
                      <BreakdownDiff
                        currency={client?.currency || CurrencyCodeEnum.Usd}
                        breakdown={newRelevantBreakdown}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderBottom={`1px solid ${theme.border} !important`}>
                      <Heading color={theme.header} size="sm">
                        Transactions
                      </Heading>
                    </Td>
                    <Td borderBottom={`1px solid ${theme.border} !important`}>
                      {/* needed to keep even formatting */}
                      <Heading color={theme.text} visibility="hidden" size="sm">
                        Transactions
                      </Heading>
                    </Td>
                  </Tr>
                  {transactions.map((transaction) => (
                    <Tr
                      key={transaction.idempotency}
                      w="100%"
                      maxWidth="100%"
                      cursor="pointer"
                      onClick={() =>
                        window.open(
                          getLink(clientId || "", {
                            search:
                              transaction.newTxn?.txnHash ||
                              transaction.oldTxn?.txnHash ||
                              "",
                          }),
                          "_blank"
                        )
                      }
                    >
                      <Td
                        w="50%"
                        maxWidth="50%"
                        borderRight={`1px solid ${theme.border}`}
                        borderBottom={`1px solid ${theme.border} !important`}
                      >
                        {/* FIXME: fix the currency at some point but people don't change currency often so it doesn't really matter */}
                        <TransactionDiff
                          currency={client?.currency || CurrencyCodeEnum.Usd}
                          oldTxn={transaction.oldTxn}
                          newTxn={null}
                        />
                      </Td>
                      <Td
                        w="50%"
                        maxWidth="50%"
                        borderBottom={`1px solid ${theme.border} !important`}
                      >
                        <Text>
                          {/* FIXME: fix the currency at some point but people don't change currency often so it doesn't really matter */}
                          <TransactionDiff
                            oldTxn={transaction.oldTxn}
                            newTxn={transaction.newTxn}
                            currency={client?.currency || CurrencyCodeEnum.Usd}
                          />
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </WhiteBox>
    </Box>
  );
}

const BreakdownDiff = ({
  breakdown,
  currency,
}: {
  breakdown: Maybe<RecalculateSummaryBreakdown>;
  currency: CurrencyCodeEnum;
}) => {
  const theme = useTheme();

  if (!breakdown) return null;
  return (
    <Box>
      <Text color={theme.text}>Year: {getBreakdownYear(breakdown)}</Text>
      <Text color={theme.text}>
        Total Income: {D(breakdown.netIncomeCents, currency).toFormat()}
      </Text>
      <Text color={theme.text}>
        Total Gains/Losses:{" "}
        {D(breakdown.capGainsTotalCents, currency).toFormat()}
      </Text>
      <Text color={theme.text}>
        Total Futures: {D(breakdown.futuresNetCents, currency).toFormat()}
      </Text>
    </Box>
  );
};

type TransactionDiffParams = {
  oldTxn?: Maybe<RecalculateSnapshotDiffTxn>;
  newTxn?: Maybe<RecalculateSnapshotDiffTxn>;
  currency: CurrencyCodeEnum;
};

// transfers:
// overrideFiatValue: transfer.isUserSet
//     ? transfer.overrideFiatValue
//     : null,
// overrideBasisFiatValue: transfer.isUserSetBasisFiatValue
//     ? transfer.overrideBasisFiatValue
//     : null,
// isUserSet: transfer.isUserSet,
// isUserSetBasisFiatValue: transfer.isUserSetBasisFiatValue,

const TransactionDiff = ({
  oldTxn,
  newTxn,
  currency,
}: TransactionDiffParams) => {
  const theme = useTheme();
  const txn = newTxn === null ? oldTxn : newTxn;
  if (!txn) return null;
  const incomeSum = new BigNumber(txn.incomeSum || 0);
  const capGainsSumSigned = new BigNumber(txn.capGainsSumSigned || 0);

  const diffIncomeSum =
    oldTxn && newTxn
      ? incomeSum.minus(oldTxn.incomeSum || 0)
      : new BigNumber(0);
  const diffCapGainsSumSigned =
    oldTxn && newTxn
      ? capGainsSumSigned.minus(oldTxn.capGainsSumSigned || 0)
      : new BigNumber(0);
  const diffDeductibleSum =
    oldTxn?.deductibleExpensesSum !== undefined &&
    newTxn?.deductibleExpensesSum !== undefined &&
    oldTxn?.deductibleExpensesSum !== null &&
    newTxn?.deductibleExpensesSum !== null
      ? new BigNumber(txn.deductibleExpensesSum || 0).minus(
          oldTxn.deductibleExpensesSum || 0
        )
      : new BigNumber(0);
  const deductibleExpensesSum =
    txn.deductibleExpensesSum !== undefined &&
    txn.deductibleExpensesSum !== null
      ? new BigNumber(txn.deductibleExpensesSum)
      : null;

  const title = truncate(txn?.title || "", {
    length: 50,
  });
  const txnHash = truncateMiddle(txn.txnHash || "", 20, 20, "...");
  const labeledText = txn.label
    ? `Label: ${txn.label}`
    : txn.ruleUsedType
    ? `Rule: ${txn.ruleUsedType}`
    : txn.autoReviewReason
    ? `Auto-review: ${formatAutoReviewReason(txn.autoReviewReason)}`
    : "";
  return (
    <Box w="100%">
      <Box>
        {txn.provider &&
          PROVIDER_TO_LOGO_URL[txn.provider?.toLowerCase() || ""] && (
            <Image
              src={
                PROVIDER_TO_LOGO_URL[txn.provider?.toLowerCase() || ""] || ""
              }
              marginRight="0.5rem"
              width="1.25rem"
              height="1.25rem"
              display="inline"
              style={{ borderRadius: 3 }}
            />
          )}
        {title && (
          <Text color={theme.text} display="inline">
            {title}
          </Text>
        )}
      </Box>
      {txnHash && (
        <SecondaryText color={theme.text} text={txnHash} margin="0.5rem 0" />
      )}
      {txn.transfers && txn.transfers.length > 0 && (
        <Box>
          <SecondaryText color={theme.text} text="Transfers" />
          <UnorderedList color={theme.text}>
            {txn.transfers.map((xfer: any) => (
              <TransferDiff currency={currency} xfer={xfer} />
            ))}
          </UnorderedList>
        </Box>
      )}
      {txn.fees && txn.fees.length > 0 && (
        <Box>
          <SecondaryText color={theme.text} text="Fees" />
          <UnorderedList color={theme.text}>
            {txn.fees.map((fee: any) => (
              <FeeDiff xfer={fee} />
            ))}
          </UnorderedList>
        </Box>
      )}
      <br />
      <StatusTag
        boxProps={{
          marginLeft: "0",
        }}
        label={labeledText || "Unlabeled"}
        type={labeledText ? "success" : "warning"}
      />

      <Box
        display="flex"
        marginTop="5px"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <SecondaryText
          color={theme.text}
          text={`Last modified by: ${txn.lastModifiedByName || "Awaken"}`}
        />
        <Info
          style={{
            margin: 0,
            padding: 0,
          }}
          message="Note: This resets every time you hit recalculate. Multiple users could have modified this transaction, but we only show the one who did right before 'Recalculate' was clicked"
        />
      </Box>

      <br />
      <Box marginTop="0.5rem">
        <Text
          fontWeight={diffIncomeSum.isZero() ? "normal" : "bold"}
          fontSize="sm"
          color={
            (diffIncomeSum.isZero()
              ? undefined
              : diffIncomeSum.gt(0)
              ? colors.positive
              : diffIncomeSum.lt(0)
              ? colors.negative
              : undefined) || theme.text
          }
        >
          Income: {D(incomeSum.toNumber(), currency).toFormat()}
        </Text>
        <Text
          marginTop="0.2rem"
          fontSize="sm"
          fontWeight={diffCapGainsSumSigned.isZero() ? "normal" : "bold"}
          color={
            (diffCapGainsSumSigned.isZero()
              ? undefined
              : diffCapGainsSumSigned.gt(0)
              ? colors.positive
              : diffCapGainsSumSigned.lt(0)
              ? colors.negative
              : undefined) || theme.text
          }
        >
          Gains/Losses: {D(capGainsSumSigned.toNumber(), currency).toFormat()}{" "}
          {!diffCapGainsSumSigned.isZero()
            ? `(${diffCapGainsSumSigned.isNegative() ? "" : "+"}${D(
                diffCapGainsSumSigned.toNumber(),
                currency
              ).toFormat()})`
            : ""}
        </Text>
        <Text
          marginTop="0.2rem"
          fontSize="sm"
          fontWeight={diffDeductibleSum.isZero() ? "normal" : "bold"}
          color={
            (diffDeductibleSum.isZero()
              ? undefined
              : diffDeductibleSum.gt(0)
              ? colors.positive
              : diffDeductibleSum.lt(0)
              ? colors.negative
              : undefined) || theme.text
          }
        >
          Deductible expenses:{" "}
          {deductibleExpensesSum === null
            ? "?"
            : D(deductibleExpensesSum.toNumber(), currency).toFormat()}{" "}
          {!diffDeductibleSum.isZero()
            ? `(${diffDeductibleSum.isNegative() ? "" : "+"}${D(
                diffDeductibleSum.toNumber(),
                currency
              ).toFormat()})`
            : ""}
          <Info message="Deductible expenses are subtracted from your total cap gains. This is good for you! For example, we factor in your Token approvals as deductible expenses, meaning your capital gains is less so you pay less in taxes." />
        </Text>
      </Box>
    </Box>
  );
};

const TransferDiff = ({
  xfer,
  currency,
}: {
  xfer: RecalculateSummarySnapshotTransfer;
  currency: CurrencyCodeEnum;
}) => {
  const theme = useTheme();

  return (
    <ListItem key={xfer.dedupeUniqueId}>
      <SecondaryText text={`${xfer.amount} ${xfer.assetSymbolOrName}`} />
      {xfer.isUserSet ? (
        <SecondaryText
          color={theme.text}
          text={`Overrided Sell Price: ${D(
            xfer.overrideFiatValue ?? 0,
            currency
          ).toFormat()}`}
        />
      ) : (
        <></>
      )}
      {xfer.isUserSetBasisFiatValue ? (
        <SecondaryText
          text={`Overrided Purchase Price: ${D(
            xfer.overrideBasisFiatValue ?? 0,
            currency
          ).toFormat()}`}
          color={theme.text}
        />
      ) : (
        <></>
      )}
    </ListItem>
  );
};

const FeeDiff = ({ xfer }: { xfer: RecalculateSummarySnapshotFee }) => {
  const theme = useTheme();

  return (
    <ListItem key={xfer.dedupeUniqueId}>
      <SecondaryText
        color={theme.text}
        text={`${xfer.amount} ${xfer.assetSymbolOrName}`}
      />
    </ListItem>
  );
};

export default Diffs;
