import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Link,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { colors } from "src/theme";
import CountUp from "react-countup";
import { Maybe, hasValue } from "src/core";
import {
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { api } from "src/api";
import {
  AssetTypeEnum,
  ImportTypeEnum,
  PartialAsset,
  PartialPortfolioAsset,
  PortfolioBalance,
  PortfolioResponse,
  Position,
  Query,
} from "src/api/generated/types";
import { DateTime } from "luxon";
import { isNil, keyBy, last, throttle, truncate } from "lodash";
import Helpers, { D, formatNum } from "src/utils/helpers";
import { useClientById, useMe, useMyToast } from "src/hooks";
import * as moment from "moment-timezone";
import { Touchable } from "src/components/Touchable";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AssetIcon } from "src/components/styled/Assets";
import numbro from "numbro";
import { ActionSheet, Copy, Info } from "src/components";
import qs from "query-string";
import WhiteBox from "src/components/styled/WhiteBox";
import { useInterval } from "src/hooks/common";
import StatusTag from "src/components/styled/StatusTag";
import { negative, positive } from "src/theme/colors";
import { BaseAccountFields, BaseAssetFields } from "src/api/fragments";
import { getImageKitCDNUrl } from "src/utils/imagekit";
import BigNumber from "bignumber.js";
import { getProviderShort } from "src/modules/providers";
import truncateMiddle from "truncate-middle";

type PortfolioAssetWithAssetInfo = PortfolioBalance & {
  assetInfo: Maybe<BaseAssetFields>;
};

export type PortfolioAccountWithAssetInfo = any & {
  assets: PortfolioAssetWithAssetInfo[];
};

const Positions = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { accounts } = useClientById(clientId);
  const [isShown, setShown] = useState(true);

  const [
    fetchPositions,
    { data: portfolioData, loading: positionsLoading, error: positionsErrors },
  ] = useLazyQuery<{ getPositions: Position[] }>(api.portfolio.getPositions, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!clientId) return;
    fetchPositions({
      variables: { clientId },
    });
  }, [clientId]);

  const positions = portfolioData?.getPositions || [];

  return (
    <Box paddingBottom="5rem">
      <HStack flex={1} marginBottom="1.25rem" alignItems="center">
        <Flex alignItems="center" flex={1}>
          <Text fontSize={18} fontWeight="semibold" marginRight="5px">
            Positions
          </Text>
          <Info
            style={{ fontSize: 16 }}
            message="These include staking/lending positions. If you see a problem, email team@awaken.tax 🙏."
          />
        </Flex>
        <Touchable
          iconName="fa-sharp fa-sync"
          onClick={async () => {
            await fetchPositions({
              variables: { clientId },
            });
          }}
        />
        <Touchable
          label={isShown ? "Hide" : "Show"}
          onClick={() => setShown(!isShown)}
        />
      </HStack>
      {isShown && (
        <div
          style={{
            border: `1px solid ${colors.gray85}`,
            borderRadius: 7,
          }}
        >
          <WhiteBox
            style={{
              boxShadow: "none", // other.lighterBoxShadow,
            }}
            padding="5px 0"
            marginTop="0"
            h="100%"
            minW="none"
          >
            {positions.length > 0 ? (
              <Grid
                display="inline"
                w="100%"
                templateColumns={{ base: "repeat(1, 1fr)" }}
                marginBottom="75px"
              >
                {positions.map((position, index) => (
                  <GridItem colSpan={1} key={position.account.id}>
                    <PositionInfo
                      position={position}
                      isLast={index === positions.length - 1}
                    />
                  </GridItem>
                ))}
              </Grid>
            ) : (
              <div
                style={{
                  padding: "2rem",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                No staking/lending positions
              </div>
            )}
          </WhiteBox>
        </div>
      )}
    </Box>
  );
};

const PositionInfo = ({
  isLast,
  position,
}: {
  isLast: boolean;
  position: Position;
}) => {
  const { clientId } = useParams<{ clientId: string }>();

  const account = position.account;
  const accountTxnsLink = `/clients/${clientId}/transactions?accountIds=${account.id}`;
  const toast = useMyToast();

  const [renameAccount] = useMutation<{
    renameAccount: BaseAccountFields;
  }>(api.accounts.rename);

  const _onClickRename = async () => {
    try {
      const newName = prompt(
        `What do you want to rename ${account.description}`
      );

      if (!newName || newName.length === 0) {
        throw Error("You must provide a name for the new wallet.");
      }

      await renameAccount({
        variables: {
          accountId: account.id,
          newName,
        },
        refetchQueries: [api.clients.accounts],
      });

      toast.show({
        message: `Renamed wallet to ${newName}`,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const assetsWithInfo = useMemo(() => position?.assets || [], [position]);

  return (
    <div
      style={{
        verticalAlign: "top",
        borderBottom: isLast ? "none" : `1px solid ${colors.gray85}`,
        flexShrink: 0,
        width: "100%",
      }}
    >
      <HStack style={{ padding: "10px 10px", position: "relative" }}>
        <VStack w="100%" alignItems="flex-start">
          <HStack w="100%" alignItems="center">
            <Box style={{ display: "flex", alignItems: "center" }} flex={1}>
              <VStack
                flex={1}
                w="100%"
                marginRight="5px"
                alignItems="flex-start"
              >
                <Link
                  marginTop="0 !important"
                  _hover={{
                    color: colors.primary,
                    textDecoration: "underline",
                  }}
                  color={colors.gray20}
                  fontWeight={500}
                  href={accountTxnsLink || "#"}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                  flex={1}
                >
                  <Text
                    marginBottom="0 !important"
                    isTruncated
                    fontSize="sm"
                    fontWeight="500"
                    color={colors.black}
                  >
                    {truncate(account.description, { length: 28 })}{" "}
                  </Text>
                </Link>
                <HStack marginTop="0 !important" alignItems="center">
                  <Text
                    marginTop="0 !important"
                    marginBottom="0 !important"
                    isTruncated
                    fontSize="xs"
                    fontWeight="normal"
                    color={colors.gray30}
                    marginRight="0"
                  >
                    {getProviderShort(account.provider)}
                  </Text>
                  <Image
                    src={getImageKitCDNUrl(account.iconImageUrl, {
                      width: 160,
                      height: 160,
                    })}
                    w="0.85rem"
                    h="0.85rem"
                    borderRadius="5px"
                    flexShrink={0}
                    marginRight="0rem"
                    marginLeft="5px !important"
                  />
                </HStack>
              </VStack>

              <ActionSheet
                popover={{ placement: "bottom-end", trigger: "hover" }}
                content={{ width: 250 }}
                commands={[
                  account.blockExplorerUrl
                    ? {
                        label: "Open in block explorer", // + account.blockExplorerName,
                        iconImageSrc: account.iconImageUrl,
                        link: account.blockExplorerUrl || "",
                      }
                    : null,
                  {
                    label: "Rename position",
                    iconName: "fa-sharp fa-pen",
                    onClick: _onClickRename,
                  },
                  {
                    label: "Copy ID",
                    iconName: "fa-sharp fa-copy",
                    onClick: () => {
                      navigator.clipboard.writeText(account.id || "");
                      toast.show({
                        message: "Copied to clipboard!",
                        status: "info",
                      });
                    },
                  },
                ].filter(hasValue)}
              >
                <Touchable iconName="fa-sharp fa-ellipsis-v" />
              </ActionSheet>
            </Box>
          </HStack>
        </VStack>
      </HStack>

      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={"flex-start"}
        alignItems="flex-start"
        maxHeight="100px"
        marginBottom="5px"
        overflowY="scroll"
        // border={"1px solid " + colors.gray90}
        // borderRadius={5}
      >
        {assetsWithInfo.map((info) => (
          <AssetPosition
            accountTxnsLink={accountTxnsLink}
            portfolioAsset={info}
            key={info.asset.id}
          />
        ))}
      </Box>
    </div>
  );
};

const AssetPosition = ({
  accountTxnsLink,
  portfolioAsset,
}: {
  accountTxnsLink: string;
  portfolioAsset: PartialPortfolioAsset;
}) => {
  const { asset, amount, currentFiatAmount, estimatedCurrentFiatAmount } =
    portfolioAsset;
  const hasPrice = !isNil(currentFiatAmount) && currentFiatAmount > 0;
  const isOpenPosition =
    amount > 0 &&
    !isNil(estimatedCurrentFiatAmount) &&
    estimatedCurrentFiatAmount > 0;

  const assetName =
    asset.type === AssetTypeEnum.Nft
      ? asset.name || asset.symbol
      : asset?.symbol || asset?.name;

  return (
    <Link
      href={`${accountTxnsLink}&assetIds=${asset.id}&includeSpam=true`}
      target="_blank"
      color={colors.gray10}
      _hover={{ color: colors.gray10, backgroundColor: colors.gray90 }}
      w="100%"
      padding="10px 15px 10px 10px"
    >
      <HStack
        key={asset.id}
        opacity={isOpenPosition ? 1 : 0.4}
        marginRight="0.5rem"
        w="100%"
      >
        <AssetIcon
          textStyle={{ fontSize: 8, color: colors.white }}
          numCharacters={2}
          asset={asset}
          style={{
            backgroundColor: colors.gray20,
            position: "relative",
            top: -1,
            color: colors.gray10,
            marginRight: "0rem",
          }}
          size={22}
        />

        <Tooltip
          openDelay={500}
          label={`${amount} ${assetName}`}
          placement="bottom-start"
        >
          <Text flex={3} fontWeight="500" fontSize="sm">
            {_trunc2(amount)} {truncateMiddle(assetName || "", 6, 6, "..")}
          </Text>
        </Tooltip>

        <Text textAlign="right" flex={2} fontWeight="500" fontSize="sm">
          <Tooltip
            openDelay={500}
            placement="bottom-end"
            label={`This position is currently worth ${D(
              estimatedCurrentFiatAmount ?? 0
            ).toFormat("$0,0.00")}.`}
          >
            <span>
              {D(estimatedCurrentFiatAmount ?? 0).toFormat("$0,0.00")}
            </span>
          </Tooltip>

          {!hasPrice && (
            <Tooltip
              placement="bottom-end"
              label={`This position doesn't have a liquid price.`}
            >
              <Text
                fontSize="xs"
                marginTop={0}
                // fontStyle="italic"
                style={{
                  marginTop: "0.25rem",
                  color: colors.gray30,
                  display: "inline-block",
                  backgroundColor: colors.gray90,
                  padding: "0.1rem 0.5rem",
                  borderRadius: 5,
                }}
              >
                Unpriced <i className="fa-sharp fa-question" />
              </Text>
            </Tooltip>
          )}
        </Text>
      </HStack>
    </Link>
  );
};
const _trunc2 = (num: number) => {
  if (new BigNumber(num).isNaN()) return 0;
  // if bigger than 100k we don't need the decimals
  if (num > 10_000)
    return numbro(num).format({ thousandSeparated: true, mantissa: 0 });
  return numbro(num).format("0,0.[0000]");
};

export default Positions;
