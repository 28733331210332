import {
  Heading,
  Table,
  Text,
  Tr,
  Th,
  TableContainer,
  Thead,
  Tbody,
  HStack,
  Box,
  Image,
  Td,
  ButtonGroup,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Tooltip,
  Divider,
  PopoverBody,
  Link,
} from "@chakra-ui/react";
import {
  BaseAccountFields,
  BaseAccountWithCurrentJobFields,
  BaseAssetFields,
  BaseUserFields,
} from "src/api/fragments";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "src/api";
import {
  AssetAccountBreakdown,
  AssetTypeEnum,
  AssetWarning,
  CurrencyCodeEnum,
  PortfolioAssetPosition,
} from "src/api/generated/types";
import _, { isEmpty, isNil, keyBy, noop, truncate } from "lodash";
import { AssetIcon } from "src/components/styled/Assets";
import { colors, other } from "src/theme";
import {
  getAssetLinkFromId,
  getAssetLinkFromIdDetailPage,
  getAssetLinkFromSymbol,
  getLink,
} from "src/modules/ledger/transactions";
import { PROVIDER_TO_LOGO_URL } from "src/components/modals/AccountModal/constants";
import Fuse from "fuse.js";
import BigNumber from "bignumber.js";
import Helpers, { D, formatNum } from "src/utils/helpers";
import numeral from "numeral";
import { Maybe, hasValue } from "src/core";
import { Touchable } from "src/components/Touchable";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { getAssetOnMarketplaceOrCoingecko } from "src/modules/getAssetUrl";
import SmallLogo from "src/assets/awaken/logos/awaken-black.jpeg";
import RedSearch from "src/assets/awaken/icons/red-search.png";
import { useInterval } from "src/hooks/common";
import numbro from "numbro";
import ReactPaginate from "react-paginate";
import StatusTag from "src/components/styled/StatusTag";
import { ActionSheet } from "src/components";
import { useMyToast } from "src/hooks";
import { getImageKitCDNUrl } from "src/utils/imagekit";
import truncateMiddle from "truncate-middle";
import { singular } from "pluralize";
import { getProviderShort } from "src/modules/providers";
import { useContext } from "react";
import { AssetKeyInfoContext } from "../utils";
import { useTheme } from "src/hooks/useTheme";

const NAME_FLEX = 3;
const VALUE_FLEX = 2;

export const AccountPosition = ({
  position,
  clientId,
  currency,
  isLast,
}: {
  position: PortfolioAssetPosition;
  clientId: string;
  currency: CurrencyCodeEnum;
  isLast: boolean;
}) => {
  const { symbol, iconImageUrl } = useContext(AssetKeyInfoContext);
  const currentValue = position.fiatAmountCents;
  const toast = useMyToast();
  const theme = useTheme();

  const name = position.name;

  return (
    <HStack
      style={{
        justifyContent: "space-between",
        borderBottom: isLast ? "none" : `1px solid ${theme.border}`,
      }}
      opacity={1}
      // borderRadius={7}
      // borderBottom="none !important"
      width="100%"
      padding="1rem 0.75rem"
    >
      <div style={{ marginRight: 15, position: "relative" }}>
        <AssetIcon
          asset={{
            iconImageUrl: iconImageUrl,
            symbol: symbol,
            type: AssetTypeEnum.FungibleToken,
          }}
          size={35}
        />

        <img
          src={position.logoUrl || SmallLogo}
          style={{
            width: "1.25rem",
            height: "1.25rem",
            borderRadius: "100%",
            bottom: -5,
            border: `1px solid ${theme.border}`,
            backgroundColor: theme.border,
            right: -5,
            borderRight: "100%",
            position: "absolute",
          }}
        />
      </div>

      <Box
        display="flex"
        style={{
          flex: NAME_FLEX,
          alignItems: "flex-start",
        }}
        marginLeft="0 !important"
        alignItems="center"
        position="relative"
      >
        <VStack marginLeft="0 !important" w="100%" alignItems="flex-start">
          <HStack w="100%">
            <VStack alignItems="flex-start" w="100%">
              <HStack w="100%">
                <Tooltip label={name} openDelay={500}>
                  <Text
                    isTruncated
                    margin="0 !important"
                    fontSize="md"
                    fontWeight="500"
                    color={theme.header}
                    textDecoration="none"
                  >
                    {truncateMiddle(name, 8, 8, "..")}
                  </Text>
                </Tooltip>
              </HStack>

              <HStack>
                {position.descriptionImageUrl && (
                  <img
                    src={position.descriptionImageUrl}
                    style={{
                      width: "1rem",
                      height: "1rem",
                      borderRadius: "100%",
                    }}
                  />
                )}
                <Text fontSize="sm" color={theme.header}>
                  {truncate(
                    position.description ||
                      Helpers.capitalize(position.provider) ||
                      "",
                    { length: 20 }
                  )}
                  {!isNil(position.positionDescription)
                    ? ` • ${position.positionDescription}`
                    : ""}
                </Text>
              </HStack>
            </VStack>
          </HStack>
        </VStack>
      </Box>

      <VStack
        style={{
          flex: VALUE_FLEX,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          marginLeft: 0,
        }}
      >
        <CurrentValue breakdown={position} currency={currency} />

        <Tooltip
          label={`${numbro(position.amount ?? 0).format(
            "0,000.[00000000]"
          )} ${symbol}`}
          openDelay={500}
        >
          <Text
            margin="0"
            isTruncated
            fontSize="sm"
            isNumeric
            color={theme.header}
          >
            {formatNum(position.amount ?? 0, false, "0,0.[0000]") + " "}
            {truncate(symbol || "", { length: 8 })}
          </Text>
        </Tooltip>
      </VStack>
    </HStack>
  );
};

const CurrentValue = ({
  breakdown,
  currency,
}: {
  breakdown: PortfolioAssetPosition;
  currency: CurrencyCodeEnum;
}) => {
  const { clientId } = useParams<{ clientId: string }>();

  const currentValue = breakdown.fiatAmountCents;
  const theme = useTheme();

  return (
    <Box>
      <Text
        fontSize="md"
        isNumeric
        color={theme.header}
        style={{
          fontWeight: "500",
        }}
      >
        {D(currentValue || 0, currency).toFormat()}
      </Text>
      {/* <Text
          fontSize="sm"
          marginTop={0}
          isTruncated
          // fontStyle="italic"
          style={{ color: colors.gray30 }}
        >
          {D(breakdown.currentTokenPriceFiatAmount || 0, currency).toFormat()}/
          {isNFT
            ? singular(tokenName)
            : asset?.symbol?.toUpperCase() || singular(tokenName)}
        </Text> */}
    </Box>
  );
};
