import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  ButtonProps,
  Center,
  Link as ChakraLink,
  Container,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  ImageProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { CSSProperties, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Lottie from "react-lottie-player";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";
import * as scroll from "react-scroll";
import { compose } from "redux";
import { show } from "redux-modal";
import AppStore from "src/assets/awaken/landing/app-store.png";
import Triangle2 from "src/assets/awaken/landing/black-triangle-2.svg";
import Triangle from "src/assets/awaken/landing/black-triangle.svg";
import Forms from "src/assets/awaken/landing/forms.png";
import Plus from "src/assets/awaken/landing/plus.png";
import Arbitrum from "src/assets/awaken/logos/chains/arb.png";
import Avalanche from "src/assets/awaken/logos/chains/avax.png";
import Ethereum from "src/assets/awaken/logos/chains/eth.png";
import Polygon from "src/assets/awaken/logos/chains/matic.png";
import Optimism from "src/assets/awaken/logos/chains/opt.png";
import Solana from "src/assets/awaken/logos/chains/sol.png";
import CoinTracker from "src/assets/awaken/logos/cointracker.png";
import Coinbase from "src/assets/awaken/logos/exchanges/coinbase.svg";
import Koinly from "src/assets/awaken/logos/koinly.png";
import AwakenLogo from "src/assets/awaken/logos/logo-name.png";
import WhiteLogo from "src/assets/awaken/logos/white-logo.png";
import ZenLedger from "src/assets/awaken/logos/zenledger.png";
import { AwakenTooltip, Info } from "src/components";
import { FreeBanner } from "src/components/banners/FreeBanner";
import { PageTemplateContainer } from "src/components/layouts";
import { CheckoutModal } from "src/components/modals/CheckoutModal";
import { FeedbackModal } from "src/components/modals/FeedbackModal";
import SecondaryText from "src/components/styled/SecondaryText";
import { getUserAuthStatus } from "src/redux/reducers/user";
import { colors } from "src/theme";
import { isElementInViewport } from "src/utils/helpers";
import styled from "styled-components";
import { Stars } from "../Landing/Stars";
import { TestimonialsScrolling } from "./TestimonialsScrolling";

// testimonials
import Ben from "src/assets/awaken/landing/testimonials/ben.png";
import Best from "src/assets/awaken/landing/testimonials/best.png";
import Close from "src/assets/awaken/landing/testimonials/close.png";
import Lawtoshi from "src/assets/awaken/landing/testimonials/lawtoshi.png";
import Miraculous from "src/assets/awaken/landing/testimonials/miraculous.png";
import Moon from "src/assets/awaken/landing/testimonials/moon.png";
import Nyoung from "src/assets/awaken/landing/testimonials/nyoung.png";
import Wish from "src/assets/awaken/landing/testimonials/wish.png";
import { isWhiteLabeledDomain } from "src/utils/whitelabel";

const MAX_WIDTH = "47rem";
const BUTTON_MAX_WIDTH = "150px";

const IMAGES1 = [Moon, Nyoung, Lawtoshi, Ben];
const IMAGES2 = [Close, Best, Wish, Miraculous];

/*
DO NOT REMOVE THIS WITHOUT YASH'S PERMISSION:
Proven:
- "The “try it for free” is a great selling point and I hope you never get rid of it. Everything about the software is helpful and you don’t pay a dime until you’re ready to download your report."
  https://twitter.com/Sarah_Taze7/status/1639877664159703041


*/

const HeaderContainer = styled(Box)`
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: ${(props: { isBlurred: boolean }) =>
      props.isBlurred ? "rgba(0,0,0,0.75)" : "transparent"};
    -webkit-backdrop-filter: ${(props: { isBlurred: boolean }) =>
      props.isBlurred ? "blur(15px)" : "none"};
    backdrop-filter: ${(props: { isBlurred: boolean }) =>
      props.isBlurred ? "blur(15px)" : "none"};
  }
`;

const MOTION_URL = "https://assets.awaken.tax/images/motion-bg-small.webp";

function TradingLanding() {
  //search
  const search = window.location.search;
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const [isLargerThan1250] = useMediaQuery("(min-width: 1250px)");
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");

  const { pathname } = useLocation();
  const status = useSelector(getUserAuthStatus);
  const isLoggedIn = status === "LOGGED_IN";

  // nav bar
  const percentPassedSplash =
    Math.min(scrollPosition, window.innerHeight) / window.innerHeight;
  const navFill = Math.max(percentPassedSplash - 0.25, 0);

  // splash zoom
  let splashSize = isLargerThan1250
    ? "130"
    : isLargerThan900
    ? "165"
    : isLargerThan600
    ? "215"
    : "290";
  splashSize = String(
    Number(splashSize) -
      (15 - Math.min(15, scrollPosition / (window.innerHeight / 15)))
  );

  const isWhiteLabeled = isWhiteLabeledDomain();

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (status === "NOT_LOADED") {
    return null;
  }

  if (isWhiteLabeled) {
    if (isLoggedIn) {
      return <Navigate to="/dashboard" />;
    }
    return <Navigate to="/login" />;
  }

  if (isLoggedIn && pathname === "/") {
    // if path name is landing, don't redirect
    return <Navigate to="/dashboard" />;
  }

  return (
    <PageTemplateContainer bgColor={colors.white}>
      <FeedbackModal />
      <CheckoutModal isLandingPage={true} />
      <Box w="100%" h="100%" overflowY="scroll">
        {/* NAV BAR */}
        <HeaderContainer
          isBlurred={isMobile || percentPassedSplash > 0.25}
          position="fixed"
          top="0"
          width="100%"
          zIndex="100"
        >
          {/* <FreeBanner2 /> */}
          <FreeBanner />
          {/* <FreeRotatingBanner /> */}

          {/* <FreeBanner /> */}
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
            padding={isLargerThan900 ? "0.75rem 6rem" : "0.75rem 3%"}
          >
            <Box flexShrink={0}>
              <Image src={WhiteLogo} h="2.5rem" />
            </Box>
            <HStack display="flex" justifyContent={"flex-end"}>
              {!isMobile && (
                <a
                  onClick={() => _showModal("CheckoutModal")}
                  style={{ margin: "0 2rem", flex: 1 }}
                >
                  <Text color={colors.white} width="auto" fontSize="md">
                    Pricing
                  </Text>
                </a>
              )}
              {/* {!isMobile && (
                <a
                  href="https://blog.awaken.tax"
                  style={{ margin: "0 2rem", flex: 1 }}
                >
                  <Text color={colors.white} width="auto" fontSize="md">
                    Blog
                  </Text>
                </a>
              )} */}
              <Link to="/login" style={{ flex: 1 }}>
                <Text color={colors.white} width="auto" fontSize="md">
                  Sign In
                </Text>
              </Link>
              <Box w={"2rem"} />
              <Link to={_getSignupLinkWithRef()}>
                <Button
                  style={{ color: colors.black }}
                  bg={colors.white}
                  fontSize="md"
                >
                  Sign up
                </Button>
              </Link>
            </HStack>
          </Box>
        </HeaderContainer>
        <Box
          w="100%"
          height={isMobile ? "125vh" : "100vh"}
          minHeight="700px"
          position="relative"
          background="black"
          display="flex"
          flexDir={"column"}
          overflow="hidden"
        >
          <Box
            width="100%"
            height="100%"
            position="relative"
            overflow="hidden"
            display="flex"
            justifyContent={"center"}
            alignItems="center"
          >
            <Stars />
            <Image
              src={MOTION_URL}
              minW={`${splashSize}% !important`}
              h="auto"
              transform="rotateZ(-18deg)"
              filter="brightness(32%) saturate(1.2)"
              opacity="0.8"
              position="absolute"
            />
          </Box>
          <Box
            bottom="0"
            position="absolute"
            textAlign={isLargerThan900 ? "left" : "center"}
            w="100%"
          >
            <Box
              marginLeft={isLargerThan900 ? "6rem" : ""}
              margin={isLargerThan900 ? undefined : "1rem !important"}
            >
              {/* <FreeTransactionsBadge /> */}
              <FreeBadge />
            </Box>
            <Heading
              marginLeft={isLargerThan900 ? "6rem" : "2%"}
              variant={isLargerThan900 ? "xl" : "lg"}
              color={colors.white}
              w="96%"
            >
              {/* We make trading on-chain {isLargerThan900 && <br />} */}
              {/* easier than Coinbase */}
              Crypto tax software {isLargerThan900 && <br />}
              that actually works
            </Heading>
            <Container
              color={colors.white}
              marginLeft={isLargerThan900 ? "6rem" : ""}
              paddingLeft="0"
              fontSize="lg"
              paddingBottom="1rem"
              w="96%"
            >
              10,000+ crypto enjoyooors use Awaken to save dozens of{" "}
              {isLargerThan900 && <br />}
              hours and thousands of dollars on their crypto taxes.
              <br />
              <br />
              Support for Coinbase, Solana, Ethereum, Base, Bitcoin, Kraken,
              <br />
              L2s, and a ton more. Plug your wallets in and try us for free.
              {/* Using web3 is a nightmare. We started with taxes,{" "}
              {isLargerThan900 && <br />} */}
              {/* hours and thousands of dollars on their crypto taxes. */}
              {/* <br />
              And for a limited time, we are making it free to use. */}
              {/* Awaken gives
              you the crypto reports that you need to file your personal taxes.{" "}
              {isLargerThan900 && <br />} It's accurate, easy-to-use, and saves
              you hours of time. */}
            </Container>
            <JoinWaitlistButton
              white
              marginLeft={isLargerThan900 ? "6rem" : ""}
              marginTop="2rem"
            />
            <Text
              marginLeft={isLargerThan900 ? "6rem" : ""}
              fontSize="sm"
              marginTop="1rem"
              fontWeight={"medium"}
              color={colors.white}
            >
              No credit card required. Awaken supports US, AU, and most EU
              countries.{" "}
              <Info
                style={{ color: colors.white }}
                message="We support capital gain/loss reports for the US, Australia, Germany, Belgium, Austria, Finland, France, Greece, Ireland, Italy, Netherlands, and Spain. We do not support Canada or UK taxes."
              />
            </Text>
            {/* <Text
              marginLeft={isLargerThan900 ? "6rem" : ""}
              fontSize="md"
              marginTop="1rem"
              color={colors.white}
            >
              Use Awaken 100% free until March 1st
            </Text> */}
            {/* <Text
              marginLeft={isLargerThan900 ? "6rem" : ""}
              fontSize="md"
              marginTop="1rem"
              color={colors.white}
            >
              🇺🇸 Awaken only supports US taxes
            </Text> */}
            {/* <LearnMoreButton /> */}
            <Box
              w="100%"
              height={"17vh"}
              display="flex"
              justifyContent={"center"}
              alignItems="center"
            >
              <Box
                w="6rem"
                h="3rem"
                bgColor="rgba(25,25,25,0)"
                borderRadius="4rem"
                display="flex"
                justifyContent={"center"}
                alignItems="center"
                cursor="pointer"
                transition="0.3s ease-in-out"
                _hover={{
                  backgroundColor: "rgba(25,25,25,0.7)",
                }}
                onClick={() =>
                  // Note: leave a bit so that we don't cover the target when we scroll to next section
                  scroll.animateScroll.scrollTo(window.innerHeight - 60)
                }
              >
                <i
                  className="far fa-chevron-down"
                  style={{
                    color: colors.white,
                    fontSize: 27,
                  }}
                ></i>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* WHITE AREA / CONTENT */}
        <Box bgColor={colors.white}>
          <Box>
            {/* <Box maxW={MAX_WIDTH} margin="0 auto" w="96%">
              <Heading variant="lg" marginTop="0" paddingTop="4rem">
                Crypto taxes were a nightmare...
              </Heading>
              <Image src={Problem} w="96%" />
              <Heading variant="lg" paddingTop="3rem">
                So we built Awaken.
              </Heading>
            </Box> */}
            <Box maxW={MAX_WIDTH} margin="0 auto" w="96%">
              <Heading variant="lg" paddingTop="2rem">
                We're the best, but don't just take our word for it...
                {/* Here's our story: We started with crypto taxes, and built the
                best product there: */}
              </Heading>
            </Box>
            <TestimonialsScrolling
              direction={"SCROLL_RIGHT"}
              fourOrMoreImages={IMAGES1}
              height="20rem"
            />
            <TestimonialsScrolling
              direction={"SCROLL_LEFT"}
              fourOrMoreImages={IMAGES2}
              height="13rem"
              useFour
            />
            <Image
              src={Triangle}
              w="100%"
              h="5rem"
              objectFit={"cover"}
              filter="brightness(0%)"
              marginTop="2rem"
            />
            <Box
              w="100%"
              bgColor={"black"}
              padding={isLargerThan800 ? "6rem 0" : "6rem 0"}
            >
              <Heading
                variant={isLargerThan800 ? "lg" : "lg"}
                margin={"0 auto"}
                fontSize="5xl"
                w="96%"
                textAlign={"center"}
                color={colors.white}
                padding="5px"
              >
                <i
                  style={{ marginRight: 15, color: colors.yellow50 }}
                  className="fa-sharp fa-sparkles"
                />
                Upload your wallets for free
                <i
                  style={{ marginLeft: 15, color: colors.yellow50 }}
                  className="fa-sharp fa-sparkles"
                />
              </Heading>
              <Text
                margin="auto"
                maxWidth="50rem"
                textAlign={"center"}
                padding="1rem 0"
                color={colors.white}
                w="96%"
                fontSize="xl"
              >
                Upload your wallets and view your tax summary for free. You only
                pay when you want to download your tax reports. And if you have
                under 500 transactions in the tax year, we only cost $1. Let's
                bring the world onchain 🤝
                {/* Spend <strong>5 seconds</strong> signing up. Save{" "}
                <strong>5+ hours</strong> when you do your crypto taxes. */}
              </Text>
              <br />
              <Center>
                <JoinWaitlistButton overrideText="Claim" white />
              </Center>
            </Box>
            <Image
              src={Triangle2}
              w="100"
              h="5rem"
              objectFit={"cover"}
              filter="brightness(0%)"
            />
            <Grid
              templateColumns={
                isLargerThan800 ? "repeat(2, 1fr)" : "repeat(1, 2fr)"
              }
              gap={10}
              width="96%"
              maxW="65rem"
              margin="0 auto"
              // paddingTop="6rem"
            >
              {isLargerThan800 ? (
                <>
                  <LandingGridItem h="auto">
                    <Heading variant="lg" margin="0">
                      Our AI accurately handles 10,000+ dapps.
                    </Heading>
                    <Container padding="1rem 0" m="0">
                      Awaken automatically supports your liquidity providing,
                      staking, bridging, swap transactions, and many others. You
                      don't have to do anything. Awaken constantly learns and
                      improves with one goal: saving you time.
                    </Container>
                    <JoinWaitlistButton />
                  </LandingGridItem>
                  <LandingGridItem alignItems="center" justifyContent="center">
                    <Image
                      src={AppStore}
                      style={{ boxShadow: "none" }}
                      objectFit="contain"
                      maxH="75%"
                    ></Image>
                  </LandingGridItem>
                </>
              ) : (
                <>
                  <LandingGridItem alignItems="center" justifyContent="center">
                    <Image
                      src={AppStore}
                      objectFit="contain"
                      maxH="75%"
                    ></Image>
                  </LandingGridItem>
                  <LandingGridItem h="auto">
                    <Heading variant="lg" margin="0">
                      Our AI accurately handles 10,000+ dapps.
                    </Heading>
                    <Container padding="1rem 0" m="0">
                      Awaken's software automatically supports your liquidity
                      providing, staking, bridging, swap transactions, and many
                      others. You don't have to do anything. Awaken constantly
                      learns and improves with one goal: saving you time.
                      {/*Sit back and relax. ⛱*/}
                    </Container>
                    <JoinWaitlistButton />
                  </LandingGridItem>
                </>
              )}
              <LandingGridItem alignItems="center">
                <RulesAnimation />
              </LandingGridItem>
              <LandingGridItem h="auto">
                <Heading variant="lg" margin="0">
                  Do your crypto taxes in less than half the time.
                </Heading>
                <Container padding="1rem 0" m="0">
                  Our smart software automatically learns as you label your
                  transactions, saving you hours of time.
                </Container>
                <JoinWaitlistButton />
              </LandingGridItem>
              {isLargerThan800 ? (
                <>
                  <LandingGridItem h="auto">
                    <Heading variant="lg" margin="0">
                      Generate the U.S. crypto tax reports you need.
                    </Heading>
                    <Container padding="1rem 0" m="0">
                      Awaken generates the crypto tax reports that U.S. citizens
                      need for TurboTax, your CPA, or whatever tax software
                      you’re using.
                    </Container>
                    <JoinWaitlistButton />
                  </LandingGridItem>
                  <LandingGridItem alignItems="center" justifyContent="center">
                    <Image src={Forms} objectFit="contain" maxH="75%"></Image>
                  </LandingGridItem>
                </>
              ) : (
                <>
                  <LandingGridItem alignItems="center" justifyContent="center">
                    <Image src={Forms} objectFit="contain" maxH="75%"></Image>
                  </LandingGridItem>
                  <LandingGridItem h="auto">
                    <Heading variant="lg" margin="0">
                      Generate the U.S. crypto tax reports you need.
                    </Heading>
                    <Container padding="1rem 0" m="0">
                      Awaken generates the crypto tax reports that U.S. citizens
                      need for TurboTax, your CPA, or whatever tax software
                      you’re using.
                      {/*Sit back and relax. ⛱*/}
                    </Container>
                    <JoinWaitlistButton />
                  </LandingGridItem>
                </>
              )}
            </Grid>
            {/* <Box maxW={MAX_WIDTH} margin="0 auto" w="96%">
              <Warning
                iconName="fa-sharp fa-info-circle"
                bg={colors.lightBlue100}
                borderColor={colors.primary}
                iconStyle={{ color: colors.primary }}
                marginTop="2rem"
                message={
                  <>
                    Already paid for a crypto tax product? We'll match your
                    payment with free credits on our software (up to $100). Just
                    email{" "}
                    <a
                      style={{ fontWeight: "bold", color: colors.primary }}
                      href="mailto:team@awaken.tax"
                    >
                      team@awaken.tax
                    </a>{" "}
                    with proof.
                  </>
                }
              />
              <Heading variant="lg" marginTop="0" paddingTop="3rem">
                Stop paying the wrong amount for your crypto taxes.
              </Heading>
              <BullseyeAnimation />
              <Text>
                Other products almost always miscalculate your required crypto
                tax payment. Paying the IRS less than required is illegal, and
                paying more is like burning your money.
              </Text>
            </Box> */}
            <ComparisonTable />
            <Box w="96%" margin="auto" maxW="65rem" marginTop={"10rem"}>
              <Heading
                variant="lg"
                textAlign={"center"}
                paddingBottom="0"
                marginBottom="0"
              >
                Want to watch a sneak peak of Awaken?
              </Heading>
              <Text textAlign={"center"} fontSize="lg" marginTop="1rem">
                See your transactions and tax summary for free. Only pay when
                it's time to download your tax reports.
              </Text>
              {/* <Image src={Demo} w="100%" /> */}

              <iframe
                src="https://www.loom.com/embed/bb35ee7f50ba4d01a7a6326ba2b49fa2?hideEmbedTopBar=true"
                frameBorder="0"
                allowFullScreen
                style={{
                  margin: "2rem auto",
                  borderRadius: 15,
                  maxWidth: 750,
                  width: "100%",
                  height: 500,
                }}
              />
            </Box>
            <Box w="96%" margin="auto" marginTop="4rem">
              <Heading
                variant="md"
                textAlign={"center"}
                paddingTop="0"
                marginTop="0"
              >
                Automated support for tons of chains/exchanges
              </Heading>
              <AutomatedChains />
              <Heading variant="md" textAlign={"center"}>
                Spreadsheet upload support for 100+ more
              </Heading>
              <SpreadsheetChains />
            </Box>
            <Box
              w="100%"
              bg={`linear-gradient(to bottom, ${colors.black}, ${colors.gray20})`}
              padding={isLargerThan800 ? "6rem 0" : "6rem 0"}
            >
              <Heading
                variant={isLargerThan800 ? "lg" : "lg"}
                margin={"0 auto"}
                fontSize="5xl"
                w="96%"
                textAlign={"center"}
                color={colors.white}
                padding="5px"
              >
                <i
                  style={{ marginRight: 15, color: colors.primary }}
                  className="fa-sharp fa-shield-halved"
                />
                Security is our priority
                <i
                  style={{ marginLeft: 15, color: colors.primary }}
                  className="fa-sharp fa-shield-halved"
                />
              </Heading>

              <br />

              <a href="https://app.getdelve.com/awaken-tax" target="_blank">
                <img
                  onClick={() => {
                    const logBadgeClick = (window as any).logBadgeClick;
                    if (logBadgeClick) logBadgeClick();
                  }}
                  id="badge-button"
                  style={{
                    width: 100,
                    margin: "auto",
                    height: "auto",
                    cursor: "pointer",
                  }}
                  src={require("src/assets/soc.png")}
                  alt="SOC 2"
                />
              </a>

              <br />

              <Text
                margin="auto"
                maxWidth="50rem"
                textAlign={"center"}
                padding="1rem 0"
                color={colors.white}
                w="96%"
                fontSize="xl"
              >
                Awaken is{" "}
                <a
                  style={{
                    fontWeight: "bold",
                    color: colors.white,
                    textDecoration: "underline",
                  }}
                  target="_blank"
                  href="https://app.getdelve.com/awaken-tax"
                >
                  SOC 2
                </a>{" "}
                compliant and follows security best practices. We encrypt all
                data using AES-256 (the same encryption banks use) and any
                in-transit data is sent via TLS 1.2. We work with{" "}
                <a
                  style={{
                    fontWeight: "bold",
                    color: colors.white,
                    textDecoration: "underline",
                  }}
                  target="_blank"
                  href="https://doppel.com"
                >
                  Doppel
                </a>{" "}
                to protect against phishing and impersonators. If you have any
                questions, just email team@awaken.tax.
              </Text>
            </Box>
            <Box w="100%" bgColor={colors.gray1}>
              <Box
                w="94%"
                maxW={MAX_WIDTH}
                margin="auto"
                padding="4rem 0"
                display="flex"
                alignItems="center"
                flexDir="column"
              >
                <Heading
                  variant="md"
                  textAlign="center"
                  marginTop="0"
                  paddingTop="0"
                >
                  A personal note from our founders
                </Heading>
                <Container w="100%" padding="0" margin="0">
                  "Last tax season, we paid hundreds of dollars for crypto tax
                  products that didn't work. Many of our colleagues were facing
                  the same nightmare, so we set out to build the best tax
                  software for web3. Awaken doesn't support every chain yet, but
                  we can promise you that we are committed to serving every user
                  of the web3 community to the best of our ability."
                  <br />
                </Container>
                <Container padding="0" margin="0" paddingTop="1rem">
                  – Yash & Andrew
                </Container>
                <br />
                <ButtonGroup>
                  <JoinWaitlistButton />
                  <a
                    href="mailto:team@awaken.tax"
                    rel="noreferrer"
                    target={isMobile ? undefined : "_blank"}
                    style={{
                      color: colors.black,
                    }}
                  >
                    <Button variant="secondary">Contact Us</Button>
                  </a>
                </ButtonGroup>
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </PageTemplateContainer>
  );
}

const FreeBadge = () => {
  const search = window.location.search;
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");

  return (
    <Link to={_getSignupLinkWithRef()}>
      <Box
        style={{
          padding: isLargerThan900 ? "0.6rem 1rem" : "0.5rem 1rem",
          display: "inline-block",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <motion.div
          animate={{ opacity: 0.25 }}
          transition={{
            repeat: Infinity,
            duration: 1.5,
            repeatType: "reverse",
          }}
          style={{
            backgroundColor: colors.gray10,
            position: "absolute",
            top: 0,
            borderRadius: 50,
            opacity: 0.9,
            right: 0,
            border: "1px solid " + colors.white,
            left: 0,
            zIndex: 1,
            bottom: 0,
          }}
        />

        <Text
          style={{
            zIndex: 2,
            position: "relative",
            color: colors.white,
            fontWeight: "600",
          }}
        >
          <i
            style={{ color: "#97CDFA", marginRight: 5 }}
            className="fa-sharp fa-rocket-launch"
          />{" "}
          The bull market is back! If you have{" "}
          <span
            style={{
              fontWeight: "bold",
              color: "#A1F4BC",
              textDecoration: "underline",
            }}
          >
            &le; 500 txns
          </span>
          , we only cost $1{" "}
          <Info
            style={{ color: colors.white }}
            message="We love the web3 ecosystem, and anyone just coming onchain for the first time shouldn't have to spend $100+ on tax software! So if you have done less than 500 transactions in the year (2024), you get reports for $1."
          />
        </Text>
      </Box>
    </Link>
  );
};

const FreeTransactionsBadge = () => {
  const search = window.location.search;
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");

  return null;

  return (
    <Link to={_getSignupLinkWithRef()}>
      <Box
        style={{
          padding: isLargerThan900 ? "0.6rem 2rem" : "0.5rem 1rem",
          display: "inline-block",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <motion.div
          animate={{ opacity: 0.45 }}
          transition={{
            repeat: Infinity,
            duration: 1.5,
            repeatType: "reverse",
          }}
          style={{
            backgroundColor: colors.gray10,
            position: "absolute",
            top: 0,
            borderRadius: 50,
            opacity: 0.9,
            right: 0,
            border: "1px solid " + colors.white,
            left: 0,
            zIndex: 1,
            bottom: 0,
          }}
        />

        <Text
          style={{
            zIndex: 2,
            position: "relative",
            color: colors.white,
            fontWeight: "600",
          }}
        >
          <i
            style={{ color: colors.yellow50, marginRight: 5 }}
            className="fa-sharp fa-sparkles"
          />{" "}
          Upload your wallets for <b>FREE</b>{" "}
          <i style={{ marginLeft: 5 }} className="fa-sharp fa-arrow-right" />
        </Text>
      </Box>
    </Link>
  );
};

const _getSignupLinkWithRef = () => {
  const search = window.location.search;
  return "/signup" + search || "";
};

function JoinWaitlistButton({
  white,
  overrideText,
  ...props
}: ButtonProps & { white?: boolean; overrideText?: string }) {
  const search = window.location.search;

  return (
    <Link to={_getSignupLinkWithRef()}>
      <motion.div>
        <Button
          variant="primary"
          bgColor={white ? colors.white : colors.black}
          _hover={{
            backgroundColor: white ? colors.white : colors.black,
          }}
          color={white ? colors.black : colors.white}
          {...props}
        >
          {overrideText || "Get Started"}{" "}
          <i style={{ marginLeft: 10 }} className="fa-sharp fa-arrow-right" />
        </Button>
      </motion.div>
    </Link>
  );
}

type LandingGridItemProps = BoxProps & {
  children: any;
  h?: string;
};

function LandingGridItem({ children, h, ...rest }: LandingGridItemProps) {
  return (
    <GridItem
      w="100%"
      h={h || "27rem"}
      display="flex"
      flexDir={"column"}
      justifyContent="center"
      {...rest}
    >
      {children}
    </GridItem>
  );
}

type AnimationsData = {
  desktop: any;
  mobile: any;
};

const BullseyeAnimation = () => {
  const [animationData, setAnimationData]: any = useState(null);
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  const [inView, setInView] = useState(false);

  // load animation data
  useEffect(() => {
    Promise.all([
      import("src/assets/awaken/landing/bullseye-desktop.json"),
      import("src/assets/awaken/landing/bullseye-mobile.json"),
    ]).then(([desktop, mobile]) =>
      setAnimationData({
        desktop,
        mobile,
      })
    );
  }, []);

  useEffect(() => {
    function handleScroll(e: any) {
      const _inView = isElementInViewport(
        document.getElementById("bullseye-animation")
      );
      setInView(_inView || inView); // once the animation stops, never pause it (i.e. play={false} never again)
    }
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [inView]);

  return (
    <Lottie
      id="bullseye-animation"
      animationData={
        animationData
          ? isLargerThan900
            ? animationData.desktop
            : animationData.mobile
          : undefined
      }
      play={inView}
      loop={false}
      style={{
        width: "100%",
        maxWidth: "35rem",
        margin: "auto",
        padding: "2rem 0",
      }}
    />
  );
};

const RulesAnimation = () => {
  const [animationData, setAnimationData]: any = useState(undefined);

  useEffect(() => {
    import("src/assets/awaken/landing/rules.json").then(setAnimationData);
  }, []);

  return (
    <Lottie
      animationData={animationData}
      loop
      speed={1.2}
      play
      style={{
        objectFit: "contain",
        maxHeight: "23rem",
        width: "100%",
      }}
    />
  );
};

// const FormsButton = () => (
//   <Link to="/forms">
//     <Button
//       maxWidth={BUTTON_MAX_WIDTH}
//       variant="primary"
//       bgColor={colors.black}
//       color={colors.white}
//       _hover={{
//         backgroundColor: colors.black,
//       }}
//     >
//       See reports
//     </Button>
//   </Link>
// );

function Footer() {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const _onClickContact = () => _showModal("FeedbackModal");
  const search = window.location.search;

  return (
    <Box bgColor={colors.black} padding="3rem 0">
      <Box
        w="95%"
        maxW="60rem"
        margin="auto"
        display="flex"
        alignItems={isLargerThan800 ? "flex-start" : "center"}
        justifyContent="center"
        flexDir={isLargerThan800 ? "row" : "column"}
      >
        <Box w={isLargerThan800 ? "20rem" : ""}>
          <Image
            src={WhiteLogo}
            w="8rem"
            objectFit="contain"
            margin={isLargerThan800 ? "" : "auto"}
          />
          <SecondaryText
            w={isLargerThan800 ? "90%" : "100%"}
            color={colors.gray3}
            text="The first tax software built for web3."
            margin={isLargerThan800 ? "" : "auto"}
          />
          <div style={{ marginTop: 25 }}>
            <a href="https://app.getdelve.com/awaken-tax" target="_blank">
              <img
                onClick={() => {
                  const logBadgeClick = (window as any).logBadgeClick;
                  if (logBadgeClick) logBadgeClick();
                }}
                id="badge-button"
                style={{
                  width: 50,
                  height: "auto",
                  cursor: "pointer",
                }}
                src={require("src/assets/soc.png")}
                alt="SOC 2"
              />
            </a>
          </div>
          <br />
        </Box>
        <Box display="flex" flexWrap="wrap" justifyContent={"center"}>
          <Box w="10rem" margin="0 1rem" marginBottom="1rem">
            <Text color={colors.white} margin="0.5rem">
              <strong>Legal</strong>
            </Text>
            <Link to="/legal/disclaimer">
              <Text color={colors.gray3} margin="0.5rem">
                Disclaimer
              </Text>
            </Link>
            <Link to="/legal/privacy">
              <Text color={colors.gray3} margin="0.5rem">
                Privacy
              </Text>
            </Link>
            <Link to="/legal/terms-of-service">
              <Text color={colors.gray3} margin="0.5rem">
                Terms of Service
              </Text>
            </Link>
            <br />
          </Box>
          <Box w="8rem" margin="0 1rem" marginBottom="1rem">
            <Text color={colors.white} margin="0.5rem">
              <strong>Product</strong>
            </Text>
            <Link to="">
              <Text
                color={colors.gray3}
                margin="0.5rem"
                onClick={() => _showModal("CheckoutModal")}
              >
                Pricing
              </Text>
            </Link>
            {/* <a
              href="https://blog.awaken.tax"
              rel="noreferrer"
              target={isMobile ? undefined : "_blank"}
            >
              <Text color={colors.gray3} margin="0.5rem">
                Blog
              </Text>
            </a> */}
            <Link to={_getSignupLinkWithRef()}>
              <Text color={colors.gray3} margin="0.5rem">
                Sign Up
              </Text>
            </Link>
            <br />
          </Box>
          <Box w="10rem" margin="0 1rem" marginBottom="1rem">
            <Text color={colors.white} margin="0.5rem">
              <strong>Support</strong>
            </Text>

            <a
              href="https://accrue.notion.site/0fc8ed341d794d7b94aaff105332a396?v=d0c67e2ed25e49878b35fc79271f6e8d&pvs=74"
              rel="noreferrer"
              target={isMobile ? undefined : "_blank"}
              style={{
                color: colors.black,
              }}
            >
              <Text color={colors.gray3} margin="0.5rem">
                Support Center
              </Text>
            </a>

            <Text
              color={colors.gray3}
              margin="0.5rem"
              onClick={_onClickContact}
              cursor="pointer"
            >
              Leave Feedback
            </Text>
            <a
              href="mailto:team@awaken.tax"
              rel="noreferrer"
              target={isMobile ? undefined : "_blank"}
              style={{
                color: colors.black,
              }}
            >
              <Text color={colors.gray3} margin="0.5rem">
                Contact Us
              </Text>
            </a>
            <br />
          </Box>
        </Box>
      </Box>
      <Box paddingTop="2rem">
        <Box display="flex" justifyContent={"center"}>
          {/* <Box>
            <ChakraLink
              display="block"
              rel="noreferrer"
              target={isMobile ? undefined : "_blank"}
              href={config.discordLink}
              style={{
                margin: "0 1rem",
              }}
            >
              <i
                style={{
                  color: "white",
                  fontSize: 30,
                }}
                className="fab fa-discord"
              ></i>
            </ChakraLink>
          </Box> */}

          <Box>
            <ChakraLink
              display="block"
              rel="noreferrer"
              target={isMobile ? undefined : "_blank"}
              href={`https://twitter.com/AwakenTax`}
              style={{
                margin: "0 1rem",
              }}
            >
              <i
                style={{
                  color: "white",
                  fontSize: 30,
                }}
                className="fab fa-twitter"
              ></i>
            </ChakraLink>
          </Box>

          <Box>
            <ChakraLink
              display="block"
              rel="noreferrer"
              target={isMobile ? undefined : "_blank"}
              href="mailto:team@awaken.tax"
              style={{
                margin: "0 1rem",
              }}
            >
              <i
                style={{
                  color: "white",
                  fontSize: 30,
                }}
                className="fa-sharp fa-envelope"
              ></i>
              {/* <Image
                width="100%"
                height="100%"
                objectFit="contain"
                objectPosition={"center"}
                src={require(`src/assets/logos/socials/twitter.png`)}
              /> */}
            </ChakraLink>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const Chain = ({
  src,
  name,
  comingSoon,
  imgProps,
}: BoxProps & {
  src: any;
  name: string;
  comingSoon?: string;
  imgProps?: ImageProps;
}) => {
  const h = "4rem";
  return (
    <Box padding="0 1.5rem" marginBottom="2rem">
      <Image
        src={src}
        w={h}
        minW={h}
        h={h}
        objectFit="contain"
        objectPosition="top"
        margin="auto"
        {...imgProps}
      />
      <br />
      <Text fontWeight="semibold" textAlign="center">
        {name}
      </Text>
      <Text color={colors.yellow} textAlign="center" fontSize="12">
        {comingSoon && (
          <>
            Coming soon!
            <br />({comingSoon})
          </>
        )}
      </Text>
    </Box>
  );
};

const circularLogo = {
  style: { borderRadius: "100%", objectFit: "cover" } as CSSProperties,
};

const AutomatedChains = () => {
  return (
    <Box
      padding="2rem 0"
      margin="auto"
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      overflowX="scroll"
      overflowY="hidden"
      flexWrap="wrap"
      maxWidth="60rem"
    >
      <Chain src={Ethereum} name="Ethereum" />
      <Chain src={Polygon} name="Polygon" />

      <Chain src={Avalanche} name="Avalanche" />
      <Chain src={"https://assets.awaken.tax/icons/bnb.png"} name="BNB Chain" />
      <Chain src={Optimism} name="Optimism" />
      <Chain src={Arbitrum} name="Arbitrum" />
      <Chain src={Solana} name="Solana" />
      <Chain src="https://assets.awaken.tax/icons/base.png" name="Base" />
      <Chain src="https://assets.awaken.tax/icons/btc.png" name="Bitcoin" />
      <Chain src="https://assets.awaken.tax/icons/fantom.png" name="Fantom" />
      <Chain src={Coinbase} name="Coinbase" />
      <Chain
        src="https://assets.awaken.tax/icons/kraken.png"
        imgProps={circularLogo}
        name="Kraken"
      />
      <Chain
        src={"https://assets.awaken.tax/icons/binance-us.png"}
        imgProps={{ style: { borderRadius: "50%" } }}
        name="Binance US"
      />
      <Chain
        src={"https://assets.awaken.tax/icons/binance.png"}
        imgProps={{ style: { borderRadius: "50%" } }}
        name="Binance"
      />
      <Chain
        src={"https://assets.awaken.tax/icons/gemini.png"}
        imgProps={{ style: { borderRadius: "50%" } }}
        name="Gemini"
      />

      <Chain
        src="https://assets.awaken.tax/icons/pulsechain.jpeg"
        imgProps={circularLogo}
        name="PulseChain"
      />

      <Chain
        src="https://assets.awaken.tax/icons/apechain.png"
        imgProps={circularLogo}
        name="Apechain"
      />

      <Chain
        src="https://assets.awaken.tax/icons/blast.png"
        imgProps={circularLogo}
        name="Blast"
      />

      <Chain
        src="https://assets.awaken.tax/icons/sui.png"
        imgProps={circularLogo}
        name="Sui"
      />

      <Chain
        src="https://assets.awaken.tax/icons/unichain.png"
        imgProps={circularLogo}
        name="Unichain"
      />
    </Box>
  );
};

const SpreadsheetChains = () => {
  return (
    <Box
      padding="2rem 0"
      margin="auto"
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      overflowX="scroll"
      overflowY="hidden"
      flexWrap="wrap"
      maxW="60rem"
    >
      {/* <Chain src={FTX} name="FTX" /> */}
      <Chain
        src="https://assets.awaken.tax/icons/xrp.png"
        imgProps={circularLogo}
        name="Ripple"
      />
      <Chain
        src="https://assets.awaken.tax/icons/hype.png"
        imgProps={circularLogo}
        name="Hyperledger"
      />
      <Chain
        src="https://assets.awaken.tax/icons/sei.png"
        imgProps={circularLogo}
        name="Sei"
      />
      <Chain
        src="https://assets.awaken.tax/icons/bitfinex.png"
        imgProps={circularLogo}
        name="Bitfinex"
      />
      <Chain
        src="https://assets.awaken.tax/icons/gate-io.webp"
        imgProps={circularLogo}
        name="Gate.io"
      />
      <Chain
        src="https://assets.awaken.tax/icons/uphold.jpeg"
        imgProps={circularLogo}
        name="Uphold"
      />
      <Chain
        src="https://assets.awaken.tax/icons/robinhood.png"
        imgProps={circularLogo}
        name="Robinhood"
      />
      <Chain
        src="https://assets.awaken.tax/icons/canto.png"
        imgProps={circularLogo}
        name="Canto"
      />

      <Chain
        src="https://assets.awaken.tax/icons/zksync.svg"
        imgProps={circularLogo}
        name="zkSync"
      />
      <Chain
        src="https://assets.awaken.tax/icons/bitmex.png"
        imgProps={circularLogo}
        name="BitMEX"
      />
      <Chain
        src="https://assets.awaken.tax/icons/crypto-com.png"
        imgProps={circularLogo}
        name="Crypto.com"
      />
      <Chain
        src={"https://assets.awaken.tax/icons/kucoin.png"}
        imgProps={{ style: { borderRadius: "50%" } }}
        name="Kucoin"
      />
      <Chain
        src={"https://assets.awaken.tax/icons/osmosis.png"}
        imgProps={{ style: { borderRadius: "50%" } }}
        name="Osmosis"
      />
      <Chain
        src={"https://assets.awaken.tax/icons/celestia.png"}
        imgProps={{ style: { borderRadius: "50%" } }}
        name="Celestia"
      />

      <Chain src={Plus} name="100+ more" />
    </Box>
  );
};

const features = [
  "", // leave in here for logo
  "Swaps - Coins",
  "Swaps - NFTs",
  "Wallet Transfers - Coins",
  "Wallet Transfers - NFTs",
  "Staking",
  "Protocol Support (web3)",
  "Loans",
  "Memecoins",
];
const awakenInfo = [AwakenLogo, "✅", "✅", "✅", "✅", "✅", "✅", "✅", "✅"];
const koinlyInfo = [Koinly, "✅", "✅", "✅", "✅", "❌", "❌", "❌", "✅"];
const cointrackerInfo = [
  CoinTracker,
  "✅",
  "✅",
  "✅",
  "✅",
  "❌",
  "❌",
  "❌",
  "❌",
];
const zenledgerInfo = [
  ZenLedger,
  "✅",
  "✅",
  "✅",
  "❌",
  "❌",
  "❌",
  "❌",
  "❌",
  "❌",
  "❌",
];
const keys = Array.from(Array(features.length).keys());
const info = keys.map((key) => [
  awakenInfo[key],
  koinlyInfo[key],
  cointrackerInfo[key],
  zenledgerInfo[key],
]);
const messages = [
  "",
  "Most crypto tax solutions were built 5-7 years ago, when people were just buying and selling tokens. That's why most of them accurately handle standard swaps but break when they touch defi. ZenLedger often doesn't import all of your exchange transactions, so be careful. ✅",
  "Awaken has custom support for OpenSea, Blur.io, LooksRare, NFT mints, NFT burns, and more. ✅",
  "Awaken has the best user interface for transfers between your own wallets, but all other crypto tax products support these transfers too. 🤝",
  "Awaken, CoinTracker, and Koinly properly handle the NFT transfers between your own wallets. 🤝",
  "ZenLedger, CoinTracker, and Koinly incorrectly treat staking as a sale and reset your purchase price. They make you pay full cap gains tax when you often shouldn't be. 🥩",
  "Awaken is the only crypto tax product with custom support for protocols, including Uniswap V3, X2Y2, and 100+ others. 🦄",
  "Awaken launched support for loans in April of 2023, becoming the first product in the industry to support this transaction type.",
  "Awaken supports all memecoins you trade, whether it is on Base, Solana (pump.fun or other), etc...",
];

const ComparisonTable = () => {
  return (
    <TableContainer marginTop="3rem" w="95%" marginLeft="2.5%">
      <Table margin="auto" padding="2%" maxW="40rem">
        <Thead>
          <Tr>
            <Th>Feature</Th>
            {info[0].map((logo) => (
              <Th>
                <Center>
                  <Competitor src={logo} />
                </Center>
              </Th>
            ))}
            <Th>
              <Center>Info</Center>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <Text>{features[1]}</Text>
            </Td>
            {info[1].map((cell) => (
              <Td>
                <Center>
                  <Text>{cell}</Text>
                </Center>
              </Td>
            ))}
            <Td>
              <AwakenTooltip message={messages[1]}>
                <Center>
                  <Text>
                    <Info message="" style={{ padding: "0 1.5rem" }}></Info>
                  </Text>
                </Center>
              </AwakenTooltip>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text>{features[2]}</Text>
            </Td>
            {info[2].map((cell) => (
              <Td>
                <Center>
                  <Text>{cell}</Text>
                </Center>
              </Td>
            ))}
            <Td>
              <AwakenTooltip message={messages[2]}>
                <Center>
                  <Text>
                    <Info message="" style={{ padding: "0 1.5rem" }}></Info>
                  </Text>
                </Center>
              </AwakenTooltip>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text>{features[3]}</Text>
            </Td>
            {info[3].map((cell) => (
              <Td>
                <Center>
                  <Text>{cell}</Text>
                </Center>
              </Td>
            ))}
            <Td>
              <AwakenTooltip message={messages[3]}>
                <Center>
                  <Text>
                    <Info message="" style={{ padding: "0 1.5rem" }}></Info>
                  </Text>
                </Center>
              </AwakenTooltip>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text>{features[4]}</Text>
            </Td>
            {info[4].map((cell) => (
              <Td>
                <Center>
                  <Text>{cell}</Text>
                </Center>
              </Td>
            ))}
            <Td>
              <AwakenTooltip message={messages[4]}>
                <Center>
                  <Text>
                    <Info message="" style={{ padding: "0 1.5rem" }}></Info>
                  </Text>
                </Center>
              </AwakenTooltip>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text>{features[5]}</Text>
            </Td>
            {info[5].map((cell) => (
              <Td>
                <Center>
                  <Text>{cell}</Text>
                </Center>
              </Td>
            ))}
            <Td>
              <AwakenTooltip message={messages[5]}>
                <Center>
                  <Text>
                    <Info message="" style={{ padding: "0 1.5rem" }}></Info>
                  </Text>
                </Center>
              </AwakenTooltip>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text>{features[6]}</Text>
            </Td>
            {info[6].map((cell) => (
              <Td>
                <Center>
                  <Text>{cell}</Text>
                </Center>
              </Td>
            ))}
            <Td>
              <AwakenTooltip message={messages[6]}>
                <Center>
                  <Text>
                    <Info message="" style={{ padding: "0 1.5rem" }}></Info>
                  </Text>
                </Center>
              </AwakenTooltip>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text>{features[7]}</Text>
            </Td>
            {info[7].map((cell) => (
              <Td>
                <Center>
                  <Text>{cell}</Text>
                </Center>
              </Td>
            ))}
            <Td>
              <AwakenTooltip message={messages[7]}>
                <Center>
                  <Text>
                    <Info message="" style={{ padding: "0 1.5rem" }}></Info>
                  </Text>
                </Center>
              </AwakenTooltip>
            </Td>
          </Tr>

          <Tr>
            <Td>
              <Text>{features[8]}</Text>
            </Td>
            {info[8].map((cell) => (
              <Td>
                <Center>
                  <Text>{cell}</Text>
                </Center>
              </Td>
            ))}
            <Td>
              <AwakenTooltip message={messages[8]}>
                <Center>
                  <Text>
                    <Info message="" style={{ padding: "0 1.5rem" }}></Info>
                  </Text>
                </Center>
              </AwakenTooltip>
            </Td>
          </Tr>

          {/* More: Unpopular coins, Lending, Bridging
            Unpopular coins: "Say you bought $100,000 of XYZ, which has no public price data. Other solutions treat your purchase price as $0. This means that they make you pay taxes on $100,000 of gains you shouldn't be. Awaken makes sure to set your purchase price to $100,000 📈
          */}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const Competitor = ({ src }: { src: string }) => (
  <Image src={src} w="7rem" minW="6rem" h="2rem" objectFit="contain" />
);

const FreeBanner2 = () => {
  return (
    <div
      className="free-banner"
      style={{
        width: "100%",
        position: "relative",
        backgroundColor: colors.yellow40,
      }}
    >
      Download your tax reports for free until March 1st.
    </div>
  );
};

const FreeRotatingBanner = () => {
  const freeArray = Array(50).fill(null); // Adjust the number to fill the banner width

  return (
    <div
      className="free-banner"
      style={{
        width: "120%",
        position: "relative",
        left: "-10%",
        backgroundColor: colors.yellow40,
      }}
    >
      {freeArray.map((_, index) => (
        <span
          style={{
            color: colors.white,
            fontSize: 16,
            fontFamily: "Mona Sans",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
          key={index}
          className="free-text"
        >
          FREE
        </span>
      ))}
    </div>
  );
};

export default TradingLanding;
