import { Box, Heading, Text } from "@chakra-ui/react";
import { NavBarPageTemplate } from "src/components";
import { colors } from "src/theme";

function Disclaimer() {
  return (
    <NavBarPageTemplate bgColor={colors.white}>
      <Box w="96%" maxW="50rem" margin="4rem auto">
        <Heading variant="lg">Disclaimer</Heading>
        <Text>
          This website disclaimer (“Disclaimer”) is written for all users of
          Awaken, including any additional websites and mobile applications now
          in use or hereinafter developed (collectively “Awaken”).
        </Text>
        <br />
        <Text>
          The parties referred to in this Disclaimer shall be defined as
          follows:
        </Text>
        <br />
        <Text marginLeft="1rem">
          <ol>
            <li>
              Accrue Finance, us, we: The Company, as the creator, operator, and
              publisher of Awaken, makes Awaken, and certain Services on it,
              available to users. The Company, us, we, our, ours and other
              first-person pronouns will refer to the Company, as well as all
              employees and affiliates of the Company.
            </li>
            <li>
              You, the User, the Client: You, as the user of Awaken, will be
              referred to throughout this Disclaimer with second-person pronouns
              such as you, your, yours, or as user or client.
            </li>
            <li>
              Parties: Collectively, the parties to this Disclaimer (the Company
              and You) will be referred to as Parties.
            </li>
            <li>
              Services: Any combination of the products and services for sale on
              Awaken.
            </li>
          </ol>
        </Text>
        <br />
        <Text>
          By continuing to use this website, you assent to each term and section
          contained in this Disclaimer.
        </Text>
        <br />
        <Text>
          Through your use of Awaken, you acknowledge and agree that information
          provided by the Company is simply that, information and, and should
          not be considered a substitute for legal advice, tax advice, audit
          advice, accounting advice, or brokerage advice under the guidance of a
          licensed professional. Further, the information provided herein should
          not be taken as financial planning or investment solicitation. No
          fiduciary relationship has been created between you and the Company.
        </Text>
        <br />
        <Text>
          You hereby understand and acknowledge that through your use of Awaken,
          you are not being represented by an attorney, certified financial
          planner, broker, or other regulated advisor. Certain of the Awaken
          Services may provide access to such a professional, through a referral
          service by which Awaken receives affiliation fees, or provide
          tax-related information, but if you are in need of legal or financial
          advice, including a review of any financial or tax decisions, we
          advise you to consult the appropriate advisor, such as your own
          attorney, accountant, or other professional. Bookkeeping services
          provided by Awaken also do not constitute as tax advice. You and only
          you are responsible for any tax liabilities or inaccuracies that arise
          from bookkeeping services even if they are provided by Awaken
          directly. We will update you accordingly about transactions and
          discuss transactions that need further clarification. If you do not
          report certain crypto income or provide all data, we are not
          responsible for any inaccuracies that arise from it.
        </Text>
        <br />
        <Text>
          You agree that your use of Awaken and Services is at your sole and
          exclusive risk and that any Services provided by the Company are on an
          “as is” basis. The Company hereby expressly disclaims any and all
          express or implied warranties of any kind, including, but not limited
          to the implied warranty of fitness for a particular purpose and the
          implied warranty of merchantability. The Company makes no warranties
          that Awaken or the Services will meet your needs or that Awaken or
          Services will be uninterrupted or error-free. We endeavor to keep
          Awaken and the Services as secure as possible but you hereby
          acknowledge that no system involving the transmission of information
          via the Internet, or the electronic storage of data, is completely
          secure. We are not liable for any loss, theft, unauthorized access,
          disclosure, copying, use, or modification of your personal data that
          occurs outside our reasonable control. The Company also makes no
          warranties as to the reliability or accuracy, completeness, or quality
          of any information on Awaken or obtained through the Services. you
          agree that the Company is not liable for any errors, omissions, loss
          or damage which may be caused by your use of Awaken or Services, to
          the fullest extent permitted by law. Any damage that may occur to You,
          through your computer system, or as a result of loss of your data from
          your use of Awaken or Services is your sole responsibility.
        </Text>
        <br />
        <Text>
          Documents, information, or other Services received on or through
          Awaken may not be appropriate for your particular situation, the
          assessment of which is your sole and exclusive responsibility.
        </Text>
        <br />
        <Text>
          Awaken makes no assurances to any particular outcome based on your use
          of Awaken or any of the Services, including business or financial
          outcomes. If you choose to purchase Awaken’s automated investment
          Services, you acknowledge and agree that these Services may not be
          suitable for every investor and that you should always undertake to
          make your own investment decisions. You further agree that any
          projected returns shown through Awaken’s Services may not be
          indicative of real-world results and that your investment returns or
          financial gains are not guaranteed in any way. Any representations of
          historical performance or projected performance are not warranted to
          be accurate or error-free.
        </Text>
        <br />
        <Text>
          The maximum liability of the Company arising from or relating to your
          use of Awaken or Services is limited to the greater of one hundred
          ($100) US Dollars or the amount you paid to the Company in the last
          three (3) months. This applies to any and all claims by you,
          including, but not limited to, lost profits or revenues, consequential
          or punitive damages, negligence, strict liability, fraud, or torts of
          any kind.
        </Text>
        <br />
      </Box>
    </NavBarPageTemplate>
  );
}

export default Disclaimer;
