import {
  Heading,
  Table,
  Text,
  HStack,
  Box,
  Image,
  Td,
  ButtonGroup,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Link,
  PopoverArrow,
  Tooltip,
} from "@chakra-ui/react";
import {
  BaseAccountFields,
  BaseAccountWithCurrentJobFields,
  BaseAssetFields,
  BaseUserFields,
} from "src/api/fragments";
import {
  useLocation,
  useNavigate,
  useParams,
  Link as ReactRouterLink,
} from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import {
  AssetAccountBreakdown,
  AssetTypeEnum,
  AssetWarning,
  CurrencyCodeEnum,
  PortfolioBalance,
  PortfolioResponse,
} from "src/api/generated/types";
import _, {
  Dictionary,
  isEmpty,
  keyBy,
  max,
  noop,
  truncate,
  uniq,
} from "lodash";
import { AssetIcon } from "src/components/styled/Assets";
import { colors, other } from "src/theme";
import { getHarvestLink, getLink } from "src/modules/ledger/transactions";
import { PROVIDER_TO_LOGO_URL } from "src/components/modals/AccountModal/constants";
import Helpers, { D, formatNum } from "src/utils/helpers";
import numeral from "numeral";
import { Maybe, hasValue } from "src/core";
import { Touchable } from "src/components/Touchable";
import {
  getAssetOnMarketplaceOrCoingecko,
  getAssetUrl,
} from "src/modules/getAssetUrl";
import SmallLogo from "src/assets/awaken/logos/awaken-black.jpeg";
import numbro from "numbro";
import StatusTag from "src/components/styled/StatusTag";
import { NAME_FLEX, VALUE_FLEX } from "./constants";
import { ActionSheet } from "src/components";
import { useMyToast } from "src/hooks";
import {
  getAssetKeyForUrl,
  getBalanceImageInfo,
  getGenericTokenName,
} from "./utils";
import { AccountPosition } from "./AccountPosition";
import { singular } from "pluralize";
import { getImageKitCDNUrl } from "src/utils/imagekit";
import BigNumber from "bignumber.js";
import { useIsLargeScreen } from "src/hooks/useScreenSize";

const MIN_DECIMALS = 8;

const _shouldRenderBreakdown = (b: { amount: number }) =>
  new BigNumber(b.amount).dp(MIN_DECIMALS).gt(0);

export const AssetRow = ({
  portfolioBalance,
  clientId,
  currency,
  warning,
  accountById,
  assetById,
  showExtraInfo,
}: {
  portfolioBalance: PortfolioBalance;
  clientId: string;
  currency: CurrencyCodeEnum;
  warning: Maybe<AssetWarning>;
  accountById: Dictionary<BaseAccountWithCurrentJobFields>;
  assetById: Dictionary<BaseAssetFields>;
  showExtraInfo: boolean;
}) => {
  const navigate = useNavigate();
  const [isShowingPositions, setShowingPositions] = useState(false);

  // TODO: there is a bug here. sometimes the portfolioBalance.assetKey is the assetId in the case
  // of a search (ex. when you search in the box for 'ETH') so we have to derive the asset key and not use it
  // given it can be an ID. this can technically work okay, but we want it to for now open
  // up the whole collection or whole asset so we don't want the asset ID even tho it technically can
  // so it is okay for now
  const assetKey = getAssetKeyForUrl(portfolioBalance.asset);
  const assetDetailLink = `${getHarvestLink(clientId)}/${assetKey}`;

  // const assetName = getAssetName(portfolioBalance.asset);
  const asset = portfolioBalance.asset;
  const tokenName = getGenericTokenName(portfolioBalance, true);
  const provider = portfolioBalance.asset.provider;
  const isNeg = portfolioBalance.gainOrLossFiatAmount
    ? portfolioBalance.gainOrLossFiatAmount < 0
    : false;
  const color = isNeg ? colors.negative : colors.positive;
  const hasAmount = portfolioBalance.amount > 0;
  const isNFT = portfolioBalance.asset.type === AssetTypeEnum.Nft;

  const assetName = isNFT
    ? // grouped by collection for NFT now
      asset?.collectionName || asset?.name || asset?.symbol || "-"
    : asset?.symbol?.toUpperCase() || asset.name || "-";
  const breakdown = portfolioBalance.breakdown ?? [];

  // if the providers on all the accounts are the same, we can render it
  const uniqAccountProviders = uniq(
    (portfolioBalance.breakdown || [])
      .map((b) => {
        const acct = accountById[b.accountId];
        if (!acct) return null;
        if (!_shouldRenderBreakdown(b)) return null;
        return acct.provider;
      })
      .filter(hasValue)
  );

  const showProvider =
    provider &&
    PROVIDER_TO_LOGO_URL[provider || ""] &&
    uniqAccountProviders.length === 1;

  const tokenSymbol =
    asset?.type === AssetTypeEnum.Nft ? tokenName : asset?.symbol;

  const positions = breakdown.filter(_shouldRenderBreakdown);
  const isLarge = useIsLargeScreen();

  return (
    <>
      <Box
        style={{
          borderRadius: 8,
          display: "flex",
          alignItems: "center",
        }}
        _hover={{
          backgroundColor: colors.gray95,
        }}
      >
        <ReactRouterLink
          style={{ flex: 1 }}
          to={assetDetailLink}
          color={colors.black}
        >
          <HStack
            opacity={hasAmount ? 1 : 0.3}
            // borderRadius={7}
            // borderBottom="none !important"
            width="100%"
            padding="1rem 0.25rem"
          >
            <Box
              style={{ width: isLarge ? 35 : 30 }}
              marginRight={isLarge ? "0.5rem !important" : "0"}
            >
              <Box style={{ position: "relative" }}>
                <AssetImage assetById={assetById} balance={portfolioBalance} />

                {showProvider && isLarge ? (
                  <Image
                    position="absolute"
                    w={"1.25rem"}
                    h={"1.25rem"}
                    borderRadius="100%"
                    src={PROVIDER_TO_LOGO_URL[provider || ""] || ""}
                    right="-0.4rem !important"
                    bottom="-0.4rem !important"
                    border={"1px solid " + colors.gray80}
                    bg={colors.white}
                  />
                ) : null}
              </Box>
            </Box>

            <Box
              display="flex"
              style={{
                flex: isLarge ? NAME_FLEX : NAME_FLEX - 1,
              }}
              alignItems="center"
              position="relative"
            >
              <VStack alignItems="flex-start">
                <HStack alignItems="center" marginBottom="0" role="group">
                  <Text
                    w="100%"
                    fontSize="sm"
                    color={colors.black}
                    margin="0 !important"
                    isTruncated
                    fontWeight="semibold"
                    _groupHover={{
                      textDecor: "underline",
                    }}
                  >
                    {truncate(assetName, { length: isLarge ? 30 : 10 })}{" "}
                    {isNFT && isLarge ? " Collection" : ""}
                    {/* <i
                    className="fa-sharp fa-arrow-right"
                    style={{
                      fontSize: 12,
                      position: "relative",
                      top: -1,
                      marginLeft: 5,
                    }}
                  /> */}
                    {warning && (
                      <AssetWarningInfo
                        asset={portfolioBalance.asset}
                        warning={warning}
                      />
                    )}
                  </Text>
                </HStack>

                {/* <AssetDescription breakdown={breakdown} assetById={assetById} /> */}

                <Tooltip
                  label={`${numbro(portfolioBalance.amount).format(
                    "0,000.[0000000000000000]"
                  )} ${tokenSymbol || "Token"}`}
                  openDelay={500}
                >
                  <Text
                    isTruncated
                    marginTop="2px !important"
                    fontSize="sm"
                    isNumeric
                    fontWeight="500"
                    color={colors.gray30}
                    w="100%"
                    whiteSpace="pre-wrap"
                  >
                    {formatNum(portfolioBalance.amount, false, "0,0.[0000]")}{" "}
                    {truncate(tokenSymbol || "", { length: 10 })}
                  </Text>
                </Tooltip>
              </VStack>
            </Box>

            <Box
              style={{
                flex: VALUE_FLEX,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 0,
              }}
            >
              <CurrentValue
                portfolioBalance={portfolioBalance}
                currency={currency}
                isNeg={isNeg}
              />
            </Box>

            <Box
              style={{
                alignItems: "center",
                justifyContent: "flex-start",
                display: "flex",
                fontWeight: "500",
                marginLeft: 0,
                flex: VALUE_FLEX,
              }}
            >
              <GainLoss
                portfolioBalance={portfolioBalance}
                currency={currency}
              />
            </Box>
          </HStack>
        </ReactRouterLink>
        <Box
          width="50px"
          alignItems="flex-end"
          justifyContent="flex-end"
          display="flex"
        >
          <Touchable
            style={{ margin: 0, display: "inline-block" }}
            // label={isShowingPositions ? "Hide Accounts" : "Show Accounts"}
            iconName={
              isShowingPositions
                ? "fa-sharp fa-chevron-up"
                : "fa-sharp fa-chevron-down"
            }
            labelStyle={{ fontSize: 14 }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowingPositions(!isShowingPositions);
            }}
          />

          <Box
            style={
              {
                //marginLeft: 5
              }
            }
          >
            <MoreAssetInfo
              assetKey={portfolioBalance.assetKey}
              asset={portfolioBalance.asset}
            />
          </Box>
        </Box>
      </Box>

      {isShowingPositions && showExtraInfo && positions.length > 0 && (
        <HStack
          style={{
            backgroundColor: colors.gray95,
            // border: "1px solid " + colors.gray85,
            marginBottom: "1rem",
            borderRadius: 7,
            marginTop: "0.5rem",
            marginLeft: isLarge ? "calc(40px + 0.75rem)" : "0",
          }}
        >
          <VStack flex={1}>
            <AccountPositionsHeader />
            {positions.map((b, i) => (
              <AccountPosition
                breakdown={b}
                key={b.accountId + b.assetId}
                clientId={clientId}
                currency={currency}
                isLast={i === breakdown.length - 1}
                asset={assetById[b.assetId]}
                account={accountById[b.accountId] ?? null}
              />
            ))}
          </VStack>
          <Box style={{ width: "calc(55px)", margin: 0 }} />
        </HStack>
      )}
    </>
  );
};

const AssetImage = ({
  balance,
  assetById,
}: {
  balance: PortfolioBalance;
  assetById: Dictionary<BaseAssetFields>;
}) => {
  const isLarge = useIsLargeScreen();
  const assets = useMemo(
    () => balance.breakdown.map((b) => assetById[b.assetId]).filter(hasValue),
    [balance]
  );

  // make it so we switch through these random assets every 2.5 seconds and render using the <AssetIcon
  const [assetIndex, setAssetIndex] = useState(0);
  const [asset, setAsset] = useState(assets[0]);

  useEffect(() => {
    const images = assets.map((a) => getAssetUrl(a));
    // if every image is the same, return
    if (images.every((i) => i === images[0])) return;
    // only if all assets are NFT
    if (assets.every((a) => a.type === AssetTypeEnum.Nft)) return;
    // if only one asset, return
    if (assets.length === 1) return;

    const interval = setInterval(() => {
      // Delay setting the new asset and resetting the fade to create a fade effect
      setTimeout(() => {
        // Calculate the next asset index, looping back to 0 if needed
        const nextIndex = (assetIndex + 1) % assets.length;
        setAssetIndex(nextIndex);

        // Set the corresponding asset from the assets array
        setAsset(assets[nextIndex]);
      }, 500); // Delay for 0.5 seconds for the fade effect
    }, 2500); // Interval of 2.5 seconds in milliseconds

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, [assetIndex, assets]);

  if (!asset) {
    return null;
  }

  return (
    <AssetIcon
      textStyle={{ fontSize: 8 }}
      size={isLarge ? 35 : 30}
      asset={asset}
    />
  );
};

const AssetDescription = ({
  breakdown,
  assetById,
}: {
  breakdown: AssetAccountBreakdown[];
  assetById: Dictionary<BaseAssetFields>;
}) => {
  const assetName = breakdown.map((a) => {
    const asset = assetById[a.assetId];
    return asset.type === AssetTypeEnum.Nft ? asset.collectionName : asset.name;
  });

  // pick the longest one. that way if the name of one is the symbol for a normal asset it doesn't look weird
  const name = max(assetName);

  return (
    <HStack marginTop="0 !important" alignItems="center">
      <Text
        marginTop="0 !important"
        marginBottom="0 !important"
        isTruncated
        fontSize="xs"
        fontWeight="normal"
        color={colors.gray30}
        marginRight="0"
      >
        {name}
      </Text>
      {/* <Image
                src={getImageKitCDNUrl(account.iconImageUrl, {
                  width: 160,
                  height: 160,
                })}
                w="0.85rem"
                h="0.85rem"
                borderRadius="5px"
                flexShrink={0}
                marginRight="0rem"
                marginLeft="5px !important"
              /> */}
    </HStack>
  );
};

const AccountPositionsHeader = () => {
  const isLarge = useIsLargeScreen();

  return (
    <HStack
      width="100%"
      padding="0.5rem 0 0 0"
      // marginBottom="0.5rem"
      style={{
        // borderBottom: `1px solid ${colors.gray80}`,
        justifyContent: "space-between",
      }}
    >
      {/* <Box width={35} /> */}

      <Box
        display="flex"
        alignItems="center"
        position="relative"
        fontWeight="500"
        style={{
          color: colors.gray30,
          flex: NAME_FLEX,
          // marginRight: "calc(35px)", // bc of the width of the image. hacky
        }}
        fontSize={isLarge ? "sm" : "xs"}
        // marginLeft="1.25rem !important"
      >
        {/* Account */}
      </Box>

      <Box
        style={{
          alignItems: "center",
          justifyContent: "flex-start",
          color: colors.gray30,
          display: "flex",
          fontWeight: "500",
          marginLeft: 0,
          flex: VALUE_FLEX,
        }}
        fontSize={isLarge ? "sm" : "xs"}
      >
        Value
      </Box>

      <Box
        style={{
          fontWeight: "500",
          color: colors.gray30,
          flex: VALUE_FLEX,
          marginLeft: 0,
        }}
        fontSize={isLarge ? "sm" : "xs"}
      >
        Tot. Return
      </Box>
    </HStack>
  );
};

const MoreAssetInfo = ({
  asset,
  assetKey,
}: {
  asset: BaseAssetFields;
  assetKey: string;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const [isOpen, setOpen] = useState(false);
  const contractUrl = asset.blockExplorerUrl;
  const assetInfoUrl = getAssetOnMarketplaceOrCoingecko(asset);
  const toast = useMyToast();
  const [setSpam] = useMutation(api.assets.setAssetSpam);
  // const [updateAsset] = useMutation(api.assets.update);
  const [markWorthless] = useMutation(api.assets.markWorthless);

  const _copyAssetId = () => {
    navigator.clipboard.writeText(asset?.id || "");
    toast.show({ status: "info", message: "Copied asset ID!" });
  };

  const _setAssetAsSpam = async () => {
    try {
      await setSpam({
        variables: { assetId: asset.id, isSpam: true },
        refetchQueries: [api.portfolio.getPortfolioAssets],
      });
      toast.show({
        status: "success",
        message: "Successfully marked as spam!",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message:
          (err as any)?.message ||
          "Something went wrong, please try again later!",
      });
    }
  };

  const _markWorthless = async () => {
    try {
      await markWorthless({
        variables: {
          assetKey: assetKey,
          isWorthless: !asset.isWorthless,
          clientId,
        },
        refetchQueries: [
          api.portfolio.getPortfolioAssets,
          api.portfolio.getChart,
          api.portfolio.getPortfolioValue,
          api.clients.assets,
        ],
      });
      toast.show({
        status: "success",
        message: "Successfully marked as worthless.",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message:
          (err as any)?.message ||
          "Something went wrong, please try again later!",
      });
    }
  };

  const isLarge = useIsLargeScreen();

  if (asset.type === AssetTypeEnum.FiatCurrency) {
    return null;
  }

  return (
    <ActionSheet
      popover={{
        placement: "bottom-end",
        trigger: isLarge ? "hover" : "click",
      }}
      content={{ width: 250 }}
      commands={[
        {
          label: "View in Awaken",
          iconImageSrc: SmallLogo,
          iconImageStyle: { borderRadius: 5 },
          // do the link by the name / symbol bc we group assets across chains in the UI for the asset row
          link: getLink(clientId || "", {
            assetSymbolOrName:
              asset.type === AssetTypeEnum.Nft
                ? asset.name
                : asset.symbol || "",
          }),
        },
        assetInfoUrl
          ? {
              label: "View on " + assetInfoUrl.name,
              // block font awesome icon
              iconName: "fa-sharp fa-external-link-alt",
              link: assetInfoUrl?.url || "",
              iconImageSrc: assetInfoUrl?.imageUrl || "",
            }
          : null,
        contractUrl
          ? {
              label: "Open in block explorer",
              // network of nodes font awesome incon
              iconName: "fa-sharp fa-network-wired",
              link: contractUrl,
            }
          : null,
        {
          label: "Copy asset ID",
          hasDivider: true,
          iconName: "fa-sharp fa-copy",
          onClick: _copyAssetId,
        },
        {
          label: asset.isWorthless
            ? "Mark as not worthless"
            : "Mark as worthless",
          iconName: "fa-sharp fa-empty-set",
          infoMessage:
            "This is useful for tokens that may have been migrated to a new contract. This will only affect your portfolio, and we will show this asset as worth $0 and adjust the historical value to be $0 in the chart.",
          onClick: _markWorthless,
        },
        {
          label: "Mark as spam",
          iconName: "fa-sharp fa-trash",
          iconColor: colors.red50,
          onClick: _setAssetAsSpam,
        },
      ].filter(hasValue)}
    >
      <Touchable
        // open font awesome icon
        iconName="fa-sharp fa-ellipsis-v"
        style={{
          fontSize: 14,
          cursor: "pointer",
        }}
      />
    </ActionSheet>
  );
};

const AssetWarningInfo = ({
  asset,
  warning,
}: {
  asset: BaseAssetFields;
  warning: AssetWarning;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const [isOpen, setOpen] = useState(false);
  const assetLabelLink = `/clients/${clientId}/transactions?assetIds=${asset.id}&labeled=false&includeSpam=true`;

  if (!warning) {
    return null;
  }

  return (
    <Popover
      trigger="hover"
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => setOpen(false)}
      isOpen={isOpen}
      placement="bottom"
      openDelay={0}
    >
      <PopoverTrigger>
        <div style={{ display: "inline-block", margin: "0 15px" }}>
          <StatusTag
            type="warning"
            //   label="Warning"
            onClick={(e) => {
              //   e.stopPropagation();
              //   e.preventDefault();
            }}
            iconStyle={{ color: colors.yellow40, fontSize: 12 }}
            boxProps={{
              style: {
                cursor: "pointer",
                padding: "4px 8px",
                border: "1px solid " + colors.yellow40,
              },
            }}
            iconName="fa-sharp fa-flag"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent padding="0.5rem 1rem">
        <HStack
          style={{
            padding: "0.25rem",
            width: "100%",
            whiteSpace: "normal",
          }}
        >
          {warning.type === "unlabeled" ? (
            <span>
              {warning.message}
              <hr style={{ margin: "0.5rem 0" }} />
              To get an accurate portfolio, please{" "}
              <Link
                style={{
                  textDecoration: "underline",
                  color: colors.primary,
                  fontWeight: "bold",
                }}
                href={assetLabelLink}
              >
                label the transactions
              </Link>{" "}
              🙏
            </span>
          ) : (
            <span>
              This asset balance has an issue, please contact support and we'll
              help you out!
            </span>
          )}
        </HStack>
      </PopoverContent>
    </Popover>
  );
};

const GainLoss = ({
  portfolioBalance,
  currency,
}: {
  portfolioBalance: PortfolioBalance;
  currency: CurrencyCodeEnum;
}) => {
  const isLarge = useIsLargeScreen();

  if (
    !portfolioBalance.gainOrLossFiatAmount ||
    isNaN(portfolioBalance.gainOrLossFiatAmount) ||
    !portfolioBalance.hasPrice
  ) {
    return <HStack alignItems="center">—</HStack>;
  }

  const isNeg = portfolioBalance.gainOrLossFiatAmount < 0;
  const color = isNeg ? colors.negative : colors.positive;

  return (
    <HStack alignItems="center" style={{ width: "100%" }}>
      <VStack
        alignItems="flex-start"
        style={{
          fontWeight: "500",
          color,
          width: "100%",
          maxWidth: 100,
        }}
      >
        <Text fontWeight="500" fontSize="sm" color={color} marginBottom="5px">
          {D(
            Math.abs(portfolioBalance.gainOrLossFiatAmount),
            currency
          ).toFormat()}{" "}
        </Text>
        <Text
          fontSize={isLarge ? "sm" : "xs"}
          marginTop="0 !important"
          style={{ color }}
        >
          {isNeg ? "(" : ""}
          {numbro(Math.abs(portfolioBalance.percentChangeAllTime || 0)).format({
            thousandSeparated: true,
            mantissa: 2,
          })}
          %{isNeg ? ")" : ""}
        </Text>
      </VStack>

      {isLarge && (
        <i
          className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
          style={{
            fontSize: 16,
            color,
          }}
        />
      )}
    </HStack>
  );
};

const CurrentValue = ({
  portfolioBalance,
  currency,
  isNeg,
}: {
  portfolioBalance: PortfolioBalance;
  currency: CurrencyCodeEnum;
  isNeg: boolean;
}) => {
  const isFiat = portfolioBalance.asset.type === AssetTypeEnum.FiatCurrency;
  const asset = portfolioBalance.asset;
  const showUnpriced =
    !isFiat && !asset?.isWorthless && !portfolioBalance.hasPrice;
  const toast = useMyToast();
  const isLarge = useIsLargeScreen();

  const [reportUnpricedAsset] = useMutation(api.assets.reportUnpricedAsset);

  const _reportAsset = async () => {
    try {
      await reportUnpricedAsset({
        variables: {
          assetId: portfolioBalance.asset.id,
          chain: portfolioBalance.asset.provider,
          contractAddress: portfolioBalance.asset.contractAddress,
        },
      });

      toast.show({
        status: "success",
        message: "Successfully reported asset!",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message: "Failed to report asset!",
      });
    }
  };

  const color = isNeg ? colors.negative : colors.positive;
  const tokenName = getGenericTokenName(portfolioBalance, true);
  const isNFT = portfolioBalance.asset?.type === AssetTypeEnum.Nft;

  return (
    <Box>
      <Text
        fontSize="sm"
        isNumeric
        style={{
          fontWeight: "500",
        }}
        marginBottom="5px"
        color={colors.black}
      >
        {D(portfolioBalance.estimatedCurrentValue || 0, currency).toFormat()}
      </Text>
      {showUnpriced ? (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Text
              fontSize="sm"
              marginTop={0}
              // fontStyle="italic"
              style={{ color: colors.gray30 }}
            >
              Unpriced <i className="fa-sharp fa-question" />
            </Text>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <Box
              style={{
                padding: "1rem",
              }}
            >
              <Text
                style={{
                  whiteSpace: "normal",
                  color: colors.gray20,
                  fontWeight: "normal",
                }}
                fontSize="sm"
              >
                This asset doesn't have a price. Think this is a mistake?{" "}
                <a
                  onClick={_reportAsset}
                  style={{
                    display: "inline",
                    fontWeight: "bold",
                    color: colors.primary,
                  }}
                >
                  Click to report
                </a>{" "}
                and we'll look into it and add pricing data if we find it!
              </Text>
            </Box>
          </PopoverContent>
        </Popover>
      ) : (
        <Text
          fontSize={isLarge ? "sm" : "xs"}
          marginTop={0}
          isTruncated
          // fontStyle="italic"
          style={{ color: colors.gray30 }}
        >
          {D(
            portfolioBalance.currentTokenPriceFiatAmount || 0,
            currency
          ).toFormat()}
          /
          {isNFT
            ? singular(tokenName)
            : portfolioBalance.asset?.symbol?.toUpperCase() ||
              singular(tokenName)}
        </Text>
      )}
    </Box>
  );
};
