import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { MutationRerunGraphArgs } from "src/api/generated/types";
import { useMyToast } from "./useMyToast";

export const useRecalculate = () => {
  const { clientId } = useParams();
  const toast = useMyToast();
  const [rerunGraph] = useMutation(api.graph.rerun);

  const recalculate = useCallback(
    async (doNotReplay?: boolean) => {
      console.log("TRIGGERED RECALCULATE!");
      if (!clientId) {
        return;
      }

      try {
        const variables: MutationRerunGraphArgs = {
          clientId,
          doNotReplay,
        };

        await rerunGraph({
          variables: variables,
          // refetchQueries: [...RECALCULATE_REFETCH_QUERIES], now we have poller
        });
      } catch (err) {
        toast.show({
          status: "error",
          message: (err as Error)?.message,
        });
      }
    },
    [clientId]
  );

  return recalculate;
};
