import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { colors } from "src/theme";
import { Info } from "src/components/styled";
import { TransferComponent } from "./Transfer";
import { useContext } from "react";
import { ActiveTransactionContext } from "src/context";
import { hasValue } from "src/core";
import React from "react";
import { useTheme } from "src/hooks/useTheme";
import { Touchable } from "src/components/Touchable";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";

export const TransferOverview = () => {
  const { transaction, transfers } = useContext(ActiveTransactionContext);
  const { sent, received, internal } = transfers || {};

  const allActions = [sent, received, internal]
    .filter(hasValue)
    .filter((a) => a.transfers?.length > 0);

  const sentTransfers = sent?.transfers || [];
  const receivedTransfers = received?.transfers || [];

  const hasSentAndReceived =
    sentTransfers.length > 0 && receivedTransfers.length > 0;
  const dispatch = useDispatch();

  const _addTransfer = async () => {
    dispatch(
      show("TransferModal", {
        mode: "creating",
        transfer: null,
        startingFiatValue: 0,
        field: "snapshot",
        transaction: transaction,
      })
    );
  };

  if (!allActions.length) {
    // no transfers, add some
    return (
      <Box padding="1rem 0">
        <Text color={colors.gray40} fontSize="md" textAlign="center">
          No transfers, yet.
        </Text>
        <div
          style={{
            margin: "1rem auto",
            textAlign: "center",
          }}
        >
          <Touchable
            label={`Add Transfer`}
            onClick={_addTransfer}
            iconName="fa-sharp fa-plus"
          />
        </div>
      </Box>
    );
  }

  return (
    <>
      <HStack spacing={0} width="100%" padding="1rem 0" alignItems="start">
        {allActions.map((a, i) => (
          <>
            <VStack w="22rem" alignItems="start">
              <Text
                w="100%"
                marginBottom="0.25rem"
                fontWeight="bold"
                fontSize={12}
                color={colors.gray40}
              >
                {a.name.toUpperCase()}{" "}
                {a.infoMessage && <Info message={a.infoMessage} />}
              </Text>
              {a.transfers.map((t, j) => (
                <TransferComponent
                  type={a.type}
                  isInternal={a.isInternal}
                  transaction={transaction ?? null}
                  provider={transaction?.provider || ""}
                  transfer={t}
                  key={j}
                  index={j}
                />
              ))}
            </VStack>
            {/* for sent add an arrow pointing to the right to received */}
            {a.type === "sent" && hasSentAndReceived && <SentBarrier />}
          </>
        ))}
      </HStack>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Touchable
          label={`Add Transfer`}
          onClick={_addTransfer}
          iconName="fa-sharp fa-plus"
        />
      </div>
    </>
  );
};

const SentBarrier = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        margin: "0 1rem",
      }}
    >
      <Box
        width="2px"
        // height of the parent
        height="100%"
        minH="225px"
        borderRadius={100}
        bg={theme.secondaryBackground}
      >
        {/* <i
        style={{ fontSize: 18, color: colors.gray40, zIndex: 1 }}
        className="fa-sharp fa-arrow-circle-right"
      /> */}
      </Box>
    </div>
  );
};
