import {
    HStack
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import SecondaryText from "src/components/styled/SecondaryText";

type Crumb = {
  name: string,
  url: string,
}

type Props = {
  crumbs: Crumb[]
}

function Breadcrumbs ({ crumbs }: Props) {
    return (
      <HStack>
        {
          crumbs.map((crumb, i) => (
            <>
              <Link to={crumb.url}>
                <SecondaryText text={crumb.name}></SecondaryText>
              </Link> 
              {(crumbs.length !== i + 1) && <SecondaryText text=" > " />}
            </>
          ))
        }
      </HStack>
    )
  }

export default Breadcrumbs;