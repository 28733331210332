import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Maybe } from "src/core";
import qs from "query-string";
import { LedgerTransactionReviewStatusEnum } from "src/api/generated/types";
import Helpers, { cleanObj } from "src/utils/helpers";
import { isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { omit } from "lodash/fp";

export const useURLSearch = <T extends Record<string, any>>({
  parseFilters,
}: {
  parseFilters: (p: URLSearchParams) => T;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearch = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const updateFilters = (filters: Record<string, any>) => {
    // filters out undefined values
    const searchParams = cleanObj({
      // take the current filters and dump them before adding new ones
      ...parseFilters(urlSearch),
      ...filters,
    });

    const newUrl =
      location.pathname +
      "?" +
      qs.stringify(searchParams, {
        arrayFormat: "comma",
      });

    navigate(newUrl, {
      replace: false, // please don't
    });
  };

  const clearFilters = () => {
    const newUrl = location.pathname;
    navigate(newUrl, {
      replace: false, // please don't
    });
  };

  const filters = useMemo(
    () => parseFilters(urlSearch),
    [parseFilters, urlSearch]
  );

  const hasFilters = !isEmpty(
    omit(["limit", "page", "clientId", "includeSpam"], cleanObj(filters))
  );

  return {
    filters,
    hasFilters,
    updateFilters,
    clearFilters,
  };
};
