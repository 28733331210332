import { Box, Button, HStack, Spinner, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { map } from "radash";
import { FileTabInfo } from "./FileTab";
import { isBoolean, isNil, isNumber, isString, result } from "lodash";
import numbro from "numbro";
import { parse } from "papaparse";
import { resolve } from "path";
import { useDownloadFile } from "src/hooks/common";
import { useTheme } from "src/hooks/useTheme";

const delimiters = [";", ",", "\t"];

export type DropFileParams = {
  acceptedFile: File;
  rawRows: any[];
  cleanedRows: any[];
  headers: string[];
  rawCsvData?: string;
  cleanedCsvData: string;
};

export type DropFileProps = {
  onDrop: (p: DropFileParams[]) => void;
  onRemoveFile: (fileId: string) => void;
  files: FileTabInfo[];
};

const CLEAN_REGEX = /[a-zA-Z,]/g;

export function isValidNumber(input: string | number | Date) {
  try {
    if (typeof input === "number") return true;
    // if it is a date, return false
    if (input instanceof Date) return false;
    if (isBoolean(input)) return false;

    // if the first digits are 0x, it isn't (it is a hash that we shouldnt parse)
    if (input?.slice(0, 2) === "0x") return false;

    const cleanedInput = (input || "").replace(CLEAN_REGEX, "");

    return (
      !isNaN(parseFloat(cleanedInput)) &&
      isFinite(parseFloat(cleanedInput)) &&
      !isUUID(input) &&
      !isValidDate(input)
    );
  } catch (err) {
    console.error(err);
    console.log(input);
    return false;
  }
}

// write a function for isUUID
export function isUUID(uuid: string) {
  try {
    const uuidPattern = new RegExp(
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
    );
    return uuidPattern.test(uuid);
  } catch (err) {
    return false;
  }
}

export function isValidDate(date: string) {
  try {
    // if the date is of type Date
    if (!moment(date).isValid()) {
      return false;
    }

    const isValid = moment(date).isValid();
    const jsDate = isValid ? new Date(date) : null;

    // need this for some weird kucoin stuff. maybe others are like it -> just check to make sure the date is in the range
    const isActualJsDate = jsDate
      ? jsDate.getFullYear() > 2006 && jsDate.getFullYear() < 2025
      : false;

    return isValid && isActualJsDate;
  } catch (err) {
    return false;
  }
}

export const buildNewRow = (row: any, headers: string[]) =>
  headers.reduce((acc, header) => {
    const _value = row[header];
    const value = _value && isString(_value) ? _value.trim() : _value;

    // if the header looks like ID -> don't try and format it
    // ex. "Order ID" may be a hex number but we don't want to strip characters
    if (!value || isMaybeID(header) || isMaybeNotes(header)) {
      return {
        ...acc,
        [header]: value,
      };
    }

    // if it looks like a hash or signature column, don't try to parse it
    if (
      header.toLowerCase().includes("hash") ||
      header.toLowerCase().includes("signature") ||
      header.toLowerCase().includes("xpub")
    ) {
      return {
        ...acc,
        [header]: value,
      };
    }

    // if the column might be a crypto -> don't try and format it. messes stuff up. ex. kucoin which does USDC-MATIC
    if (mightBeCryptocurrency(value)) {
      return {
        ...acc,
        [header]: value,
      };
    }

    // if the value is a number, clean it
    if (isValidNumber(value)) {
      const number = parseFloat(value);

      // if the number is already valid, return it early. there could be a number like 4-10E and we want to make sure
      // that exits before hitting the below code
      if (!isNaN(number)) {
        return {
          ...acc,
          [header]: formatNumber(number),
        };
      }

      // get the characters (this is common ex a row of 1,000,000MATIC or 1,000,000 MATIC)
      const characters = value.match(/[A-Za-z]+/g);
      const numValue = value.replace(CLEAN_REGEX, "");
      const symbol = characters ? characters[0] : "";

      return {
        ...acc,
        [header]:
          formatNumber(parseFloat(numValue)) + (symbol ? " " + symbol : ""),
      };
    }

    if (isUUID(value)) {
      return {
        ...acc,
        [header]: value,
      };
    }

    if (isValidDate(value)) {
      return {
        ...acc,
        [header]: moment(value).toISOString(),
      };
    }

    // otherwise, just return the value
    return {
      ...acc,
      [header]: value,
    };
  }, {});

export const buildCleanedCSV = (headers: string[], rows: any[]) => {
  const newRows = rows.map<any>((row) => {
    // build a row mapping through the header values as fields of row
    const n = buildNewRow(row, headers);
    return n;
  });

  // console.log(newRows);

  return {
    headers,
    newRows,
  };
};

function detectCSVSeparator(csvData: string) {
  // Define the possible separators to check
  const separators = [",", ";", "\t"];

  // Loop through each separator and check if it appears in the CSV data
  for (const separator of separators) {
    const regex = new RegExp(`${separator}(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)`);
    if (regex.test(csvData)) {
      return separator;
    }
  }

  // If no separator is found, return null
  return null;
}

const _parseFileContentsV2 = async (file: File): Promise<DropFileParams> => {
  return new Promise<DropFileParams>((res, r) => {
    parse(file, {
      complete: function (results) {
        // console.log(csvRows);

        const rawCsvRows = results.data as any[];
        const rawHeaders = results.meta.fields ?? [];

        const { headers, newRows } = buildCleanedCSV(rawHeaders, rawCsvRows);

        const csvHeaderData = headers.join(",");
        const csvRowsData = newRows
          .map((r) => headers.map((h) => r[h]).join(","))
          .join("\n");
        const fullCsvData = `${csvHeaderData}\n${csvRowsData}`;

        const params: DropFileParams = {
          acceptedFile: file,
          rawRows: rawCsvRows,
          cleanedRows: newRows,
          headers,
          cleanedCsvData: fullCsvData,
        };

        return res(params);
      },
      header: true, // Set to true if the first row contains column headers
      skipEmptyLines: true,
      dynamicTyping: true, // Automatically converts strings to numbers, dates, etc.
    });
  });
};

export const DropFile = (props: DropFileProps) => {
  const hasFile = props.files.length > 0;

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const fileResults = await map(acceptedFiles, async (file) => {
        return _parseFileContentsV2(file);
      });

      if (!fileResults.length) {
        alert("Missing file.");
        return;
      }

      return props.onDrop(fileResults);
    },
    [props]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      "application/csv": [".csv"],
    },
  });

  const theme = useTheme();

  return (
    <div
      {...getRootProps()}
      className={`border-2 border-dashed p-6 py-2 rounded-lg ${
        isDragActive ? "border-green-500" : "border-gray-500"
      }`}
      style={{ minWidth: 500, background: theme.medBackground }}
    >
      <input {...getInputProps()} />

      {hasFile ? (
        <div>
          {props.files.map((file, index) => (
            <FileRow
              removeFile={() => props.onRemoveFile(file.id)}
              file={file.file}
              key={index}
            />
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 5,
            width: "100%",
          }}
          className="p-6"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-center text-green-500">Drop the files here...</p>
          ) : (
            <p
              style={{
                color: theme.text,
              }}
              className="text-center text-gray-500"
            >
              Drag &apos;n&apos; drop files here, or click to select files
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const FileRow = ({
  file,
  removeFile,
}: {
  file: File;
  removeFile: () => void;
}) => {
  const { downloadFile } = useDownloadFile();
  const theme = useTheme();

  const downloadCSV = (e: any) => {
    e.stopPropagation();

    if (!file) {
      alert("Missing file.");
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const csvData = reader.result;

      downloadFile(
        file?.name || "",
        csvData?.toString() || "",
        "text/csv",
        "original"
      );
    };

    reader.readAsText(file); // Read the file as text
  };

  const _removeFile = (e: any) => {
    e.stopPropagation();
    removeFile();
  };

  return (
    <HStack
      style={{
        margin: "1rem 0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: 5,
        width: "100%",
      }}
    >
      <i
        style={{
          fontSize: 28,
          marginRight: 5,
          color: theme.header,
        }}
        className="fa-solid fa-file-csv"
      />
      <Text flex={1} fontSize="sm" color={theme.text}>
        {file?.name || "Your File"}
      </Text>
      <Button
        onClick={downloadCSV}
        variant="none"
        className="bg-gray-200"
        size="sm"
      >
        <i className="fa-solid fa-download" />
      </Button>
      <Button
        onClick={_removeFile}
        variant="none"
        className="text-red-500"
        size="sm"
      >
        <i className="fa-solid fa-times" />
      </Button>
    </HStack>
  );
};

function isMaybeID(str: string) {
  const pattern = /ID/gi;
  return pattern.test(str);
}

function isMaybeNotes(str: string) {
  const pattern = /notes/gi;
  return pattern.test(str);
}

function formatNumber(num: number) {
  if (!isValidNumber(num.toString())) return null;
  return numbro(num).format("0.[0000000000]");
}

function mightBeCryptocurrency(symbol: string) {
  const cryptocurrencyRegex =
    /^[A-Z]{2,6}(\.[A-Z]{1,4}(-[A-Z]{2,4})?)?(-[A-Z]{2,4})?$/;
  return cryptocurrencyRegex.test(symbol);
}
