import React from "react";
import { BaseLedgerEntryFullFields } from "src/api";
import { BaseClientFields, BaseFullTransactionFields } from "src/api/fragments";
import { TransactionTypeOptionResponse } from "src/api/generated/types";
import { TransferBreakdown } from "src/components/modals/TxnDetailModal/Transfers/TransferForm/utils";
import { Maybe } from "src/core";

type ActiveTransactionContext = {
  transaction: Maybe<BaseFullTransactionFields>;
  client: Maybe<BaseClientFields>;
  transfers: Maybe<TransferBreakdown>;
  defaultLabels: Maybe<TransactionTypeOptionResponse>;
  // accounts: BaseAccountFields[];
  showAssetTransfersBuilder: boolean;
  ledgerEntries: BaseLedgerEntryFullFields[];
};

export const ActiveTransactionContext =
  React.createContext<ActiveTransactionContext>({
    transaction: null,
    transfers: null,
    defaultLabels: null,
    // accounts: [],
    client: null,
    showAssetTransfersBuilder: false,
    ledgerEntries: [],
  });
