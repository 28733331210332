import { Maybe } from "src/core";
import * as yup from "yup";
import { Option } from "src/components/styled";
import { generateOptionSchema } from "src/utils/schemas";

export type FormValues = {
  fileObjectKey: string;
  fileName: string;
  account: Maybe<Option>;
};

export const getDefaultValues = (): FormValues => ({
  fileObjectKey: "",
  fileName: "",
  account: null,
});

const optionSchema = generateOptionSchema();

export const schema = yup.object().shape({
  fileObjectKey: yup.string().required().label("File "),
  fileName: yup.string().label("File "),
  account: optionSchema.nullable(),
});
