import { Maybe } from "graphql/jsutils/Maybe";
import { BaseFullTransactionFields } from "src/api/fragments";
import { TransactionTypeOption } from "src/api/generated/types";
import { Option } from "src/components/styled";

type Props = {
  labelOptions: TransactionTypeOption[];
  selectedOption?: Option;
  onSelectOption: any;
  loading: boolean;
  transaction: Maybe<BaseFullTransactionFields>;
  isDisabled: boolean;
  _showModal: any;
};

export const useOnClickLabelSelect = ({
  labelOptions,
  isDisabled,
  selectedOption,
  onSelectOption,
  loading,
  transaction,
  _showModal,
}: Props) => {
  return (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (loading) return;
    if (isDisabled) return;

    _showModal("LabelsModal", {
      labelOptions,
      selectedOption,
      onSelectOption,
      loading,
      transaction,
    });
  };
};
