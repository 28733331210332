import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  List,
  Text,
  VStack,
} from "@chakra-ui/react";
import { isNil } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { BaseBookkeepingQuoteFields } from "src/api/fragments";
import {
  BookkeepingQuoteStatusEnum,
  MutationSubmitFeedbackArgs,
  Query,
} from "src/api/generated/types";
import { Button } from "src/components";
import StatusTag from "src/components/styled/StatusTag";
import { Touchable } from "src/components/Touchable";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { D } from "src/utils/helpers";

function News() {
  const theme = useTheme();
  const { clientId } = useParams();
  const dispatch = useDispatch();

  const toast = useMyToast();
  const [submitFeedback] = useMutation(api.contact.feedback);

  const _submitFeedback = async () => {
    const variables: MutationSubmitFeedbackArgs = {
      feedback: "I'm interested in crypto news.",
    };

    await submitFeedback({
      variables,
    });

    toast.show({
      message: "Joined waitlist! Thanks for your interest 🙏",
      status: "success",
    });
  };

  return (
    <Box
      maxWidth={"inherit"}
      bg={theme.background}
      // style={{
      //   padding: "1rem 2.5rem",
      // }}
    >
      <Box w="100%" paddingTop="1rem">
        {/* <FreeCreditBadge /> */}

        <HStack alignItems="center" padding="0.5rem 0">
          <Flex flex={1} alignItems="center" display="flex" flexDir="row">
            <Heading color={theme.header} margin="0" size="lg">
              What's happening in crypto?
            </Heading>
          </Flex>
        </HStack>

        <div style={{ marginTop: 25 }}>
          <Text
            style={{
              color: theme.text,
              maxWidth: 600,
              fontWeight: "normal",
              marginBottom: 15,
            }}
          >
            The latest crypto news. Personalized to you based on what protocols
            & chains you use. Stay up to date on the latest trends.
          </Text>

          <br />

          <ListItem
            header="Catch the latest trends"
            backgroundColor={colors.primary}
            subtitle="Relevant news to help you catch the latest trends."
            iconName="fa-sharp fa-arrow-trend-up"
          />

          <ListItem
            header="Personalized for you"
            backgroundColor={colors.pink50}
            subtitle="Only content related to protocols and chains you use and/or are interested in."
            iconName="fa-sharp fa-bullseye"
          />

          <ListItem
            backgroundColor={colors.positive}
            iconName="fa-sharp fa-sack-dollar"
            header="Earn Awaken credit"
            subtitle="Every day you use Awaken's news feed, you'll earn free credit towards your taxes."
          />

          <br />
          <br />

          <HStack>
            <Button onClick={_submitFeedback} variant="primary">
              Join Waitlist
            </Button>
          </HStack>
        </div>
      </Box>
    </Box>
  );
}

const ListItem = ({
  header,
  subtitle,
  iconName,
  backgroundColor = colors.gray80,
}: {
  subtitle: string;
  header: string;
  iconName: string;
  backgroundColor?: string;
}) => {
  const theme = useTheme();

  return (
    <HStack marginBottom="1.5rem">
      <div
        style={{
          width: 45,
          height: 45,
          backgroundColor: backgroundColor,
          borderRadius: 50,
          flexShrink: 0,
          display: "flex",
          fontSize: 22,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          color: colors.white,
          marginRight: "0.5rem",
        }}
      >
        <i className={iconName} />
      </div>
      <VStack alignItems="flex-start">
        <Text color={theme.header} fontSize="lg" fontWeight="600">
          {header}
        </Text>
        <Text color={theme.text} marginTop="0 !important" fontSize="md">
          {subtitle}
        </Text>
      </VStack>
    </HStack>
  );
};

export default News;
