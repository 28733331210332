import { HStack, Box, Text, Tooltip, useClipboard } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { colors } from "src/theme";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { api } from "src/api";
import { Query, QueryPacksForClientArgs } from "src/api/generated/types";
import { getIsGlobalRuleMode } from "src/redux/reducers/globalState";
import Banner from "../styled/Banner";

export function GlobalRuleBanner() {
  const isGlobalRuleMode = useSelector(getIsGlobalRuleMode);

  return (
    <Banner
      message={isGlobalRuleMode ? "Global rule mode enabled 🌍" : ""}
      bgColor="purple"
    />
  );
}
