import { Maybe } from "src/core";
import * as yup from "yup";
import { Option } from "src/components/styled";
import { BlockchainEnum } from "src/api/generated/types";

export type FormValues = {
  txnHash: string;
  accountId: string;
  date: string;
  time: string;
};

export const getDefaultValues = (): FormValues => ({
  txnHash: "",
  accountId: "",
  date: "",
  time: "",
});

export const schema = yup.object().shape({
  txnHash: yup.string().optional(),
  accountId: yup.string().required(),
  date: yup.string().required(),
  time: yup.string().required(),
});
