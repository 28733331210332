import { RecalculateSummaryBreakdown } from "src/api/generated/types";
import { CURRENT_TAX_YEAR_STR } from "src/config";
import { Maybe } from "src/core";

export * from "./urls";

export function formatSummaryDate(dateString: Date, UTC?: boolean) {
  const date = new Date(dateString);
  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: UTC ? "UTC" : undefined,
  };
  return date.toLocaleDateString("en-US", options);
}

export const getBreakdownYear = (
  breakdown: RecalculateSummaryBreakdown
): number => {
  return new Date(breakdown.startDate).getUTCFullYear();
};

export const getRelevantBreakdown = (
  breakdowns: RecalculateSummaryBreakdown[],
  year: number | string
): Maybe<RecalculateSummaryBreakdown> =>
  breakdowns.find((b: any) => String(getBreakdownYear(b)) === String(year)) ||
  null;

// export const getBreakdownYears = (
//   breakdownsArray: any,
// ): string[] =>
