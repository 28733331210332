export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: Date;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  accountGroupId?: Maybe<Scalars['ID']>;
  blockExplorerName?: Maybe<Scalars['String']>;
  blockExplorerUrl?: Maybe<Scalars['String']>;
  canTrade: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  currentJob?: Maybe<Job>;
  description: Scalars['String'];
  endDate?: Maybe<Scalars['Date']>;
  errorMessage?: Maybe<Scalars['String']>;
  etaImportFinishedAt?: Maybe<Scalars['Date']>;
  fileObjectKey?: Maybe<Scalars['String']>;
  freeReason?: Maybe<Scalars['String']>;
  hasDoneInitialSync: Scalars['Boolean'];
  hatchfiId?: Maybe<Scalars['String']>;
  iconImageUrl: Scalars['String'];
  id: Scalars['String'];
  idempotency: Scalars['String'];
  importType: ImportTypeEnum;
  includeOrdinals?: Maybe<Scalars['Boolean']>;
  integrationOutOfDateMessage?: Maybe<Scalars['String']>;
  integrationStatus: AccountIntegrationStatusEnum;
  isActive: Scalars['Boolean'];
  isFree?: Maybe<Scalars['Boolean']>;
  isWalletConnected: Scalars['Boolean'];
  lastSyncedAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  numberOfImportedTransactions?: Maybe<Scalars['Int']>;
  numberOfTransactions?: Maybe<Scalars['Int']>;
  provider: Scalars['String'];
  providerImageUrl?: Maybe<Scalars['String']>;
  referenceId: Scalars['String'];
  shouldRecommendSyncing: Scalars['Boolean'];
  source?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  status: AccountStatusEnum;
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
  walletAddress?: Maybe<Scalars['String']>;
};

export type AccountGroup = {
  __typename?: 'AccountGroup';
  id: Scalars['String'];
};

export type AccountInfoSummary = {
  __typename?: 'AccountInfoSummary';
  accountName: Scalars['String'];
  amount: Scalars['String'];
  info: Scalars['String'];
};

export enum AccountIntegrationStatusEnum {
  Current = 'Current',
  OutOfDate = 'OutOfDate'
}

export enum AccountProviderEnum {
  Algorand = 'Algorand',
  Apechain = 'Apechain',
  Arbitrum = 'Arbitrum',
  Aurora = 'Aurora',
  Avalanche = 'Avalanche',
  Bkex = 'BKEX',
  Bsc = 'BSC',
  Base = 'Base',
  Binance = 'Binance',
  BinanceUs = 'BinanceUS',
  Bingx = 'Bingx',
  Bione = 'Bione',
  Bitay = 'Bitay',
  Bitbank = 'Bitbank',
  Bitclout = 'Bitclout',
  Bitcoin = 'Bitcoin',
  BitcoinSwan = 'BitcoinSwan',
  Bitcom = 'Bitcom',
  Bitfinex = 'Bitfinex',
  Bitflyer = 'Bitflyer',
  Bitforex = 'Bitforex',
  Bitfront = 'Bitfront',
  Bitget = 'Bitget',
  Bithumb = 'Bithumb',
  Bitmart = 'Bitmart',
  Bitmax = 'Bitmax',
  Bitmex = 'Bitmex',
  Bitpanda = 'Bitpanda',
  Bitrue = 'Bitrue',
  Bitso = 'Bitso',
  Bitstamp = 'Bitstamp',
  Bittrex = 'Bittrex',
  Blast = 'Blast',
  BlockFi = 'BlockFi',
  BlockchainCom = 'BlockchainCom',
  Btcex = 'Btcex',
  Btse = 'Btse',
  ByBit = 'ByBit',
  Canto = 'Canto',
  Cardano = 'Cardano',
  CashApp = 'CashApp',
  Celestia = 'Celestia',
  Celsius = 'Celsius',
  CexIo = 'CexIo',
  Changelly = 'Changelly',
  Chia = 'Chia',
  Citex = 'Citex',
  CoinDcx = 'CoinDCX',
  CoinEx = 'CoinEx',
  CoinSpot = 'CoinSpot',
  CoinStats = 'CoinStats',
  Coinbase = 'Coinbase',
  CoinbasePro = 'CoinbasePro',
  Coincheck = 'Coincheck',
  Coinhako = 'Coinhako',
  Coinmetro = 'Coinmetro',
  Coinone = 'Coinone',
  Cosmos = 'Cosmos',
  Cronos = 'Cronos',
  Crypto4winners = 'Crypto4winners',
  CryptoCom = 'CryptoCom',
  Dcoin = 'Dcoin',
  Deribit = 'Deribit',
  Digifinex = 'Digifinex',
  Dogechain = 'Dogechain',
  EnergyWeb = 'EnergyWeb',
  Ethereum = 'Ethereum',
  Exodus = 'Exodus',
  Ftx = 'FTX',
  Fantom = 'Fantom',
  Flow = 'Flow',
  FtxUs = 'FtxUs',
  Fuse = 'Fuse',
  GateIo = 'GateIo',
  Gemini = 'Gemini',
  GeneralBlockchain = 'GeneralBlockchain',
  GeneralExchange = 'GeneralExchange',
  Gnosis = 'Gnosis',
  Harmony = 'Harmony',
  Helium = 'Helium',
  Huobi = 'Huobi',
  Hyperliquid = 'Hyperliquid',
  ImmutableX = 'ImmutableX',
  Juno = 'Juno',
  JunoChain = 'JunoChain',
  Klaytn = 'Klaytn',
  Kraken = 'Kraken',
  Kucoin = 'Kucoin',
  Lbank = 'Lbank',
  LedgerX = 'LedgerX',
  LocalBitcoins = 'LocalBitcoins',
  Mexc = 'MEXC',
  MetalPay = 'MetalPay',
  Metis = 'Metis',
  Moonriver = 'Moonriver',
  Near = 'Near',
  NewtonExchange = 'NewtonExchange',
  Nexo = 'Nexo',
  NiftyGateway = 'NiftyGateway',
  Okx = 'OKX',
  Okcoin = 'Okcoin',
  Optimism = 'Optimism',
  Osmosis = 'Osmosis',
  Pom = 'POM',
  Phantom = 'Phantom',
  Pokt = 'Pokt',
  Polkadot = 'Polkadot',
  Poloniex = 'Poloniex',
  Polygon = 'Polygon',
  Pulsechain = 'Pulsechain',
  Ripple = 'Ripple',
  Robinhood = 'Robinhood',
  Ronin = 'Ronin',
  Scroll = 'Scroll',
  Secret = 'Secret',
  Sei = 'Sei',
  Solana = 'Solana',
  Stacks = 'Stacks',
  StarkNet = 'StarkNet',
  Stex = 'Stex',
  Sui = 'Sui',
  Swyftx = 'Swyftx',
  Tapbit = 'Tapbit',
  Terra2 = 'Terra2',
  TerraClassic = 'TerraClassic',
  Tezos = 'Tezos',
  Theta = 'Theta',
  Thorchain = 'Thorchain',
  Ton = 'Ton',
  Tron = 'Tron',
  Unichain = 'Unichain',
  Unknown = 'Unknown',
  UpBit = 'UpBit',
  Uphold = 'Uphold',
  Vechain = 'Vechain',
  Voyager = 'Voyager',
  Wax = 'Wax',
  Whitebit = 'Whitebit',
  XtCom = 'XtCom',
  ZkSync = 'ZkSync',
  ZkSyncEra = 'ZkSyncEra'
}

export enum AccountStatusEnum {
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  Failed = 'Failed',
  None = 'None',
  Queued = 'Queued',
  Synced = 'Synced',
  Syncing = 'Syncing'
}

export enum AccountTypeEnum {
  Exchange = 'Exchange',
  None = 'None',
  Wallet = 'Wallet'
}

export type ActiveSubscriptionDiscountCalculation = {
  __typename?: 'ActiveSubscriptionDiscountCalculation';
  coinbaseWalletDiscount: Scalars['Int'];
  discounts?: Maybe<Array<ActiveSubscriptionDiscountInfo>>;
  likelySpamDiscount: Scalars['Int'];
  magicEdenDiscount: Scalars['Int'];
  numPayableTxns: Scalars['Int'];
  spamDiscount: Scalars['Int'];
  totalDiscount: Scalars['Int'];
  totalNumTxns: Scalars['Int'];
};

export type ActiveSubscriptionDiscountInfo = {
  __typename?: 'ActiveSubscriptionDiscountInfo';
  label: Scalars['String'];
  number: Scalars['Int'];
};

export type ApplyGmeDiscountResponse = {
  __typename?: 'ApplyGMEDiscountResponse';
  gmeBalance: Scalars['Float'];
};

export type Asset = {
  __typename?: 'Asset';
  blockExplorerUrl?: Maybe<Scalars['String']>;
  coinGeckoTokenId?: Maybe<Scalars['String']>;
  collectionName?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  iconImageUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isSpam?: Maybe<Scalars['Boolean']>;
  isWorthless: Scalars['Boolean'];
  metadataUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  standard?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  type: AssetTypeEnum;
  updateAt: Scalars['Date'];
};

export type AssetAccountBreakdown = {
  __typename?: 'AssetAccountBreakdown';
  accountId: Scalars['String'];
  amount: Scalars['Float'];
  assetId: Scalars['String'];
  avgBasisFiatAmount?: Maybe<Scalars['Float']>;
  costBasisFiatAmount: Scalars['Float'];
  currentFiatAmount?: Maybe<Scalars['Float']>;
  currentTokenPriceFiatAmount?: Maybe<Scalars['Float']>;
  estimatedCurrentValue?: Maybe<Scalars['Float']>;
  gainOrLossFiatAmount?: Maybe<Scalars['Float']>;
  hasPrice: Scalars['Boolean'];
  percentChangeAllTime?: Maybe<Scalars['Float']>;
};

export type AssetHolding = {
  __typename?: 'AssetHolding';
  amount: Scalars['Float'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  updateAt: Scalars['Date'];
};

export type AssetKeyInfo = {
  __typename?: 'AssetKeyInfo';
  assets: Array<Asset>;
  coingeckoId?: Maybe<Scalars['String']>;
  firstAsset?: Maybe<Asset>;
  iconImageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberOfAssets?: Maybe<Scalars['Int']>;
  symbol?: Maybe<Scalars['String']>;
  type: AssetTypeEnum;
};

export type AssetPoint = {
  __typename?: 'AssetPoint';
  timestamp: Scalars['Date'];
  utcTimeSeconds: Scalars['Float'];
  value?: Maybe<Scalars['Float']>;
  valueCents?: Maybe<Scalars['Float']>;
};

export type AssetTaxes = {
  __typename?: 'AssetTaxes';
  asset?: Maybe<Asset>;
  assetId: Scalars['ID'];
  currency: CurrencyCodeEnum;
  totalGain: Scalars['String'];
};

export enum AssetTypeEnum {
  FiatCurrency = 'FiatCurrency',
  FungibleToken = 'FungibleToken',
  Nft = 'NFT'
}

export type AssetWarning = {
  __typename?: 'AssetWarning';
  assetId: Scalars['ID'];
  message: Scalars['String'];
  type: Scalars['String'];
};

export type BalanceSnapshot = {
  __typename?: 'BalanceSnapshot';
  accountId: Scalars['String'];
  accountName?: Maybe<Scalars['String']>;
  after: Scalars['Float'];
  assetId: Scalars['String'];
  assetSymbol?: Maybe<Scalars['String']>;
  before: Scalars['Float'];
};

export type BatchWalletInputParams = {
  address: Scalars['String'];
  includeOrdinals?: InputMaybe<Scalars['Boolean']>;
  includeStakingRewards?: InputMaybe<Scalars['Boolean']>;
  isLargeAccount?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
  provider: Scalars['String'];
  shouldUploadAllEVM?: InputMaybe<Scalars['Boolean']>;
};

export enum BlockchainEnum {
  Ethereum = 'Ethereum',
  Polygon = 'Polygon',
  Solana = 'Solana',
  Terra = 'Terra'
}

export type BookkeepingQuote = {
  __typename?: 'BookkeepingQuote';
  acceptedAt?: Maybe<Scalars['Date']>;
  clientEmail?: Maybe<Scalars['String']>;
  clientId: Scalars['ID'];
  clientName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  hasPaid: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  isBookkeeper?: Maybe<Scalars['Boolean']>;
  monthlyPriceCents?: Maybe<Scalars['Int']>;
  partnerEmail?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  quoteFileUrl?: Maybe<Scalars['String']>;
  rejectedAt?: Maybe<Scalars['Date']>;
  requestYear?: Maybe<Scalars['Int']>;
  requestedAt?: Maybe<Scalars['Date']>;
  sentAt?: Maybe<Scalars['Date']>;
  status: BookkeepingQuoteStatusEnum;
  stripeBookkeepingCheckoutSessionUrl?: Maybe<Scalars['String']>;
  taxYearsDescription?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  upfrontPriceCents?: Maybe<Scalars['Int']>;
};

export enum BookkeepingQuoteStatusEnum {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Requested = 'Requested',
  Sent = 'Sent'
}

export type ChartPoint = {
  __typename?: 'ChartPoint';
  amount?: Maybe<Scalars['Float']>;
  breakdown?: Maybe<Array<ChartPointBreakdown>>;
  costBasis?: Maybe<Scalars['Float']>;
  costBasisCents?: Maybe<Scalars['Float']>;
  isAfterNow: Scalars['Boolean'];
  numberOfAssets?: Maybe<Scalars['Int']>;
  numberOfNfts?: Maybe<Scalars['Int']>;
  numberOfTokens?: Maybe<Scalars['Int']>;
  timestamp: Scalars['Date'];
  utcTimeSeconds: Scalars['Float'];
  value?: Maybe<Scalars['Float']>;
  valueCents?: Maybe<Scalars['Float']>;
};

export type ChartPointBreakdown = {
  __typename?: 'ChartPointBreakdown';
  amount?: Maybe<Scalars['Float']>;
  assetId: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

export type CheckRuleResponse = {
  __typename?: 'CheckRuleResponse';
  id: Scalars['String'];
  numTxnsApplied: Scalars['Int'];
};

export type CheckRuleUsedResponse = {
  __typename?: 'CheckRuleUsedResponse';
  id: Scalars['String'];
  numTxnsRuleUsed: Scalars['Int'];
};

export type ClaimCodeResponse = {
  __typename?: 'ClaimCodeResponse';
  message: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  accountantNotes?: Maybe<Scalars['String']>;
  accountantStatus?: Maybe<ClientAccountantStatusEnum>;
  availableCreditCents?: Maybe<Scalars['Float']>;
  canContinuouslySync: Scalars['Boolean'];
  coinbaseWalletInfo?: Maybe<CoinbaseWalletInfo>;
  costBasisAlgorithm?: Maybe<CostBasisAlgorithmEnum>;
  country?: Maybe<CountryEnum>;
  createdAt: Scalars['Date'];
  createdById?: Maybe<Scalars['ID']>;
  creditMatchFileKey?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyCodeEnum>;
  defaultToFloor?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  hasClaimedMagicEden: Scalars['Boolean'];
  id: Scalars['ID'];
  isLocked: Scalars['Boolean'];
  lastSyncedAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  paypalUsername?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  recalculateEta?: Maybe<Scalars['Date']>;
  referralCode?: Maybe<Scalars['String']>;
  referralPercentage: Scalars['Int'];
  referredByCode?: Maybe<Scalars['String']>;
  referredByName?: Maybe<Scalars['String']>;
  referredDomainUrl: Scalars['String'];
  shouldRecalculate?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ClientStatusEnum>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export enum ClientAccountantStatusEnum {
  Blocked = 'Blocked',
  Finished = 'Finished',
  InProgress = 'InProgress'
}

export type ClientAssetsResponse = {
  __typename?: 'ClientAssetsResponse';
  assets: Array<Asset>;
  misc: Array<Asset>;
};

export type ClientPermission = {
  __typename?: 'ClientPermission';
  client?: Maybe<Client>;
  clientId: Scalars['ID'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  pendingUser?: Maybe<PendingUser>;
  pendingUserId?: Maybe<Scalars['ID']>;
  permissionLevel: PermissionLevelEnum;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export enum ClientStatusEnum {
  Importing = 'importing',
  Recalculating = 'recalculating'
}

export type CoinbaseWalletInfo = {
  __typename?: 'CoinbaseWalletInfo';
  addresses: CoinbaseWalletInfoAddresses;
  coinbaseID: Scalars['String'];
  confirmed: Scalars['Boolean'];
  url: Scalars['String'];
};

export type CoinbaseWalletInfoAddresses = {
  __typename?: 'CoinbaseWalletInfoAddresses';
  BTC: Scalars['String'];
  DOGE: Scalars['String'];
  ETH: Scalars['String'];
  LTC: Scalars['String'];
  SOL: Scalars['String'];
};

export enum CostBasisAlgorithmEnum {
  Acb = 'ACB',
  Fifo = 'FIFO',
  Hifo = 'HIFO',
  Lifo = 'LIFO',
  SharePooling = 'SharePooling'
}

export type CountNotDoneResponse = {
  __typename?: 'CountNotDoneResponse';
  dirty: Scalars['Int'];
  importing: Scalars['Int'];
  isDirty: Scalars['Boolean'];
};

export enum CountryEnum {
  At = 'AT',
  Au = 'AU',
  Be = 'BE',
  Ca = 'CA',
  De = 'DE',
  Es = 'ES',
  Fi = 'FI',
  Fr = 'FR',
  Gr = 'GR',
  Ie = 'IE',
  It = 'IT',
  Nl = 'NL',
  No = 'NO',
  Nz = 'NZ',
  Pl = 'PL',
  Pt = 'PT',
  Uk = 'UK',
  Us = 'US',
  Za = 'ZA'
}

export type CreateUserWithEmailResponse = {
  __typename?: 'CreateUserWithEmailResponse';
  customToken: Scalars['String'];
  user: User;
};

export enum CurrencyCodeEnum {
  Aud = 'AUD',
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type CurrentAssetPriceResponse = {
  __typename?: 'CurrentAssetPriceResponse';
  showWarning: Scalars['Boolean'];
  timestamp: Scalars['Date'];
  value?: Maybe<Scalars['Float']>;
  valueCents?: Maybe<Scalars['Float']>;
};

export type DefaultRule = {
  __typename?: 'DefaultRule';
  constraints: Scalars['String'];
  createdAt: Scalars['Date'];
  exampleTxnHash?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  provider: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type DefiPositionDebt = {
  __typename?: 'DefiPositionDebt';
  accountId: Scalars['ID'];
  amount: Scalars['Float'];
  assetId?: Maybe<Scalars['String']>;
  assetKey: Scalars['String'];
  assetPriceCents?: Maybe<Scalars['Float']>;
  assetPricingKey: Scalars['String'];
  coingeckoId?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  dailyFiatAmountCents?: Maybe<Scalars['Float']>;
  dailyPercentage?: Maybe<Scalars['Float']>;
  dailyPercentageFormatted?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fiatAmountCents?: Maybe<Scalars['Float']>;
  groupKey: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  symbol: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type DefiPositionItem = {
  __typename?: 'DefiPositionItem';
  assetValueCents?: Maybe<Scalars['Float']>;
  debt: Array<DefiPositionDebt>;
  debtValueCents?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  netValueCents?: Maybe<Scalars['Float']>;
  pool?: Maybe<DefiPositionPool>;
  principals: Array<DefiPositionPrincipal>;
  rewards: Array<DefiPositionReward>;
};

export type DefiPositionPool = {
  __typename?: 'DefiPositionPool';
  contractAddress: Scalars['String'];
};

export type DefiPositionPrincipal = {
  __typename?: 'DefiPositionPrincipal';
  accountId: Scalars['ID'];
  amount: Scalars['Float'];
  assetId?: Maybe<Scalars['String']>;
  assetKey: Scalars['String'];
  assetPriceCents?: Maybe<Scalars['Float']>;
  assetPricingKey: Scalars['String'];
  coingeckoId?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  dailyFiatAmountCents?: Maybe<Scalars['Float']>;
  dailyPercentage?: Maybe<Scalars['Float']>;
  dailyPercentageFormatted?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fiatAmountCents?: Maybe<Scalars['Float']>;
  groupKey: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  symbol: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type DefiPositionReward = {
  __typename?: 'DefiPositionReward';
  accountId: Scalars['ID'];
  amount: Scalars['Float'];
  assetId?: Maybe<Scalars['String']>;
  assetKey: Scalars['String'];
  assetPriceCents?: Maybe<Scalars['Float']>;
  assetPricingKey: Scalars['String'];
  coingeckoId?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  dailyFiatAmountCents?: Maybe<Scalars['Float']>;
  dailyPercentage?: Maybe<Scalars['Float']>;
  dailyPercentageFormatted?: Maybe<Scalars['String']>;
  fiatAmountCents?: Maybe<Scalars['Float']>;
  groupKey: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  symbol: Scalars['String'];
};

export type DiscountInfo = {
  __typename?: 'DiscountInfo';
  amount: Scalars['String'];
  amountCents: Scalars['Float'];
  infoMessage?: Maybe<Scalars['String']>;
  numberOfSpam?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type DiscoveryCoingeckoToken = {
  __typename?: 'DiscoveryCoingeckoToken';
  assetKey: Scalars['String'];
  coinGeckoTokenId: Scalars['String'];
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  priceFormatted?: Maybe<Scalars['String']>;
  symbol: Scalars['String'];
  thumb: Scalars['String'];
  type: DiscoveryResultTypeEnum;
};

export type DiscoveryCoingeckoTrendingToken = {
  __typename?: 'DiscoveryCoingeckoTrendingToken';
  assetKey: Scalars['String'];
  coinGeckoTokenId: Scalars['String'];
  name: Scalars['String'];
  priceChangePercentage24h: Scalars['Float'];
  priceChangePercentage24hFormatted: Scalars['String'];
  priceFormatted: Scalars['String'];
  symbol: Scalars['String'];
  thumb: Scalars['String'];
  type: DiscoveryResultTypeEnum;
};

export enum DiscoveryResultTypeEnum {
  FungibleToken = 'FungibleToken',
  Nft = 'NFT'
}

export type Fee = {
  __typename?: 'Fee';
  amount?: Maybe<Scalars['Float']>;
  assetCreditId?: Maybe<Scalars['ID']>;
  assetDebitId?: Maybe<Scalars['ID']>;
  assetId?: Maybe<Scalars['String']>;
  basis?: Maybe<Scalars['Float']>;
  entries?: Maybe<Array<LedgerEntry>>;
  fiatAmountCents?: Maybe<Scalars['Float']>;
  fromReferenceId?: Maybe<Scalars['String']>;
  fullAsset?: Maybe<Asset>;
  id: Scalars['String'];
  income?: Maybe<Scalars['Float']>;
  isMissingBasis?: Maybe<Scalars['Boolean']>;
  isWithheldFee: Scalars['Boolean'];
  links?: Maybe<Array<LedgerEntryLink>>;
  originalAssetId?: Maybe<Scalars['String']>;
  originalFiatValue?: Maybe<Scalars['Float']>;
  originalFromReferenceId?: Maybe<Scalars['String']>;
  paidTxnFee: Scalars['Boolean'];
  payerAccount?: Maybe<Account>;
  payerAccountId?: Maybe<Scalars['String']>;
  proceeds?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  rawAsset?: Maybe<RawTransactionAsset>;
};

export type FilterFormCpa = {
  __typename?: 'FilterFormCPA';
  confirmed: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  description: Scalars['ID'];
  email: Scalars['ID'];
  headline: Scalars['ID'];
  id: Scalars['ID'];
  imgUrl: Scalars['ID'];
  isAcceptingBusinesses: Scalars['Boolean'];
  isAcceptingEth: Scalars['Boolean'];
  isAcceptingEthL2: Scalars['Boolean'];
  isAcceptingIndividuals: Scalars['Boolean'];
  isAcceptingOtherChains: Scalars['Boolean'];
  linkedin: Scalars['ID'];
  name: Scalars['ID'];
  numTxnsLowerBound: FilterFormNumTxnsEnum;
  numTxnsUpperBound: FilterFormNumTxnsEnum;
  twitter: Scalars['ID'];
  updatedAt: Scalars['Date'];
};

export enum FilterFormChainsEnum {
  Eth = 'Eth',
  EthL2 = 'EthL2',
  Other = 'Other'
}

export enum FilterFormClientEnum {
  Business = 'Business',
  Individual = 'Individual'
}

export enum FilterFormNumTxnsEnum {
  High = 'High',
  Low = 'Low',
  LowMid = 'LowMid',
  Mid = 'Mid',
  MidHigh = 'MidHigh'
}

export type FullLabelOption = {
  __typename?: 'FullLabelOption';
  category: Scalars['String'];
  infoMessage?: Maybe<Scalars['String']>;
  keywordsContains?: Maybe<Array<Scalars['String']>>;
  keywordsDoesNotStartWith?: Maybe<Array<Scalars['String']>>;
  keywordsStartsWith?: Maybe<Array<Scalars['String']>>;
  label: Scalars['String'];
  value: Scalars['String'];
};

export type GasMetrics = {
  __typename?: 'GasMetrics';
  fiatAmount: Scalars['String'];
  year: Scalars['Int'];
};

export type GetAccountWalletBalancesResponse = {
  __typename?: 'GetAccountWalletBalancesResponse';
  positions: Array<PortfolioAccountAssetPosition>;
};

export type GetAssetPositionsResponse = {
  __typename?: 'GetAssetPositionsResponse';
  avgCostBasisFiatAmountCents: Scalars['Float'];
  avgCostBasisFiatAmountFormatted: Scalars['String'];
  dailyFiatAmountCents?: Maybe<Scalars['Float']>;
  dailyFiatAmountFormatted?: Maybe<Scalars['String']>;
  dailyPercentage?: Maybe<Scalars['Float']>;
  dailyPercentageFormatted?: Maybe<Scalars['String']>;
  overallGainLossFiatAmountCents?: Maybe<Scalars['Float']>;
  overallGainLossFiatAmountFormatted?: Maybe<Scalars['String']>;
  overallGainLossPercentage?: Maybe<Scalars['Float']>;
  overallGainLossPercentageFormatted?: Maybe<Scalars['String']>;
  positions: Array<PortfolioAssetPosition>;
  showWarning: Scalars['Boolean'];
  totalAmount: Scalars['Float'];
  totalComputedAmount: Scalars['Float'];
  totalCostBasisFiatAmountCents: Scalars['Float'];
  totalCostBasisFiatAmountFormatted: Scalars['String'];
  totalFiatAmountCents: Scalars['Float'];
  totalFiatAmountFormatted: Scalars['String'];
};

export type GetChartResponse = {
  __typename?: 'GetChartResponse';
  points: Array<ChartPoint>;
  showWarning: Scalars['Boolean'];
};

export type GetClientLedgerAccountsResponse = {
  __typename?: 'GetClientLedgerAccountsResponse';
  accounts: Array<LedgerAccount>;
  tree: Array<LedgerAccount>;
};

export type GetCollectionInfoResponse = {
  __typename?: 'GetCollectionInfoResponse';
  avgCostBasisFiatAmountCents: Scalars['Float'];
  avgCostBasisFiatAmountFormatted: Scalars['String'];
  description: Scalars['String'];
  discordUrl?: Maybe<Scalars['String']>;
  floorPriceFiatAmountCents?: Maybe<Scalars['Float']>;
  floorPriceFiatAmountFormatted?: Maybe<Scalars['String']>;
  floorPriceNativeAmount?: Maybe<Scalars['Float']>;
  floorPriceNativeCryptocurrency?: Maybe<Scalars['String']>;
  floorPriceNativeCryptocurrencyImageUrl?: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  lastSalePriceCents?: Maybe<Scalars['Float']>;
  lastSalePriceCentsFormatted?: Maybe<Scalars['String']>;
  lastSalePriceDate?: Maybe<Scalars['Date']>;
  marketplaceImageUrl?: Maybe<Scalars['String']>;
  marketplaceName?: Maybe<Scalars['String']>;
  marketplaceUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nfts: Array<PortfolioNft>;
  overallGainLossFiatAmountCents?: Maybe<Scalars['Float']>;
  overallGainLossFiatAmountFormatted?: Maybe<Scalars['String']>;
  overallGainLossPercentage?: Maybe<Scalars['Float']>;
  overallGainLossPercentageFormatted?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  providerImageUrl?: Maybe<Scalars['String']>;
  showWarning: Scalars['Boolean'];
  totalAmount: Scalars['Float'];
  totalCostBasisFiatAmountCents: Scalars['Float'];
  totalCostBasisFiatAmountFormatted: Scalars['String'];
  totalFiatAmountCents: Scalars['Float'];
  totalFiatAmountFormatted: Scalars['String'];
  twitterUrl?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type GetConnectWalletLinkResponse = {
  __typename?: 'GetConnectWalletLinkResponse';
  connectUrl: Scalars['String'];
  provider: Scalars['String'];
};

export type GetDiscoveryResultsResponse = {
  __typename?: 'GetDiscoveryResultsResponse';
  resultsV1: Array<DiscoveryCoingeckoToken>;
};

export type GetDiscoverySplashResponse = {
  __typename?: 'GetDiscoverySplashResponse';
  resultsV1: Array<DiscoveryCoingeckoTrendingToken>;
};

export type GetEntryLinksResponse = {
  __typename?: 'GetEntryLinksResponse';
  fromLinks: Array<LedgerEntryLinkWithCapacity>;
  toLinks: Array<LedgerEntryLink>;
};

export type GetEstimatedCostBasisResponse = {
  __typename?: 'GetEstimatedCostBasisResponse';
  entries: Array<LedgerEntry>;
  totalCostBasisCents: Scalars['Int'];
};

export type GetHiddenAssetsResponse = {
  __typename?: 'GetHiddenAssetsResponse';
  hidden: Array<HiddenAsset>;
};

export type GetIncomeAndCapGainsResponse = {
  __typename?: 'GetIncomeAndCapGainsResponse';
  capGainsLongTerm: Scalars['String'];
  capGainsLongTermBasis?: Maybe<Scalars['String']>;
  capGainsLongTermProceeds?: Maybe<Scalars['String']>;
  capGainsShortTerm: Scalars['String'];
  capGainsShortTermBasis?: Maybe<Scalars['String']>;
  capGainsShortTermProceeds?: Maybe<Scalars['String']>;
  capGainsTotal: Scalars['String'];
  futuresExpenses: Scalars['String'];
  futuresFees: Scalars['String'];
  futuresNet: Scalars['String'];
  futuresProceeds: Scalars['String'];
  goodwill: Array<AccountInfoSummary>;
  goodwillTotal: Scalars['String'];
  hasActiveSubscription: Scalars['Boolean'];
  incomeBreakdown: Array<IncomeAccountSummary>;
  incomeTotal: Scalars['String'];
};

export type GetMyActiveSubscriptionResponse = {
  __typename?: 'GetMyActiveSubscriptionResponse';
  currentCountForTaxYear: Scalars['Float'];
  discountCalculation: ActiveSubscriptionDiscountCalculation;
  hasPaidForUnlimitedCX: Scalars['Boolean'];
  hasSubscription: Scalars['Boolean'];
  maxAllowedForTaxYear: Scalars['Float'];
  subscription?: Maybe<Subscription>;
  upgrades?: Maybe<Array<SubscriptionUpgrade>>;
};

export type GetMyReferralsResponse = {
  __typename?: 'GetMyReferralsResponse';
  claimable?: Maybe<Array<ReferralInfo>>;
  claimed?: Maybe<Array<ReferralInfo>>;
  count: Scalars['Int'];
};

export type GetNftPositionResponse = {
  __typename?: 'GetNFTPositionResponse';
  accounts: Array<Account>;
  costBasisFiatAmountCents?: Maybe<Scalars['Float']>;
  dates: Array<Scalars['Date']>;
  showWarning: Scalars['Boolean'];
};

export type GetNewsForTickersResponse = {
  __typename?: 'GetNewsForTickersResponse';
  news: Array<Maybe<NewsForTickers>>;
};

export type GetNextDefaultRulesResponse = {
  __typename?: 'GetNextDefaultRulesResponse';
  blockExplorerName?: Maybe<Scalars['String']>;
  blockExplorerUrl?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  constraints: Scalars['String'];
  exampleRuleId: Scalars['String'];
  exampleTxnHash: Scalars['String'];
  formattedType: Scalars['String'];
  numRules: Scalars['Float'];
  numType: Scalars['Float'];
  percentage: Scalars['Float'];
  provider: Scalars['String'];
  type: Scalars['String'];
};

export type GetNumTxnTypesResponse = {
  __typename?: 'GetNumTxnTypesResponse';
  biggestGainsLosses: Array<Transaction>;
  gasMetrics: Array<GasMetrics>;
  numTxnTypes: Array<TxnTypes>;
};

export type GetOtherUserLabelsResp = {
  __typename?: 'GetOtherUserLabelsResp';
  numUsers?: Maybe<Scalars['Int']>;
  ruleType?: Maybe<Scalars['String']>;
  shouldUse?: Maybe<Scalars['Boolean']>;
};

export type GetPaidTransactionOverviewResponse = {
  __typename?: 'GetPaidTransactionOverviewResponse';
  hasPaidForPeriod: Scalars['Boolean'];
  totalAvailable: Scalars['Int'];
  totalLeft: Scalars['Int'];
  totalLeftForPeriod: Scalars['Int'];
  totalUpToPeriod: Scalars['Int'];
  totalUsed: Scalars['Int'];
};

export type GetPnLResponse = {
  __typename?: 'GetPnLResponse';
  accounts: Array<ReportAccountInfo>;
  cogs?: Maybe<ReportAccountInfo>;
  grossProfit: Scalars['Int'];
  income?: Maybe<ReportAccountInfo>;
  netIncome: Scalars['Int'];
  netOperatingIncome: Scalars['Int'];
  netOtherIncome: Scalars['Int'];
  operationExpenses?: Maybe<ReportAccountInfo>;
  otherExpenses?: Maybe<ReportAccountInfo>;
  otherIncome?: Maybe<ReportAccountInfo>;
  revenue?: Maybe<ReportAccountInfo>;
  totalCogs: Scalars['Int'];
  totalIncome: Scalars['Int'];
  totalOperatingExpenses: Scalars['Int'];
  totalOtherExpenses: Scalars['Int'];
};

export type GetPortfolioValueResponse = {
  __typename?: 'GetPortfolioValueResponse';
  assets: Array<PartialAsset>;
  breakdown: Array<ChartPointBreakdown>;
  showWarning: Scalars['Boolean'];
  timestamp: Scalars['Date'];
  value: Scalars['Float'];
  valueCents: Scalars['Float'];
};

export type GetQuoteResponse = {
  __typename?: 'GetQuoteResponse';
  bestQuote: Quote;
  options: Array<Quote>;
  timestamp: Scalars['Date'];
};

export type GetReferralCommissionsResponse = {
  __typename?: 'GetReferralCommissionsResponse';
  commissions: Array<ReferralCommission>;
  referrals: Array<Referral>;
  total: Scalars['Int'];
  totalAvailableCents: Scalars['Float'];
  totalPaidCents: Scalars['Float'];
  totalPendingCents: Scalars['Float'];
};

export type GetReferralPayoutsResponse = {
  __typename?: 'GetReferralPayoutsResponse';
  payouts: Array<ReferralPayout>;
  total: Scalars['Int'];
};

export type GetReferralPrizesResponse = {
  __typename?: 'GetReferralPrizesResponse';
  available?: Maybe<Array<ReferralPrizeEnum>>;
  completed?: Maybe<Array<ReferralPrizeEnum>>;
};

export type GetSupportedWalletsResponse = {
  __typename?: 'GetSupportedWalletsResponse';
  accounts: Array<Account>;
  supported: Array<SupportWalletWithBalance>;
};

export type GetSwapTransactionResponse = {
  __typename?: 'GetSwapTransactionResponse';
  connectUrl?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  requiresSetup: Scalars['Boolean'];
  txn?: Maybe<Scalars['String']>;
  wallet: Scalars['String'];
};

export type GetSwapsForAssetResponse = {
  __typename?: 'GetSwapsForAssetResponse';
  pending: Array<Swap>;
  swaps: Array<Swap>;
};

export type GetTokenChartResponse = {
  __typename?: 'GetTokenChartResponse';
  points: Array<TokenChartPoint>;
};

export type GetTokenInfoResponse = {
  __typename?: 'GetTokenInfoResponse';
  about: GetTokenInfoResponseAbout;
  security: GetTokenInfoResponseSecurity;
  stats: GetTokenInfoResponseStats;
};

export type GetTokenInfoResponseAbout = {
  __typename?: 'GetTokenInfoResponseAbout';
  address: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  links: GetTokenOverviewResponseExtensions;
};

export type GetTokenInfoResponseSecurity = {
  __typename?: 'GetTokenInfoResponseSecurity';
  creationTime?: Maybe<Scalars['String']>;
  mintTime?: Maybe<Scalars['String']>;
};

export type GetTokenInfoResponseStats = {
  __typename?: 'GetTokenInfoResponseStats';
  marketCap?: Maybe<Scalars['Float']>;
  v24hUSD?: Maybe<Scalars['Float']>;
};

export type GetTokenOverviewResponseExtensions = {
  __typename?: 'GetTokenOverviewResponseExtensions';
  coingecko?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type GetTokenPriceResponse = {
  __typename?: 'GetTokenPriceResponse';
  currentPrice: Scalars['String'];
  currentPriceCents: Scalars['Float'];
};

export type GetTransactionImportJobs = {
  __typename?: 'GetTransactionImportJobs';
  active: Array<Job>;
  completed: Array<Job>;
  failed: Array<Job>;
};

export type GetTransactionsResponse = {
  __typename?: 'GetTransactionsResponse';
  total: Scalars['Int'];
  transactions: Array<Transaction>;
};

export enum GranularityEnum {
  All = 'All',
  Day = 'Day',
  Month = 'Month',
  ThreeMonth = 'ThreeMonth',
  Week = 'Week',
  Year = 'Year',
  YearToDate = 'YearToDate'
}

export type HiddenAsset = {
  __typename?: 'HiddenAsset';
  assetKey: Scalars['String'];
  assets: Array<Asset>;
  iconImageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
  type: AssetTypeEnum;
};

export enum HistoricalBalanceIntervalEnum {
  All = 'All',
  Day = 'Day',
  Month = 'Month',
  ThreeMonth = 'ThreeMonth',
  Week = 'Week',
  Year = 'Year',
  YearToDate = 'YearToDate'
}

export enum ImportTypeEnum {
  Address = 'Address',
  AwakenCsvFileUpload = 'AwakenCsvFileUpload',
  FileUpload = 'FileUpload',
  Hatchfi = 'Hatchfi',
  Manual = 'Manual',
  OauthToken = 'OauthToken',
  Plaid = 'Plaid',
  Vezgo = 'Vezgo',
  VirtualAccount = 'VirtualAccount'
}

export type IncomeAccountSummary = {
  __typename?: 'IncomeAccountSummary';
  accountName: Scalars['String'];
  amount: Scalars['String'];
  info: Scalars['String'];
};

export enum IsDirtyEnum {
  Clean = 'Clean',
  Dirty = 'Dirty',
  Recalculate = 'Recalculate'
}

export type Job = {
  __typename?: 'Job';
  completedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  failures?: Maybe<Array<Scalars['String']>>;
  finishEta?: Maybe<Scalars['Date']>;
  inngestRunId?: Maybe<Scalars['String']>;
  jobId: Scalars['ID'];
  logFileObjectKey?: Maybe<Scalars['String']>;
  logFileSignedUrl?: Maybe<Scalars['String']>;
  restoreFileObjectKey?: Maybe<Scalars['String']>;
  restoreFileSignedUrl?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
};

export type LabelOption = {
  __typename?: 'LabelOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type LedgerAccount = {
  __typename?: 'LedgerAccount';
  children?: Maybe<Array<LedgerAccount>>;
  classification: LedgerAccountClassificationEnum;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  level: Scalars['Int'];
  name: Scalars['String'];
  path: Scalars['String'];
  slug: Scalars['String'];
  subType?: Maybe<LedgerAccountSubTypeEnum>;
  type: LedgerAccountTypeEnum;
  updatedAt: Scalars['Date'];
};

export type LedgerAccountAssetCollectionInput = {
  id: Scalars['String'];
  isNew?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type LedgerAccountAssetInput = {
  collection?: InputMaybe<LedgerAccountAssetCollectionInput>;
  id?: InputMaybe<Scalars['ID']>;
  isNew: Scalars['Boolean'];
  name: Scalars['String'];
  type: AssetTypeEnum;
};

export enum LedgerAccountClassificationEnum {
  Asset = 'Asset',
  Equity = 'Equity',
  Expense = 'Expense',
  Liability = 'Liability',
  Revenue = 'Revenue'
}

export enum LedgerAccountSubTypeEnum {
  AccountsPayable = 'AccountsPayable',
  AccountsReceivable = 'AccountsReceivable',
  AirdropFees = 'AirdropFees',
  AirdropIncome = 'AirdropIncome',
  ApprovalFee = 'ApprovalFee',
  BridgingContributions = 'BridgingContributions',
  BridgingDistributions = 'BridgingDistributions',
  BridgingFees = 'BridgingFees',
  CancellationFee = 'CancellationFee',
  CashAssets = 'CashAssets',
  CoinAsset = 'CoinAsset',
  Contributions = 'Contributions',
  CurrentLiabilities = 'CurrentLiabilities',
  Default = 'Default',
  DerivativesProceeds = 'DerivativesProceeds',
  Distributions = 'Distributions',
  DonationFees = 'DonationFees',
  Donations = 'Donations',
  Error = 'Error',
  FailedTransactionFee = 'FailedTransactionFee',
  FeeExpenseDeduction = 'FeeExpenseDeduction',
  Funds = 'Funds',
  FuturesFees = 'FuturesFees',
  FuturesPositionExpenses = 'FuturesPositionExpenses',
  FuturesProceeds = 'FuturesProceeds',
  Gifts = 'Gifts',
  IncomeExpenseFees = 'IncomeExpenseFees',
  InterestIncome = 'InterestIncome',
  Investments = 'Investments',
  LoanExpenseFees = 'LoanExpenseFees',
  LoanLiabilities = 'LoanLiabilities',
  LoanPayable = 'LoanPayable',
  MiningIncome = 'MiningIncome',
  NftAsset = 'NFTAsset',
  OtherCurrentAssets = 'OtherCurrentAssets',
  OtherFees = 'OtherFees',
  OwnersEquity = 'OwnersEquity',
  PartnerContributions = 'PartnerContributions',
  PeerToPeerDistributions = 'PeerToPeerDistributions',
  ProtocolFee = 'ProtocolFee',
  RewardsIncome = 'RewardsIncome',
  Sales = 'Sales',
  ScamsOrFraud = 'ScamsOrFraud',
  ServiceIncome = 'ServiceIncome',
  StakingIncome = 'StakingIncome',
  SwappingFee = 'SwappingFee',
  TransferFees = 'TransferFees',
  TransferlessGasFees = 'TransferlessGasFees',
  UnCategorizedAssets = 'UnCategorizedAssets',
  UnCategorizedIncome = 'UnCategorizedIncome',
  Unknown = 'Unknown',
  UnknownContributions = 'UnknownContributions'
}

export enum LedgerAccountTypeEnum {
  AccountsPayable = 'AccountsPayable',
  AccountsReceivable = 'AccountsReceivable',
  CostOfGoodsSold = 'CostOfGoodsSold',
  CurrentAsset = 'CurrentAsset',
  Equity = 'Equity',
  Expense = 'Expense',
  FixedAsset = 'FixedAsset',
  Income = 'Income',
  LongTermLiability = 'LongTermLiability',
  OperationExpense = 'OperationExpense',
  OtherAsset = 'OtherAsset',
  OtherExpense = 'OtherExpense',
  OtherIncome = 'OtherIncome',
  OtherLiability = 'OtherLiability',
  RewardExpense = 'RewardExpense',
  Unknown = 'Unknown'
}

export type LedgerEntry = {
  __typename?: 'LedgerEntry';
  account?: Maybe<Account>;
  accountId: Scalars['String'];
  addedAt: Scalars['Date'];
  amount: Scalars['Float'];
  asset?: Maybe<Asset>;
  classification?: Maybe<LedgerAccountClassificationEnum>;
  client?: Maybe<Client>;
  createdAt: Scalars['Date'];
  direction: LedgerEntryDirectionEnum;
  fiatAmount: Scalars['Float'];
  fiatCurrency: CurrencyCodeEnum;
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  ledgerAccount?: Maybe<LedgerAccount>;
  ledgerAccountId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  relatedFeeId?: Maybe<Scalars['String']>;
  relatedTransferId?: Maybe<Scalars['String']>;
  transaction: Transaction;
  transactionId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum LedgerEntryDirectionEnum {
  Credit = 'Credit',
  Debit = 'Debit'
}

export type LedgerEntryInput = {
  amount: Scalars['Float'];
  assetId?: InputMaybe<Scalars['ID']>;
  fiatAmount: Scalars['Float'];
  fiatCurrency: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  insertionId?: InputMaybe<Scalars['String']>;
  ledgerAccountId: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
};

export type LedgerEntryLabelInput = {
  labelId: Scalars['ID'];
};

export type LedgerEntryLink = {
  __typename?: 'LedgerEntryLink';
  createdAt: Scalars['Date'];
  cryptoAmountUsed?: Maybe<Scalars['Float']>;
  fiatAmountUsed: Scalars['Int'];
  fiatCurrency: CurrencyCodeEnum;
  from: LedgerEntry;
  id: Scalars['String'];
  to?: Maybe<LedgerEntry>;
  transaction?: Maybe<Transaction>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type LedgerEntryLinkInput = {
  cryptoAmountUsed: Scalars['Float'];
  fiatAmountUsed: Scalars['Int'];
  fromEntryId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  toEntryId?: InputMaybe<Scalars['String']>;
};

export type LedgerEntryLinkWithCapacity = {
  __typename?: 'LedgerEntryLinkWithCapacity';
  createdAt: Scalars['Date'];
  cryptoAmountAvailable: Scalars['Float'];
  cryptoAmountUsed: Scalars['Float'];
  cryptoTotalAmount: Scalars['Float'];
  fiatAmountAvailable: Scalars['Float'];
  fiatAmountTotal: Scalars['Float'];
  fiatAmountUsed: Scalars['Float'];
  fiatCurrency: CurrencyCodeEnum;
  from: LedgerEntry;
  id: Scalars['String'];
  links?: Maybe<Array<LedgerEntryLinkWithCapacity>>;
  to?: Maybe<LedgerEntry>;
  transaction?: Maybe<Transaction>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type LedgerEntryReferenceInput = {
  ledgerEntryId: Scalars['ID'];
};

export type LedgerEntryWithCapacity = {
  __typename?: 'LedgerEntryWithCapacity';
  cryptoAmountAvailable: Scalars['Float'];
  cryptoAmountUsed: Scalars['Float'];
  cryptoTotalAmount: Scalars['Float'];
  entry: LedgerEntry;
  fiatAmountAvailable: Scalars['Float'];
  fiatAmountTotal: Scalars['Float'];
  links: Array<LedgerEntryLink>;
};

export type LedgerReference = {
  __typename?: 'LedgerReference';
  createdAt: Scalars['Date'];
  from: LedgerEntry;
  id: Scalars['String'];
  to: LedgerEntry;
};

export enum LedgerTransactionReviewStatusEnum {
  NeedsReview = 'NeedsReview',
  Reviewed = 'Reviewed'
}

export enum LedgerTransactionStatusEnum {
  Canceled = 'Canceled',
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending',
  Unknown = 'Unknown'
}

export enum LedgerTransactionTypeEnum {
  Buy = 'Buy',
  Sell = 'Sell',
  Transfer = 'Transfer',
  Unknown = 'Unknown'
}

export type LineItem = {
  priceId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type MagicEdenDiscountResponse = {
  __typename?: 'MagicEdenDiscountResponse';
  accountIds: Array<Scalars['ID']>;
  hasClaimed: Scalars['Boolean'];
  isEligible: Scalars['Boolean'];
  totalFreeTransactionCount: Scalars['Int'];
};

export type MonthlyDataPoint = {
  __typename?: 'MonthlyDataPoint';
  longTermCapGainsCents: Scalars['Float'];
  netIncomeCents: Scalars['Float'];
  netIncomeFormatted: Scalars['String'];
  shortTermCapGainsCents: Scalars['Float'];
  timestamp: Scalars['Date'];
  totalCapGainsCents: Scalars['Float'];
  totalCapGainsFormatted: Scalars['String'];
};

export type MonthlySummaryResponse = {
  __typename?: 'MonthlySummaryResponse';
  email: Scalars['String'];
  emailBody: Scalars['String'];
  emailSubject: Scalars['String'];
  longTermCapGainsCents: Scalars['Float'];
  netIncomeCents: Scalars['Float'];
  numHighPriority: Scalars['Int'];
  numMissingBasis: Scalars['Int'];
  numNeedingReview: Scalars['Int'];
  numReviewed: Scalars['Int'];
  shortTermCapGainsCents: Scalars['Float'];
  timestamp: Scalars['Date'];
  totalCapGainsCents: Scalars['Float'];
  unlabeledTransactionUrl: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAssetHolding: AssetHolding;
  addClientMember: ClientPermission;
  addPricedAsset: Scalars['String'];
  applyForCreditMatch: Client;
  applyGMEDiscount: ApplyGmeDiscountResponse;
  applyMagicEdenDiscount: Scalars['String'];
  checkGMEDiscount: Scalars['Float'];
  checkMagicEdenDiscount: MagicEdenDiscountResponse;
  claimCode: ClaimCodeResponse;
  claimReferral: Referral;
  confirmCoinbaseID: Scalars['String'];
  createAccount: Array<Account>;
  createAccountGroup: AccountGroup;
  createAsset: Asset;
  createAssetV2: Asset;
  createBatchWallets: Array<Account>;
  createClient: Client;
  createClientLedgerAccount: LedgerAccount;
  createDefaultRule: DefaultRule;
  createFilterFormCPA: Scalars['ID'];
  createFilterFormSubmission: Scalars['ID'];
  createSpamAsset: SpamAsset;
  createSubscription: Subscription;
  createSwap: Swap;
  createTransaction: Transaction;
  createTransfer: Transaction;
  createUser: User;
  createUserWithEmail: CreateUserWithEmailResponse;
  createWatchlistAssetForUser: Scalars['String'];
  deleteAccount?: Maybe<Account>;
  deleteAccountGroup?: Maybe<Array<Account>>;
  deleteClient: Scalars['Boolean'];
  deleteMe: Scalars['Boolean'];
  deleteSubscription: Subscription;
  deleteUser: Scalars['Boolean'];
  deleteWatchlistAssetForUser: Scalars['String'];
  downloadAccountFile: Scalars['String'];
  freeSubscription: Subscription;
  generateBookkeepingInvoice: Scalars['String'];
  getOAuthLink: OAuthLinkResponse;
  getOAuthToken?: Maybe<Scalars['String']>;
  getPaymentLink: Scalars['String'];
  getVezgoLink: VezgoTokenResponse;
  getWaitlistStatus?: Maybe<Scalars['String']>;
  giveCredit: Client;
  hardRefreshAccount: Account;
  hideTransaction: Transaction;
  joinTradingBeta?: Maybe<Scalars['String']>;
  labelTransactions: Array<Transaction>;
  linkAccountWithWallet: Account;
  markAssetKeyWorthless: Scalars['String'];
  payWithCryptoLink: Scalars['String'];
  purchaseUpgrade: Scalars['String'];
  redeemReferralPrize: Scalars['String'];
  refreshPortfolio?: Maybe<Scalars['String']>;
  refreshPortfolioBalances?: Maybe<Scalars['String']>;
  removeClientMember: Scalars['Boolean'];
  removePricedAsset: Scalars['String'];
  removeRule: Scalars['String'];
  renameAccount: Account;
  reportUnpricedAsset: Scalars['String'];
  requestBookkeepingQuote: BookkeepingQuote;
  requestPayout: ReferralPayout;
  rerunGraph: Scalars['String'];
  ruleToDefaultRule: DefaultRule;
  sendSlackNotification: Scalars['String'];
  setAssetSpam: Asset;
  setMyActiveClient: Client;
  startCheckout: Scalars['String'];
  startSubscriptionCheckout: StartSubscriptionCheckoutResponse;
  startUpgradeCheckout: StartUpgradeCheckoutResponse;
  submitFeedback: Scalars['String'];
  submitPortfolioBeta?: Maybe<Scalars['String']>;
  submitPortfolioFeedback: Scalars['String'];
  submitPortfolioWaitlistEntry?: Maybe<Scalars['String']>;
  summarizeTransaction: TransactionSummary;
  syncAccount: Account;
  syncAllClientAccounts: SyncAllClientAccountsResponse;
  syncAssetMetadata: Array<Asset>;
  syncTransaction: Transaction;
  toggleLockClient: Scalars['String'];
  unenrollTwoFactor: Scalars['String'];
  unhideTransfers: Scalars['String'];
  updateAccount: Account;
  updateAccounts?: Maybe<Scalars['String']>;
  updateAsset: Asset;
  updateAssetKey: Scalars['String'];
  updateBookkeepingQuote: BookkeepingQuote;
  updateClient: Client;
  updateClientCostBasisAlgorithm: Client;
  updateClientUser: Client;
  updateCoinbaseID: UpdateCoinbaseIdResponse;
  updateFee: Transaction;
  updateLedgerAccount: LedgerAccount;
  updateTransaction: UpdateTransactionResponse;
  updateTransfer: Transaction;
  updateUser: User;
  uploadFile: UploadFileResponse;
  uploadManualTransactions: Scalars['String'];
  upsertNftAsset: Array<Asset>;
  verifyBiometric: VerifyBiometricResponse;
  verifyRecaptcha: Scalars['Boolean'];
};


export type MutationAddAssetHoldingArgs = {
  amount: Scalars['Float'];
  assetId: Scalars['ID'];
};


export type MutationAddClientMemberArgs = {
  clientId: Scalars['ID'];
  email: Scalars['String'];
  whiteLabelUrl?: InputMaybe<Scalars['String']>;
};


export type MutationAddPricedAssetArgs = {
  coingeckoId: Scalars['String'];
  contractAddress: Scalars['String'];
  provider: Scalars['String'];
  symbol: Scalars['String'];
};


export type MutationApplyForCreditMatchArgs = {
  clientId: Scalars['ID'];
  creditMatchFileKey: Scalars['String'];
};


export type MutationApplyGmeDiscountArgs = {
  clientId: Scalars['String'];
};


export type MutationApplyMagicEdenDiscountArgs = {
  clientId: Scalars['ID'];
};


export type MutationCheckGmeDiscountArgs = {
  clientId: Scalars['String'];
};


export type MutationCheckMagicEdenDiscountArgs = {
  clientId: Scalars['ID'];
};


export type MutationClaimCodeArgs = {
  clientId: Scalars['ID'];
  referralCode: Scalars['String'];
};


export type MutationClaimReferralArgs = {
  referralId: Scalars['ID'];
};


export type MutationConfirmCoinbaseIdArgs = {
  clientId: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  address?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  fileObjectKey?: InputMaybe<Scalars['String']>;
  importType: ImportTypeEnum;
  includeOrdinals?: InputMaybe<Scalars['Boolean']>;
  includeStakingRewards?: InputMaybe<Scalars['Boolean']>;
  isLargeAccount?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  plaidPublicToken?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  redirectUrl?: InputMaybe<Scalars['String']>;
  shouldUploadAllEVM?: InputMaybe<Scalars['Boolean']>;
  skipRecalculate?: InputMaybe<Scalars['Boolean']>;
  type: AccountTypeEnum;
};


export type MutationCreateAccountGroupArgs = {
  clientId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateAssetArgs = {
  clientId: Scalars['ID'];
  coinGeckoTokenId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  provider: Scalars['String'];
  symbol?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssetTypeEnum>;
};


export type MutationCreateAssetV2Args = {
  clientId: Scalars['ID'];
  coinGeckoTokenId?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  provider: Scalars['String'];
  symbol?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssetTypeEnum>;
};


export type MutationCreateBatchWalletsArgs = {
  clientId: Scalars['ID'];
  skipRecalculate?: InputMaybe<Scalars['Boolean']>;
  wallets: Array<BatchWalletInputParams>;
};


export type MutationCreateClientArgs = {
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  referredDomainUrl: Scalars['String'];
};


export type MutationCreateClientLedgerAccountArgs = {
  clientId: Scalars['ID'];
  name: Scalars['String'];
  parentLedgerAccountId: Scalars['ID'];
};


export type MutationCreateDefaultRuleArgs = {
  exampleRuleId: Scalars['String'];
  label: Scalars['String'];
};


export type MutationCreateFilterFormCpaArgs = {
  confirmed: Scalars['Boolean'];
  description: Scalars['String'];
  email: Scalars['String'];
  headline: Scalars['String'];
  imgUrl: Scalars['String'];
  isAcceptingBusinesses: Scalars['Boolean'];
  isAcceptingEth: Scalars['Boolean'];
  isAcceptingEthL2: Scalars['Boolean'];
  isAcceptingIndividuals: Scalars['Boolean'];
  isAcceptingOtherChains: Scalars['Boolean'];
  linkedin: Scalars['String'];
  name: Scalars['String'];
  numTxnsLowerBound: FilterFormNumTxnsEnum;
  numTxnsUpperBound: FilterFormNumTxnsEnum;
  twitter: Scalars['String'];
};


export type MutationCreateFilterFormSubmissionArgs = {
  chainsType: FilterFormChainsEnum;
  clientType: FilterFormClientEnum;
  email: Scalars['String'];
  name: Scalars['String'];
  numTxns: FilterFormNumTxnsEnum;
};


export type MutationCreateSpamAssetArgs = {
  assetName: Scalars['String'];
  contractAddress: Scalars['String'];
  exampleTxnHash: Scalars['String'];
  isSpam?: InputMaybe<Scalars['Boolean']>;
  provider: AccountProviderEnum;
};


export type MutationCreateSubscriptionArgs = {
  clientId: Scalars['ID'];
  txnCeiling: Scalars['Float'];
};


export type MutationCreateSwapArgs = {
  data: Scalars['String'];
  nonce?: InputMaybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};


export type MutationCreateTransactionArgs = {
  accountId: Scalars['ID'];
  clientId: Scalars['ID'];
  createdAt: Scalars['Date'];
  txnHash?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTransferArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  assetId: Scalars['ID'];
  fiatValue?: InputMaybe<Scalars['Float']>;
  fromAccountId?: InputMaybe<Scalars['ID']>;
  toAccountId?: InputMaybe<Scalars['ID']>;
  transactionId: Scalars['ID'];
};


export type MutationCreateUserArgs = {
  avatarImageUrl?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  isMobile?: InputMaybe<Scalars['Boolean']>;
  linkToken?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referredDomainUrl?: InputMaybe<Scalars['String']>;
};


export type MutationCreateUserWithEmailArgs = {
  email: Scalars['String'];
  isMobile?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referredDomainUrl?: InputMaybe<Scalars['String']>;
};


export type MutationCreateWatchlistAssetForUserArgs = {
  contractAddress: Scalars['String'];
  provider: AccountProviderEnum;
  userId: Scalars['String'];
};


export type MutationDeleteAccountArgs = {
  accountId: Scalars['ID'];
};


export type MutationDeleteAccountGroupArgs = {
  clientId: Scalars['ID'];
  walletAddress: Scalars['String'];
};


export type MutationDeleteClientArgs = {
  clientId: Scalars['ID'];
};


export type MutationDeleteSubscriptionArgs = {
  subscriptionId: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  email: Scalars['String'];
};


export type MutationDeleteWatchlistAssetForUserArgs = {
  contractAddress: Scalars['String'];
  provider: AccountProviderEnum;
  userId: Scalars['String'];
};


export type MutationDownloadAccountFileArgs = {
  accountId: Scalars['ID'];
};


export type MutationFreeSubscriptionArgs = {
  clientId: Scalars['ID'];
  notes: Scalars['String'];
};


export type MutationGenerateBookkeepingInvoiceArgs = {
  bookkeepingQuoteId: Scalars['ID'];
  monthlyAmountCents: Scalars['Float'];
  upfrontAmountCents: Scalars['Float'];
};


export type MutationGetOAuthLinkArgs = {
  clientId: Scalars['ID'];
  provider: Scalars['String'];
};


export type MutationGetOAuthTokenArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  clientId: Scalars['ID'];
  code: Scalars['String'];
  provider: Scalars['String'];
};


export type MutationGetPaymentLinkArgs = {
  amountCents: Scalars['Int'];
  clientId: Scalars['ID'];
  maxTransactionsCeiling?: InputMaybe<Scalars['Int']>;
};


export type MutationGetVezgoLinkArgs = {
  clientId: Scalars['ID'];
  provider: Scalars['String'];
};


export type MutationGiveCreditArgs = {
  clientId: Scalars['ID'];
  newCreditCents: Scalars['Float'];
};


export type MutationHardRefreshAccountArgs = {
  accountId: Scalars['ID'];
  shouldApplyCurrentEdits?: InputMaybe<Scalars['Boolean']>;
};


export type MutationHideTransactionArgs = {
  isHidden: Scalars['Boolean'];
  transactionId: Scalars['ID'];
};


export type MutationLabelTransactionsArgs = {
  label: Scalars['String'];
  transactionIds: Array<Scalars['ID']>;
};


export type MutationLinkAccountWithWalletArgs = {
  accountId: Scalars['ID'];
  walletEncryptedData?: InputMaybe<Scalars['String']>;
  walletNonce?: InputMaybe<Scalars['String']>;
  walletProvider: Scalars['String'];
  walletPublicKey?: InputMaybe<Scalars['String']>;
};


export type MutationMarkAssetKeyWorthlessArgs = {
  assetKey: Scalars['String'];
  clientId: Scalars['ID'];
  isWorthless: Scalars['Boolean'];
};


export type MutationPayWithCryptoLinkArgs = {
  clientId: Scalars['ID'];
  maxTransactionsCeiling: Scalars['Int'];
};


export type MutationPurchaseUpgradeArgs = {
  clientId: Scalars['ID'];
};


export type MutationRedeemReferralPrizeArgs = {
  clientId: Scalars['ID'];
  prize: ReferralPrizeEnum;
};


export type MutationRefreshPortfolioArgs = {
  canUseBalanceCache?: InputMaybe<Scalars['Boolean']>;
  canUsePriceCache?: InputMaybe<Scalars['Boolean']>;
  clientId: Scalars['ID'];
};


export type MutationRefreshPortfolioBalancesArgs = {
  clientId: Scalars['ID'];
};


export type MutationRemoveClientMemberArgs = {
  id: Scalars['ID'];
};


export type MutationRemovePricedAssetArgs = {
  contractAddress: Scalars['String'];
  provider: Scalars['String'];
};


export type MutationRemoveRuleArgs = {
  ruleId: Scalars['ID'];
};


export type MutationRenameAccountArgs = {
  accountId: Scalars['ID'];
  newName: Scalars['String'];
};


export type MutationReportUnpricedAssetArgs = {
  assetId: Scalars['ID'];
  chain: Scalars['String'];
  contractAddress?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
};


export type MutationRequestBookkeepingQuoteArgs = {
  clientId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  taxYearsDescription?: InputMaybe<Scalars['String']>;
};


export type MutationRequestPayoutArgs = {
  clientId: Scalars['ID'];
};


export type MutationRerunGraphArgs = {
  clientId: Scalars['ID'];
  doNotReplay?: InputMaybe<Scalars['Boolean']>;
  useSpeedup?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRuleToDefaultRuleArgs = {
  label: Scalars['String'];
  transactionId: Scalars['ID'];
};


export type MutationSendSlackNotificationArgs = {
  message: Scalars['String'];
};


export type MutationSetAssetSpamArgs = {
  assetId: Scalars['ID'];
  isSpam: Scalars['Boolean'];
};


export type MutationSetMyActiveClientArgs = {
  clientId: Scalars['String'];
};


export type MutationStartCheckoutArgs = {
  clientId: Scalars['ID'];
  lineItems: Array<LineItem>;
  promoCode?: InputMaybe<Scalars['String']>;
};


export type MutationStartSubscriptionCheckoutArgs = {
  clientId: Scalars['ID'];
  txnCeiling: Scalars['Float'];
};


export type MutationStartUpgradeCheckoutArgs = {
  clientId: Scalars['ID'];
};


export type MutationSubmitFeedbackArgs = {
  feedback: Scalars['String'];
};


export type MutationSubmitPortfolioFeedbackArgs = {
  message: Scalars['String'];
};


export type MutationSubmitPortfolioWaitlistEntryArgs = {
  email: Scalars['String'];
};


export type MutationSummarizeTransactionArgs = {
  transactionId: Scalars['ID'];
};


export type MutationSyncAccountArgs = {
  accountId: Scalars['ID'];
  isProgressiveSync?: InputMaybe<Scalars['Boolean']>;
  shouldGetLastImportedAt?: InputMaybe<Scalars['Boolean']>;
  skipRecalculate?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSyncAllClientAccountsArgs = {
  clientId: Scalars['ID'];
  isContinuousSync: Scalars['Boolean'];
};


export type MutationSyncAssetMetadataArgs = {
  clientId: Scalars['ID'];
};


export type MutationSyncTransactionArgs = {
  transactionId: Scalars['ID'];
};


export type MutationToggleLockClientArgs = {
  clientId: Scalars['ID'];
  isLocked: Scalars['Boolean'];
};


export type MutationUnhideTransfersArgs = {
  transactionId: Scalars['ID'];
};


export type MutationUpdateAccountArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
  accountGroupId?: InputMaybe<Scalars['ID']>;
  accountId: Scalars['ID'];
  refreshAccessToken?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAccountsArgs = {
  clientId: Scalars['ID'];
};


export type MutationUpdateAssetArgs = {
  assetId: Scalars['ID'];
  coingeckoId?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isSpam?: InputMaybe<Scalars['Boolean']>;
  isWorthless?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  overrideCurrentValue?: InputMaybe<Scalars['Float']>;
  symbol?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssetTypeEnum>;
  useAwakenPrice?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateAssetKeyArgs = {
  assetKey: Scalars['String'];
  clientId: Scalars['ID'];
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isSpam?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateBookkeepingQuoteArgs = {
  bookkeepingQuoteId: Scalars['ID'];
  hasPaid?: InputMaybe<Scalars['Boolean']>;
  monthlyPriceCents?: InputMaybe<Scalars['Int']>;
  paymentFrequency?: InputMaybe<Scalars['String']>;
  quoteFileUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<BookkeepingQuoteStatusEnum>;
  upfrontPriceCents?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateClientArgs = {
  accountantNotes?: InputMaybe<Scalars['String']>;
  accountantStatus?: InputMaybe<ClientAccountantStatusEnum>;
  clientId: Scalars['ID'];
  country?: InputMaybe<CountryEnum>;
  currency?: InputMaybe<CurrencyCodeEnum>;
  defaultToFloor?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  paypalUsername?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateClientCostBasisAlgorithmArgs = {
  clientId: Scalars['ID'];
  costBasisAlgorithm: CostBasisAlgorithmEnum;
};


export type MutationUpdateClientUserArgs = {
  clientId: Scalars['ID'];
  newUserId: Scalars['ID'];
};


export type MutationUpdateCoinbaseIdArgs = {
  clientId: Scalars['String'];
  coinbaseID: Scalars['String'];
};


export type MutationUpdateFeeArgs = {
  feeId: Scalars['ID'];
  fiatCurrency?: InputMaybe<Scalars['String']>;
  fiatValue?: InputMaybe<Scalars['Float']>;
  transactionId: Scalars['ID'];
};


export type MutationUpdateLedgerAccountArgs = {
  ledgerAccountId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateTransactionArgs = {
  createDefaultRule: Scalars['Boolean'];
  transactionId: Scalars['ID'];
  updates: UpdateTransactionInput;
};


export type MutationUpdateTransferArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  assetId?: InputMaybe<Scalars['String']>;
  basisFiatValue?: InputMaybe<Scalars['Float']>;
  fiatCurrency?: InputMaybe<Scalars['String']>;
  fiatValue?: InputMaybe<Scalars['Float']>;
  fromAccountId?: InputMaybe<Scalars['ID']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  originalFiatValue?: InputMaybe<Scalars['Float']>;
  resetFiatValue?: InputMaybe<Scalars['Boolean']>;
  toAccountId?: InputMaybe<Scalars['ID']>;
  transactionId: Scalars['ID'];
  transferId: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  biometricPublicKey?: InputMaybe<Scalars['String']>;
  hasMobile?: InputMaybe<Scalars['Boolean']>;
  hasPushNotifications?: InputMaybe<Scalars['Boolean']>;
  hasTwoFactorAuth?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUploadFileArgs = {
  clientId: Scalars['ID'];
  expiresInSeconds?: InputMaybe<Scalars['Int']>;
  file: Scalars['Upload'];
};


export type MutationUploadManualTransactionsArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  clientId?: InputMaybe<Scalars['String']>;
  fileObjectKey: Scalars['String'];
  provider?: InputMaybe<Scalars['String']>;
};


export type MutationUpsertNftAssetArgs = {
  assetKey: Scalars['String'];
  clientId: Scalars['ID'];
  contractAddress: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isSpam?: InputMaybe<Scalars['Boolean']>;
  isWorthless?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  overrideCurrentValue?: InputMaybe<Scalars['Float']>;
  provider: Scalars['String'];
  tokenId?: InputMaybe<Scalars['String']>;
  useAwakenPrice?: InputMaybe<Scalars['Boolean']>;
};


export type MutationVerifyBiometricArgs = {
  payload: Scalars['String'];
  signature: Scalars['String'];
};


export type MutationVerifyRecaptchaArgs = {
  ipAddress?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  version: Scalars['String'];
};

export type NeedsReview = {
  __typename?: 'NeedsReview';
  errors: Array<Maybe<NeedsReviewError>>;
  needsRecalculate: Scalars['Boolean'];
  transferErrors: Array<NeedsReviewTransferError>;
};

export enum NeedsReviewError {
  EntryUnlabeledNftSale = 'EntryUnlabeledNFTSale',
  IsMissingBasis = 'IsMissingBasis',
  Unlabeled = 'Unlabeled'
}

export type NeedsReviewTransferError = {
  __typename?: 'NeedsReviewTransferError';
  error: NeedsReviewError;
  transferId: Scalars['String'];
};

export type NewsForTickers = {
  __typename?: 'NewsForTickers';
  date: Scalars['String'];
  imageUrl: Scalars['String'];
  newsUrl: Scalars['String'];
  sentiment: Scalars['String'];
  sourceName: Scalars['String'];
  text: Scalars['String'];
  tickers: Array<Maybe<Scalars['String']>>;
  title: Scalars['String'];
  topics: Array<Maybe<Scalars['String']>>;
  type: Scalars['String'];
};

export type NumTxnsResponse = {
  __typename?: 'NumTxnsResponse';
  high: Scalars['Int'];
  insignificant: Scalars['Int'];
  low: Scalars['Int'];
  medium: Scalars['Int'];
  reviewed: Scalars['Int'];
  total: Scalars['Int'];
  unknown: Scalars['Int'];
  unreviewed: Scalars['Int'];
};

export type OAuthLinkResponse = {
  __typename?: 'OAuthLinkResponse';
  url?: Maybe<Scalars['String']>;
};

export type Pack = {
  __typename?: 'Pack';
  id: Scalars['ID'];
};

export type PacksForClientResponse = {
  __typename?: 'PacksForClientResponse';
  packs: Array<Pack>;
  totalAvailable: Scalars['Int'];
  totalUsed: Scalars['Int'];
};

export type PartialAsset = {
  __typename?: 'PartialAsset';
  iconImageUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
  type: AssetTypeEnum;
};

export type PartialPortfolioAsset = {
  __typename?: 'PartialPortfolioAsset';
  amount: Scalars['Float'];
  asset: Asset;
  basisFiatAmount: Scalars['Float'];
  currentFiatAmount?: Maybe<Scalars['Float']>;
  estimatedCurrentFiatAmount?: Maybe<Scalars['Float']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  last4: Scalars['String'];
  paymentMethodId: Scalars['String'];
  source: Scalars['String'];
};

export type PendingUser = {
  __typename?: 'PendingUser';
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum PermissionLevelEnum {
  Read = 'Read',
  Write = 'Write'
}

export type PortfolioAccountAssetPosition = {
  __typename?: 'PortfolioAccountAssetPosition';
  amount?: Maybe<Scalars['Float']>;
  assetKey: Scalars['String'];
  canTradeToken: Scalars['Boolean'];
  contractAddress?: Maybe<Scalars['String']>;
  costBasisCents?: Maybe<Scalars['Float']>;
  fiatAmountCents?: Maybe<Scalars['Float']>;
  iconImageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
  token?: Maybe<Token>;
};

export type PortfolioAssetPosition = {
  __typename?: 'PortfolioAssetPosition';
  accountId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  costBasisCents?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  descriptionImageUrl?: Maybe<Scalars['String']>;
  fiatAmountCents?: Maybe<Scalars['Float']>;
  groupKey: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  positionDescription?: Maybe<Scalars['String']>;
  positionType?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type PortfolioBalance = {
  __typename?: 'PortfolioBalance';
  accountIds: Array<Scalars['ID']>;
  amount: Scalars['Float'];
  asset: Asset;
  assetKey: Scalars['String'];
  avgBasisFiatAmount?: Maybe<Scalars['Float']>;
  breakdown: Array<AssetAccountBreakdown>;
  costBasisFiatAmount: Scalars['Float'];
  currentFiatAmount?: Maybe<Scalars['Float']>;
  currentTokenPriceFiatAmount?: Maybe<Scalars['Float']>;
  estimatedCurrentValue: Scalars['Float'];
  gainOrLossFiatAmount?: Maybe<Scalars['Float']>;
  hasPrice: Scalars['Boolean'];
  percentChangeAllTime?: Maybe<Scalars['Float']>;
};

export type PortfolioBalanceV2 = {
  __typename?: 'PortfolioBalanceV2';
  assetId?: Maybe<Scalars['String']>;
  assetKey: Scalars['String'];
  assetPriceCents?: Maybe<Scalars['Float']>;
  assetPricingKey: Scalars['String'];
  calculatedAmount?: Maybe<Scalars['Float']>;
  coinGeckoId?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  costBasisCents?: Maybe<Scalars['Float']>;
  dailyFiatAmountCents?: Maybe<Scalars['Float']>;
  dailyPercentage?: Maybe<Scalars['Float']>;
  dailyPercentageFormatted?: Maybe<Scalars['String']>;
  gainLossCents?: Maybe<Scalars['Float']>;
  groupKey: Scalars['String'];
  hasPrice: Scalars['Boolean'];
  iconImageUrl?: Maybe<Scalars['String']>;
  isMissingBasis?: Maybe<Scalars['Boolean']>;
  isSpam?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  percentOfPortfolio?: Maybe<Scalars['Float']>;
  positionPercentage?: Maybe<Scalars['Float']>;
  positionPercentageFormatted?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  providerImageUrl?: Maybe<Scalars['String']>;
  symbol: Scalars['String'];
  totalAmount: Scalars['Float'];
  totalDebt?: Maybe<Scalars['Float']>;
  totalDebtFiatAmountCents?: Maybe<Scalars['Float']>;
  totalFiatAmountCents: Scalars['Float'];
  type: AssetTypeEnum;
};

export type PortfolioCollection = {
  __typename?: 'PortfolioCollection';
  contractAddress: Scalars['String'];
  externalUrl?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nfts: Array<PortfolioNft>;
  numberOfNFTs: Scalars['Int'];
  provider: Scalars['String'];
  providerImageUrl?: Maybe<Scalars['String']>;
  simplehashCollectionId: Scalars['String'];
  totalValueCents?: Maybe<Scalars['Float']>;
};

export type PortfolioDefiPosition = {
  __typename?: 'PortfolioDefiPosition';
  accountId: Scalars['ID'];
  groupByKey: Scalars['String'];
  items: Array<DefiPositionItem>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  protocolContractAddress?: Maybe<Scalars['String']>;
  protocolDetailUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  totalValue?: Maybe<Scalars['String']>;
  totalValueCents?: Maybe<Scalars['Float']>;
};

export type PortfolioNft = {
  __typename?: 'PortfolioNFT';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  assetKey: Scalars['String'];
  contractAddress: Scalars['String'];
  costBasisCents?: Maybe<Scalars['Float']>;
  fiatPriceCents?: Maybe<Scalars['Float']>;
  floorPriceCents?: Maybe<Scalars['Float']>;
  gainLossCents?: Maybe<Scalars['Float']>;
  imageUrl?: Maybe<Scalars['String']>;
  lastSalePriceCents?: Maybe<Scalars['Float']>;
  lastSalePriceDate?: Maybe<Scalars['Date']>;
  marketplaceImageUrl?: Maybe<Scalars['String']>;
  marketplaceUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  positionPercentage?: Maybe<Scalars['Float']>;
  positionPercentageFormatted?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  providerImageUrl?: Maybe<Scalars['String']>;
  tokenId: Scalars['String'];
  useAwakenPrice: Scalars['Boolean'];
  videoUrl?: Maybe<Scalars['String']>;
};

export type PortfolioResponse = {
  __typename?: 'PortfolioResponse';
  balances: Array<PortfolioBalance>;
  total: Scalars['Int'];
};

export type PortfolioResponseV2 = {
  __typename?: 'PortfolioResponseV2';
  balanceTotalValue: Scalars['Float'];
  balanceTotalValueCents: Scalars['Float'];
  balances: Array<PortfolioBalanceV2>;
  coinTotalValueCents: Scalars['Float'];
  collections?: Maybe<Array<PortfolioCollection>>;
  dailyFiatAmountCents?: Maybe<Scalars['Float']>;
  dailyPercentage?: Maybe<Scalars['Float']>;
  dailyPercentageFormatted?: Maybe<Scalars['String']>;
  defiPositions?: Maybe<Array<PortfolioDefiPosition>>;
  defiTotalValue: Scalars['Float'];
  defiTotalValueCents: Scalars['Float'];
  nftTotalValueCents: Scalars['Float'];
  nfts?: Maybe<Array<PortfolioNft>>;
  showWarning: Scalars['Boolean'];
  timestamp: Scalars['Date'];
  value: Scalars['Float'];
  valueCents: Scalars['Float'];
};

export enum PortfolioSortColumnEnum {
  CostBasis = 'CostBasis',
  CurrentValue = 'CurrentValue',
  GainOrLoss = 'GainOrLoss'
}

export type Position = {
  __typename?: 'Position';
  account: Account;
  assets: Array<PartialPortfolioAsset>;
  estimatedTotalFiatCurrentValue?: Maybe<Scalars['Float']>;
  hasOpenPositions: Scalars['Boolean'];
};

export type PotentialSpamAsset = {
  __typename?: 'PotentialSpamAsset';
  assetName: Scalars['String'];
  contractAddress: Scalars['String'];
  count: Scalars['Int'];
  exampleTxnHash: Scalars['String'];
  minTxnCreatedAt: Scalars['Date'];
  numInitiatedFees: Scalars['Int'];
  provider: AccountProviderEnum;
};

export type PricingData = {
  __typename?: 'PricingData';
  date?: Maybe<Scalars['Date']>;
  price?: Maybe<Scalars['Float']>;
};

export type PricingForDateResponse = {
  __typename?: 'PricingForDateResponse';
  hasPriceForDate: Scalars['Boolean'];
  pricePerAsset: Scalars['Float'];
  totalPrice?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  checkCode: Scalars['Boolean'];
  checkForErrors: Array<Scalars['String']>;
  checkRuleUsed: CheckRuleUsedResponse;
  checkRules: Array<CheckRuleResponse>;
  countDirty: Scalars['Int'];
  countNotDone: CountNotDoneResponse;
  getAccountWalletBalances: GetAccountWalletBalancesResponse;
  getAccountsForGroup: Array<Maybe<Account>>;
  getAllCPAs: Array<FilterFormCpa>;
  getAssetKeyChart: Array<AssetPoint>;
  getAssetKeyChartV2: Array<AssetPoint>;
  getAssetKeyInfo: AssetKeyInfo;
  getAssetPositions: GetAssetPositionsResponse;
  getAssetWarnings: Array<AssetWarning>;
  getAuthToken: Scalars['String'];
  getBookkeepingQuote: BookkeepingQuote;
  getChart: GetChartResponse;
  getClientAccounts: Array<Maybe<Account>>;
  getClientAssets: ClientAssetsResponse;
  getClientBillingPortal: Scalars['String'];
  getClientBookkeepingQuotes: Array<BookkeepingQuote>;
  getClientById: Client;
  getClientLedgerAccounts: GetClientLedgerAccountsResponse;
  getClientMembers: Array<ClientPermission>;
  getClientTransactions: GetTransactionsResponse;
  getClientsForUser: Array<Client>;
  getCollectionInfo: GetCollectionInfoResponse;
  getConnectWalletLink: GetConnectWalletLinkResponse;
  getCurrentAssetPrice: GetPortfolioValueResponse;
  getCurrentAssetPriceV2: CurrentAssetPriceResponse;
  getCurrentHoldingsChart: GetChartResponse;
  getCurrentTaxYearNumTxns: Scalars['Float'];
  getDiffV1: RecalculateSummaryDiffV1;
  getDiscoveryResults: GetDiscoveryResultsResponse;
  getDiscoverySplash: GetDiscoverySplashResponse;
  getEligibleDiscountsForTaxYear: Array<DiscountInfo>;
  getEntriesForAccount: Array<LedgerEntry>;
  getEntryLinks: GetEntryLinksResponse;
  getEstimatedCostBasis: GetEstimatedCostBasisResponse;
  getFilteredCPAs: Array<FilterFormCpa>;
  getHiddenAssets: GetHiddenAssetsResponse;
  getIncomeAndCapGains: GetIncomeAndCapGainsResponse;
  getIntercomMobileToken: Scalars['String'];
  getJobs: Array<Job>;
  getLabelOptionsForTransactions: TransactionTypeOptionResponse;
  getLabels: Array<FullLabelOption>;
  getMonthlyPoints: Array<MonthlyDataPoint>;
  getMonthlySummary: MonthlySummaryResponse;
  getMyActiveClient: Client;
  getMyActiveSubscription: GetMyActiveSubscriptionResponse;
  getMyClients: Array<Client>;
  getMyReferrals: GetMyReferralsResponse;
  getNFTCollectionChart: Array<AssetPoint>;
  getNFTPosition: GetNftPositionResponse;
  getNewsForTickers: GetNewsForTickersResponse;
  getNextDefaultRules: Array<GetNextDefaultRulesResponse>;
  getNumCPAs: Scalars['Int'];
  getNumTxnTypes: GetNumTxnTypesResponse;
  getNumTxns: NumTxnsResponse;
  getOtherUserLabels?: Maybe<GetOtherUserLabelsResp>;
  getPaidTransactionOverview: GetPaidTransactionOverviewResponse;
  getPartnerBookkeepingQuotes: Array<BookkeepingQuote>;
  getPaymentMethods: Array<PaymentMethod>;
  getPlaidLinkToken: Scalars['String'];
  getPnL: GetPnLResponse;
  getPortfolio: PortfolioResponse;
  getPortfolioV2: PortfolioResponseV2;
  getPortfolioValue: GetPortfolioValueResponse;
  getPositions: Array<Position>;
  getPossibleEntryLinks: Array<LedgerEntryWithCapacity>;
  getPotentialSpamAssets: Array<PotentialSpamAsset>;
  getPriceForDate: PricingForDateResponse;
  getPricing: Array<PricingData>;
  getQuote: GetQuoteResponse;
  getRecalculateSummaries: Array<RecalculateSummary>;
  getReferralCommissions: GetReferralCommissionsResponse;
  getReferralPayouts: GetReferralPayoutsResponse;
  getReferralPrizes: GetReferralPrizesResponse;
  getReplayOverview: ReplayResponseInfo;
  getReportDownloadUrl: Scalars['String'];
  getReportExport: ReportExportResponse;
  getReports: Array<Report>;
  getRerunGraphJob?: Maybe<Job>;
  getRules: Array<Rule>;
  getRulesForClient: Array<Rule>;
  getSubscriptions: Array<Subscription>;
  getSupportedProviders: Array<SupportedProvider>;
  getSupportedWallets: GetSupportedWalletsResponse;
  getSwap: Swap;
  getSwapTransaction: GetSwapTransactionResponse;
  getSwapsForAsset: GetSwapsForAssetResponse;
  getTaxYearBrackets: Array<TaxYearBracket>;
  getTaxYears: Array<Scalars['Int']>;
  getTaxesByAsset: Array<AssetTaxes>;
  getToken: TokenInfo;
  getTokenChart: GetTokenChartResponse;
  getTokenHolding: TokenPosition;
  getTokenInfo?: Maybe<GetTokenInfoResponse>;
  getTokenPrice: GetTokenPriceResponse;
  getTokenV2: TokenInfo;
  getTransaction: Transaction;
  getTransactionHistoryReport: ReportExportResponse;
  getTransactionHistoryReportV2: ReportExportResponse;
  getTransactionImportJobs: GetTransactionImportJobs;
  getTransactionLedgerEntries: Array<LedgerEntry>;
  getTransactionPage: TransactionPageResponse;
  getTransactionTypeOptions: TransactionTypeOptionResponse;
  getTransactions: Array<Transaction>;
  getWatchlistAssetsForUser: Array<WatchlistAssetWithInfo>;
  getWrapped: WrappedResponse;
  me?: Maybe<User>;
  myAccounts: Array<Maybe<Account>>;
  packsForClient: PacksForClientResponse;
  redirectByHash: RedirectByHashResponse;
  searchClients: Array<Client>;
  searchTokens: SearchTokensResponse;
  sendLoginEmail?: Maybe<Scalars['String']>;
};


export type QueryCheckCodeArgs = {
  referralCode: Scalars['String'];
};


export type QueryCheckForErrorsArgs = {
  clientId: Scalars['ID'];
};


export type QueryCheckRuleUsedArgs = {
  ruleId: Scalars['ID'];
};


export type QueryCheckRulesArgs = {
  clientId: Scalars['ID'];
  ruleIds: Array<Scalars['ID']>;
};


export type QueryCountDirtyArgs = {
  clientId: Scalars['ID'];
};


export type QueryCountNotDoneArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetAccountWalletBalancesArgs = {
  accountId: Scalars['ID'];
};


export type QueryGetAccountsForGroupArgs = {
  accountGroupId: Scalars['String'];
};


export type QueryGetAllCpAsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAssetKeyChartArgs = {
  assetKey: Scalars['String'];
  clientId: Scalars['ID'];
  hardRefresh?: InputMaybe<Scalars['Boolean']>;
  interval: HistoricalBalanceIntervalEnum;
};


export type QueryGetAssetKeyChartV2Args = {
  assetPricingKey: Scalars['String'];
  clientId: Scalars['ID'];
  hardRefresh?: InputMaybe<Scalars['Boolean']>;
  interval: HistoricalBalanceIntervalEnum;
};


export type QueryGetAssetKeyInfoArgs = {
  assetKey?: InputMaybe<Scalars['String']>;
  assetPricingKeyV2?: InputMaybe<Scalars['String']>;
  clientId: Scalars['ID'];
};


export type QueryGetAssetPositionsArgs = {
  assetPricingKey: Scalars['String'];
  clientId: Scalars['ID'];
};


export type QueryGetAssetWarningsArgs = {
  assetIds: Array<Scalars['ID']>;
  clientId: Scalars['ID'];
};


export type QueryGetBookkeepingQuoteArgs = {
  bookkeepingId: Scalars['ID'];
};


export type QueryGetChartArgs = {
  clientId: Scalars['ID'];
  hardRefresh?: InputMaybe<Scalars['Boolean']>;
  interval: HistoricalBalanceIntervalEnum;
};


export type QueryGetClientAccountsArgs = {
  clientId: Scalars['String'];
  includeVirtual?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetClientAssetsArgs = {
  clientId: Scalars['ID'];
  hideSpamAssets?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetClientBillingPortalArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetClientBookkeepingQuotesArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetClientByIdArgs = {
  clientId: Scalars['String'];
};


export type QueryGetClientLedgerAccountsArgs = {
  clientId: Scalars['ID'];
  depth: Scalars['Int'];
};


export type QueryGetClientMembersArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetClientTransactionsArgs = {
  accountIds?: InputMaybe<Array<Scalars['ID']>>;
  ascending?: InputMaybe<Scalars['Boolean']>;
  assetId?: InputMaybe<Scalars['String']>;
  assetIds?: InputMaybe<Array<Scalars['String']>>;
  assetKey?: InputMaybe<Scalars['String']>;
  assetKeyV2?: InputMaybe<Scalars['String']>;
  assetSymbolOrName?: InputMaybe<Scalars['String']>;
  assetType?: InputMaybe<Scalars['String']>;
  clientId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['Date']>;
  hasIncome?: InputMaybe<Scalars['Boolean']>;
  hasNotes?: InputMaybe<Scalars['Boolean']>;
  includeHidden?: InputMaybe<Scalars['Boolean']>;
  includeSpam?: InputMaybe<Scalars['Boolean']>;
  isMissingBasis?: InputMaybe<Scalars['Boolean']>;
  labeledPriority?: InputMaybe<Scalars['Int']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  providers?: InputMaybe<Array<Scalars['String']>>;
  reviewed?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGetClientsForUserArgs = {
  userId: Scalars['String'];
};


export type QueryGetCollectionInfoArgs = {
  clientId: Scalars['ID'];
  simplehashCollectionId: Scalars['String'];
};


export type QueryGetConnectWalletLinkArgs = {
  accountId: Scalars['ID'];
};


export type QueryGetCurrentAssetPriceArgs = {
  assetKey: Scalars['String'];
  clientId: Scalars['ID'];
  useCacheIfAvailable?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetCurrentAssetPriceV2Args = {
  assetPricingKey: Scalars['String'];
  clientId: Scalars['ID'];
  useCacheIfAvailable?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetCurrentHoldingsChartArgs = {
  accountIds?: InputMaybe<Array<Scalars['ID']>>;
  assetKey: Scalars['String'];
  clientId: Scalars['ID'];
  hardRefresh?: InputMaybe<Scalars['Boolean']>;
  interval: HistoricalBalanceIntervalEnum;
};


export type QueryGetCurrentTaxYearNumTxnsArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetDiffV1Args = {
  clientId: Scalars['ID'];
  newRecalculateSummaryId: Scalars['ID'];
  oldRecalculateSummaryId: Scalars['ID'];
  year: Scalars['Int'];
};


export type QueryGetDiscoveryResultsArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  query: Scalars['String'];
};


export type QueryGetDiscoverySplashArgs = {
  clientId?: InputMaybe<Scalars['String']>;
};


export type QueryGetEligibleDiscountsForTaxYearArgs = {
  clientId: Scalars['ID'];
  taxYear: Scalars['Int'];
};


export type QueryGetEntriesForAccountArgs = {
  accountId: Scalars['ID'];
  includeSubAccounts?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetEntryLinksArgs = {
  ledgerEntryId: Scalars['ID'];
};


export type QueryGetEstimatedCostBasisArgs = {
  assetId: Scalars['ID'];
};


export type QueryGetFilteredCpAsArgs = {
  filterFormSubmissionId: Scalars['ID'];
};


export type QueryGetHiddenAssetsArgs = {
  clientId: Scalars['ID'];
  type?: InputMaybe<AssetTypeEnum>;
};


export type QueryGetIncomeAndCapGainsArgs = {
  clientId: Scalars['ID'];
  year: Scalars['String'];
};


export type QueryGetJobsArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetLabelOptionsForTransactionsArgs = {
  transactionIds: Array<Scalars['ID']>;
};


export type QueryGetMonthlyPointsArgs = {
  clientId: Scalars['ID'];
  year: Scalars['String'];
};


export type QueryGetMonthlySummaryArgs = {
  clientId: Scalars['ID'];
  month: Scalars['Int'];
  year: Scalars['String'];
};


export type QueryGetMyActiveSubscriptionArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetMyReferralsArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetNftCollectionChartArgs = {
  clientId: Scalars['ID'];
  hardRefresh?: InputMaybe<Scalars['Boolean']>;
  interval: HistoricalBalanceIntervalEnum;
  simplehashCollectionId: Scalars['String'];
};


export type QueryGetNftPositionArgs = {
  assetPricingKey: Scalars['String'];
  clientId: Scalars['ID'];
};


export type QueryGetNewsForTickersArgs = {
  tickers: Array<Scalars['String']>;
};


export type QueryGetNumTxnTypesArgs = {
  clientId: Scalars['ID'];
  gainsLossesPage: Scalars['Int'];
};


export type QueryGetNumTxnsArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetOtherUserLabelsArgs = {
  transactionId: Scalars['ID'];
};


export type QueryGetPaidTransactionOverviewArgs = {
  clientId: Scalars['ID'];
  endPeriod?: InputMaybe<Scalars['Date']>;
};


export type QueryGetPnLArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetPortfolioArgs = {
  ascending?: InputMaybe<Scalars['Boolean']>;
  assetKey?: InputMaybe<Scalars['String']>;
  clientId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<PortfolioSortColumnEnum>;
  type?: InputMaybe<AssetTypeEnum>;
  useCache?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetPortfolioV2Args = {
  ascending?: InputMaybe<Scalars['Boolean']>;
  assetKey?: InputMaybe<Scalars['String']>;
  clientId: Scalars['ID'];
  includeCoins?: InputMaybe<Scalars['Boolean']>;
  includeDefi?: InputMaybe<Scalars['Boolean']>;
  includeNFTs?: InputMaybe<Scalars['Boolean']>;
  includeSpam?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  onlyIncludedAccountIds?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  prefetch?: InputMaybe<Scalars['Boolean']>;
  provider?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<PortfolioSortColumnEnum>;
  type?: InputMaybe<AssetTypeEnum>;
  useCache?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetPortfolioValueArgs = {
  clientId: Scalars['ID'];
  useCacheIfAvailable?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetPositionsArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetPossibleEntryLinksArgs = {
  assetId: Scalars['ID'];
  beforeDate: Scalars['Date'];
};


export type QueryGetPriceForDateArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  assetId: Scalars['ID'];
  currency: CurrencyCodeEnum;
  date: Scalars['Date'];
};


export type QueryGetPricingArgs = {
  coinGeckoTokenId: Scalars['String'];
};


export type QueryGetQuoteArgs = {
  accountId: Scalars['ID'];
  amount: Scalars['Float'];
  receive: QuoteAsset;
  send: QuoteAsset;
  side: TradingSideEnum;
};


export type QueryGetRecalculateSummariesArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetReferralCommissionsArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetReferralPayoutsArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetReferralPrizesArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetReplayOverviewArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetReportDownloadUrlArgs = {
  reportId: Scalars['ID'];
};


export type QueryGetReportExportArgs = {
  clientId: Scalars['ID'];
  forceSendEmail?: InputMaybe<Scalars['Boolean']>;
  tagGroupId?: InputMaybe<Scalars['ID']>;
  type: ReportExportTypeEnum;
  year: Scalars['String'];
};


export type QueryGetReportsArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetRerunGraphJobArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetRulesForClientArgs = {
  clientId: Scalars['String'];
};


export type QueryGetSubscriptionsArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetSupportedProvidersArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetSupportedWalletsArgs = {
  assetKey: Scalars['String'];
  clientId: Scalars['ID'];
};


export type QueryGetSwapArgs = {
  swapId: Scalars['ID'];
};


export type QueryGetSwapTransactionArgs = {
  accountId: Scalars['ID'];
  quoteId: Scalars['ID'];
};


export type QueryGetSwapsForAssetArgs = {
  assetKeyV2: Scalars['String'];
  clientId: Scalars['ID'];
};


export type QueryGetTaxYearBracketsArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetTaxYearsArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetTaxesByAssetArgs = {
  clientId: Scalars['ID'];
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};


export type QueryGetTokenArgs = {
  contractAddress: Scalars['String'];
  provider: AccountProviderEnum;
};


export type QueryGetTokenChartArgs = {
  contractAddress: Scalars['String'];
  granularity: GranularityEnum;
  provider: AccountProviderEnum;
};


export type QueryGetTokenHoldingArgs = {
  contractAddress: Scalars['String'];
  provider: AccountProviderEnum;
};


export type QueryGetTokenInfoArgs = {
  assetKey: Scalars['String'];
};


export type QueryGetTokenPriceArgs = {
  contractAddress: Scalars['String'];
  provider: AccountProviderEnum;
};


export type QueryGetTokenV2Args = {
  accountId: Scalars['ID'];
  assetKey: Scalars['String'];
};


export type QueryGetTransactionArgs = {
  transactionId: Scalars['ID'];
};


export type QueryGetTransactionHistoryReportArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetTransactionHistoryReportV2Args = {
  accountIds?: InputMaybe<Array<Scalars['ID']>>;
  ascending?: InputMaybe<Scalars['Boolean']>;
  assetId?: InputMaybe<Scalars['String']>;
  assetIds?: InputMaybe<Array<Scalars['String']>>;
  assetKey?: InputMaybe<Scalars['String']>;
  assetKeyV2?: InputMaybe<Scalars['String']>;
  assetSymbolOrName?: InputMaybe<Scalars['String']>;
  assetType?: InputMaybe<Scalars['String']>;
  clientId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['Date']>;
  hasIncome?: InputMaybe<Scalars['Boolean']>;
  hasNotes?: InputMaybe<Scalars['Boolean']>;
  includeHidden?: InputMaybe<Scalars['Boolean']>;
  includeSpam?: InputMaybe<Scalars['Boolean']>;
  isMissingBasis?: InputMaybe<Scalars['Boolean']>;
  labeledPriority?: InputMaybe<Scalars['Int']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  providers?: InputMaybe<Array<Scalars['String']>>;
  reviewed?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGetTransactionImportJobsArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetTransactionLedgerEntriesArgs = {
  transactionId?: InputMaybe<Scalars['ID']>;
  txnHash?: InputMaybe<Scalars['String']>;
};


export type QueryGetTransactionPageArgs = {
  transactionId: Scalars['ID'];
};


export type QueryGetTransactionTypeOptionsArgs = {
  transactionId: Scalars['ID'];
};


export type QueryGetTransactionsArgs = {
  clientId: Scalars['ID'];
  transactionIds: Array<Scalars['ID']>;
};


export type QueryGetWrappedArgs = {
  clientId: Scalars['ID'];
};


export type QueryPacksForClientArgs = {
  clientId: Scalars['ID'];
};


export type QueryRedirectByHashArgs = {
  txnHash: Scalars['String'];
};


export type QuerySearchClientsArgs = {
  search: Scalars['String'];
};


export type QuerySearchTokensArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  provider?: InputMaybe<AccountProviderEnum>;
  search?: InputMaybe<Scalars['String']>;
};


export type QuerySendLoginEmailArgs = {
  browser: Scalars['String'];
  ipAddress: Scalars['String'];
};

export type Quote = {
  __typename?: 'Quote';
  chain: AccountProviderEnum;
  id: Scalars['ID'];
  provider: TradingProviderEnum;
  providerImageUrl?: Maybe<Scalars['String']>;
  receiveAmount: Scalars['Float'];
  receiveFiatAmountCents: Scalars['Float'];
  receiveFiatCurrency: Scalars['String'];
  receiveIconImageUrl: Scalars['String'];
  receiveSymbol: Scalars['String'];
  recommendedSlippageBps?: Maybe<Scalars['Float']>;
  sendAmount: Scalars['Float'];
  sendFiatAmountCents: Scalars['Float'];
  sendFiatCurrency: Scalars['String'];
  sendIconImageUrl: Scalars['String'];
  sendSymbol: Scalars['String'];
};

export type QuoteAsset = {
  contractAddress: Scalars['String'];
  provider: Scalars['String'];
};

export type RawTransactionAsset = {
  __typename?: 'RawTransactionAsset';
  category: Scalars['String'];
  collectionName?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  decimals?: Maybe<Scalars['Int']>;
  identifier: Scalars['String'];
  imageUri?: Maybe<Scalars['String']>;
  logoUri?: Maybe<Scalars['String']>;
  metadataUri?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  standard: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
};

export type RecalculateSnapshotDiffFee = {
  __typename?: 'RecalculateSnapshotDiffFee';
  dedupeUniqueId?: Maybe<Scalars['String']>;
  newFee: RecalculateSummarySnapshotFee;
  oldFee: RecalculateSummarySnapshotFee;
};

export type RecalculateSnapshotDiffTransfer = {
  __typename?: 'RecalculateSnapshotDiffTransfer';
  dedupeUniqueId?: Maybe<Scalars['String']>;
  newTransfer: RecalculateSummarySnapshotTransfer;
  oldTransfer: RecalculateSummarySnapshotTransfer;
};

export type RecalculateSnapshotDiffTxn = {
  __typename?: 'RecalculateSnapshotDiffTxn';
  autoReviewReason?: Maybe<Scalars['String']>;
  capGainsSumSigned: Scalars['String'];
  capPriority?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  deductibleExpensesSum?: Maybe<Scalars['String']>;
  fees?: Maybe<Array<RecalculateSummarySnapshotFee>>;
  idempotency: Scalars['String'];
  incomeSum?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  lastModifiedById?: Maybe<Scalars['String']>;
  lastModifiedByName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  provider: AccountProviderEnum;
  reviewStatus: LedgerTransactionReviewStatusEnum;
  ruleUsedId?: Maybe<Scalars['String']>;
  ruleUsedType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  transfers?: Maybe<Array<RecalculateSummarySnapshotTransfer>>;
  txnHash?: Maybe<Scalars['String']>;
};

export type RecalculateSnapshotDiffTxnPair = {
  __typename?: 'RecalculateSnapshotDiffTxnPair';
  createdAt?: Maybe<Scalars['Date']>;
  idempotency?: Maybe<Scalars['String']>;
  newTxn?: Maybe<RecalculateSnapshotDiffTxn>;
  oldTxn?: Maybe<RecalculateSnapshotDiffTxn>;
};

export type RecalculateSummary = {
  __typename?: 'RecalculateSummary';
  breakdowns: Array<RecalculateSummaryBreakdown>;
  clientId: Scalars['ID'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  jobId?: Maybe<Scalars['ID']>;
  snapshot?: Maybe<RecalculateSummarySnapshot>;
  updatedAt: Scalars['Date'];
  usedSpeedup: Scalars['Boolean'];
};

export type RecalculateSummaryBreakdown = {
  __typename?: 'RecalculateSummaryBreakdown';
  capGainsTotalCents: Scalars['Float'];
  endDate: Scalars['Date'];
  futuresNetCents: Scalars['Float'];
  netIncomeCents: Scalars['Float'];
  startDate: Scalars['Date'];
};

export type RecalculateSummaryDiffV1 = {
  __typename?: 'RecalculateSummaryDiffV1';
  newSummary: RecalculateSummary;
  oldSummary: RecalculateSummary;
  transactions: Array<RecalculateSnapshotDiffTxnPair>;
};

export type RecalculateSummarySnapshot = {
  __typename?: 'RecalculateSummarySnapshot';
  schema?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<RecalculateSummarySnapshotTxn>>;
};

export type RecalculateSummarySnapshotFee = {
  __typename?: 'RecalculateSummarySnapshotFee';
  amount?: Maybe<Scalars['Float']>;
  assetId?: Maybe<Scalars['String']>;
  assetSymbolOrName?: Maybe<Scalars['String']>;
  dedupeUniqueId?: Maybe<Scalars['String']>;
};

export type RecalculateSummarySnapshotTransfer = {
  __typename?: 'RecalculateSummarySnapshotTransfer';
  amount?: Maybe<Scalars['Float']>;
  assetId?: Maybe<Scalars['String']>;
  assetSymbolOrName?: Maybe<Scalars['String']>;
  dedupeUniqueId?: Maybe<Scalars['String']>;
  isUserSet: Scalars['Boolean'];
  isUserSetBasisFiatValue: Scalars['Boolean'];
  overrideBasisFiatValue?: Maybe<Scalars['Float']>;
  overrideFiatValue?: Maybe<Scalars['Float']>;
};

export type RecalculateSummarySnapshotTxn = {
  __typename?: 'RecalculateSummarySnapshotTxn';
  autoReviewReason?: Maybe<Scalars['String']>;
  capGainsSumSigned: Scalars['String'];
  createdAt: Scalars['Date'];
  deductibleExpensesSum?: Maybe<Scalars['String']>;
  fees?: Maybe<Array<RecalculateSummarySnapshotFee>>;
  idempotency: Scalars['String'];
  incomeSum: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  lastModifiedById?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  provider: AccountProviderEnum;
  reviewStatus: LedgerTransactionReviewStatusEnum;
  ruleUsedId?: Maybe<Scalars['String']>;
  ruleUsedType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  transfers?: Maybe<Array<RecalculateSummarySnapshotTransfer>>;
  txnHash?: Maybe<Scalars['String']>;
};

export type RedirectByHashResponse = {
  __typename?: 'RedirectByHashResponse';
  clientId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  redirectUrl: Scalars['String'];
  transactionId?: Maybe<Scalars['ID']>;
};

export type Referral = {
  __typename?: 'Referral';
  id: Scalars['String'];
};

export type ReferralCommission = {
  __typename?: 'ReferralCommission';
  commissionCents: Scalars['Float'];
  createdAt: Scalars['Date'];
  id: Scalars['String'];
};

export type ReferralInfo = {
  __typename?: 'ReferralInfo';
  id: Scalars['String'];
  referredByCanClaim: Scalars['Boolean'];
  referredByHasClaimed: Scalars['Boolean'];
  referredByNumTxnsFree?: Maybe<Scalars['Float']>;
  referredClientName?: Maybe<Scalars['String']>;
};

export type ReferralPayout = {
  __typename?: 'ReferralPayout';
  amountCents: Scalars['Float'];
  createdAt: Scalars['Date'];
  id: Scalars['String'];
  status: ReferralPayoutStatusEnum;
};

export enum ReferralPayoutStatusEnum {
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  Succeeded = 'Succeeded'
}

export enum ReferralPrizeEnum {
  Friend = 'friend',
  Twitter = 'twitter'
}

export type ReplayResponseInfo = {
  __typename?: 'ReplayResponseInfo';
  estimatedTimeInMS?: Maybe<Scalars['Int']>;
  isReplayNeeded: Scalars['Boolean'];
  isUsingReplay: Scalars['Boolean'];
  numberOfTransactions: Scalars['Int'];
};

export type Report = {
  __typename?: 'Report';
  clientId: Scalars['String'];
  costBasisAlgorithm?: Maybe<CostBasisAlgorithmEnum>;
  createdAt: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  fileObjectKey: Scalars['String'];
  id: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  title: Scalars['String'];
  type?: Maybe<ReportTypeEnum>;
  updatedAt: Scalars['Date'];
};

export type ReportAccountInfo = {
  __typename?: 'ReportAccountInfo';
  account: LedgerAccount;
  accountBalance: Scalars['Int'];
  children?: Maybe<Array<ReportAccountInfo>>;
  childrenBalance: Scalars['Int'];
  classification: LedgerAccountClassificationEnum;
  numberOfChildren: Scalars['Int'];
  numberOfEntries: Scalars['Int'];
  totalBalance: Scalars['Int'];
};

export type ReportExportResponse = {
  __typename?: 'ReportExportResponse';
  downloadUrl?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export enum ReportExportTypeEnum {
  Accounts = 'Accounts',
  AssetBreakdownReport = 'AssetBreakdownReport',
  AssetRollupBreakdown = 'AssetRollupBreakdown',
  BalanceReport = 'BalanceReport',
  BalanceSheet = 'BalanceSheet',
  CoinBreakdown = 'CoinBreakdown',
  Futures = 'Futures',
  IncomeReport = 'IncomeReport',
  Irs8949 = 'Irs8949',
  IrsSchedule1 = 'IrsSchedule1',
  IrsScheduleD = 'IrsScheduleD',
  NftBreakdown = 'NFTBreakdown',
  SummaryReport = 'SummaryReport',
  Tags = 'Tags',
  TaxAct1099B = 'TaxAct1099B',
  TransactionHistoryReport = 'TransactionHistoryReport',
  TransactionsReport = 'TransactionsReport',
  TurboTax1099B = 'TurboTax1099B',
  TurboTaxAggregated1099B = 'TurboTaxAggregated1099B'
}

export enum ReportTypeEnum {
  BalanceReport = 'BalanceReport',
  CapGainsCsv = 'CapGainsCSV',
  CapGainsRollupCsv = 'CapGainsRollupCSV',
  Futures = 'Futures',
  IncomeReport = 'IncomeReport',
  Irs8949 = 'Irs8949',
  IrsSchedule1 = 'IrsSchedule1',
  IrsScheduleD = 'IrsScheduleD',
  None = 'None',
  PerAssetBreakdownExcel = 'PerAssetBreakdownExcel',
  SummaryReport = 'SummaryReport',
  TaxAct = 'TaxAct',
  TransactionHistoryReport = 'TransactionHistoryReport',
  TransactionsReport = 'TransactionsReport',
  TurboTax = 'TurboTax',
  TurboTaxAggregated1099B = 'TurboTaxAggregated1099B'
}

export type Rule = {
  __typename?: 'Rule';
  clientId?: Maybe<Scalars['String']>;
  constraints: Scalars['String'];
  createdAt: Scalars['Date'];
  createdByAwaken: Scalars['Boolean'];
  exampleTxnHash: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  provider: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum RuleTypeEnum {
  V1AddLiquidity = 'v1_ADD_LIQUIDITY',
  V1Airdrop = 'v1_AIRDROP',
  V1Borrow = 'v1_BORROW',
  V1Bridging = 'v1_BRIDGING',
  V1CapitalGain = 'v1_CAPITAL_GAIN',
  V1ClaimRewards = 'v1_CLAIM_REWARDS',
  V1ClosePosition = 'v1_CLOSE_POSITION',
  V1CoinBuy = 'v1_COIN_BUY',
  V1CoinSell = 'v1_COIN_SELL',
  V1Donation = 'v1_DONATION',
  V1ExternalTransfer = 'v1_EXTERNAL_TRANSFER',
  V1FeeExpenseDeduction = 'v1_FEE_EXPENSE_DEDUCTION',
  V1Gift = 'v1_GIFT',
  V1Income = 'v1_INCOME',
  V1IncomeExpense = 'v1_INCOME_EXPENSE',
  V1InternalTransfer = 'v1_INTERNAL_TRANSFER',
  V1Lend = 'v1_LEND',
  V1LendBorrow = 'v1_LEND_BORROW',
  V1LendSwap = 'v1_LEND_SWAP',
  V1MiningIncome = 'v1_MINING_INCOME',
  V1NftBuy_1 = 'v1_NFT_BUY__1',
  V1NftList_1 = 'v1_NFT_LIST__1',
  V1NftSell_1 = 'v1_NFT_SELL__1',
  V1NftSwap = 'v1_NFT_SWAP',
  V1NonTaxable = 'v1_NON_TAXABLE',
  V1OpenPosition = 'v1_OPEN_POSITION',
  V1Payment = 'v1_PAYMENT',
  V1Receive = 'v1_RECEIVE',
  V1RemoveLiquidity = 'v1_REMOVE_LIQUIDITY',
  V1Repay = 'v1_REPAY',
  V1RepayWithdraw = 'v1_REPAY_WITHDRAW',
  V1RewardsIncome = 'v1_REWARDS_INCOME',
  V1SaveForLater = 'v1_SAVE_FOR_LATER',
  V1Spam = 'v1_SPAM',
  V1Staking = 'v1_STAKING',
  V1StakingSwap = 'v1_STAKING_SWAP',
  V1Swap_1 = 'v1_SWAP__1',
  V1UnstakingSwap = 'v1_UNSTAKING_SWAP',
  V1UnstakingWithoutRewards = 'v1_UNSTAKING_WITHOUT_REWARDS',
  V1UnstakingWithRewards = 'v1_UNSTAKING_WITH_REWARDS',
  V1Withdraw = 'v1_WITHDRAW',
  V1WithdrawSwap = 'v1_WITHDRAW_SWAP',
  V1Wrapping = 'v1_WRAPPING'
}

export type SearchTokensResponse = {
  __typename?: 'SearchTokensResponse';
  recommended?: Maybe<TokenSearchResult>;
  tokens: Array<TokenSearchResult>;
};

export type SpamAsset = {
  __typename?: 'SpamAsset';
  assetName: Scalars['String'];
  contractAddress: Scalars['String'];
  createdAt: Scalars['Date'];
  exampleTxnHash: Scalars['String'];
  isSpam?: Maybe<Scalars['Boolean']>;
  provider: AccountProviderEnum;
};

export type StartSubscriptionCheckoutLineItem = {
  __typename?: 'StartSubscriptionCheckoutLineItem';
  amount: Scalars['String'];
  amountCents: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type StartSubscriptionCheckoutResponse = {
  __typename?: 'StartSubscriptionCheckoutResponse';
  clientSecret?: Maybe<Scalars['String']>;
  lineItems: Array<StartSubscriptionCheckoutLineItem>;
  requiresPayment: Scalars['Boolean'];
  txnCeiling: Scalars['Float'];
};

export type StartUpgradeCheckoutResponse = {
  __typename?: 'StartUpgradeCheckoutResponse';
  amount: Scalars['Float'];
  clientSecret: Scalars['String'];
  paymentIntentId: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  createdAt: Scalars['Date'];
  endPeriod?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  idempotency: Scalars['String'];
  isFreeSubscription: Scalars['Boolean'];
  startPeriod?: Maybe<Scalars['Date']>;
  stripeCheckoutSessionId?: Maybe<Scalars['String']>;
  stripeLineItemId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  stripeProductId?: Maybe<Scalars['String']>;
  taxYear: Scalars['Float'];
  totalCents: Scalars['Float'];
  transactionCeiling: Scalars['Float'];
};

export type SubscriptionUpgrade = {
  __typename?: 'SubscriptionUpgrade';
  id: Scalars['ID'];
  taxYear: Scalars['Float'];
  totalCents: Scalars['Float'];
  type: SubscriptionUpgradeTypeEnum;
};

export enum SubscriptionUpgradeTypeEnum {
  UnlimitedCx = 'UnlimitedCX'
}

export type SupportWalletWithBalance = {
  __typename?: 'SupportWalletWithBalance';
  account: Account;
  balance: Scalars['Float'];
  fiatAmountCents: Scalars['Float'];
  gasBalance?: Maybe<Scalars['Float']>;
  isSupported: Scalars['Boolean'];
  provider: AccountProviderEnum;
};

export type SupportedProvider = {
  __typename?: 'SupportedProvider';
  iconImageUrl: Scalars['String'];
  name: Scalars['String'];
};

export type Swap = {
  __typename?: 'Swap';
  blockExplorerName?: Maybe<Scalars['String']>;
  blockExplorerUrl?: Maybe<Scalars['String']>;
  chain: AccountProviderEnum;
  createdAt: Scalars['String'];
  hash: Scalars['String'];
  id: Scalars['String'];
  status: SwapStatusEnum;
};

export enum SwapStatusEnum {
  Confirmed = 'Confirmed',
  Failed = 'Failed',
  Pending = 'Pending'
}

export type SyncAllClientAccountsResponse = {
  __typename?: 'SyncAllClientAccountsResponse';
  jobIds: Array<Scalars['String']>;
};

export type TaxYearBracket = {
  __typename?: 'TaxYearBracket';
  end: Scalars['Date'];
  endFormatted: Scalars['String'];
  start: Scalars['Date'];
  startFormatted: Scalars['String'];
  taxYear: Scalars['String'];
};

export type Token = {
  __typename?: 'Token';
  coingeckoId?: Maybe<Scalars['String']>;
  contractAddress: Scalars['String'];
  iconImageUrl: Scalars['String'];
  id: Scalars['ID'];
  provider: AccountProviderEnum;
  symbol: Scalars['String'];
};

export type TokenChartPoint = {
  __typename?: 'TokenChartPoint';
  timestamp: Scalars['Date'];
  utcTimeSeconds: Scalars['Float'];
  value?: Maybe<Scalars['Float']>;
  valueCents?: Maybe<Scalars['Float']>;
};

export type TokenInfo = {
  __typename?: 'TokenInfo';
  contractAddress: Scalars['String'];
  iconImageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  provider: AccountProviderEnum;
  symbol: Scalars['String'];
};

export type TokenPosition = {
  __typename?: 'TokenPosition';
  amount: Scalars['Float'];
  avgCostBasisPerUnitCents?: Maybe<Scalars['Float']>;
  avgCostBasisPerUnitFormatted?: Maybe<Scalars['String']>;
  coingeckoTokenId?: Maybe<Scalars['String']>;
  contractAddress: Scalars['String'];
  fiatAmountCents: Scalars['Float'];
  fiatAmountFormatted: Scalars['String'];
  fiatCurrency: Scalars['String'];
  iconImageUrl?: Maybe<Scalars['String']>;
  isNativeToken: Scalars['Boolean'];
  isPending?: Maybe<Scalars['Boolean']>;
  priceCents: Scalars['Float'];
  provider: AccountProviderEnum;
  symbol?: Maybe<Scalars['String']>;
  totalCostBasisCents?: Maybe<Scalars['Float']>;
  totalCostBasisFormatted?: Maybe<Scalars['String']>;
  totalReturnFiatCents?: Maybe<Scalars['Float']>;
  totalReturnFiatFormatted?: Maybe<Scalars['String']>;
  totalReturnPercentage?: Maybe<Scalars['Float']>;
};

export type TokenSearchResult = {
  __typename?: 'TokenSearchResult';
  coingeckoId?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  iconImageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  provider: AccountProviderEnum;
  symbol: Scalars['String'];
};

export enum TradingProviderEnum {
  Jupiter = 'Jupiter'
}

export enum TradingSideEnum {
  Buy = 'Buy',
  Sell = 'Sell'
}

export type Transaction = {
  __typename?: 'Transaction';
  assets: Array<RawTransactionAsset>;
  autoReviewReason?: Maybe<Scalars['String']>;
  balances?: Maybe<Array<BalanceSnapshot>>;
  blockExplorerName?: Maybe<Scalars['String']>;
  blockExplorerUrl?: Maybe<Scalars['String']>;
  capGainsSum?: Maybe<Scalars['Float']>;
  capGainsSumSigned?: Maybe<Scalars['String']>;
  capPriority?: Maybe<Scalars['Int']>;
  client?: Maybe<Client>;
  clientId?: Maybe<Scalars['String']>;
  constraints?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  cryptocurrency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fees: Array<Fee>;
  fiatCurrency?: Maybe<CurrencyCodeEnum>;
  formattedFunctionName?: Maybe<Scalars['String']>;
  hasHiddenTransfers?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  incomeSum?: Maybe<Scalars['Float']>;
  isBalanced?: Maybe<Scalars['Boolean']>;
  isDirty?: Maybe<IsDirtyEnum>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isImporting: Scalars['Boolean'];
  isMissingBasis?: Maybe<Scalars['Boolean']>;
  labelUsed?: Maybe<Scalars['String']>;
  ledgerEntries?: Maybe<Array<LedgerEntry>>;
  manual?: Maybe<Scalars['Boolean']>;
  needsReview?: Maybe<NeedsReview>;
  notes?: Maybe<Scalars['String']>;
  options?: Maybe<TransactionTypeOptionResponse>;
  overrideIncomeCents?: Maybe<Scalars['Float']>;
  priceCents: Scalars['Float'];
  probablyWalletTransfer?: Maybe<Scalars['Boolean']>;
  processingType?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  providerImageUrl?: Maybe<Scalars['String']>;
  references?: Maybe<Array<LedgerEntry>>;
  reviewStatus: LedgerTransactionReviewStatusEnum;
  ruleUsed?: Maybe<Rule>;
  ruleUsedId?: Maybe<Scalars['String']>;
  sourceAccountId?: Maybe<Scalars['String']>;
  status: LedgerTransactionStatusEnum;
  title?: Maybe<Scalars['String']>;
  transfers: Array<Transfer>;
  txnHash?: Maybe<Scalars['String']>;
  type?: Maybe<LedgerTransactionTypeEnum>;
  updatedAt: Scalars['Date'];
};

export enum TransactionEntityTypeEnum {
  AccountId = 'account_id',
  Address = 'address'
}

export type TransactionPageResponse = {
  __typename?: 'TransactionPageResponse';
  page: Scalars['Int'];
};

export type TransactionSummary = {
  __typename?: 'TransactionSummary';
  analysis: Scalars['String'];
  description: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
};

export enum TransactionTransferTypeEnum {
  Adjustment = 'Adjustment',
  Card = 'Card',
  Deposit = 'Deposit',
  Interest = 'Interest',
  Referral = 'Referral',
  Staking = 'Staking',
  StakingReward = 'StakingReward',
  Trade = 'Trade',
  Unknown = 'Unknown',
  Unstaking = 'Unstaking',
  Withdrawal = 'Withdrawal'
}

export enum TransactionTypeEnum {
  AutoReview = 'auto_review',
  Label = 'label',
  Rule = 'rule'
}

export type TransactionTypeOption = {
  __typename?: 'TransactionTypeOption';
  applicable: Scalars['Boolean'];
  applicableCautious: Scalars['Boolean'];
  category: Scalars['String'];
  infoMessage: Scalars['String'];
  keywordMatches: Scalars['Boolean'];
  label: Scalars['String'];
  ruleType?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type TransactionTypeOptionResponse = {
  __typename?: 'TransactionTypeOptionResponse';
  labels: Array<TransactionTypeOption>;
  message?: Maybe<Scalars['String']>;
};

export type Transfer = {
  __typename?: 'Transfer';
  assetCreditFiatAmount?: Maybe<Scalars['Float']>;
  assetCreditId?: Maybe<Scalars['ID']>;
  assetDebitFiatAmount?: Maybe<Scalars['Float']>;
  assetDebitId?: Maybe<Scalars['ID']>;
  assetId?: Maybe<Scalars['String']>;
  basis?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyCodeEnum>;
  entries?: Maybe<Array<LedgerEntry>>;
  estFiatValue?: Maybe<Scalars['Float']>;
  fiatAmountCents?: Maybe<Scalars['Float']>;
  fiatCurrency?: Maybe<CurrencyCodeEnum>;
  fromAccount?: Maybe<Account>;
  fromAccountId?: Maybe<Scalars['String']>;
  fromAddress?: Maybe<Scalars['String']>;
  fromMetadata?: Maybe<TransferEntityMetadata>;
  fromType?: Maybe<TransactionEntityTypeEnum>;
  fullAsset?: Maybe<Asset>;
  id: Scalars['String'];
  income?: Maybe<Scalars['Float']>;
  isCreatedByUser: Scalars['Boolean'];
  isHidden?: Maybe<Scalars['Boolean']>;
  isMissingBasis?: Maybe<Scalars['Boolean']>;
  isUserSet?: Maybe<Scalars['Boolean']>;
  isUserSetBasisFiatValue?: Maybe<Scalars['Boolean']>;
  links?: Maybe<Array<LedgerEntryLink>>;
  originalAssetId?: Maybe<Scalars['String']>;
  originalFiatValue?: Maybe<Scalars['Float']>;
  originalFromAddress?: Maybe<Scalars['String']>;
  originalToAddress?: Maybe<Scalars['String']>;
  overrideBasisFiatValue?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  proceeds?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  rawAsset?: Maybe<RawTransactionAsset>;
  toAccount?: Maybe<Account>;
  toAccountId?: Maybe<Scalars['String']>;
  toAddress?: Maybe<Scalars['String']>;
  toMetadata?: Maybe<TransferEntityMetadata>;
  toType?: Maybe<TransactionEntityTypeEnum>;
  transferCategory?: Maybe<TransferCategoryTypeEnum>;
  txnLabel?: Maybe<Scalars['String']>;
  type?: Maybe<TransactionTransferTypeEnum>;
  value: Scalars['Float'];
};

export enum TransferCategoryTypeEnum {
  Internal = 'internal',
  Received = 'received',
  ReceivedFromVirtual = 'received_from_virtual',
  Sent = 'sent',
  SentToVirtual = 'sent_to_virtual',
  SplitInternal = 'split_internal'
}

export type TransferEntityMetadata = {
  __typename?: 'TransferEntityMetadata';
  accountId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isAccount: Scalars['Boolean'];
  name: Scalars['String'];
};

export type TxnTypes = {
  __typename?: 'TxnTypes';
  count: Scalars['Int'];
  reason: Scalars['String'];
};

export type UpdateCoinbaseIdResponse = {
  __typename?: 'UpdateCoinbaseIDResponse';
  BTC: Scalars['String'];
  DOGE: Scalars['String'];
  ETH: Scalars['String'];
  LTC: Scalars['String'];
  SOL: Scalars['String'];
  coinbaseID: Scalars['String'];
  url: Scalars['String'];
};

export type UpdateTransactionInput = {
  createdAt?: InputMaybe<Scalars['Date']>;
  globalRuleName?: InputMaybe<Scalars['String']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  overrideIncomeCents?: InputMaybe<Scalars['Float']>;
  overrideLabel: Scalars['Boolean'];
  title?: InputMaybe<Scalars['String']>;
  txnHash?: InputMaybe<Scalars['String']>;
};

export type UpdateTransactionResponse = {
  __typename?: 'UpdateTransactionResponse';
  message?: Maybe<Scalars['String']>;
  messageStatus?: Maybe<Scalars['String']>;
  otherTxnsRuleApplies: Scalars['Int'];
  transaction: Transaction;
};

export type UploadFileResponse = {
  __typename?: 'UploadFileResponse';
  fileName: Scalars['String'];
  objectFileKey: Scalars['String'];
  url: Scalars['String'];
};

export enum UploadManualTransactionFileType {
  AwakenCsvFileUpload = 'awaken_csv_file_upload'
}

export type User = {
  __typename?: 'User';
  activeClient?: Maybe<Client>;
  authProvider: UserAuthProviderEnum;
  authProviderId: Scalars['String'];
  avatarImageUrl?: Maybe<Scalars['String']>;
  biometricPublicKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  hasMobile?: Maybe<Scalars['Boolean']>;
  hasPortfolioEnabled: Scalars['Boolean'];
  hasPushNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  hasTradingEnabled: Scalars['Boolean'];
  hasTwoFactorAuth: Scalars['Boolean'];
  id: Scalars['String'];
  intercomMobileUserHash?: Maybe<Scalars['String']>;
  intercomUserHash?: Maybe<Scalars['String']>;
  isSuperuser: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Float']>;
  numberMobileUser?: Maybe<Scalars['Float']>;
  phoneNumber?: Maybe<Scalars['String']>;
  referredByCode?: Maybe<Scalars['String']>;
  referredByName?: Maybe<Scalars['String']>;
  referredCreditCents?: Maybe<Scalars['Float']>;
  referredNumberOfFreeTxns?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['String']>;
  status: UserStatusEnum;
  updatedAt: Scalars['Date'];
};

export enum UserAuthProviderEnum {
  Firebase = 'Firebase'
}

export enum UserStatusEnum {
  Pending = 'Pending',
  User = 'User'
}

export enum UserTypeEnum {
  Pending = 'Pending',
  Verified = 'Verified'
}

export type VerifyBiometricResponse = {
  __typename?: 'VerifyBiometricResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type VezgoTokenResponse = {
  __typename?: 'VezgoTokenResponse';
  connectUrl: Scalars['String'];
  token: Scalars['String'];
};

export type WatchlistAsset = {
  __typename?: 'WatchlistAsset';
  contractAddress: Scalars['String'];
  provider: Scalars['String'];
  userId: Scalars['String'];
};

export type WatchlistAssetWithInfo = {
  __typename?: 'WatchlistAssetWithInfo';
  contractAddress: Scalars['String'];
  iconImageUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceChangePercentage24h?: Maybe<Scalars['Float']>;
  priceChangePercentage24hFormatted?: Maybe<Scalars['String']>;
  priceFormatted?: Maybe<Scalars['String']>;
  provider: AccountProviderEnum;
  symbol?: Maybe<Scalars['String']>;
};

export type WrappedResponse = {
  __typename?: 'WrappedResponse';
  hasWrapped: Scalars['Boolean'];
  slides: Array<WrappedSlide>;
};

export type WrappedSlide = {
  __typename?: 'WrappedSlide';
  data?: Maybe<Scalars['String']>;
  gradient?: Maybe<Array<Scalars['String']>>;
  text: Array<WrappedSlideText>;
  textColor?: Maybe<Scalars['String']>;
  type: WrappedSlideEnum;
};

export enum WrappedSlideEnum {
  Bonk = 'Bonk',
  Fund = 'Fund',
  Gas = 'Gas',
  Nft = 'NFT',
  NftHodl = 'NFTHodl',
  NftSale = 'NFTSale',
  Personality = 'Personality',
  PersonalityArt = 'PersonalityArt',
  PersonalityMeme = 'PersonalityMeme',
  Protocols = 'Protocols',
  Swap = 'Swap',
  Tax = 'Tax',
  TaxInfinity = 'TaxInfinity',
  TaxShill = 'TaxShill',
  Txns = 'Txns'
}

export type WrappedSlideText = {
  __typename?: 'WrappedSlideText';
  piece: Scalars['String'];
  type: Scalars['String'];
};
