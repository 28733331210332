import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { BaseTransferFields } from "src/api/fragments";
import {
  CheckRuleUsedResponse,
  Mutation,
  MutationUnhideTransfersArgs,
} from "src/api/generated/types";
import { Touchable } from "src/components/Touchable";
import { Maybe } from "src/core";
import { useMyToast } from "src/hooks";

type Props = {
  transactionId: string;
  hasHiddenTransfers?: Maybe<boolean>;
};

export const UnhideTransfers = ({
  transactionId,
  hasHiddenTransfers,
}: Props) => {
  const toast = useMyToast();

  const [unhideTransfers, { loading }] = useMutation<
    {
      unhideTransfers?: Mutation["unhideTransfers"];
    },
    MutationUnhideTransfersArgs
  >(api.transactions.transfers.unhideTransfers, {});

  const onClickUnhideTransfers = () => {
    // if (loading) return;
    if (
      window.confirm(
        "Are you sure you want to unhide the transfers on this transaction?"
      )
    ) {
      try {
        unhideTransfers({
          variables: {
            transactionId,
          },
          refetchQueries: [api.transactions.retrieve],
        });
      } catch (err) {
        toast.show({
          status: "error",
          message: (err as Error).message || "Could not unhide transfers",
        });
      }
    }
  };

  if (!hasHiddenTransfers) return <Box />;
  if (loading) return <Box>Loading...</Box>;
  return (
    <Box>
      <Touchable
        onClick={onClickUnhideTransfers}
        label="Unhide transfers"
        padding="0.5rem 0.75rem"
        // iconName="fa-sharp fa-list-timeline"
      />
    </Box>
  );
};
