import { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useAuth } from "./useAuth";

// screens
import { signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuthStatus } from "src/redux/reducers/user";
import { auth } from "src/utils/firebase";
import { CreateCPA, FilterForm, Results } from "src/views/Accountants";
import Accounts from "src/views/client/Accounts";
import Referrals from "src/views/client/Referrals";
import Rules from "src/views/client/Rules";
import Team from "src/views/client/Settings";
import Taxes from "src/views/client/TaxReports";
import Transactions from "src/views/client/Transactions";
import History from "src/views/consumer/History";
import Disclaimer from "src/views/consumer/Legal/Disclaimer";
import Privacy from "src/views/consumer/Legal/Privacy";
import Terms from "src/views/consumer/Legal/Terms";
// import Assets from "src/views/client/Assets";
import { useApolloClient } from "@apollo/client";
import { Box, Spinner, Text } from "@chakra-ui/react";
import { isNil } from "lodash";
import { show } from "redux-modal";
import { PageTemplateContainer, SideBarPageTemplate } from "src/components";
import { config } from "src/config";
import { useClientById, useMe } from "src/hooks";
import { useIntervalWithDependencies } from "src/hooks/common";
import { useTheme } from "src/hooks/useTheme";
import { setTheme } from "src/redux/reducers/globalState";
import { AuthStatus } from "src/redux/types";
import { isWhiteLabeledDomain } from "src/utils/whitelabel";
import SpamAssets from "src/views/admin/SpamAssets";
import { Login, Signup } from "src/views/Authentication";
import { ForgotPassword } from "src/views/Authentication/ForgotPassword";
import { Welcome } from "src/views/Authentication/Welcome";
import AddAccountScreen from "src/views/client/AddAccount";
import Analytics from "src/views/client/Analytics";
import AssetDetails from "src/views/client/AssetDetails";
import Bookkeeping from "src/views/client/Bookkeeping";
import Harvest from "src/views/client/Harvest";
import Jobs from "src/views/client/Jobs";
import News from "src/views/client/News";
import PortfolioV2 from "src/views/client/PortfolioV2";
import QuoteDetails from "src/views/client/QuoteDetails";
import QuoteRequests from "src/views/client/QuoteRequests";
import TaxGPT from "src/views/client/TaxGPT";
import { TaxProfessionals } from "src/views/client/TaxReports/TaxProfessionals/TaxProfessionals";
import FriendTechTaxes from "src/views/consumer/Blog/FriendTechTaxes";
import Diffs from "src/views/consumer/Diffs";
import TradingLanding from "src/views/consumer/TradingLanding";
import Dashboard from "src/views/Dashboard";
import HatchfiRedirect from "src/views/Oauth/HatchfiRedirect";
import OAuthRedirect from "src/views/Oauth/OAuthRedirect";
import VezgoRedirect from "src/views/Oauth/VezgoRedirect";
import { RedirectToTxn } from "./RedirectToTxn";

const AdminRoutes = () => (
  <Routes>
    <Route path="spam-assets" element={<SpamAssets />} />
  </Routes>
);

const ClientRoutes = () => {
  const location = useLocation();
  // remove the first /
  // ex. /clients/123 => clients/123
  const parts = location.pathname.slice(1).split("/");

  // has to be client path and the last element has to empty or dashboard
  const isAllowed =
    location.pathname.includes("/clients") &&
    (parts[2] === undefined || parts[2] === "accounts");

  // just removing for now. I want to be able to use on phone even if it is crappy. the main flow of adding first account
  // is mobile friendly, rest isn't yet
  // if (isMobile && !isAllowed) {
  //   return <DesktopOnly />;
  // }
  const dispatch = useDispatch();

  useEffect(() => {
    const theme = window.localStorage.getItem("theme");
    dispatch(setTheme(theme === "dark" ? "dark" : "light"));
  }, []);

  return (
    <Routes>
      <Route path=":clientId" element={<DashboardContainer />}>
        <Route path="portfolio">
          <Route path="" element={<PortfolioV2 />} />
          <Route path=":assetKey" element={<AssetDetails />} />
        </Route>
        <Route path="" element={<PortfolioV2 />} />
        <Route path="accounts">
          <Route path="" element={<Accounts />} />
          <Route path="new" element={<AddAccountScreen />} />
        </Route>
        <Route path="quotes">
          <Route path="" element={<Bookkeeping />} />
          <Route path="requests" element={<QuoteRequests />} />
          <Route path=":quoteId" element={<QuoteDetails />} />
        </Route>

        <Route path="news">
          <Route path="" element={<News />} />
        </Route>
        {/* redirect the dashboard route to portfolio */}
        <Route path="dashboard" element={<RedirectToPortfolio />} />
        <Route path="harvest">
          <Route path="" element={<Harvest />} />
          <Route path=":assetKey" element={<AssetDetails />} />
        </Route>
        <Route path="jobs">
          <Route path="" element={<Jobs />} />
        </Route>
        <Route path="transactions">
          <Route path="" element={<Transactions />} />
        </Route>
        <Route path="analytics" element={<Analytics />} />
        <Route path="rules">
          <Route path="" element={<Rules />} />
        </Route>
        <Route path="taxgpt">
          <Route path="" element={<TaxGPT />} />
        </Route>
        <Route path="taxes">
          <Route path="" element={<Taxes />} />
          <Route path="professionals" element={<TaxProfessionals />} />
        </Route>
        <Route path="team" element={<Team />} />
        <Route path="referrals" element={<Referrals />} />
        <Route path="history" element={<History />} />
        <Route
          path="diffs/:oldSummaryId/:newSummaryId/:year"
          element={<Diffs />}
        />
      </Route>
    </Routes>
  );
};

const RedirectToPortfolio = () => {
  const { clientId } = useParams(); // Capture the dynamic segment
  return <Navigate to={`/clients/${clientId}/portfolio`} replace />;
};

const DashboardContainer = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { checkToSyncNewTransactionsForClient } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const { me } = useMe();

  // check to sync new txns every 10 seconds. the backend will prevent rate limiting. this is kinda arbitrary and may end up
  // getting called multiple times. after the backend sets the last synced at there is a 5 minute buffer before can sync again through the UI
  // so this is just the code that checks to call that backend function
  useIntervalWithDependencies(
    () => checkToSyncNewTransactionsForClient(),
    5 * 1000,
    [checkToSyncNewTransactionsForClient]
  );

  const dispatch = useDispatch();

  const _resetPasswordBanner = async () => {
    const hasResetSet = window.localStorage.getItem("show_phishing_alert");

    if (isNil(hasResetSet)) {
      dispatch(show("PhishingAlertModal"));
      window.localStorage.setItem("show_phishing_alert", "true");
      return;
    }
  };

  useEffect(() => {
    if (!me || !me.createdAt || isNil(me?.createdAt)) {
      return;
    }
    // if created before Nov 27th, 2024, show the reset password banner
    if (new Date(me.createdAt) < new Date("2024-11-27")) {
      _resetPasswordBanner();
    }
  }, [me?.createdAt || null]);

  return (
    <SideBarPageTemplate>
      <Outlet />
    </SideBarPageTemplate>
  );
};

const DashboardRoutes = () => {
  // return <MaintenanceOverride />;

  return (
    <Routes>
      <Route path="" element={<Dashboard />} />
    </Routes>
  );
};

const ProtectedRoute = ({
  authStatus,
  children,
}: {
  authStatus: AuthStatus;
  children: JSX.Element;
}) => {
  const theme = useTheme();

  if (authStatus === "LOADING" || authStatus === "NOT_LOADED") {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.background,
        }}
      >
        <Spinner color={theme.header} />
      </div>
    );
  }

  if (authStatus === "NOT_LOGGED_IN") {
    const path = window.location.pathname;
    // add the search params if there are any as well
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchString = params.toString();
    return (
      <Navigate
        to={`/login?redirect=${path}${searchString ? "&" + searchString : ""}`}
        replace
      />
    );
  }

  return children;
};

export const MainRoutes = () => {
  const { pathname } = useLocation();
  const status = useSelector(getUserAuthStatus);
  const authStatus = useSelector(getUserAuthStatus);

  const theme = useTheme();

  useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isWhiteLabeled = isWhiteLabeledDomain();

  const dispatch = useDispatch();

  useEffect(() => {
    const script = document.createElement("script");
    const url = `https://www.google.com/recaptcha/api.js?render=${config.recaptcha.v3Token}`;
    script.src = url;
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    // if dark, set the html to have the theme.background color
    document.documentElement.style.backgroundColor = theme.background;
  }, [theme.background]);

  useEffect(() => {
    const theme = window.localStorage.getItem("theme");
    dispatch(setTheme(theme === "dark" ? "dark" : "light"));
  }, []);

  return (
    <Routes>
      <Route path="" element={<TradingLanding />} />
      {/* <Route path="portfolio" element={<PortfolioLanding />} /> */}
      <Route path="landing" element={<TradingLanding />} />
      {/* <Route path="integrations" element={<Integrations />} /> */}
      <Route path="signup" element={<Signup />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ForgotPassword />} />
      <Route path="welcome" element={<Welcome />} />
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="legal">
        <Route path="disclaimer" element={<Disclaimer />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms-of-service" element={<Terms />} />
      </Route>
      <Route path="blog">
        <Route path="friend-tech-taxes" element={<FriendTechTaxes />} />
      </Route>
      <Route path="ref">
        <Route path=":refCode" element={<RedirectReferral />} />
      </Route>
      {/* <Route path="proof">
        <Route path="nfts" element={<NFTsProof />} />
      </Route> */}
      <Route path="web3cpas">
        <Route path="/web3cpas/createCPA" element={<CreateCPA />} />
        <Route path=":formId" element={<Results />} />
        <Route path="" element={<FilterForm />} />
      </Route>
      <Route
        path="dashboard/*"
        element={
          <ProtectedRoute authStatus={authStatus}>
            <DashboardRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="admin/*"
        element={
          <ProtectedRoute authStatus={authStatus}>
            <AdminRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="clients/*"
        element={
          <ProtectedRoute authStatus={authStatus}>
            <ClientRoutes />
          </ProtectedRoute>
        }
      />
      <Route path="oauth">
        <Route path="hatchfi" element={<HatchfiRedirect />} />
        <Route path="vezgo" element={<VezgoRedirect />} />
        <Route
          path=":provider"
          element={
            <ProtectedRoute authStatus={authStatus}>
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route path="redirect" element={<OAuthRedirect />} />
        </Route>
      </Route>
      <Route path="/:txnHash" element={<RedirectToTxn />}></Route>
    </Routes>
  );
};

const RedirectReferral = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { refCode } = useParams();

  useEffect(() => {
    navigate("/?ref=" + refCode);
  }, []);

  return null;
};

const Logout = () => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  useEffect(() => {
    signOut(auth)
      .then(() => apolloClient.cache.reset())
      .then(() => navigate("/login"));
  }, []);

  return null;
};

export const Navigation = () => (
  <BrowserRouter>
    <MainRoutes />
  </BrowserRouter>
);

const MaintenanceOverride = () => {
  const theme = useTheme();
  return (
    <PageTemplateContainer h="100vh">
      <Box
        display="flex"
        flexDir={"column"}
        justifyContent="center"
        alignItems="center"
        h="100%"
      >
        <Text
          maxWidth="30rem"
          w="96%"
          textAlign={"center"}
          marginBottom="1rem"
          color={theme.text}
        >
          Awaken is undergoing a vicious cyberattack where attackers are DDoSing
          our system trying to crack passwords. To keep your data safe, we have
          temporarily paused all services while we battle it. If you have
          questions, you can email team@awaken.tax. Thank you for understanding,
          we'll get through this ❤️
          {/* We can email you a link to open */}
          {/* the app on your computer. 👇 */}
        </Text>
        {/* <Input
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
          <Button variant="primary">Send email</Button> */}
      </Box>
    </PageTemplateContainer>
  );
};
