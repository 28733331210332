import { HStack, Box, Radio, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { BaseLedgerAccountWithChildrenFields } from "src/api/fragments";
import { Maybe } from "src/core";
import { colors } from "src/theme";

type AccountOptionProps = {
  children: BaseLedgerAccountWithChildrenFields[];
  account: BaseLedgerAccountWithChildrenFields;
  isSelected: boolean;
  isToggled: boolean;
  onSelect: (a: BaseLedgerAccountWithChildrenFields) => void;
  index: number;
};

const AccountOption = ({
  account,
  children,
  isSelected,
  isToggled,
  onSelect,
  index,
}: AccountOptionProps) => {
  const paddingLeft = Math.max((index || 0) * 1.5, 0.5);

  return (
    <HStack
      width="100%"
      padding={`0.5rem 0rem`}
      borderRadius="0.5rem"
      // add padding dynamically to nest the children
      paddingLeft={`${paddingLeft}rem`}
      _hover={{
        backgroundColor: colors.gray90,
      }}
      alignItems="center"
    >
      <Box
        display="flex"
        width="1rem"
        justifyContent="center"
        alignItems="center"
      >
        {children.length > 0 ? (
          <i
            style={{ fontSize: 16 }}
            className={`fa-sharp fa-chevron-${isToggled ? "down" : "right"}`}
          ></i>
        ) : (
          <div />
        )}
      </Box>

      <Radio
        isChecked={isSelected}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onSelect(account);
        }}
      >
        {account.name}
      </Radio>
    </HStack>
  );
};

type AccountSelectableProps = {
  children: BaseLedgerAccountWithChildrenFields[];
  account: BaseLedgerAccountWithChildrenFields;
  selectedPath: Maybe<string>;
  index: number;
  onSelect: (a: BaseLedgerAccountWithChildrenFields) => void;
};

export function AccountOptionRow({
  account,
  children,
  onSelect,
  selectedPath,
  index,
}: AccountSelectableProps) {
  const [toggled, setToggled] = useState(false);

  return (
    <Box
      w="100%"
      userSelect="none"
      cursor="pointer"
      display="flex"
      alignItems="center"
      onClick={(e) => {
        e.stopPropagation();
        setToggled(!toggled);
      }}
    >
      <VStack width="100%">
        <AccountOption
          onSelect={onSelect}
          index={index}
          account={account}
          children={children}
          isToggled={toggled}
          isSelected={selectedPath === account.path}
        />

        {toggled && (
          <VStack marginTop="0px !important" width="100%">
            {children.map((c) => (
              <AccountOptionRow
                onSelect={onSelect}
                key={c.id}
                index={index + 1}
                account={c}
                children={c.children}
                selectedPath={selectedPath}
              />
            ))}
          </VStack>
        )}
      </VStack>
    </Box>
  );
}
