import { useMemo, useState } from "react";
import {
  Box,
  Heading,
  Button,
  ButtonGroup,
  Center,
  Table,
  Text,
  TableContainer,
  Image,
  HStack,
  Container,
} from "@chakra-ui/react";
import {
  Control,
  FieldErrors,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { PageTemplateContainer } from "src/components/layouts/PageTemplate";
import Twitter from "src/assets/awaken/logos/socials/twitter.png";
import LinkedIn from "src/assets/awaken/logos/socials/linkedin.png";
import { colors, other } from "src/theme";
import SecondaryText from "src/components/styled/SecondaryText";
import NavBar from "src/components/NavBar";
import { Select, Input } from "src/components/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormValues,
  CHAINS_TYPES,
  CLIENT_TYPES,
  getDefaultValues,
  NUM_TXN_OPTIONS,
  schema,
} from "./form";
import { useMyToast } from "src/hooks";
import { useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import { api } from "src/api";
import { useNavigate } from "react-router-dom";

export const NOT_ACCEPTING_NEW_CLIENTS = "🔴 Not accepting clients";

export const ACCOUNTANTS = [
  {
    name: "Dan",
    social: "twitter",
    handle: "The_NFT_CPA",
    headline: "PwC manager for 10 yrs",
    description: "Dan has worked at PwC for over 10 years.",
    status: "✅ Accepting clients",
  },
  {
    name: "Matt Metras",
    social: "twitter",
    handle: "EmDeeEm",
    headline: "Featured on USA TODAY",
    description: "Waiting",
    status: NOT_ACCEPTING_NEW_CLIENTS,
  },
  {
    name: "Nick Young",
    social: "twitter",
    handle: "nyoungdumb",
    headline: "3.5K Twitter followers",
    description:
      "NFT and Cryptocurrency Tax Attorney at The Costello Law Firm, P.A. Our firm is dedicated to helping clients create NFT and Cryptocurrency tax strategies that are best suited for their tax and financial goals and dreams",
    status: "🟡 Unconfirmed",
  },
  {
    name: "Mark A. Costello",
    social: "linkedin",
    handle: "markacostello",
    headline: "Featured on Morning Brew",
    description:
      "NFT and Cryptocurrency Tax Attorney at The Costello Law Firm, P.A. Our firm is dedicated to helping clients create NFT and Cryptocurrency tax strategies that are best suited for their tax and financial goals and dreams",
    status: "🟡 Unconfirmed",
  },
];

export function FilterForm() {
  const toast = useMyToast();
  const navigate = useNavigate();

  const [
    createFilterFormSubmission,
    { loading: loadingCreateFilterFormSubmission },
  ] = useMutation(api.misc.filterForm);

  const { data: numCPAs, loading: loadingNumCPAs } = useQuery(
    api.misc.getNumCPAs
  );

  // Form hooks / fxns
  const values = useMemo(() => getDefaultValues(), []);

  const formProps = useForm<FormValues>({
    defaultValues: values,
    resolver: yupResolver(schema),
  });

  function _onInvalidForm(values: FieldErrors<FormValues>) {
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      const message = _.get(values, keys[i])?.message;
      if (message) {
        toast.show({
          message,
          status: "error",
        });
      }
    }
  }

  async function _onSubmit(values: FormValues) {
    try {
      if (!values.clientType || !values.chains || !values.numTransactions) {
        throw Error("Please fill out all required form fields");
      }

      const id = (
        await createFilterFormSubmission({
          variables: {
            clientType: values.clientType.value,
            chainsType: values.chains.value,
            numTxns: values.numTransactions.value,
            name: values.name,
            email: values.email,
          },
        })
      ).data.createFilterFormSubmission;

      navigate(`${id}`);
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  }

  if (loadingNumCPAs) return null;

  // Filter Modal
  return (
    <PageTemplateContainer bgColor={colors.white}>
      <NavBar />
      <Examples />
      <Box margin="auto" w="96%" maxW="40rem" paddingBottom="5rem">
        <Heading>Crypto CPAs are hard to find.</Heading>
        <Text>
          Fortunately for you, we've found <strong>{numCPAs.getNumCPAs}</strong>{" "}
          of them. If you're a CPA that wants to be added, reach out to{" "}
          <a href="mailto:team@awaken.tax" color={colors.primary}>
            team@awaken.tax
          </a>
        </Text>
        <form onSubmit={formProps.handleSubmit(_onSubmit, _onInvalidForm)}>
          <Container padding="0px" marginTop="1rem">
            <Select
              label="Are you an individual or a business?"
              name="clientType"
              control={formProps.control}
              options={CLIENT_TYPES}
              isRequired
              containerStyle={{ marginBottom: "1.3rem" }}
            />
            <Select
              label="Which chains do you use?"
              name="chains"
              control={formProps.control}
              options={CHAINS_TYPES}
              isRequired
              containerStyle={{ marginBottom: "1.3rem" }}
            />
            <Select
              label="How many total transactions do you have?"
              name="numTransactions"
              control={formProps.control}
              options={NUM_TXN_OPTIONS}
              isRequired
              containerStyle={{ marginBottom: "1.3rem" }}
            />
            <Input
              control={formProps.control}
              name="name"
              label="What's your full name?"
              placeholder="John Legend"
              type="name"
              isRequired
            />
            <Input
              control={formProps.control}
              name="email"
              label="What's your email?"
              placeholder="john.legend@gmail.com"
              type="email"
              marginBottom="0.5rem"
              isRequired
            />
          </Container>
          <Button
            variant="primary"
            width="100%"
            type="submit"
            isLoading={loadingCreateFilterFormSubmission}
          >
            Find CPAs
          </Button>
          <SecondaryText
            marginTop="1rem"
            text="Are you a web3 CPA? Email us to get registered: team@awaken.tax"
          />
        </form>
      </Box>
    </PageTemplateContainer>
  );
}

function Examples() {
  return (
    <HStack
      w="100%"
      h="8rem"
      padding="0 1rem"
      marginTop="2rem"
      overflowX="scroll"
    >
      {ACCOUNTANTS.map((a) => {
        return (
          <HStack key={a.handle}>
            <Box minW="1rem" />
            {/* <ExampleAccountant {...a} key={a.handle} /> */}
          </HStack>
        );
      })}
      <Box minW="1rem" />
      <Box
        minW="20rem"
        maxW="20rem"
        height="6.5rem"
        borderRadius={other.borderRadius}
        border={other.boxBorder}
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
        padding="1rem"
        transition="height 0.2s ease-in-out"
        backgroundColor={colors.white}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        fontWeight="bold"
      >
        <Heading>•••</Heading>
      </Box>
      <Box minW="1rem" />
    </HStack>
  );
}

// function ExampleAccountant({
//   name,
//   social,
//   handle,
//   headline,
//   description,
//   status,
// }: {
//   name: string;
//   social: string;
//   handle: string;
//   headline: string;
//   description: string;
//   status: string;
// }) {
//   const src = social === "linkedin" ? `${handle}.jpg` : `${handle}.png`;
//   return (
//     <HStack
//       minW="20rem"
//       maxW="20rem"
//       height="6.5rem"
//       borderRadius={other.borderRadius}
//       border={other.boxBorder}
//       boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
//       padding="1rem"
//       transition="height 0.2s ease-in-out"
//       backgroundColor={colors.white}
//     >
//       <Image
//         width="4rem"
//         height="4rem"
//         objectFit="contain"
//         objectPosition={"center"}
//         src={require(`src/assets/awaken/accountants/${src}`)}
//         borderRadius={other.borderRadius}
//       />
//       <Box flex="1">
//         <Heading size="md" margin="1rem 0 0 0">
//           {name}
//         </Heading>
//         <SecondaryText text={headline} />
//       </Box>
//     </HStack>
//   );
// }
