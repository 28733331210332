import { Box, HStack, List, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { isEmpty } from "lodash/fp";
import { useState } from "react";
import Confetti from "react-confetti";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "src/components";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import { useMe, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";
import { D } from "src/utils/helpers";

export function Welcome() {
  const toast = useMyToast();
  const [runConfetti, setRunConfetti] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const amountFreeCreditCents = searchParams.get("amountFreeCreditCents");
  const credit = !isEmpty(amountFreeCreditCents)
    ? D(parseFloat(amountFreeCreditCents || "0"), "USD")
    : null;

  const { me } = useMe();
  const theme = useTheme();

  const _claimTransactions = () => {
    setRunConfetti(true);

    toast.show({
      message: `Congrats, you unlocked ${credit?.toFormat()} of Awaken credit! It applies automatically at checkout.`,
      status: "success",
    });

    setTimeout(() => {
      navigate(redirect || "/dashboard");
    }, 3000);
  };

  return (
    <NoNavBarPageTemplate>
      <VStack
        w="100%"
        padding="2rem 0"
        flexDir="column"
        display="flex"
        minH="100vh"
        justifyContent="center"
      >
        <Confetti
          run={runConfetti}
          colors={[
            // colors.blue50,
            // colors.green50,
            // colors.purple50,
            // colors.red50,
            colors.yellow50,
            colors.orange50,
            // colors.pink50,
          ]}
          numberOfPieces={250}
          recycle={false}
        />
        <Box>
          <Box
            display="flex"
            flexDir="column"
            flex={1}
            maxWidth="30rem"
            margin="auto"
            padding="2rem"
            w="100%"
            borderRadius={other.borderRadius}
            bg={theme.background}
            border={`1px solid ${theme.border}`}
          >
            <Box width="100%">
              <Text
                color={theme.header}
                fontSize="3xl"
                fontWeight="600"
                marginBottom="1rem"
              >
                Welcome to Awaken 👋
              </Text>

              <Text color={theme.text} fontSize={"18px"}>
                For a limited time, you get{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    backgroundColor: colors.primary,
                    color: colors.white,
                    borderRadius: 100,
                    padding: 5,
                  }}
                >
                  {credit?.toFormat()}
                </span>{" "}
                of Awaken credit for <b>FREE</b>!
              </Text>

              <List style={{ padding: "2rem 0" }} marginBottom="0 !important">
                <ListItem
                  iconName="fa-sharp fa-timer"
                  backgroundColor={colors.pink50}
                  header="Save Hours of Time"
                  subtitle="Our smart automation makes taxes easy."
                />
                <ListItem
                  iconName="fa-sharp fa-bullseye-arrow"
                  backgroundColor={colors.blue50}
                  header="Accurate Reports"
                  subtitle="Unlike competitors, Awaken understands web3."
                />
                <ListItem
                  iconName="fa-sharp fa-flag-usa"
                  header="Download Tax Reports"
                  backgroundColor={colors.positive}
                  subtitle="TurboTax, TaxAct, 8949, and more."
                />
              </List>

              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 1 }}>
                <Button
                  onClick={_claimTransactions}
                  padding="1.5rem 0.5rem"
                  disabled={runConfetti}
                  bg={`linear-gradient(30deg, ${colors.green60} 0%, ${colors.green50} 50%, ${colors.green70} 100%) !important`}
                  color={colors.white}
                  style={{ width: "100%" }}
                >
                  Claim {credit?.toFormat()} Credit{" "}
                  <i
                    style={{ marginLeft: 15 }}
                    className="fa-sharp fa-arrow-right"
                  />
                </Button>
              </motion.div>
            </Box>
          </Box>
        </Box>
      </VStack>
    </NoNavBarPageTemplate>
  );
}

const ListItem = ({
  header,
  subtitle,
  iconName,
  backgroundColor = colors.gray80,
}: {
  subtitle: string;
  header: string;
  iconName: string;
  backgroundColor?: string;
}) => {
  const theme = useTheme();

  return (
    <HStack marginBottom="1.5rem">
      <div
        style={{
          width: 40,
          height: 40,
          backgroundColor,
          borderRadius: 50,
          flexShrink: 0,
          display: "flex",
          fontSize: 20,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          color: colors.white,
          marginRight: "0.5rem",
        }}
      >
        <i className={iconName} />
      </div>
      <VStack alignItems="flex-start">
        <Text color={theme.header} fontSize="lg" fontWeight="600">
          {header}
        </Text>
        <Text color={theme.text} marginTop="0 !important" fontSize="md">
          {subtitle}
        </Text>
      </VStack>
    </HStack>
  );
};
