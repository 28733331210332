import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { GraphQLError } from "graphql";
import { groupBy, uniq } from "lodash";
import React, { useMemo, useState } from "react";
import { api } from "src/api";
import {
  Query,
  TransactionTypeOption,
  TransactionTypeOptionResponse,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { Input } from "../styled";
import { AwakenTooltip } from "../styled/AwakenTooltip";
import StatusTag from "../styled/StatusTag";

type ReviewStatusTagProps = {
  isReviewed: boolean;
  capPriority?: Maybe<number>;
  needsRecalculate: boolean;
  transactionId?: string;
  type?: string | null;
  currentLabelUsed?: Maybe<string>;
  defaultLabels?: Maybe<TransactionTypeOptionResponse>;
};

export const ReviewStatusTag = React.memo(
  ({
    isReviewed,
    capPriority,
    type,
    currentLabelUsed,
    transactionId,
    needsRecalculate,
    defaultLabels,
  }: ReviewStatusTagProps) => {
    if (needsRecalculate) {
      return (
        <AwakenTooltip openDelay={250} label={"Run recalculate to update this"}>
          <div>
            <StatusTag
              iconName="fa-sharp fa-question-circle"
              type={"info"}
              boxProps={{ style: { flexWrap: "nowrap" } }}
              label="Needs Recalculate"
            />
          </div>
        </AwakenTooltip>
      );
    } else if (isReviewed) {
      return (
        <UpdatableReviewTag
          iconName="fa-sharp fa-check-circle"
          type="success"
          label={"Reviewed"}
          capPriority={capPriority}
          transactionId={transactionId}
          processingType={type}
          currentLabelUsed={currentLabelUsed}
          needsReview={false}
          defaultLabels={defaultLabels}
        />
      );
    } else {
      return (
        <UpdatableReviewTag
          label="Needs Review"
          iconName="fa-sharp fa-chevron-down"
          type={capPriority === 3 ? "error" : "none"}
          capPriority={capPriority}
          transactionId={transactionId}
          needsReview={true}
          defaultLabels={defaultLabels}
        />
      );
    }
  }
);

const UpdatableReviewTag = React.memo(
  ({
    capPriority,
    transactionId,
    iconName,
    type,
    label,
    currentLabelUsed,
    processingType,
    needsReview,
    defaultLabels,
  }: {
    capPriority?: Maybe<number>;
    transactionId?: string;
    iconName: string;
    type: any;
    label: string;
    currentLabelUsed?: Maybe<string>;
    processingType?: Maybe<string>;
    needsReview?: boolean;
    defaultLabels?: Maybe<TransactionTypeOptionResponse>;
  }) => {
    const toast = useMyToast();
    const theme = useTheme();

    const [search, setSearch] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);

    const [updateTransaction, { loading: isLoadingUpdateTransaction }] =
      useMutation(api.transactions.update);

    const [getLabelOptions, { data: data }] = useLazyQuery<
      Pick<Query, "getTransactionTypeOptions">
    >(api.transactions.typeOptions);

    const onSelectOption = async (option: Maybe<TransactionTypeOption>) => {
      if (!option) return;

      try {
        if (!option.applicable) {
          return;
        }

        const variables = {
          updates: {
            label: option.value,
            overrideLabel: true,
          },
          transactionId: transactionId,
          createDefaultRule: false,
        };

        setIsOpen(false);

        await updateTransaction({
          variables,
          refetchQueries: [
            api.transactions.retrieve,
            api.clients.transactions,
            api.transactions.countTransactions,
            api.transactions.getNumTxnTypes, // refetch gains / losses txns
            api.graph.getReplayInfo,
          ],
        });

        toast.show({
          message: `Successfully labeled transaction as ${option.label}`,
          status: "success",
        });
      } catch (err) {
        //
        toast.show({
          message:
            (err as GraphQLError).message ||
            "There was an error updating the transaction.",
          status: "error",
        });
      }
    };

    const _getOptions = async (e: any) => {
      // get the labels

      e.preventDefault();
      e.stopPropagation();

      try {
        // trigger open, don't wait
        setIsOpen(true);

        await getLabelOptions({
          variables: {
            transactionId: transactionId,
          },
        });
      } catch (err) {
        // toast.show({
        //   message:
        //     (err as any)?.message || "There was an error loading the labels",
        //   status: "error",
        // });
      }
    };

    const _labelOptions =
      data?.getTransactionTypeOptions.labels || defaultLabels?.labels || [];

    // console.log("params: ", data, defaultLabels);

    const labelOptions = useMemo(() => {
      return _labelOptions.filter((o) =>
        o.label.toLowerCase().includes(search.toLowerCase())
      );
    }, [_labelOptions, search]);

    const categories = useMemo(
      () => (!labelOptions ? [] : uniq(labelOptions.map((o) => o.category))),
      [labelOptions]
    );

    const optionsByCategory = useMemo(
      () => groupBy(labelOptions, (o: TransactionTypeOption) => o.category),
      [labelOptions]
    );

    return (
      <Popover isLazy placement="bottom" isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Box>
            <AwakenTooltip openDelay={250} message={processingType}>
              <div>
                <StatusTag
                  iconName={iconName}
                  onClick={(e) => {
                    if (isOpen) {
                      onClose();
                    } else {
                      _getOptions(e);
                    }
                  }}
                  type={type}
                  boxProps={{
                    style: {
                      flexWrap: "nowrap",
                      border: !needsReview
                        ? `1px solid ${colors.positive}`
                        : capPriority === 3
                        ? `1px solid ${colors.red50}`
                        : `1px solid ${theme.border}`,
                    },
                  }}
                  label={label}
                />
              </div>
            </AwakenTooltip>
          </Box>
        </PopoverTrigger>
        <PopoverContent
          width={225}
          paddingTop={"10px"}
          overflowY="hidden"
          overflowX="hidden"
          style={{
            paddingTop: 10,
            border: `1px solid ${theme.border}`,
            backgroundColor: theme.background,
            boxShadow:
              theme.theme === "dark"
                ? `0 0 10px 0 ${theme.border}`
                : `0 0 10px 0 ${theme.ternaryBackground}`,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {/* <PopoverArrow /> */}

          <Input
            iconLeft={
              <i
                className="fa-solid fa-magnifying-glass"
                style={{
                  marginLeft: 15,
                  position: "relative",
                  top: -2,
                  color: colors.gray60,
                }}
              />
            }
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            focusBorderColor={"transparent"}
            style={{
              border: `1px solid ${theme.border}`,
              margin: "0 10px",
              height: 35,
              backgroundColor: theme.secondaryBackground,
              paddingLeft: 30,
              fontSize: 14,
              marginBottom: 10,
            }}
            containerStyle={{ marginBottom: 0 }}
            placeholder="Search labels"
          />
          <Grid
            paddingBottom="0"
            templateColumns={{
              base: "repeat(1, 1fr)",
            }}
            style={{
              borderTop: `1px solid ${theme.border}`,
              overflowY: "scroll",
              padding: 5,
              height: 250,
            }}
            // alignContent="flex-start"
          >
            {categories.map((category) => (
              <CategorySection
                selectedOptionValue={currentLabelUsed}
                onSelectOption={onSelectOption}
                category={category}
                options={optionsByCategory[category]}
              />
            ))}
          </Grid>

          <div
            onClick={onClose}
            style={{
              borderTop: `1px solid ${theme.border}`,
              padding: 10,
              backgroundColor: theme.medBackground,
              color: theme.text,
              textAlign: "right",
            }}
          >
            Close{" "}
            <i style={{ fontSize: 12 }} className="fa-sharp fa-eye-slash" />
          </div>
        </PopoverContent>
      </Popover>
    );
  }
);

const CategorySection = React.memo(
  ({
    category,
    options,
    selectedOptionValue,
    onSelectOption,
  }: {
    category: string;
    options: TransactionTypeOption[];
    selectedOptionValue?: Maybe<string>;
    onSelectOption: (option: TransactionTypeOption) => void;
  }) => {
    const theme = useTheme();

    if (!options || options.length === 0) return null;
    return (
      <GridItem
        colSpan={1}
        position="relative"
        key={category}
        style={{
          marginBottom: 15,
          paddingTop: 15,
          textAlign: "left",
          paddingBottom: 5,
          borderBottom: `1px solid ${theme.border}`,
        }}
      >
        <Heading
          color={theme.text}
          fontWeight="black"
          textTransform="uppercase"
          marginTop="0rem"
          padding={"0 7px"}
          marginBottom={"0.25rem"}
          fontSize={10}
        >
          {category}
        </Heading>
        {options.map((o) => {
          const isSelected = selectedOptionValue === o.value;
          return (
            <Box
              _hover={{
                backgroundColor: theme.secondaryBackground,
              }}
              onClick={() => onSelectOption(o)}
              isDisabled={!o.applicable}
              bg={isSelected ? theme.greenBg : undefined}
              style={{
                borderRadius: 10,
                opacity: o.applicable ? 1 : 0.33,
                // borderBottom: `1px solid ${theme.border}`,
                padding: 7,
                color: !o.applicable ? colors.gray4 : theme.header,
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              key={o.value}
            >
              <span style={{ flex: 1 }}>{o.label}</span>

              {isSelected && (
                <i
                  className="fa-solid fa-check"
                  style={{ color: colors.positive }}
                />
              )}
            </Box>
          );
        })}
      </GridItem>
    );
  }
);
