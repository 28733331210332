import { FormLabel, HStack, Heading, Box, RadioGroup } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { BaseLedgerAccountWithChildrenFields } from "src/api/fragments";
import { hasValue, Maybe } from "src/core";
import WhiteBox from "src/components/styled/WhiteBox";
import BackButton from "src/components/styled/BackButton";
import SecondaryText from "src/components/styled/SecondaryText";
import { PartialLedgerAccount, useClientById } from "src/hooks";
import { useParams } from "react-router-dom";
import { Account, AccountInfoOption, ModalTypeName } from "./types";
import { AccountOptionRow } from "./AccountOptionRow";
import { Input } from "src/components/styled";
import { keyBy } from "lodash/fp";
import { Dictionary } from "lodash";

type ModalContentProps = {
  modalType: ModalTypeName;
  name: string;
  rootAccount: Maybe<BaseLedgerAccountWithChildrenFields>;
  selectedParentAccount: Maybe<BaseLedgerAccountWithChildrenFields>;
  setName: (s: string) => void;
  setModalType: (t: ModalTypeName) => void;
  setRootAccount: (a: BaseLedgerAccountWithChildrenFields) => void;
  setSelectedParentAccount: (a: BaseLedgerAccountWithChildrenFields) => void;
};

export const ModalContent = ({
  modalType,
  name,
  rootAccount,
  selectedParentAccount,
  setName,
  setModalType,
  setRootAccount,
  setSelectedParentAccount,
}: ModalContentProps) => {
  const { clientId } = useParams<{ clientId: string }>();

  const { getLedgerAccounts, ledgerAccounts } = useClientById(clientId);

  const onSelectRootAccount = (a: BaseLedgerAccountWithChildrenFields) => {
    setModalType(ModalTypeName.AccountTreeSelector);
    setRootAccount(a);
  };

  const ledgerAccountMapping = useMemo(
    () => keyBy("id", ledgerAccounts.accounts),
    [ledgerAccounts.accounts]
  );

  useEffect(() => void getLedgerAccounts(6), [getLedgerAccounts, modalType]);

  // return the form that makes sense based on the user's page
  switch (modalType) {
    case ModalTypeName.AccountDetailsForm:
      return <AccountDetailsForm name={name} setName={setName} />;
    case ModalTypeName.CreateAccountRootLevel:
      return (
        <RootAccountSelector
          name={name}
          setModalType={setModalType}
          ledgerAccounts={ledgerAccounts.tree}
          onSelectRootAccount={onSelectRootAccount}
        />
      );
    case ModalTypeName.AccountTreeSelector:
      return (
        <SubAccountTreeSelector
          setModalType={setModalType}
          setSelectedParentAccount={setSelectedParentAccount}
          name={name}
          rootAccount={rootAccount}
          ledgerAccountMapping={ledgerAccountMapping}
          selectedParentAccount={selectedParentAccount}
        />
      );
    default:
      return null;
  }
};

const AccountDetailsForm = ({
  name,
  setName,
}: {
  name: string;
  setName: (n: string) => void;
}) => (
  <Box>
    <Input
      label="Account Name"
      placeholder="Ex. Staking Income"
      onChange={(e) => setName(e.target.value || "")}
      value={name}
    />
  </Box>
);

const RootAccountSelector = ({
  ledgerAccounts,
  name,
  onSelectRootAccount,
  setModalType,
}: {
  name: string;
  ledgerAccounts: BaseLedgerAccountWithChildrenFields[];
  onSelectRootAccount: (a: BaseLedgerAccountWithChildrenFields) => void;
  setModalType: (t: ModalTypeName) => void;
}) => (
  <Box>
    <BackButton
      onClick={() => setModalType(ModalTypeName.AccountDetailsForm)}
    />
    <SecondaryText text={`Where do you want to place "${name}"?`} />
    <FormLabel marginTop="0.5rem">All Accounts</FormLabel>
    {ledgerAccounts.map((la) => (
      <AccountClickable
        account={{ name: la.name, id: la.id, level: 1 }}
        icon="fa-sharp fa-inbox-in"
        onSelectAccount={() => onSelectRootAccount(la)}
      />
    ))}
  </Box>
);

const SubAccountTreeSelector = ({
  rootAccount,
  name,
  selectedParentAccount,
  ledgerAccountMapping,
  setModalType,
  setSelectedParentAccount,
}: {
  rootAccount?: Maybe<BaseLedgerAccountWithChildrenFields>;
  selectedParentAccount?: Maybe<BaseLedgerAccountWithChildrenFields>;
  name: string;
  ledgerAccountMapping: Dictionary<PartialLedgerAccount>;
  setModalType: (t: ModalTypeName) => void;
  setSelectedParentAccount: (a: BaseLedgerAccountWithChildrenFields) => void;
}) => {
  const path = useMemo(
    () =>
      selectedParentAccount
        ? (selectedParentAccount.path || "")
            .split(".")
            .map((id) => ledgerAccountMapping[id])
            .filter(hasValue)
            .map((l) => l.name)
            .concat(name)
            .join(" > ")
        : "",
    [selectedParentAccount, name]
  );

  if (!rootAccount) {
    return null;
  }

  return (
    <Box>
      <BackButton
        onClick={() => setModalType(ModalTypeName.CreateAccountRootLevel)}
      />
      <SecondaryText text={`Where do you want to place "${name}"?`} />
      <FormLabel marginTop="0.5rem">{path}</FormLabel>
      <RadioGroup name="group">
        {(rootAccount.children || []).map((c) => (
          <AccountOptionRow
            index={0}
            selectedPath={selectedParentAccount?.path ?? null}
            onSelect={setSelectedParentAccount}
            account={c}
            key={c.id}
            children={c.children}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

type AccountClickableProps = {
  account: Account;
  icon: string;
  onSelectAccount: () => void;
};

function AccountClickable({
  account,
  icon,
  onSelectAccount,
}: AccountClickableProps) {
  return (
    <WhiteBox cursor="pointer" onClick={() => onSelectAccount()}>
      <HStack>
        {(icon && <i className={icon}></i>) || <div />}
        <Heading size="sm">{account.name}</Heading>
      </HStack>
    </WhiteBox>
  );
}
