import { Maybe } from "src/core";
import { generateOptionSchema, Option } from "src/utils/schemas";
import * as yup from "yup";

export type FormValues = {
  accountantNotes: Maybe<string>;
  accountantStatus: Maybe<Option>;
};

export const getDefaultValues = (
  defaults?: Partial<FormValues>
): FormValues => ({
  accountantNotes: defaults?.accountantNotes || null,
  accountantStatus: defaults?.accountantStatus || null,
});

export const schema = yup.object().shape({
  accountantNotes: yup.string().label("Notes").nullable(),
  accountantStatus: generateOptionSchema(true).nullable(),
});
