import { BaseTransferFields } from "src/api/fragments";
import { ImportTypeEnum } from "src/api/generated/types";

export const hasTransferSent = (transfer: BaseTransferFields) => {
  if (!transfer.fromAccount) return false;
  if (
    transfer.toAccount &&
    transfer.toAccount.importType !== ImportTypeEnum.VirtualAccount
  )
    return false;
  return true;
};

export const hasTransferReceived = (transfer: BaseTransferFields) => {
  if (!transfer.toAccount) return false;
  if (
    transfer.fromAccount &&
    transfer.fromAccount.importType !== ImportTypeEnum.VirtualAccount
  )
    return false;
  return true;
};
