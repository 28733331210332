import * as yup from "yup";

export type Option<T = null> = {
  value: string | number;
  label: string | JSX.Element;
  data?: T;
};

export const generateOptionSchema = (labelMixed?: boolean) =>
  yup.object().shape({
    value: yup.string().required(),
    label: labelMixed ? yup.mixed().required() : yup.string().required(),
  });

export const generateOptionArraySchema = (allowedValues?: string[]) =>
  yup.array().of(
    yup.object().shape({
      value: allowedValues
        ? yup.string().oneOf(allowedValues).required()
        : yup.string().required(),
      label: yup.string().required(),
    })
  );
