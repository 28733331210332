import React from "react";
import {
  Box,
  VStack,
  HStack,
  Heading,
  Text,
  Badge,
  Divider,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { api } from "src/api";
import {
  BookkeepingQuoteStatusEnum,
  Mutation,
  Query,
} from "src/api/generated/types";
import { useTheme } from "src/hooks/useTheme";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { isNil, noop } from "lodash";
import { D } from "src/utils/helpers";
import StatusTag from "src/components/styled/StatusTag";
import { QuoteStatus } from "../Bookkeeping";
import { AssetIcon } from "src/components/styled/Assets";
import { useDownloadFile } from "src/hooks/common";
import { Touchable } from "src/components/Touchable";
import { QuotePriceModal } from "src/components/modals/QuotePriceModal";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import BackButton from "src/components/styled/BackButton";
import { useMyToast } from "src/hooks";
import { Button } from "src/components";
import { colors } from "src/theme";
import { QuoteInvoiceModal } from "src/components/modals/QuoteInvoiceModal";

function QuoteDetails() {
  const theme = useTheme();
  const { quoteId, clientId } = useParams();
  const dispatch = useDispatch();

  const toast = useMyToast();

  const { download } = useDownloadFile();

  const [updateBookkeepingQuote] = useMutation<
    Pick<Mutation, "updateBookkeepingQuote">
  >(api.bookkeepingQuotes.update);

  const [getClientBillingPortal] = useLazyQuery<
    Pick<Query, "getClientBillingPortal">
  >(api.clients.billingPortal);

  const { data } = useQuery<Pick<Query, "getBookkeepingQuote">>(
    api.bookkeepingQuotes.retrieve,
    {
      variables: {
        bookkeepingId: quoteId,
      },
      skip: !quoteId,
      fetchPolicy: "network-only",
    }
  );

  const _updateStatus = async (status: BookkeepingQuoteStatusEnum) => {
    try {
      // confirm
      const confirmed = window.confirm(
        `Are you sure you want to update the quote to ${status}? You'll receive an email regarding the updated status.`
      );

      if (!confirmed) {
        return;
      }

      await updateBookkeepingQuote({
        variables: {
          bookkeepingQuoteId: quoteId,
          status: status,
        },
        refetchQueries: [
          api.bookkeepingQuotes.retrieve,
          api.bookkeepingQuotes.listForClient,
        ],
      });

      toast.show({
        message: "Quote updated!",
        status: "success",
      });
    } catch (e) {
      console.error(e);

      toast.show({
        message: "Error updating quote to client.",
        status: "error",
      });
    }
  };

  const _manageBilling = async () => {
    const params = await getClientBillingPortal({
      variables: {
        clientId: clientId,
      },
    });

    const url = params.data?.getClientBillingPortal || "";

    if (url) {
      window.open(url, "_blank");
    }
  };

  const quote = data?.getBookkeepingQuote ?? null;
  const navigate = useNavigate();

  if (!quote) {
    return null;
  }

  return (
    <Box margin="0" padding={6}>
      <QuotePriceModal />
      <QuoteInvoiceModal />

      <Touchable
        onClick={() => navigate(-1)}
        style={{
          color: theme.text,
          display: "inline-block",
          fontSize: 16,
        }}
      >
        <i className="fas fa-chevron-left" style={{ marginRight: 5 }} /> Back
      </Touchable>

      <br />
      <br />

      <VStack spacing={8} align="stretch">
        <HStack justifyContent="space-between">
          <Heading
            color={theme.header}
            marginBottom={0}
            marginTop="0"
            size="lg"
          >
            Bookkeeping Quote
          </Heading>
          <QuoteStatus status={quote.status} />
        </HStack>

        <HStack>
          <a href="mailto:team@awaken.tax?subject=Bookkeeping Quote Inquiry">
            <Text color={theme.text} fontWeight="normal">
              Have a question? Contact us <b>team@awaken.tax</b>
            </Text>
          </a>
        </HStack>

        {quote.isBookkeeper && (
          <HStack justifyContent="flex-start" spacing={4}>
            <Button
              onClick={() => _updateStatus(BookkeepingQuoteStatusEnum.Sent)}
              variant="secondary"
            >
              Send Client Quote{" "}
              <i
                style={{ marginLeft: 10 }}
                className="fa-sharp fa-paper-plane"
              />
            </Button>

            <Button
              onClick={() => dispatch(show("QuoteInvoiceModal", { quote }))}
              variant="primary"
            >
              Generate Invoice{" "}
              <i
                style={{ marginLeft: 10 }}
                className="fa-sharp fa-file-invoice"
              />
            </Button>
          </HStack>
        )}

        {quote.status === BookkeepingQuoteStatusEnum.Sent && (
          <HStack justifyContent="flex-start" spacing={4}>
            <Button
              onClick={() => {
                download(quote.quoteFileUrl || "");
              }}
              disabled={!quote.quoteFileUrl}
              colorScheme="gray"
            >
              Download Quote PDF
            </Button>

            <Button
              colorScheme="red"
              onClick={() => _updateStatus(BookkeepingQuoteStatusEnum.Rejected)}
            >
              Reject Quote
            </Button>
            <Button
              variant="primary"
              onClick={() => _updateStatus(BookkeepingQuoteStatusEnum.Accepted)}
            >
              Accept Quote
            </Button>
          </HStack>
        )}

        <Box
          bg={theme.background}
          borderRadius="md"
          borderWidth={1}
          borderColor={theme.border}
          p={6}
        >
          <VStack spacing={4} align="stretch">
            <HStack justifyContent="space-between">
              <Text color={theme.text} fontWeight="bold">
                Quote ID
              </Text>
              <Text
                style={{
                  textAlign: "right",
                }}
                color={theme.text}
              >
                {quote.id}
              </Text>
            </HStack>
            {/* <Divider /> */}
            <HStack justifyContent="space-between">
              <Text color={theme.text} fontWeight="bold">
                Description
              </Text>
              <Text style={{ textAlign: "right" }} color={theme.text}>
                {quote.description || "N/A"}
              </Text>
            </HStack>
            <HStack justifyContent="space-between">
              <Text color={theme.text} fontWeight="bold">
                Tax Years
              </Text>
              <Text color={theme.text}>
                {quote.taxYearsDescription || "N/A"}
              </Text>
            </HStack>
          </VStack>
        </Box>

        <Box
          bg={theme.background}
          borderRadius="md"
          borderWidth={1}
          borderColor={theme.border}
          p={6}
        >
          <VStack spacing={4} align="stretch">
            <HStack>
              <Heading flex={1} color={theme.header} marginTop="0" size="md">
                Pricing Options
              </Heading>

              {quote.isBookkeeper && (
                <Touchable
                  style={{
                    color: theme.text,
                  }}
                  onClick={() =>
                    dispatch(
                      show("QuotePriceModal", {
                        quote,
                      })
                    )
                  }
                >
                  Edit <i className="fas fa-pen" style={{ marginLeft: 10 }} />
                </Touchable>
              )}
            </HStack>
            <HStack justifyContent="space-between">
              <Text color={theme.text} fontWeight="bold">
                Quote
              </Text>
              <Button
                onClick={() => {
                  download(quote.quoteFileUrl || "");
                }}
                disabled={!quote.quoteFileUrl}
                colorScheme="gray"
              >
                Download Quote PDF
              </Button>
            </HStack>
            {/* <HStack justifyContent="space-between">
              <Text color={theme.text} fontWeight="bold">
                Upfront Price
              </Text>
              <Text color={theme.text}>
                {isNil(quote.upfrontPriceCents)
                  ? "TBD"
                  : D(quote.upfrontPriceCents ?? 0).toFormat()}
              </Text>
            </HStack> */}
            {/* <HStack justifyContent="space-between">
              <Text color={theme.text} fontWeight="bold">
                Monthly Price
              </Text>
              <Text color={theme.text}>
                {isNil(quote.monthlyPriceCents)
                  ? "TBD"
                  : D(quote.monthlyPriceCents ?? 0).toFormat()}
                /mo
              </Text>
            </HStack> */}
            <HStack justifyContent="space-between">
              <Text color={theme.text} fontWeight="bold">
                Payment Status
              </Text>
              <StatusTag
                type={quote.hasPaid ? "success" : "info"}
                label={quote.hasPaid ? "Paid" : "Unpaid"}
              />
            </HStack>

            <HStack justifyContent="space-between">
              <Text color={theme.text} fontWeight="bold">
                Manage Subscription
              </Text>

              {quote.hasPaid ? (
                <Button onClick={_manageBilling} variant="primary">
                  Manage{" "}
                  <i
                    style={{ marginLeft: 5 }}
                    className="fas fa-external-link-alt"
                  />
                </Button>
              ) : (
                <Text color={theme.text}>Subscription not paid</Text>
              )}
            </HStack>
          </VStack>
        </Box>

        <Box
          bg={theme.background}
          borderRadius="md"
          borderWidth={1}
          borderColor={theme.border}
          p={6}
        >
          <VStack spacing={4} align="stretch">
            <Heading
              color={theme.header}
              marginBottom={0}
              marginTop={0}
              size="md"
            >
              Timeline
            </Heading>
            {renderTimelineItem("Created", quote.createdAt, theme)}
            {renderTimelineItem("Sent", quote.sentAt, theme)}
            {renderTimelineItem("Accepted", quote.acceptedAt, theme)}
            {renderTimelineItem("Rejected", quote.rejectedAt, theme)}
          </VStack>
        </Box>

        <Box
          bg={theme.secondaryBackground}
          borderRadius="md"
          borderWidth={1}
          borderColor={theme.border}
          p={6}
        >
          <VStack spacing={4} align="stretch">
            <Heading
              color={theme.header}
              marginTop={0}
              marginBottom={0}
              size="md"
            >
              Additional Information
            </Heading>
            <HStack justifyContent="space-between">
              <Text color={theme.text} fontWeight="bold">
                Partner Email
              </Text>
              <Text color={theme.text}>{quote.partnerEmail || "N/A"}</Text>
            </HStack>
            <HStack justifyContent="space-between">
              <Text color={theme.text} fontWeight="bold">
                Quote PDF
              </Text>

              {quote.quoteFileUrl ? (
                <Link
                  style={{
                    color: theme.text,
                  }}
                  href={quote.quoteFileUrl}
                  isExternal
                  color="blue.500"
                >
                  View Quote
                </Link>
              ) : (
                <Text color={theme.text}>TBD</Text>
              )}
            </HStack>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
}

function renderTimelineItem(
  label: string,
  date: Date | null | undefined,
  theme: ReturnType<typeof useTheme>
) {
  if (!date) return null;

  return (
    <HStack justifyContent="space-between">
      <Text color={theme.text} fontWeight="bold">
        {label}
      </Text>
      <Text color={theme.text}>{moment(date).format("h:mma, MMM Do")}</Text>
    </HStack>
  );
}

export default QuoteDetails;
