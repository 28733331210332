import {
  Box,
  Text,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Heading,
  Alert,
  Image,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { useCallback, useEffect, useContext, useMemo, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps, show } from "redux-modal";
import {
  MutationSubmitFeedbackArgs,
  TransactionTypeOption,
} from "src/api/generated/types";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import { Input } from "src/components/styled/Form/Input";
import { Maybe } from "src/core";
import { useMyToast } from "src/hooks";
import { colors, other } from "src/theme";
import { BaseAssetFields, BaseFullTransactionFields } from "src/api/fragments";
import { useParams } from "react-router-dom";
import { Info, Option, Select } from "src/components/styled";
import _ from "lodash";
import { Warning } from "src/components/Warning";
import SecondaryText from "src/components/styled/SecondaryText";
import { LabelInfo } from "./LabelInfo";
import { ActiveTransactionContext } from "src/context";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useDispatch } from "react-redux";
import { useTheme } from "src/hooks/useTheme";

const DEBOUNCE_MS = 200;

type Props = InjectedProps & {
  asset: Maybe<BaseAssetFields>;
  onCreate?: (x: any) => any;
  labelOptions: TransactionTypeOption[];
  selectedOption: Option | undefined;
  onSelectOption: (o: Maybe<TransactionTypeOption>) => void;
  loading: boolean;
  transaction?: BaseFullTransactionFields;
};

function _LabelsModal({
  handleHide,
  show: isVisible,
  asset,
  onCreate,
  selectedOption,
  labelOptions,
  onSelectOption,
  loading,
  transaction,
}: Props) {
  const isSol = transaction?.provider === "solana";
  const [leaveFeedback] = useMutation(api.contact.feedback);
  const toast = useMyToast();
  const dispatch = useDispatch();
  const theme = useTheme();

  const _reportTxnCannotLabel = async () => {
    const feedback = [
      `Please provide details: \n`,
      `\n\n\n------------------------------\n`,
      `Info (do not edit this info):`,
      `Provider: ${transaction?.provider}`,
      `URL: ${window.location.href}`,
    ].join("\n");

    // const variables: MutationSubmitFeedbackArgs = {
    //   feedback: [
    //     `I cannot label my transaction`,
    //     `Provider: ${transaction?.provider}`,
    //     `URL: ${window.location.href}`,
    //   ].join("\n"),
    // };

    dispatch(
      show("FeedbackModal", {
        startingFeedback: feedback,
      })
    );

    // try {
    //   await leaveFeedback({
    //     variables,
    //   });

    //   toast.show({
    //     message: "Success, we have received your report!",
    //     status: "success",
    //   });
    // } catch (err) {
    //   if (err instanceof ApolloError) {
    //     console.log(err);
    //     toast.show({
    //       message: "There was an error submitting your feedback.",
    //       status: "error",
    //     });
    //   }
    // }
  };

  const categories = useMemo(
    () => (!labelOptions ? [] : _.uniq(labelOptions.map((o) => o.category))),
    [labelOptions]
  );
  const optionsByCategory = useMemo(
    () => _.groupBy(labelOptions, (o: TransactionTypeOption) => o.category),
    [labelOptions]
  );
  const isLarge = useIsLargeScreen();

  return (
    <Modal
      headerProps={{ paddingBottom: 0 }}
      // title={
      //   <>
      //     <Box
      //       style={{
      //         backgroundColor: colors.lightBlue100,
      //         borderRadius: 10,
      //         fontSize: 14,
      //         padding: 15,
      //         color: colors.black,
      //         fontWeight: "normal",
      //         marginBottom: "1rem",
      //         marginTop: "1.5rem",
      //         border: `1px solid ${colors.primary}`,
      //       }}
      //     >
      //       If you cannot select a specific label for this transaction,{" "}
      //       <span
      //         onClick={_reportTxnCannotLabel}
      //         style={{
      //           fontWeight: "bold",
      //           color: colors.primary,
      //           cursor: "pointer",
      //         }}
      //       >
      //         click here
      //       </span>{" "}
      //       and we'll look into it!
      //       <Info
      //         message={
      //           "Awaken's labeling system is strict to make sure we create the correct taxable events for your transactions. This means that sometimes if a transaction looks different from what we expect, the option may be grayed out. Just click this button though, and we'll look into this!"
      //         }
      //       />
      //     </Box>
      //   </>
      // }
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <div
          style={{
            width: "100%",
            textAlign: "center",
            backgroundColor: theme.secondaryBackground,
            color: theme.header,
            padding: "1rem",
            borderRadius: 10,
          }}
        >
          If you want to learn more about the taxes for each of these labels,{" "}
          <a
            style={{
              color: colors.primary,
              textDecoration: "underline",
              fontSize: 14,
            }}
            href="https://www.notion.so/accrue/Label-Taxation-Documentation-9e9203f80cba40e2873c28004073844b?pvs=4"
          >
            view our documentation.
          </a>
        </div>
      }
      minW={isLarge ? "50rem" : undefined}
      w={!isLarge ? "95%" : undefined}
      minH="95vh"
      footerProps={{
        paddingTop: 0,
      }}
      bodyProps={{
        style: {
          padding: isLarge ? "0rem 2rem 1rem 2rem" : "1rem",
        },
      }}
    >
      <Grid
        paddingBottom="0"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(3, 1fr)",
        }}
        marginTop="2rem"
        // alignContent="flex-start"
      >
        {!categories
          ? null
          : categories.map((category) => {
              const options = optionsByCategory[category];
              if (!options || options.length === 0) return null;
              return (
                <GridItem colSpan={1} position="relative" key={category}>
                  <Heading color={theme.header} marginTop="0.5rem" size="sm">
                    {category}
                  </Heading>
                  {options.map((o) => (
                    <LabelInfo
                      o={o}
                      key={o.value}
                      loading={loading}
                      handleHide={handleHide}
                      onSelectOption={onSelectOption}
                      transaction={transaction}
                      isSelected={selectedOption?.value === o.value}
                    />
                  ))}
                </GridItem>
              );
            })}
      </Grid>
    </Modal>
  );
}

export const LabelsModal = connectModal({
  name: "LabelsModal",
})(_LabelsModal);
