import { Box, Image, useMediaQuery } from "@chakra-ui/react";
import { useIsInViewport } from "src/hooks/common/useIsInViewport";
import _ from "lodash";
import { useRef, useState, useEffect, useMemo } from "react";

type TestimonialsScrollingProps = {
  direction?: string; // SCROLL_LEFT, SCROLL_RIGHT (default)
  fourOrMoreImages: string[]; // must have a multiple of 4 images (e.g. 4, 8, 12)
  height?: string;
  useFour?: boolean;
};

type StyleOverride = {
  width: string;
  left?: string;
  right?: string;
};

export const TestimonialsScrolling = ({
  direction,
  fourOrMoreImages,
  height,
  useFour,
}: TestimonialsScrollingProps) => {
  const ref = useRef<any>(null);
  const [offset, setOffset] = useState(0);
  const { isInViewport, ref: isInViewportRef } = useIsInViewport();
  const [fourImages] = useMediaQuery("(min-width: 1500px)");
  const [threeImages] = useMediaQuery("(min-width: 1100px)");
  const [twoImages] = useMediaQuery("(min-width: 800px)");

  useEffect(() => {
    if (isInViewport) {
      const intervalId = setInterval(() => {
        setOffset((prevOffset) => prevOffset - 0.25);
      }, 10);
      return () => clearInterval(intervalId);
    }
  }, [isInViewport]);

  const { doubledImages, images } = useMemo(() => {
    let chunkSize = 1;
    if (twoImages) chunkSize = 2;
    if (threeImages) chunkSize = 3;
    if (fourImages && useFour) chunkSize = 4; // too many images, can't see

    const divisibleImages = fourOrMoreImages.slice(
      0,
      chunkSize * Math.trunc(fourOrMoreImages.length / chunkSize)
    );

    const images = _.chunk(divisibleImages, chunkSize);
    return {
      images,
      doubledImages: [...images, ...images],
    };
  }, [twoImages, fourImages, fourOrMoreImages]);

  const containerWidthPx = ref?.current?.offsetWidth || 0;
  const imagesWidthPx = containerWidthPx * images.length;

  // if (containerWidthPx === 0) return null; <- this breaks everything

  const style: StyleOverride = {
    width: `${doubledImages.length * 100}%`,
  };
  if (direction === "SCROLL_RIGHT") style.left = `${offset % imagesWidthPx}px`;
  else style.right = `${offset % imagesWidthPx}px`;

  return (
    <Box
      w="100%"
      h={height || "13rem"}
      overflow="hidden"
      position="relative"
      ref={ref}
    >
      <Box
        ref={isInViewportRef}
        position="absolute"
        top="0"
        display="flex"
        width="200%"
        height="100%"
        style={style}
      >
        {doubledImages.map((images, i) => (
          <Box key={i} width="100%" display="flex">
            {images.map((image, j) => (
              <Image
                key={j}
                src={image}
                padding="1rem"
                alt={`Image`}
                width={`${100 / images.length}%`}
                height="100%"
                objectFit="contain"
                objectPosition={"center"}
              />
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
