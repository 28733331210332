import { BaseAccountFields, BaseAssetFields } from "src/api/fragments";
import { AssetTypeEnum } from "src/api/generated/types";
import { Maybe } from "src/core";

export const getGenericTokenName = (
  { asset, amount }: { asset: { type: string }; amount: number },
  pluralize: boolean
) => {
  if (!asset) return "";
  const isOne = amount === 1;
  if (asset?.type === AssetTypeEnum.FungibleToken) {
    if (pluralize) return isOne ? "Token" : "Tokens";
    return "Token";
  } else if (asset?.type === AssetTypeEnum.Nft) {
    if (pluralize) return isOne ? "NFT" : "NFTs";
    return "NFT";
  } else {
    return "";
  }
};

export const getBalanceImageInfo = (
  asset: Maybe<BaseAssetFields>,
  account: Maybe<BaseAccountFields>
) => {
  if (!asset) return { imageUrl: "", type: "asset" };
  if (asset.type === AssetTypeEnum.Nft) {
    return { imageUrl: asset.imageUrl, type: "asset" };
  }
  if (asset.type === AssetTypeEnum.FungibleToken)
    return { imageUrl: account?.iconImageUrl || "", type: "account" };
  return { imageUrl: asset.imageUrl, type: "asset" };
};

export const getAssetKeyForUrl = (
  asset: Pick<
    BaseAssetFields,
    "type" | "contractAddress" | "coinGeckoTokenId" | "id"
  >
) => {
  if (!asset) return "";
  if (asset.coinGeckoTokenId) return asset.coinGeckoTokenId;
  if (asset.contractAddress) return asset.contractAddress;
  return asset.id;
};
