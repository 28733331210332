import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  createChart,
  ColorType,
  LineStyle,
  Time,
  Point,
  SingleValueData,
  UTCTimestamp,
  CrosshairMode,
  MouseEventHandler,
  MouseEventParams,
} from "lightweight-charts";
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Switch,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { colors } from "src/theme";
import CountUp from "react-countup";
import { Maybe, hasValue } from "src/core";
import {
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { api } from "src/api";
import {
  AssetTypeEnum,
  ChartPoint,
  ChartPointBreakdown,
  GetPortfolioValueResponse,
  HistoricalBalanceIntervalEnum,
  PartialAsset,
  Query,
} from "src/api/generated/types";
import { DateTime } from "luxon";
import {
  chunk,
  debounce,
  isEmpty,
  isNil,
  keyBy,
  last,
  throttle,
  truncate,
} from "lodash";
import { D, formatNum } from "src/utils/helpers";
import { useClientById, useMe, useMyToast } from "src/hooks";
import * as moment from "moment-timezone";
import { Touchable } from "src/components/Touchable";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AssetIcon } from "src/components/styled/Assets";
import numbro from "numbro";
import { Button, Info } from "src/components";
import { PROVIDER_TO_LOGO_URL } from "src/components/modals/AccountModal/constants";
import qs from "query-string";
import { updateCurrentUser } from "firebase/auth";
import { Transactions } from "./Transactions";
import WhiteBox from "src/components/styled/WhiteBox";
import { DEFAULT_TF, TIME_FRAMES, TimeFrame, getTimeFormat } from "./utils";
import { useInterval } from "src/hooks/common";
import StatusTag from "src/components/styled/StatusTag";
import { negative, positive } from "src/theme/colors";
import { BaseAssetFields } from "src/api/fragments";
import { getAssetLinkFromId } from "src/modules/ledger/transactions";
import Assets from "./Assets/Assets";
import Positions from "./Positions/Positions";
import { PortfolioFeedbackModal } from "src/components/modals/PortfolioFeedbackModal";
import { useDispatch } from "react-redux";
import { compose } from "lodash/fp";
import { show } from "redux-modal";
import { useMediaQuery } from "@chakra-ui/react";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { Warning } from "src/components/Warning";

const CHART_HEIGHT = 325;
const REFETCH_DATA_INTERVAL = 60 * 1000;
const subject = "I'd like to do a feedback call about the portfolio";

type PartialPoint = Pick<ChartPoint, "timestamp" | "value"> & {
  breakdown: PointBreakdownWithAsset[];
};

type PointBreakdownWithAsset = Pick<
  ChartPointBreakdown,
  "assetId" | "value" | "price" | "amount"
> & {
  asset: PartialAsset;
};

type OverallGainLoss = {
  percent: string;
  color: string;
  amount: number;
  formattedAmount: string;
};

export const NetWorth = ({
  clientId,
  showAccountsWarning,
}: {
  clientId: string;
  showAccountsWarning: boolean;
}) => {
  const { client, assets, getAssets } = useClientById(clientId);
  const { me } = useMe();
  const [search] = useSearchParams();
  const tf = search.get("tf") as unknown as Maybe<TimeFrame["type"]>;
  const defaultTf = TIME_FRAMES.find((d) => d.type === tf) || DEFAULT_TF;

  const navigate = useNavigate();
  const [selectedValue, _setSelectedValue] = useState<Maybe<number>>(null);

  const [selectedPoint, _setSelectedPoint] =
    useState<Maybe<SingleValueData>>(null);
  const [timeFrame, setTimeFrame] = useState<Maybe<TimeFrame>>(defaultTf);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const timezone = client?.timezone ?? "UTC";
  const isSuperUser = me?.isSuperuser ?? false;
  const dispatch = useDispatch();

  const {
    data: chartData,
    loading: loadingChart,
    refetch: fetchChart,
    networkStatus,
  } = useQuery<{
    getChart: Query["getChart"];
  }>(api.portfolio.getChart, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: false,
    variables: {
      clientId,
      interval: timeFrame?.type || HistoricalBalanceIntervalEnum.All,
    },
  });

  const { data: portfolioValueData, refetch: fetchPortfolioValue } = useQuery<{
    getPortfolioValue: Query["getPortfolioValue"];
  }>(api.portfolio.getPortfolioValue, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: false,
    variables: {
      clientId,
    },
  });

  const _onAddWallet = () => {
    dispatch(
      show("AccountModal", {
        location: "Portfolio",
      })
    );
  };

  const _setTimeFrame = (t: Maybe<TimeFrame>) => {
    setTimeFrame(t);
    _updateUrl({ tf: t?.type || undefined });
  };

  const assetById = useMemo(() => {
    const assetById = keyBy(assets, (a) => a.id);

    return {
      ...assetById,
    };
  }, [assets]);

  const fullPoints = useMemo(() => {
    const _points = chartData?.getChart?.points || [];
    if (!_points.length) return [];
    const currentValue = portfolioValueData?.getPortfolioValue;
    const currentValuePoint = currentValue
      ? {
          timestamp: currentValue.timestamp,
          value: currentValue.value,
          breakdown: currentValue?.breakdown,
        }
      : null;

    const points = [..._points, currentValuePoint]
      .filter(hasValue)
      .sort(_sortAsc);

    return points;
  }, [chartData?.getChart || "", portfolioValueData?.getPortfolioValue || ""]);

  const fullPointByTime = useMemo(
    () => keyBy(fullPoints, (p) => new Date(p.timestamp).toISOString()),
    [fullPoints]
  );

  const _updateUrl = (params: any) => {
    const newUrl = location.pathname + "?" + qs.stringify(params);

    navigate(newUrl, {
      replace: false, // please don't
    });
  };

  const throttledSelectedValue = useRef(throttle(_setSelectedValue, 50));
  const throttleSetSelectedPoint = useRef(throttle(_setSelectedPoint, 50));

  const points = useMemo<SingleValueData[]>(() => {
    const result = fullPoints.map((p): SingleValueData => {
      const isAfterNow = new Date(p.timestamp).getTime() > Date.now();
      return {
        // convert timestamp to seconds in the specific timezone. the dates are in UTC to start
        time: Math.floor(new Date(p.timestamp).getTime() / 1000) as Time,
        value: (isAfterNow ? undefined : p.value ?? 0) as number,
        // costBasis: !isNil(p.costBasis) ? p.costBasis / 100 : undefined,
      };
    });

    return result;
  }, [fullPoints]);

  const startingValue = useMemo(() => points[0]?.value ?? null, [points]);

  // current selected value is whatever point is selected
  const currentValue = useMemo((): Maybe<any> => {
    const currentValue = portfolioValueData?.getPortfolioValue;
    if (!currentValue) return null;
    return {
      time: Math.floor(
        new Date(currentValue.timestamp).getTime() / 1000
      ) as UTCTimestamp,
      value: currentValue.value,
      breakdown: currentValue.breakdown,
    };
  }, [portfolioValueData?.getPortfolioValue || ""]);

  const fullSelectedDataPoint = useMemo(() => {
    if (!selectedPoint) return null;
    const date = new Date((selectedPoint.time as any) * 1000).toISOString();
    // const backupPoint = fullPoints
    //   .slice()
    //   .reverse()
    //   .find((p) => !!p.value && p.value > 0);

    // get the date indexed in, otherwise return the most recent full point value above 0
    const pointValue = fullPointByTime[date] || currentValue;

    return pointValue;
  }, [fullPointByTime, fullPoints, selectedPoint, currentValue]);

  const lineColor = useMemo(() => {
    const first = points[0];
    const last = points[points.length - 1];
    if (isNil(first) || isNil(last)) return colors.positive;
    return first.value > last.value ? colors.negative : colors.positive;
  }, [points]);

  const overallGainLoss = useMemo((): Maybe<OverallGainLoss> => {
    if (isNil(startingValue) || isNil(selectedValue) || !selectedValue) {
      return null;
    }

    const percent =
      startingValue > 0
        ? (selectedValue - startingValue) / startingValue
        : null;

    return {
      percent:
        percent === null ? "N/A" : Math.abs(percent * 100).toFixed(2) + "%",
      color: isNil(percent) || percent >= 0 ? colors.positive : colors.negative,
      amount: selectedValue - startingValue,
      formattedAmount: D(
        Math.floor(Math.abs(selectedValue - startingValue) * 100),
        "USD"
      ).toFormat(),
    };
  }, [selectedValue, startingValue]);

  const _hardRefresh = async () => {
    try {
      await fetchChart({
        clientId,
        hardRefresh: true,
        interval: timeFrame?.type || HistoricalBalanceIntervalEnum.All,
      });

      await fetchPortfolioValue({
        clientId,
        useCacheIfAvailable: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  // chart + the current value of the portfolio
  useEffect(() => {
    fetchChart({
      clientId,
      interval: timeFrame?.type || HistoricalBalanceIntervalEnum.All,
    }).then(() =>
      fetchPortfolioValue({
        clientId,
      })
    );
  }, [clientId, timeFrame]);

  useEffect(() => {
    throttledSelectedValue.current(currentValue?.value ?? null);
    throttleSetSelectedPoint.current(currentValue ?? null);
  }, [currentValue?.time || ""]);

  useEffect(() => {
    if (!selectedPoint) {
      throttledSelectedValue.current(currentValue?.value ?? null);
      throttleSetSelectedPoint.current(currentValue ?? null);
    }
  }, [selectedPoint?.time || ""]);

  useEffect(() => {
    getAssets();
  }, [clientId]);

  // useInterval(() => {
  //   // note: have to fetch new chart data as well bc otherwise have $0 points below the value
  //   // we need to do this before the most recent point tho so there isn't a weird gap where the line becomes $0
  //   _fetchPortfolioData();
  // }, REFETCH_DATA_INTERVAL);

  const isNeg = !isNil(currentValue) && currentValue.value < 0;
  const color = isNeg ? colors.negative : colors.positive;

  // show at most 6 rows
  const breakdown = useMemo(
    () => fullSelectedDataPoint?.breakdown || [],
    [fullSelectedDataPoint?.breakdown || ""]
  );

  const chartIsLoading = loadingChart && !points.length;
  const showWarning =
    portfolioValueData?.getPortfolioValue?.showWarning ||
    chartData?.getChart?.showWarning ||
    false;

  const isLarge = useIsLargeScreen();

  return (
    <div>
      <PortfolioFeedbackModal />

      {/* {!isLarge && !showAccountsWarning && <PortfolioAccuracySurvey />} */}

      <HStack
        alignItems="flex-start"
        justifyContent="flex-start"
        marginTop="0.5rem"
      >
        <VStack flex={1} alignItems="flex-start" maxW="100vw">
          {/* FIXME: add back when ready <HStack width="100%" alignItems="flex-start">
            <Flex flex={1}>
              <VStack width="100%" alignItems="flex-start">
                <CurrentSelectedValue
                  showWarning={showWarning}
                  selectedValue={selectedValue}
                  overallGainLoss={overallGainLoss}
                />
              </VStack>
            </Flex>
            {isLarge && <PortfolioAccuracySurvey />}
          </HStack> */}

          {/* // Note: keep this here so the rendering is less jolty when the loading stops */}
          {/* FIXME: add back when ready <div
            style={{
              width: "100%",
              height: CHART_HEIGHT,
              flexGrow: 0,
            }}
          >
            <NetworthChart
              timezone={timezone}
              timeFrame={timeFrame}
              onHoverPoint={throttleSetSelectedPoint}
              onHoverValue={throttledSelectedValue}
              points={points}
              isLoading={chartIsLoading}
              currentValue={currentValue}
              lineColor={lineColor}
            />
          </div> 

          <HStack w="100%" alignItems="center">
            <Flex flex={1}>
              {TIME_FRAMES.map((d) => (
                <TimeFrameOption
                  key={d.type}
                  isActive={timeFrame?.type === d.type}
                  timeFrame={d}
                  activeColor={lineColor}
                  setActiveTimeFrame={_setTimeFrame}
                />
              ))}
            </Flex>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip label="Refresh graph">
                <div style={{ marginLeft: "1rem" }}>
                  <Touchable
                    onClick={_hardRefresh}
                    isLoading={networkStatus === NetworkStatus.loading}
                    style={{ background: colors.gray90 }}
                    _hover={{ bg: colors.gray80 + " !important" }}
                    fontSize="sm"
                    iconName="fa-sharp fa-refresh"
                    iconPosition="right"
                  />
                </div>
              </Tooltip>
            </div>
          </HStack>
          */}
          {showAccountsWarning && (
            <Warning
              style={{ marginBottom: "1rem" }}
              message={
                <HStack>
                  <Text flex={1}>
                    Add your first account to see your portfolio!
                  </Text>
                  <Button
                    _hover={{
                      bg: colors.black,
                      color: colors.white,
                      opacity: 0.8,
                    }}
                    onClick={_onAddWallet}
                    bg={colors.black}
                    color={colors.white}
                    size="sm"
                  >
                    Add Account
                  </Button>
                </HStack>
              }
            />
          )}
          <Assets />
        </VStack>

        {isLarge && (
          <Box
            style={{
              width: 300,
              marginLeft: "2rem",
              maxHeight: 425,
              height: "100%",
              flexShrink: 0,
              paddingBottom: "2rem",
            }}
          >
            {/* FIXME: remove when ready */}
            {/* {isLarge && !showAccountsWarning && <PortfolioAccuracySurvey />} */}

            <Transactions />

            {/* FIXME: add back when ready {breakdown ? (
              <AssetBreakdownSection
                assetById={assetById}
                breakdown={breakdown}
              />
            ) : (
              <Transactions />
            )} */}

            <Positions />
          </Box>
        )}
      </HStack>
    </div>
  );
};

const PortfolioAccuracySurvey = () => {
  const toast = useMyToast();
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const [submitFeedback] = useMutation(api.portfolio.submitFeedback);

  const onClickIncorrect = async () => {
    const message = [
      `Portfolio is incorrect ❌\n`,
      `URL: ${window.location.href}`,
    ].join("\n");

    await submitFeedback({
      variables: {
        message: message,
      },
    });

    setHidden(true);
    _showModal("PortfolioFeedbackModal");
  };

  const onClickCorrect = async () => {
    const message = [
      `Portfolio is correct ✅\n`,
      `URL: ${window.location.href}`,
    ].join("\n");

    await submitFeedback({
      variables: {
        message,
      },
    });

    toast.show({
      message: "Thanks for your feedback!",
      status: "success",
    });

    setHidden(true);
  };

  const isLarge = useIsLargeScreen();

  if (hidden) {
    return null;
  }

  return (
    <HStack>
      <Box
        style={{
          padding: "0.5rem 1rem",
          width: isLarge ? "100%" : "100%",
          // backgroundColor: colors.gray100,
          border: `2px dashed ${colors.gray80}`,
          marginBottom: "2rem",
          borderRadius: 8,
        }}
      >
        <Text fontSize="sm" fontWeight="normal">
          Is our portfolio accurate for you?
        </Text>

        <HStack marginTop="0.5rem" justifyContent="flex-end" w="100%">
          <Button
            size="xs"
            style={{
              color: colors.white,
              backgroundColor: colors.red50,
            }}
            onClick={onClickIncorrect}
            _hover={{ opacity: 0.8 }}
          >
            No
          </Button>
          <Button
            style={{
              color: colors.white,
              backgroundColor: colors.green50,
            }}
            onClick={onClickCorrect}
            _hover={{ opacity: 0.8 }}
            size="xs"
          >
            Yes
          </Button>
        </HStack>
      </Box>
    </HStack>
  );
};

const NetworthChart = React.memo(
  ({
    timezone,
    timeFrame,
    lineColor,
    currentValue,
    onHoverValue,
    onHoverPoint,
    points,
    isLoading,
  }: {
    timezone: string;
    timeFrame: Maybe<TimeFrame>;
    lineColor: string;
    currentValue: Maybe<SingleValueData>;
    onHoverPoint: any;
    onHoverValue: any;
    points: SingleValueData[];
    isLoading: boolean;
  }) => {
    const chartContainerRef = useRef(null);
    const { clientId } = useParams<{ clientId: string }>();

    useEffect(() => {
      if (!chartContainerRef.current) return;

      const handleResize = () => {
        chart.timeScale().fitContent();
      };

      const dateTimeFormat = getTimeFormat(timezone, timeFrame);

      const chart = createChart(chartContainerRef.current!, {
        handleScroll: {
          mouseWheel: false,
          pressedMouseMove: false,
          horzTouchDrag: false,
          vertTouchDrag: false,
        },
        handleScale: {
          axisPressedMouseMove: false,
          mouseWheel: false,
          pinch: false,
        },
        layout: {
          background: { type: ColorType.Solid },
          // textColor,
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        crosshair: {
          mode: CrosshairMode.Magnet,
          vertLine: {
            style: LineStyle.Solid,
            labelVisible: true,
            // labelBackgroundColor: "white",
          },
          horzLine: { visible: false },
        },
        rightPriceScale: {
          visible: false,
        },
        timeScale: {
          visible: true,
          borderVisible: false,
          secondsVisible: true,
          timeVisible: !!dateTimeFormat.timeFormat,
          ticksVisible: false,
          tickMarkFormatter: () => "",
        },
        localization: {
          dateFormat: dateTimeFormat.dateFormat ?? undefined,
          timeFormatter: dateTimeFormat.timeFormat ?? undefined,
        },
        height: CHART_HEIGHT,
        autoSize: true,
      });

      const lineSeries = chart.addLineSeries({
        color: lineColor,
        lineWidth: 2,
        lineStyle: LineStyle.Solid,
        // priceLineColor: "blue",
        priceLineVisible: false,
      });

      // const costBasisLine = chart.addLineSeries({
      //   color: colors.yellow50,
      //   lineWidth: 2,
      //   lineStyle: LineStyle.Dashed,
      //   priceLineVisible: false,
      // });

      const clickEventHandler = (param: MouseEventParams) => {
        if (!param.point) {
          return;
        }

        // if shift is held down -> open up at this point in a new URL.
        // this is super useful for us to debug things
        if (param.sourceEvent?.shiftKey) {
          const data = param.seriesData.get(lineSeries);

          if (data) {
            const timestamp = new Date((data.time as any) * 1000).toISOString();

            // oen up that url with ascending date from this point sorted by the created at
            const url =
              "/clients/" +
              clientId +
              "/transactions?ascending=true&limit=20&page=0&sortBy=createdAt&startDate=" +
              timestamp;
            window.open(url, "_blank");
            return;
          }
        }

        const data = param.seriesData.get(lineSeries);

        if (data) {
          const value = data as SingleValueData;
          // onHover.current(true);
          onHoverPoint.current(value ?? currentValue ?? null);
          onHoverValue.current(value?.value ?? currentValue?.value ?? null);

          return;
        }
      };

      const subscribeCrosshairMove = throttle((param: MouseEventParams) => {
        if (!param.point) {
          // onHover.current(false);
          onHoverPoint.current(currentValue);
          onHoverValue.current(currentValue?.value ?? null);
          return;
        }
        const data = param.seriesData.get(lineSeries);
        const value = data as SingleValueData;
        // onHover.current(true);
        onHoverPoint.current(value ?? currentValue ?? null);
        onHoverValue.current(value?.value ?? currentValue?.value ?? null);
      }, 150);

      chart.subscribeClick(clickEventHandler);
      chart.subscribeCrosshairMove(subscribeCrosshairMove);
      lineSeries.setData(points);

      if (
        points.length &&
        timeFrame?.type === HistoricalBalanceIntervalEnum.Day
      ) {
        lineSeries.setMarkers([
          {
            shape: "circle",
            time: points[points.length - 1].time,
            position: "inBar",
            size: 1.5,
            id: "last-point",
            color: lineColor,
          },
        ]); // Set color for the most recent point
      }

      chart.timeScale().fitContent();

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        chart.unsubscribeClick(clickEventHandler);
        chart.unsubscribeCrosshairMove(subscribeCrosshairMove);
        chart.remove();
      };
    }, [points, timezone, timeFrame, currentValue, lineColor, clientId]);

    // console.log("re-render chart");

    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Spinner size="md" />
        </div>
      );
    }

    return (
      <div
        style={{ width: "100%", height: "100%", position: "relative" }}
        ref={chartContainerRef}
      />
    );
  },
  (prev, next) => {
    return (
      prev.lineColor === next.lineColor &&
      prev.isLoading === next.isLoading &&
      JSON.stringify(prev.points) === JSON.stringify(next.points) &&
      JSON.stringify(prev.timeFrame) === JSON.stringify(next.timeFrame) &&
      JSON.stringify(prev.currentValue) === JSON.stringify(next.currentValue)
    );
  }
);

const AssetBreakdownSection = ({
  breakdown,
  assetById,
}: {
  breakdown: ChartPointBreakdown[];
  assetById: Record<string, BaseAssetFields>;
}) => {
  return (
    <>
      {/* <HStack flex={1} marginBottom="1.25rem">
        <Text fontSize={18} fontWeight="semibold" marginRight="5px">
          Assets
        </Text>
      </HStack> */}
      <div
        style={{
          border: `1px solid ${colors.gray85}`,
          borderRadius: 7,
          overflow: "hidden",
        }}
      >
        <WhiteBox
          style={{
            boxShadow: "none", // other.lighterBoxShadow,
          }}
          padding="0"
          marginTop="0"
          h="100%"
          minW="none"
        >
          {breakdown.length === 0 ? (
            <div
              style={{
                padding: "2rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "500",
              }}
            >
              Hover over graph
            </div>
          ) : (
            <Grid
              display="inline"
              w="100%"
              templateColumns={{ base: "repeat(2, 1fr)" }}
            >
              {breakdown.slice(0, 6).map((b) => (
                <GridItem colSpan={1} key={b.assetId}>
                  <AssetBreakdownInfo
                    breakdown={b}
                    assetById={assetById}
                    key={b.assetId}
                  />
                </GridItem>
              ))}
            </Grid>
          )}
        </WhiteBox>
      </div>
    </>
  );
};

const CurrentSelectedValue = ({
  selectedValue,
  overallGainLoss,
  showWarning,
}: {
  selectedValue: Maybe<number>;
  overallGainLoss: Maybe<OverallGainLoss>;
  showWarning: boolean;
}) => {
  let Warning = null;
  if (showWarning) {
    Warning = (
      <Box
        bg={colors.yellow100}
        border={`1px solid ${colors.yellow60}`}
        padding="0.25rem 0.5rem"
        borderRadius={5}
        fontWeight="semibold"
      >
        <Text fontSize="sm" color={colors.yellow10}>
          This portfolio value looks wrong, we'll look into it{" "}
          <i className="fa-sharp fa-exclamation-triangle" />
        </Text>
      </Box>
    );
  }

  return (
    <>
      {Warning}
      <HStack alignItems="flex-start">
        <Box flex={1}>
          <Text
            style={{
              fontFamily:
                "Capsule Sans Display, system-ui, -apple-system, system-ui, Segoe UI, Helvetica, Arial, sans-serif",
            }}
            fontSize={28}
            color={colors.black}
            fontWeight="600"
          >
            $
            {!isNil(selectedValue) ? (
              <CountUp
                decimalPlaces={2}
                decimals={2}
                duration={0.25}
                preserveValue
                end={selectedValue}
              />
            ) : (
              "—"
            )}
          </Text>
          <HStack alignItems="center">
            <i
              className={
                !overallGainLoss
                  ? "fa-sharp fa-none"
                  : overallGainLoss?.amount < 0
                  ? "fa-sharp fa-caret-down"
                  : "fa-sharp fa-caret-up"
              }
              style={{
                fontSize: 14,
                position: "relative",
                // top: 1,
                color: overallGainLoss?.color || colors.black,
              }}
            />
            <span
              style={{
                marginLeft: 5,
                color: overallGainLoss?.color || colors.black,
                fontWeight: "700",
              }}
            >
              {overallGainLoss ? (
                <>
                  {overallGainLoss.formattedAmount} ({overallGainLoss.percent})
                </>
              ) : (
                "—"
              )}
            </span>
          </HStack>
        </Box>
      </HStack>
    </>
  );
};

const _AssetBreakdownInfo = ({
  breakdown: b,
  assetById,
}: {
  breakdown: ChartPointBreakdown;
  assetById: Record<string, BaseAssetFields>;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const asset = assetById[b.assetId];
  const provider = asset?.provider;

  if (!b.amount || !asset) {
    return null;
  }

  const isNFT = asset?.type === AssetTypeEnum.Nft;
  const assetName = isNFT
    ? asset?.name || asset?.symbol || "-"
    : asset?.symbol?.toUpperCase() || asset.name || "-";

  const assetLink = getAssetLinkFromId(clientId || "", asset.id);

  return (
    <div
      style={{
        verticalAlign: "top",
        padding: "5px 10px",
        flexShrink: 0,
      }}
    >
      <HStack style={{ position: "relative" }}>
        <AssetIcon
          textStyle={{ fontSize: 8 }}
          style={{ marginRight: "0.5rem" }}
          size={28}
          asset={asset}
        />

        {provider && PROVIDER_TO_LOGO_URL[provider || ""] ? (
          <Image
            position="absolute"
            w="1rem"
            h="1rem"
            borderRadius="100%"
            src={PROVIDER_TO_LOGO_URL[provider || ""] || ""}
            marginLeft="1rem !important"
            marginTop="1rem !important"
            border={"1px solid " + colors.gray80}
            bg={colors.white}
          />
        ) : null}

        <VStack alignItems="flex-start">
          <Text isTruncated fontSize="sm" fontWeight="500">
            {D(b.value || 0).toFormat()}{" "}
            <i
              // open font awesome icon
              className="fa-sharp fa-arrow-up-right-from-square"
              style={{
                marginLeft: 2,
                fontSize: 10,
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                window.open(assetLink, "_blank");
              }}
            />
            {/* <Text
              fontSize="xs"
              color={colors.gray30}
              style={{ display: "inline-block" }}
            >
              ({numbro(b.amount).format("0,0.[000]")})
            </Text> */}
            {/* {truncate(assetName || "", {
              length: 6,
              separator: "..",
            })} */}
          </Text>
          <Text
            isTruncated
            marginTop="0 !important"
            fontSize="xs"
            fontWeight="500"
          >
            {truncate(assetName || "", { length: 20 })} |{" "}
            {formatNum(b.amount, false, "0.[0000]")?.toLowerCase()}
            {/*  x{" "}
            {D(Math.floor(b.price || 0)).toFormat()} */}
          </Text>
        </VStack>
      </HStack>
    </div>
  );
};

const AssetBreakdownInfo = React.memo(_AssetBreakdownInfo);

const _TimeFrameOption = ({
  isActive,
  timeFrame,
  setActiveTimeFrame,
  activeColor,
}: {
  isActive?: boolean;
  timeFrame: TimeFrame;
  activeColor: string;
  setActiveTimeFrame: (timeFrame: TimeFrame) => void;
}) => {
  return (
    <div
      style={{
        backgroundColor: isActive ? activeColor : colors.gray90,
        borderRadius: 8,
        marginRight: 5,
        padding: "0.2rem 0.5rem",
        fontSize: 10,
        fontWeight: "bold",
        color: isActive ? "white" : colors.gray30,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveTimeFrame(timeFrame);
      }}
    >
      {timeFrame.label}
    </div>
  );
};

const TimeFrameOption = React.memo(_TimeFrameOption);

const _sortAsc = (
  a: Pick<ChartPoint, "timestamp">,
  b: Pick<ChartPoint, "timestamp">
) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();

export default NetWorth;
