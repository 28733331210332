import { useLazyQuery } from "@apollo/client";
import {
  Text,
  Divider,
  Heading,
  Box,
  Grid,
  GridItem,
  HStack,
  Center,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  toast,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "src/api";
import {
  GetNumTxnTypesResponse,
  Transaction as TransactionType,
} from "src/api/generated/types";
import WhiteBox from "src/components/styled/WhiteBox";
import { colors, other } from "src/theme";
import { isMobile } from "react-device-detect";
import { Maybe } from "src/core";
import { BaseSimpleTransactionFields } from "src/api/fragments";
import { useClientById } from "src/hooks";
import { Transaction } from "./Transaction";
import { Touchable } from "src/components/Touchable";

const LIMIT = 5;

export const Transactions = () => {
  const { clientId } = useParams();
  const [page, setPage] = useState(0);

  const {
    client,
    transactions,
    totalTransactions,
    getTransactions,
    isLoadingTransactions,
  } = useClientById(clientId, {
    txnFetchPolicy: "cache-and-network",
  });

  const _setPage = (newPage: number) => {
    if (newPage < 0) {
      setPage(0);
    } else {
      setPage(newPage);
    }
  };

  useEffect(() => {
    getTransactions({ page, limit: LIMIT });
  }, [page]);

  const canSelectPrev = page > 0;
  const totalPages = useMemo(
    () => Math.ceil(totalTransactions / LIMIT),
    [totalTransactions]
  );

  if (totalTransactions === 0) {
    return null;
  }

  return (
    <div style={{ width: "100%", flexShrink: 0, marginBottom: "1.5rem" }}>
      <HStack alignItems="center" marginBottom="1.25rem">
        <HStack flex={1}>
          <Text fontSize={18} fontWeight="semibold" marginRight="5px">
            Transactions
          </Text>
          <Text
            fontWeight="500"
            marginTop="0 !important"
            fontSize="xs"
            color={colors.gray30}
          >
            Page {page + 1} of {totalPages}
          </Text>
        </HStack>
        {/* left arrow font awesome */}
        <Touchable
          style={{ marginRight: 0 }}
          iconName="fa-sharp fa-chevron-left"
          onClick={() => _setPage(page - 1)}
          //   disable={canSelectPrev}
        />
        <Touchable
          style={{ marginLeft: 0 }}
          iconName="fa-sharp fa-chevron-right"
          onClick={() => _setPage(page + 1)}
        />
      </HStack>

      <div
        style={{
          border: `1px solid ${colors.gray85}`,
          borderRadius: 7,
        }}
      >
        <WhiteBox
          style={{
            boxShadow: "none", // other.lighterBoxShadow,
          }}
          overflow="hidden"
          padding="0"
          marginTop="0"
          width="100%"
          h="100%"
        >
          {transactions.map((t, i) => (
            <Transaction
              timezone={client?.timezone || "UTC"}
              transaction={t}
              key={t.id}
              limitedView
              isLast={i + 1 === LIMIT}
            />
          ))}
        </WhiteBox>
      </div>

      {/* <Text
       
        fontSize="sm"
        style={{ marginTop: "1rem" }}
      >
        See a problem?{" "}
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: colors.primary,
          }}
        >
          Leave feedback
        </span>
      </Text> */}
    </div>
  );
};
