import { HStack, Tooltip } from "@chakra-ui/react";
import { BaseSimpleTransactionFields } from "src/api/fragments";
import {
  CurrencyCodeEnum,
  IsDirtyEnum,
  LedgerTransactionReviewStatusEnum,
} from "src/api/generated/types";
import { StatusTagType } from "src/components/styled/StatusTag";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import { colors } from "src/theme";
import BigNumber from "bignumber.js";
import { formatNum, getCurrencySymbol } from "src/utils/helpers";
import { Maybe } from "src/core";
import { AwakenTooltip } from "../AwakenTooltip";
import { useTheme } from "src/hooks/useTheme";

type PriorityInfo = {
  iconName: string;
  type: StatusTagType;
  message: string;
  infoMessage: string;
};

const INFO_FOR_PRIORITY: Record<number, PriorityInfo> = {
  3: {
    iconName: "fa-sharp fa-sack-dollar",
    type: "error",
    message: "Large",
    infoMessage: "You earned or lost a lot of money in this transaction.",
  },
  2: {
    iconName: "fa-sharp fa-coins",
    type: "warning",
    message: "Medium",
    infoMessage:
      "You earned or lost a decent amount of money in this transaction.",
  },
  1: {
    iconName: "fa-sharp fa-coin",
    type: "brown",
    message: "Small",
    infoMessage:
      "You earned or lost a small amount of money in this transaction.",
  },
  0: {
    iconName: "fa-sharp fa-question",
    type: "info",
    message: "Unknown",
    infoMessage:
      "Awaken is unsure how much money or earned or lost in this transaction.",
  },
};

export const CapGainsTag = ({
  capPriority,
  capGainsSum,
  currency,
  isDirty,
}: {
  capPriority: Maybe<number>;
  capGainsSum: any;
  currency: CurrencyCodeEnum;
  isDirty?: Maybe<IsDirtyEnum>;
}): JSX.Element | null => {
  const info = INFO_FOR_PRIORITY[capPriority ?? 0];
  const symbol = getCurrencySymbol(currency);
  const num = new BigNumber(capGainsSum ?? 0);
  const { text } = useTheme();

  if (num.isNaN() || num.eq(0)) {
    return (
      <HStack>
        <Tooltip label="No gain/loss.">
          <span style={{ color: text }}>—</span>
        </Tooltip>
      </HStack>
    );
  }

  // cap gains sum if we have one
  const dollars = Number(capGainsSum) / 100;
  const isNeg = dollars < 0;
  const cashAmount = formatNum(Math.abs(dollars), true);
  const color = isNeg ? colors.negative : colors.positive;
  const uptodate = !isDirty || isDirty === IsDirtyEnum.Clean;

  if (new BigNumber(dollars).dp(2).isZero()) {
    return (
      <HStack>
        <Tooltip label="No gain/loss.">
          <span style={{ color: text }}>—</span>
        </Tooltip>
      </HStack>
    );
  }

  return (
    <AwakenTooltip
      message={
        uptodate
          ? ""
          : "This transaction's gains/losses is outdated. Please run 'Recalculate' to see the up-to-date version"
      }
    >
      <HStack opacity={uptodate ? 1 : 0.5}>
        <span
          style={{
            color,
            fontWeight: "500",
            textDecoration: uptodate ? undefined : "line-through",
          }}
        >
          {isNeg ? "-" : ""}
          {symbol}
          {cashAmount}
        </span>{" "}
        <i
          className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
          style={{
            fontSize: 15,
            color,
          }}
        />
      </HStack>
    </AwakenTooltip>
  );

  // if (info) {
  //   return (
  //     <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
  //       <Tooltip
  //         zIndex={1401}
  //         trigger={isMobile ? "click" : "hover"}
  //         bg={colors.black}
  //         placement="top"
  //         borderRadius="0.25rem"
  //         label={info.infoMessage}
  //         padding={{ base: "0.5rem 1rem" }}
  //       >
  //         <HStack>
  //           <i className={info.iconName} style={{ fontSize: "11px" }}></i>
  //           <span>{info.message}</span>
  //           {/* <StatusTag <- too colorful
  //         iconName={info.iconName}
  //         type={info.type}
  //         label={info.message}
  //         infoMessage={info.infoMessage}
  //       ></StatusTag> */}
  //         </HStack>
  //       </Tooltip>
  //     </motion.div>
  //   );
  // }
};
