import { BaseAccountFields } from "src/api/fragments";
import { ImportTypeEnum } from "src/api/generated/types";

export const ACCOUNT_FIELDS = [
  "id",
  "numberOfTransactions",
  "status",
  "startDate",
  "endDate",
  "integrationStatus",
  "description",
  "name",
  "walletAddress",
] as (keyof BaseAccountFields)[];

export const getShouldReconnectCoinbase = (
  account: Pick<BaseAccountFields, "provider" | "importType">
) =>
  account.provider === "coinbase" &&
  account.importType === ImportTypeEnum.Hatchfi;

export const getShouldReconnectKraken = (
  account: Pick<BaseAccountFields, "provider" | "importType">
) =>
  account.provider === "kraken" &&
  account.importType === ImportTypeEnum.Hatchfi;

export const getShouldReconnectGemini = (
  account: Pick<BaseAccountFields, "provider" | "importType">
) =>
  account.provider === "gemini" &&
  account.importType === ImportTypeEnum.Hatchfi;

export const shouldReconnect = (
  account: Pick<BaseAccountFields, "provider" | "importType">
) =>
  getShouldReconnectCoinbase(account) ||
  getShouldReconnectKraken(account) ||
  getShouldReconnectGemini(account);
