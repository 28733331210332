import { Button, Container, Divider, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { useNavigate, useParams } from "react-router-dom";
import { Textarea } from "src/components/styled";
import {
  Mutation,
  MutationRequestBookkeepingQuoteArgs,
} from "src/api/generated/types";

type Props = InjectedProps & {
  startingFeedback?: string;
};

function _VIPQuoteRequestModal({
  handleHide,
  show: isVisible,
  startingFeedback,
}: Props) {
  const [requestBookkeepingQuote, { loading }] = useMutation<
    Pick<Mutation, "requestBookkeepingQuote">
  >(api.bookkeepingQuotes.request);
  const toast = useMyToast();
  const theme = useTheme();
  const navigate = useNavigate();

  const { clientId } = useParams();
  const [description, setDescription] = useState("");
  const [taxYearsDescription, setTaxYearsDescription] = useState("");

  const _onSubmit = async function () {
    try {
      const variables: MutationRequestBookkeepingQuoteArgs = {
        clientId: clientId || "",
        taxYearsDescription: taxYearsDescription || "",
        description: description || "",
      };

      const response = await requestBookkeepingQuote({
        variables,
        refetchQueries: [api.bookkeepingQuotes.listForClient],
      });

      const quoteId = response.data?.requestBookkeepingQuote?.id;

      toast.show({
        message:
          "Thank you for your request! You'll receive an email shortly with next steps.",
        status: "success",
      });

      handleHide();

      navigate(`/clients/${clientId}/quotes/${quoteId}`);
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title={
        <>
          VIP Service{" "}
          <i className="fas fa-whale" style={{ color: theme.header }} />
        </>
      }
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          isLoading={loading}
          onClick={_onSubmit}
        >
          Request Quote
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text fontWeight="normal" fontSize="md" color={theme.text}>
          It may take <b>3-7 days</b> to receive a quote. We'll email you when
          we have your quote prepared.
          <br />
          <br />
          Pricing starts at $1,000/year. Before you submit, please add as many
          wallets/exchanges as you can to your account.
        </Text>

        <br />

        <Divider
          style={{
            borderColor: theme.border,
          }}
        />

        <br />

        <Textarea
          label="Tell us about your crypto activity."
          subtitle="Ex. what are your top chains/exchanges, around how many transactions do you have, etc..."
          placeholder=""
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          marginBottom="1rem"
          textareaProps={{
            resize: "vertical",
          }}
        />

        <Textarea
          label="What tax years do you need help with?"
          subtitle="Ex. 2023, 2024, etc..."
          placeholder=""
          value={taxYearsDescription}
          onChange={(e) => {
            setTaxYearsDescription(e.target.value);
          }}
          textareaProps={{
            resize: "vertical",
          }}
        />

        <br />

        <div
          style={{
            padding: 10,
            borderRadius: 15,
            backgroundColor: theme.blueBg,
          }}
        >
          <Text fontSize="sm" color={theme.header}>
            When you submit this form, your account will grant read-only access
            to our VIP team for review. They will reach out to you with a quote
            and next steps.
          </Text>
        </div>

        <br />
      </Container>
    </Modal>
  );
}

export const VIPQuoteRequestModal = connectModal({
  name: "VIPQuoteRequestModal",
})(_VIPQuoteRequestModal);
