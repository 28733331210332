import { BoxProps, Text, Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

type Props = BoxProps & {
  text: string | JSX.Element;
};

function SecondaryText({ text, ...props }: Props) {
  const { text: textColor } = useTheme();

  return (
    <Text
      color={textColor}
      fontSize="sm"
      margin="0"
      padding="0.2rem 0"
      {...props}
    >
      {text}
    </Text>
  );
}

export default SecondaryText;
