import Pusher from "pusher-js";
import { config } from "src/config";

export const pusher = new Pusher(config.pusher.key, {
  cluster: config.pusher.cluster,
  // userAuthentication: {
  //   transport: "ajax",
  //   endpoint: `${config.apiUrl}/pusher/auth`,
  // },
});
