import { Alert, Box, BoxProps, HStack, Text } from "@chakra-ui/react";
import React, { CSSProperties, useState } from "react";
import { colors } from "src/theme";
import { Touchable } from "../Touchable";
import { WarningService } from "./warningService";
import { useTheme } from "src/hooks/useTheme";

export function Warning({
  message,
  hideKey,
  iconName,
  iconStyle,
  setClickedHide,
  ...boxProps
}: BoxProps & {
  message: string | JSX.Element;
  hideKey?: string;
  iconName?: string;
  iconStyle?: CSSProperties;
  setClickedHide?: (arg0: boolean) => void;
}) {
  const theme = useTheme();
  const value = WarningService.getValue(hideKey);
  const [isHidden, setHidden] = useState(
    WarningService.getIsHidden(value, hideKey)
  );

  const _onHide = () => {
    if (hideKey) {
      localStorage.setItem(hideKey, "hidden");
      if (setClickedHide) setClickedHide(true);
      setHidden(true);
    }
  };

  if (isHidden) {
    return null;
  }

  return (
    <Alert
      status="warning"
      borderRadius="5px"
      bg={theme.theme === "light" ? colors.yellow100 : colors.yellow05}
      border={`1px solid ${
        theme.theme === "light" ? colors.yellow70 : colors.yellow50
      }`}
      color={theme.header}
      {...boxProps}
    >
      <HStack w="100%" textAlign="left">
        <i
          className={iconName || "fa-sharp fa-exclamation-triangle"}
          style={{
            marginRight: 15,
            color: colors.yellow50,
            fontSize: 18,
            ...iconStyle,
          }}
        />
        <Text flex={1} fontSize="sm">
          {message}
        </Text>
        {hideKey && (
          <Touchable onClick={_onHide} iconName="fa-sharp fa-times" />
        )}
      </HStack>
    </Alert>
  );
}
