import {
  Box,
  FormLabel,
  Textarea as ChakraTextarea,
  TextareaProps,
  Text,
} from "@chakra-ui/react";
import React, { CSSProperties } from "react";
import { colors } from "src/theme";
import { Controller, Control } from "react-hook-form";
import { isNil } from "lodash";
import { Info } from "../Info";
import { Required } from "./Required";
import { omit } from "lodash/fp";
import { Maybe } from "src/core";
import { useTheme } from "src/hooks/useTheme";

type MyTextAreaProps = {
  label?: string;
  subtitle?: string;
  name?: string;
  hidden?: boolean;
  infoMessage?: string;
  visible?: boolean;
  control?: Control<any, any>;
  containerStyle?: CSSProperties;
  width?: string;
  ref?: React.LegacyRef<HTMLInputElement> | undefined;
  value?: Maybe<string>;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  textareaProps?: TextareaProps;
} & Omit<TextareaProps, "name" | "value">;

export const Textarea = ({
  containerStyle,
  infoMessage,
  textareaProps,
  onChange,
  ...props
}: MyTextAreaProps) => {
  const theme = useTheme();

  if (props.hidden) {
    return null;
  }

  if (!isNil(props.visible) && !props.visible) {
    return null;
  }

  return (
    <Box style={{ marginBottom: "1rem", ...containerStyle }}>
      {props.label && (
        <FormLabel marginBottom="6px" color={theme.header} fontSize={16}>
          {props.label} {props.isRequired && <Required />}
          {infoMessage && <Info message={infoMessage} />}
        </FormLabel>
      )}

      {props.subtitle && (
        <Text color={theme.text} fontSize={14} marginBottom="1rem">
          {props.subtitle}
        </Text>
      )}

      {props.control && props.name ? (
        <Controller
          control={props.control}
          name={props.name}
          render={({ field: { value, onChange, onBlur, ref } }) => (
            <ChakraTextarea
              flex={1}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              bg={theme.background}
              color={theme.header}
              focusBorderColor={colors.primary}
              placeholder={props.placeholder}
              {...textareaProps}
            />
          )}
        />
      ) : (
        <ChakraTextarea
          flex={1}
          value={props.value || ""}
          onChange={onChange}
          focusBorderColor={colors.primary}
          bg={theme.background}
          color={theme.header}
          placeholder={props.placeholder}
          {...textareaProps}
          style={{
            width: props.width || "100%",
          }}
        />
      )}
    </Box>
  );
};
