import { useQuery } from "@apollo/client";
import { Box, Divider, Flex, Heading, HStack, Text } from "@chakra-ui/react";
import { isNil } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { BaseBookkeepingQuoteFields } from "src/api/fragments";
import { BookkeepingQuoteStatusEnum, Query } from "src/api/generated/types";
import { Button } from "src/components";
import StatusTag from "src/components/styled/StatusTag";
import { Touchable } from "src/components/Touchable";
import { useTheme } from "src/hooks/useTheme";
import { D } from "src/utils/helpers";
import { QuoteStatus } from "../Bookkeeping";

function QuoteRequests() {
  const theme = useTheme();

  const { data } = useQuery<Pick<Query, "getPartnerBookkeepingQuotes">>(
    api.bookkeepingQuotes.listForPartner
  );

  const quotes = data?.getPartnerBookkeepingQuotes ?? [];

  return (
    <Box
      maxWidth={"inherit"}
      bg={theme.background}
      // style={{
      //   padding: "1rem 2.5rem",
      // }}
    >
      <Box w="100%" paddingTop="1rem">
        {/* <FreeCreditBadge /> */}

        <HStack alignItems="center" padding="0.5rem 0">
          <Flex flex={1} alignItems="center" display="flex" flexDir="row">
            <Heading color={theme.header} margin="0" size="lg">
              Quote Requests
            </Heading>
          </Flex>
        </HStack>

        <div style={{ marginTop: 25 }}>
          {/* if none, say none */}

          {quotes.length === 0 && (
            <Text color={theme.text} fontSize="md">
              No quote requests
            </Text>
          )}

          {quotes.map((quote: BaseBookkeepingQuoteFields) => (
            <QuoteRow key={quote.id} quote={quote} />
          ))}
        </div>
      </Box>
    </Box>
  );
}

const QuoteRow = ({ quote }: { quote: BaseBookkeepingQuoteFields }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <Link to={`/clients/${quote.clientId}/quotes/${quote.id}`}>
      <HStack
        style={{
          cursor: "pointer",
          width: "100%",
          background: theme.background,
          padding: 15,
          marginBottom: "1rem",
          borderRadius: 15,
          border: "1px solid " + theme.border,
        }}
      >
        <Box style={{ flex: 1 }}>
          <Text color={theme.header} fontSize="md">
            Bookkeeping Quote for {quote.clientName}
          </Text>

          <Text style={{ marginTop: 10 }} color={theme.text} fontSize="sm">
            Email: {quote.clientEmail}
          </Text>
          <Text color={theme.text} fontSize="sm">
            Overview: {quote.description}
          </Text>
          <Text color={theme.text} fontSize="sm">
            Tax years: {quote.taxYearsDescription}
          </Text>
        </Box>

        {/* <Box style={{ width: 200, textAlign: "center" }}>
          <Text color={theme.text} fontSize="sm">
            {isNil(quote.monthlyPriceCents)
              ? "TBD"
              : D(quote.monthlyPriceCents).toFormat()}
            /mo
          </Text>
          <Text color={theme.text} fontSize="sm">
            -or-
          </Text>
          <Text color={theme.text} fontSize="sm">
            {isNil(quote.upfrontPriceCents)
              ? "TBD"
              : D(quote.upfrontPriceCents).toFormat()}{" "}
            upfront
          </Text>
        </Box> */}
        <Box style={{ width: 200 }}>
          <QuoteStatus status={quote.status} />
        </Box>
        <Box
          style={{
            alignItems: "flex-end",
          }}
        >
          <Touchable
            style={{
              color: theme.header,
            }}
          >
            View Quote{" "}
            <i style={{ marginLeft: 5 }} className="fas fa-chevron-right" />
          </Touchable>
        </Box>
      </HStack>
    </Link>
  );
};

export default QuoteRequests;
