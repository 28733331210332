import React, { ReactNode } from "react";
import { colors, other } from "src/theme";
import { Box, BoxProps } from "@chakra-ui/react";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";

export const SIDEBAR_PADDING = 1.5;

type Props = BoxProps & {
  children?: ReactNode;
  noPadding?: boolean;
  isgray?: boolean;
};

function WhiteBox(props: Props) {
  const isLarge = useIsLargeScreen();
  const { background, border } = useTheme();

  return (
    <Box
      minW="18rem"
      bgColor={props.bgColor || background}
      borderRadius={other.borderRadius}
      border={props.border || `1px solid ${border}`}
      padding={props.padding || (isLarge ? SIDEBAR_PADDING : "1") + "rem"}
      marginTop="1.3rem"
      boxShadow={other.lighterBoxShadow}
      {...props}
    >
      {props.children}
    </Box>
  );
}

export default WhiteBox;
