import { useMutation } from "@apollo/client";
import { Button, Container, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import {
  Mutation,
  MutationPayWithCryptoLinkArgs,
} from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { Info, Select } from "src/components/styled";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { THRESHOLDS } from "../CheckoutModal/constants";

type Props = InjectedProps & {
  //
};

const OPTIONS = THRESHOLDS.map((threshold) => ({
  label: threshold.labelName,
  value: threshold.max,
  price: threshold.price,
}));

function _PayWithCryptoModal({ handleHide, show: isVisible }: Props) {
  const { clientId } = useParams();

  const [payWithCryptoLink, { loading }] = useMutation<
    Pick<Mutation, "payWithCryptoLink">
  >(api.checkouts.payWithCryptoLink);

  const toast = useMyToast();
  const theme = useTheme();
  const navigate = useNavigate();

  const [checkoutLink, setCheckoutLink] = useState<string | null>(null);

  // max ceiling
  const [maxCeiling, setMaxCeiling] = useState(1_500);

  const selectedThreshold = OPTIONS.find(
    (threshold) => threshold.value === maxCeiling
  );

  const _onSubmit = async function () {
    if (!clientId || !maxCeiling) {
      return;
    }

    try {
      const variables: MutationPayWithCryptoLinkArgs = {
        clientId: clientId,
        maxTransactionsCeiling: maxCeiling,
      };

      const response = await payWithCryptoLink({
        variables: variables,
      });

      const url = response.data?.payWithCryptoLink;

      setCheckoutLink(url || "");

      toast.show({
        message: "Checkout link created! Click it to securely pay with USDC.",
        status: "success",
      });
    } catch (e) {
      console.error(e);

      toast.show({
        message: "Error updating quote to client.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title={<>Payment with Crypto</>}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        checkoutLink ? (
          <div />
        ) : (
          <Button
            width="100%"
            variant="primary"
            isLoading={loading}
            marginBottom="1rem"
            onClick={_onSubmit}
          >
            Pay with Crypto
          </Button>
        )
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text fontSize="md" color={theme.text}>
          This will generate a link with Stripe, which will allow you to pay for
          your tax plan with USDC.
        </Text>

        <br />

        {/* number inputs for monthly + upfront prices */}
        {!checkoutLink ? (
          <>
            <Select
              label="Max Transaction Ceiling"
              options={OPTIONS}
              value={selectedThreshold as any}
              selectProps={{
                onChange: (option: any) => {
                  if (!option) return;
                  setMaxCeiling(option?.value ?? 0);
                },
              }}
            />

            <Text fontSize="md" color={theme.text}>
              This plan costs ${selectedThreshold?.price}.{" "}
              <Info message="If you have credit, this will automatically be applied at checkout to give you a discount" />
            </Text>
          </>
        ) : (
          <a href={checkoutLink} rel="noopener noreferrer">
            <div
              style={{
                cursor: "pointer",
              }}
            >
              {/* make a UI for a user's referral link they can copy */}
              <div
                style={{
                  padding: "10px 15px",
                  backgroundColor: theme.secondaryBackground,
                  borderRadius: 50,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                {/* <div>
              <i className="fa-sharp fa-link" style={{ marginRight: 10 }} />
            </div> */}
                <Text
                  fontSize="md"
                  style={{
                    flex: 1,
                    color: colors.primary,
                    textDecoration: "underline",
                    overflow: "hidden",
                  }}
                  noOfLines={1}
                >
                  {checkoutLink}
                </Text>
                <i
                  className="fa-sharp fa-arrow-right"
                  style={{
                    marginLeft: 10,
                    cursor: "pointer",
                    color: colors.primary,
                  }}
                />
              </div>
            </div>
          </a>
        )}
      </Container>
    </Modal>
  );
}

export const PayWithCryptoModal = connectModal({
  name: "PayWithCryptoModal",
})(_PayWithCryptoModal);
