import { HStack, Tooltip } from "@chakra-ui/react";
import { BaseSimpleTransactionFields } from "src/api/fragments";
import {
  CurrencyCodeEnum,
  IsDirtyEnum,
  LedgerTransactionReviewStatusEnum,
} from "src/api/generated/types";
import { StatusTagType } from "src/components/styled/StatusTag";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import { colors } from "src/theme";
import BigNumber from "bignumber.js";
import { formatNum, getCurrencySymbol } from "src/utils/helpers";
import { Maybe } from "src/core";
import { useTheme } from "src/hooks/useTheme";

type PriorityInfo = {
  iconName: string;
  type: StatusTagType;
  message: string;
  infoMessage: string;
};

export const IncomeTag = ({
  transaction,
  isDirty,
}: {
  transaction: BaseSimpleTransactionFields;
  isDirty?: Maybe<IsDirtyEnum>;
}): JSX.Element | null => {
  const theme = useTheme();

  const symbol = getCurrencySymbol(
    transaction.fiatCurrency || CurrencyCodeEnum.Usd
  );

  if (transaction.isImporting === true) {
    return null;
  }

  if (
    transaction.incomeSum !== null &&
    transaction.incomeSum !== undefined &&
    String(transaction.incomeSum) !== ""
  ) {
    const dollars =
      Number(transaction.incomeSum || transaction.incomeSum) / 100;
    const isNeg = dollars < 0;
    const cashAmount = formatNum(Math.abs(dollars), true);

    if (new BigNumber(dollars).dp(2).isZero()) {
      return (
        <HStack>
          <Tooltip label="No income.">
            <span style={{ color: theme.header }}>—</span>
          </Tooltip>
        </HStack>
      );
    }

    return (
      <HStack>
        <span
          style={{
            color: theme.header,
            textDecoration:
              !isDirty || isDirty === IsDirtyEnum.Clean
                ? undefined
                : "strikethrough",
          }}
        >
          {isNeg ? "-" : ""}
          {symbol}
          {cashAmount}
        </span>
        {/* <StatusTag <- too colorful
          iconName={info.iconName}
          type={info.type}
          label={info.message}
          infoMessage={info.infoMessage}
        ></StatusTag> */}
      </HStack>
    );
  }

  return null;
};
