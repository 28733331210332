import { Button, Container, Text, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useMyToast } from "src/hooks";
import { MutationDeleteUserArgs } from "src/api/generated/types";
import { useNavigate, useParams } from "react-router-dom";
import { noop } from "lodash";
import { Input } from "src/components/styled";
import { colors } from "src/theme";

type Props = InjectedProps & {
  email?: string;
};

function _DeleteUserModal({
  handleHide,
  show: isVisible,
  email: startingEmail,
}: Props) {
  const [email, setEmail] = useState(startingEmail || "");
  const [deleteUser, { loading }] = useMutation(api.users.delete);
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();
  const navigate = useNavigate();

  const _onSubmit = async function () {
    if (!clientId) return;

    if (!email) {
      return toast.show({
        message: "Please fill out the email field.",
        status: "error",
      });
    }

    try {
      const variables: MutationDeleteUserArgs = {
        email,
      };

      await deleteUser({
        variables,
        refetchQueries: [api.clients.retrieve],
      });

      toast.show({
        message: "User deleted successfully!",
        status: "success",
      });

      navigate("/dashboard");
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }

    handleHide();
  };

  return (
    <Modal
      title="Delete User"
      isVisible={isVisible}
      handleHide={noop}
      hideClose
      Footer={
        <Button
          width="100%"
          variant="primary"
          style={{
            backgroundColor: colors.red50,
          }}
          isLoading={loading}
          onClick={_onSubmit}
        >
          Delete User
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text>
          This will remove all clients that the user created
          (accounts/transactions/reports).
        </Text>
        <br />
        <Input
          value={email}
          label="User email"
          noOfLines={5}
          onChange={(e) => setEmail(e.target.value)}
          // label="Client Full Name"
          placeholder="Type here..."
        />
      </Container>
    </Modal>
  );
}

export const DeleteUserModal = connectModal({
  name: "DeleteUserModal",
})(_DeleteUserModal);
