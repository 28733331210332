import { ApolloError, useMutation } from "@apollo/client";
import {
  Box,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { isEqual, pick, sortBy, truncate } from "lodash";
import { chunk, orderBy, sum } from "lodash/fp";
import React, { CSSProperties, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "src/api";
import {
  BaseAccountFields,
  BaseAccountWithCurrentJobFields,
} from "src/api/fragments";
import {
  AccountIntegrationStatusEnum,
  AccountStatusEnum,
  ImportTypeEnum,
  MutationDeleteAccountGroupArgs,
} from "src/api/generated/types";
import { ActionSheet, AwakenTooltip, Button, Copy } from "src/components";
import StatusTag from "src/components/styled/StatusTag";
import WhiteBox from "src/components/styled/WhiteBox";
import { Touchable } from "src/components/Touchable";
import { hasValue } from "src/core";
import { useMe, useMyToast } from "src/hooks";
import { useDownloadFile } from "src/hooks/common";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { getImageKitCDNUrl } from "src/utils/imagekit";
import truncateMiddle from "truncate-middle";
import { AccountRow } from "./Account";
import NumberOfTransactions from "./NumberOfTransactions";
import { ACCOUNT_FIELDS } from "./utils";

const CHUNK_SIZE = 3;

type AccountProps = {
  accounts: BaseAccountWithCurrentJobFields[];
};

const _AccountsGroupedRow = ({ accounts }: AccountProps) => {
  const { clientId } = useParams();
  const toast = useMyToast();
  const [renameAccount] = useMutation<{
    renameAccount: BaseAccountFields;
  }>(api.accounts.rename);

  const navigate = useNavigate();
  const { me } = useMe("cache-only");
  const { download } = useDownloadFile();
  const isSuperUser = me?.isSuperuser || false;
  const { background, theme, header, text, border } = useTheme();

  const [deleteAccount] = useMutation<{
    deleteAccount: BaseAccountFields;
  }>(api.accounts.delete);

  const accountsInOrder = useMemo(
    () => orderBy((a) => a.numberOfTransactions ?? -Infinity, "desc", accounts),
    [JSON.stringify(accounts)]
  );

  const totalTransactions = useMemo(
    () => sum(accounts.map((a) => a.numberOfTransactions)),
    [accounts]
  );

  const firstAccount = accountsInOrder[0];
  const uniqWallets = new Set(
    accountsInOrder
      .filter((a) => !!a.walletAddress)
      .map((a) => a.walletAddress || "")
  );

  const isMultipleCSVs =
    accountsInOrder.filter((a) => !!a.fileObjectKey).length > 1;
  const isMultipleAddresses = uniqWallets.size > 1;
  const identifier = isMultipleAddresses
    ? "Multiple addresses"
    : isMultipleCSVs
    ? "Multiple CSVs"
    : firstAccount?.walletAddress;
  const showCopyIcon = uniqWallets.size > 0;

  const isLarge = useIsLargeScreen();
  const [open, setOpen] = useState(false);

  const isLoading = useMemo(
    () => accounts.some((a) => a.status === AccountStatusEnum.Syncing),
    [accounts]
  );

  const hasOutOfDate = useMemo(
    () =>
      accounts.some(
        (a) => a.integrationStatus === AccountIntegrationStatusEnum.OutOfDate
      ),
    [accounts]
  );

  console.log("re-render account");
  const freeAccount = accounts.filter((a) => a.isFree);
  const isFree = freeAccount.length > 0;
  const freeReason = freeAccount.map((a) => a.freeReason).join(", ");

  if (accounts.length === 1) {
    return <AccountRow account={accounts[0]} />;
  }

  return (
    <div style={{ width: "100%" }}>
      <Popover
        matchWidth
        isLazy
        trigger="click"
        placement="bottom"
        preventOverflow={false}
        isOpen={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        closeOnEsc
      >
        <PopoverTrigger>
          <div>
            <WhiteBox
              display="flex"
              alignItems="center"
              cursor="pointer"
              flexDir="column"
              padding={{ base: "1.75rem 0.5rem", lg: "1rem 1rem" }}
              width="100%"
              h="100%"
              marginTop="0"
              minWidth="auto"
              position="relative"
              bg={
                isFree
                  ? theme === "light"
                    ? `linear-gradient(to right, ${colors.lightBlue90}, ${colors.green110})`
                    : `linear-gradient(to right, ${colors.lightBlue10}, ${colors.green05})`
                  : background
              }
              border={
                isFree
                  ? `1px solid ${colors.lightBlue50}`
                  : `1px solid ${border}`
              }
              overflow="visible"
            >
              {isFree && (
                <AwakenTooltip
                  openDelay={0}
                  message={freeReason || "This wallet is free this year!"}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: -5,
                      right: -5,
                      zIndex: 1,
                    }}
                  >
                    <Button
                      style={{
                        height: 30,
                        fontSize: 12,
                        background: `linear-gradient(to right, rgb(31, 162, 255), rgb(77 246 147))`,
                        color: "#fff",
                        borderRadius: 0,
                        fontWeight: "black",
                      }}
                      borderRadius={"5px !important"}
                    >
                      Free
                      <i
                        style={{ marginLeft: 5 }}
                        className={"fa-sharp fa-party-horn"}
                      />
                    </Button>
                  </div>
                </AwakenTooltip>
              )}

              <VStack h="100%" justifyContent="center" w="100%">
                <HStack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  w="100%"
                >
                  <VStack>
                    {chunk(CHUNK_SIZE, accountsInOrder).map(
                      (accountsChunk, index) => (
                        <OverlappingImages
                          offset={-index * CHUNK_SIZE}
                          accounts={accountsChunk}
                          key={index}
                        />
                      )
                    )}
                  </VStack>

                  <VStack flex={1} alignItems="flex-start">
                    <HStack flex={1} alignItems="center" marginTop="0.4rem">
                      <AwakenTooltip message={firstAccount.description}>
                        <Text
                          color={header}
                          margin="0 !important"
                          fontSize="md"
                          fontWeight="medium"
                        >
                          {truncate(firstAccount.description || "", {
                            length: 26,
                            omission: "..",
                          })}
                        </Text>
                      </AwakenTooltip>

                      {hasOutOfDate && (
                        <StatusTag
                          type="warning"
                          label="Updates available"
                          iconName="fa-sharp fa-exclamation-circle"
                        />
                      )}
                    </HStack>
                    <HStack marginTop="0 !important">
                      <Text color={text} margin="0 !important" fontSize="sm">
                        {isMultipleAddresses
                          ? identifier
                          : truncateMiddle(identifier, 8, 8, "..")}
                      </Text>

                      {showCopyIcon && <Copy value={identifier || ""} />}

                      <StatusTag
                        type="info"
                        boxProps={{
                          style: {
                            border: `1px solid ${colors.lightBlue50}`,
                          },
                        }}
                        label={
                          <>
                            <NumberOfTransactions
                              showAnimation={isLoading}
                              count={totalTransactions ?? null}
                            />
                            <i
                              style={{ marginLeft: 5, fontSize: 12 }}
                              className="fa-sharp fa-sharp fa-arrow-right-arrow-left"
                            />
                          </>
                        }
                        infoMessage="Number of transactions across all wallets."
                      />
                    </HStack>

                    {isLoading && (
                      <HStack
                        w="100%"
                        marginTop="0.5 !important"
                        marginBottom="0rem !important"
                        justifyContent="flex-start"
                      >
                        <Syncing showLoadingAccount={isLoading} />
                      </HStack>
                    )}
                  </VStack>

                  {/* <Touchable iconName={"fa-sharp fa-chevron-down"} /> */}
                  <GroupActionSheet
                    identifier={identifier}
                    clientId={clientId}
                  />
                </HStack>
              </VStack>
            </WhiteBox>
          </div>
        </PopoverTrigger>

        {/* only render on open -> prevents over-rendering */}

        <PopoverContent
          // maxHeight="375px"
          // overflowY="scroll"
          marginTop="0px"
          style={{ width: "100%" }}
          borderRadius="10px"
          bg={background}
          border={`1px solid ${border} !important`}
          boxShadow={"0px 3px 10px rgb(0 0 0 / 20%) !important"}
        >
          <Box style={{ width: "100%" }}>
            {accountsInOrder.map((a, i) => (
              <AccountRow
                containerStyle={{
                  border: "none",
                  borderRadius: 0,
                  borderBottom: "1px solid " + border,
                  borderTopLeftRadius: i === 0 ? "10px" : 0,
                  borderTopRightRadius: i === 0 ? "10px" : 0,
                  borderBottomLeftRadius:
                    i === accountsInOrder.length - 1 ? "10px" : 0,
                  borderBottomRightRadius:
                    i === accountsInOrder.length - 1 ? "10px" : 0,
                }}
                account={a}
                key={a.id}
              />
            ))}
          </Box>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const Syncing = ({ showLoadingAccount }: { showLoadingAccount: boolean }) => {
  const theme = useTheme();

  if (!showLoadingAccount) {
    return null;
  }

  return (
    <StatusTag
      type="none"
      label={"Syncing"}
      rightIcon={<Spinner color={theme.header} size="sm" />}
    />
  );
};

const SLOW_IMPORT_PROVIDER = new Set([
  "coinbase",
  "coinbase_pro",
  "binance_us",
  "binance",
  "gemini",
  "kraken",
  "kucoin",
  // "solana",
]);

const AccountWarning = ({ account }: { account: BaseAccountFields }) => {
  const hasSlowWarning =
    SLOW_IMPORT_PROVIDER.has(account.provider) &&
    !account.numberOfTransactions &&
    !account.hasDoneInitialSync &&
    (account.importType === ImportTypeEnum.Hatchfi ||
      account.importType === ImportTypeEnum.Address);

  if (account.importType === ImportTypeEnum.Manual) {
    return null;
  }

  // don't show warning if the import just plain up failed
  if (account.status === AccountStatusEnum.Failed || !!account.errorMessage) {
    return null;
  }

  if (hasSlowWarning) {
    return (
      <StatusTag
        type="warning"
        label="Slow Import 🐌"
        infoMessage="Import may take 10+ minutes."
        className="progress-border-yellow"
      />
    );
  }

  // if (account.provider === "robinhood") {
  //   return (
  //     <StatusTag
  //       type="none"
  //       label="Only Crypto"
  //       infoMessage="We only import your Robinhood Crypto transactions (not stock or equities trades)."
  //     />
  //   );
  // }

  return null;
};

const GroupActionSheet = ({
  identifier,
  clientId,
}: {
  identifier?: string | null;
  clientId?: string | null;
}) => {
  const [deleteAccount, { loading }] = useMutation<{
    deleteAccount: BaseAccountFields;
  }>(api.accounts.deleteGroup);
  const toast = useMyToast();

  const _onDelete = async () => {
    try {
      if (!clientId) return;
      if (!identifier) return;

      const variables: MutationDeleteAccountGroupArgs = {
        clientId,
        walletAddress: identifier,
      };

      await deleteAccount({
        variables,
        refetchQueries: [api.clients.accounts],
      });
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
      if (e instanceof ApolloError) {
        alert(e.message || "An error occurred.");
      }
    }
  };

  const _onPressDelete = async () => {
    // prompt the user to confirm they want to delete
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this account?"
    );

    if (!isConfirmed) {
      return;
    }

    await _onDelete();

    toast.show({
      message: "These accounts are being deleted. This may take a few minutes.",
      status: "success",
    });
  };

  return (
    <ActionSheet
      popover={{ placement: "bottom-end", trigger: "click" }}
      content={{ width: 250 }}
      commands={[
        {
          label: "Delete accounts",
          iconName: "fa-sharp fa-trash",
          color: colors.red50,
          iconColor: colors.red50,
          onClick: _onPressDelete,
        },
      ].filter(hasValue)}
    >
      <Touchable
        style={{
          marginRight: 15,
        }}
        iconName="fa-sharp fa-ellipsis-v"
      />
    </ActionSheet>
  );
};

export const AccountsGroupedRow = React.memo(
  _AccountsGroupedRow,
  (prev, next) => {
    const prevAccts = sortBy(prev.accounts, (a) => a.id).map((a) =>
      pick(a, ACCOUNT_FIELDS)
    );
    const nextAccts = sortBy(next.accounts, (a) => a.id).map((a) =>
      pick(a, ACCOUNT_FIELDS)
    );

    const eq = isEqual(prevAccts, nextAccts);

    if (!eq) console.log("[re-rendering account group]");

    return eq;
  }
);

export const OverlappingImages = ({
  accounts,
  offset,
  overrideWidth,
  leftOffset,
  containerStyle,
}: {
  accounts: BaseAccountWithCurrentJobFields[];
  offset: number;
  overrideWidth?: number;
  leftOffset?: number;
  containerStyle?: CSSProperties;
}) => {
  const theme = useTheme();
  const isLarge = useIsLargeScreen();
  const width = overrideWidth || (isLarge ? 90 : 80);

  const imageContainerStyle: CSSProperties = {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: width,
    height: 12,
    flexWrap: "wrap",
  };

  const imageStyle: CSSProperties = {
    width: width / CHUNK_SIZE,
    height: width / CHUNK_SIZE,
    borderRadius: "100%",
    flexShrink: 0,
    marginRight: "0.25rem",
    position: "absolute",
    border: "1px solid " + theme.border,
    flexGrow: 0,
  };

  return (
    <div
      style={{
        width: 80,
        alignItems: "center",
        padding: isLarge ? undefined : "0 20px",
        ...containerStyle,
      }}
    >
      <div style={imageContainerStyle}>
        {accounts.map((account, index) => (
          <img
            key={index}
            src={getImageKitCDNUrl(account.iconImageUrl, {
              width: 160,
              height: 160,
            })}
            alt={`Account ${index + 1}`}
            style={
              {
                ...imageStyle,
                backgroundColor: theme.background,
                left: `${index * (leftOffset || (isLarge ? 22 : 15))}px`, // Adjust the overlapping positioning as desired
                zIndex: accounts.length - index - offset, // Ensure correct overlapping order
              } as CSSProperties
            }
          />
        ))}
      </div>
    </div>
  );
};
