import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  HStack,
  Image,
  Text,
  useFormControlProps,
} from "@chakra-ui/react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps, show } from "redux-modal";
import { Maybe } from "src/core";
import { useClientById, useMe, useMyToast } from "src/hooks";
import {
  DatePicker,
  Input,
  Select,
  Option,
  Checkbox,
  Textarea,
} from "src/components/styled";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormValues, getDefaultValues, schema } from "./form";
import _, { filter } from "lodash";
import {
  AssetTypeEnum,
  ClientAccountantStatusEnum,
} from "src/api/generated/types";
import {
  BaseAccountWithCurrentJobFields,
  BaseClientFields,
} from "src/api/fragments";
import { useMutation } from "@apollo/client";
import { api } from "src/api";

type Props = InjectedProps & {
  client: Maybe<BaseClientFields>;
};

// Client.ts entity model file
// InProgress = "in_progress",
// Blocked = "blocked",
// Finished = "finished",
const STATUS_OPTIONS = [
  {
    value: ClientAccountantStatusEnum.InProgress,
    label: "In Progress",
  },
  {
    value: ClientAccountantStatusEnum.Blocked,
    label: "Blocked",
  },
  {
    value: ClientAccountantStatusEnum.Finished,
    label: "Finished",
  },
];

function _EditClientModal({ client, handleHide, show: isVisible }: Props) {
  const { me } = useMe("cache-first");
  const [updateClient, { data }] = useMutation(api.clients.update);
  const toast = useMyToast();

  const defaultValues = useMemo(() => {
    return getDefaultValues({
      accountantNotes: client?.accountantNotes,
      accountantStatus: STATUS_OPTIONS.find(
        (o) => o.value === client?.accountantStatus
      ),
    });
  }, [client]); // Note: do not add the filters as a dependency

  const {
    formState: { isSubmitting },
    control,
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: FormValues) => {
    try {
      await updateClient({
        variables: {
          clientId: client?.id,
          accountantNotes: values.accountantNotes,
          accountantStatus: values.accountantStatus?.value,
        },
        refetchQueries: [api.clients.list, api.clients.retrieve],
      });

      // show a toast success
      toast.show({
        message: "Client updated successfully",
        status: "success",
      });

      handleHide();
    } catch (err) {
      toast.show({
        message: "Client failed to update.",
        status: "error",
      });
    }
  };

  return (
    <>
      {/* other referenced modals */}
      <Modal
        title="Edit Client Information"
        isVisible={isVisible}
        handleHide={handleHide}
        Footer={
          <Button
            onClick={handleSubmit(onSubmit, (e) => console.error(e))}
            width="100%"
            variant="primary"
            isLoading={isSubmitting}
          >
            Save Client
          </Button>
        }
      >
        <Textarea label="Notes 📝" name="accountantNotes" control={control} />
        <Select
          label="Status"
          options={STATUS_OPTIONS}
          name="accountantStatus"
          control={control}
          selectProps={{ isClearable: true }}
        />
      </Modal>
    </>
  );
}

export const EditClientModal = connectModal({
  name: "EditClientModal",
})(_EditClientModal);
