import { Time } from "lightweight-charts";
import { AssetPoint, ChartPoint } from "src/api/generated/types";

export type AwakenChartValuePoint = {
  time: Time;
  value: number;
  data: any; // FIXME: ChartPoint | AssetPoint
  timestamp: Date;
};

import { HistoricalBalanceIntervalEnum } from "src/api/generated/types";

export type TimeFrame = {
  label: string;
  type: HistoricalBalanceIntervalEnum;
};

export enum ChartType {
  Price = "price",
  Holdings = "holdings",
}

export type AwakenChartValuePointV2 = {
  value: number;
  data: any;
  date: Date;
};
