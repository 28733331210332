import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { createUploadLink } from "apollo-upload-client";
import { config } from "src/config";
import { getAuthToken } from "src/utils/firebase";

const uri = `${config.apiUrl}/graphql`;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "no-cache",
  },
};

const cache = new InMemoryCache({
  // resultCacheMaxSize: Math.pow(2, 6), // max things allowed in cache, default is 2^16
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3, // Retry up to 3 times
    retryIf: (error, _operation) => {
      // Retry if there is a network error or timeout (AbortError)
      return (
        !!error && (error.networkError || error.message === "Request timed out")
      );
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const dynamicUriLink = setContext((operation) => {
  const isPortfolioQuery = operation.operationName?.includes("Portfolio");

  const apiUrl = isPortfolioQuery
    ? `${config.apiPortfolioUrl}/graphql`
    : `${config.apiUrl}/graphql`;

  return {
    uri: apiUrl,
  };
});

const uploadLink: any = createUploadLink();

const timeoutLink = new ApolloLink((operation, forward) => {
  let timeout = 30_000;

  // Set a longer timeout for summarizeTransaction
  if (operation.operationName?.toLowerCase() === "summarizetransaction") {
    timeout = 60_000;
  }

  const controller = new AbortController();
  const signal = controller.signal;

  // Set a timeout to cancel the request
  const timeoutId = setTimeout(() => {
    controller.abort(); // Abort the request
    operation.setContext(({ response }: any) => ({
      ...response,
      error: new Error("Request timed out"),
    }));
  }, timeout);

  // Pass the abort signal to the context of the operation
  operation.setContext({
    fetchOptions: {
      signal,
    },
  });

  return forward(operation).map((data) => {
    clearTimeout(timeoutId); // Clear timeout if request completes in time
    return data;
  });
});

export const apolloClient = new ApolloClient({
  // does auth link and then after does http request
  link: ApolloLink.from([
    timeoutLink,
    retryLink,
    authLink,
    dynamicUriLink,
    uploadLink,
  ]),
  cache,
  defaultOptions,
});
