import { FetchPolicy, NetworkStatus, useLazyQuery } from "@apollo/client";
import { debounce, omit } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import {
  AssetTypeEnum,
  PortfolioResponse,
  PortfolioSortColumnEnum,
  QueryGetPortfolioArgs,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import { useURLSearch } from "src/hooks/useURLSearch";

const DEFAULT_LIMIT = 10;

type PortfolioQueryArgs = QueryGetPortfolioArgs;

export const usePortfolioFilter = () => {
  const { clientId } = useParams();
  // state
  const [assetTypeFilter, setAssetTypeFilter] =
    useState<Maybe<AssetTypeEnum>>(null);
  const [provider, setProvider] = useState<Maybe<string>>(null);
  const [page, setPage] = useState(0);
  const [isAscending, setIsAscending] = useState(false);
  const [hideExtraAssetInfo, setHideExtraAssetInfo] = useState(false);
  const [sortColumn, setSortColumn] = useState<PortfolioSortColumnEnum>(
    PortfolioSortColumnEnum.CurrentValue
  );
  const [search, _setSearch] = useState("");

  const setSearch = useCallback(debounce(_setSearch, 250), []);

  const portfolioVariables = useMemo(
    (): PortfolioQueryArgs => ({
      clientId: clientId!,
      page,
      limit: DEFAULT_LIMIT,
      type: assetTypeFilter,
      ascending: isAscending,
      sort: sortColumn,
      search,
      provider,
    }),
    [clientId, page, assetTypeFilter, isAscending, sortColumn, search, provider]
  );

  const parseFilters = (
    urlSearch: URLSearchParams
  ): Omit<PortfolioQueryArgs, "clientId" | "limit"> => {
    const ascending = urlSearch.get("ascending") === "true";
    const sort = urlSearch.get("sort") as PortfolioSortColumnEnum;
    const search = urlSearch.get("search");
    const page = parseInt(urlSearch.get("page") ?? "0");
    const type = urlSearch.get("type") as AssetTypeEnum;
    const provider = urlSearch.get("provider");

    return {
      page,
      type,
      ascending,
      sort,
      search,
      provider,
    };
  };

  const { filters, hasFilters, updateFilters, clearFilters } = useURLSearch<
    Omit<PortfolioQueryArgs, "clientId" | "limit">
  >({
    parseFilters,
  });

  // on page load sync the states
  useEffect(() => {
    if (filters.ascending) setIsAscending(filters.ascending);
    if (filters.sort) setSortColumn(filters.sort);
    if (filters.search) setSearch(filters.search);
    if (filters.type) setAssetTypeFilter(filters.type);
    if (filters.provider) setProvider(filters.provider);
    if (filters.page) setPage(filters.page);
  }, []);

  const _setAssetTypeFilter = (type: Maybe<AssetTypeEnum>) => {
    setAssetTypeFilter(type);
    setPage(0);
  };

  const _setProvider = (p: Maybe<string>) => {
    setProvider(p);
    setPage(0);
  };

  const _setPage = (newPage: number) => {
    if (newPage < 0) {
      setPage(0);
    } else {
      setPage(newPage);
    }
  };

  const [
    fetchPortfolio,
    {
      data: portfolioData,
      networkStatus: portfolioNetworkStatus,
      error: portfolioError,
      client: portfolioClient,
    },
  ] = useLazyQuery<{
    getPortfolio: Pick<PortfolioResponse, "total" | "balances">;
  }>(api.portfolio.getPortfolioAssets, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "cache-first",
  });

  useEffect(
    () => updateFilters(omit(portfolioVariables, ["clientId", "limit"])),
    [portfolioVariables]
  );

  useEffect(() => {
    if (!portfolioVariables) return;
    console.log("[pre-fetching next page " + page + " ]");
    portfolioClient
      .query({
        query: api.portfolio.getPortfolioAssets,
        variables: {
          ...portfolioVariables,
          page: (portfolioVariables.page ?? 0) + 1,
        },
      })
      .then(console.log);
  }, [portfolioClient, portfolioVariables]);

  const _getPortfolio = useCallback(
    async (fetchPolicy?: FetchPolicy, otherVariables?: any) => {
      // console.log("[fetching portfolio]");
      try {
        const variables = { ...portfolioVariables, ...otherVariables };

        await fetchPortfolio({
          variables: variables,
          fetchPolicy,
        });
      } catch (err) {
        console.log(err);
      }
    },
    [portfolioVariables]
  );

  const balances = portfolioData?.getPortfolio.balances || [];
  const totalAssets = portfolioData?.getPortfolio.total || 0;

  const totalPages = Math.ceil(totalAssets / DEFAULT_LIMIT);
  const isLoading = portfolioNetworkStatus === NetworkStatus.loading;

  return {
    isLoading,
    filters,
    hasFilters,
    updateFilters,
    clearFilters,
    portfolioVariables,
    assetTypeFilter,
    setAssetTypeFilter: _setAssetTypeFilter,
    provider,
    setProvider: _setProvider,
    page,
    setPage: _setPage,
    isAscending,
    setIsAscending,
    hideExtraAssetInfo,
    setHideExtraAssetInfo,
    sortColumn,
    setSortColumn,
    search,
    setSearch,
    balances,
    totalAssets,
    totalPages,
    getPortfolio: _getPortfolio,
  };
};
