import { compose } from "lodash/fp";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { Query, TransactionTypeOption } from "src/api/generated/types";
import { Option, Select } from "src/components/styled";
import { hasValue, Maybe } from "src/core";
import { motion } from "framer-motion";
import { colors } from "src/theme";
import { BaseFullTransactionFields } from "src/api/fragments";
import { useOnClickLabelSelect } from "./useOnClickLabelSelect";

type LabelsProps = {
  labelOptions: TransactionTypeOption[];
  selectedOption: Option | undefined;
  onSelectOption: (o: Maybe<TransactionTypeOption>) => void;
  loading: boolean;
  transaction?: BaseFullTransactionFields;
};

export function BulkLabelSelect({
  labelOptions,
  selectedOption,
  onSelectOption,
  loading,
  transaction,
}: LabelsProps) {
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const isDisabled = false; // isSol;

  const onClick = useOnClickLabelSelect({
    loading,
    isDisabled,
    _showModal,
    labelOptions,
    selectedOption,
    onSelectOption,
    transaction,
  });

  const SelectComponent = (
    <Select
      containerStyle={{
        marginBottom: 0,
        cursor: isDisabled ? "not-allowed" : "pointer",
        width: "100%",
      }}
      selectProps={{
        placeholder: "Select label",
        value: selectedOption,
        isDisabled: isDisabled,
        isSearchable: false,
        onChange: onSelectOption as any, // TODO: fix the any cast
        menuPlacement: "top",
        isClearable: true,
        isLoading: loading,
        noOptionsMessage: () => null,
        styles: {
          indicatorSeparator: () => ({ display: "none" }),
          control: (base) => ({
            ...base,
          }),
        },
      }}
      options={[]}
    />
  );

  return (
    <div style={{ width: "100%" }} onClick={onClick}>
      {SelectComponent}
    </div>
  );
}
