import { Box, Flex, HStack, Text, Tooltip } from "@chakra-ui/react";
import { colors, other } from "src/theme";
import { RECALCULATING_HELP_MESSAGE } from "src/config";
import { useSelector } from "react-redux";

import { useContext, useEffect, useMemo } from "react";
import { SelectTransactionsContext } from "./context";
import { boxShadow } from "src/theme/other";
import { BulkLabelSelect } from "src/components/Labels";
import { api } from "src/api";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Mutation,
  Query,
  TransactionTypeOption,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import { Button } from "src/components";
import { useMyToast } from "src/hooks";

export function BatchTransactionsEdit() {
  const toast = useMyToast();
  const { selectedTransactionIds, wipeTxnIds, activeLabel, setActiveLabel } =
    useContext(SelectTransactionsContext);

  const [getAvailableLabels, { loading, data, error }] = useLazyQuery<{
    getLabelOptionsForTransactions: Query["getLabelOptionsForTransactions"];
  }>(api.transactions.availableLabelsForTransactions, {
    fetchPolicy: "cache-first",
  });

  const [labelTransactions, { loading: loadingLabel }] = useMutation<{
    labelTransactions: Mutation["labelTransactions"];
  }>(api.transactions.labelMany);

  useEffect(() => {
    getAvailableLabels({
      variables: {
        transactionIds: selectedTransactionIds,
      },
    });
  }, [selectedTransactionIds]);

  const _saveLabel = async () => {
    try {
      await labelTransactions({
        variables: {
          transactionIds: selectedTransactionIds,
          label: activeLabel,
        },
        refetchQueries: [api.transactions.retrieve, api.clients.transactions],
      });

      wipeTxnIds();
      setActiveLabel(null);

      toast.show({
        status: "success",
        message: `Successfully labelled ${selectedTransactionIds.length} transactions!`,
      });
    } catch (err) {
      toast.show({
        status: "error",
        message: (err as Error).message || "Error saving label!",
      });
    }
  };

  const onSelectOption = (o: Maybe<TransactionTypeOption>) => {
    setActiveLabel(o?.value || null);
  };

  const availableLabels = data?.getLabelOptionsForTransactions?.labels || [];
  const applicableLabels = (
    data?.getLabelOptionsForTransactions?.labels || []
  ).filter((l) => l.applicable);

  const selectedLabel = useMemo(
    () => availableLabels.find((l) => l.value === activeLabel),
    [availableLabels, activeLabel]
  );

  const isApplicable = useMemo(
    () => !!applicableLabels.find((l) => l.value === activeLabel),
    [applicableLabels, activeLabel]
  );

  if (selectedTransactionIds.length < 2) {
    return null;
  }

  const isLoading = loading || loadingLabel;
  const isNotApplicable = !isApplicable && !isLoading && !!activeLabel;
  const errorMessage = error?.message || null;

  return (
    <Box
      display="flex"
      position="fixed"
      flexDir="column"
      right="60px"
      bottom="60px"
      alignItems="center"
      cursor="pointer"
      padding="1rem 2rem"
      textAlign="center"
      bg={colors.white}
      boxShadow="0px 3px 20px rgb(0 0 0 / 25%)"
      borderRadius={7}
      border={`1px solid ${colors.gray70}`}
      zIndex={20}
    >
      <Text width="100%" textAlign="center" fontSize={16}>
        Labeling{" "}
        <span style={{ color: colors.black, fontWeight: "bold" }}>
          {selectedTransactionIds.length} transactions
        </span>
      </Text>
      <div style={{ margin: "auto", marginTop: "0.75rem" }}>
        <HStack style={{ width: "100%" }}>
          <Flex flex={1} minWidth="250px">
            <BulkLabelSelect
              labelOptions={availableLabels}
              selectedOption={selectedLabel}
              onSelectOption={onSelectOption}
              loading={loading}
            />
          </Flex>
          <Button
            disabled={!activeLabel || !isApplicable || loading}
            padding="0.6rem 1rem"
            minWidth="none"
            variant="primary"
            isLoading={loadingLabel} // disable when loading more as well
            onClick={_saveLabel}
          >
            Save
          </Button>
        </HStack>

        {(isNotApplicable || !!errorMessage) && (
          <Text fontSize="sm" marginTop="0.75rem" color={colors.red50}>
            {errorMessage ? (
              <span style={{ fontWeight: "bold" }}>{errorMessage}</span>
            ) : (
              <>
                <span style={{ fontWeight: "bold" }}>
                  {selectedLabel?.label}
                </span>{" "}
                does not apply to all transactions
              </>
            )}
          </Text>
        )}

        {!!data?.getLabelOptionsForTransactions?.message && (
          <Text fontSize="sm" marginTop="0.75rem" color={colors.gray30}>
            <>{data?.getLabelOptionsForTransactions?.message}</>
          </Text>
        )}

        <HStack display="inline-block" margin="0.75rem auto 0 auto">
          <Text
            display={"inline-block"}
            fontSize="sm"
            onClick={() => wipeTxnIds()}
            _hover={{ textDecoration: "underline" }}
          >
            <i
              className="fa-sharp fa-trash-undo"
              style={{ marginRight: 5, color: colors.red50 }}
            />
            Unselect Transactions{" "}
          </Text>
          {/* <Text display={"inline-block"} style={{ margin: "0 0.5rem" }}>
            |
          </Text>
          <Tooltip label="When you label transactions in bulk, we will not create rules for transactions. Rules are patterns that learn over-time as you label. Ex. if you stake with LooksRare and label it as 'staking', we'll automatically mark other LooksRare transactions as 'staking'.">
            <Text
              _hover={{ textDecoration: "underline" }}
              display={"inline-block"}
              fontSize="sm"
            >
              <i
                className="fa-sharp fa-warning"
                style={{ color: colors.yellow40, marginRight: 5 }}
              />
              Bulk labelling limits{" "}
            </Text>
          </Tooltip> */}
        </HStack>
      </div>
    </Box>
  );
}
