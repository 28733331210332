import { noop } from "lodash";
import { createContext } from "react";
import { BaseAccountFields, BaseClientFields } from "src/api/fragments";
import { Maybe } from "src/core";

export type AccountsContext = {
  accountsToGroup: BaseAccountFields[];
  setAccounts: (accounts: BaseAccountFields[]) => void;
  groupModeOn: boolean;
  setGroupModeOn: (groupModeOn: boolean) => void;
  isEligibleForMagicEden: boolean;
  magicEdenAccountIds: string[];
  client: Maybe<BaseClientFields>;
};

export const AccountsContext = createContext<AccountsContext>({
  accountsToGroup: [] as BaseAccountFields[],
  setAccounts: noop,
  groupModeOn: false,
  setGroupModeOn: noop,
  isEligibleForMagicEden: false,
  magicEdenAccountIds: [] as string[],
  client: null,
});
