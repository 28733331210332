import { Checkbox, Container, HStack, Text, useToast } from "@chakra-ui/react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { BaseAccountFields } from "src/api/fragments";
import ReactJson from "react-json-view";

type Props = InjectedProps & {
  failures: string[];
  onSuccess: (acct?: BaseAccountFields) => void;
};

function _JobFailureModal({ handleHide, failures, show: isVisible }: Props) {
  return (
    <Modal
      title="Job Failure Details"
      isVisible={isVisible}
      minW="65rem"
      handleHide={handleHide}
    >
      <Container padding="0px" marginTop="0px !important">
        {(failures || []).map((f) => {
          return <ReactJson enableClipboard={false} src={JSON.parse(f)} />;
        })}
      </Container>
    </Modal>
  );
}

export const JobFailureModal = connectModal({
  name: "JobFailureModal",
})(_JobFailureModal);
