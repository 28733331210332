import { ApolloError, useMutation } from "@apollo/client";
import { Box, Center, HStack, Td, toast, Tooltip, Tr } from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { api, UserType } from "src/api";
import { BaseUserFields } from "src/api/fragments";
import { ClientPermission } from "src/api/generated/types";
import { Touchable } from "src/components/Touchable";
import StatusTag from "src/components/styled/StatusTag";
import { UseMeReturn, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";

type OrganizationMemberProps = {
  isMe: boolean;
  permission: ClientPermission;
  isLast?: boolean;
  isClientUser: boolean;
  numberOfPermissions: number;
};

// Note: this is a team member, but we call it an member even tho it is technically an organization
export function Member({
  isMe,
  permission,
  isLast,
  numberOfPermissions,
  isClientUser,
}: OrganizationMemberProps) {
  const toast = useMyToast();
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;
  const theme = useTheme();

  const [_removeClientMember, { loading }] = useMutation(
    api.clientMembers.remove
  );

  const [_updateOwner, { loading: loadingOwner }] = useMutation(
    api.clients.updateOwner
  );

  const email = permission.user?.email || permission.pendingUser?.email;
  const name = permission.user?.name || "-";

  async function removeClientMember() {
    if (
      window.confirm(`Are you sure you want to remove access for ${email}?`)
    ) {
      try {
        await _removeClientMember({
          variables: {
            id: permission.id,
          },
          refetchQueries: [api.clientMembers.list],
        });

        toast.show({
          message: `Removed ${permission.user ? name : email}`,
          status: "success",
        });
      } catch (err) {
        toast.show({
          message: (err as any)?.message || "Sorry, an error occurred.",
          status: "error",
        });
      }
    }
  }

  async function _setClientUser() {
    try {
      if (!permission.userId) {
        return toast.show({
          message: `This user has to make an account with Awaken before you can set them as owner. Have them signup with the link we emailed them.`,
          status: "error",
        });
      }

      await _updateOwner({
        variables: {
          clientId: permission.clientId,
          newUserId: permission.userId,
        },
        refetchQueries: [api.clientMembers.list],
      });

      toast.show({
        message: `Updated ${permission.user ? name : email} to be the owner.`,
        status: "success",
      });
    } catch (err) {
      if (err instanceof ApolloError) {
        toast.show({
          message: err.message || "Sorry, an error occurred.",
          status: "error",
        });
      } else {
        toast.show({
          message: (err as any)?.message || "Sorry, an error occurred.",
          status: "error",
        });
      }
    }
  }

  return (
    <Tr
      borderBottom={isLast ? "none" : other.boxBorder}
      transition="0.1s ease-in-out"
    >
      <Td borderColor={theme.border} color={theme.header}>
        {name}
        {isMe ? " (Me)" : ""}{" "}
        {isClientUser && <StatusTag type="info" label="Owner" />}
      </Td>
      <Td borderColor={theme.border}>
        <Center color={theme.header}>{email}</Center>
      </Td>
      <Td borderColor={theme.border}>
        <Center color={theme.header}>{permission.user ? "✅" : "🟡"}</Center>
      </Td>
      <Td borderColor={theme.border}>
        <Box display="flex" justifyContent="flex-end" borderBottom="none">
          {isMe ? (
            <HStack borderBottom="none" alignItems="center">
              <Tooltip label="This is the user who is the actual client/owner for the Awaken account. This is only used for certain notifications.">
                <div style={{ display: "inline-flex" }}>
                  <Touchable
                    isLoading={loadingOwner}
                    label="Set Owner"
                    onClick={_setClientUser}
                  />
                </div>
              </Tooltip>
            </HStack>
          ) : (
            <HStack borderBottom="none" alignItems="center">
              <Touchable
                label="Remove"
                iconName="fa-sharp fa-times-circle"
                onClick={removeClientMember}
              />

              <Tooltip label="This is the user who is the actual client/owner for the Awaken account. This is only used for certain notifications.">
                <div style={{ display: "inline-flex" }}>
                  <Touchable
                    isLoading={loadingOwner}
                    label="Set Owner"
                    onClick={_setClientUser}
                  />
                </div>
              </Tooltip>
            </HStack>
          )}
        </Box>
      </Td>
      {/* <Td isNumeric>
        {DateTime.fromJSDate(new Date(member.createdAt)).toFormat(
          "LLL dd, yyyy h:mma"
        )}
      </Td> */}
    </Tr>
  );
}
