import { Maybe } from "src/core";

export type Account = {
  name: string;
  id: string;
  level: Maybe<number>;
};

export type AccountInfoOption = {
  label: string;
  value: string;
};

export enum ModalTypeName {
  AccountDetailsForm = "account_name",
  CreateAccountRootLevel = "create_account_root_level",
  AccountTreeSelector = "account_tree_selector",
}

type ModalTypeInfo = {
  title: string;
  buttonTitle: string;
  buttonClick: string;
};

export type ModalTypesMapping = Record<ModalTypeName, ModalTypeInfo>;

export const MODAL_TYPES_MAPPING: ModalTypesMapping = {
  [ModalTypeName.AccountDetailsForm]: {
    title: "Name Account",
    buttonTitle: "Next",
    buttonClick: "",
  },
  [ModalTypeName.CreateAccountRootLevel]: {
    title: "Create Account – Info",
    buttonTitle: "Next",
    buttonClick: "CREATE_ACCOUNT_LOCATION",
  },
  [ModalTypeName.AccountTreeSelector]: {
    title: "Create Account – Location",
    buttonTitle: "Create Account",
    buttonClick: "",
  },
};
