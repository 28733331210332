import { FetchPolicy, useLazyQuery } from "@apollo/client";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  api,
  BaseLedgerEntryFullFields,
  BaseTransactionFullFields,
} from "src/api";
import {
  Transaction,
  TransactionTypeOptionResponse,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import { getPrepopulatedTxns } from "src/redux/reducers/active";

export type UseTransactionByIdReturn = {
  ledgerEntries: BaseLedgerEntryFullFields[];
  transaction: Maybe<BaseTransactionFullFields>;
  defaultLabels: TransactionTypeOptionResponse | null;
  isLoadingTransaction: boolean;
  isLoadingLedgerEntries: boolean;
  getLedgerEntries: () => Promise<void>;
};

type GetTxn = {
  resolver: { getTransaction?: BaseTransactionFullFields };
  variables: { transactionId: string };
};

type FetchEntries = {
  resolver: { getTransactionLedgerEntries?: BaseLedgerEntryFullFields[] };
  variables: { transactionId: string };
};

export const useTransactionById = (
  transactionId: Maybe<string>,
  fetchPolicy: FetchPolicy = "no-cache"
): UseTransactionByIdReturn => {
  const txnMapping = useSelector(getPrepopulatedTxns);
  const cachedTxn = txnMapping[transactionId || ""] ?? null;

  const defaultLabels =
    (cachedTxn as unknown as Maybe<Transaction>)?.options || null;
  // if (cachedTxn) {
  //   console.log("hit cache");
  // }

  const [
    fetchFullTransaction,
    { data: fullTxnData, loading: isLoadingTransaction, networkStatus },
  ] = useLazyQuery<GetTxn["resolver"], GetTxn["variables"]>(
    api.transactions.retrieve,
    {
      fetchPolicy,
    }
  );

  const [
    fetchLedgerEntries,
    { data: ledgerEntryData, loading: isLoadingLedgerEntries },
  ] = useLazyQuery<FetchEntries["resolver"], FetchEntries["variables"]>(
    api.transactions.ledgerEntries,
    {
      fetchPolicy: "no-cache",
    }
  );

  const _fetchFullTxn = useCallback(async () => {
    if (!transactionId) {
      return;
    }

    await fetchFullTransaction({
      variables: {
        transactionId,
      },
    });
  }, [transactionId, fetchFullTransaction]);

  const _fetchLedgerEntries = useCallback(async () => {
    if (!transactionId) {
      return;
    }

    await fetchLedgerEntries({
      variables: {
        transactionId,
      },
    });
  }, [transactionId, fetchLedgerEntries]);

  useEffect(() => void _fetchFullTxn(), [_fetchFullTxn]);
  useEffect(() => void _fetchLedgerEntries(), [_fetchLedgerEntries]);

  const ledgerEntries = ledgerEntryData?.getTransactionLedgerEntries || [];
  const transaction = fullTxnData?.getTransaction || cachedTxn || null;

  return {
    ledgerEntries,
    transaction,
    defaultLabels,
    isLoadingTransaction: isLoadingTransaction,
    isLoadingLedgerEntries,
    getLedgerEntries: _fetchLedgerEntries,
  };
};
