import { Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { connectModal, InjectedProps, show } from "redux-modal";
import { Modal } from "src/components/Modal";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { trackEvent } from "src/utils/analytics";
import { AccountsWorkflow } from "./AccountsWorkflow";

type Props = InjectedProps & {
  location: string;
};

function _AccountModal({ handleHide, show: isVisible, location }: Props) {
  const isLarge = useIsLargeScreen();
  const theme = useTheme();
  const dispatch = useDispatch();

  const _onSuccess = () => {
    handleHide();
  };

  useEffect(() => {
    if (isVisible) {
      trackEvent("AccountModal", {
        location,
      });
    }
  }, [isVisible]);

  return (
    <>
      {/* other referenced modals */}
      <Modal
        title={<>Add a Wallet/Exchange</>}
        isVisible={isVisible}
        handleHide={handleHide}
        maxW="60rem"
        marginTop={isLarge ? undefined : "0 !important"}
        w={isLarge ? "95%" : "100%"}
        contentStyle={{
          borderRadius: isLarge ? undefined : 0,
        }}
        headerProps={{
          style: {
            padding: isLarge ? "2rem 2rem 0 2rem" : "1rem",
          },
        }}
        bodyProps={{
          style: {
            padding: isLarge ? "2rem" : "1rem",
          },
        }}
        trapFocus={false}
      >
        <Text
          fontSize="md"
          style={{
            marginBottom: "1rem",
            fontWeight: 500,
            color: theme.text,
          }}
        >
          Do you need to upload a lot of wallets at once? Try our{" "}
          <span
            onClick={() =>
              dispatch(
                show("BatchWalletModal", {
                  onSuccess: () => _onSuccess(),
                })
              )
            }
            style={{
              color: colors.primary,
              fontWeight: "bold",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            batch upload
          </span>
          .
        </Text>
        <AccountsWorkflow onSuccess={_onSuccess} />
      </Modal>
    </>
  );
}

export const AccountModal = connectModal({
  name: "AccountModal",
})(_AccountModal);
