import { Button, Container, Text, Textarea } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps, show } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useMyToast } from "src/hooks";
import { MutationFreeSubscriptionArgs } from "src/api/generated/types";
import { useParams } from "react-router-dom";
import { colors } from "src/theme";
import { useDispatch } from "react-redux";
import { setShowConfetti } from "src/redux/reducers/globalState";
import { PriceThreshold, THRESHOLDS } from "../CheckoutModal/constants";
import { PricingCard } from "../CheckoutModal";
import { first, isNil, noop, orderBy, sortBy } from "lodash";
import { useActiveSubscription } from "src/hooks/useActiveSubscription";
import { Maybe } from "src/core";

type Props = InjectedProps;

function _FreeSubscriptionModal({ handleHide, show: isVisible }: Props) {
  const [notes, setNotes] = useState("");
  const [freeSubscription, { loading }] = useMutation(api.subscriptions.free);
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const { totalUsed, hasSubscription, availableDiscounts } =
    useActiveSubscription(clientId || "", "no-cache");

  const _onSubmit = async function () {
    try {
      if (!clientId) return;

      const variables: MutationFreeSubscriptionArgs = {
        notes: "",
        clientId,
      };

      await freeSubscription({
        variables,
        refetchQueries: [
          api.subscriptions.active,
          api.reports.getIncomeAndCapGains,
        ],
      });

      toast.show({
        message: "You just unlocked free tax reports until March 1st!",
        status: "success",
      });

      handleHide();

      setTimeout(() => dispatch(setShowConfetti(true)), 500);

      dispatch(show("ShareModal"));
      // setTimeout(() => dispatch(setShowConfetti(true)), 500);
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const largestThreshold = first(orderBy(THRESHOLDS, (t) => t.max, "desc"));
  const relevantThreshold = useMemo(() => {
    if (totalUsed === null) return null;
    return THRESHOLDS.find(
      (t) =>
        (!isNil(t.max) ? t.max >= totalUsed : true) &&
        (!isNil(t.min) ? t.min <= totalUsed : true)
    );
  }, [totalUsed]);

  const threshold: Maybe<PriceThreshold> =
    largestThreshold && relevantThreshold
      ? {
          ...largestThreshold,
          price: relevantThreshold.price,
        }
      : null;

  const discountCents = (threshold?.price ?? 0) * 100;

  return (
    <Modal
      title="Free tax reports!"
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          bg={colors.green50}
          color={colors.white}
          isLoading={loading}
          marginBottom="1.5rem"
          marginTop="1.5rem"
          _hover={{ bg: colors.green40 }}
          onClick={_onSubmit}
        >
          Unlock free subscription{" "}
          <i
            style={{
              marginLeft: 15,
              position: "absolute",
              right: 20,
              top: 10,
              fontSize: 20,
            }}
            className="fa-sharp fa-arrow-right"
          />
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text>
          The best crypto tax software just got better.
          <br />
          <br />
          Until March 1st, you can use Awaken <b>100% FREE</b>. Unlimited
          wallets. Unlimited exchanges. Unlimited transactions. Download tax
          reports 100% free.
        </Text>

        <br />

        {!!threshold && (
          <PricingCard
            threshold={threshold}
            isLandingPage={false}
            gradient={threshold.gradient}
            totalTxns={totalUsed}
            onClick={noop}
            discountCents={discountCents ?? 0}
            hideButton
          />
        )}

        {/* <Textarea 
          value={notes}
          noOfLines={5}
          onChange={(e) => setNotes(e.target.value)}
          // label="Client Full Name"
          placeholder="Type here..."
        /> */}
      </Container>
    </Modal>
  );
}

export const FreeSubscriptionModal = connectModal({
  name: "FreeSubscriptionModal",
})(_FreeSubscriptionModal);
