import { FC, KeyboardEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  DropFile,
  DropFileParams,
  buildNewRow,
  isUUID,
  isValidDate,
  isValidNumber,
} from "./DropFile";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import Editor from "react-simple-code-editor";
import StatusTag from "./Status";
import moment from "moment";
import { groupBy, head, truncate } from "lodash";
import { useDownloadFile } from "src/hooks/common";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

export type FileTabInfo = {
  id: string;
  file: File;
  rawRows: any[];
  cleanedRows: any[];
  cleanedCsvData: string;
  headers: string[];
  description: string;
  gptDescription: string;
  columns: string;
};

export const FileTab = ({ fileInfo }: { fileInfo: FileTabInfo }) => {
  const { file, headers, cleanedRows, cleanedCsvData } = fileInfo;
  const toast = useToast();

  const [description, setDescription] = useState<string>("");
  const [columns, setColumns] = useState<string>(
    "Date, Received Quantity, Received Currency, Sent Quantity, Sent Currency, Fee Amount, Fee Currency, and Notes.\n\nThe Received Quantity and Sent Quantity should be numbers. There may not be a Fee Amount, and if there is not it should be set to 0. The Received Currency and Sent Currency should be 3-4 character cryptocurrencies. Generate notes just describing the transaction. Don't do strict comparisons and instead lowercase and see if a string includes a value."
  );
  const [jsParser, setJsParser] = useState<string>("");
  const [hasCode, setHasCode] = useState<boolean>(false);
  const [hasGPTDescription, setHasGPTDescription] = useState<boolean>(false);
  const [loadingGPTDescription, setLoadingGPTDescription] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [clickedNext, setClickedNext] = useState<boolean>(false);
  const [showCode, setShowCode] = useState<boolean>(false);
  const [hasDescribed, setHasDescribed] = useState<boolean>(false);

  const { downloadFile } = useDownloadFile();

  const exampleRows = useMemo(
    () => getExampleRows(headers, cleanedRows),
    [JSON.stringify({ cleanedRows, headers })]
  );

  const _generateCSV = async () => {
    if (!hasGPTDescription) {
      alert("Please wait for GPT to generate the description first.");
      return;
    }

    if (!file) {
      alert("Please add a file.");
      return;
    }

    if (!exampleRows.length) {
      alert("No example rows to generate a parser for.");
      return;
    }

    if (!columns) {
      alert("Please add columns you want.");
      return;
    }

    await handleStreamForJSParser();
  };

  const formatAsCSVRow = (headers: string[]) => (jsonObj: any) => {
    return Object.values(buildNewRow(jsonObj, headers)).join(",");
  };

  const removeCharsBeforeFunction = (str: string) => {
    const functionIndex = str.indexOf("function");
    return functionIndex === -1 ? str : str.slice(functionIndex);
  };

  const handleStreamForRowDescription = async () => {
    try {
      if (!exampleRows.length) {
        // alert("No example rows to generate a parser for.");
        return;
      }

      if (loadingGPTDescription) {
        return;
      }

      setLoadingGPTDescription(true);
      setDescription("");
      setHasGPTDescription(false);
      setClickedNext(true);

      console.log("example rows: ", exampleRows.length);

      const rows = exampleRows.map(formatAsCSVRow(headers));
      const exampleRowsFormatted = `${headers}\n` + rows.join("\n");

      const response = await fetch("https://csv.awaken.tax/api/describe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          exampleRows: exampleRowsFormatted,
        }),
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error(response.statusText);
      }

      const data = response.body;

      if (!data) {
        return;
      }

      const reader = data.getReader();
      const decoder = new TextDecoder();
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        // console.log("CHUNK: ", chunkValue);
        setDescription((prev) => prev + chunkValue);
      }
      setHasDescribed(true);
      setHasGPTDescription(true);
    } catch (err) {
      console.error(err);
      setHasGPTDescription(false);
      toast({
        position: "top",
        status: "error",
        title:
          ((err as any)?.message || "An error occurred.") +
          " Please just click the describe button to try again.",
      });
    } finally {
      setLoading(false);
      setLoadingGPTDescription(false);
    }
  };

  const handleStreamForJSParser = async () => {
    try {
      setLoading(true);
      setHasCode(false);
      setJsParser("");

      const rows = exampleRows.map(formatAsCSVRow(headers));
      const exampleRowsFormatted = `${headers}\n` + rows.join("\n");
      const fullDescription = description;
      const response = await fetch("https://csv.awaken.tax/api/getParser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          columns,
          description: fullDescription,
          exampleRows: exampleRowsFormatted,
        }),
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error(response.statusText);
      }

      const data = response.body;

      if (!data) {
        return;
      }

      const reader = data.getReader();
      const decoder = new TextDecoder();
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        // console.log("CHUNK: ", chunkValue);
        setJsParser((prev) => {
          if (done) {
            return (prev + chunkValue)
              .replace("javascript", "")
              .replace(/`/g, "");
          }
          return prev + chunkValue;
        });
      }
      setHasCode(true);
    } catch (err) {
      console.error(err);
      toast({
        position: "top",
        status: "error",
        title: (err as any)?.message || "An error occurred.",
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = () => {
    try {
      console.log(`[downloading CSV]`);

      const jsFunctionString = removeCharsBeforeFunction(
        jsParser
          .replace(/(?<!`)(`)(?!`{2})/g, "\\`")
          .replace(/`/g, "")
          .replace("javascript", "")
      );

      // console.log(jsFunctionString);

      // Use a regular expression to extract the parameters and function body
      const functionRegex = /function\s*\w*\s*\(([^)]*)\)\s*\{([\s\S]*)\}/m;
      const match = jsFunctionString.match(functionRegex);

      if (match) {
        const params = match[1]; // Parameters inside the parentheses
        const body = match[2]; // Function body inside the braces

        let formatFileFunc;
        try {
          // Create the function dynamically
          formatFileFunc = new Function(params, body);
        } catch (error) {
          console.error("Error creating function:", error);
          // Handle the error appropriately
          return;
        }

        const result = formatFileFunc(cleanedCsvData);

        // any NaN in between two commas, replace with nothing
        const cleanedResult = result.replace(/,NaN,/g, ",,");

        console.log("result: " + cleanedResult);

        downloadFile(file?.name || "", cleanedResult);
      } else {
        console.error("Invalid function string");
        // Handle the error appropriately
        toast({
          position: "top",
          status: "error",
          title: "An error occurred.",
        });
        return;
      }
    } catch (err) {
      console.error(err);
      toast({
        position: "top",
        status: "error",
        title: (err as any)?.message || "An error occurred.",
      });
    }
  };

  const theme = useTheme();

  if (exampleRows.length === 0) {
    return (
      <div className="ml-1 mt-5 w-full text-red-500 text-left text-lg font-semibold">
        This CSV appears to have no rows. Please export it and make sure there
        are not any weird newline characters.
      </div>
    );
  }

  return (
    <div style={{ marginTop: "1rem", width: "100%" }}>
      <div
        style={{
          color: theme.header,
        }}
        className="ml-1 w-full text-left text-lg font-semibold"
      >
        Let&apos;s figure out what is happening with this CSV 🤔
      </div>

      <VStack alignItems="flex-start" w="100%" marginTop="2rem">
        <HStack alignItems="flex-start" w="100%">
          <div style={{ width: 800, flexShrink: 0 }}>
            <div
              className="border rounded-lg overflow-hidden"
              style={{
                background: theme.medBackground,
                borderColor: theme.border,
                maxHeight: 400,
                overflowY: "scroll",
                overflowX: "scroll",
                overscrollBehaviorX: "none",
              }}
            >
              <table
                style={{
                  width: "100%",
                }}
              >
                <thead>
                  <tr
                    className="text-left"
                    style={{
                      color: theme.header,
                      background: theme.secondaryBackground,
                    }}
                  >
                    {headers.map((h, i) => (
                      <th
                        key={i}
                        className="px-2 py-3 text-xs"
                        // css to not have the lines wrap
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {truncate(h, { length: 20 })}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {exampleRows.map((r, j) => (
                    <tr key={j} style={{ color: theme.header }}>
                      {headers.map((h, i) => {
                        const val = r[h];

                        const isDate = isValidDate(val);
                        const isNumber = isValidNumber(val);
                        const isUuid = isUUID(val);
                        const isOther = !isDate && !isNumber && !isUuid;

                        return (
                          <td
                            key={i}
                            className="px-2 border-t  py-1 text-sm"
                            style={{
                              borderColor: theme.border,
                              color: theme.text,
                            }}
                          >
                            {/* format date so readable */}
                            {isDate
                              ? moment(val).format("h:mm:ss a MM/DD/YYYY")
                              : ""}
                            {isNumber ? val : ""}
                            {isUuid ? truncate(val, { length: 10 }) : ""}
                            {isOther ? truncate(val, { length: 20 }) : ""}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {!hasDescribed && (
              <HStack
                alignItems="center"
                style={{ marginTop: "2rem", maxWidth: 450, overflow: "hidden" }}
              >
                <Button
                  className="bg-blue-500 text-white hover:bg-blue-400 font-medium"
                  style={{
                    fontSize: 18,
                  }}
                  _hover={{
                    opacity: 0.9,
                    backgroundColor: "#3B82F6",
                  }}
                  variant="none"
                  onClick={handleStreamForRowDescription}
                  isLoading={loadingGPTDescription}
                >
                  Describe my CSV{" "}
                  <i style={{ marginLeft: 5 }} className="fas fa-arrow-right" />
                </Button>
              </HStack>
            )}
          </div>
        </HStack>
      </VStack>

      {clickedNext && (
        <VStack
          style={{ marginTop: "2rem" }}
          w="100%"
          flex={1}
          alignItems="flex-start"
        >
          {/* <FormLabel
            fontSize="md"
            className="text-gray-700"
            style={{ marginBottom: 0 }}
          >
            What is happening in these transactions?
          </FormLabel> */}

          <p
            className="text-gray-700 text-sm"
            style={{ marginBottom: "1rem", color: theme.header }}
          >
            Make sure to check over the below information and edit it if it
            appears wrong ✏️
          </p>

          {/* <p className="text-xs" style={{ marginBottom: "1rem" }}>
                We recommend using the words &quot;sent&quot; and
                &quot;received&quot; instead of sold/bought. Ex. &quot;I sent
                USDT and received ETH&quot;.
              </p> */}
          <Textarea
            marginTop="1rem"
            rows={10}
            flex={1}
            value={description}
            maxWidth="450px"
            _disabled={{
              opacity: 0.9,
              cursor: "not-allowed",
            }}
            style={{
              color: theme.text,
            }}
            disabled={loadingGPTDescription}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Ex. I sold 450,000 VET for 500 USDT on kucoin."
          />

          {!hasGPTDescription && (
            <p
              className="text-sm mt-1"
              style={{
                color: theme.header,
              }}
            >
              GPT is currently typing
              <LoadingDots />
            </p>
          )}
          {/* <br />
              <FormLabel fontSize="sm">
                What columns should the CSV have?
              </FormLabel>
              <Textarea
                rows={4}
                flex={1}
                value={columns}
                onChange={(e) => setColumns(e.target.value)}
                placeholder="Ex. The CSV should have these columns: Date, Received Quantity, Received Currency, Sent Quantity, Sent Currency, Fee Amount, Fee Currency, and Notes."
              /> */}

          {!hasCode && (
            <>
              <Button
                className="bg-primary text-white hover:bg-primary"
                onClick={_generateCSV}
                _hover={{
                  opacity: 0.9,
                  backgroundColor: colors.primary,
                }}
                style={{
                  marginTop: "2rem",
                  fontSize: 18,
                }}
                isLoading={loading}
                disabled={!hasGPTDescription || loading}
                variant="none"
              >
                Looks good, format my CSV{" "}
                <i style={{ marginLeft: 5 }} className="fas fa-arrow-right" />
              </Button>

              <p
                className="text-xs"
                style={{ marginTop: "0.5rem", color: theme.text }}
              >
                This may take a couple of minutes.
              </p>
            </>
          )}
        </VStack>
      )}

      {hasCode && file && jsParser && (
        <div
          style={{ marginTop: "3rem", width: "100%" }}
          // className="bg-gray-50 border border-gray-100 rounded-lg p-4"
        >
          {hasCode && (
            <>
              <div
                style={{
                  color: theme.header,
                }}
                className="w-full text-left text-lg font-semibold"
              >
                Ta-da! Try downloading the new, formatted CSV 💫
              </div>

              <br />

              <div
                style={{
                  color: theme.header,
                }}
                className="w-full text-left text-lg font-semibold"
              >
                You can then import this CSV into Awaken on the "Accounts" page.
              </div>

              <VStack
                justifyItems="flex-start"
                display="flex"
                alignItems="flex-start"
                paddingTop="1rem !important"
                textAlign="left"
                flex={1}
              >
                <Button
                  className="bg-primary text-white hover:bg-primary"
                  style={{
                    marginBottom: "1.5rem",
                    marginTop: "0.5rem",
                    fontSize: 18,
                    background: colors.primary,
                  }}
                  _hover={{
                    opacity: 0.9,
                  }}
                  variant="none"
                  onClick={downloadCSV}
                >
                  Download Formatted CSV{" "}
                  <i style={{ marginLeft: 8 }} className="fas fa-download" />
                </Button>

                <p
                  style={{
                    color: theme.text,
                  }}
                >
                  Having problems? Try tweaking the description above or you can{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.notion.so/accrue/How-to-Format-Your-CSV-for-Awaken-Tax-a7c06a4a90e44ff190c5ff9601ffd751"
                    className="underline text-blue-500 font-semibold font-sm"
                  >
                    view our guide
                  </a>{" "}
                  on how to manually create an Awaken formatted CSV.
                </p>
                {/*  */}
                <div style={{ marginTop: "1rem" }}>
                  <p
                    style={{
                      color: theme.text,
                    }}
                  >
                    You can also try regenerating the CSV formatted here:
                  </p>
                  <Button
                    className=""
                    onClick={_generateCSV}
                    _hover={{
                      opacity: 0.9,
                    }}
                    style={{
                      marginTop: "0.75rem",
                      fontSize: 14,
                      background: theme.secondaryBackground,
                      color: theme.header,
                    }}
                    isLoading={loading}
                    disabled={!hasGPTDescription || loading}
                    variant="none"
                  >
                    Try formatting the CSV again{" "}
                    <i style={{ marginLeft: 5 }} className="fas fa-redo-alt" />
                  </Button>
                </div>
              </VStack>
            </>
          )}

          {/* {hasCode && (
            <div
              className="text-md font-normal"
              style={{ marginBottom: "2rem", color: colors.white }}
            >
              {showCode ? "Show me" : "Show me"} the{" "}
              <span
                onClick={() => setShowCode((prev) => !prev)}
                style={{ cursor: "pointer" }}
                className="text-blue-500 font-semibold font-sm underline"
              >
                code <i className="fas fa-code" />
              </span>
            </div>
          )}

          {showCode && (
            <VStack display="flex">
              <div className="w-full text-left text-md font-normal">
                ADVANCED: Here is the code that is used to generate the CSV. You
                can edit the code below if you like, or adjust your description
                above to have GPT try again!
              </div>
              {jsParser && (
                <Editor
                  value={jsParser}
                  onValueChange={(code) => setJsParser(code)}
                  highlight={(code) => highlight(code, languages.js)}
                  padding={10}
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid " + "#EDEDEE",
                    borderRadius: 10,
                    marginTop: "1rem",
                    outline: "none",
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 14,
                    width: "100%",
                  }}
                />
              )}
            </VStack>
          )} */}
        </div>
      )}
    </div>
  );
};

// these usually specify the type of the row / what is happening
const TYPE_OPTIONS = [
  "kind",
  "type",
  "side",
  "operation",
  "classification",
  "activity",
  "action",
];

const _cleanHeader = (h: string): string[] => {
  // if the header has spaces, _ or - split it into each piece
  const split = h.split(/[\s_-]/);
  return split;
};

const _partiallyMatchesAnOption = (headers: string[]): string | null => {
  const matches = headers.filter((h) => {
    const header = _cleanHeader(h);
    const hasMatches = header.some((part) =>
      TYPE_OPTIONS.includes(part.toLowerCase())
    );
    return hasMatches;
  });

  // console.log(TYPE_OPTIONS, headers, matches);

  return matches[0] ?? null;
};

const getExampleRows = (headers: string[], rows: any[]) => {
  if (!rows.length) return [];
  const typeColumn = _partiallyMatchesAnOption(headers);

  console.log(`[using ${typeColumn} as type]`);
  // if no type or kind just return first three
  if (!typeColumn) return rows.slice(0, 3);
  // otherwise unique by the column and pick values
  const byType = groupBy(rows, (r) => r[typeColumn]);
  const exampleRows = Object.values(byType)
    .map((t) => t[0])
    .filter((v) => v);

  // if only one, not rlly enough data points
  if (exampleRows.length === 1) {
    // pick random indices and get the rows
    const randomIndices = [0, 1, 2].map(() =>
      Math.floor(Math.random() * rows.length)
    );
    return [...exampleRows, ...randomIndices.map((i) => rows[i])];
  }

  return exampleRows;
};

function LoadingDots() {
  const [dots, setDots] = useState(".");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => {
        const dotLength = prevDots.length;
        return dotLength < 3 ? prevDots + "." : ".";
      });
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return <span className="ml-1">{dots}</span>;
}
