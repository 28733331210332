import {
  Box,
  Container,
  Heading,
  Text,
  ListItem,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { isMobile } from "react-device-detect";
import { NavBarPageTemplate } from "src/components";
import ContentContainer from "src/components/styled/ContentContainer";
import { colors } from "src/theme";

const SITE_URL = "https://awaken.tax";
const EMAIL = "team@awaken.tax";

function WebsiteLink() {
  return (
    <a
      href={SITE_URL}
      rel="noreferrer"
      target={isMobile ? undefined : "_blank"}
      style={{
        textDecoration: "underline",
      }}
    >
      Awaken
    </a>
  );
}

function Privacy() {
  return (
    <NavBarPageTemplate bgColor={colors.white}>
      <Box w="96%" maxW="50rem" margin="4rem auto">
        <Heading size="xl">Privacy Policy</Heading>
        <ContentContainer>
          Below is our Privacy Policy which outlines a lot of legal goodies, but
          the bottom line is we do not sell or share your data with any third
          parties.
        </ContentContainer>
        <ContentContainer>
          We are Accrue Finance Inc. ("Data Controller", "we", "us", "our",
          "Accrue Finance", "the Company", "Owner", "Operator").
        </ContentContainer>
        <Heading size="lg">Your Privacy</Heading>
        <ContentContainer>
          It is our policy to respect your privacy regarding any personal data
          we may collect while operating our services on Awaken, available at
          https://awaken.tax and the accompanying mobile application Awaken
          (hereinafter, collectively referred to as “Awaken”). Accordingly, we
          have developed this privacy policy (hereinafter, referred to as the
          “Privacy Policy” or the “Policy”) in order for you to understand how
          we collect and process (i.e., use, store, share, disclose and
          otherwise make use of) your personal data. This Privacy Policy will
          also cover your rights in relation to your personal data.
        </ContentContainer>
        <ContentContainer>
          By registering to use our Services, you consent to us processing your
          personal information in accordance with this Privacy Policy. This is
          the legal basis upon which we process your personal information as
          well as because it is necessary for the performance of our obligations
          under the Terms of Service. By continuing to use Awaken, you
          acknowledge that you have had the chance to review and consider this
          Privacy Policy, and you acknowledge that you agree to it. This means
          that you also consent to the use of your personal data and the methods
          of disclosure as described in this Privacy Policy. If you do not
          understand the Privacy Policy or do not agree to one or more
          provisions of this Privacy Policy, please immediately cease your use
          of Awaken. You may withdraw your consent at any time by letting us
          know at team@awaken.tax. At that time, you may also want to remove any
          cookies which have been placed on any device used to access Awaken.
          Your withdrawal of consent will not affect the lawfulness of any
          processing carried out by us prior to such withdrawal.
        </ContentContainer>
        <ContentContainer>
          This Agreement specifically incorporates by reference the entirety of
          our Terms of Service and Disclaimer.
        </ContentContainer>
        <ContentContainer>
          We want you to obtain personalized experiences anytime, anywhere, on
          any device. This requires us to collect personal information from you.
          However, we want to put you in control of that personal information.
          We want you to be aware at all times where and how your personal
          information is being used. This is of paramount importance to us.
        </ContentContainer>
        <ContentContainer>
          So let’s describe what this policy tells you. It explains:
        </ContentContainer>
        <OrderedList
          style={{
            marginLeft: "1rem",
          }}
        >
          <Text>
            <ListItem>What information we may collect about you</ListItem>
            <ListItem>
              What we may do with the information we collect about you
            </ListItem>
            <ListItem>
              Whether we share your information with anyone else
            </ListItem>
            <ListItem>
              The types of cookies we use and how you can reject these cookies
            </ListItem>
            <ListItem>Where we store your information</ListItem>
            <ListItem>How we keep your information secure</ListItem>
            <ListItem>Your rights in relation to your information</ListItem>
          </Text>
        </OrderedList>
        <ContentContainer>
          At Accrue Finance we take your privacy seriously. We operate on the
          principle that your personal information belongs to you and only you
          can decide who you want to share it with and why. This is fundamental
          to the way we work and we’re committed to providing a secure
          environment for you to store your personal information and share it
          with others when you want to.
        </ContentContainer>
        <Heading size="lg">
          How We Collect Information and What Information We May Collect About
          You
        </Heading>
        <Heading size="sm">Information you voluntarily provide</Heading>
        <ContentContainer>
          If you contact us (by phone, email or through our website, or app), we
          may keep a record of that correspondence indefinitely in case we need
          to contact you in relation to the issue for which you contacted us,
          for operational performance improvement and/or nuisance caller
          management. We will not use it for marketing purposes.
        </ContentContainer>
        <ContentContainer>
          If you report a problem with Awaken, we may keep that information
          indefinitely in case we need to contact you in relation to the issue
          for which you contacted us, for operational performance improvement
          and/or nuisance caller management. We will not use it for marketing
          purposes.
        </ContentContainer>
        <ContentContainer>
          The information you give may include your name, address, telephone
          number and email address.
        </ContentContainer>
        <Heading size="sm">
          Information we collect about you and your device
        </Heading>
        <ContentContainer>
          Through your use of Awaken, you will be subject to different types of
          personal data collected and different manners of collection. We will
          collect and process your personal data in a lawful, fair, and
          transparent manner and, where appropriate, with your knowledge or
          prior explicit consent. Personal data will be relevant to the purposes
          for which they are to be used, and, to the extent necessary for those
          purposes, should be accurate, complete, and up-to-date.
        </ContentContainer>
        <Heading size="lg">Types of Personal Data</Heading>
        <ContentContainer>
          The following types of personal data will be collected by the Company
          through Awaken:
        </ContentContainer>
        <OrderedList
          style={{
            marginLeft: "1rem",
          }}
        >
          <Text>
            <ListItem>
              REGISTRATION: You, as a user of Awaken, may be asked to register
              an account to use Awaken or access any of the services thereon.
              During the process of your registration, you will be asked to
              provide your email address, a password, your Google credentials
              (if you opt for registration through Google), your Coinbase
              credentials (if you opt for registration through Coinbase), and
              any cryptocurrency wallets that you would like to disclose. We use
              these personal data to provide the services to you and ensure the
              security of Awaken.
            </ListItem>
            <ListItem>
              BILLING: If you elect to use any of our paid services, you will be
              asked to provide billing information, such as, but not limited to,
              your credit card number and billing address. This personal data
              will be used specifically for providing you with the services you
              have purchased. The payment and billing information may be stored
              for the time period as required by applicable law for lawful
              purposes (e.g., accountancy records).
            </ListItem>
            <ListItem>
              Whether we share your information with anyone else
            </ListItem>
            <ListItem>
              CRYPTO AND FINANCIAL INFORMATION: Depending on how you choose to
              interact with the Awaken services, you may be asked to provide
              your cryptocurrency wallet and general portfolio information and
              other information that will assist us in providing the services to
              you. You may also be asked for API access for certain accounts
              which may be aggregated for you on Awaken.
            </ListItem>
            <ListItem>
              USE: Through your use of Awaken, you may be asked questions about
              how to improve Awaken or you may, at some point, communicate with
              representatives of Awaken. We will receive your email address, as
              well as the contents of your communications, answers to questions
              and any other form of contact between you and Awaken. We will use
              personal data contained in such communications only for the
              purposes of providing you with the requested information and for
              improving the services.
            </ListItem>
            <ListItem>
              AUTOMATIC COLLECTION: We may collect information from you through
              automatic tracking systems on your device or through the Awaken
              application programming interface, or API, or other web and mobile
              analysis software. We may also receive certain usage data, such as
              your IP address and referral source. We use this information for
              our legitimate business interests, including analyzing usage of
              Awaken.
            </ListItem>
            <ListItem>
              COMBINED OR AGGREGATED INFORMATION: We may combine or aggregate
              some of your personal data in order to better serve you and to
              better enhance and update Awaken for your and other consumers’
              use, or to create public posts about insights or trends in the
              domain.
            </ListItem>
          </Text>
        </OrderedList>
        <Heading size="lg">Non-Personal Data</Heading>
        <ContentContainer>
          We may collect non-personal data, such as browser types, operating
          systems, and the URL addresses of websites clicked to and from Awaken,
          including referral links we may post on Awaken in order to analyze
          what kind of users visit Awaken, how they find it, how long they stay,
          from which other websites they come to Awaken, what pages they look
          at, and to which other websites they go from Awaken. In case your
          non-personal data are aggregated with certain elements of your
          personal data in the way that we may become able to identify you, such
          non-personal data will be treated as personal data.
        </ContentContainer>
        <Heading size="lg">How Information Is Used</Heading>
        <ContentContainer>
          We respect data protection principles and process personal data only
          for specified, explicit and legitimate purposes for which such
          personal data were provided. We primarily use your personal data to
          enable your use of Awaken and supply the services requested by you. We
          may also use your personal data for the following purposes:
        </ContentContainer>
        <OrderedList
          style={{
            marginLeft: "1rem",
          }}
        >
          <Text>
            <ListItem>Asking you about your experience with us;</ListItem>
            <ListItem>
              Communicating with you about your user account with us;
            </ListItem>
            <ListItem>Analyzing our aggregate user data;</ListItem>
            <ListItem>Enforcing our Terms of Service;</ListItem>
            <ListItem>Providing customer services to you;</ListItem>
            <ListItem>
              Ensure that content from Awaken is presented in the most effective
              manner for you and for your device to achieve the most
              user-friendly navigation experience;
            </ListItem>
            <ListItem>
              Provide you with marketing information about us and our services
              (you may opt out from receiving such information at any time);
            </ListItem>
            <ListItem>Defend our servers against malicious attacks;</ListItem>
            <ListItem>
              Advising you about updates to Awaken or related items; or
            </ListItem>
            <ListItem>
              Providing you with information about services that are similar to
              the services used by you
            </ListItem>
          </Text>
        </OrderedList>
        <ContentContainer>
          Where we propose using your personal information for any other uses we
          will ensure that we notify you first. You will also be given the
          opportunity to withhold or withdraw your consent for the use of your
          personal information for purposes other than those listed above.
        </ContentContainer>
        <Heading size="lg">
          Our Use of Aggregated and Anonymized Information
        </Heading>
        <ContentContainer>
          We may share anonymized usage data which does not identify you
          specifically with third parties. We may combine your data with those
          of other users of Awaken and share this information in aggregated and
          anonymised form with third parties to help us improve the design and
          delivery of our software tools, increasing the effectiveness of all
          users.
        </ContentContainer>
        <Heading size="lg">Newsletters and Marketing Communications</Heading>
        <ContentContainer>
          From time to time, we may send you informational communications
          related to Awaken, such as announcements about updates to Awaken. you
          may also receive information from us that is specifically about your
          use of Awaken or about your account with us, including information
          about security breaches or other privacy-related matters. Please note
          that such communication does not fall within the scope of direct
          marketing communication.
        </ContentContainer>
        <ContentContainer>
          After we obtain your explicit prior consent, we may use your personal
          data for direct marketing purposes, such as:
        </ContentContainer>
        <OrderedList
          style={{
            marginLeft: "1rem",
          }}
        >
          <Text>
            <ListItem>Sending you a newsletter;</ListItem>
            <ListItem>
              Offering you new products, services, mobile applications, and/or
              recommendations;
            </ListItem>
            <ListItem>Profiling; and</ListItem>
            <ListItem>
              Delivering you targeted information regarding promotions related
              to Awaken.
            </ListItem>
          </Text>
        </OrderedList>
        <ContentContainer>
          If you decide at any time that you no longer wish to receive
          communications from us, please follow the “unsubscribe” instructions
          provided in the communications, turn off communications in your
          settings page, or contact us at team@awaken.tax.
        </ContentContainer>
        <Heading size="lg">Your Consent</Heading>
        <ContentContainer>
          By using Awaken, you are consenting to our processing of your personal
          data as set forth in this Privacy Policy. The term “processing”
          includes collecting, storing, deleting, using, and disclosing personal
          data.
        </ContentContainer>
        <ContentContainer>
          Please note that we do not collect any sensitive data about you (e.g.,
          your health information, opinions about your religious and political
          beliefs, racial origins and memberships of a professional or trade
          associations, social security number). Should we intend to process any
          sensitive data collected from you, we will seek your explicit consent
          in advance.
        </ContentContainer>
        <Heading size="lg">How Information Is Stored And Kept Secure</Heading>
        <Heading size="sm">Protection of Personal Data</Heading>
        <ContentContainer>
          We protect your personal data by using reasonable security safeguards
          against loss or theft, unauthorized access, disclosure, copying, use,
          or modification. Your personal data are contained behind secured
          networks and are only accessible by a limited number of persons who
          have special access rights to such systems and are required to keep
          the personal data confidential. We implement a variety of security
          measures, such as encryption and pseudonymisation, when users enter,
          submit, or access their personal data to maintain the safety of your
          personal data. Please note, however, that no system involving the
          transmission of information via the Internet, or the electronic
          storage of data, is completely secure. However, we take the protection
          and storage of your personal data very seriously and we therefore take
          all reasonable steps to ensure the ongoing confidentiality, integrity,
          and availability of your personal data. Nevertheless, we are not
          liable for any loss, theft, unauthorized access, disclosure, copying,
          use, or modification of your personal data that occurs outside our
          reasonable control.
        </ContentContainer>
        <Heading size="sm">Breach notification</Heading>
        <ContentContainer>
          Should a personal data breach occur, we will inform the relevant
          authorities without undue delay and immediately take reasonable
          measures to mitigate the breach. We will notify you about such a
          breach via email as soon as possible but no later than within seven
          business days.
        </ContentContainer>
        <Heading size="sm">Retention period</Heading>
        <ContentContainer>
          Your personal data will be kept for as long as it is necessary to
          provide you with the requested services. When your personal data are
          no longer necessary to deliver the requested services, we will delete
          your personal data immediately, unless we are obliged by law to retain
          such personal data for a specific period of time.
        </ContentContainer>
        <Heading size="lg">Cookies</Heading>
        <ContentContainer>
          Awaken uses cookies. Cookies are small files stored on your computer
          or mobile device which collect information about your browsing
          behavior. These cookies do not access information which is stored on
          your computer.
        </ContentContainer>
        <ContentContainer>
          There are two types of cookies, namely, persistent cookies (i.e.,
          cookies that remain valid until their expiration date, unless deleted
          by the user before that date) and session cookies (i.e., cookies that
          are stored on a web browser and remain valid until the moment when the
          browser is closed). We use both persistent cookies and session
          cookies.
        </ContentContainer>
        <ContentContainer>
          We use cookies to help us remember information about your account. For
          example, cookies are used to help us understand your preferences based
          on previous or current activity on Awaken, which enables us to provide
          you with improved services. We also use cookies to help us compile
          aggregate data about Awaken traffic and interaction so that we can
          offer better experiences and tools in the future. Other reasons we use
          cookies include (but are not limited to):
        </ContentContainer>
        <OrderedList
          style={{
            marginLeft: "1rem",
          }}
        >
          <Text>
            <ListItem>
              Understanding and saving user’s preferences for future visits;
            </ListItem>
            <ListItem>Keeping track of advertisements; and</ListItem>
            <ListItem>
              Compiling aggregate data about traffic and interactions in order
              to offer better experiences and tools in the future. We may also
              use trusted third-party services that track this information on
              our behalf
            </ListItem>
          </Text>
        </OrderedList>
        <ContentContainer>
          Most Internet browsers accept cookies automatically, although you are
          able to change your browser settings to control cookies, including
          whether or not you accept them, and how to remove them. You may also
          be able to set your browser to advise you if you receive a cookie, or
          to block or delete cookies.
        </ContentContainer>
        <ContentContainer>
          If you would like to set your browser to refuse cookies, check your
          browser’s help information or visit http://www.whatarecookies.com for
          further information.
        </ContentContainer>
        <ContentContainer>
          We honor Do Not Track signals and do not track, plant cookies, or use
          advertising when a Do Not Track (DNT) browser mechanism is in place.
          Users can also choose to visit Awaken anonymously, but you may not be
          permitted to access any of the services.
        </ContentContainer>
        <Heading size="lg">How Information May Be Shared Or Disclosed</Heading>
        <ContentContainer>
          We may disclose your information if we believe that it is reasonable
          to do so in certain cases, in Our sole and exclusive discretion. Such
          a disclosure or transfer is limited to the situations when the
          personal data are required for the purposes of (1) provision of the
          services, (2) pursuing our legitimate interests, (3) law enforcement
          purposes, or (4) if you provide your prior explicit consent. Please
          note that some third parties may be located outside of the
          jurisdiction in which you reside.
        </ContentContainer>
        <ContentContainer>
          Such reasonable disclosure cases may include, but are not limited to:
        </ContentContainer>
        <UnorderedList
          style={{
            marginLeft: "1rem",
          }}
        >
          <Text>
            <ListItem>
              Satisfying any local, state, or Federal laws or regulations;
            </ListItem>
            <ListItem>
              Responding to requests, such as discovery, criminal, civil, or
              administrative process, subpoenas, court orders, or writs from law
              enforcement or other governmental or legal bodies;
            </ListItem>
            <ListItem>
              Bringing legal action against a user who has violated the law or
              violated our Terms of Services;
            </ListItem>
            <ListItem>
              As may be necessary for the operation of Awaken;
            </ListItem>
            <ListItem>
              Generally cooperating with any lawful investigation about our
              users; or
            </ListItem>
            <ListItem>
              If we suspect any fraudulent activity on Awaken or if we have
              noticed any activity which may violate our Terms or other
              applicable rules
            </ListItem>
          </Text>
        </UnorderedList>
        <Heading size="lg">Third-Parties</Heading>
        <ContentContainer>
          We may utilize third party service providers, from time to time or all
          the time, to help us with Awaken and to help to serve you. Such
          third-party service providers may include:
        </ContentContainer>
        <OrderedList
          style={{
            marginLeft: "1rem",
          }}
        >
          <Text>
            <ListItem>
              Data storage companies (e.g., Amazon Web Services and other cloud
              storage providers);
            </ListItem>
            <ListItem>
              User information logistics providers (e.g., Google Analytics or
              other analytics companies which help us track general user
              information and usage);
            </ListItem>
            <ListItem>Web hosting companies;</ListItem>
            <ListItem>Newsletter providers; and</ListItem>
            <ListItem>
              Other parties who assist us in operating Awaken and conducting our
              business
            </ListItem>
          </Text>
        </OrderedList>
        <ContentContainer>
          The third party service providers indicated above may have access to
          your personal data as part of their partnerships with us only if they
          agree to assure an adequate level of protection of personal data that
          is consistent with this Privacy Policy.
        </ContentContainer>
        <ContentContainer>
          <strong>
            We do not sell, trade, or transfer your personal data to third
            parties not indicated in this Privacy Policy, unless we obtain your
            prior explicit consent.
          </strong>
        </ContentContainer>
        <ContentContainer>
          Please note that some non-personal data may be provided to third
          parties for marketing, advertising, or other uses. We also allow
          third-party behavioral tracking which may include non-personal data.
        </ContentContainer>
        <ContentContainer>
          We are not responsible for actions of third parties that are not
          mentioned in this Privacy Policy and with whom you share your personal
          data, and we have no authority to manage or control third party
          solicitations.
        </ContentContainer>
        <Heading size="lg">Transmittals From Us</Heading>
        <ContentContainer>
          From time to time, we may send you informational communications
          related to Awaken, such as announcements or other information. You may
          also receive information from us that is specifically about your use
          of Awaken or about your account with us, including information about
          security breaches or other privacy-related matters.
        </ContentContainer>
        <ContentContainer>
          By providing any personal information to us, or by using Awaken in any
          manner, you have created a commercial relationship with us. As such,
          you agree that any email sent from us or third-party affiliates, even
          unsolicited email, shall specifically not be considered SPAM, as that
          term is legally defined.
        </ContentContainer>
        <Heading size="lg">Third Party Links</Heading>
        <ContentContainer>
          Through your use of Awaken, you may find links out to other websites
          or mobile applications. This Privacy Policy does not and will not
          apply to any of those linked websites or applications. We are not
          responsible in any manner for the content or privacy and security
          practices and policies of any third parties, including other websites,
          services or applications that may be linked to or from Awaken.
        </ContentContainer>
        <ContentContainer>
          Before visiting and providing any information to any such third-party
          websites and applications, you should familiarize yourself with the
          applicable privacy practices and take reasonable steps necessary to
          protect your personal data.
        </ContentContainer>
        <Heading size="lg">Your Rights</Heading>
        <ContentContainer>
          You may choose to forego the provision of certain personal data to us,
          but you may then be prohibited from using Awaken or any of the
          services thereon. We collect the information we do to maintain the
          integrity of Awaken and to help to ensure the information security of
          the Parties. If you do not agree to any of the sections or subsections
          within this Privacy Policy, you must cease your use of Awaken
          entirely.
        </ContentContainer>
        <Heading size="lg">
          Accessing, Correcting, and Erasing Personal Data
        </Heading>
        <ContentContainer>
          We take reasonable steps to ensure that the personal data collected
          and processed by us is accurate, complete, and up-to-date. Thus, we
          ask you to keep your personal data as current as possible and update
          your personal data through Awaken if necessary.
        </ContentContainer>
        <ContentContainer>
          You may at any time review or change the personal data in your user
          account or terminate your user account by logging into it through
          Awaken and updating your personal data. Upon your request to terminate
          your user account, we will deactivate or delete your account and
          information from our active databases.
        </ContentContainer>
        <ContentContainer>
          If you would like to access, modify, or delete your personal data that
          are processed through Awaken, please send us an email to
          team@awaken.tax. We will answer your request within a reasonable
          timeframe.
        </ContentContainer>
        <Heading size="lg">Children</Heading>
        <ContentContainer>
          We do not market to any individual under the age of 18 years old.
          Individuals under 18 years of age may not create an account on Awaken.
          However, we cannot distinguish the age of people accessing Awaken. If
          a person under 18 has provided us with personal data without obtaining
          parental or guardian consent in advance, the parent or guardian may
          contact us at team@awaken.tax requesting us to destroy or de-identify
          the personal data.
        </ContentContainer>
        <Heading size="lg">Updates, Modifications, or Revisions</Heading>
        <ContentContainer>
          We are committed to conducting our business in accordance with the
          principles stated in this Privacy Policy in order to ensure that the
          confidentiality of your personal data is protected and maintained.
          However, we may change this Privacy Policy from time to time at our
          sole and exclusive discretion.
        </ContentContainer>
        <ContentContainer>
          Unless we specifically obtain your consent, any changes to the Privacy
          Policy will apply to the personal data collected on or after the date
          of the last amendment that is indicated at the end of the Privacy
          Policy.
        </ContentContainer>
        <ContentContainer>
          It is your responsibility to periodically check this Privacy Policy
          for any such modification, revision or amendment. Any changes made are
          assumed to have been accepted by your continued use of Awaken.
        </ContentContainer>
        <ContentContainer>
          For significant changes in the Privacy Policy, or where required by
          the applicable law, we may seek your explicit consent to the changes
          stated in the Privacy Policy.
        </ContentContainer>
        <Heading size="lg">Complaints about Handling of Personal Data</Heading>
        <ContentContainer>
          You have the right to submit a complaint to us about the way in which
          your personal data have been handled by using the contact details
          indicated in the “Contact Us” section of this Privacy Policy.
        </ContentContainer>
        <ContentContainer>
          After you submit such a complaint, we will send you an email within
          five business days confirming that we have received your complaint.
          Afterwards, we will investigate your complaint and provide you with
          our response within a reasonable timeframe.
        </ContentContainer>
        <ContentContainer>
          If you are a resident of the European Union and you are not satisfied
          with the outcome of your complaint, you have the right to lodge a
          complaint with your local data protection authority.
        </ContentContainer>
        <Heading size="lg">Change of Control</Heading>
        <ContentContainer>
          If the ownership of our business changes, we may transfer your
          information to the new owner so they can continue to operate Awaken
          and provide the Services. The new owners will be obliged to comply
          with this Policy.
        </ContentContainer>
        <Heading size="lg">Changes to our Policy</Heading>
        <ContentContainer>
          Any changes we may make to this Policy will be posted on this page.
          Where it makes sense because the changes are material, we will notify
          you by email or in another appropriate manner such as when you next
          interact with Awaken.
        </ContentContainer>
      </Box>
    </NavBarPageTemplate>
  );
}

export default Privacy;
