import { Image } from "@chakra-ui/react";
import { Maybe } from "src/core";

type Props = {
  logoUrl: Maybe<string> | undefined;
};

export const WalletHeaderImage = ({ logoUrl }: Props) => {
  if (!logoUrl) return null;
  return (
    <Image
      style={{ borderRadius: 3 }}
      src={logoUrl}
      w="2rem"
      h="2rem"
      marginRight="1rem"
      float="left"
    />
  );
};
