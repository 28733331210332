import { useEffect, useRef, useState } from "react";

export const useIsInViewport = () => {
  const [isInViewport, setIsInViewport] = useState(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref?.current) {
        const top = ref.current.getBoundingClientRect().top;
        const bottom = ref.current.getBoundingClientRect().bottom;
        const isVisible = top >= 0 && bottom <= window.innerHeight;
        setIsInViewport(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { isInViewport, ref };
};
