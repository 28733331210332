import {
  Button,
  Container,
  Textarea,
  Text,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import { useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import SecondaryText from "src/components/styled/SecondaryText";
import { colors } from "src/theme";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  capLoss: string;
};

function _CapitalLossModal({ handleHide, show: isVisible, capLoss }: Props) {
  const positiveGains = (capLoss || "").replace("-", "");
  const theme = useTheme();

  return (
    <Modal
      title={`How to write-off ${positiveGains} of capital gains for future tax years`}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          variant="primary"
          style={{
            width: "100%",
          }}
          onClick={handleHide}
        >
          Close
        </Button>
      }
    >
      <Text color={theme.text}>
        You have a {positiveGains} loss on crypto/NFTs this year. What few
        people know is that capital losses actually offset your future capital
        gains until exhaustion. That means you wouldn't have to pay taxes on{" "}
        {positiveGains} of capital gains tax in future years if you file your
        losses this year. You may also be able to use these capital losses to
        offset $3,000 of your income.
      </Text>
      <br />
      <Text color={theme.text}>
        If you are interested in learning more, you can read the articles below.
      </Text>
      <br />
      <UnorderedList>
        <ListItem color={theme.text}>
          <a
            target="_blank"
            style={{
              color: colors.primary,
              textDecoration: "underline",
            }}
            href="https://www.thebalancemoney.com/can-a-capital-loss-carryover-to-the-next-year-2388983"
          >
            <Text>The Balance Money</Text>
          </a>
        </ListItem>
        <ListItem color={theme.text}>
          <a
            target="_blank"
            style={{
              color: colors.primary,
              textDecoration: "underline",
            }}
            href="https://www.investopedia.com/terms/c/capitalloss.asp#:~:text=A%20capital%20loss%20is%20a,amount%20of%20the%20capital%20loss."
          >
            <Text>Investopedia</Text>
          </a>
        </ListItem>
      </UnorderedList>
      <br />
      <SecondaryText
        text="Disclaimer: This is not tax advice. It is recommended that you seek the
        advice of a tax professional or refer to the tax laws in your
        jurisdiction for guidance on your specific tax obligations in regards to
        cryptocurrency transactions."
      />
    </Modal>
  );
}

export const CapitalLossModal = connectModal({
  name: "CapitalLossModal",
})(_CapitalLossModal);
