import { Button, Container } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMyToast } from "src/hooks";
import { Input, Option, Select } from "src/components/styled";
import _ from "lodash";
import { RuleTypeEnum } from "src/api/generated/types";
import { Maybe } from "src/core";
import { api, BaseTransactionFullFields } from "src/api";
import { useMutation } from "@apollo/client";

type Props = InjectedProps & {
  transaction: BaseTransactionFullFields;
};

const RULE_OPTIONS = [
  {
    label: "🦧 NFT Buy",
    value: RuleTypeEnum.V1NftBuy_1,
  },
  {
    label: "🦧 NFT Sell",
    value: RuleTypeEnum.V1NftSell_1,
  },
  {
    label: "🔁 Swap",
    value: RuleTypeEnum.V1Swap_1,
  },
  {
    label: "🤑 Claim Rewards",
    value: RuleTypeEnum.V1ClaimRewards,
  },
  {
    label: "🥩 Staking",
    value: RuleTypeEnum.V1Staking,
  },
  {
    label: "🥩 Unstaking WITH Rewards",
    value: RuleTypeEnum.V1UnstakingWithRewards,
  },
  {
    label: "🥩 Unstaking WITHOUT Rewards",
    value: RuleTypeEnum.V1UnstakingWithoutRewards,
  },
];

function _CreateGlobalRuleModal({
  handleHide,
  transaction,
  show: isVisible,
}: Props) {
  const toast = useMyToast();
  const [rule, setRule] = useState<Maybe<Option>>(null);
  const [label, setLabel] = useState("");
  const [createRule, { loading }] = useMutation(api.rules.create);

  async function _onClickButton() {
    try {
      throw new Error(
        "You can't create general rules, since clients won't be able to delete them"
      );

      if (!transaction || !rule) {
        throw new Error("Must select transaction and rule type");
      }

      await createRule({
        variables: {
          transactionId: transaction.id,
          ruleType: rule?.value,
          label: label,
        },
        // refetchQueries: [api.accounts.me, api.clients.accounts],
      });

      toast.show({
        message: "Successfully created rule. Re-run graph to apply it",
        status: "success",
      });

      handleHide();
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  }

  if (!transaction) {
    return null;
  }

  return (
    <Modal
      title="Create Rule"
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          onClick={_onClickButton}
          disabled={!rule}
          isLoading={loading}
        >
          Create Rule
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Select
          label="Transaction Hash (Ethereum)"
          value={{
            label: transaction.txnHash || "none",
            value: transaction.txnHash || "none",
          }}
          selectProps={{
            isDisabled: true,
          }}
        />
        <Select
          label="Rule"
          options={RULE_OPTIONS}
          value={rule}
          selectProps={{
            onChange: (o) => setRule(o as Maybe<Option>),
          }}
        />
        <Input
          label="Label"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />
      </Container>
    </Modal>
  );
}

export const CreateGlobalRuleModal = connectModal({
  name: "CreateGlobalRuleModal",
})(_CreateGlobalRuleModal);
