import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { theme } from "src/theme";
import { apolloClient } from "./api/apollo";
import { Navigation } from "./navigation";
import { persistor, store } from "./redux";

// Antd partial styles
import "antd/lib/message/style/css";
import "antd/lib/notification/style/css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { getMetadataInfo } from "./utils/whitelabel";

const queryClient = new QueryClient();

(window as any).mixpanel?.init("168390d8de2db771932bdeed5e6198c3", {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
});

// initSentry();
// LogRocket.init(config.logrocket);

const App = () => {
  const metadata = getMetadataInfo();

  return (
    <>
      <Helmet>
        {/* set the favicon */}
        {metadata?.name && <title>{metadata?.name}</title>}
        {metadata?.favicon && <link rel="icon" href={metadata.favicon} />}
        {metadata?.description && (
          <meta name="description" content={metadata.description} />
        )}
        {metadata?.logoUrl && (
          <meta name="image" property="og:image" content={metadata.logoUrl} />
        )}

        {metadata?.logoUrl && (
          <meta
            name="image"
            property="og:image:secure_url"
            content={metadata.logoUrl}
          />
        )}
      </Helmet>
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <ApolloProvider client={apolloClient}>
              <Provider store={store}>
                <PersistGate persistor={persistor}>
                  <Navigation />
                </PersistGate>
              </Provider>
            </ApolloProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </>
  );
};

export default App;
