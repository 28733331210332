import {
  Button,
  Text,
  UnorderedList,
  ListItem,
  ButtonGroup,
  VStack,
  theme,
} from "@chakra-ui/react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import SecondaryText from "src/components/styled/SecondaryText";
import { colors } from "src/theme";
import { getGainsLossesLink } from "src/modules/ledger/transactions";
import { useNavigate, useParams } from "react-router";
import { ReportExportTypeEnum } from "src/api/generated/types";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  numImportantUnlabeled: number;
  exportReport: (
    clientId: string,
    type: ReportExportTypeEnum,
    year: string
  ) => Promise<void>;
  type: ReportExportTypeEnum;
  year: string;
};

function _ReportExportWarningModal({
  handleHide,
  show: isVisible,
  numImportantUnlabeled,
  exportReport,
  type,
  year,
}: Props) {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const toast = useMyToast();
  const theme = useTheme();

  const _tryExportReport = async () => {
    try {
      handleHide();
      await exportReport(clientId || "", type, year);
    } catch (err) {
      console.error(err);
      return toast.show({
        message:
          "Error exporting report! Message support and we'll help you out!",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title={`Your reports might be inaccurate`}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <VStack w="100%">
          <Button
            variant="primary"
            style={{
              width: "100%",
            }}
            onClick={() => {
              handleHide();
              navigate(
                getGainsLossesLink({
                  clientId: clientId || "",
                  reviewed: false,
                })
              );
            }}
          >
            Label Transactions
          </Button>
          <Button
            variant="secondary"
            style={{
              width: "100%",
            }}
            onClick={_tryExportReport}
          >
            Export Anyways
          </Button>
        </VStack>
      }
    >
      <Text color={theme.text}>
        You have <strong>{numImportantUnlabeled}</strong> unlabeled transaction
        {numImportantUnlabeled === 1 ? "" : "s"} with high capital gains/losses.
        You should review {numImportantUnlabeled === 1 ? "it" : "them"} to
        ensure you have the most accurate tax reports.
      </Text>
    </Modal>
  );
}

export const ReportExportWarningModal = connectModal({
  name: "ReportExportWarningModal",
})(_ReportExportWarningModal);
