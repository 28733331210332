import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getAuth } from "firebase/auth";
import { config } from "../config";

export const app = initializeApp({
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  measurementId: config.firebase.measurementId,
});

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(config.recaptcha.token),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

export const auth = getAuth(app);

export async function getAuthToken(): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      currentUser.getIdToken().then(resolve).catch(reject);
    } else {
      return resolve(null);
    }
  });
}
