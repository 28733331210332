import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import _ from "lodash";
import { isNil } from "lodash/fp";
import numbro from "numbro";
import { useParams } from "react-router-dom";
import { BaseLedgerEntryLinkWithCapacityFields } from "src/api/fragments";
import { LedgerEntryLinkWithCapacity } from "src/api/generated/types";
import { ReviewStatusTag } from "src/components/Labels/ReviewStatusTag";
import { Maybe } from "src/core";
import { useTheme } from "src/hooks/useTheme";
import { isReviewed } from "src/modules/ledger/transactions";
import { colors } from "src/theme";
import Helpers, { D } from "src/utils/helpers";
import { useCreatedAtUTC } from "../utils";

export const LinksTable = ({
  assetCreditId,
  links,
  timezone,
}: {
  assetCreditId: Maybe<string>;
  links: LedgerEntryLinkWithCapacity[];
  timezone: string;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const baseLink = `/clients/${clientId}/transactions`;
  const theme = useTheme();

  return (
    <Box w="100%">
      <Box w="100%" maxW="100%">
        <Table border="none" w="100%" maxW="100%" overflowY="scroll">
          <Thead bg={theme.secondaryBackground}>
            <Tr fontSize="xs">
              <Th borderColor={theme.border} color={theme.header} fontSize="xs">
                Type
              </Th>
              <Th borderColor={theme.border} color={theme.header} fontSize="xs">
                Transaction
              </Th>
              <Th borderColor={theme.border} color={theme.header} fontSize="xs">
                Status
              </Th>
              <Th borderColor={theme.border} color={theme.header} fontSize="xs">
                Amount
              </Th>
              <Th
                borderColor={theme.border}
                color={theme.header}
                padding="0"
                w="15px"
              />
              <Th borderColor={theme.border} color={theme.header} fontSize="xs">
                Price
              </Th>
              <Th
                borderColor={theme.border}
                color={theme.header}
                padding="0"
                w="15px"
              />
              <Th
                borderColor={theme.border}
                color={theme.header}
                textAlign="right"
                fontSize="xs"
              >
                Value
              </Th>
            </Tr>
          </Thead>
          <Tbody bg={theme.background}>
            {links
              .slice(0, 100) // FIXME: hack because some clients have just soooo many links
              .filter((l) => !isNil(l.fiatAmountUsed))
              .map((link) => (
                <LinkRow
                  timezone={timezone}
                  baseLink={baseLink}
                  key={link.id}
                  link={link}
                />
              ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

const LinkRow = ({
  link,
  baseLink,
  timezone,
}: {
  link: BaseLedgerEntryLinkWithCapacityFields;
  baseLink: string;
  timezone: string;
}) => {
  const theme = useTheme();
  const cryptoAmountUsed = new BigNumber(link.cryptoAmountUsed || 0).toNumber();

  const transaction = link.transaction || link.to?.transaction;
  const createdAt = useCreatedAtUTC(transaction ?? null, timezone);

  return (
    <Tr>
      <Td
        fontSize="sm"
        color={theme.header}
        bg={theme.background}
        borderBottom={theme.border}
      >
        {link.type?.split("_").map(Helpers.capitalize).join(" ")}
      </Td>
      <Td
        fontSize="sm"
        color={theme.header}
        bg={theme.background}
        borderBottom={theme.border}
      >
        {transaction ? (
          <>
            <a
              target="_blank"
              style={{
                color: colors.primary,
                textDecoration: "underline",
              }}
              href={`${baseLink}?transactionId=${transaction?.id}`}
            >
              {_.truncate(
                transaction?.title || createdAt?.toFormat("M/dd/yyyy")
              )}{" "}
            </a>
            <Text fontSize="xs">
              {createdAt?.toFormat("M/dd/yyyy")}{" "}
              {transaction?.txnHash
                ? `(${transaction?.txnHash.slice(0, 5)}...)`
                : ""}
            </Text>
          </>
        ) : (
          <span>—</span>
        )}
      </Td>
      <Td
        fontSize="sm"
        color={theme.header}
        bg={theme.background}
        borderBottom={theme.border}
      >
        {transaction ? (
          <ReviewStatusTag
            isReviewed={isReviewed(transaction)}
            needsRecalculate={
              transaction.needsReview?.needsRecalculate || false
            }
            capPriority={transaction?.capPriority || null}
          />
        ) : (
          <span>—</span>
        )}
      </Td>

      <Td
        fontSize="sm"
        color={theme.header}
        bg={theme.background}
        borderBottom={theme.border}
      >
        +{numbro(cryptoAmountUsed).format("0,0.[000000000000]")}
      </Td>

      <Td
        padding="0"
        w="15px"
        textAlign="right"
        fontSize="sm"
        color={theme.header}
        bg={theme.background}
        borderBottom={theme.border}
      >
        &#215;
      </Td>
      <Td
        fontSize="sm"
        color={theme.header}
        bg={theme.background}
        borderBottom={theme.border}
      >
        {cryptoAmountUsed > 0
          ? D(
              link.fiatAmountUsed / cryptoAmountUsed,
              link.fiatCurrency
            ).toFormat()
          : "-"}
      </Td>
      <Td
        padding="0"
        w="15px"
        textAlign="right"
        fontSize="sm"
        color={theme.header}
        bg={theme.background}
        borderBottom={theme.border}
      >
        =
      </Td>
      <Td
        textAlign="right"
        fontSize="sm"
        color={theme.header}
        bg={theme.background}
        borderBottom={theme.border}
      >
        {D(link.fiatAmountUsed, link.fiatCurrency).toFormat()}
      </Td>
    </Tr>
  );
};
