import { GenerateCsvFlow } from "./components/GenerateCsvFlow";
import {
  Box,
  ChakraProvider,
  Heading,
  HStack,
  position,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { noop } from "lodash";
import { useState } from "react";
import { useLocalStorage } from "src/hooks/common";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { AwakenTooltip, Info } from "src/components";
import StatusTag from "src/components/styled/StatusTag";
import { Touchable } from "src/components/Touchable";

export default function TaxGPT() {
  const theme = useTheme();
  const [section, setSection] = useState<"csv" | null>(null);
  const isLarge = useIsLargeScreen();

  return (
    <div style={{ marginTop: 25 }}>
      <HStack>
        <Box flex={1}>
          {section && (
            <Touchable
              style={{ marginBottom: 20, display: "inline-block" }}
              onClick={() => setSection(null)}
              label="Go back"
              iconName="fa-sharp fa-arrow-left"
              iconPosition="left"
            />
          )}

          <Heading
            color={theme.header}
            padding="0"
            margin={0}
            size="lg"
            marginRight="0.5rem"
          >
            TaxGPT
          </Heading>

          <Text color={theme.text} style={{ marginTop: 10 }}>
            An AI helper to make taxes a lil less painful.
          </Text>
        </Box>
      </HStack>

      {!section && (
        <div
          style={{
            justifyContent: "center",
            width: "100%",
            marginTop: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Text
            textAlign="center"
            fontSize="3xl"
            color={theme.header}
            fontWeight="bold"
            style={{ marginBottom: 15 }}
          >
            How can we help you?
          </Text>

          <HStack
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: isLarge ? "row" : "column",
            }}
          >
            <Section
              title="Data formatting"
              onClick={() => setSection("csv")}
              description="Ex. can you help me format a CSV file from my exchange?"
              iconColor={colors.green60}
              iconName="fa-file-csv"
            />

            <a
              href="https://chatgpt.com?q=You are an expert in cryptocurrency taxes. I have questions regarding my crypto taxes."
              target="_blank"
            >
              <Section
                title="Crypto tax questions"
                onClick={noop}
                description="Ex. how is staking on Solana taxed in the US?"
                iconColor={colors.pink70}
                component={
                  <div
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      background: theme.secondaryBackground,
                      fontSize: 14,
                      padding: "5px 10px",
                      paddingRight: 15,
                      borderRadius: 100,
                      color: theme.text,
                    }}
                  >
                    ChatGPT{" "}
                    <Info message="ChatGPT is extremely good for answering tax questions, especially now that it incorporates web results. We recommend asking questions like 'My wallet was hacked, can I write off the losses?' directly to it. Note: if you need tax advice, consult a CPA." />
                  </div>
                }
                iconName="fa-question-circle"
              />
            </a>

            {/* <Section
              onClick={noop}
              isComingSoon
              title="AI bookkeeping"
              description="Ex. what should I label these 5 transactions"
              iconColor={colors.lightBlue70}
              iconName="fa-book"
            /> */}
          </HStack>
        </div>
      )}

      {section === "csv" && <GenerateCsvFlow />}
    </div>
  );
}

const Section = ({
  title,
  isComingSoon,
  description,
  iconColor,
  iconName,
  onClick,
  component,
}: {
  iconName: string;
  iconColor: string;
  title: string;
  isComingSoon?: boolean;
  description: string;
  onClick: any;
  component?: React.ReactNode;
}) => {
  const theme = useTheme();
  const isLarge = useIsLargeScreen();

  return (
    <Box
      style={{
        margin: 5,
        padding: 20,
        borderRadius: 20,
        // flex: 1,
        // height: 135,
        width: isLarge ? 275 : "100%",
        border: `2px solid ${theme.border}`,
        cursor: isComingSoon ? "not-allowed" : "pointer",
        position: "relative",
      }}
      onClick={onClick}
      bg={theme.background}
      _hover={{
        background: theme.medBackground,
      }}
    >
      {component}
      {isComingSoon && (
        <AwakenTooltip message="Coming soon">
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 12,
            }}
          >
            <StatusTag type="info" label="Soon 👀" />
          </div>
        </AwakenTooltip>
      )}

      <i
        style={{
          color: iconColor || colors.primary,
          fontSize: 18,
          marginBottom: 10,
        }}
        className={`fa-sharp ${iconName}`}
      ></i>

      <Text fontSize="md" style={{ marginBottom: 5 }} color={theme.header}>
        {title}
      </Text>

      <Text fontSize="sm" color={theme.text}>
        {description}
      </Text>
    </Box>
  );
};
