import { HistoricalBalanceIntervalEnum } from "src/api/generated/types";
import { Maybe } from "src/core";
import * as moment from "moment-timezone";
import { Time } from "lightweight-charts";

export type TimeFrame = {
  label: string;
  type: HistoricalBalanceIntervalEnum;
};

// ex. h:mma not just date
export const SHOW_TIMESTAMP = new Set<string>([
  HistoricalBalanceIntervalEnum.Day,
  HistoricalBalanceIntervalEnum.Week,
  HistoricalBalanceIntervalEnum.Month,
]);

export const TIME_FRAMES = [
  {
    label: "1D",
    type: HistoricalBalanceIntervalEnum.Day,
  },
  {
    label: "1W",
    type: HistoricalBalanceIntervalEnum.Week,
  },
  {
    label: "1M",
    type: HistoricalBalanceIntervalEnum.Month,
  },
  {
    label: "3M",
    type: HistoricalBalanceIntervalEnum.ThreeMonth,
  },
  {
    label: "YTD",
    type: HistoricalBalanceIntervalEnum.YearToDate,
  },
  {
    label: "1Y",
    type: HistoricalBalanceIntervalEnum.Year,
  },
  {
    label: "ALL",
    type: HistoricalBalanceIntervalEnum.All,
  },
];

export const DEFAULT_TF = TIME_FRAMES.find(
  (d) => d.type === HistoricalBalanceIntervalEnum.YearToDate
)!;

export const timeFormat = (timezone: string) => (time: Time) => {
  const timeFormatted = moment
    .tz(parseInt((time || 0).toString()) * 1000, timezone)
    .format("h:mma, MMM D");
  return timeFormatted;
};

export const getTimeFormat = (
  timezone: string,
  timeFrame: Maybe<TimeFrame>
) => {
  if (timeFrame?.type === HistoricalBalanceIntervalEnum.Day) {
    return {
      dateFormat: null,
      timeFormat: (time: Time) => {
        const timeFormatted = moment
          .tz(parseInt((time || 0)?.toString()) * 1000, timezone)
          .format("h:mma");
        return timeFormatted;
      },
    };
  }
  if (timeFrame?.type === HistoricalBalanceIntervalEnum.Week) {
    return {
      dateFormat: null,
      timeFormat: timeFormat(timezone),
    };
  }
  if (timeFrame?.type === HistoricalBalanceIntervalEnum.Month) {
    return {
      dateFormat: null,
      timeFormat: timeFormat(timezone),
    };
  }
  return { dateFormat: "MMM dd, yyyy", timeFormat: null };
};
