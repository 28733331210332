import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { show } from "redux-modal";
import WhiteBox from "src/components/styled/WhiteBox";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { orderBy } from "lodash";
import { compose, isNil } from "lodash/fp";
import moment from "moment";
import { isMobile } from "react-device-detect";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { api } from "src/api";
import {
  AccountInfoSummary,
  AccountIntegrationStatusEnum,
  CountNotDoneResponse,
  GetIncomeAndCapGainsResponse,
  NumTxnsResponse,
  Query,
  QueryGetIncomeAndCapGainsArgs,
  TaxYearBracket,
} from "src/api/generated/types";
import { Button, Info, RecalculateButton, Select } from "src/components";
import { CapitalLossModal } from "src/components/modals/CapitalLossModal";
import StatusTag from "src/components/styled/StatusTag";
import { Warning } from "src/components/Warning/Warning";
import { CURRENT_TAX_YEAR, CURRENT_TAX_YEAR_STR } from "src/config";
import { Maybe } from "src/core";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { useActiveSubscription } from "src/hooks/useActiveSubscription";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import {
  getGainsLossesLink,
  getIncomeLink,
} from "src/modules/ledger/transactions";
import { colors } from "src/theme";
import { CURRENCY_REGEX, D } from "src/utils/helpers";
import Loading from "src/views/Loading";
import { CPAsBox } from "./CPAsBox";
import { ReportDownloadOptions } from "./ReportDownloadOptions";
import { ReportsTable } from "./ReportsTable";

export const MAX_REPORTS_WIDTH_NUM = 60;
const MAX_REPORTS_WIDTH = `${MAX_REPORTS_WIDTH_NUM}rem`;

const getDefaultYear = (
  getTaxYears: Query["getTaxYearBrackets"]
): Maybe<TaxYearBracket> => {
  if (!getTaxYears || getTaxYears.length === 0) return null;
  if (getTaxYears.length === 1) return getTaxYears[0];

  const currentTaxYear = getTaxYears.find(
    (t) => t.taxYear === CURRENT_TAX_YEAR.toString()
  );

  if (currentTaxYear) return currentTaxYear;

  return getTaxYears[getTaxYears.length - 1];
};

function Taxes() {
  const { clientId } = useParams<{ clientId: string }>();
  const { client, accounts } = useClientById(clientId || "");
  const { canDownload, totalAvailable, totalUsed, subscription } =
    useActiveSubscription(clientId || "");
  const toast = useMyToast();

  const timezone = client?.timezone;

  const [_getTaxYears, { data: taxYearsData, loading: loadingTaxYears }] =
    useLazyQuery<{
      getTaxYearBrackets: Query["getTaxYearBrackets"];
    }>(api.reports.getTaxYearBrackets, {
      fetchPolicy: "cache-and-network",
    });

  const [
    countNotDone,
    {
      data: countNotDoneData,
      networkStatus: countNotDoneNetworkStatus,
      loading: loadingNotDone,
    },
  ] = useLazyQuery<{
    countNotDone: CountNotDoneResponse;
  }>(api.transactions.countNotDone, {
    fetchPolicy: "cache-and-network",
  });

  const [updateClient] = useMutation(api.clients.update);

  const [yearBracket, setTaxYearBracket] = useState<Maybe<TaxYearBracket>>(
    getDefaultYear(taxYearsData?.getTaxYearBrackets || [])
  );

  const hasOutOfDateAccounts = accounts.some(
    (a) => a.integrationStatus !== AccountIntegrationStatusEnum.Current
  );

  const {
    data: numTxnsData,
    networkStatus: numTxnsStatus,
    loading: loadingNumTxns,
  } = useQuery<{
    getNumTxns?: NumTxnsResponse;
  }>(api.transactions.countTransactions, {
    fetchPolicy: "cache-and-network",
    variables: {
      clientId,
    },
  });

  useEffect(() => {
    if (!clientId) return;
    getTaxYears();
    countNotDone({
      variables: {
        clientId,
      },
    });
  }, [clientId]);

  const yearOptions = useMemo(() => {
    if (!taxYearsData?.getTaxYearBrackets) return [];
    return orderBy(
      taxYearsData.getTaxYearBrackets.map((bracket) => ({
        label: bracket.taxYear,
        value: bracket,
      })),
      (v) => parseFloat(v.value.taxYear),
      "desc"
    );
  }, [taxYearsData]);

  const getTaxYears = async () => {
    const taxYearsResp = await _getTaxYears({
      variables: { clientId },
    });
    // set default year to most recent year
    setTaxYearBracket(
      getDefaultYear(taxYearsResp.data?.getTaxYearBrackets || [])
    );
  };

  // variables
  const yearValueOption = yearBracket
    ? {
        label: yearBracket.taxYear,
        value: yearBracket,
      }
    : null;

  const numImportantUnlabeled = numTxnsData?.getNumTxns
    ? numTxnsData.getNumTxns.high
    : 0;

  const { text, header, background, border } = useTheme();

  if (
    (loadingTaxYears && !taxYearsData) ||
    // (loadingNotDone && !countNotDoneData) ||
    (loadingNumTxns && !numTxnsData)
  ) {
    return <Loading />;
  }

  if (yearOptions.length === 0) {
    return (
      <Box w="100%">
        <Heading color={header} size="lg" padding="1rem 0" margin="0">
          Taxes
        </Heading>
        <Text color={text}>
          Please connect your accounts and review your transactions to see your
          tax reports.
        </Text>
      </Box>
    );
  }

  return (
    <div
    // style={{
    //   padding: "1rem 2.5rem",
    // }}
    >
      <HStack alignItems="center" padding="1rem 0">
        <Flex flex={1}>
          <Heading color={header} size="lg" margin="0">
            Taxes
          </Heading>
          <Box display="flex" margin="0 0 0 1rem">
            <Select
              options={yearOptions}
              value={yearValueOption as any}
              selectProps={{
                styles: {
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: background,
                    borderColor: border,
                  }),
                  indicatorSeparator: (provider) => ({
                    ...provider,
                    display: "none",
                  }),
                  placeholder: (p) => ({
                    ...p,
                    color: text,
                  }),
                  singleValue: (p) => ({ ...p, color: header }),
                },
                onChange: (o: any) =>
                  setTaxYearBracket(o.value as Maybe<TaxYearBracket>),
              }}
              containerStyle={{
                width: "6rem",
                marginBottom: 0,
                backgroundColor: background,
              }}
            />
          </Box>
        </Flex>

        {/* <EmailMonthlyBooks /> */}
      </HStack>

      <HStack>
        <Heading flex={1} size="md" color={header}>
          Tax Summary
        </Heading>
      </HStack>

      <RecalculateWarning countNotDone={countNotDoneData?.countNotDone} />
      <TaxReportPeriod year={yearBracket} />
      <Box display="flex" flexWrap="wrap">
        <TaxSummary
          year={yearBracket}
          numImportantUnlabeled={numImportantUnlabeled}
        />
      </Box>
      {/* TODO: this is too slow / cpu intensive, hurts the server atm <AssetTaxes yearBracket={yearBracket} /> */}

      <ReportDownloadOptions
        year={yearBracket?.taxYear || null}
        totalTxnForYear={totalUsed}
        hasPaidForPeriod={canDownload}
        maxWNum={MAX_REPORTS_WIDTH_NUM}
        numImportantUnlabeled={numImportantUnlabeled}
        hasOutOfDateAccounts={hasOutOfDateAccounts}
      />

      <ReportsTable maxWNum={MAX_REPORTS_WIDTH_NUM} />

      <CPAsBox />
    </div>
  );
}

const EmailMonthlyBooks = () => {
  const { me } = useMe();
  const theme = useTheme();
  const { clientId } = useParams();
  const [getMonthlySummary, { data }] = useLazyQuery<
    Pick<Query, "getMonthlySummary">
  >(api.reports.monthlySummary);
  const currentMonthNum = new Date().getMonth();
  const [month, setMonth] = useState<number>(currentMonthNum - 1);
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );

  const yearOptions = useMemo(() => {
    const y = [2023, 2024, 2025, 2026];

    return y.map((year) => ({
      label: year.toString(),
      value: year,
    }));
  }, []);

  const _onPress = async () => {
    try {
      const summary = await getMonthlySummary({
        variables: {
          clientId: clientId || "",
          year: selectedYear.toString(),
          month: month + 1,
        },
        fetchPolicy: "no-cache",
      });

      const error = summary?.error;

      if (error) {
        alert(error.message);
        return;
      }

      const email = summary?.data?.getMonthlySummary?.email || "";

      window.open(email, "_blank");
    } catch (err) {
      console.error(err);
      alert("Error getting summary");
    }
  };

  const transactionUrl = useMemo(() => {
    const startDate = moment()
      .year(selectedYear)
      .month(month)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment()
      .year(selectedYear)
      .month(month)
      .endOf("month")
      .format("YYYY-MM-DD");

    const url = `/clients/${clientId}/transactions?startDate=${startDate}&endDate=${endDate}`;

    return url;
  }, [selectedYear, month]);

  if (!me || !me?.isSuperuser) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 50,
        right: 30,
        width: 300,
        zIndex: 100,
        border: `1px solid ${theme.border}`,
        backgroundColor: theme.background,
        borderRadius: 15,
        padding: 10,
        // add a shadow
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      }}
    >
      <VStack
        style={{
          alignItems: "flex-start",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        <div>
          <Text fontSize={16} marginBottom="5px" color={theme.header}>
            Monthly Books Email
          </Text>

          {/* selector for month of year */}
          <HStack style={{ width: "100%" }}>
            <Select
              containerStyle={{ width: 125, marginTop: 5 }}
              selectProps={{
                onChange: (o: any) => {
                  setMonth(o.value - 1);
                },
                value: {
                  label: moment().month(month).format("MMMM"),
                  value: month + 1,
                },
              }}
              options={Array.from({ length: 12 }, (_, i) => ({
                label: moment().month(i).format("MMMM"),
                value: i + 1,
              }))}
            />

            {/* year selector for all the tax years */}
            <Select
              containerStyle={{ marginTop: 5 }}
              selectProps={{
                onChange: (o: any) => {
                  setSelectedYear(o.value);
                },
                value: {
                  label: selectedYear.toString(),
                  value: selectedYear,
                },
              }}
              options={yearOptions}
            />
          </HStack>

          <Link
            style={{
              color: theme.header,
              fontSize: 14,
            }}
            to={transactionUrl}
          >
            Review for {moment().month(month).format("MMMM")}{" "}
            <i className="fa-sharp fa-link" />
          </Link>
        </div>

        <Button
          bg={colors.primary + " !important"}
          _hover={{ bg: colors.primary }}
          _active={{ bg: colors.primary }}
          style={{
            marginTop: 15,
            background: colors.primary,
            color: colors.white,
            width: "100%",
            alignSelf: "flex-end",
            height: 40,
            fontSize: 14,
          }}
          onClick={_onPress}
          variant="primary"
        >
          Send
        </Button>

        {data?.getMonthlySummary?.unlabeledTransactionUrl && (
          <a
            target="_blank"
            style={{
              marginTop: 15,
              color: colors.white,
              fontSize: 16,
              textDecoration: "underline",
            }}
            href={data.getMonthlySummary.unlabeledTransactionUrl}
          >
            Review unlabeled transactions
          </a>
        )}
      </VStack>
    </div>
  );
};

const RecalculateWarning = ({
  countNotDone,
}: {
  countNotDone?: Maybe<CountNotDoneResponse>;
}) => {
  if (!countNotDone) return <div />;

  let message: Maybe<JSX.Element> = null;
  // this is misleading bc u prob just need to recalc. removing bc people are getting confused
  // if (countNotDone.importing > 0) {
  //   message = (
  //     <Box>
  //       Some of your transactions are still importing. It is best to pull your
  //       tax reports once they've finished.
  //     </Box>
  //   );
  // } else
  if (countNotDone.dirty > 0) {
    message = (
      <Box display="flex" alignItems="center">
        <Box marginRight="1rem">
          Some of your transactions are not up-to-date. To pull your latest tax
          reports, please press this button:
        </Box>
        <RecalculateButton />
      </Box>
    );
  } else if (countNotDone.isDirty) {
    message = (
      <Box display="flex" alignItems="center">
        <Box marginRight="1rem">
          Your tax reports may be out of date due to recent edits. Please press
          this button:
        </Box>
        <RecalculateButton />
      </Box>
    );
  }

  if (message) {
    return (
      <Warning
        message={message}
        maxW={MAX_REPORTS_WIDTH}
        w="100%"
        maxWidth={"600px"}
        marginBottom="2rem"
      />
    );
  } else return <div />;
};

const TaxReportPeriod = ({ year }: { year: Maybe<TaxYearBracket> }) => {
  const { text } = useTheme();

  if (!year || !year.start || !year.end) return null;

  return (
    <span
      style={{
        color: text,
      }}
    >
      Reporting for {year.startFormatted} - {year.endFormatted}
    </span>
  );
};

const TaxSummary = ({
  year,
  numImportantUnlabeled,
}: {
  year: Maybe<TaxYearBracket>;
  numImportantUnlabeled: number;
}) => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const isLarge = useIsLargeScreen();

  const _showCheckoutModal = () => {
    _showModal("CheckoutModal", { canCheckout: true });
  };

  const [getIncomeAndCapGains, { data: incomeAndCapGainsData }] = useLazyQuery<
    { getIncomeAndCapGains: GetIncomeAndCapGainsResponse },
    QueryGetIncomeAndCapGainsArgs
  >(api.reports.getIncomeAndCapGains, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!year || !clientId) return;
    getIncomeAndCapGains({
      variables: {
        clientId,
        year: year.taxYear,
      },
    });
  }, [year]);

  const theme = useTheme();

  const data = incomeAndCapGainsData?.getIncomeAndCapGains;
  const hasFutures =
    data?.futuresNet &&
    data.futuresNet !== "0.00" &&
    // replace all currency symbols
    data.futuresNet.replace(CURRENCY_REGEX, "") !== "0.00";
  const hasSubscription =
    incomeAndCapGainsData?.getIncomeAndCapGains?.hasActiveSubscription ?? false;

  if (!year) return null;

  const link = getGainsLossesLink({
    clientId: clientId || "",
    startDate: year.start,
    endDate: year.end,
  });

  return (
    <Grid
      w="100%"
      templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
    >
      <WhiteBox
        border={`1px solid ${theme.border}`}
        flex={1}
        marginBottom="1rem"
        marginRight={isLarge ? "2rem" : "0"}
      >
        <CapitalLossModal />
        <HStack alignItems="center" marginTop="0.5rem" marginBottom="1rem">
          <Heading
            color={theme.header}
            flex={1}
            size="md"
            margin="0"
            paddingTop="0"
          >
            Capital Gains ({year.taxYear})
          </Heading>
          {!hasSubscription && (
            <StatusTag
              boxProps={{ style: { cursor: "pointer" } }}
              onClick={_showCheckoutModal}
              iconName="fa-sharp fa-unlock"
              label={"Unlock " + year.taxYear}
              type="warning"
              infoMessage="Upgrade to be able to download your full capital gain/loss reports below."
            />
          )}
        </HStack>

        <CapitalGainsYearlyChart clientId={clientId || ""} year={year} />

        <Table className="collapsed-borders" border="0" padding="0" w="100%">
          <Thead>
            <Tr border={0}>
              <Th borderColor={theme.border} color={theme.text}>
                Type
              </Th>
              <Th borderColor={theme.border} color={theme.text} isNumeric>
                Amount
              </Th>
              {hasSubscription && (
                <Th
                  borderColor={theme.border}
                  color={theme.text}
                  w="20px"
                  style={{ padding: 0 }}
                />
              )}
            </Tr>
          </Thead>

          <Tbody w="100%">
            <Tr>
              <Td borderColor={theme.border} color={theme.text}>
                Short-term
              </Td>
              <Td borderColor={theme.border} color={theme.text} isNumeric>
                {data ? data.capGainsShortTerm : "Calculating..."}{" "}
              </Td>
              {hasSubscription && (
                <Td
                  borderColor={theme.border}
                  color={theme.text}
                  w="20px"
                  style={{ padding: 0 }}
                >
                  <CalculationDetails
                    basis={data?.capGainsShortTermBasis ?? null}
                    proceeds={data?.capGainsShortTermProceeds ?? null}
                    total={data?.capGainsShortTerm ?? null}
                  />
                </Td>
              )}
            </Tr>
            <Tr>
              <Td borderColor={theme.border} color={theme.text}>
                Long-term
              </Td>
              <Td color={theme.text} isNumeric borderColor={theme.border}>
                {data ? data.capGainsLongTerm : "Calculating..."}{" "}
              </Td>
              {hasSubscription && (
                <Td
                  borderColor={theme.border}
                  color={theme.text}
                  w="20px"
                  style={{ padding: 0 }}
                >
                  <CalculationDetails
                    basis={data?.capGainsLongTermBasis ?? null}
                    proceeds={data?.capGainsLongTermProceeds ?? null}
                    total={data?.capGainsLongTerm ?? null}
                  />
                </Td>
              )}
            </Tr>
            {/* {hasOther && (
              <Tr>
                <Td>Other (ex. Futures)</Td>
                <Td isNumeric>{data ? data.futuresNet : "Calculating..."}</Td>
              </Tr>
            )} */}
            <Tr color={colors.primary}>
              <Td borderBottom="0" fontWeight="bold">
                Total
              </Td>
              <Td
                borderBottom="0"
                isNumeric
                fontWeight="bold"
                color={colors.primary}
                // textDecor={
                //   data?.capGainsTotal && numImportantUnlabeled > 0
                //     ? "line-through"
                //     : ""
                // }
              >
                {data ? data.capGainsTotal : "Calculating..."}
              </Td>
            </Tr>
          </Tbody>
        </Table>

        {data?.capGainsTotal && (
          <Box w="100%" marginTop="1rem">
            <Box padding="0">
              <Link
                to={link}
                style={{
                  color: colors.primary,
                  fontWeight: "bold",
                }}
              >
                <Box
                  padding="0.75rem"
                  bg={
                    theme.theme === "light"
                      ? colors.lightBlue100
                      : colors.lightBlue10
                  }
                  borderRadius={10}
                  border={"1px solid " + colors.primary}
                >
                  <Box>
                    {numImportantUnlabeled > 0
                      ? `This might be inaccurate, since you have
                    ${numImportantUnlabeled} transactions that aren't labeled
                    yet. Click here to review your transactions sorted by
                    gains/losses.`
                      : "Looks wrong? Click here to review your transactions sorted by gains/losses."}{" "}
                    <i className="fa-sharp fa-long-arrow-right" />
                  </Box>
                </Box>
              </Link>

              {data?.capGainsTotal.includes("-") &&
                numImportantUnlabeled === 0 &&
                year.taxYear === CURRENT_TAX_YEAR_STR && (
                  <Box
                    paddingTop="1rem"
                    onClick={() =>
                      _showModal("CapitalLossModal", {
                        capLoss: data.capGainsTotal,
                      })
                    }
                    cursor="pointer"
                    bg={theme.medBackground}
                    border={"1px solid " + theme.border}
                    marginTop="1rem"
                    padding="0.75rem"
                    borderRadius={10}
                  >
                    <Text
                      color={colors.green50}
                      fontSize="sm"
                      fontWeight="bold"
                      _hover={{
                        color: colors.green60,
                      }}
                    >
                      {`You can save ${data?.capGainsTotal.replace(
                        "-",
                        ""
                      )} on taxable gains by filing your capital losses this year. Learn more`}{" "}
                      <i className="fa-sharp fa-long-arrow-right" />
                    </Text>
                  </Box>
                )}
            </Box>
          </Box>
        )}

        {hasFutures && (
          <Box marginTop="3rem">
            <HStack alignItems="center" margin="1.5rem 0">
              <Heading
                color={theme.header}
                flex={1}
                size="md"
                margin="0"
                paddingTop="0"
              >
                Futures/Derivatives ({year.taxYear}){" "}
              </Heading>
              <StatusTag
                label="New"
                type="info"
                iconName="fa-sharp fa-stars"
                infoMessage="Awaken separates Perpetuals/Futures/Derivatives from normal capital gains. You can download a report below 👇 that gives you a breakdown of all proceeds, basis, as well as futures fees."
              />
            </HStack>

            <Table
              className="collapsed-borders"
              border="0"
              padding="0"
              w="100%"
            >
              <Thead>
                <Tr border={0}>
                  <Th borderColor={theme.border} color={theme.text}>
                    Type
                  </Th>
                  <Th borderColor={theme.border} color={theme.text} isNumeric>
                    Amount
                  </Th>
                </Tr>
              </Thead>

              <Tbody w="100%">
                <Tr>
                  <Td borderColor={theme.border} color={theme.text}>
                    Futures Proceeds{" "}
                    <Info message="These are proceeds from closed positions." />
                  </Td>
                  <Td borderColor={theme.border} color={theme.text} isNumeric>
                    {data ? data.futuresProceeds : "Calculating..."}
                  </Td>
                </Tr>

                <Tr>
                  <Td borderColor={theme.border} color={theme.text}>
                    Futures Expenses{" "}
                    <Info message="These are expenses based on open positions. Ex. if you deposit $500 into GMX, this will be $500." />
                    <Box style={{ marginLeft: 15, display: "inline-block" }}>
                      <StatusTag
                        type="warning"
                        infoMessage="Awaken realizes futures expenses when you open a position, not when you close it. This is due to how difficult it is to track which open position you are closing out for on-chain perpetuals."
                        iconName="fa-sharp fa-warning"
                      />
                    </Box>
                  </Td>
                  <Td borderColor={theme.border} color={theme.text} isNumeric>
                    {data ? data.futuresExpenses : "Calculating..."}
                  </Td>
                </Tr>

                <Tr>
                  <Td borderColor={theme.border} color={theme.text}>
                    Other Future Fees{" "}
                    <Info message="These are fees / gas paid when interacting with futures. They are a write-off against your income." />
                  </Td>
                  <Td borderColor={theme.border} color={theme.text} isNumeric>
                    {data ? data.futuresFees : "Calculating..."}
                  </Td>
                </Tr>

                <Tr color={colors.primary}>
                  <Td
                    borderColor={theme.border}
                    borderBottom="0"
                    fontWeight="bold"
                  >
                    Futures Gain/Loss
                  </Td>
                  <Td
                    borderColor={theme.border}
                    borderBottom="0"
                    isNumeric
                    fontWeight="bold"
                  >
                    {data ? data.futuresNet : "Calculating..."}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        )}

        {data && (
          <Box marginTop="1rem">
            <Text
              onClick={() => {
                if (!hasSubscription) {
                  alert(
                    "Please subscribe to a tax plan to view your specific numbers / tax calculation history."
                  );
                  return;
                }
                navigate(`/clients/${clientId}/history`);
              }}
              fontSize="sm"
              style={{ cursor: "pointer" }}
              target={isMobile ? undefined : "_blank"}
              color={theme.text}
            >
              <u>View history of changes</u>
            </Text>
          </Box>
        )}
      </WhiteBox>
      <WhiteBox
        border={`1px solid ${theme.border}`}
        flex={1}
        marginBottom="1rem"
        alignSelf="start"
      >
        <HStack alignItems="center" marginTop="0.5rem" marginBottom="1rem">
          <Heading
            color={theme.header}
            flex={1}
            size="md"
            margin="0"
            paddingTop="0"
          >
            Income ({year.taxYear})
          </Heading>
          {!hasSubscription && (
            <StatusTag
              boxProps={{ style: { cursor: "pointer" } }}
              onClick={_showCheckoutModal}
              iconName="fa-sharp fa-unlock"
              label={"Unlock " + year.taxYear}
              type="warning"
              infoMessage="Upgrade to view your specific numbers and file your tax reports."
            />
          )}
        </HStack>

        <IncomeYearlyChart clientId={clientId || ""} year={year} />

        <Table className="collapsed-borders" border="0" padding="0" w="100%">
          <Thead>
            <Tr border={0}>
              <Th borderColor={theme.border} color={theme.text}>
                Type
              </Th>
              <Th borderColor={theme.border} color={theme.text} isNumeric>
                Amount
              </Th>
            </Tr>
          </Thead>

          <Tbody w="100%">
            {!data ? (
              <Tr>
                <Td borderColor={theme.border} />
                <Td borderColor={theme.border} color={theme.text} isNumeric>
                  Calculating...
                </Td>
              </Tr>
            ) : (
              data.incomeBreakdown.map((acc) => (
                <Tr>
                  <Td borderColor={theme.border} color={theme.text}>
                    {acc.accountName} {acc.info && <Info message={acc.info} />}
                  </Td>
                  <Td borderColor={theme.border} color={theme.text} isNumeric>
                    {acc.amount}
                  </Td>
                </Tr>
              ))
            )}
            <Tr color={colors.primary}>
              <Td borderBottom="0" fontWeight="bold">
                Total
              </Td>
              <Td borderBottom="0" isNumeric fontWeight="bold">
                {data ? data.incomeTotal : "Calculating..."}
              </Td>
            </Tr>
          </Tbody>
        </Table>

        {data?.incomeTotal && data?.incomeTotal !== "$0.00" && (
          <Box w="100%" padding={"0"}>
            <Link
              style={{
                color: colors.primary,
                fontWeight: "bold",
              }}
              to={getIncomeLink(clientId || "", year?.start, year?.end)}
            >
              <Box
                padding="0.75rem"
                bg={
                  theme.theme === "light"
                    ? colors.lightBlue100
                    : colors.lightBlue10
                }
                borderRadius={10}
                border={"1px solid " + colors.primary}
              >
                See income transactions{" "}
                <i className="fa-sharp fa-long-arrow-right" />
              </Box>
            </Link>
          </Box>
        )}

        {data?.goodwillTotal && data.goodwillTotal !== "$0.00" && (
          <GoodwillBreakdown
            goodwillTotal={data.goodwillTotal}
            year={year}
            goodwill={data.goodwill ?? []}
          />
        )}
      </WhiteBox>
    </Grid>
  );
};
const RoundedBar = (props: any) => {
  const { fill, x, y, width, height } = props;
  const radius = 5; // Adjust the border-radius as needed

  if (height < 0) {
    return (
      <g>
        <rect
          x={x}
          y={y + height}
          onClick={() => {
            if (props.onClick) {
              props.onClick();
            }
          }}
          width={width}
          height={-height}
          fill={fill}
          // only do the bottom right and bottom left radius
          rx={radius}
          ry={radius}
          style={{ cursor: "pointer", transition: "opacity 0.3s" }}
        />
      </g>
    );
  }

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
        fill={fill}
        rx={radius}
        ry={radius}
        style={{ cursor: "pointer", transition: "opacity 0.3s" }}
      />
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, field }: any) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    const value = ((payload || [])[0]?.payload?._data || {})[field];
    return (
      <div
        style={{
          padding: "5px 15px",
          background: theme.header,
          borderRadius: 100,
        }}
      >
        <p style={{ color: theme.background, fontWeight: 500, fontSize: 16 }}>
          <b>{label}: </b>
          {value || D(payload[0].value * 100).toFormat()}
        </p>
      </div>
    );
  }
  return null;
};

const CapitalGainsYearlyChart = ({
  year,
  clientId,
}: {
  year: TaxYearBracket;
  clientId: string;
}) => {
  const navigate = useNavigate();

  const variables = useMemo(
    () => ({
      year: year.taxYear,
      clientId,
    }),
    [year, clientId]
  );

  const { data } = useQuery(api.reports.monthlyPoints, {
    variables,
    fetchPolicy: "cache-first",
  });

  const points = data?.getMonthlyPoints ?? [];
  const theme = useTheme();

  const chartData = points.map((point: any) => ({
    timestamp: moment(point.timestamp).format("MMM"),
    Capital_Gains: D(point.totalCapGainsCents).toUnit(),
    _data: point,
    // totalCapGainsCents: point.totalCapGainsCents,
  }));

  const _openCapGains = (entry: any) => {
    // console.log(entry);
    const start = moment(entry._data.timestamp).startOf("month");
    const end = moment(entry._data.timestamp).endOf("month");

    const link = getGainsLossesLink({
      clientId: clientId || "",
      startDate: start.toDate(),
      endDate: end.toDate(),
    });

    navigate(link);
  };

  if (!chartData.length) {
    return null;
  }

  return (
    <ResponsiveContainer
      className={"rechart-" + theme.theme}
      style={{
        background: theme.background,
        borderRadius: 15,
        marginBottom: 25,
      }}
      width="100%"
      height={150}
    >
      <BarChart data={chartData}>
        <XAxis dataKey="timestamp" />
        <Tooltip content={<CustomTooltip field="totalCapGainsFormatted" />} />

        <Bar
          dataKey="Capital_Gains"
          // make the bar at least 5 high
          minPointSize={5}
          shape={(props: any) => (
            <RoundedBar
              {...props}
              radius={10}
              onClick={() => _openCapGains(props.payload)}
            />
          )}
        >
          {chartData.map((entry: any, index: number) => (
            <Cell
              key={`cell-${index}`}
              fill={
                entry["Capital_Gains"] === 0
                  ? theme.secondaryBackground
                  : entry["Capital_Gains"] > 0
                  ? colors.positive
                  : colors.negative
              }
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const IncomeYearlyChart = ({
  year,
  clientId,
}: {
  year: TaxYearBracket;
  clientId: string;
}) => {
  const navigate = useNavigate();

  const variables = useMemo(
    () => ({
      year: year.taxYear,
      clientId,
    }),
    [year, clientId]
  );

  const { data } = useQuery(api.reports.monthlyPoints, {
    variables,
    fetchPolicy: "cache-first",
  });

  const points = data?.getMonthlyPoints ?? [];
  const theme = useTheme();

  const chartData = points.map((point: any) => ({
    timestamp: moment(point.timestamp).format("MMM"),
    Income: D(point.netIncomeCents).toUnit(),
    _data: point,
    // totalCapGainsCents: point.totalCapGainsCents,
  }));

  const _openIncome = (entry: any) => {
    const start = moment(entry._data.timestamp).startOf("month");
    const end = moment(entry._data.timestamp).endOf("month");

    const link = getIncomeLink(clientId, start.toDate(), end.toDate());

    navigate(link);
  };

  if (!chartData.length) {
    return null;
  }

  return (
    <ResponsiveContainer
      className={"rechart-" + theme.theme}
      style={{
        background: theme.background,
        borderRadius: 15,
        marginBottom: 25,
      }}
      width="100%"
      height={150}
    >
      <BarChart data={chartData}>
        <XAxis dataKey="timestamp" />
        <Tooltip content={<CustomTooltip field="netIncomeFormatted" />} />

        <Bar
          dataKey="Income"
          minPointSize={5}
          shape={(props: any) => (
            <RoundedBar
              {...props}
              radius={10}
              onClick={() => _openIncome(props.payload)}
            />
          )}
        >
          {chartData.map((entry: any, index: number) => (
            <Cell
              key={`cell-${index}`}
              fill={
                entry["Income"] === 0
                  ? theme.secondaryBackground
                  : entry["Income"] > 0
                  ? colors.positive
                  : colors.negative
              }
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const GoodwillBreakdown = ({
  year,
  goodwill,
  goodwillTotal,
}: {
  year: Maybe<TaxYearBracket>;
  goodwill: AccountInfoSummary[];
  goodwillTotal: string;
}) => {
  const theme = useTheme();

  if (!year) return null;

  return (
    <Box marginTop="3rem">
      <Divider />
      <br />
      <VStack alignItems="flex-start" margin="1.5rem 0">
        <Heading
          color={theme.header}
          flex={1}
          size="md"
          margin="0"
          paddingTop="0"
        >
          Goodwill ({year.taxYear}){" "}
        </Heading>
        <Text color={theme.text}>Donations, Gifts, etc...</Text>
      </VStack>

      <Table className="collapsed-borders" border="0" padding="0" w="100%">
        <Thead>
          <Tr border={0}>
            <Th color={theme.text} borderColor={theme.border}>
              Type
            </Th>
            <Th color={theme.text} borderColor={theme.border} isNumeric>
              Amount
            </Th>
          </Tr>
        </Thead>

        <Tbody w="100%">
          {(goodwill || []).map((g) => (
            <Tr>
              <Td color={theme.text} borderColor={theme.border}>
                {g.accountName}
              </Td>
              <Td color={theme.text} borderColor={theme.border} isNumeric>
                {g ? g.amount : "Calculating..."}
              </Td>
            </Tr>
          ))}

          <Tr color={colors.primary}>
            <Td
              color={colors.primary}
              borderColor={theme.border}
              borderBottom="0"
              fontWeight="bold"
            >
              Total Goodwill
            </Td>
            <Td
              color={colors.primary}
              borderColor={theme.border}
              borderBottom="0"
              isNumeric
              fontWeight="bold"
            >
              {goodwillTotal ? goodwillTotal : "Calculating..."}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

const CalculationDetails = ({
  proceeds,
  basis,
  total,
}: {
  proceeds: Maybe<string>;
  basis: Maybe<string>;
  total: Maybe<string>;
}) => {
  if (isNil(proceeds) || isNil(basis)) {
    return null;
  }

  return (
    <div style={{ display: "inline-block", marginLeft: 5 }}>
      <Popover trigger="hover">
        <PopoverTrigger>
          <i
            style={{ display: "inline-block", color: colors.gray30 }}
            className="fa-sharp fa-calculator"
          />
        </PopoverTrigger>
        <PopoverContent
          width={250}
          left="0" // i think this is using left relative to grandfather parent, instead of direct parent
          overflowY="scroll"
        >
          <Box padding="0.75rem">
            <Text fontSize="sm" marginBottom="1rem" fontWeight="bold">
              How was this calculated?
            </Text>
            <HStack padding="0.25rem 0">
              <Text fontSize="sm" flex={1}>
                Proceeds Sum:
              </Text>
              <Text fontWeight="bold" fontSize="sm">
                {proceeds}
              </Text>
            </HStack>
            <HStack padding="0.25rem 0">
              <Text fontSize="sm" flex={1}>
                Basis Sum:
              </Text>
              <Text fontWeight="bold" fontSize="sm">
                -{basis}
              </Text>
            </HStack>
            <Divider style={{ margin: "0.5rem 0" }} />
            <HStack padding="0.25rem 0">
              <Text fontSize="sm" flex={1}>
                Net Amount:
              </Text>
              <Text fontWeight="bold" fontSize="sm">
                {total}
              </Text>
            </HStack>
          </Box>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default Taxes;
