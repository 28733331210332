import {
  Box,
  Button,
  Divider,
  Grid,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { colors, other } from "src/theme";
import { motion } from "framer-motion";
import { Info } from "src/components";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { api } from "src/api";
import { CSSProperties, useEffect } from "react";
import StatusTag from "src/components/styled/StatusTag";
import { Reward } from "./Reward";
import { isWhiteLabeledDomain } from "src/utils/whitelabel";
import { trackEvent } from "src/utils/analytics";

export const ReferralProgram = () => {
  const isWhiteLabel = isWhiteLabeledDomain();
  const { clientId } = useParams<{ clientId: string }>();
  const { client, accounts } = useClientById(clientId, {
    skipFetchAssetsOnLoad: true,
  });
  const { me } = useMe("cache-first");
  const toast = useMyToast();
  const [redeem] = useMutation(api.referrals.redeemReferralPrize);
  const { data: refData, refetch: refetchPrizes } = useQuery(
    api.referrals.getReferralPrizes,
    { variables: { clientId } }
  );

  const available = new Set(refData?.getReferralPrizes?.available || []);
  const completed = new Set(refData?.getReferralPrizes?.completed || []);

  useEffect(() => {
    refetchPrizes({ clientId });
  }, [clientId]);

  const _copyLink = () => {
    const referralCode = client?.referralCode || "";

    navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    toast.show({
      message: "Copied to clipboard!",
      status: "info",
    });

    void trackEvent("Referral Link Copied", { referralCode });
  };

  const _shareTwitter = () => {
    if (!available.has("twitter")) {
      toast.show({
        message: "You've already shared on Twitter!",
        status: "error",
      });
      return;
    }

    const url = `https://twitter.com/intent/tweet?text=Finally found a good crypto tax software: @AwakenTax. Sign up and get 500 free transactions: https://awaken.tax/?ref=${
      client?.referralCode || ""
    }`;

    window.open(url, "_blank");

    void trackEvent("Twitter Referral Share");

    void redeem({
      variables: { clientId, prize: "twitter" },
      refetchQueries: [
        api.subscriptions.active,
        api.clients.retrieve,
        api.referrals.getReferralPrizes,
      ],
    });
  };

  const email = client?.email || me?.email || "";
  const hasAccount = accounts?.length > 2;

  if (isWhiteLabel) return null;

  return null;

  return (
    <Box marginTop="6rem">
      <Heading
        margin="0"
        marginTop="2rem !important"
        marginBottom="2rem !important"
        size="md"
      >
        Earn up to{" "}
        <span
          style={{
            // textDecoration: "underline",
            fontWeight: "bold",
            color: colors.primary,
          }}
        >
          $100
        </span>{" "}
        of free credit!
      </Heading>

      <Grid
        gap={1}
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
        }}
      >
        {hasAccount && (
          <Reward
            isCompleted={false}
            // backgroundImageSrcUrl="https://assets.awaken.tax/images/motion-small-min.png"
            style={{
              backgroundColor: colors.gray20,
              transform: "rotateZ(20deg)",
              maxWidth: "130%",
              height: "27rem",
              width: "190%",
              top: "-40%",
              left: "-17%",
            }}
            title={
              <Text
                color={colors.white}
                fontSize="14px"
                fontWeight="500"
                textAlign={"left"}
                margin="0"
              >
                Fill out this{" "}
                <a
                  href={`https://docs.google.com/forms/d/e/1FAIpQLSf2oNEzaeoB92MkVTE5MY1U0VIPLBMJmoCJICFm3kHZQYA06w/viewform?usp=pp_url&entry.606972150=${email}`}
                  target="_blank"
                  style={{
                    fontWeight: "bold",
                    color: colors.white,
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  5 question survey
                </a>{" "}
                about Awaken.
              </Text>
            }
            // black and dark gray gradient
            gradient="linear-gradient(45deg, #2C2C2C 0%, #000 50%, #2C2C2C 100%)"
            numCredit={5}
          />
        )}
        <Reward
          isCompleted={completed.has("twitter")}
          title={
            <Text
              color={colors.gray20}
              fontSize="14px"
              fontWeight="500"
              textAlign={"left"}
              margin="0"
            >
              Tag{" "}
              <a
                onClick={_shareTwitter}
                style={{
                  fontWeight: "bold",
                  color: colors.primary,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                @awakentax
              </a>{" "}
              on twitter
            </Text>
          }
          gradient="linear-gradient(45deg, #A770EF 0%, #CF8BF3 50%, #fdb99b 100%)"
          numCredit={5}
        />
        <Reward
          isCompleted={completed.has("friend")}
          title={
            <Text
              color={colors.gray20}
              fontSize="14px"
              fontWeight="500"
              textAlign={"left"}
              margin="0"
            >
              Refer a friend using{" "}
              <span
                onClick={_copyLink}
                style={{
                  fontWeight: "bold",
                  color: colors.primary,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                this link
              </span>{" "}
              <Info
                style={{ color: colors.gray20 }}
                message="You can earn credit for up to two friends!"
              />
            </Text>
          }
          gradient="linear-gradient(45deg, #1FA2FF 0%, #12D8FA 50%, #A6FFCB 100%)"
          numCredit={25}
        />
        <Reward
          isCompleted={false}
          title={
            <Text
              color={colors.gray20}
              fontSize="14px"
              fontWeight="500"
              textAlign={"left"}
              margin="0"
            >
              Unhappy with Koinly or CoinTracker? Earn up to $100 of credit by{" "}
              <a
                href="mailto:team@awaken.tax?subject=I%20want%20to%20switch%20from%20Koinly%20or%20CoinTracker!"
                style={{
                  fontWeight: "bold",
                  color: colors.primary,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                emailing us
              </a>{" "}
              your receipt
              <Info
                style={{ color: colors.gray20 }}
                message="We'll match up to $100 if you send us a receipt of one of our competitors. Just email us the receipt. It's that easy."
              />
            </Text>
          }
          gradient={
            "linear-gradient(45deg, #FABC3C 0%, #FACC6B 50%, #F0AC6B 100%)"
          }
          showUpToText
          numCredit={100}
        />
        {/* <Web3Rewards /> */}
      </Grid>
    </Box>
  );
};

const ClaimButton = () => {
  return (
    <Button
      style={{
        backgroundColor: "rgba(255,255,255,1)",
        color: colors.black,
      }}
      fontSize="sm"
      borderRadius="5px !important"
      padding="0rem 0.75rem"
      height="27px"
    >
      Claim{" "}
      <i
        className="fa-sharp fa-gem"
        style={{ marginLeft: 5, color: "#3ADAFF", fontSize: 12 }}
      />
    </Button>
  );
};
