import {
  Box,
  Container,
  Heading,
  Text,
  ListItem,
  OrderedList,
  UnorderedList,
  Image,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { NavBarPageTemplate } from "src/components";
import ContentContainer from "src/components/styled/ContentContainer";
import { colors, other } from "src/theme";
import FriendTechTaxesImg from "src/assets/awaken/blog/friend-tech/friend-tech.png";
import Example from "src/assets/awaken/blog/friend-tech/example.png";
import BuyShares from "src/assets/awaken/blog/friend-tech/buy-shares.png";
import SellShares from "src/assets/awaken/blog/friend-tech/sell-shares.png";

const SITE_URL = "https://awaken.tax";
const EMAIL = "team@awaken.tax";

function WebsiteLink() {
  return (
    <a
      href={SITE_URL}
      rel="noreferrer"
      target={isMobile ? undefined : "_blank"}
      style={{
        textDecoration: "underline",
      }}
    >
      Awaken
    </a>
  );
}

function FriendTechTaxes() {
  return (
    <NavBarPageTemplate bgColor={colors.white}>
      <Box w="96%" maxW="50rem" margin="4rem auto">
        <Heading size="xl">How to do your taxes for Friend.tech</Heading>
        <Image src={FriendTechTaxesImg} w="100%" />
        <Heading size="md">What is Friend.tech?</Heading>
        <ContentContainer>
          Launched on August 10, 2023, Friend.tech is a decentralized social
          media platform that rapidly gained over 100,000 users, generating
          $1.68 million in fees within two weeks. It allows users to tokenize
          their social networks by buying and selling "keys" of their profiles
          on Coinbase's Layer 2 blockchain, Base. These keys, linked to Twitter
          accounts (now X), provide access to private chatrooms and exclusive
          content. This innovative approach blends finance with social
          interaction, transforming how users connect and monetize their online
          presence.
        </ContentContainer>
        <Heading size="lg" paddingTop="1rem">
          Option A: Find a crypto tax software that supports Friend.tech
          (Recommended)
        </Heading>
        <ContentContainer>
          As of November 2023, the following crypto tax products support and do
          not support Friend.tech:
        </ContentContainer>
        <OrderedList
          style={{
            marginLeft: "1rem",
          }}
        >
          <Text>
            <ListItem>✅ Awaken</ListItem>
            <ListItem>✅ CoinLedger</ListItem>
            <ListItem>❌ CoinTracker</ListItem>
            <ListItem>❌ ZenLedger</ListItem>
            <ListItem>❌ Koinly</ListItem>
          </Text>
        </OrderedList>
        <ContentContainer>
          To check if your crypto tax software supports Friend.tech, import your
          base wallet address into the software. Go to a transaction where you
          bought or sold a key, and it should have a transfer where you received
          or sent out a friend.tech key, like this:
        </ContentContainer>
        <Image
          src={Example}
          w="100%"
          maxW="40rem"
          margin="auto"
          boxShadow={other.boxShadow}
          marginBottom="2rem"
        />
        <ContentContainer>
          If you can't find the transaction in your wallet, you can use mine:
        </ContentContainer>
        <OrderedList
          style={{
            marginLeft: "1rem",
          }}
        >
          <Text>
            <ListItem>
              Wallet address: 0x543713990a095409ca34aadd1300e79ad249c0da
            </ListItem>
            <ListItem>
              Example buy key transaction:
              0x933a84c2e775461157cd2fb123b3e3c382aaa39ec542ef7b22302a160ceec738
            </ListItem>
            <ListItem>
              Example sell key transaction:
              0xf44014f862e3cc5d17c848234cb8fea218c51ad5a54ec13cc9accdb712370cd0
            </ListItem>
          </Text>
        </OrderedList>
        <Heading size="lg" paddingTop="1rem">
          Option B: Do the taxes yourself, without crypto tax software
        </Heading>
        <ContentContainer>
          If you want to calculate the taxes yourself, here's how to do it. We
          are going to use my wallet for this example. You can find the wallet
          address above.
        </ContentContainer>
        <ContentContainer>
          If you want to calculate the taxes yourself, here's how to do it. We
          are going to use my wallet for this example. You can find the wallet
          address above.
        </ContentContainer>
        <Heading size="md">Buying keys</Heading>
        <ContentContainer>
          On Awaken, when you buy a key, we treat it as if you are purchasing an
          asset, like an NFT. To calculate the purchase price for your new
          asset, we sum up the gas fee and the amount of ETH you spent. For
          example, in the transaction below I bought a key for 0.000275 ETH and
          paid 0.000162757 ETH in gas fees. Therefore, I spent a total of
          0.000437757 ETH on this key. We then find the real-time price of ETH
          and convert the ETH value into your currency of choice. The final
          amount we spent on the NFT is $0.73 ($0.46 + $0.27)
        </ContentContainer>
        <Image
          src={BuyShares}
          w="100%"
          maxW="40rem"
          margin="auto"
          boxShadow={other.boxShadow}
          marginBottom="2rem"
        />
        <Heading size="md">Selling keys</Heading>
        <ContentContainer>
          When you sell your keys, we use the price of your *received* ETH to
          calculate the sell price of your friend tech key. The price of the
          received ETH in this transaction is actually $2.21, which is why the
          sell price of the NFT is $2.21. We then add the gas fee's sell price
          to the received ETH, putting the purchase price of the received ETH at
          $2.25 ($2.21 + $0.04).
        </ContentContainer>
        <Image
          src={SellShares}
          w="100%"
          maxW="40rem"
          margin="auto"
          boxShadow={other.boxShadow}
          marginBottom="2rem"
        />

        <br />
        <hr />
        <ContentContainer>
          <i>
            Disclaimer: The information in this post is for general
            informational purposes and should not be considered as professional
            tax advice. Always consult with a qualified tax advisor for specific
            guidance on tax-related matters.
          </i>
        </ContentContainer>
      </Box>
    </NavBarPageTemplate>
  );
}

export default FriendTechTaxes;
