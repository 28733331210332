import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Heading,
  HStack,
  Image,
  Table,
  Text,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
} from "@chakra-ui/react";
import { sortBy, truncate } from "lodash";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { api } from "src/api";
import {
  PotentialSpamAsset,
  Rule,
  RuleTypeEnum,
  SpamAsset,
} from "src/api/generated/types";
import {
  AwakenTooltip,
  Copy,
  Info,
  PageTemplateContainer,
  SideBarPageTemplate,
} from "src/components";
import { PROVIDER_TO_LOGO_URL } from "src/components/modals/AccountModal/constants";
import WhiteBox from "src/components/styled/WhiteBox";
import { useMyToast } from "src/hooks";
import { colors, other } from "src/theme";
import Helpers, { isLoadingGQL } from "src/utils/helpers";
import Loading from "src/views/Loading";

function SpamAssets() {
  const toast = useMyToast();
  const [
    getPotentialSpamAssets,
    {
      data: potentialSpamAssetsData,
      networkStatus: potentialSpamAssetsDataNetworkStatus,
      error,
    },
  ] = useLazyQuery<{
    getPotentialSpamAssets: Array<PotentialSpamAsset>;
  }>(api.misc.getPotentialSpamAssets, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });
  const [createSpamAsset, { loading: loadingCreateSpamAsset }] = useMutation(
    api.misc.createSpamAsset
  );
  const navigate = useNavigate();
  const [labeled, setLabeled] = useState<string[]>([]);

  useEffect(() => {
    getPotentialSpamAssets();
  }, []);

  const _onClickCreate = async (
    nextSpamAsset: PotentialSpamAsset,
    option: string
  ) => {
    if (loadingCreateSpamAsset) return;
    try {
      const spamAsset = await createSpamAsset({
        variables: {
          contractAddress: nextSpamAsset.contractAddress,
          provider: nextSpamAsset.provider,
          assetName: nextSpamAsset.assetName,
          isSpam: option === "YES" ? true : option === "NO" ? false : null,
          exampleTxnHash: nextSpamAsset.exampleTxnHash,
          minTxnCreatedAt: nextSpamAsset.minTxnCreatedAt,
          numInitiatedTxns: nextSpamAsset.numInitiatedFees,
        },
        // refetchQueries: [api.defaultRules.getnextSpamAssets],
      });
      toast.show({
        message: `Successfully created spam asset for ${nextSpamAsset.assetName}`,
        status: "success",
      });
      setLabeled([
        ...labeled,
        `${nextSpamAsset.provider}:${nextSpamAsset.contractAddress}`,
      ]);
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  if (isLoadingGQL(potentialSpamAssetsDataNetworkStatus)) return <Loading />;

  const nextSpamAssets = sortBy(
    potentialSpamAssetsData?.getPotentialSpamAssets || [],
    (x) => x.count
  ).reverse();

  // Filter Modal
  return (
    <PageTemplateContainer>
      <div>
        <HStack>
          <Heading flex={1} size="lg">
            🌎 Create Spam Assets
          </Heading>
        </HStack>
        <WhiteBox padding="0">
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Asset Name</Th>
                  <Th>First seen (# days ago)</Th>
                  <Th>
                    # Initiated Fees
                    <Info message="The number of fees that were initiated by this asset by users... NOT the number of transactions. So if they had 1 transaction they initiated with 2 fees, then this number would be 2." />
                  </Th>
                  <Th># Clients Who Labeled</Th>
                  <Th>Example</Th>
                  <Th>Is Spam?</Th>
                </Tr>
              </Thead>
              <Tbody>
                {error ? (
                  <Text>{error.message}</Text>
                ) : (
                  nextSpamAssets?.map((nextSpamAsset, i) => (
                    <Tr
                      w="100%"
                      cursor="pointer"
                      borderBottom={other.boxBorder}
                      overflowX="scroll"
                      transition="0.1s ease-in-out"
                      _hover={{
                        backgroundColor: colors.gray1,
                        transition: "0.2s ease-in-out",
                      }}
                    >
                      <Td>
                        <Image
                          src={
                            PROVIDER_TO_LOGO_URL[
                              nextSpamAsset.provider.toLowerCase() || ""
                            ] || ""
                          }
                          marginRight="0.5rem"
                          width="1rem"
                          height="1rem"
                          display="inline"
                          style={{ borderRadius: 3 }}
                        />
                        {nextSpamAsset.assetName}
                        <br />
                        {nextSpamAsset.contractAddress}
                      </Td>
                      <Td>
                        {Math.floor(
                          (new Date().getTime() -
                            new Date(nextSpamAsset.minTxnCreatedAt).getTime()) /
                            (1000 * 60 * 60 * 24)
                        )}
                      </Td>
                      <Td>{nextSpamAsset.numInitiatedFees}</Td>

                      <Td>{nextSpamAsset.count}</Td>
                      <Td
                        display="flex"
                        border="0"
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <a
                          href={`https://awaken.tax/${nextSpamAsset.exampleTxnHash}`}
                          target="_blank"
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          Example
                        </a>
                        <Copy value={nextSpamAsset.exampleTxnHash} />
                      </Td>
                      {!labeled.includes(
                        `${nextSpamAsset.provider}:${nextSpamAsset.contractAddress}`
                      ) && (
                        <Td>
                          <Button
                            bgColor={colors.primaryGreen}
                            marginRight="1rem"
                            onClick={() => _onClickCreate(nextSpamAsset, "YES")}
                          >
                            YES
                          </Button>
                          <Button
                            bgColor={colors.red}
                            marginRight="1rem"
                            onClick={() => _onClickCreate(nextSpamAsset, "NO")}
                          >
                            NO
                          </Button>
                          <Button
                            // bgColor={colors.}
                            onClick={() => _onClickCreate(nextSpamAsset, "IDK")}
                          >
                            IDK
                          </Button>
                        </Td>
                      )}
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </WhiteBox>
      </div>
    </PageTemplateContainer>
  );
}

export default SpamAssets;
