import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api } from "src/api";
import { useTheme } from "src/hooks/useTheme";
import { Query, TokenSearchResult } from "src/api/generated/types";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { InjectedProps, connectModal } from "redux-modal";
import { Modal } from "src/components/Modal";
import { Button, Copy } from "src/components/styled";
import { Box, Divider, HStack, Text } from "@chakra-ui/react";
import { AssetIcon } from "src/components/styled/Assets/AssetIcon";
import { Input } from "src/components/styled/Form";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { E164Number } from "libphonenumber-js/types.cjs";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { colors } from "src/theme";
import { isMobile } from "react-device-detect";
import { noop } from "lodash";
import { useParams } from "react-router-dom";
import { config } from "src/config";
import { trackEvent } from "src/utils/analytics";

type Props = InjectedProps & {
  onComplete: (token: TokenSearchResult) => void;
};

const _ReferralModal = ({ onComplete, handleHide, show: isVisible }: Props) => {
  const { me } = useMe();

  const { clientId } = useParams<{ clientId: string }>();

  const { client } = useClientById(clientId || "", {
    onlyFetchClient: true,
    clientFetchPolicy: "cache-first",
  });

  const theme = useTheme();
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const toast = useMyToast();
  const value = `https://awaken.tax?ref=${client?.referralCode}`;

  const _shareToTwitter = () => {
    if (!client) return;

    trackEvent("Share to Twitter Clicked");

    const referralLink = `https://twitter.com/intent/tweet?text=I just started using @AwakenTax for my crypto taxes and it is a game changer! You can try it out and get $25 of free credit using my referral link:\n\n${value}`;

    window.open(referralLink, "_blank");
  };

  return (
    <Modal isVisible={isVisible} handleHide={handleHide}>
      <div>
        <br />
        {/* <div style={{ textAlign: "center", width: "100%" }}>
          <i
            className="fas fa-mobile"
            style={{
              fontSize: 75,
              textAlign: "center",
              marginBottom: 25,
              color: theme.text,
            }}
          />
        </div> */}

        <Text
          color={theme.header}
          style={{
            fontStretch: "extra-expanded",
          }}
          fontSize="4xl"
          fontWeight="bold"
        >
          Earn $10 of credit
        </Text>

        <br />

        <Text style={{ color: theme.text, fontSize: 18, fontWeight: 500 }}>
          You only need to do 2 things:
          <br />
          <br />
          1) Tweet about Awaken (and tag @AwakenTax). Your link is{" "}
          <a
            href={value}
            target="_blank"
            style={{
              fontWeight: "bold",
              color: colors.primary,
              textDecoration: "underline",
              display: "inline-block",
            }}
          >
            {value}
          </a>{" "}
          <Copy containerStyle={{ display: "inline-block" }} value={value} />
          <br />
          <br />
          <Button
            style={{
              flex: 1,
              borderRadius: 50,
              color: colors.white,
              // twitter blue
              backgroundColor: "#1DA1F2",
            }}
            onClick={_shareToTwitter}
          >
            Share to Twitter{" "}
            <img
              style={{
                marginLeft: 10,
                width: 15,
                height: 15,
                objectFit: "contain",
              }}
              src={require("src/assets/awaken/logos/socials/twitter-white.png")}
            />
          </Button>
          <br />
          <br />
          2) Email{" "}
          <a
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
              color: colors.primary,
            }}
            target="_blank"
            href="mailto:team@awaken.tax"
          >
            team@awaken.tax
          </a>{" "}
          a link to your tweet!
        </Text>

        <br />
        <br />

        <Text style={{ color: theme.text, fontSize: 18, fontWeight: 500 }}>
          After that, we'll add credit to your account. And you'll also get
          rev-share from anyone that uses your link.
        </Text>

        {/* <a href={APP_STORE_URL} target="_blank">
          <Button
            leftIcon={
              <img
                src={require("src/assets/social/white-apple.png")}
                style={{
                  position: "absolute",
                  left: 15,
                  top: 15,
                  width: 24,
                  height: 25,
                  objectFit: "contain",
                }}
              />
            }
            onClick={noop}
            style={{
              marginTop: 30,
              minWidth: 200,
              height: 60,
              width: "100%",
              fontWeight: 700,
              fontSize: 18,
              fontStretch: "expanded",
              background: colors.black,
              border: `2px solid ${colors.white}`,
              color: colors.white,
            }}
            isLoading={loading}
          >
            Download on App Store
          </Button>
        </a>

        <a href={GOOGLE_PLAY_URL} target="_blank">
          <Button
            leftIcon={
              <img
                src={require("src/assets/social/play.png")}
                style={{
                  position: "absolute",
                  left: 15,
                  top: 15,
                  width: 24,
                  height: 25,
                  objectFit: "contain",
                }}
              />
            }
            onClick={noop}
            style={{
              marginTop: 15,
              minWidth: 200,
              height: 60,
              width: "100%",
              fontWeight: 700,
              fontSize: 18,
              fontStretch: "expanded",
              background: colors.black,
              border: `2px solid ${colors.white}`,
              color: colors.white,
            }}
            isLoading={loading}
          >
            Download on Google Play
          </Button>
        </a> */}

        <br />

        {/* <HStack
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box
            onClick={() => alert("Only US phone numbers are supported.")}
            style={{
              width: 55,
              height: 55,
              fontSize: 18,
              cursor: "pointer",
              backgroundColor: theme.secondaryBackground,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 15,
            }}
          >
            🇺🇸
          </Box>
          <Input
            containerStyle={{ flex: 1, marginBottom: 0 }}
            value={formattedPhone}
            autoFocus
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter phone number"
          />
        </HStack> */}

        {/* <Button
          onClick={_textDownloadLink}
          style={{
            marginTop: 50,
            minWidth: 200,
            width: "100%",
            fontWeight: 700,
            fontSize: 18,
            background: theme.header,
            color: theme.background,
          }}
          isLoading={loading}
        >
          Text me link{" "}
          <img
            src={require("src/assets/icons/svgs/arrow-right.svg").default}
            style={{
              position: "absolute",
              fontSize: 20,
              right: 15,
              top: 17,
              // transform the images color to be inverted
              filter: theme.theme === "light" ? "invert(100%)" : "invert(0%)",
            }}
            className="fas fa-arrow-right"
          />
        </Button> */}

        {/* <br />

        <Divider />

        <br />

        <Text
          style={{
            color: theme.text,
            fontWeight: 500,
            fontSize: 18,
          }}
        >
          Or you can use{" "}
          <a
            style={{
              color: colors.black,
              fontWeight: "bold",
              textDecoration: "underline",
            }}
            target={isMobile ? undefined : "_blank"}
            href={APP_STORE_URL}
          >
            this link
          </a>
          .
        </Text> */}

        <br />
      </div>
    </Modal>
  );
};

export const ReferralModal = connectModal({
  name: "ReferralModal",
})(_ReferralModal);
