import React, { useEffect, useState } from "react";
import Particles from "react-tsparticles";

export function Stars() {
  return (
    <Particles
      options={{
        interactivity: { detect_on: "window" },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            enable: false,
          },
          collisions: {
            enable: true,
          },
          move: {
            enable: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 75,
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 1,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: true,
              speed: 0.25,
              opacity_min: 0,
              sync: false,
            },
          },
          size: {
            value: 2,
            random: true,
          },
        },
      }}
    />
  );
}
