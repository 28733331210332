import { Text, HStack } from "@chakra-ui/react";
import { colors } from "src/theme";
import { blue100 } from "src/theme/colors";

function BackButton ({ onClick }: any) {
    return (
        <HStack onClick={onClick} cursor="pointer" width="4rem" padding="0.2rem 0" userSelect="none">
            <i className="far fa-chevron-left" style={{
                color: colors.primary
            }}></i>
            <Text color={colors.primary}>Back</Text>
        </HStack>
    )
}

export default BackButton;