import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Heading,
  HStack,
  Spinner,
  Text,
  useTimeout,
} from "@chakra-ui/react";
import { useEffect } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { api } from "src/api";
import { useMyToast } from "src/hooks";
import { colors } from "src/theme";

function OAuthRedirect() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { provider } = useParams<{
    provider: string;
  }>();
  const [getOAuthToken, { error, loading }] = useMutation(
    api.accounts.getOAuthToken
  );
  const toast = useMyToast();

  // location storage variables
  const clientId = localStorage.getItem("v1:oauth_link:client_id");
  const accountId = localStorage.getItem("v1:oauth_link:account_id");
  const integrationName = localStorage.getItem(
    "v1:oauth_link:integration_name"
  );

  const _getTokenAndRedirect = async (code: string) => {
    try {
      const res = await getOAuthToken({
        variables: {
          provider,
          clientId,
          code,
          accountId,
        },
      });

      console.log(`[oauth response]`);
      console.log(res);

      localStorage.removeItem("v1:oauth_link:client_id");
      localStorage.removeItem("v1:oauth_link:account_id");
      localStorage.removeItem("v1:oauth_link:integration_name");

      navigate(`/clients/${clientId}/accounts`);
    } catch (e) {
      console.log(e);
      toast.show({
        status: "error",
        message: "Failed to link account",
      });
    }
  };

  useEffect(() => {
    console.log(`[getting code for oauth...]`);
    const code = search.get("code");

    if (!code) {
      toast.show({
        status: "error",
        message: "No code provided",
      });
      return;
    }

    if (!clientId) {
      toast.show({
        status: "error",
        message:
          "Missing client ID. Please make sure to open back up in the same browser. You'll need to try hooking the account up again.",
      });
      return;
    }

    _getTokenAndRedirect(code);
  }, []);

  if (error) {
    return (
      <HStack
        height="100vh"
        bg={colors.gray90}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          padding="2rem"
          bg={colors.white}
          borderRadius="1rem"
          textAlign="center"
        >
          <Heading size="md">Error linking {integrationName}!</Heading>
          <br />
          <Text margin="auto">Contact support and we will help you out.</Text>
          <br />
          <Link to={"/dashboard"}>
            <Button variant="primary">Go back to Dashboard</Button>
          </Link>
        </Box>
      </HStack>
    );
  }

  if (loading) {
    return (
      <HStack
        height="100vh"
        bg={colors.gray90}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          padding="2rem"
          bg={colors.white}
          borderRadius="1rem"
          textAlign="center"
        >
          <Heading size="md">
            Linking {integrationName}... <Spinner size="md" />{" "}
          </Heading>
          <br />
          <Text margin="auto">
            This may take a few seconds, please hold tight!
          </Text>
        </Box>
      </HStack>
    );
  }

  return (
    <HStack
      height="100vh"
      bg={colors.gray90}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        padding="2rem"
        bg={colors.white}
        borderRadius="1rem"
        textAlign="center"
      >
        <Heading size="md">Successfully linked {integrationName} 🎊!</Heading>
        <br />
        <Text margin="auto">
          We will redirect back to the dashboard in a few seconds...
          <br />
          please hold tight!
        </Text>
      </Box>
    </HStack>
  );
}

export default OAuthRedirect;
