import { Button, Container, Text, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useMyToast } from "src/hooks";
import { MutationUpdateClientArgs } from "src/api/generated/types";
import { useParams } from "react-router-dom";
import { noop } from "lodash";
import { Input } from "src/components/styled";

type Props = InjectedProps;

function _ClientNameModal({ handleHide, show: isVisible }: Props) {
  const [name, setName] = useState("");
  const [updateName, { loading }] = useMutation(api.clients.update);
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();

  const _onSubmit = async function () {
    if (!clientId) return;

    if (!name) {
      return toast.show({
        message: "Please fill out the name field.",
        status: "error",
      });
    }

    try {
      const variables: MutationUpdateClientArgs = {
        clientId,
        name,
      };

      await updateName({
        variables,
        refetchQueries: [api.clients.retrieve, api.users.clients.getActive],
      });

      toast.show({
        message: "Name updated successfully!",
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }

    handleHide();
  };

  return (
    <Modal
      title="What is your name?"
      isVisible={isVisible}
      handleHide={noop}
      hideClose
      Footer={
        <Button
          width="100%"
          variant="primary"
          isLoading={loading}
          onClick={_onSubmit}
        >
          Save
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text>
          This information isn't shared anywhere, but if you want to stay anon
          you can put whatever you want here 🙂
        </Text>
        <br />
        <br />
        <Input
          value={name}
          label="Full name"
          noOfLines={5}
          onChange={(e) => setName(e.target.value)}
          // label="Client Full Name"
          placeholder="Type here..."
        />
      </Container>
    </Modal>
  );
}

export const ClientNameModal = connectModal({
  name: "ClientNameModal",
})(_ClientNameModal);
