import { Text, Heading, Box, HStack, Flex } from "@chakra-ui/react";
import WhiteBox from "src/components/styled/WhiteBox";
import { Link as ReactRouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { useNavigate, useParams } from "react-router-dom";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { api } from "src/api";
import { colors, other } from "src/theme";
import Loading from "src/views/Loading";
import { useEffect, useMemo } from "react";
import { Query } from "src/api/generated/types";
import StatusTag from "src/components/styled/StatusTag";
import { truncate } from "lodash";
import { getImageKitCDNUrl } from "src/utils/imagekit";
import { isMobile } from "react-device-detect";
import { Metrics } from "./metrics";
import _ from "lodash";

function Analytics() {
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();

  const { accounts, isLoadingAccounts } = useClientById(clientId, {
    // poll accounts every 5 seconds for updates
    pollAccountsMs: 5000,
  });

  const { data: meData, refetch: refetchMe } = useQuery<Pick<Query, "me">>(
    api.users.me,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => void refetchMe(), []);

  if (isLoadingAccounts) {
    return <Loading />;
  }

  return (
    <Box maxWidth={"inherit"}>
      <Box w="100%">
        <HStack alignItems="center" padding="1rem 0">
          <Flex flex={1} alignItems="center" display="flex" flexDir="row">
            <Heading margin="0" size="lg">
              Analytics
            </Heading>
          </Flex>
        </HStack>
      </Box>

      <Metrics />
    </Box>
  );
}

export default Analytics;
