import { Button, Container, Text, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useMyToast } from "src/hooks";

type Props = InjectedProps;

function _PortfolioFeedbackModal({ handleHide, show: isVisible }: Props) {
  const [feedback, setFeedback] = useState("");
  const [submitFeedback, { loading }] = useMutation(
    api.portfolio.submitFeedback
  );
  const toast = useMyToast();

  const _onSubmit = async function () {
    if (!feedback)
      return toast.show({
        message: "Please fill out all the form fields.",
        status: "error",
      });

    try {
      const message = [
        `Portfolio is incorrect ❌\n`,
        `URL: ${window.location.href}`,
        `Feedback: ${feedback}`,
      ].join("\n");

      await submitFeedback({
        variables: { message },
      });

      toast.show({
        message: "Thank you for your feedback",
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }

    handleHide();
  };

  return (
    <Modal
      title="Leave portfolio feedback"
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          isLoading={loading}
          onClick={_onSubmit}
        >
          Submit Feedback
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text fontSize="md">
          Tell us a little bit more about what is inaccurate. This helps us make
          sure your <b>taxes & portfolio are accurate</b>, helping you save
          money and time.
        </Text>
        <br />
        <Textarea
          value={feedback}
          noOfLines={5}
          onChange={(e) => setFeedback(e.target.value)}
          // label="Client Full Name"
          placeholder="Type here..."
        />
      </Container>
    </Modal>
  );
}

export const PortfolioFeedbackModal = connectModal({
  name: "PortfolioFeedbackModal",
})(_PortfolioFeedbackModal);
