import { useMutation } from "@apollo/client";
import { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { api } from "src/api";
import { MutationVerifyRecaptchaArgs } from "src/api/generated/types";
import { config } from "src/config";
import {
  DefaultErrors,
  failure,
  FailureOrSuccess,
  success,
  UnexpectedError,
} from "src/core";
import { useMyToast } from "src/hooks";
import { getIPAddress } from "src/utils/ipaddress";

const useRecaptchaV3 = (siteKey: string, action: string) => {
  const toast = useMyToast();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const [verifyRecaptcha] = useMutation<{ verifyRecaptcha: boolean }>(
    api.users.verifyRecaptcha
  );

  const executeRecaptcha = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const grecaptcha = (window as any).grecaptcha;

      // console.log(grecaptcha);
      if (!grecaptcha || !grecaptcha.enterprise) {
        reject(new Error("reCAPTCHA not loaded"));
        return;
      }
      grecaptcha.ready(() => {
        grecaptcha
          .execute(siteKey, { action })
          .then((token: string) => resolve(token))
          .catch(reject);
      });
    });
  };

  const renderV2Recaptcha = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const grecaptcha = (window as any).grecaptcha;

      // const container = document.getElementById("recaptcha-container");
      // if (container) container.innerHTML = ""; // Clear the container

      if (!grecaptcha) {
        reject(new Error("reCAPTCHA script is not loaded!"));
        return;
      }

      grecaptcha.render("recaptcha-container", {
        sitekey: config.recaptcha.v2Token,
        callback: function (response: string) {
          console.log("reCAPTCHA v2 challenge passed!", response);
          resolve(response);
        },
        "error-callback": function () {
          reject(new Error("Failed to pass reCAPTCHA v2 challenge."));
        },
        "expired-callback": function () {
          reject(new Error("reCAPTCHA v2 expired. Please try again."));
        },
      });
    });
  };

  const verifyV3 = async (): Promise<
    FailureOrSuccess<DefaultErrors, { requiresCheckbox: boolean }>
  > => {
    const token = await executeRecaptcha();

    if (!token) {
      return failure(new UnexpectedError("Failed to get reCAPTCHA token."));
    }

    const ipAddress = await getIPAddress();
    const params: MutationVerifyRecaptchaArgs = {
      token: token,
      ipAddress,
      version: "v3",
    };

    const verifyResponse = await verifyRecaptcha({
      variables: params,
    });

    const isVerified = verifyResponse.data?.verifyRecaptcha ?? false;

    if (!isVerified) {
      return success({ requiresCheckbox: true });
    }

    return success({ requiresCheckbox: false });
  };

  const verifyV2 = async (): Promise<FailureOrSuccess<DefaultErrors, null>> => {
    // trigger the recaptcha
    try {
      const token = await recaptchaRef.current?.getValue();

      if (!token) {
        return failure(new UnexpectedError("Failed to get reCAPTCHA token."));
      }

      const ipAddress = await getIPAddress();
      const params: MutationVerifyRecaptchaArgs = {
        token: token,
        ipAddress,
        version: "v2",
      };

      const verifyResponse = await verifyRecaptcha({
        variables: params,
      });

      const isVerified = verifyResponse.data?.verifyRecaptcha ?? false;

      if (!isVerified) {
        return failure(new UnexpectedError("Failed to verify reCAPTCHA v2."));
      }

      return success(null);
    } catch (error) {
      return failure(new UnexpectedError("Failed to verify reCAPTCHA v2."));
    }
  };

  useEffect(() => {
    const grecaptcha = (window as any).grecaptcha;

    if (!grecaptcha) {
      console.error("reCAPTCHA script is not loaded!");
    }
  }, []);

  return {
    verifyV3,
    verifyV2,
    recaptchaRef,
  };
};

export default useRecaptchaV3;
