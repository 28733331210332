import { Maybe } from "src/core";
import * as yup from "yup";
import { Option } from "src/components/styled";

export type FormValues = {
    isAcceptingIndividuals: Maybe<boolean>;
    isAcceptingBusinesses: Maybe<boolean>;
    isAcceptingEth: Maybe<boolean>;
    isAcceptingEthL2: Maybe<boolean>;
    isAcceptingOtherChains: Maybe<boolean>;
    numTxnsLowerBound: Maybe<Option>;
    numTxnsUpperBound: Maybe<Option>;
    name: string;
    email: string;
    twitter: string;
    linkedin: string;
    description: string;
    headline: string;
    confirmed: Maybe<boolean>;
    imgUrl: string;
};

export const getDefaultValues = (
    ): FormValues => ({
        isAcceptingIndividuals: null,
        isAcceptingBusinesses: null,
        isAcceptingEth: null,
        isAcceptingEthL2: null,
        isAcceptingOtherChains: null,
        numTxnsLowerBound: null,
        numTxnsUpperBound: null,
        name: "",
        email: "",
        twitter: "",
        linkedin: "",
        headline: "",
        description: "",
        confirmed: null,
        imgUrl: "",
});

export const schema = yup.object().shape({
    isAcceptingIndividuals: yup.boolean().nullable(),
    isAcceptingBusinesses: yup.boolean().nullable(),
    isAcceptingEth: yup.boolean().nullable(),
    isAcceptingEthL2: yup.boolean().nullable(),
    isAcceptingOtherChains: yup.boolean().nullable(),

    numTxnsLowerBound: yup.mixed().required("Please fill out all required fields"),
    numTxnsUpperBound: yup.mixed().required("Please fill out all required fields"),
    name: yup.string().required(),
    email: yup.string().email().required(),
    twitter: yup.string().optional(),
    linkedin: yup.string().optional(),
    headline: yup.string().required(),
    description: yup.string().required(),
    confirmed: yup.boolean().nullable(),
    imgUrl: yup.string().required(),
});
