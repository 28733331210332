import { Container } from "@chakra-ui/react";
import { ReactNode } from "react";

function ContentContainer({ children }: { children: ReactNode }) {
  return (
    <Container marginLeft="0" paddingLeft="0" marginBottom="1rem">
      {children}
    </Container>
  );
}

export default ContentContainer;
