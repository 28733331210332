import {
  Grid,
  GridItem,
  HStack,
  VStack,
  Box,
  Tooltip,
  Text,
  Button,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { noop } from "lodash";
import { useMemo, useState, useCallback } from "react";
import { isBrowser } from "react-device-detect";
import {
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import {
  MutationCreateAccountArgs,
  AccountTypeEnum,
  ImportTypeEnum,
  Query,
} from "src/api/generated/types";
import StatusTag from "src/components/styled/StatusTag";
import { useMe, useMyToast } from "src/hooks";
import { colors, other } from "src/theme";
import { isWhiteLabeledDomain } from "src/utils/whitelabel";
import { Maybe } from "src/core";
import { Info } from "src/components";
import {
  IntegrationAction,
  IntegrationOption,
  IntegrationOptionProps,
  IntegrationProviderInfo,
} from "./types";
import { compose } from "lodash/fp";
import { INTEGRATIONS } from "./constants";
import { useTheme } from "src/hooks/useTheme";

type Wallet = {
  url: string;
  name: string;
  allowedIntegrationProviders?: Set<string>;
};

const WALLETS: Wallet[] = [
  {
    url: require("src/assets/awaken/wallets/metamask.png"),
    name: "Metamask Wallet",
    allowedIntegrationProviders: new Set([
      "ethereum",
      "polygon",
      "arbitrum",
      "optimism",
      "avalanche",
      "bsc",
    ]),
  },
  {
    url: require("src/assets/awaken/wallets/coinbase.png"),
    name: "Coinbase Wallet",
    allowedIntegrationProviders: new Set([
      "ethereum",
      "polygon",
      "solana",
      "bitcoin",
      "avalanche",
      "bsc",
      "arbitrum",
      "fantom",
      "optimism",
      "polygon",
    ]),
  },
  {
    url: require("src/assets/awaken/wallets/ledger.png"),
    name: "Ledger Wallet",
  },
  {
    url: require("src/assets/awaken/wallets/phantom.png"),
    name: "Phantom Wallet",
    allowedIntegrationProviders: new Set([
      "ethereum",
      "polygon",
      "solana",
      "bitcoin",
    ]),
  },
  {
    url: require("src/assets/awaken/wallets/magiceden.png"),
    name: "Magic Eden",
    allowedIntegrationProviders: new Set([
      "ethereum",
      "polygon",
      "solana",
      "bitcoin",
    ]),
  },
  {
    url: require("src/assets/awaken/wallets/trust.png"),
    name: "Trust Wallet",
  },
  {
    url: require("src/assets/awaken/wallets/trezor.png"),
    name: "Trezor Wallet",
  },
];

export const Wallets = ({ search }: { search: string }) => {
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const theme = useTheme();

  const matches = useMemo(
    () =>
      WALLETS.filter(
        (w) =>
          search.length > 0 &&
          w.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ),
    [search]
  );

  const _onClickWallet = (match: Wallet) => {
    _showModal("WalletModal", {
      walletName: match.name,
      walletLogoUrl: match.url,
      allowedIntegrationProviders: match.allowedIntegrationProviders,
    });
  };

  if (matches.length === 0) return null;

  return (
    <Grid
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
      gap={1}
      marginTop="1rem"
    >
      {matches.map((match) => (
        <GridItem colSpan={1}>
          <HStack
            h="100%"
            border={`1px solid ${theme.border}`}
            margin="0"
            marginBottom="0.5rem"
            bg={theme.background}
            borderRadius="0.25rem"
            padding="1rem 0.75rem"
          >
            <img
              style={{
                width: "2.6rem",
                height: "2.6rem",
                borderRadius: 5,
                objectFit: "contain",
              }}
              src={match.url}
            />
            <VStack
              marginLeft="0.5rem !important"
              w="100%"
              flex={1}
              alignItems="start"
            >
              <HStack w="100%" flex={1}>
                <Text color={theme.header} flex={1} style={{ fontSize: 16 }}>
                  {match.name}{" "}
                </Text>
              </HStack>
            </VStack>
            <span>
              <Button
                size="sm"
                borderRadius="7px !important"
                style={{ fontSize: 12 }}
                onClick={
                  () => _onClickWallet(match)
                  // window.alert(
                  //   'To add your MetaMask transactions, you need to connect each chain you used. For example, if you used MetaMask on Ethereum, you need to add "Ethereum" and type in your public key.'
                  // )
                }
                bg={theme.secondaryBackground}
                _hover={{ bg: theme.ternaryBackground }}
                color={theme.header}
              >
                Connect{" "}
              </Button>
            </span>
          </HStack>
        </GridItem>
      ))}
    </Grid>
  );
};
