import {
  FilterFormChainsEnum,
  FilterFormClientEnum,
  FilterFormNumTxnsEnum,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import { D } from "src/utils/helpers";
import * as yup from "yup";
import { Option } from "src/components/styled";

export const CLIENT_TYPES = [
  { label: "Individual", value: FilterFormClientEnum.Individual },
  { label: "Business", value: FilterFormClientEnum.Business },
];

export const CHAINS_TYPES = [
  { label: "Ethereum only", value: FilterFormChainsEnum.Eth },
  {
    label: "Ethereum and its L2s (e.g. Ethereum + Polygon + Arbitrum)",
    value: FilterFormChainsEnum.EthL2,
  },
  {
    label: "Other chains too (e.g. Ethereum + Polygon + Solana + Near)",
    value: FilterFormChainsEnum.Other,
  },
];

export const NUM_TXN_OPTIONS = [
  { label: "1 - 20", value: FilterFormNumTxnsEnum.Low },
  { label: "20 - 100", value: FilterFormNumTxnsEnum.LowMid },
  { label: "100 - 500", value: FilterFormNumTxnsEnum.Mid },
  { label: "500 - 1000", value: FilterFormNumTxnsEnum.MidHigh },
  { label: "More than 1000", value: FilterFormNumTxnsEnum.High },
];

export type FormValues = {
  clientType: Maybe<Option>;
  chains: Maybe<Option>;
  numTransactions: Maybe<Option>;
  name: string;
  email: string;
};

// FIXME: this is pretty sloppy code but it works so
export const getDefaultValues = (): FormValues => ({
  clientType: null,
  chains: null,
  numTransactions: null,
  name: "",
  email: "",
});

export const schema = yup.object().shape({
  clientType: yup.mixed().required("Please fill out all required fields"),
  chains: yup.mixed().required("Please fill out all required fields"),
  numTransactions: yup.mixed().required("Please fill out all required fields"),
  name: yup.string().required("Please provide your full name"),
  email: yup.string().email().required("Please provide your email"),
});
