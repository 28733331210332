import { Maybe } from "src/core";

const getValue = (hideKey?: string) =>
  hideKey ? localStorage.getItem(hideKey) : null;

const getIsHidden = (value: Maybe<string>, hideKey?: string) =>
  !!hideKey && value === "hidden";

export const WarningService = {
  getValue,
  getIsHidden,
};
