import { useLazyQuery } from "@apollo/client";
import { Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "src/api";
import {
  RedirectByHashResponse,
  QueryRedirectByHashArgs,
} from "src/api/generated/types";
import { getUserAuthStatus } from "src/redux/reducers/user";
import { isLoadingGQL } from "src/utils/helpers";

export const RedirectToTxn = () => {
  const navigate = useNavigate();
  const authStatus = useSelector(getUserAuthStatus);
  const { txnHash } = useParams();

  const [
    redirectByHash,
    {
      data: redirectByHashData,
      networkStatus: redirectByHashNetworkStatus,
      error,
    },
  ] = useLazyQuery<
    {
      redirectByHash?: RedirectByHashResponse;
    },
    QueryRedirectByHashArgs
  >(api.transactions.redirectByHash, {
    fetchPolicy: "network-only",
  });

  const isLoading = authStatus === "LOADING" || authStatus === "NOT_LOADED";
  const notLoggedIn = authStatus === "NOT_LOGGED_IN";
  const isLoggedIn = authStatus === "LOGGED_IN";

  useEffect(() => {
    if (!isLoggedIn) return;
    redirectByHash({
      variables: {
        txnHash: txnHash || "",
      },
    });
  }, [txnHash, isLoggedIn]);

  // auth: loading or server: loading
  if (isLoading || isLoadingGQL(redirectByHashNetworkStatus))
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );

  // auth: not logged in or server error
  if (notLoggedIn || error) {
    navigate("/");
  }

  if (redirectByHashData?.redirectByHash) {
    const redirectUrl = redirectByHashData.redirectByHash.redirectUrl;
    if (redirectUrl.startsWith("https://")) {
      window.location.href = redirectUrl;
    } else {
      navigate(redirectUrl);
    }
  }

  return <div />;
};
