import {
  BaseFeeFields,
  BaseFullTransactionFields,
  BaseTransferFields,
} from "src/api/fragments";
import {
  TransactionEntityTypeEnum,
  TransactionTransferTypeEnum,
  TransactionTypeEnum,
  TransferCategoryTypeEnum,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import * as yup from "yup";

export type TransferFormValues = {
  id: string;
  value: number;
  originalValue: number;
  fiatValue: number;
  originalFiatValue: number;
  fiatCurrency: string;
  type?: Maybe<TransactionTransferTypeEnum>;
  price: number;
  estFiatValue: number;
  assetId?: Maybe<string>;
  originalAssetId?: Maybe<string>;
  fromAccountId?: Maybe<string>;
  originalFrom?: Maybe<string>;
  fromType?: Maybe<TransactionEntityTypeEnum>;
  toAccountId?: Maybe<string>;
  originalTo?: Maybe<string>;
  toType?: Maybe<TransactionEntityTypeEnum>;
  transferCategory?: Maybe<TransferCategoryTypeEnum>;
};

export type FeeFormValues = {
  id: string;
  amount: number;
  originalAmount: number;
  fiatValue: number;
  originalFiatValue: number;
  fiatCurrency: string;
  assetId?: Maybe<string>;
  originalAssetId?: Maybe<string>;
  paidTxnFee: boolean;
  fromReferenceId?: Maybe<string>;
  originalFromReferenceId?: Maybe<string>;
  payerAccountId?: Maybe<string>;
  isWithheldFee: boolean;
};

export type FormValues = {
  title: string;
  notes?: Maybe<string>;
  labelUsed: Maybe<string>;
  transfers: TransferFormValues[];
  fees: FeeFormValues[];
  globalRuleName: string;
};

export const getDefaultValues = (
  transaction?: Maybe<BaseFullTransactionFields>,
  values?: Partial<FormValues>
): FormValues => ({
  title: values?.title || transaction?.title || "",
  notes: values?.notes || transaction?.notes || "",
  labelUsed: values?.labelUsed || transaction?.labelUsed || "",
  fees: values?.fees || transaction?.fees.map(feeToFormInput) || [],
  transfers:
    values?.transfers || transaction?.transfers.map(transferToFormInput) || [],
  globalRuleName: values?.globalRuleName || "",
});

// : yup.SchemaOf<FormValues>
export const schema = yup.object().shape({
  title: yup.string().label("Title").required(),
  notes: yup.string().label("Notes").nullable(),
  labelUsed: yup.mixed().label("Label").nullable(),
  ruleTypeUsed: yup.mixed().label("Rule Used").nullable(), // TODO:
  transfers: yup.array().of(yup.mixed() /*transferSchema*/).min(0).required(),
  fees: yup.array().of(yup.mixed() /*feeSchema*/).min(0).required(),
});

export const transferToFormInput = (
  t: BaseTransferFields
): TransferFormValues => ({
  id: t.id,
  value: t.value,
  fiatCurrency: t.fiatCurrency || "USD",
  type: t.type || null,
  originalFrom: t.originalFromAddress || null,
  originalTo: t.originalToAddress || null,
  originalFiatValue: t.originalFiatValue || 0,
  originalValue: 0,
  fiatValue: t.fiatAmountCents || 0,
  fromAccountId: t.fromAccountId || t.fromMetadata?.accountId || null,
  toAccountId: t.toAccountId || t.toMetadata?.accountId || null,
  price: t.price || 0,
  estFiatValue: t.estFiatValue || 0,
  assetId: t.assetId || "", // TODO:
  originalAssetId: t.originalAssetId || "", // TODO:
  fromType: t.fromType || null,
  toType: t.toType || null,
});

export const feeToFormInput = (fee: BaseFeeFields): FeeFormValues => ({
  ...fee,
  amount: fee.amount || 0,
  fiatCurrency: "USD",
  assetId: fee.assetId || null,
  originalAmount: 0,
  originalFiatValue: fee.originalFiatValue || 0,
  fiatValue: fee.fiatAmountCents || 0,
  originalAssetId: fee.originalAssetId || null,
  fromReferenceId: fee.fromReferenceId || "", // TODO:
  originalFromReferenceId: fee.originalFromReferenceId || "", // TODO:
  payerAccountId: fee.payerAccountId || null,
});
