import { useQuery } from "@apollo/client";
import { compose } from "lodash/fp";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { api } from "src/api";
import { Query, TransactionTypeOption } from "src/api/generated/types";
import { Option } from "src/components/styled";
import { hasValue, Maybe } from "src/core";
import { motion } from "framer-motion";
import { colors } from "src/theme";
import { useFormContext } from "react-hook-form";
import { useContext } from "react";
import { ActiveTransactionContext } from "src/context";
import { useOnClickLabelSelect } from "./useOnClickLabelSelect";
import ReactSelect from "react-select";
import { Touchable } from "../Touchable";
import { Tooltip } from "@chakra-ui/react";
import { useTheme } from "src/hooks/useTheme";
type LabelsProps = {
  labelOptions: TransactionTypeOption[];
  selectedOption: Option | undefined;
  onSelectOption: (o: Maybe<TransactionTypeOption>) => void;
  loading: boolean;
};

export function LabelSelect({
  labelOptions,
  selectedOption,
  onSelectOption,
  loading,
}: LabelsProps) {
  const { transaction } = useContext(ActiveTransactionContext);
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const formProps = useFormContext();
  const labelUsed = formProps.watch("labelUsed");
  const isDisabled = false;
  const { medBackground, header, border, secondaryBackground } = useTheme();

  const onClick = useOnClickLabelSelect({
    loading,
    isDisabled,
    _showModal,
    labelOptions,
    selectedOption,
    onSelectOption,
    transaction,
  });

  const _onClick = (e: any) => {
    onClick(e);
  };

  const _onClickRemove = (e: any) => {
    e.stopPropagation();
    onSelectOption(null);
  };

  const hasLabel = !!labelUsed;

  return (
    <div style={{ width: "100%", maxWidth: 275 }} onClick={_onClick}>
      <div
        style={{
          padding: "0.5rem 1rem",
          borderRadius: 7,
          backgroundColor: secondaryBackground,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          border: `2px ${hasLabel ? "solid" : "dashed"} ${
            hasLabel ? border : colors.yellow60
          }`,
          color: header,
        }}
      >
        <div style={{ fontWeight: "500", flex: 1, color: header }}>
          {selectedOption?.label || "Select Label"}
        </div>
        {hasLabel && (
          <Tooltip label="Remove label">
            <div style={{ position: "relative", top: 1 }}>
              <Touchable
                onClick={_onClickRemove}
                style={{ marginRight: "0.5rem" }}
                iconName="fa-sharp fa-times"
              />
            </div>
          </Tooltip>
        )}
        <i className="fa-sharp fa-chevron-down" />
      </div>
    </div>
  );
}
