import {
  Box,
  Button,
  Container,
  Divider,
  FormLabel,
  HStack,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { useNavigate, useParams } from "react-router-dom";
import { Input, Textarea } from "src/components/styled";
import {
  Mutation,
  MutationRequestBookkeepingQuoteArgs,
  MutationUpdateBookkeepingQuoteArgs,
  UploadFileResponse,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import { BaseBookkeepingQuoteFields } from "src/api/fragments";
import { useDropzone } from "react-dropzone";
import { Required } from "src/components/styled/Form/Required";
import { isNil } from "lodash";

type Props = InjectedProps & {
  quote: Maybe<BaseBookkeepingQuoteFields>;
};

function _QuotePriceModal({ handleHide, quote, show: isVisible }: Props) {
  const [updateBookkeepingQuote, { loading }] = useMutation<
    Pick<Mutation, "updateBookkeepingQuote">
  >(api.bookkeepingQuotes.update);

  const toast = useMyToast();
  const theme = useTheme();
  const navigate = useNavigate();

  const [description, setDescription] = useState("");
  const [fileUpload, setFileUpload] = useState<UploadFileResponse | null>(null);

  // monthly price
  const [monthlyPrice, setMonthlyPrice] = useState(
    isNil(quote?.monthlyPriceCents) ? 0 : quote?.monthlyPriceCents || 0 / 100
  );
  // upfront price
  const [upfrontPrice, setUpfrontPrice] = useState(
    isNil(quote?.upfrontPriceCents) ? 0 : quote?.upfrontPriceCents || 0 / 100
  );

  useEffect(() => {
    // set the fields based on quote changes
    if (quote) {
      setMonthlyPrice((quote.monthlyPriceCents || 0) / 100);
      setUpfrontPrice((quote.upfrontPriceCents || 0) / 100);
      setFileUpload(
        quote.quoteFileUrl
          ? {
              url: quote.quoteFileUrl,
              fileName: "Quote PDF",
              objectFileKey: "",
            }
          : null
      );
    }
  }, [quote]);

  const _onSubmit = async function () {
    try {
      if (!quote) {
        return;
      }

      const variables: MutationUpdateBookkeepingQuoteArgs = {
        bookkeepingQuoteId: quote.id || "",
        monthlyPriceCents: Math.ceil((monthlyPrice ?? 0) * 100),
        upfrontPriceCents: Math.ceil((upfrontPrice ?? 0) * 100),
        quoteFileUrl: fileUpload?.url,
      };

      const response = await updateBookkeepingQuote({
        variables,
        refetchQueries: [
          api.bookkeepingQuotes.listForClient,
          api.bookkeepingQuotes.retrieve,
        ],
      });

      toast.show({
        message: "Successfully updated quote.",
        status: "success",
      });

      handleHide();
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title={<>Bookkeeping Quote</>}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          isLoading={loading}
          marginBottom="1rem"
          onClick={_onSubmit}
        >
          Save Changes
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        {/* number inputs for monthly + upfront prices */}
        {/* <Input
          label="Monthly Price (USD)"
          type="number"
          value={monthlyPrice}
          onChange={(e) => setMonthlyPrice(parseFloat(e.target.value))}
        />

        <Input
          label="Upfront Price (USD)"
          type="number"
          value={upfrontPrice}
          onChange={(e) => setUpfrontPrice(parseFloat(e.target.value))}
        /> */}

        <br />

        <PDFUpload fileUpload={fileUpload} onFileUpload={setFileUpload} />

        <br />
      </Container>
    </Modal>
  );
}

const PDFUpload = ({
  fileUpload,
  onFileUpload,
}: {
  fileUpload: UploadFileResponse | null;
  onFileUpload: (p: UploadFileResponse) => void;
}) => {
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();

  const [uploadFile, { loading }] = useMutation<Pick<Mutation, "uploadFile">>(
    api.uploadFile
  );

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file || !clientId) return;

    try {
      const threeYearsInSeconds = 60 * 60 * 24 * 365 * 3;

      // Do something with the files
      const result = await uploadFile({
        variables: { file, clientId, expiresInSeconds: threeYearsInSeconds },
      });

      const fileUpload = result.data?.uploadFile;

      if (!fileUpload) {
        return;
      }

      onFileUpload(fileUpload);
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      // pdf
      "application/pdf": [".pdf"],
    },
    onDropRejected: (errors) => {
      // console.log(err);
      const message = errors
        .map((e) => e.errors.map((e) => e.message))
        .join(" ");
      toast.show({
        message,
        status: "error",
      });
    },
  });

  const hasFile = loading || !!fileUpload;
  const theme = useTheme();

  return (
    <>
      <FormLabel color={theme.text} fontSize={14} marginBottom="6px">
        Quote PDF Upload <Required />
      </FormLabel>

      {hasFile && (
        <HStack
          style={{
            marginBottom: "1rem",
            padding: "1rem 1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 5,
            border: `1px solid ` + theme.border,
          }}
          bg={theme.secondaryBackground}
        >
          <i
            style={{
              fontSize: 28,
              color: colors.primary,
              marginRight: 5,
            }}
            className="fa-sharp fa-file-pdf"
          />

          <Text flex={1} color={theme.header} fontSize="sm">
            {loading ? (
              <>
                Uploading... <Spinner size="xs" />{" "}
              </>
            ) : (
              fileUpload?.fileName || "Your File"
            )}
          </Text>

          <a href={fileUpload?.url} target="_blank" rel="noreferrer">
            <i
              style={{
                fontSize: 20,
                color: colors.primary,
                marginLeft: 5,
              }}
              className="fa-sharp fa-external-link"
            />
          </a>
        </HStack>
      )}

      <div
        style={{
          padding: "2rem 1rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: 5,
          border: `1px dashed ` + colors.gray80,
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Text color={theme.header} fontSize="md" fontWeight="semibold">
            Drop the files here ...
          </Text>
        ) : (
          <Box textAlign="center">
            <i
              style={{ color: colors.primary, fontSize: 40 }}
              className="fa-sharp fa-file-pdf"
            />
            <Text
              color={theme.header}
              marginTop="1rem"
              fontWeight="bold"
              fontSize="md"
            >
              Drop{" "}
              {/* or <span style={{ color: colors.primary }}>browse</span>{" "} */}
              your file.
            </Text>
          </Box>
        )}
      </div>
    </>
  );
};

export const QuotePriceModal = connectModal({
  name: "QuotePriceModal",
})(_QuotePriceModal);
