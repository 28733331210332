import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Heading,
  HStack,
  Spinner,
  Table,
  Text,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Progress,
} from "@chakra-ui/react";
import { truncate } from "lodash";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { api } from "src/api";
import {
  GetNextDefaultRulesResponse,
  Rule,
  RuleTypeEnum,
} from "src/api/generated/types";
import { Copy } from "src/components";
import WhiteBox from "src/components/styled/WhiteBox";
import { useMyToast } from "src/hooks";
import { colors, other } from "src/theme";
import { isLoadingGQL } from "src/utils/helpers";
import Loading from "src/views/Loading";

function Rules() {
  const toast = useMyToast();
  const [
    getNextDefaultRules,
    {
      data: nextDefaultRulesData,
      networkStatus: nextDefaultRulesNetworkStatus,
      error,
    },
  ] = useLazyQuery<{
    getNextDefaultRules: Array<GetNextDefaultRulesResponse>;
  }>(api.defaultRules.getNextDefaultRules, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });
  const [createDefaultRule] = useMutation(api.defaultRules.createDefaultRule);

  const [lastClicked, setLastClicked] = useState<number | null>(null);

  useEffect(() => {
    getNextDefaultRules();
  }, []);

  const _onClickCreate = async (
    nextDefaultRule: GetNextDefaultRulesResponse
  ) => {
    const label = window.prompt("What protocol is this for? (Label the rule)");
    if (label === null) return;

    try {
      const defaultRule = await createDefaultRule({
        variables: {
          exampleRuleId: nextDefaultRule.exampleRuleId,
          label,
        },
        refetchQueries: [api.defaultRules.getNextDefaultRules],
      });
      toast.show({
        message: `Successfully created default rule for ${label}`,
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  if (isLoadingGQL(nextDefaultRulesNetworkStatus)) return <Loading />;

  const nextDefaultRules = nextDefaultRulesData?.getNextDefaultRules;

  // Filter Modal
  return (
    <div>
      <HStack>
        <Heading flex={1} size="lg">
          🌎 Create Default Rules
        </Heading>
      </HStack>
      <WhiteBox padding="0">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Type</Th>
                <Th># Type</Th>
                <Th># Rules</Th>
                <Th>Percentage</Th>
                <Th>
                  <Center>Verification</Center>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {error ? (
                <Text>{error.message}</Text>
              ) : (
                nextDefaultRules?.map((nextDefaultRule, i) => (
                  <Tr
                    bgColor={lastClicked === i ? colors.lightBlue80 : undefined}
                    w="100%"
                    cursor="pointer"
                    borderBottom={other.boxBorder}
                    overflowX="scroll"
                    transition="0.1s ease-in-out"
                    _hover={{
                      backgroundColor:
                        lastClicked === i ? undefined : colors.gray1,
                      transition: "0.2s ease-in-out",
                    }}
                  >
                    <Td fontSize={14}>{nextDefaultRule.formattedType}</Td>
                    <Td fontSize={14}>{nextDefaultRule.numType}</Td>
                    <Td fontSize={14}>{nextDefaultRule.numRules}</Td>
                    <Td fontSize={14}>
                      <Progress
                        width="8rem"
                        size="sm"
                        borderRadius={other.borderRadius}
                        value={nextDefaultRule.percentage * 100}
                        colorScheme={
                          nextDefaultRule.percentage > 0.5
                            ? nextDefaultRule.percentage > 0.8
                              ? "green"
                              : "yellow"
                            : "red"
                        }
                      />
                    </Td>
                    <Td>
                      <Center>
                        <a
                          key={i}
                          href={nextDefaultRule.blockExplorerUrl || "#"}
                          rel="noreferrer"
                          target={isMobile ? undefined : "_blank"}
                          style={{
                            display: "block",
                          }}
                        >
                          <Button
                            _hover={{
                              color: colors.black,
                            }}
                            onClick={() => setLastClicked(i)}
                          >
                            Verify on {nextDefaultRule.blockExplorerName}
                          </Button>
                        </a>
                      </Center>
                    </Td>
                    <Td>
                      <Center>
                        <Button
                          bgColor={colors.primaryGreen}
                          _hover={{
                            backgroundColor: colors.primaryGreen,
                          }}
                          onClick={() => _onClickCreate(nextDefaultRule)}
                        >
                          Create
                        </Button>
                      </Center>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </WhiteBox>
    </div>
  );
}

export default Rules;
