import { useLazyQuery } from "@apollo/client";
import {
  Alert,
  Box,
  Divider,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Table,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { motion } from "framer-motion";
import { truncate } from "lodash";
import { isNil, isNull } from "lodash/fp";
import { useContext, useEffect, useMemo, useState } from "react";
import { api, BaseTransactionFullFields } from "src/api";
import {
  AssetTypeEnum,
  CurrencyCodeEnum,
  GetEntryLinksResponse,
  IsDirtyEnum,
  LedgerTransactionReviewStatusEnum,
  NeedsReview,
  NeedsReviewError,
  QueryGetEntryLinksArgs,
  TransactionEntityTypeEnum,
  TransferCategoryTypeEnum,
} from "src/api/generated/types";
import { AwakenTooltip, Info, InfoBox } from "src/components/styled";
import StatusTag from "src/components/styled/StatusTag";
import { Touchable } from "src/components/Touchable";
import { ActiveTransactionContext } from "src/context";
import { Maybe } from "src/core";
import { colors } from "src/theme";
import { D } from "src/utils/helpers";
import { EditTransferPopover } from "../Transfers/EditTransferPopover";
import {
  TransferBreakdown,
  TransferWithExtraInfo,
} from "../Transfers/TransferForm/utils";
import { LinksTable } from "./EntryLinksTable";
import { LedgerEntryLinkWithCapacity } from "src/api/generated/types";
import { Warning } from "src/components/Warning";
import { getLink } from "src/modules/ledger/transactions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BaseFullTransactionFields } from "src/api/fragments";
import { useTheme } from "src/hooks/useTheme";
import { show } from "redux-modal";
import { useDispatch } from "react-redux";

type FinancialInfoProps = {
  proceeds: Maybe<number>;
  basis: Maybe<number>;
  profit: Maybe<number>;
  assetCreditId: Maybe<string>;
  transfer: Maybe<TransferWithExtraInfo>;
  transaction: Maybe<BaseFullTransactionFields>;
  fiatCurrency: CurrencyCodeEnum;
  timezone: string;
};

// can only edit proceeds if no received transfers
const _canEditProceeds = (
  txn: Maybe<BaseFullTransactionFields>,
  transfer: Maybe<TransferWithExtraInfo>,
  breakdown: Maybe<TransferBreakdown>
) => {
  // always allow editing
  return true;

  // if (!transfer || !breakdown) return false;

  // const noReceives = breakdown.received.transfers.length === 0;
  // // FIXME: hack for txns like this 4zVheUvzBBrhsL7XGsX6DpppVmqzmin7PynSC9WevBPM3jmPKFRXmfoVes9k8M55xTZbS5ofZLwaFjEe8t5jN96Z
  // const isSolNoReceive =
  //   txn?.provider === "solana" &&
  //   breakdown.received.transfers?.length === 1 &&
  //   breakdown.received.transfers[0]?.fullAsset?.symbol === "SOL";

  // return transfer.transferType === "sent" && (noReceives || isSolNoReceive);
};

export const CreditFinancialInfo = ({
  proceeds,
  basis: _basis,
  profit,
  assetCreditId,
  transfer,
  transaction,
  fiatCurrency,
  timezone,
}: FinancialInfoProps) => {
  const { transfers } = useContext(ActiveTransactionContext);
  const symbol = transfer?.fullAsset?.symbol;
  const isFiat = transfer?.fullAsset?.type === AssetTypeEnum.FiatCurrency;

  // === api stuff === //
  const [fetchLinks, { data }] = useLazyQuery<
    { getEntryLinks: GetEntryLinksResponse },
    QueryGetEntryLinksArgs
  >(api.ledgerEntries.links, {
    // Note: we want this so it doesn't override ledger entry links or other elements of the cache which causes issues
    fetchPolicy: "no-cache",
  });

  // get the links for the asset credit
  useEffect(() => {
    if (!assetCreditId) {
      return;
    }

    void fetchLinks({
      variables: { ledgerEntryId: assetCreditId },
    });
  }, [assetCreditId]);

  const canEditProceeds =
    !!transfer && _canEditProceeds(transaction, transfer, transfers);

  const isBasisSet = transfer?.isUserSetBasisFiatValue ?? false;
  const isMissingBasis = transfer?.isMissingBasis ?? false;

  // Always show missing basis edit because
  const showMissingBasisEdit = true; // || isBasisSet || isMissingBasis; - show the missing basis if it is missing or if the user set it (so they can edit it)

  const links = data?.getEntryLinks?.fromLinks || [];
  const amount = useMemo(
    () =>
      links.reduce(
        (a, l) => a.plus(new BigNumber(l.cryptoAmountUsed || 0)),
        new BigNumber(0)
      ),
    [links]
  );

  const fiatAmountUsed = useMemo(
    () =>
      links.reduce(
        (a, l) => a.plus(new BigNumber(l.fiatAmountUsed || 0)),
        new BigNumber(0)
      ),
    [links]
  );

  const hasProceeds = !isNil(proceeds) || transfer?.isCreatedByUser;
  const hasProfit = !isNil(profit);
  const costBasisFiatAmount = _basis ?? transfer?.assetCreditFiatAmount ?? null;
  const { header, secondaryBackground } = useTheme();
  const dispatch = useDispatch();

  // don't show this info for fiat currencies
  if (
    !transfer?.isCreatedByUser &&
    (isNull(costBasisFiatAmount) || isNull(assetCreditId) || isFiat)
  ) {
    return null;
  }

  // if is missing basis OR the user set the field -> allow editing
  // TODO: can edit the basis warning? if it is missing basis on the transfer

  return (
    <Box padding="0.25rem 0">
      <CostBasisInfo
        basis={costBasisFiatAmount ?? 0}
        transfer={transfer}
        symbol={symbol}
        transaction={transaction}
        assetCreditId={assetCreditId || ""}
        links={links}
        amount={amount}
        showMissingBasisEdit={showMissingBasisEdit}
        isBasisSet={isBasisSet}
        isMissingBasis={isMissingBasis}
        fiatCurrency={fiatCurrency}
        timezone={timezone}
      />
      {hasProceeds && (
        <>
          <HStack spacing={0} margin="0.5rem 0" w="100%">
            <Text
              style={{
                fontSize: 14,
                flex: 1,
                fontWeight: "normal",
              }}
              flex={1}
              cursor="default"
              color={header}
            >
              Sell Price
            </Text>

            <Text
              style={{
                flex: 1,
                textAlign: "right",
                fontWeight: "500",
                fontSize: 14,
                marginRight: 5,
                color: header,
              }}
            >
              {D(proceeds || 0, fiatCurrency).toFormat()}
            </Text>

            <div>
              {canEditProceeds ? (
                <Touchable
                  style={{
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    dispatch(
                      show("TransferModal", {
                        transfer,
                        mode: "editing",
                        field: "snapshot",
                        startingFiatValue: proceeds,
                        transaction,
                      })
                    );
                  }}
                  iconName="fa-sharp fa-pen"
                  iconPosition="right"
                />
              ) : (
                <div style={{ width: 30 }} />
              )}
            </div>
          </HStack>

          {hasProfit && (
            <>
              <Box
                bg={secondaryBackground}
                style={{ margin: "0.75rem 0" }}
                height="2px"
                w="100%"
              />

              <HStack spacing={0} margin="0.5rem 0" w="100%">
                <Text
                  cursor="default"
                  fontSize="sm"
                  color={_getColorForValue(profit)}
                  borderRadius="5px"
                  flex={1}
                  fontWeight="semibold"
                >
                  {profit < 0 ? "Loss" : "Profit"}
                </Text>

                <Text
                  fontSize="sm"
                  flex={1}
                  style={{ textAlign: "right" }}
                  color={_getColorForValue(profit)}
                  fontWeight="semibold"
                >
                  {profit === 0
                    ? "--"
                    : D(profit || 0, fiatCurrency).toFormat()}
                </Text>

                <div
                  style={{
                    width: 35,
                  }}
                />
              </HStack>
            </>
          )}

          <WrongCostBasisAlert
            transfer={transfer}
            proceeds={proceeds ?? 0}
            basis={costBasisFiatAmount ?? 0}
            links={links}
            needsReview={transaction?.needsReview}
          />
        </>
      )}
    </Box>
  );
};

const _getColorForValue = (v: number) => {
  if (v === 0) {
    return colors.gray50;
  }

  return v > 0 ? colors.green50 : colors.red50;
};

const CostBasisInfo = ({
  basis,
  transaction,
  assetCreditId,
  symbol,
  transfer,
  links,
  amount,
  showMissingBasisEdit,
  isBasisSet,
  isMissingBasis,
  fiatCurrency,
  timezone,
}: {
  basis: number;
  assetCreditId: string;
  symbol?: Maybe<string>;
  transaction: Maybe<BaseFullTransactionFields>;
  transfer: Maybe<TransferWithExtraInfo>;
  links: LedgerEntryLinkWithCapacity[];
  amount: BigNumber;
  showMissingBasisEdit: boolean;
  isBasisSet: boolean;
  isMissingBasis: boolean;
  fiatCurrency: CurrencyCodeEnum;
  timezone: string;
}) => {
  const [isOpen, setOpen] = useState(false);
  const _onOpen = () => setOpen(true);
  // force transfer so that for fees we don't show this. FIXME: kinda a hack for now
  const hasEditBasisValue = transfer && showMissingBasisEdit;
  const theme = useTheme();

  return (
    <VStack alignItems="flex-start" margin="0.5rem 0 0.25rem 0" w="100%">
      <HStack spacing={0} w="100%">
        <div
          style={{
            flex: 1,
            marginRight: 0,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              marginRight: 5,
              fontWeight: "normal",
              fontSize: 14,
              // make it same line
              whiteSpace: "nowrap",
            }}
            cursor="default"
            color={theme.header}
          >
            Cost Basis
          </Text>

          <Popover
            trigger="hover"
            onOpen={_onOpen}
            onClose={() => setOpen(false)}
            isOpen={isOpen}
            placement="bottom"
          >
            <PopoverTrigger>
              <div>
                <StatusTag
                  type="none"
                  boxProps={{
                    cursor: "pointer",
                    style: {
                      padding: "0.25rem 0.5rem",
                    },
                  }}
                  // infoMessage="How did we calculate this?"
                  iconName="fa-sharp fa-info-circle"
                  labelStyle={{
                    fontSize: 10,
                  }}
                />
              </div>
            </PopoverTrigger>

            <PopoverContent
              width="750px"
              left="5px"
              maxHeight="300px"
              overflowY="scroll"
              bg={theme.background}
              zIndex={1000}
              border={`1px solid ${theme.border} !important`}
            >
              <Box>
                <HStack padding="0.75rem 1rem" alignItems="center">
                  <Text
                    color={theme.header}
                    flex={1}
                    fontSize="md"
                    fontWeight="semibold"
                  >
                    How we calculated your cost basis/purchase price 🕵️‍♂️
                  </Text>
                  <VStack alignItems="flex-end">
                    <Text
                      color={theme.header}
                      margin="0 !important"
                      fontSize="sm"
                      fontWeight="bold"
                    >
                      Total: {D(basis || 0, fiatCurrency).toFormat()}
                    </Text>
                    <Text
                      color={theme.header}
                      fontSize="xs"
                      margin="0 !important"
                      fontWeight="normal"
                    >
                      {amount.toFormat()}{" "}
                      {truncate(symbol || "", { length: 5, omission: ".." })}
                    </Text>
                  </VStack>
                </HStack>
                <LinksTable
                  timezone={timezone}
                  links={links}
                  assetCreditId={assetCreditId}
                />
              </Box>
            </PopoverContent>
          </Popover>
        </div>

        <Text
          style={{
            flex: 1,
            textAlign: "right",
            fontWeight: "500",
            fontSize: 14,
            marginRight: 5,
            color: theme.header,
          }}
        >
          {D(basis || 0, fiatCurrency).toFormat()}
        </Text>

        <div>
          {hasEditBasisValue ? (
            <EditBasisValue
              basis={basis}
              transfer={transfer}
              showMissingBasisEdit={showMissingBasisEdit}
              isBasisSet={isBasisSet}
              isMissingBasis={isMissingBasis}
            />
          ) : (
            <div style={{ width: 30 }} />
          )}
        </div>
      </HStack>
    </VStack>
  );
};

const EditBasisValue = ({
  basis,
  transfer,
  showMissingBasisEdit,
  isBasisSet,
  isMissingBasis,
}: {
  basis: number;
  transfer: Maybe<TransferWithExtraInfo>;
  showMissingBasisEdit: boolean;
  isBasisSet: boolean;
  isMissingBasis: boolean;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { transaction } = useContext(ActiveTransactionContext);

  if (!transfer) return null;

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
      }}
    >
      <div>
        <AwakenTooltip
          message={
            isMissingBasis
              ? "We were unable to find the full purchase price of this asset transfer. Please set it manually."
              : ""
          }
        >
          <span>
            <Touchable
              onClick={() => {
                dispatch(
                  show("TransferModal", {
                    transfer,
                    mode: "editing",
                    field: "basis",
                    startingFiatValue: basis,
                    transaction,
                  })
                );
              }}
              style={{
                cursor: "pointer",
                background:
                  isMissingBasis && !isBasisSet
                    ? theme.theme === "light"
                      ? colors.red100
                      : colors.red20
                    : undefined,
              }}
              iconStyle={{
                color:
                  isMissingBasis && !isBasisSet ? colors.red50 : theme.text,
              }}
              iconName="fa-sharp fa-pen"
            />
          </span>
        </AwakenTooltip>
      </div>
    </div>
  );
};

type WrongCostBasisAlertProps = {
  transfer: Maybe<TransferWithExtraInfo>;
  links: LedgerEntryLinkWithCapacity[];
  proceeds: number;
  basis: number;
  needsReview?: Maybe<NeedsReview>;
};

const SMALL_PROCEEDS_THRESHOLD_USD = 10 * 100; // $100
const SMALL_BASIS_THRESHOLD_USD = SMALL_PROCEEDS_THRESHOLD_USD;

const LARGE_PROCEEDS_THRESHOLD_USD = 100 * 100; // $100
const LARGE_BASIS_THRESHOLD_USD = LARGE_PROCEEDS_THRESHOLD_USD;

const WrongCostBasisAlert = ({
  transfer,
  proceeds,
  basis,
  links,
  needsReview,
}: WrongCostBasisAlertProps) => {
  const { clientId } = useParams();
  // const navigate = useNavigate();
  const fullAsset = transfer?.fullAsset;
  const { transaction } = useContext(ActiveTransactionContext);

  const show = useMemo(
    () =>
      needsReview?.transferErrors?.some(
        (e) => e.error === NeedsReviewError.EntryUnlabeledNftSale
      ),
    [needsReview]
  );

  if (!fullAsset) return null; // e.g. fee

  // Asset sell price much larger than purchase price (ex. 0xb3cee4e9778c822e9d22271273c84649f6a8579597a205d2bc88a7938edb6665)
  if (
    show
    // basis < SMALL_BASIS_THRESHOLD_USD &&
    // proceeds > LARGE_PROCEEDS_THRESHOLD_USD &&
    // links.length > 0 &&
    // links.some(
    //   (link) =>
    //     link.to?.transaction?.reviewStatus ===
    //     LedgerTransactionReviewStatusEnum.NeedsReview
    // )
  ) {
    // const prevTxnLink = getLink(clientId || "", {
    //   transactionId: links[0].to?.transaction.id,
    // });
    return (
      <Warning
        marginTop="1rem"
        message={
          <>
            The purchase price looks wrong. To fix it, hover over "Calculation",
            label the unlabeled transactions,{" "}
            {/* {
              <Link
                to={prevTxnLink}
                style={{
                  color: colors.primary,
                  textDecoration: "underline",
                }}
              >
                <a>label the transaction where you received this NFT</a>
              </Link>
            }{" "} */}
            and then click Recalculate.
          </>
        }
        width="95%"
      />
    );
  }

  // Asset purchase price much larger than sell price (ex. 0x41d107616554a00b6e35aaa20eff6e48e6e0f696d22f6d97cfdaf42901b4a0a2)
  if (
    basis > LARGE_BASIS_THRESHOLD_USD &&
    proceeds < SMALL_PROCEEDS_THRESHOLD_USD
  ) {
    return (
      <Warning
        marginTop="1rem"
        message={
          transaction?.reviewStatus ===
            LedgerTransactionReviewStatusEnum.Reviewed &&
          transaction?.isDirty === IsDirtyEnum.Clean
            ? 'The sell price might be wrong here. You can click "Edit" if you think it\'s wrong.'
            : "The sell price might be wrong here. To fix it, please label this transaction and then click Recalculate."
        }
        width="95%"
      />
    );
  }

  return null;
};
