import { api } from "src/api";

export const RECALCULATE_REFETCH_QUERIES = [
  api.jobs.list,
  api.transactions.countTransactions,
  api.transactions.countNotDone, // def need this
  api.transactions.getNumTxnTypes, // def need this
  api.portfolio.get, // def need this
  api.clients.retrieve,
  api.clients.transactions,
  api.clients.accounts,
  api.reports.getTaxYears,
  api.reports.getIncomeAndCapGains,
  // this can change in terms of num txns we are looking at if we switch the country (which is rare but watever).
  // so let's just refetch quick enof query
  api.subscriptions.active,
  api.summaries.list,
  api.graph.getReplayInfo,
];
