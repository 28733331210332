import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Link,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { colors } from "src/theme";
import CountUp from "react-countup";
import { Maybe, hasValue } from "src/core";
import {
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { api } from "src/api";
import {
  CurrencyCodeEnum,
  ImportTypeEnum,
  PartialAsset,
  PartialPortfolioAsset,
  PortfolioAssetPosition,
  PortfolioBalance,
  PortfolioResponse,
  Position,
  Query,
} from "src/api/generated/types";
import { DateTime } from "luxon";
import { first, isNil, keyBy, last, orderBy, throttle, truncate } from "lodash";
import Helpers, { D, formatNum } from "src/utils/helpers";
import { useClientById, useMe, useMyToast } from "src/hooks";
import * as moment from "moment-timezone";
import { Touchable } from "src/components/Touchable";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AssetIcon } from "src/components/styled/Assets";
import numbro from "numbro";
import { ActionSheet, Copy, Info } from "src/components";
import qs from "query-string";
import WhiteBox from "src/components/styled/WhiteBox";
import { useInterval } from "src/hooks/common";
import StatusTag from "src/components/styled/StatusTag";
import { negative, positive } from "src/theme/colors";
import {
  BaseAccountFields,
  BaseAccountWithCurrentJobFields,
  BaseAssetFields,
  BaseClientFields,
} from "src/api/fragments";
import { getImageKitCDNUrl } from "src/utils/imagekit";
import BigNumber from "bignumber.js";
import { AccountPosition } from "./Position";
import ReactPaginate from "react-paginate";
import { AssetKeyInfoContext } from "../utils";
import { useTheme } from "src/hooks/useTheme";

type PortfolioAssetWithAssetInfo = PortfolioBalance & {
  assetInfo: Maybe<BaseAssetFields>;
};

export type PortfolioAccountWithAssetInfo = any & {
  assets: PortfolioAssetWithAssetInfo[];
};

const Accounts = () => {
  const { clientId, assetKey } = useParams<{
    clientId: string;
    assetKey: string;
  }>();
  const { client } = useClientById(clientId);
  const [isShown, setShown] = useState(true);

  const { position } = useContext(AssetKeyInfoContext);
  const positions = useMemo(() => position?.positions ?? [], [position]);
  const theme = useTheme();

  return (
    <Box
      border="none"
      paddingBottom="5rem"
      marginTop="1.5rem"
      style={{ width: "100%" }}
    >
      <HStack flex={1} marginBottom="1.25rem" alignItems="center">
        <Flex alignItems="center" flex={1}>
          <Text
            color={theme.header}
            fontSize={18}
            fontWeight="semibold"
            marginRight="5px"
          >
            Balances
          </Text>
        </Flex>
      </HStack>

      <div>
        <WhiteBox
          style={{
            boxShadow: "none", // other.lighterBoxShadow,
          }}
          padding="5px 0"
          marginTop="0"
          h="100%"
          minW="none"
        >
          {positions ? (
            <AccountPositions positions={positions} client={client} />
          ) : (
            <div
              style={{
                padding: "2rem",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                color: theme.header,
              }}
            >
              No balances for asset
            </div>
          )}
        </WhiteBox>
      </div>
    </Box>
  );
};

const AccountPositions = ({
  positions,
  client,
}: {
  positions: PortfolioAssetPosition[];
  client: Maybe<BaseClientFields>;
}) => {
  const numPerPage = 10;
  const [page, setPage] = useState(0);

  return (
    <div>
      {positions.map((breakdown, index) => (
        <AccountPosition
          key={index}
          position={breakdown}
          clientId={client?.id || ""}
          isLast={index === positions.length - 1}
          currency={client?.currency || CurrencyCodeEnum.Usd}
        />
      ))}
    </div>
  );
};

export default Accounts;
