import { CSSProperties, useState } from "react";
import { HStack, Text, Tooltip, useClipboard } from "@chakra-ui/react";
import { colors } from "src/theme";
import { Maybe } from "src/core";

type CopyProps = {
  iconStyle?: any;
  value: string;
  copyMessage?: string;
  label?: string;
  labelStyle?: CSSProperties;
  iconPosition?: Maybe<"left" | "right">;
  containerStyle?: CSSProperties;
};

export const Copy = ({
  value,
  copyMessage,
  iconStyle,
  label,
  labelStyle,
  iconPosition = "left",
  containerStyle,
}: CopyProps) => {
  const [isOpen, setOpen] = useState(false);

  const copyCode = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
    navigator.clipboard.writeText(value || "");
    setTimeout(() => setOpen(false), 2500);
  };

  return (
    <Tooltip
      zIndex={1401}
      trigger="click"
      isOpen={isOpen}
      bg={colors.black}
      placement="top"
      borderRadius="0.25rem"
      label={copyMessage || "Copied!"}
      padding={{ base: "0.5rem 1rem" }}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...containerStyle,
        }}
      >
        {iconPosition === "left" && (
          <span
            data-clipboard-text={value}
            onClick={copyCode}
            className="copy-value"
            style={{
              position: "relative",
              marginRight: 5,
              color: "rgb(155, 156, 158)",
              cursor: "pointer",
              padding: "5px 10px",
              display: "inline-block",
              borderRadius: 5,
              fontSize: 15,
              ...iconStyle,
            }}
          >
            <i className="fa-sharp fa-copy" />
          </span>
        )}
        <Text style={labelStyle}>{label}</Text>
        {iconPosition === "right" && (
          <span
            data-clipboard-text={value}
            onClick={copyCode}
            className="copy-value"
            style={{
              position: "relative",
              marginRight: 5,
              color: "rgb(155, 156, 158)",
              cursor: "pointer",
              padding: "5px 10px",
              display: "inline-block",
              borderRadius: 5,
              fontSize: 15,
              ...iconStyle,
            }}
          >
            <i className="fa-sharp fa-copy" />
          </span>
        )}
      </span>
    </Tooltip>
  );
};
