import { useQuery } from "@apollo/client";
import {
  Box,
  BoxProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { Query } from "src/api/generated/types";
import { useRecalculate } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors, other } from "src/theme";
import { formatNum, getTimeMessage } from "src/utils/helpers";
import { Touchable } from "../Touchable";
import { AwakenTooltip } from "./AwakenTooltip";
import { Button } from "./Button";
import { Info } from "./Info";

type RecalculateButtonProps = BoxProps & {
  message?: string;
  buttonProps?: BoxProps;
  textProps?: TextProps;
  iconStyle?: CSSProperties;
  showReplay?: boolean;
};

export function RecalculateButton({
  message,
  buttonProps,
  textProps,
  iconStyle,
  showReplay,
  ...props
}: RecalculateButtonProps) {
  const { clientId } = useParams();
  const _recalculate = useRecalculate();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const { data, refetch } = useQuery<Pick<Query, "getReplayOverview">>(
    api.graph.getReplayInfo,
    {
      variables: {
        clientId: clientId,
      },
      skip: false,
      fetchPolicy: "cache-and-network", // To ensure data updates
    }
  );

  useEffect(() => {
    if (showReplay || isOpen) {
      refetch();
    }
  }, [showReplay, isOpen]);

  const replayInfo = data?.getReplayOverview;

  console.log(replayInfo);

  const recalculate = useCallback(
    async (doNotReplay?: boolean) => {
      if (!showReplay) {
        await _recalculate(true);
        setIsOpen(false);
        return;
      }
      // if no replay or it isn't needed, replay the whole thing
      if (!replayInfo || !replayInfo.isReplayNeeded) {
        await _recalculate(true);
        setIsOpen(false);
        return;
      }
      await _recalculate(doNotReplay);
      setIsOpen(false);
    },
    [showReplay, replayInfo]
  );

  const isLarge = useIsLargeScreen();
  const { background, secondaryBackground, border, text } = useTheme();

  const RecalcBtn = (
    <div style={{ display: "inherit" }}>
      <Touchable
        bg={secondaryBackground}
        _hover={{ bg: buttonProps?.bg || secondaryBackground }}
        padding={isLarge ? "0.5rem 1rem" : "0.5rem"}
        borderRadius={other.borderRadius}
        cursor="pointer"
        onClick={() => recalculate()}
        labelStyle={{ color: colors.white }}
        w="auto"
        {...buttonProps}
      >
        <i
          className="fa-sharp fa-calculator"
          style={{
            color: text,
            ...iconStyle,
          }}
        />
        <Text
          fontSize={isLarge ? "sm" : "xs"}
          padding="0"
          color={text}
          fontWeight="semibold"
          {...textProps}
        >
          Recalculate
        </Text>
      </Touchable>
    </div>
  );

  if (!showReplay) {
    return RecalcBtn;
  }

  if (replayInfo && replayInfo.isUsingReplay) {
    // popover
    const hasTime =
      replayInfo.estimatedTimeInMS && replayInfo.estimatedTimeInMS > 0;

    return (
      <Popover
        trigger="hover"
        placement="bottom-end"
        onOpen={() => setIsOpen(true)}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <PopoverTrigger>{RecalcBtn}</PopoverTrigger>
        <PopoverContent
          overflowY="scroll"
          style={{
            width: "100%",
            maxWidth: 400,
            boxShadow: other.boxShadow,
            background: background,
            border: `1px solid ${border}`,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0 0.5rem",
            }}
          >
            <Box
              style={{
                width: 30,
                height: 30,
                display: "flex",
                alignItems: "center",
                borderRadius: 100,
                justifyContent: "center",
                background: colors.primary,
              }}
            >
              <i
                style={{
                  color: colors.white,
                  fontSize: 16,
                }}
                className="fa-solid fa-fast-forward"
              />
            </Box>

            <Text
              style={{ padding: "0.5rem 0.5rem" }}
              flex={1}
              fontSize="sm"
              fontWeight="500"
              color={text}
            >
              {formatNum(replayInfo.numberOfTransactions)} transactions need
              recalculating.
              {hasTime ? (
                <>
                  <br />
                  This should take ~
                  {getTimeMessage(replayInfo.estimatedTimeInMS || 0)}.
                </>
              ) : (
                ""
              )}
            </Text>

            <AwakenTooltip message="If you want to hard recalculate from the very beginning, click this. Hard recalculates are much much slower.">
              <div>
                <Touchable onClick={() => recalculate(true)}>
                  <i
                    style={{ color: theme.text }}
                    className="fa-solid fa-sync"
                  />
                </Touchable>
              </div>
            </AwakenTooltip>
          </Box>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Popover
      trigger="hover"
      placement="bottom-end"
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <PopoverTrigger>{RecalcBtn}</PopoverTrigger>
      <PopoverContent
        overflowY="scroll"
        style={{
          width: "100%",
          maxWidth: 400,
          boxShadow: other.boxShadow,
          background: background,
          border: `1px solid ${border}`,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 0.5rem",
          }}
        >
          <Box
            style={{
              width: 30,
              height: 30,
              display: "flex",
              alignItems: "center",
              borderRadius: 100,
              justifyContent: "center",
              background: colors.positive,
            }}
          >
            <i
              style={{
                color: colors.white,
                fontSize: 16,
              }}
              className="fa-sharp fa-turtle"
            />
          </Box>

          <Text
            style={{ padding: "0.5rem 0.5rem" }}
            flex={1}
            fontSize="sm"
            fontWeight="500"
            color={text}
          >
            It doesn't seem like you have any changes. Do you want to hard
            recalculate your tax history from the very beginning? This can take
            a while.{" "}
            <Info message="Awaken implements algorithms to make it so we only recalculate transactions you have changed, which makes it a lot faster! If you see an issue though and want to recalculate from the very beginning, you can click the button below!" />
          </Text>
        </Box>

        <Box
          style={{
            padding: "0.5rem",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <Button
            size="sm"
            style={{
              height: 35,
              padding: "0.5rem",
            }}
            variant="primary"
            onClick={() => recalculate(true)}
          >
            Yes, hard recalculate{" "}
            <i
              style={{
                marginLeft: 10,
              }}
              className="fa-solid fa-sync"
            />
          </Button>
        </Box>
      </PopoverContent>
    </Popover>
  );
}
