import {
  HStack,
  Box,
  Tooltip,
  Divider,
  Spinner,
  Image,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { BaseAssetFields, BaseUserFields } from "src/api/fragments";
import WhiteBox from "src/components/styled/WhiteBox";
import {
  Copy,
  Input,
  Select,
  Option,
  Info,
  Button,
  ActionSheet,
} from "src/components/styled";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  FetchPolicy,
  NetworkStatus,
  useApolloClient,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { api } from "src/api";
import {
  AssetTypeEnum,
  AssetWarning,
  CurrencyCodeEnum,
  PortfolioResponse,
  PortfolioSortColumnEnum,
} from "src/api/generated/types";
import _, { debounce, isEmpty, keyBy, truncate } from "lodash";
import { colors, other } from "src/theme";
import { Maybe, hasValue } from "src/core";
import { Touchable } from "src/components/Touchable";
import ReactPaginate from "react-paginate";
import { AssetRow } from "./AssetRow";
import { NAME_FLEX, VALUE_FLEX } from "./constants";
import Helpers from "src/utils/helpers";
import { PROVIDER_TO_LOGO_URL } from "src/components/modals/AccountModal/constants";
import { useURLSearch } from "src/hooks/useURLSearch";
import { usePortfolioFilter } from "./usePortfolioFilter";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";

function Assets() {
  const { clientId } = useParams<{ clientId: string }>();
  const { client, accounts, assets, getAssets } = useClientById(clientId);

  const {
    setAssetTypeFilter,
    setIsAscending,
    setPage,
    setProvider,
    setSearch,
    page,
    search,
    assetTypeFilter,
    provider,
    isAscending,
    sortColumn,
    setSortColumn,
    hideExtraAssetInfo,
    getPortfolio,
    isLoading,
    balances,
    totalPages,
  } = usePortfolioFilter();

  const location = useLocation();
  const toast = useMyToast();

  // note used atm -> need to fix
  // const [fetchAssetWarnings, { data: assetWarningsData }] = useLazyQuery<{
  //   getAssetWarnings: AssetWarning[];
  // }>(api.assets.getAssetWarnings, {
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: "cache-and-network",
  // });

  const [syncMetadata] = useMutation(api.assets.syncMetadata);

  const apollo = useApolloClient();

  const accountById = useMemo(() => keyBy(accounts, (a) => a.id), [accounts]);
  const assetById = useMemo(() => keyBy(assets, (a) => a.id), [assets]);

  const _refreshAssets = useCallback(async () => {
    // console.log("[fetching portfolio]");
    try {
      await getPortfolio("network-only", {
        useCache: false, // force a refresh
      });
      toast.show({
        status: "success",
        message: "Refreshed assets successfully!",
      });
    } catch (err) {
      console.log(err);
    }
  }, [getPortfolio]);

  useEffect(() => {
    console.log(`[fetching portfolio]`);
    getPortfolio();
    // so we get the new profile photo
    apollo.refetchQueries({ include: [api.clients.retrieve] });
  }, [getPortfolio, location.pathname]); // everytime user gets to this page, recall it. otherwise it won't be recalled

  useEffect(() => void getAssets(), []);

  // refresh every 60 seconds
  // useInterval(() => _getPortfolio(), REFRESH_INTERVAL);
  const theme = useTheme();

  const _hardRefreshAssetImages = async () => {
    try {
      await syncMetadata({
        variables: {
          clientId,
        },
        refetchQueries: [api.clients.assets, api.portfolio.get],
      });

      toast.show({
        status: "info",
        message:
          "We started refreshing the names, symbols, and images of your assets in the background.",
      });
    } catch (err) {
      console.error(err);
      toast.show({
        status: "error",
        message: "Error syncing metadata",
      });
    }
  };

  // const warnings = assetWarningsData?.getAssetWarnings || [];
  // const warningByAssetId = useMemo(
  //   () => keyBy(warnings, (w) => w.assetId),
  //   [warnings]
  // );

  const isLarge = useIsLargeScreen();

  if (!isLoading && !assets.length) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        flexGrow: 0,
        // marginTop: "1.5rem", FIXME: add back when ready
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        alignSelf="flex-start"
        alignContent="flex-start"
        w="100%"
        // marginTop="2rem" FIXME: add back when ready
      >
        <HStack alignItems="center" w="100%" marginBottom="1rem">
          {isLarge ? (
            <HStack flex={1}>
              <SearchAssets search={search} setSearch={setSearch} />
              <AssetTypeFilter
                setAssetTypeFilter={setAssetTypeFilter}
                assetTypeFilter={assetTypeFilter}
              />
              <ChainSelector provider={provider} setProvider={setProvider} />
              <Flex flex={1} justifyContent="flex-end">
                <Touchable
                  label="Refresh"
                  iconName="fa-sharp fa-sync"
                  onClick={_refreshAssets}
                />
                <ActionSheet
                  popover={{ placement: "bottom-end" }}
                  content={{ width: 250 }}
                  commands={[
                    // {
                    //   label: "Refresh portfolio",
                    //   // show icon font awesome:
                    //   iconName: "fa-sharp fa-piggy-bank",
                    //   onClick: _refreshAssets,
                    // },
                    {
                      label: "Sync assets",
                      // show icon font awesome:
                      iconName: "fa-sharp fa-sync",
                      infoMessage:
                        "Updates images and names of assets if they have changed. This is helpful for when Awaken adds support for new tokens, so the image for them may be updated.",
                      onClick: _hardRefreshAssetImages,
                    },
                    // {
                    //   label: hideExtraAssetInfo
                    //     ? "Show breakdown"
                    //     : "Hide breakdown",
                    //   // show icon font awesome:
                    //   iconName: hideExtraAssetInfo
                    //     ? "fa-sharp fa-eye"
                    //     : "fa-sharp fa-eye-slash",
                    //   onClick: () => setHideExtraAssetInfo(!hideExtraAssetInfo),
                    // },
                  ].filter(hasValue)}
                >
                  <Touchable
                    // open font awesome icon
                    iconName="fa-sharp fa-ellipsis-v"
                    style={{
                      fontSize: 14,
                      cursor: "pointer",
                    }}
                  />
                </ActionSheet>
              </Flex>
            </HStack>
          ) : (
            <VStack w="100%" alignItems="flex-start">
              <SearchAssets search={search} setSearch={setSearch} />
              <HStack w="100%">
                <Flex flex={1}>
                  <AssetTypeFilter
                    setAssetTypeFilter={setAssetTypeFilter}
                    assetTypeFilter={assetTypeFilter}
                    style={{
                      marginRight: "0.5rem",
                    }}
                  />
                  <ChainSelector
                    provider={provider}
                    setProvider={setProvider}
                  />
                </Flex>
                <Touchable
                  iconName="fa-sharp fa-sync"
                  onClick={_refreshAssets}
                />
              </HStack>
            </VStack>
          )}
        </HStack>

        <WhiteBox
          padding="0"
          w="100%"
          marginTop="1rem"
          style={{
            boxShadow: "none",
            //minHeight: LIMIT * 70
          }} // just so less jolty when navigating
          border="none"
        >
          <AssetHeader
            isAscending={isAscending}
            setIsAscending={setIsAscending}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
          />

          {isLoading ? (
            <div
              style={{
                padding: "10rem 3rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          ) : (
            <>
              {balances.map((balance) => (
                <AssetRow
                  showExtraInfo={!hideExtraAssetInfo}
                  key={balance.assetKey}
                  portfolioBalance={balance}
                  accountById={accountById}
                  assetById={assetById}
                  warning={null} // warningByAssetId[balance.assetKey] ??
                  clientId={clientId || ""}
                  currency={client?.currency || CurrencyCodeEnum.Usd}
                />
              ))}
            </>
          )}

          <Divider style={{ borderColor: "transparent", margin: "1rem 0" }} />

          <HStack padding="0.25rem 0">
            <HStack
              flex={1}
              marginLeft={5}
              alignItems="center"
              justifyContent="flex-end"
            >
              <ReactPaginate
                breakLabel=".."
                nextLabel={<i className="fa-sharp fa-chevron-right" />}
                onPageChange={({ selected }) => setPage(selected)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                forcePage={page}
                initialPage={page || 0}
                pageCount={totalPages}
                previousLabel={<i className="fa-sharp fa-chevron-left" />}
                pageClassName={
                  theme.theme === "light" ? "page-item" : "dark-page-item"
                }
                pageLinkClassName={
                  theme.theme === "light" ? "page-link" : "dark-page-link"
                }
                previousClassName={
                  theme.theme === "light" ? "page-item" : "dark-page-item"
                }
                previousLinkClassName={
                  theme.theme === "light" ? "page-link" : "dark-page-link"
                }
                nextClassName={
                  theme.theme === "light" ? "page-item" : "dark-page-item"
                }
                nextLinkClassName={
                  theme.theme === "light" ? "page-link" : "dark-page-link"
                }
                breakClassName={
                  theme.theme === "light" ? "page-item" : "dark-page-item"
                }
                breakLinkClassName={
                  theme.theme === "light" ? "page-link" : "dark-page-link"
                }
                containerClassName="pagination"
                activeClassName="active"
              />
            </HStack>
          </HStack>
        </WhiteBox>
      </Box>
      {/* {isSuperUser && (
        <Button
          bg={colors.gray2}
          marginRight="0.5rem"
          _hover={{ bg: colors.gray80 }}
          padding="0.5rem 1rem"
          borderRadius={other.borderRadius}
          onClick={_hardRefreshAssetImages}
        >
          <i
            style={{ marginRight: 5, color: colors.green50 }}
            className="fa-sharp fa-refresh"
          />{" "}
          <Text
            color={colors.gray20}
            fontSize="sm"
            padding="0"
            fontWeight="semibold"
          >
            Refresh Asset Metadata{" "}
            <Info message="Updates images and names of assets if they have changed." />
          </Text>
        </Button>
      )} */}
    </div>
  );

  // <Asset info={pa} />

  //   return (
  //     <div>
  //       <AssetModal />

  //       <HStack marginTop="1rem">
  //         <Heading marginTop="0" flex={1} size="lg">
  //           Assets ({assets?.length || 0})
  //         </Heading>
  //         <Button
  //           leftIcon={<i style={{ fontSize: 12 }} className="fa-sharp fa-plus" />}
  //           display="flex"
  //           justifyContent="center"
  //           alignItems="center"
  //           onClick={_onClickCreateAsset}
  //           bg={colors.gray80}
  //           padding="0.75rem 1.25rem"
  //         >
  //           Create Asset
  //         </Button>
  //       </HStack>

  //       <WhiteBox padding="0">
  //         <TableContainer>
  //           <Table variant="simple">
  //             <Thead>
  //               <Tr>
  //                 <Th>Name</Th>
  //                 <Th style={{ textAlign: "center" }}>Symbol</Th>
  //                 <Th style={{ textAlign: "center" }}>Standard</Th>
  //                 <Th>Linked</Th>
  //               </Tr>
  //             </Thead>
  //             <Tbody>
  //               {assets.map((a) => (
  //                 <Asset asset={a} key={a.id} />
  //               ))}
  //             </Tbody>
  //           </Table>
  //         </TableContainer>
  //       </WhiteBox>
  //     </div>
  //   );
}

const SearchAssets = ({
  search,
  setSearch,
}: {
  search: string;
  setSearch: (s: string) => void;
}) => {
  const isLarge = useIsLargeScreen();

  return (
    <Input
      iconLeft={
        <i
          style={{
            color: colors.gray40,
            fontSize: 14,
            position: "relative",
            top: -2, // super hacky
          }}
          className="fa-sharp fa-search"
        />
      }
      containerStyle={{
        width: "100%",
        maxWidth: isLarge ? 275 : "inherit",
        marginBottom: 0,
        height: "35px !important",
      }}
      defaultValue={search}
      onChange={(e) => setSearch(e.target.value)}
      focusBorderColor="none"
      style={{
        borderRadius: 7,
        marginLeft: 1,
        outline: "none",
        backgroundColor: colors.gray90,
        fontSize: 13,
        padding: "0.05rem 1rem 0.05rem 2.25rem",
        color: colors.gray40,
        fontWeight: 600,
        marginRight: "0.25rem",
        fontFamily: "Mona Sans, sans-serif",
      }}
      height="35px !important" // super hacky
      placeholder="Search assets"
    />
  );
};

// const Asset = ({ info }: { info: AssetBox }) => {
//   return (
//     <WhiteBox
//       margin="0"
//       padding="0"
//       w="10rem"
//       minW={undefined}
//       h="15rem"
//       marginRight="1rem"
//       marginBottom="1rem"
//     >
//       <AssetIcon asset={info.assetInfo} />
//       <Text fontWeight="bold">{info.assetInfo.symbol}</Text>
//       <Text>Amount: {info.amount}</Text>
//       <Text>Cost: {info.fiatAmount}</Text>
//     </WhiteBox>
//   );
// };

const AssetHeader = ({
  isAscending,
  setIsAscending,
  sortColumn,
  setSortColumn,
}: {
  isAscending: boolean;
  setIsAscending: (b: boolean) => void;
  sortColumn: PortfolioSortColumnEnum;
  setSortColumn: (s: PortfolioSortColumnEnum) => void;
}) => {
  const isLarge = useIsLargeScreen();

  return (
    <HStack
      width="100%"
      padding="0.25rem 0.25rem 0.75rem 0.25rem"
      marginBottom="0.5rem"
      style={{
        borderBottom: `1px solid ${colors.gray85}`,
        justifyContent: "space-between",
      }}
    >
      {/* <Box width={35} /> */}

      <Box
        display="flex"
        alignItems="center"
        position="relative"
        fontWeight="500"
        fontSize={isLarge ? "sm" : "xs"}
        style={{
          color: colors.gray10,
          flex: isLarge ? NAME_FLEX : NAME_FLEX - 1,
          marginRight: "calc(35px + 0.5rem)", // bc of the width of the image. hacky
        }}
        // marginLeft="1.25rem !important"
      >
        Name
      </Box>

      {/* <Tooltip label="Your cost basis/purchase price.">
        <Box
          style={{
            fontWeight: "500",
            cursor: "pointer",
            color: colors.gray10,
            flex: VALUE_FLEX,
          }}
          onClick={() => {
            setSortColumn(PortfolioSortColumnEnum.CostBasis);
            setIsAscending(!isAscending);
          }}
        >
          Basis{" "}
          <SortTriangle
            isSorted={PortfolioSortColumnEnum.CostBasis === sortColumn}
            isAscending={
              isAscending && PortfolioSortColumnEnum.CostBasis === sortColumn
            }
          />
        </Box>
      </Tooltip> */}

      <Tooltip label="What the asset is currently worth.">
        <Box
          style={{
            fontWeight: "500",
            cursor: "pointer",
            color: colors.gray10,
            flex: VALUE_FLEX,
            marginLeft: 0,
          }}
          fontSize={isLarge ? "sm" : "xs"}
          onClick={() => {
            setSortColumn(PortfolioSortColumnEnum.CurrentValue);
            setIsAscending(!isAscending);
          }}
        >
          Value{" "}
          <SortTriangle
            isSorted={PortfolioSortColumnEnum.CurrentValue === sortColumn}
            isAscending={
              isAscending && PortfolioSortColumnEnum.CurrentValue === sortColumn
            }
          />
        </Box>
      </Tooltip>

      <Box
        style={{
          alignItems: "center",
          justifyContent: "flex-start",
          color: colors.gray10,
          display: "flex",
          fontWeight: "500",
          marginLeft: 0,
          cursor: "pointer",
          flex: VALUE_FLEX,
        }}
        fontSize={isLarge ? "sm" : "xs"}
        onClick={() => {
          setSortColumn(PortfolioSortColumnEnum.GainOrLoss);
          setIsAscending(!isAscending);
        }}
      >
        Tot. Return{" "}
        <SortTriangle
          isSorted={PortfolioSortColumnEnum.GainOrLoss === sortColumn}
          isAscending={
            isAscending && PortfolioSortColumnEnum.GainOrLoss === sortColumn
          }
        />
      </Box>

      <Box width="50px" />
    </HStack>
  );
};

const SortTriangle = ({
  isAscending,
  isSorted,
}: {
  isAscending: boolean;
  isSorted: boolean;
}) => {
  const isLarge = useIsLargeScreen();

  return (
    <i
      className={`fa-sharp fa-angle-${isAscending ? "up" : "down"}`}
      style={{
        marginLeft: "0.3rem",
        fontSize: isLarge ? 16 : 12,
        position: "relative",
        top: 2,
        color: isSorted ? colors.black : colors.gray70,
      }}
    />
  );
};

const ChainSelector = ({
  setProvider,
  provider,
}: {
  setProvider: (provider: Maybe<string>) => void;
  provider: Maybe<string>;
}) => {
  return (
    <ActionSheet
      content={{ width: 200 }}
      commands={[
        {
          label: "All chains",
          iconName: "fa-sharp fa-layer-group",
          onClick: () => setProvider(null),
        },
        {
          label: "Ethereum",
          iconImageSrc: "https://assets.awaken.tax/icons/eth.png",
          onClick: () => setProvider("ethereum"),
        },
        {
          label: "Arbitrum",
          iconImageSrc: "https://assets.awaken.tax/icons/arbitrum.png",
          onClick: () => setProvider("arbitrum"),
        },
        {
          label: "Optimism",
          iconImageSrc: "https://assets.awaken.tax/icons/optimism.svg",
          onClick: () => setProvider("optimism"),
        },
        {
          label: "Avalanche",
          iconImageSrc: "https://assets.awaken.tax/icons/avax.png",
          onClick: () => setProvider("avalanche"),
        },
        {
          label: "BSC",
          iconImageSrc: "https://assets.awaken.tax/icons/bnb.png",
          onClick: () => setProvider("bsc"),
        },
        {
          label: "Fantom",
          iconImageSrc: "https://assets.awaken.tax/icons/fantom.png",
          onClick: () => setProvider("fantom"),
        },
        {
          label: "Base",
          iconImageSrc: "https://assets.awaken.tax/icons/base.png",
          onClick: () => setProvider("base"),
        },
        {
          label: "Solana",
          iconImageSrc: "https://assets.awaken.tax/icons/solana.svg",
          onClick: () => setProvider("solana"),
        },
        {
          label: "Coinbase",
          iconImageSrc: "https://assets.awaken.tax/icons/coinbase.svg",
          onClick: () => setProvider("coinbase"),
        },
      ]}
    >
      <HStack
        style={{
          backgroundColor: colors.gray90,
          border: `1px solid ${colors.gray80}`,
          borderRadius: 7,
          padding: "0.4rem 0.75rem",
          cursor: "pointer",
          color: colors.gray20,
        }}
      >
        {provider ? (
          <Image
            w="1rem"
            h="1rem"
            borderRadius="100%"
            src={PROVIDER_TO_LOGO_URL[(provider || "").toLowerCase()] || ""}
            right="-0.4rem !important"
            bottom="-0.4rem !important"
            border={"1px solid " + colors.gray80}
            bg={colors.white}
          />
        ) : (
          <i className="fa-sharp fa-cubes" style={{ marginRight: 5 }} />
        )}
        <Text fontSize={13} fontWeight="semibold">
          {Helpers.capitalize(provider || "Chains")}
        </Text>
        <i style={{ marginLeft: 5 }} className="fa-sharp fa-chevron-down" />
      </HStack>
    </ActionSheet>
  );
};

const AssetTypeFilter = ({
  assetTypeFilter,
  setAssetTypeFilter,
  style,
}: {
  assetTypeFilter: Maybe<AssetTypeEnum>;
  setAssetTypeFilter: (assetType: Maybe<AssetTypeEnum>) => void;
  style?: CSSProperties;
}) => {
  const isLarge = useIsLargeScreen();

  const _getLabel = (t: Maybe<AssetTypeEnum>) => {
    if (!t) return "All";
    if (t === AssetTypeEnum.FiatCurrency) return "Fiat";
    if (t === AssetTypeEnum.FungibleToken) return "Coins";
    if (t === AssetTypeEnum.Nft) return "NFTs";
    return "All";
  };

  return (
    <HStack marginRight={isLarge ? "0.5rem" : "0"}>
      <ActionSheet
        content={{ width: 200 }}
        commands={[
          {
            label: "All",
            iconName: "fa-sharp fa-layer-group",
            onClick: () => setAssetTypeFilter(null),
          },
          {
            label: "Coins",
            iconName: "fa-sharp fa-coins",
            onClick: () => setAssetTypeFilter(AssetTypeEnum.FungibleToken),
          },
          {
            label: "NFTs",
            iconName: "fa-sharp fa-hexagon-image",
            onClick: () => setAssetTypeFilter(AssetTypeEnum.Nft),
          },
        ]}
      >
        <div
          style={{
            backgroundColor: colors.gray90,
            border: `1px solid ${colors.gray80}`,
            borderRadius: 10,
            padding: "0.4rem 0.75rem",
            cursor: "pointer",
            fontSize: 13,
            fontWeight: "bold",
            color: colors.gray20,
            ...style,
          }}
        >
          <i className="fa-sharp fa-filter" style={{ marginRight: 5 }} />{" "}
          {_getLabel(assetTypeFilter)}{" "}
          <i style={{ marginLeft: 5 }} className="fa-sharp fa-chevron-down" />
        </div>
      </ActionSheet>
    </HStack>
  );
};

const AssetFilterOption = ({
  isActive,
  label,
  iconName,
  onClick,
}: {
  isActive?: boolean;
  label: string;
  iconName: string;
  onClick?: () => void;
}) => {
  return (
    <div
      style={{
        backgroundColor: isActive ? colors.gray10 : colors.gray85,
        borderRadius: 7,
        padding: "0.5rem 0.75rem",
        cursor: "pointer",
        fontSize: 12,
        fontWeight: "bold",
        color: isActive ? "white" : colors.gray20,
      }}
      onClick={onClick}
    >
      {label} <i style={{ marginLeft: 2 }} className={iconName} />
    </div>
  );
};

export default Assets;
