import { useMutation } from "@apollo/client";
import {
  Box,
  Checkbox,
  Container,
  FormLabel,
  HStack,
  Image,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import {
  AccountProviderEnum,
  AccountTypeEnum,
  BatchWalletInputParams,
  Mutation,
  MutationCreateBatchWalletsArgs,
} from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { MyToast } from "src/components/MyToast";
import { Button, Info, Input, Select } from "src/components/styled";
import { Touchable } from "src/components/Touchable";
import { useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import {
  ALLOWED_EVM_PROVIDERS,
  EVM_PROVIDERS,
  isValidEVMAddress,
  isValidSolanaAddress,
} from "src/modules/ledger/accounts";
import { colors } from "src/theme";
import * as yup from "yup";
import { INTEGRATIONS } from "../AccountModal/constants";

type Props = InjectedProps & {
  onSuccess: () => void;
};

const walletSchema = yup.object().shape({
  provider: yup.string().optional().nullable(),
  label: yup.string().optional().nullable(),
  address: yup.string().optional().nullable(),
  shouldUploadAllEVM: yup.boolean().nullable(),
  includeStakingRewards: yup.boolean().nullable(),
  includeOrdinals: yup.boolean().nullable(),
  isLargeAccount: yup.boolean().nullable(),
});

type WalletFormValues = {
  provider?: string | null;
  label?: string | null;
  address?: string | null;
  shouldUploadAllEVM?: boolean | null;
  includeStakingRewards?: boolean | null;
  includeOrdinals?: boolean | null;
  isLargeAccount?: boolean | null;
};

const schema = yup.object().shape({
  wallets: yup.array().of(walletSchema),
});

type FormValues = {
  wallets: WalletFormValues[];
};

const DEFAULT_WALLET = {
  provider: "ethereum",
  label: "",
  address: "",
  shouldUploadAllEVM: true,
  includeStakingRewards: null,
  includeOrdinals: null,
  isLargeAccount: null,
};

const DEFAULT_VALUES: FormValues = {
  wallets: [DEFAULT_WALLET],
};

function _BatchWalletModal({ handleHide, onSuccess, show: isVisible }: Props) {
  const toast = useToast();
  const { clientId } = useParams<{ clientId: string }>();
  const myToast = useMyToast();
  const [skipRecalculate, setSkipRecalculate] = useState(false);
  const [createBatchWallets, { loading }] = useMutation<
    Pick<Mutation, "createBatchWallets">
  >(api.accounts.createBatch);

  const formProps = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = formProps;

  const wallets = watch("wallets");

  const _onHide = () => {
    if (wallets.length > 1) {
      const isConfirmed = confirm(
        `All your changes won't be saved if you close this modal. Are you sure you want to close?`
      );
      if (isConfirmed) {
        handleHide();
      }
    } else {
      handleHide();
    }
  };

  const addWallet = () => {
    const lastWallet = wallets[wallets.length - 1];

    const newWallet: any = {
      ...DEFAULT_WALLET,
      // use same as last wallet
      provider: lastWallet.provider,
    };

    setValue("wallets", [...wallets, newWallet]);
  };

  const _onCreateBatchWallet = useCallback(async () => {
    try {
      const rawWallets = formProps.getValues("wallets");

      // validate all the wallets, no bitcoin zpub, xpub, ypubc allowed
      for (const wallet of rawWallets) {
        const address = wallet.address || "";
        const provider = wallet.provider || "";
        const trimmedAddress = address.replace(" ", ""); // remove spaces

        const isSUI = provider?.toLowerCase() === "sui";
        const isEVM = EVM_PROVIDERS.has(provider?.toLowerCase());

        if (isSUI) {
          // just make sure starts with 0x
          if (!trimmedAddress.startsWith("0x")) {
            return toast({
              position: "top",
              render: () => (
                <MyToast
                  message={"Invalid Sui address. Please check and try again."}
                  status="error"
                />
              ),
            });
          }
        }

        if (isEVM) {
          const isValidEMV = isValidEVMAddress(trimmedAddress);

          if (!isValidEMV) {
            return toast({
              position: "top",
              render: () => (
                <MyToast
                  message={"Invalid EVM address. Please check and try again."}
                  status="error"
                />
              ),
            });
          }
        }

        if (
          provider?.toLowerCase() ===
            AccountProviderEnum.Solana.toLowerCase() &&
          !isValidSolanaAddress(trimmedAddress)
        ) {
          return toast({
            position: "top",
            render: () => (
              <MyToast
                message={"Invalid Solana address. Please check and try again."}
                status="error"
              />
            ),
          });
        }

        if (
          provider?.toLowerCase() ===
            AccountProviderEnum.Bitcoin.toLowerCase() &&
          // if xpub ypub zpub etc
          (trimmedAddress.includes("xpub") ||
            trimmedAddress.includes("ypub") ||
            trimmedAddress.includes("zpub"))
        ) {
          return toast({
            position: "top",
            render: () => (
              <MyToast
                message={
                  "You can only upload xpub/ypub/zpub addresses one at a time. After you are done bulk uploading, you can click 'Bitcoin' and add your xpub/ypub/zpub address by itself."
                }
                status="error"
              />
            ),
          });
        }
      }

      // only less than 30 at a time
      if (rawWallets.length > 30) {
        return toast({
          position: "top",
          render: () => (
            <MyToast
              message="You can only upload 30 wallets at a time."
              status="error"
            />
          ),
        });
      }

      const createAccountArgs: MutationCreateBatchWalletsArgs = {
        clientId: clientId || "",
        wallets: rawWallets.map(
          (w): BatchWalletInputParams => ({
            provider: w.provider || "",
            address: w.address || "",
            label: w.label || "",
            isLargeAccount: w.isLargeAccount || false,
            includeStakingRewards: w.includeStakingRewards || false,
            includeOrdinals: w.includeOrdinals || false,
            shouldUploadAllEVM: w.shouldUploadAllEVM || false,
          })
        ),
      };

      console.log(createAccountArgs);

      await createBatchWallets({
        variables: createAccountArgs,
        refetchQueries: [api.clients.accounts, api.portfolio.get],
      });

      myToast.show({
        message: `Importing transactions 🤖 This may take a while.`,
        status: "success",
      });

      handleHide();

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      if (err instanceof Error) {
        toast({
          position: "top",
          render: () => (
            <MyToast message={(err as Error).message} status="error" />
          ),
        });
      }
    }
  }, [clientId, skipRecalculate]);

  const theme = useTheme();
  const isLarge = useIsLargeScreen();

  return (
    <FormProvider {...formProps}>
      <Modal
        title={<>Batch Wallet Upload</>}
        isVisible={isVisible}
        handleHide={_onHide}
        w={isLarge ? "100%" : "95%"}
        maxW="40rem"
        overflow="visible"
        Footer={
          <Box w="100%">
            <Button
              width="100%"
              variant="primary"
              isLoading={loading}
              onClick={_onCreateBatchWallet}
            >
              Add all wallets
            </Button>
          </Box>
        }
        headerProps={{
          style: {
            padding: isLarge ? "2rem" : "1rem",
          },
        }}
        footerProps={{
          style: {
            padding: isLarge ? "2rem" : "1rem",
          },
        }}
        bodyProps={{
          style: {
            padding: isLarge ? "0 2rem" : "1rem",
          },
        }}
      >
        <Container
          padding="0px"
          marginTop="0px !important"
          paddingBottom="2rem"
        >
          {wallets.map((wallet, index) => (
            <WalletComponent key={index} wallet={wallet} index={index} />
          ))}
          <Button
            style={{
              marginTop: 25,
              backgroundColor: theme.medBackground,
              borderColor: theme.border,
              color: theme.header,
            }}
            onClick={addWallet}
          >
            Add Wallet
          </Button>
        </Container>
      </Modal>
    </FormProvider>
  );
}

type WalletProps = {
  wallet: WalletFormValues;
  index: number;
};

const providerOptions = INTEGRATIONS.filter(
  (i) =>
    i.type === AccountTypeEnum.Wallet &&
    i.options.find((o) => o.modalName === "WalletAccountModal")
).map((i) => ({
  value: i.provider,
  label: i.name,
  logoUrl: i.logoUrl,
}));

const WalletComponent: React.FC<WalletProps> = ({ wallet, index }) => {
  const fullTheme = useTheme();
  const theme = fullTheme;
  const isEVM = EVM_PROVIDERS.has(wallet.provider?.toLowerCase() || "");

  const formProps = useFormContext<FormValues>();

  const { remove } = useFieldArray<FormValues>({
    control: formProps.control,
    name: "wallets",
  });

  const selectedProvider = providerOptions.find(
    (p) => p.value === wallet.provider
  );

  return (
    <div
      style={{
        marginBottom: 15,
        padding: 15,
        borderRadius: 10,
        position: "relative",
        backgroundColor: fullTheme.secondaryBackground,
      }}
    >
      <Touchable
        style={{
          position: "absolute",
          top: 10,
          zIndex: 10,
          right: 10,
        }}
        iconStyle={{ color: colors.red50 }}
        onClick={() => remove(index)}
        iconName="fa-sharp fa-trash"
      />

      <HStack>
        <Select
          options={providerOptions}
          placeholder=""
          selectProps={{
            isClearable: false,
            isMulti: false,
            isSearchable: false,
            onChange: (e: any) => {
              formProps.setValue(`wallets.${index}.provider`, e.value);
            },
            styles: {
              input: (base) => ({
                color: theme.header,
              }),
              multiValue: (base, state) => ({
                ...base,
                minWidth: "inherit",
                color: theme.header,
                background: theme.secondaryBackground,
              }),
              multiValueLabel: (base) => ({
                ...base,
                color: theme.header,
              }),
              dropdownIndicator: (base) => ({
                ...base,
                display: "none",
              }),
              control: (base) => ({
                ...base,
                backgroundColor: theme.background,
                borderColor: theme.border,
                border: "none",
              }),
              placeholder: (base) => ({
                ...base,
                color: theme.text,
              }),
              indicatorSeparator: (p) => ({ ...p, display: "none" }),
              singleValue: (p) => ({ ...p, color: theme.header }),
              container: (container) => ({
                ...container,
                backgroundColor: theme.background,
                borderRadius: "5px",
                border: "1px solid " + theme.border,
              }),
              valueContainer: (base) => ({
                ...base,
                display: "flex",
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            },
            components: {
              // make it so its the image of the selected provider
              SingleValue: () => (
                <HStack>
                  <Image
                    src={selectedProvider?.logoUrl || ""}
                    w="1.5rem"
                    h="1.5rem"
                  />
                </HStack>
              ),
              Option: ({ innerRef, innerProps, label, data }) => {
                const theme = useTheme();
                return (
                  <HStack
                    w="100%"
                    padding="0.5rem"
                    ref={innerRef}
                    {...innerProps}
                    style={{
                      textAlign: "center",
                    }}
                    _hover={{
                      bg: theme.secondaryBackground,
                      cursor: "pointer",
                    }}
                  >
                    {(data as any)?.logoUrl ? (
                      <Image
                        style={{ borderRadius: 3 }}
                        src={(data as any).logoUrl}
                        w="1.5rem"
                        h="1.5rem"
                      />
                    ) : (
                      <Box
                        style={{
                          width: "1rem",
                          height: "1rem",
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "revert",
                          alignItems: "center",
                          borderRadius: 5,
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                        bg={theme.secondaryBackground}
                      >
                        {(data as any).label?.charAt(0).toUpperCase()}
                      </Box>
                    )}
                  </HStack>
                );
              },
            },
          }}
          value={selectedProvider}
          containerStyle={{
            marginTop: 30,
            width: "100%",
            maxWidth: 50,
            display: "flex",
          }}
        />

        <VStack
          style={{
            marginLeft: 10,
            alignItems: "flex-start",
          }}
          flex={1}
        >
          <FormLabel
            style={{ marginBottom: 0 }}
            color={theme.header}
            fontSize={14}
          >
            Wallet Name{" "}
            <Info message="A nickname for your wallet so it's easier for you to identify." />
          </FormLabel>

          <Input
            value={wallet.label || ""}
            onChange={(e) => {
              formProps.setValue(`wallets.${index}.label`, e.target.value);
            }}
            label=""
            // Note: removing the default name for now just cause feel like it was confusing.
            // had a friend and dad, and they were confused by the placeholder (idk why seemed obvious to me)
            placeholder="Enter name..." // {defaultName}
            marginRight="0"
            margin="0"
            flex={1}
            style={{
              color: theme.header,
            }}
            borderColor={theme.border}
            focusBorderColor={colors.primary}
            containerStyle={{
              width: "100%",
            }}
          />
        </VStack>
        <VStack
          style={{
            alignItems: "flex-start",
          }}
          flex={1}
        >
          <FormLabel
            style={{ marginBottom: 0 }}
            color={theme.header}
            fontSize={14}
          >
            Address{" "}
            <Info message="Your wallet address or ENS address. For ETH/Polygon, this starts with 0x. Or an ENS address like bigduca.eth." />
          </FormLabel>

          <Input
            value={wallet.address || ""}
            onChange={(e) =>
              formProps.setValue(`wallets.${index}.address`, e.target.value)
            }
            placeholder={""}
            containerStyle={{
              width: "100%",
            }}
          />
        </VStack>
      </HStack>

      <HStack>
        {wallet.provider === "solana" && (
          <HStack margin="0.5rem" marginLeft={"0"}>
            <Checkbox
              isChecked={wallet.includeStakingRewards || false}
              onChange={(e) => {
                formProps.setValue(
                  `wallets.${index}.includeStakingRewards`,
                  e.target.checked
                );
              }}
            />
            <Text fontSize="sm" color={theme.text}>
              Include Solana staking reward transactions{" "}
              <Info
                message={`If you have staking accounts and you'd like us to pull in the rewards as transactions, which will default to being taxed as income when the staking account receives them, check this box. Otherwise, reward income is realized when you unstake, and not as they are paid to your staking accounts. Note: this will make the import take up to 5 minutes longer.`}
              />
            </Text>
          </HStack>
        )}

        {isEVM && (
          <HStack margin="0.5rem" marginLeft={"0"}>
            <Checkbox
              isChecked={wallet.shouldUploadAllEVM || false}
              onChange={(e) => {
                formProps.setValue(
                  `wallets.${index}.shouldUploadAllEVM`,
                  e.target.checked
                );
              }}
            />
            <Text color={theme.text} fontSize="sm">
              Upload all EVM wallets{" "}
              <Info
                message={`We'll import wallets for ${Array.from(
                  ALLOWED_EVM_PROVIDERS
                ).join(", ")} automatically.`}
              />
            </Text>
          </HStack>
        )}

        {/* is large wallet */}
        {isEVM && (
          <HStack margin="0.5rem">
            <Checkbox
              isChecked={wallet.isLargeAccount || false}
              onChange={(e) => {
                formProps.setValue(
                  `wallets.${index}.isLargeAccount`,
                  e.target.checked
                );
              }}
            />
            <Text color={theme.text} fontSize="sm">
              Large Wallet (over 10k transactions){" "}
              <Info message="These uploads take longer. Please check this for wallets with 10k+ transactions so we can scan the full blockchain for your history. We have a cap at 30,000 transactions; if you exceed this, contact support and we will help you!" />
            </Text>
          </HStack>
        )}
      </HStack>
    </div>
  );
};

export const BatchWalletModal = connectModal({
  name: "BatchWalletModal",
})(_BatchWalletModal);
