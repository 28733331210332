import axios from "axios";
import { config } from "src/config";
import { getAuthToken } from "src/utils/firebase";

const client = axios.create({
  baseURL: config.apiUrl,
});

client.interceptors.request.use(async (config) => {
  const token = await getAuthToken();

  // return the headers to the context so httpLink can read them
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export { client };
