import { WatchQueryFetchPolicy, useQuery } from "@apollo/client";
import { isNil } from "lodash";
import { useMemo, useState } from "react";
import { api } from "src/api";
import {
  PortfolioResponseV2,
  QueryGetPortfolioV2Args,
} from "src/api/generated/types";

type Props = {
  activeClientId: string;
  setIsLoadingInitialCoins?: (isLoading: boolean) => void;
  fetchPolicy?: WatchQueryFetchPolicy;
  includedAccountIds?: string[];
};

export const usePortfolio = ({
  activeClientId,
  setIsLoadingInitialCoins,
  fetchPolicy,
  includedAccountIds,
}: Props) => {
  const [isFreshData, setIsFresh] = useState(false);

  // console.log("client: ", activeClient?.name, " ", activeClient?.id);
  const coinsAndDefiPortfolioVariables = useMemo(
    (): QueryGetPortfolioV2Args => ({
      clientId: activeClientId,
      includeDefi: true,
      includeCoins: true,
      includeNFTs: false,
      onlyIncludedAccountIds:
        !isNil(includedAccountIds) && includedAccountIds.length > 0
          ? includedAccountIds
          : null,
    }),
    [activeClientId, includedAccountIds]
  );

  const coinsAndDefiPortfolioVariablesPrefetch = useMemo(
    (): QueryGetPortfolioV2Args => ({
      clientId: activeClientId,
      includeDefi: true,
      includeCoins: true,
      includeNFTs: false,
      prefetch: true,
    }),
    [activeClientId]
  );

  const { data: initialPortfolioData, loading: initialLoading } = useQuery<{
    getPortfolioV2: Omit<PortfolioResponseV2, "nfts" | "collections">;
  }>(api.portfolio.getPortfolioV2CoinsAndDefi, {
    fetchPolicy: fetchPolicy || "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !coinsAndDefiPortfolioVariablesPrefetch.clientId,
    variables: coinsAndDefiPortfolioVariablesPrefetch,
  });

  // how to delay this query?
  const {
    data: _coinsAndDefiData,
    error: coinsAndDefiError,
    refetch: refetchCoinsAndDefiPortfolio,
    networkStatus: coinsAndDefiNetworkStatus,
    loading: coinsAndDefiLoading,
  } = useQuery<{
    getPortfolioV2: Omit<PortfolioResponseV2, "nfts" | "collections">;
  }>(api.portfolio.getPortfolioV2CoinsAndDefi, {
    skip: !coinsAndDefiPortfolioVariables.clientId,
    variables: coinsAndDefiPortfolioVariables,
    fetchPolicy: fetchPolicy || "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (setIsLoadingInitialCoins) {
        setIsLoadingInitialCoins(false);
      }
      setTimeout(() => {
        setIsFresh(true);
      }, 1000);
    },
  });

  const coinsAndDefiData = _coinsAndDefiData;

  const balances = useMemo(
    () =>
      coinsAndDefiData?.getPortfolioV2?.balances ??
      initialPortfolioData?.getPortfolioV2?.balances ??
      [],
    [
      coinsAndDefiData?.getPortfolioV2?.balances,
      initialPortfolioData?.getPortfolioV2?.balances,
    ]
  );

  const defiPositions = useMemo(
    () =>
      coinsAndDefiData?.getPortfolioV2?.defiPositions ??
      initialPortfolioData?.getPortfolioV2?.defiPositions ??
      [],
    [
      coinsAndDefiData?.getPortfolioV2?.defiPositions,
      initialPortfolioData?.getPortfolioV2?.defiPositions,
    ]
  );

  const totalDefiCents = useMemo(
    () =>
      coinsAndDefiData?.getPortfolioV2?.defiTotalValueCents ??
      initialPortfolioData?.getPortfolioV2?.defiTotalValueCents ??
      null,
    [
      coinsAndDefiData?.getPortfolioV2?.defiTotalValueCents,
      initialPortfolioData?.getPortfolioV2?.defiTotalValueCents,
    ]
  );

  const totalWalletCents = useMemo(
    () =>
      coinsAndDefiData?.getPortfolioV2?.balanceTotalValueCents ??
      initialPortfolioData?.getPortfolioV2?.balanceTotalValueCents ??
      null,
    [
      coinsAndDefiData?.getPortfolioV2?.balanceTotalValueCents,
      initialPortfolioData?.getPortfolioV2?.balanceTotalValueCents,
    ]
  );

  const coinTotalValueCents = useMemo(
    () =>
      coinsAndDefiData?.getPortfolioV2?.coinTotalValueCents ??
      initialPortfolioData?.getPortfolioV2?.coinTotalValueCents ??
      null,
    [
      coinsAndDefiData?.getPortfolioV2?.coinTotalValueCents,
      initialPortfolioData?.getPortfolioV2?.coinTotalValueCents,
    ]
  );

  const dailyFiatAmountCents = useMemo(() => {
    return (
      coinsAndDefiData?.getPortfolioV2?.dailyFiatAmountCents ??
      initialPortfolioData?.getPortfolioV2?.dailyFiatAmountCents ??
      null
    );
  }, [
    coinsAndDefiData?.getPortfolioV2?.dailyFiatAmountCents,
    initialPortfolioData?.getPortfolioV2?.dailyFiatAmountCents,
  ]);

  // we only gave this data for coins + defi atm. if roll out for NFTs, will want to add that in here
  const totalDailyPercentage = useMemo(
    () =>
      coinsAndDefiData?.getPortfolioV2?.dailyPercentage ??
      initialPortfolioData?.getPortfolioV2?.dailyPercentage ??
      null,
    [
      coinsAndDefiData?.getPortfolioV2?.dailyPercentage,
      initialPortfolioData?.getPortfolioV2?.dailyPercentage,
    ]
  );

  const totalDailyPercentageFormatted = useMemo(
    () =>
      coinsAndDefiData?.getPortfolioV2?.dailyPercentageFormatted ??
      initialPortfolioData?.getPortfolioV2?.dailyPercentageFormatted ??
      null,
    [
      coinsAndDefiData?.getPortfolioV2?.dailyPercentageFormatted,
      initialPortfolioData?.getPortfolioV2?.dailyPercentageFormatted,
    ]
  );

  const totalValueCents = useMemo(
    () =>
      coinsAndDefiData?.getPortfolioV2?.valueCents ??
      initialPortfolioData?.getPortfolioV2?.valueCents ??
      null,
    [
      coinsAndDefiData?.getPortfolioV2?.valueCents,
      initialPortfolioData?.getPortfolioV2?.valueCents,
    ]
  );

  const portfolioShowLoading: boolean =
    (coinsAndDefiLoading || initialLoading) && !coinsAndDefiData;

  return {
    isFreshData,
    portfolioShowLoading,
    coinsAndDefiData: coinsAndDefiData ?? initialPortfolioData,
    coinsAndDefiError,
    coinsAndDefiNetworkStatus,
    refetchCoinsAndDefiPortfolio: refetchCoinsAndDefiPortfolio,
    balances,
    defiPositions,
    totalDailyPercentageFormatted,
    totalDailyPercentage,
    totalDefiCents,
    totalWalletCents,
    coinTotalValueCents,
    dailyFiatAmountCents,
    totalValueCents,
  };
};
