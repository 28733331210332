import { Text, BoxProps, Tooltip } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Maybe } from "src/core";
import { colors } from "src/theme";

type VerifiedProps = BoxProps & {
  isChecked: boolean;
  priority?: Maybe<number>;
};

const PRIORITY_MAP: Record<number, string> = {
  0: "💰💰💰",
  1: "💰💰",
  2: "💰",
  3: "",
};

function Verified({ isChecked, priority }: VerifiedProps) {
  let value: any = "";
  if (isChecked) value = "✅";
  else value = PRIORITY_MAP[priority ?? 3];

  return <Text>{value}</Text>;
}

export default Verified;
