import { Box, Spinner, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Info } from "src/components";
import WhiteBox from "src/components/styled/WhiteBox";
import { useClientById, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { PortfolioContext } from "../context";
import DefiPositionRow from "./DefiPositionRow";

function DefiPositions() {
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClientById(clientId);

  const location = useLocation();
  const toast = useMyToast();
  const { isLoadingCoins, defiPositions } = useContext(PortfolioContext);
  const { background, medBackground, secondaryBackground, text, header } =
    useTheme();

  if (!isLoadingCoins && !defiPositions.length) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        flexGrow: 0,
        paddingBottom: 100,
        // marginTop: "1.5rem", FIXME: add back when ready
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        alignSelf="flex-start"
        alignContent="flex-start"
        w="100%"
        style={{ marginTop: 20 }}
        // marginTop="2rem" FIXME: add back when ready
      >
        <WhiteBox
          padding="0"
          w="100%"
          marginTop="0"
          style={{
            boxShadow: "none",
            //minHeight: LIMIT * 70
          }} // just so less jolty when navigating
          border="none"
          bg={background}
        >
          {isLoadingCoins ? (
            <div
              style={{
                padding: "10rem 3rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner color={header} />
            </div>
          ) : (
            <>
              <Box
                style={{
                  padding: 10,
                  marginBottom: 30,
                  borderRadius: 10,
                  background: medBackground,
                  border: `1px solid ${secondaryBackground}`,
                }}
              >
                <Text color={header} fontSize="sm">
                  DeFi positions are only refreshed once every 24 hours, and do
                  not include Solana{" "}
                  <Info message="Staking on Solana is supported, but other DeFi protocols are not." />
                </Text>
              </Box>
              {defiPositions.map((position) => (
                <DefiPositionRow
                  position={position}
                  key={position.groupByKey}
                  client={client}
                />
              ))}
            </>
          )}
        </WhiteBox>
      </Box>
      {/* {isSuperUser && (
          <Button
            bg={colors.gray2}
            marginRight="0.5rem"
            _hover={{ bg: colors.gray80 }}
            padding="0.5rem 1rem"
            borderRadius={other.borderRadius}
            onClick={_hardRefreshAssetImages}
          >
            <i
              style={{ marginRight: 5, color: colors.green50 }}
              className="fa-sharp fa-refresh"
            />{" "}
            <Text
              color={colors.gray20}
              fontSize="sm"
              padding="0"
              fontWeight="semibold"
            >
              Refresh Asset Metadata{" "}
              <Info message="Updates images and names of assets if they have changed." />
            </Text>
          </Button>
        )} */}
    </div>
  );
}

export default DefiPositions;
