import { Box, FormLabel, SelectProps, Text } from "@chakra-ui/react";
import React, { CSSProperties } from "react";
import { colors } from "src/theme";
import { Controller, Control, useForm } from "react-hook-form";
import { isNil } from "lodash";
import { Info } from "./Info";
import { Maybe } from "src/core";

import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./css/datepicker.css";
import { noop } from "lodash/fp";
import { DateTime } from "luxon";
import { useTheme } from "src/hooks/useTheme";

type MyReactDatePickerProps = {
  label?: string;
  labelIconName?: string;
  name?: string;
  hidden?: boolean;
  infoMessage?: string;
  visible?: boolean;
  control?: Control<any, any>;
  containerStyle?: CSSProperties;
  width?: string;
  value?: Maybe<Date | string>;
  onChange?: (date: Maybe<Date>) => void;
  isRequired?: boolean;
  placeholder?: string;
  dateFormat?: string;
  labelStyle?: CSSProperties;
  showTimeSelect?: boolean;
  dateInputClassName?: string;
  timeIntervals?: number;
  showTimeSelectOnly?: boolean;
};

export const DatePicker = (props: MyReactDatePickerProps) => {
  const { setValue } = useForm();
  const theme = useTheme();
  const className = theme.theme === "dark" ? "datepicker-darkmode" : undefined;

  if (props.hidden) {
    return null;
  }

  if (!isNil(props.visible) && !props.visible) {
    return null;
  }

  return (
    <Box style={{ marginBottom: "1rem", ...props.containerStyle }}>
      {props.label && (
        <FormLabel color={theme.header} fontSize="sm" style={props.labelStyle}>
          {props.labelIconName && (
            <i style={{ marginRight: 5 }} className={props.labelIconName} />
          )}
          {props.label}{" "}
          {props.isRequired && (
            <Text display="inline" color="red.500">
              *
            </Text>
          )}
          {props.infoMessage && <Info message={props.infoMessage} />}
        </FormLabel>
      )}

      {props.control && props.name ? (
        <Controller
          control={props.control}
          name={props.name}
          render={({ field: { value, onChange, onBlur, ref } }) => {
            // console.log(
            //   new Date(value),
            //   DateTime.fromJSDate(new Date(value)).toUTC().toJSDate()
            // );
            return (
              <ReactDatePicker
                selected={value ? new Date(value) : null}
                onChange={onChange}
                showTimeSelect={props.showTimeSelect ?? true}
                dateFormat={props.dateFormat ?? "P"}
                placeholderText={props.placeholder}
                className={props.dateInputClassName ?? className}
                timeIntervals={props.timeIntervals}
                timeFormat="HH:mm"
                showTimeSelectOnly={props.showTimeSelectOnly}
              />
            );
          }}
        />
      ) : (
        <ReactDatePicker
          selected={props.value ? new Date(props.value) : null}
          onChange={props.onChange || noop}
          showTimeSelect={props.showTimeSelect ?? true}
          dateFormat={props.dateFormat ?? "Pp"}
          placeholderText={props.placeholder}
          className={props.dateInputClassName ?? className}
          timeIntervals={props.timeIntervals}
          timeFormat="HH:mm"
          showTimeSelectOnly={props.showTimeSelectOnly}
        />
      )}
    </Box>
  );
};
