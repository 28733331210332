import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import { useParams } from "react-router-dom";
import { useMyToast } from "src/hooks";
import {
  Mutation,
  MutationStartCheckoutArgs,
  Query,
  StartSubscriptionCheckoutLineItem,
  StartSubscriptionCheckoutResponse,
  StartUpgradeCheckoutResponse,
} from "src/api/generated/types";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { stripePromise } from "src/utils/stripe";
import { Box, Divider, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { last } from "radash";
import { colors } from "src/theme";
import { Button } from "src/components/styled";
import { D } from "src/utils/helpers";

type Props = InjectedProps & {
  onPaymentSuccess: () => void;
};

function _PurchaseUpgradeModal({
  handleHide,
  onPaymentSuccess,
  show: isVisible,
}: Props) {
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();

  const [getPaymentMethods, { data, loading }] = useLazyQuery<
    Pick<Query, "getPaymentMethods">
  >(api.users.paymentMethods);

  const [startUpgradeCheckout, { data: subData, loading: subLoading }] =
    useMutation<Pick<Mutation, "startUpgradeCheckout">>(
      api.checkouts.startUpgrade
    );

  const onSuccess = () => {
    handleHide();
    if (onPaymentSuccess) onPaymentSuccess();
  };

  const _load = async () => {
    // const response = await getPaymentMethods({
    //   variables: { clientId },
    // });

    await startUpgradeCheckout({
      variables: { clientId },
    });
  };

  useEffect(() => {
    _load();
  }, []);

  const paymentMethods = data?.getPaymentMethods;
  const checkout = subData?.startUpgradeCheckout;
  const clientSecret = checkout?.clientSecret;

  if (loading) {
    return (
      <Modal
        isVisible={isVisible}
        handleHide={handleHide}
        w="100%"
        maxW="40rem"
      >
        <LoadingBox />
      </Modal>
    );
  }

  return (
    <Modal isVisible={isVisible} handleHide={handleHide} w="100%" maxW="40rem">
      <Box style={{ padding: "1.5rem 0" }}>
        {clientSecret ? (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret,
            }}
          >
            <FullPaymentForm onSuccess={onSuccess} checkout={checkout} />
          </Elements>
        ) : (
          <div />
        )}
      </Box>
    </Modal>
  );
}

const LoadingBox = () => {
  return (
    <div
      style={{
        minHeight: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text fontSize="md" style={{ alignItems: "center", display: "flex" }}>
        Loading checkout <Spinner style={{ marginLeft: "1rem" }} size="sm" />
      </Text>
    </div>
  );
};

const FullPaymentForm = ({
  checkout,
  onSuccess,
}: {
  checkout: StartUpgradeCheckoutResponse;
  onSuccess: () => void;
}) => {
  const [isReady, setReady] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements || !isReady) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div>
      <div
        style={{
          margin: "1.5rem 0.25rem 0 0.25rem",
        }}
      >
        <CheckoutSummary amount={checkout.amount} />
        <PaymentElement onReady={() => setReady(true)} />
        {!isReady && (
          <Text fontSize="md" style={{ alignItems: "center", display: "flex" }}>
            <Spinner size="md" />
          </Text>
        )}
      </div>
      <Button
        style={{
          width: "100%",
          margin: "3rem 0.25rem 1.5rem 0.25rem",
        }}
        variant="primary"
        onClick={handleSubmit}
        disabled={!isReady}
      >
        Upgrade Subscription
      </Button>
    </div>
  );
};

const Features = () => {
  // We'll answer any questions you have on how to use the software, what to label specific transactions, figure out problems with your CSVs, and anything else you need. You'll get responses from a real person (no AI bots).

  const features = [
    {
      iconName: "fa-computer",
      title: "Software help",
      description:
        "We'll help you with any questions you have about how to use Awaken.",
    },
    {
      iconName: "fa-tags",
      title: "Labeling",
      description:
        "If you aren't sure which label applies to a specific transaction, we'll help point you in the right direction.",
    },
    {
      iconName: "fa-database",
      title: "Data help (ex. CSVs)",
      description:
        "If you need help with your CSVs, we'll help you figure out what's wrong and give you advice on how to fix it.",
    },
  ];

  return (
    <div
      style={{
        paddingTop: 0,
      }}
    >
      <Text textAlign="center" fontWeight="semibold" fontSize="md">
        Unlimited Customer Service{" "}
        <i
          style={{ color: colors.yellow50 }}
          className="fa-sharp fa-sparkles"
        />
      </Text>

      <br />

      {features.map((feature, i) => (
        <div key={i} style={{ marginBottom: "0.5rem" }}>
          <HStack alignItems="flex-start">
            <i
              className={`fa-sharp ${feature.iconName}`}
              style={{
                fontSize: 16,
                position: "relative",
                top: 7,
                marginRight: "0.5rem",
              }}
            />
            <VStack alignItems="flex-start" padding="0.25rem 0">
              <div style={{ flex: 1, textAlign: "left" }}>
                <Text color={colors.black} fontSize="sm" fontWeight="semibold">
                  {feature.title}
                </Text>
              </div>
              <div style={{ flex: 1, textAlign: "left" }}>
                <Text marginTop="0" fontSize="sm">
                  {feature.description}
                </Text>
              </div>
            </VStack>
          </HStack>
        </div>
      ))}

      <br />

      <Box
        style={{
          borderRadius: 7,
          backgroundColor: colors.gray90,
          padding: "0.5rem",
          border: `1px solid ${colors.gray70}`,
        }}
      >
        <Text fontSize="sm" style={{}} fontStyle="italic">
          Note: Awaken is not a tax advisor so none of what we say is tax
          advice. If you have more specific needs, we can connect you to a CPA
          which usually will cost $200/hour.
        </Text>
      </Box>
    </div>
  );
};

const CheckoutSummary = ({ amount }: { amount: number }) => {
  return (
    <>
      <div
        style={{
          padding: "1rem",
          marginBottom: "2rem",
          backgroundColor: colors.gray100,
          borderRadius: 10,
          border: `1px solid ${colors.gray70}`,
        }}
      >
        <HStack padding="0.25rem 0">
          <div style={{ flex: 1, textAlign: "left" }}>
            <Text
              color={amount >= 0 ? colors.black : colors.black}
              fontSize="sm"
              fontWeigh="500"
            >
              Total Price
            </Text>
          </div>
          <div style={{ flex: 1, textAlign: "right" }}>
            <Text
              fontSize="sm"
              fontWeight="bold"
              color={amount > 0 ? colors.black : colors.green50}
            >
              {amount === 0 ? "FREE" : D(amount).toFormat()}
            </Text>
          </div>
        </HStack>
      </div>
    </>
  );
};

export const PurchaseUpgradeModal = connectModal({
  name: "PurchaseUpgradeModal",
})(_PurchaseUpgradeModal);
