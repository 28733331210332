import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Heading,
  Box,
  HStack,
  Link,
  Button,
  Table,
  ButtonGroup,
  BoxProps,
  Divider,
  Spinner,
  Text,
  Image,
  Th,
  Tr,
  Td,
  Tbody,
  Thead,
  Grid,
  Flex,
  PopoverTrigger,
  Popover,
  PopoverContent,
  VStack,
} from "@chakra-ui/react";
import { show } from "redux-modal";
import WhiteBox from "src/components/styled/WhiteBox";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "src/hooks/useTheme";

import TwitterIcon from "src/assets/awaken/logos/socials/twitter.png";
import _ from "lodash";

import Dan from "src/assets/awaken/accountants/dan.png";
import Squeeze from "src/assets/awaken/accountants/squeeze.jpg";
import Gordon from "src/assets/awaken/accountants/gordon.jpg";
import CryptoTaxGirl from "src/assets/awaken/accountants/cryptotaxgirl.jpg";

const TaxProfessionalsInfo = _.sortBy(
  [
    {
      url: "https://twitter.com/@The_NFT_CPA",
      name: "Dan Cournoyer",
      img: Dan,
      description:
        "Dan Cournoyer helped build Awaken. He taught us how to do crypto taxes. Before starting his own firm, Dan was a manager at PwC.",
    },
    {
      url: "https://twitter.com/@SqueezeTaxes",
      name: "Squeeze Taxes",
      img: Squeeze,
      description:
        "Squeeze has helped us grow the Awaken community and has provided advice for dozens of our users.",
    },
    {
      name: "Andrew Gordon",
      url: "https://twitter.com/gordonlawltd",
      img: Gordon,
      description:
        "Andrew Gordon is a highly experienced tax attorney and CPA. He advises Awaken on complex tax matters like accounting for Uniswap V3.",
    },
    {
      url: "https://twitter.com/@CryptoTaxGirl",
      name: "Crypto Tax Girl",
      img: CryptoTaxGirl,
      description:
        "Laura is a CPA and has been an industry leader in the crypto tax space since 2017. Her team offers crypto tax reports, tax return filing services, tax advisory services, and tax notice resolution services.",
    },
    {
      url: "https://twitter.com/@CryptoTaxSucks",
      name: "Crypto Tax Made Easy",
    },
    { url: "https://twitter.com/@j_howe4", name: "CryptoTax_Monke" },
    { url: "https://twitter.com/@cocothecorncob", name: "El Coco" },

    { url: "https://twitter.com/@theDakotaBear", name: "Dakota Bear" },
    { url: "https://twitter.com/@carlosdquintana", name: "Carlos Quintana" },
    { url: "https://twitter.com/@CryptoTaxGuyETH", name: "Crypto Tax Guy" },
    { url: "https://twitter.com/@DeFiTaxCPA", name: "DeFi Tax CPAs" },
    { url: "https://twitter.com/@EmDeeEm", name: "Matt Metras" },
    { url: "https://twitter.com/@CryptoTaxCat", name: "Lambo McGee" },
    { url: "https://twitter.com/@jbreckCPA", name: "J Breck" },
    { url: "https://twitter.com/@donovanfinance", name: "Donovan Miller" },
    { url: "https://twitter.com/@erocknghiem", name: "Eric Nghiem" },
    { url: "https://twitter.com/@CarterFCS", name: "MC" },
    { url: "https://twitter.com/@TH3Collectour", name: "Collectour" },
    { url: "https://twitter.com/@TorchiaMatthew", name: "Matthew Torchia" },
    { url: "https://twitter.com/@MagisterTax", name: "Magister Tax" },
    { url: "https://twitter.com/@paulydcpa", name: "Pauly D" },
    { url: "https://twitter.com/@JamisonSites", name: "Jamison Sites" },
    { url: "https://twitter.com/@nyoungdumb", name: "Nick Young" },
    { url: "https://twitter.com/@MZeiterCPA", name: "M Zeiter" },
    { url: "https://twitter.com/@0xSeanCPA", name: "Sean.CPA" },
  ],
  []
);

export const TaxProfessionals = () => {
  const { header } = useTheme();
  return (
    <Box>
      <Heading
        color={header}
        margin="0"
        size="lg"
        paddingTop="1rem"
        paddingBottom="3rem"
      >
        Our favorite tax professionals
      </Heading>

      <Box w="100%" maxWidth="75rem" display="flex" flexWrap="wrap">
        {TaxProfessionalsInfo.map((info) => (
          <TaxProfessional info={info} />
        ))}
      </Box>
    </Box>
  );
};

type TaxProfessionalProps = {
  info: {
    url: string;
    name: string;
    img?: string;
    description?: string;
  };
};

const TaxProfessional = ({ info }: TaxProfessionalProps) => {
  const { text, header } = useTheme();
  return (
    <WhiteBox marginRight="2rem" width="20rem" minWidth="15rem">
      {info.img && (
        <Image
          src={info.img}
          w="4rem"
          h="4rem"
          objectFit="contain"
          marginRight="0.5rem"
          marginTop="0.4rem"
        ></Image>
      )}

      <Text color={header} margin="0.5rem 0 0.4rem 0">
        {info.name}
      </Text>
      <Text fontSize="sm" color={text} margin="0">
        {info.description}
      </Text>
      <Link href={info.url} isExternal>
        <a href={info.url} target="_blank">
          <Image
            src={TwitterIcon}
            w="1.25rem"
            h="1.25rem"
            marginTop="1rem"
            objectFit="contain"
            marginRight="0.75rem"
          ></Image>
        </a>
      </Link>
    </WhiteBox>
  );
};
