import { Box, Spinner } from "@chakra-ui/react";
import { useTheme } from "src/hooks/useTheme";

function Loading() {
  const theme = useTheme();

  return (
    <Box
      w="100%"
      display="flex"
      bg={theme.background}
      flexDir="row"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Spinner color={theme.header} />
    </Box>
  );
}

export default Loading;
