import React, { useContext, useMemo, useRef } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import { api, apolloClient } from "src/api";
import _, {
  debounce,
  isEmpty,
  isNil,
  keyBy,
  noop,
  orderBy,
  truncate,
} from "lodash";
import {
  DefaultErrors,
  FailureOrSuccess,
  Maybe,
  UnexpectedError,
  failure,
  hasValue,
  success,
} from "src/core";
import Helpers, { D } from "src/utils/helpers";
import {
  AssetTypeEnum,
  CurrencyCodeEnum,
  MutationUpdateAssetArgs,
  MutationUpsertNftAssetArgs,
  PortfolioNft,
} from "src/api/generated/types";
import { Link, useParams } from "react-router-dom";
import { colors } from "src/theme";
import { Box, HStack, Text, textDecoration, VStack } from "@chakra-ui/react";
import { AssetIcon } from "src/components/styled/Assets";
import { useTheme } from "src/hooks/useTheme";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { NAME_FLEX, VALUE_FLEX } from "./constants";
import { Touchable } from "src/components/Touchable";
import { ActionSheet } from "src/components";
import BigNumber from "bignumber.js";
import { useMyToast } from "src/hooks";

type NFTUpdateParams = {
  isHidden?: boolean;
  useAwakenPrice?: boolean;
  overrideCurrentValue?: Maybe<number>;
};

export const GRID_ITEM_HEIGHT = 250;
export const LIST_ITEM_HEIGHT = 60;

type NFTRowProps = {
  nft: PortfolioNft;
  itemHeight?: number;
  mode: "grid" | "list";
  onPress: (nft: PortfolioNft) => void;
};

const SWIPEABLE_ICON_WIDTH = 75;

const NFTRow = React.memo((props: NFTRowProps) => {
  if (props.mode === "list") {
    return <RowNFT {...props} />;
  }

  return <ColumnNFT {...props} />;
});

const RowNFT = React.memo(({ nft, onPress, itemHeight }: NFTRowProps) => {
  const { clientId } = useParams();
  const toast = useMyToast();

  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  const theme = useTheme();

  const [upsertNFT, { loading: loadingCreate, error: createError }] =
    useMutation(api.assets.upsertNFT);

  const _upsertAsset = async (
    nft: PortfolioNft,
    params: NFTUpdateParams
  ): Promise<FailureOrSuccess<DefaultErrors, null>> => {
    if (!clientId) return failure(new Error("There is no clientId."));

    const assetKey = nft.assetKey;

    try {
      const variables: MutationUpsertNftAssetArgs = {
        assetKey,
        clientId: clientId,
        contractAddress: nft.contractAddress,
        name: nft.name,
        provider: nft.provider,
        imageUrl: nft.imageUrl || nft.previewImageUrl,
        isHidden: params.isHidden,
        tokenId: nft.tokenId,
        useAwakenPrice: params.useAwakenPrice,
        overrideCurrentValue: params.overrideCurrentValue,
      };

      await upsertNFT({
        variables,
        refetchQueries: [api.portfolio.getPortfolioV2NFTs],
      });
      return success(null);
    } catch (err) {
      apolloClient.refetchQueries({
        include: [api.portfolio.getPortfolioV2NFTs],
      });
      return failure(
        new Error((err as any)?.message || "Error creating NFT asset.")
      );
    }
  };

  const _setAwakenPrice = async (
    useAwakenPrice: boolean
  ): Promise<FailureOrSuccess<DefaultErrors, null>> => {
    if (!nft) {
      // revert it
      return failure(new Error("Unknown asset."));
    }

    console.log("[loading use awaken price: true]");

    try {
      toast.show({
        message: "Updating asset... this can take a bit.",
        status: "info",
      });
      return _upsertAsset(nft, {
        useAwakenPrice: useAwakenPrice,
        // either remove it or set it to $0 (so they have to edit it)
        overrideCurrentValue: useAwakenPrice === true ? null : 0,
      });
    } catch (err) {
      toast.show({
        message: "Error updating asset",
        status: "error",
      });
      return failure(new UnexpectedError("An error occurred."));
    } finally {
      console.log("[loading use awaken price: false]");
      // setIsToggled(!isToggled); // revert toggle
    }
  };

  const _updateAssetOverridePrice = async (
    newValueCents: BigNumber
  ): Promise<FailureOrSuccess<DefaultErrors, null>> => {
    if (!nft) return failure(new Error("There is no NFT."));

    try {
      toast.show({
        message: "Updating asset... this can take a bit.",
        status: "info",
      });
      return _upsertAsset(nft, {
        isHidden: false,
        useAwakenPrice: false,
        overrideCurrentValue: newValueCents.toNumber(),
      });
    } catch (err) {
      toast.show({
        message: "Error updating asset",
        status: "error",
      });

      return failure(new UnexpectedError("An error occurred."));
    } finally {
      //
    }
  };

  return (
    <Box
      style={{
        backgroundColor: theme.background,
        display: "flex",
        flexDirection: "row",
        padding: "15px 0",
        alignItems: "center",
        justifyContent: "center",
        height: itemHeight,
        cursor: "default",
        // borderBottomColor: border,
        // borderBottomWidth: 1,
      }}
    >
      <div style={{ width: 60 }}>
        <AssetIcon
          asset={{
            iconImageUrl: nft.previewImageUrl || "",
            symbol: nft.name || "",
            type: AssetTypeEnum.Nft,
          }}
          size={60}
          textStyle={{
            fontSize: 32,
            fontWeight: "bold",
          }}
          style={{
            borderRadius: 15,
            overflow: "hidden",
            width: "100%",
          }}
        />
      </div>

      <div
        style={{
          flex: NAME_FLEX,
          marginRight: 15,
          marginLeft: 20,
        }}
      >
        <Link
          to={`/clients/${clientId}/transactions?assetIds=${nft.assetId || ""}`}
        >
          <Text
            _hover={{
              textDecoration: "underline",
              color: colors.primary,
            }}
            style={{
              color: theme.header,
              textAlign: "left",
              fontSize: 16,
            }}
          >
            {nft?.name || ""}{" "}
            <i
              style={{
                fontSize: 12,
              }}
              className="fa-sharp fa-link"
            />
          </Text>
        </Link>

        <HStack style={{ marginTop: 5 }}>
          <Text
            style={{
              color: theme.text,
              textAlign: "left",
              fontSize: 16,
              marginRight: 5,
            }}
          >
            {(nft?.provider || "-").toUpperCase()}
          </Text>

          {!isNil(nft?.providerImageUrl) && (
            <img
              style={{
                width: 18,
                height: 18,
                borderColor: colors.gray90,
                borderRadius: 100,
                borderWidth: 1,
                objectFit: "cover",
              }}
              src={nft?.providerImageUrl || ""}
            />
          )}
        </HStack>
      </div>

      <Box
        style={{
          flex: VALUE_FLEX + 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginLeft: 0,
        }}
      >
        <Touchable
          onClick={() => {
            window.open(nft.marketplaceUrl || "", "_blank");
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: 14,
              marginRight: 5,
              color: theme.header,
            }}
          >
            View on{" "}
            {nft.marketplaceUrl?.includes("opensea")
              ? "Opensea"
              : nft.marketplaceUrl?.includes("magiceden")
              ? "Magic Eden"
              : "Marketplace"}
          </Text>
          <img
            style={{
              width: 18,
              height: 18,
            }}
            src={nft.marketplaceImageUrl || ""}
          />
        </Touchable>
      </Box>

      <Box
        style={{
          flex: VALUE_FLEX,
          maxWidth: 175,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginLeft: 0,
        }}
      >
        {!isNil(nft.fiatPriceCents) && (
          <div style={{}}>
            <Text
              style={{
                fontSize: 16,
                color: theme.header,
              }}
            >
              {nft.fiatPriceCents ? D(nft.fiatPriceCents).toFormat() : "-"}
            </Text>
          </div>
        )}

        <ActionSheet
          content={{ width: 250 }}
          popover={{ placement: "bottom-end" }}
          commands={[
            {
              label: "Edit current value",
              iconName: "fa-sharp fa-pen",
              onClick: async () => {
                const newPrice = prompt(
                  "What is the NFT worth?",
                  ((nft.fiatPriceCents ?? 0) / 100).toFixed(2)
                );

                if (!newPrice) {
                  return;
                }
                // parse the float
                const newPriceCents = new BigNumber(parseFloat(newPrice) * 100);
                if (newPriceCents.isNaN()) {
                  alert("Invalid price");
                  return;
                }

                await _updateAssetOverridePrice(newPriceCents);
              },
            },
            {
              label: "Mark as worthless",
              iconName: "fa-sharp fa-empty-set",
              onClick: async () => {
                await _updateAssetOverridePrice(new BigNumber(0));
              },
            },
            {
              label: "Use floor as current value",
              iconName: "fa-sharp fa-shop",
              onClick: async () => {
                await _setAwakenPrice(true);
              },
            },
          ]}
        >
          <Touchable style={{ marginLeft: 5 }} iconName="fa-sharp fa-pen" />
        </ActionSheet>
      </Box>

      <Box
        style={{
          flex: VALUE_FLEX,
          maxWidth: 175,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginLeft: 0,
        }}
      >
        <TotalGainLoss
          nft={nft}
          currency={CurrencyCodeEnum.Usd} // FIXME:
        />
      </Box>
    </Box>
  );
});

const TotalGainLoss = ({
  nft,
  currency,
}: {
  nft: PortfolioNft;
  currency: CurrencyCodeEnum;
}) => {
  const isLarge = useIsLargeScreen();
  const theme = useTheme();

  if (!nft.gainLossCents || isNaN(nft.gainLossCents)) {
    return (
      <HStack
        alignItems="center"
        style={{
          color: theme.text,
        }}
      >
        —
      </HStack>
    );
  }

  const isNeg = nft.gainLossCents < 0;
  const color = isNeg ? colors.negative : colors.positive;

  return (
    <HStack
      alignItems="center"
      style={{ width: "100%", justifyContent: "flex-end" }}
    >
      {isLarge && (
        <i
          className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
          style={{
            fontSize: 16,
            color,
          }}
        />
      )}

      <VStack
        alignItems="flex-end"
        style={{
          fontWeight: "500",
          color,
          width: "100%",
          maxWidth: 100,
        }}
      >
        <Text fontWeight="700" fontSize="md" color={color} marginBottom="0">
          {D(Math.abs(nft.gainLossCents), currency).toFormat()}{" "}
        </Text>
        <Text fontSize="md" marginTop="0 !important" style={{ color }}>
          {/* {isNeg ? "-" : ""} */}
          {nft.positionPercentageFormatted}
        </Text>
      </VStack>
    </HStack>
  );
};

const ColumnNFT = React.memo(({ onPress, nft, itemHeight }: NFTRowProps) => {
  const { header, background, border, secondaryBackground } = useTheme();

  const _onPress = () => {
    onPress(nft);
  };

  return (
    <Link
      to="#"
      onClick={_onPress}
      style={{
        height: itemHeight,
        display: "flex",
        flexDirection: "column",
        padding: 5,
        alignItems: "center",
      }}
    >
      {/* {!isNil(nft.videoUrl) ? (
        <View
          style={{
            borderRadius: 10,
            overflow: "hidden",
            height: 150,
            width: "100%",
          }}
        >
          <FastImage
            style={{
              height: 150,
              width: "100%",
              borderRadius: 10,
              borderColor: border,
              borderWidth: 2,
              position: "absolute", // Add this to position image over the video initially
            }}
            resizeMode="cover"
            source={{
              uri: nft.previewImageUrl || "",
            }}
          />
          <Video
            source={{ uri: nft.videoUrl }}
            isMuted
            shouldPlay
            isLooping
            resizeMode={ResizeMode.COVER}
            style={{
              borderRadius: 10,
              height: "100%",
              width: "100%",
              opacity: isVideoLoaded ? 1 : 0, // Control the display of video based on its load state
            }}
            onLoad={() => setIsVideoLoaded(true)} // Event handler for when the video is loaded
          />
        </View>
      ) : ( */}
      <div
        style={{
          height: GRID_ITEM_HEIGHT - 75,
          width: "100%",
        }}
      >
        <AssetIcon
          asset={{
            iconImageUrl: nft.previewImageUrl || "",
            symbol: nft.name || "",
            type: AssetTypeEnum.Nft,
          }}
          size={GRID_ITEM_HEIGHT - 75}
          textStyle={{
            fontSize: 32,
            fontWeight: "bold",
          }}
          style={{
            borderRadius: 15,
            overflow: "hidden",
            width: "100%",
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: 5,
          width: "100%",
          padding: 5,
        }}
      >
        <div
          style={{
            height: 40,
            marginTop: 5,
            flexDirection: "row",
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <Box
            width="100%"
            textAlign="left"
            mr={5}
            flex={1}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            fontWeight="medium"
          >
            <Text color={header} fontSize="md" noOfLines={2}>
              {nft?.name || ""}
            </Text>
          </Box>

          {!isNil(nft?.providerImageUrl) && (
            <img
              style={{
                width: 18,
                top: 2,
                position: "relative",
                height: 18,
                borderColor: border,
                borderRadius: 100,
                borderWidth: 1,
                objectFit: "cover",
              }}
              src={nft?.providerImageUrl || ""}
            />
          )}
        </div>

        <div
          style={{
            flexDirection: "row",
            marginTop: 15,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!isNil(nft.fiatPriceCents) && (
            <div
              style={{
                padding: "5px 10px",
                alignSelf: "flex-start",
                backgroundColor: secondaryBackground,
                borderRadius: 5,
                width: "100%",
              }}
            >
              <Text
                fontWeight="bold"
                fontSize="sm"
                style={{
                  textAlign: "center",
                  color: header,
                }}
              >
                {!isNil(nft.fiatPriceCents)
                  ? D(nft.fiatPriceCents).toFormat()
                  : "-"}
              </Text>
            </div>
          )}
          <div style={{ flex: 1 }} />
        </div>
      </div>
    </Link>
  );
});

export default React.memo(NFTRow, (prev, next) => {
  return JSON.stringify(prev.nft) === JSON.stringify(next.nft);
});
