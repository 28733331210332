import { Box, Heading, Text, Image, HStack } from "@chakra-ui/react";
import SecondaryText from "src/components/styled/SecondaryText";
import Twitter from "src/assets/awaken/logos/socials/twitter.png";
import LinkedIn from "src/assets/awaken/logos/socials/linkedin.png";
import Email from "src/assets/awaken/logos/socials/email.png";
import { colors, other } from "src/theme";
import { api } from "src/api";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { FilterFormCpa } from "src/api/generated/types";
import { NavBarPageTemplate } from "src/components";

const STATUS_ACCEPTING = "✅ Accepting new clients";
const STATUS_UNCONFIRMED = "🟡 Unconfirmed";

export function Results() {
  const params = useParams();

  const { data: filteredCPAs, loading: loadingFilteredCPAs } = useQuery(
    api.misc.getFilteredCPAs,
    {
      variables: { filterFormSubmissionId: params.formId || "" },
    }
  );

  if (loadingFilteredCPAs) return null;

  return (
    <NavBarPageTemplate>
      <Box margin="auto" w="96%" maxW="55rem" paddingBottom="5rem">
        <Heading>Accountants</Heading>
        <Text paddingBottom="1rem">
          {filteredCPAs.getFilteredCPAs.length > 0
            ? "Here are the web3 CPAs who can best serve your needs."
            : "We couldn't find any web3 CPAs that fit your needs."}
          &nbsp;If you know of more web3 CPAs, please email team@awaken.tax
        </Text>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          flexWrap="wrap"
        >
          {filteredCPAs.getFilteredCPAs.map(
            (a: JSX.IntrinsicAttributes & FilterFormCpa) => (
              <Accountant {...a} key={a.id} />
            )
          )}
        </Box>
        <SecondaryText
          marginTop="1.8rem"
          text="This is a community-sourced list. If you know of other web3 CPAs, please let us know at team@awaken.tax"
        />
      </Box>
    </NavBarPageTemplate>
  );
}

function Accountant({
  name,
  twitter,
  linkedin,
  headline,
  email,
  description,
  imgUrl,
  confirmed,
}: FilterFormCpa) {
  const status = confirmed ? STATUS_ACCEPTING : STATUS_UNCONFIRMED;

  return (
    <Box
      w="25rem"
      h="19rem"
      display="flex"
      flexDir="column"
      borderRadius={other.borderRadius}
      border={other.boxBorder}
      boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.1)"}
      marginTop="2rem"
      marginRight="2rem"
      padding="1.5rem"
      transition="height 0.2s ease-in-out"
      backgroundColor={colors.white}
      // opacity={status === NOT_ACCEPTING_NEW_CLIENTS ? "60%" : "100%"}
    >
      <Image
        width="4rem"
        height="4rem"
        objectFit="contain"
        objectPosition={"center"}
        src={imgUrl}
        borderRadius={other.borderRadius}
      />
      <Heading size="md" margin="1rem 0 0 0">
        {name}
      </Heading>
      <SecondaryText text={headline} />
      <Box position="relative" marginTop="0.4rem" flex="1" overflowY="scroll">
        {/* <Box w="100%" h="100%" background={`linear-gradient(rgba(255, 255, 255, 0), ${colors.white})`} position="absolute" top="0"></Box> */}
        <Text>{description}</Text>
      </Box>
      <Box marginTop="1rem" display="flex" justifyContent="space-between">
        <SecondaryText text={status} />
        <HStack>
          {twitter.trim() && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.twitter.com/${twitter}`}
            >
              <Image
                width="1.6rem"
                height="1.6rem"
                objectFit="contain"
                objectPosition={"left"}
                src={Twitter}
              />
            </a>
          )}
          {linkedin.trim() && (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.linkedin.com/in/${linkedin}/`}
            >
              <Image
                marginLeft="0.5rem"
                width="1.5rem"
                height="1.5rem"
                objectFit="contain"
                objectPosition={"left"}
                src={LinkedIn}
              />
            </a>
          )}
          {email.trim() && (
            <a target="_blank" rel="noreferrer" href={`mailto: ${email}`}>
              <Image
                marginLeft="0.5rem"
                width="1.7rem"
                height="1.7rem"
                objectFit="contain"
                objectPosition={"left"}
                src={Email}
              />
            </a>
          )}
        </HStack>
      </Box>
    </Box>
  );
}
