import { Button, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { Modal } from "src/components/Modal";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  capLoss: string;
};

function _PhishingAlertModal({ handleHide, show: isVisible, capLoss }: Props) {
  const theme = useTheme();
  const toast = useMyToast();

  return (
    <Modal
      title={`Important: Please Reset Your Password`}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Link to="/forgot-password">
          <Button
            variant="primary"
            style={{
              width: "100%",
            }}
            onClick={handleHide}
          >
            Reset my password
          </Button>
        </Link>
      }
    >
      <Text color={theme.text}>
        If you have recently received a suspicious email claiming to be Awaken
        and you clicked it, please reset your password immediately. Awaken users
        are currently being phished. Awaken will never ask you to connect a
        crypto wallet or for your private key.
        <br />
        <br />
        Awaken will only ever email you from an @awaken.tax domain and always
        check the link to make sure it is "https://awaken.tax".
        <br />
        <br />
        If you are ever in doubt, always email us at team@awaken.tax.
      </Text>

      <br />
    </Modal>
  );
}

export const PhishingAlertModal = connectModal({
  name: "PhishingAlertModal",
})(_PhishingAlertModal);
