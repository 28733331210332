import { useMutation } from "@apollo/client";
import { Button, Container, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { Mutation, MutationGetPaymentLinkArgs } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { Input } from "src/components/styled";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  //
};

function _BuyLinkModal({ handleHide, show: isVisible }: Props) {
  const { clientId } = useParams();

  const [generatePaymentLink, { loading }] = useMutation<
    Pick<Mutation, "getPaymentLink">
  >(api.checkouts.generatePaymentLink);

  const toast = useMyToast();
  const theme = useTheme();
  const navigate = useNavigate();

  const [checkoutLink, setCheckoutLink] = useState<string | null>(null);

  // monthly price
  const [price, setPrice] = useState(0);

  // max ceiling
  const [maxCeiling, setMaxCeiling] = useState(0);

  const _onSubmit = async function () {
    if (!clientId) {
      return;
    }

    try {
      const variables: MutationGetPaymentLinkArgs = {
        clientId: clientId,
        amountCents: Math.ceil(price * 100),
        maxTransactionsCeiling: maxCeiling,
      };

      const response = await generatePaymentLink({
        variables: variables,
      });

      const url = response.data?.getPaymentLink;

      setCheckoutLink(url || "");

      toast.show({
        message:
          "Checkout link created! You can send it to a client now to pay.",
        status: "success",
      });
    } catch (e) {
      console.error(e);

      toast.show({
        message: "Error updating quote to client.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title={<>Payment Link</>}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        checkoutLink ? (
          <div />
        ) : (
          <Button
            width="100%"
            variant="primary"
            isLoading={loading}
            marginBottom="1rem"
            onClick={_onSubmit}
          >
            Generate Payment Link
          </Button>
        )
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text fontSize="md" color={theme.text}>
          This will generate a payment link you can share with the client. The
          link will not be sent to the client automatically.
        </Text>

        <br />

        {/* number inputs for monthly + upfront prices */}
        {!checkoutLink ? (
          <>
            <Input
              label="Price (USD)"
              type="number"
              value={price}
              onChange={(e) => setPrice(parseFloat(e.target.value))}
            />

            <Input
              label="Max Transaction Ceiling"
              type="number"
              value={maxCeiling}
              onChange={(e) => setMaxCeiling(parseFloat(e.target.value))}
            />
          </>
        ) : (
          <div
            onClick={() => {
              // copy the link to the clipboard
              navigator.clipboard.writeText(checkoutLink);
              toast.show({
                message: "Link copied to clipboard!",
                status: "success",
              });
            }}
            style={{
              cursor: "pointer",
            }}
          >
            {/* make a UI for a user's referral link they can copy */}
            <div
              style={{
                padding: "10px 15px",
                backgroundColor: theme.secondaryBackground,
                borderRadius: 50,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {/* <div>
              <i className="fa-sharp fa-link" style={{ marginRight: 10 }} />
            </div> */}
              <Text
                fontSize="sm"
                style={{
                  flex: 1,
                  color: theme.header,
                  overflow: "hidden",
                }}
                noOfLines={1}
              >
                {checkoutLink}
              </Text>
              <i
                className="fa-sharp fa-copy"
                style={{
                  marginLeft: 10,
                  cursor: "pointer",
                  color: theme.header,
                }}
              />
            </div>

            <Text
              style={{ marginTop: 5, textAlign: "center", color: theme.text }}
              fontSize="xs"
            >
              Click to copy
            </Text>
          </div>
        )}

        <br />

        {checkoutLink ? (
          <Text fontSize="md" color={theme.text}>
            This link expires after 24 hours. You can always generate a new one
            if it expires.
          </Text>
        ) : null}
      </Container>
    </Modal>
  );
}

export const BuyLinkModal = connectModal({
  name: "BuyLinkModal",
})(_BuyLinkModal);
