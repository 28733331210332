import { isNil } from "lodash/fp";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { start } from "repl";
import { Maybe } from "src/core";

const durationSeconds = 2;

function NumberOfTransactions({
  count,
  showAnimation,
}: {
  count: Maybe<number>;
  showAnimation: boolean;
}) {
  if (isNil(count) || count === 0) {
    return <>None</>;
  }

  if (!showAnimation) {
    return <>{count}</>;
  }

  return (
    <CountUp preserveValue={true} duration={durationSeconds} end={count} />
  );
}

export default NumberOfTransactions;
