import { noop } from "lodash";
import { createContext } from "react";

export type DefaultValues = {
  selectedTransactionIds: string[];
  activeLabel: string | null;
  wipeTxnIds: () => void;
  addOrRemoveTxnIds: (txnId: string) => void;
  setActiveLabel: (label: string | null) => void;
};

const defaultValues = {
  selectedTransactionIds: [],
  activeLabel: null,
  wipeTxnIds: noop,
  addOrRemoveTxnIds: noop,
  setActiveLabel: noop,
};

export const SelectTransactionsContext =
  createContext<DefaultValues>(defaultValues);
