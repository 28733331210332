import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { truncate } from "lodash";
import numbro from "numbro";
import { BalanceSnapshot } from "src/api/generated/types";
import { AwakenTooltip } from "src/components/styled";
import { useTheme } from "src/hooks/useTheme";

export const BalancesTable = ({
  balances,
}: {
  balances: BalanceSnapshot[];
}) => {
  const theme = useTheme();

  return (
    <Box
      bg={theme.medBackground}
      w="100%"
      position="relative"
      borderRadius="0.5rem"
    >
      <Box w="100%" maxW="100%" overflowX="scroll" border="none">
        <Table w="100%" maxW="100%" border="none">
          <Thead bg={theme.medBackground} color={theme.header}>
            <Tr>
              <Th
                style={{
                  color: theme.header,
                }}
              >
                Account
              </Th>
              <Th
                style={{
                  color: theme.header,
                }}
              >
                Asset
              </Th>
              <Th
                style={{
                  color: theme.header,
                }}
              >
                Before
              </Th>
              <Th
                style={{
                  color: theme.header,
                }}
              >
                After
              </Th>
            </Tr>
          </Thead>

          <Tbody border="none" w="100%">
            {balances.map((e, i) => (
              <BalanceRow balance={e} key={`${e.accountId}-${e.assetId}`} />
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

const BalanceRow = ({ balance }: { balance: BalanceSnapshot }) => {
  const theme = useTheme();

  return (
    <Tr borderBottom={`1px solid ${theme.border}`}>
      <Td color={theme.text} borderBottom="0" fontSize="xs">
        <AwakenTooltip message={balance.accountName || ""}>
          {truncate(balance.accountName || "", { length: 15 })}
        </AwakenTooltip>
      </Td>
      <Td color={theme.text} borderBottom="0" fontSize="xs">
        <AwakenTooltip message={balance.assetSymbol || ""}>
          {truncate(balance.assetSymbol || "", { length: 15 })}
        </AwakenTooltip>
      </Td>
      <Td color={theme.text} borderBottom="0" fontSize="xs">
        {numbro(balance.before || 0).format("0,0.00[00000000]")}
      </Td>
      <Td color={theme.text} borderBottom="0" fontSize="xs">
        {numbro(balance.after || 0).format("0,0.00[00000000]")}
      </Td>
    </Tr>
  );
};
