import { useQuery } from "@apollo/client";
import { Box, Divider, Flex, Heading, HStack, Text } from "@chakra-ui/react";
import { isNil } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { BaseBookkeepingQuoteFields } from "src/api/fragments";
import { BookkeepingQuoteStatusEnum, Query } from "src/api/generated/types";
import { Button } from "src/components";
import StatusTag from "src/components/styled/StatusTag";
import { Touchable } from "src/components/Touchable";
import { useTheme } from "src/hooks/useTheme";
import { D } from "src/utils/helpers";

function Bookkeeping() {
  const theme = useTheme();
  const { clientId } = useParams();
  const dispatch = useDispatch();

  const { data } = useQuery<Pick<Query, "getClientBookkeepingQuotes">>(
    api.bookkeepingQuotes.listForClient,
    {
      variables: { clientId },
      skip: !clientId,
    }
  );

  const _submitNewRequest = () => {
    dispatch(show("VIPQuoteRequestModal"));
  };

  const quotes = data?.getClientBookkeepingQuotes ?? [];

  return (
    <Box
      maxWidth={"inherit"}
      bg={theme.background}
      // style={{
      //   padding: "1rem 2.5rem",
      // }}
    >
      <Box w="100%" paddingTop="1rem">
        {/* <FreeCreditBadge /> */}

        <HStack alignItems="center" padding="0.5rem 0">
          <Flex flex={1} alignItems="center" display="flex" flexDir="row">
            <Heading color={theme.header} margin="0" size="lg">
              Bookkeeping
            </Heading>
          </Flex>

          <Button onClick={_submitNewRequest} variant="primary">
            Request Quote
          </Button>
        </HStack>

        <div style={{ marginTop: 25 }}>
          <Text
            style={{
              color: theme.text,
              maxWidth: 600,
              fontWeight: "normal",
              marginBottom: 15,
            }}
          >
            Year-round bookkeeping so you can have someone else do your crypto
            taxes for you. Starts at $1,000 a year. Price varies based on a
            variety of factors.
          </Text>

          <br />

          {quotes.map((quote: BaseBookkeepingQuoteFields) => (
            <QuoteRow key={quote.id} quote={quote} />
          ))}
        </div>
      </Box>
    </Box>
  );
}

const QuoteRow = ({ quote }: { quote: BaseBookkeepingQuoteFields }) => {
  const theme = useTheme();
  const { clientId } = useParams();

  const navigate = useNavigate();

  const _viewQuote = () => {
    navigate(`/clients/${clientId}/quotes/${quote.id}`);
  };

  return (
    <Link to={`/clients/${clientId}/quotes/${quote.id}`}>
      <HStack
        style={{
          cursor: "pointer",
          width: "100%",
          background: theme.background,
          padding: 15,
          marginBottom: "1rem",
          borderRadius: 15,
          border: "1px solid " + theme.border,
        }}
      >
        <Box style={{ flex: 1 }}>
          <Text color={theme.header} fontSize="md">
            Bookkeeping Quote for {quote.requestYear}
          </Text>

          <div style={{ color: theme.text }}>----</div>

          <Text color={theme.text} fontSize="sm">
            Overview: {quote.description}
          </Text>
          <Text color={theme.text} fontSize="sm">
            Tax years: {quote.taxYearsDescription}
          </Text>
        </Box>

        {/* <Box style={{ width: 200, textAlign: "center" }}>
          <Text color={theme.header} fontSize="sm" marginBottom="0.5rem">
            Pricing:
          </Text>
          <Text color={theme.text} fontSize="sm">
            {isNil(quote.monthlyPriceCents)
              ? "TBD"
              : D(quote.monthlyPriceCents).toFormat()}
            /mo
          </Text>
          <Text color={theme.text} fontSize="sm">
            -or-
          </Text>
          <Text color={theme.text} fontSize="sm">
            {isNil(quote.upfrontPriceCents)
              ? "TBD"
              : D(quote.upfrontPriceCents).toFormat()}{" "}
            upfront
          </Text>
        </Box> */}
        <Box style={{ width: 200 }}>
          <QuoteStatus status={quote.status} />
        </Box>
        <Box
          style={{
            alignItems: "flex-end",
          }}
        >
          <Touchable
            style={{
              color: theme.header,
            }}
          >
            View Quote{" "}
            <i style={{ marginLeft: 5 }} className="fas fa-chevron-right" />
          </Touchable>
        </Box>
      </HStack>
    </Link>
  );
};

export const QuoteStatus = ({
  status,
}: {
  status: BookkeepingQuoteStatusEnum;
}) => {
  if (status === BookkeepingQuoteStatusEnum.Accepted) {
    return (
      <StatusTag type="success" label="Accepted" iconName="fa-sharp fa-check" />
    );
  }

  if (status === BookkeepingQuoteStatusEnum.Rejected) {
    return (
      <StatusTag type="error" label="Rejected" iconName="fa-sharp fa-times" />
    );
  }

  if (status === BookkeepingQuoteStatusEnum.Sent) {
    return (
      <StatusTag
        type="info"
        label="Awaiting Response"
        iconName="fa-sharp fa-envelope"
      />
    );
  }

  return (
    <StatusTag type="warning" label="Requested" iconName="fa-sharp fa-sync" />
  );
};

export default Bookkeeping;
