import { Button, Container, Text, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useClientById, useMyToast } from "src/hooks";
import { MutationFreeSubscriptionArgs } from "src/api/generated/types";
import { useParams } from "react-router-dom";
import { colors } from "src/theme";
import { useDispatch } from "react-redux";
import { setShowConfetti } from "src/redux/reducers/globalState";
import { trackEvent } from "src/utils/analytics";

type Props = InjectedProps;

function _ShareModal({ handleHide, show: isVisible }: Props) {
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });

  const _copyLink = () => {
    if (!client) return;

    const referralCode = client?.referralCode || "";

    navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    toast.show({
      message:
        "Copied sharable link to clipboard! You can paste it in discord/imessage/twitter or wherever you want.",
      status: "info",
    });

    void trackEvent("Referral Link Copied", { referralCode });
  };

  const onHide = () => {
    handleHide();
  };

  return (
    <Modal
      title="The best crypto tax software just got better"
      titleHeaderProps={{
        fontSize: 20,
      }}
      marginTop="1.5rem"
      isVisible={isVisible}
      handleHide={onHide}
      Footer={
        <Button
          width="100%"
          bg={colors.green50}
          color={colors.white}
          marginBottom="1.5rem"
          marginTop="1.5rem"
          _hover={{ bg: colors.green40 }}
          onClick={_copyLink}
        >
          Share Awaken with friends{" "}
          <i
            style={{
              marginLeft: 15,
              position: "absolute",
              right: 20,
              top: 10,
              fontSize: 20,
            }}
            className="fa-sharp fa-arrow-right"
          />
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text>
          {/* We launched Awaken 11 months ago, and thousands of you made accounts
          and gave us a shot. Your response was invigorating. So we asked
          ourselves: how can we get even better?
          <br /> <br /> */}
          Awaken is <b>100% FREE</b> until March 1st. Unlimited wallets.
          Unlimited exchanges. Unlimited transactions.
          <br /> <br />
          But, we have one favor to ask of you: <b>help us spread the word</b>.
          If you know anyone who loves crypto, please tell them about us. You
          all are the fuel that helps us grow ❤️
        </Text>

        {/* <Textarea 
          value={notes}
          noOfLines={5}
          onChange={(e) => setNotes(e.target.value)}
          // label="Client Full Name"
          placeholder="Type here..."
        /> */}
      </Container>
    </Modal>
  );
}

export const ShareModal = connectModal({
  name: "ShareModal",
})(_ShareModal);
