import { Button, Container } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import {
  BaseClientFields,
  BaseLedgerAccountWithChildrenFields,
} from "src/api/fragments";
import { Maybe } from "src/core";
import { ModalTypeName, MODAL_TYPES_MAPPING } from "./types";
import { ModalContent } from "./ModalContent";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useParams } from "react-router-dom";
import { useMyToast } from "src/hooks";

type Props = InjectedProps & {
  clientId: Maybe<string>;
  client: Maybe<BaseClientFields>;
};

function _CreateAccountModal({ handleHide, clientId, show: isVisible }: Props) {
  const toast = useMyToast();

  const [name, setName] = useState("");
  const [rootAccount, setRootAccount] =
    useState<Maybe<BaseLedgerAccountWithChildrenFields>>(null);
  const [selectedParentAccount, setSelectedParentAccount] =
    useState<Maybe<BaseLedgerAccountWithChildrenFields>>(null);
  const [modalType, setModalType] = useState<ModalTypeName>(
    ModalTypeName.AccountDetailsForm
  );

  const [createLedgerAccount, { loading }] = useMutation<
    {
      createLedgerAccount: BaseLedgerAccountWithChildrenFields;
    },
    { clientId: string; parentLedgerAccountId: string; name: string }
  >(api.clients.createLedgerAccount);

  const _createAccount = useCallback(async () => {
    try {
      if (!clientId) {
        return toast.show({
          message: "Invalid state: missing client ID.",
          status: "error",
        });
      }

      if (!name) {
        return toast.show({
          message: "Please add a name for this account.",
          status: "error",
        });
      }

      if (!selectedParentAccount || !selectedParentAccount.id) {
        return toast.show({
          message: "Must select an account as the parent of your account.",
          status: "error",
        });
      }

      await createLedgerAccount({
        variables: {
          clientId,
          name,
          parentLedgerAccountId: selectedParentAccount.id,
        },
        refetchQueries: [api.clients.ledgerAccounts],
      });

      toast.show({
        message: `Successfully created account ${name}!`,
        status: "success",
      });

      handleHide();
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  }, [toast, clientId, name, selectedParentAccount]);

  const _onClickButton = useCallback(async () => {
    if (modalType === ModalTypeName.AccountDetailsForm) {
      return setModalType(ModalTypeName.CreateAccountRootLevel);
    }

    if (modalType === ModalTypeName.CreateAccountRootLevel) {
      return setModalType(ModalTypeName.AccountTreeSelector);
    }

    if (modalType === ModalTypeName.AccountTreeSelector) {
      await _createAccount();
    }
  }, [modalType, _createAccount]);

  const info = MODAL_TYPES_MAPPING[modalType];

  const disabled = useMemo(
    () => getIsDisabled(modalType, name, rootAccount, selectedParentAccount),
    [modalType, name, rootAccount, selectedParentAccount]
  );

  return (
    <Modal
      title={info?.title}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          onClick={_onClickButton}
          disabled={disabled}
          isLoading={loading}
        >
          {info?.buttonTitle}
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <ModalContent
          modalType={modalType}
          name={name}
          rootAccount={rootAccount}
          selectedParentAccount={selectedParentAccount}
          setModalType={setModalType}
          setName={setName}
          setRootAccount={setRootAccount}
          setSelectedParentAccount={setSelectedParentAccount}
        />
      </Container>
    </Modal>
  );
}

const getIsDisabled = (
  modalType: ModalTypeName,
  name: string,
  rootAccount: Maybe<BaseLedgerAccountWithChildrenFields>,
  selectedParentAccount: Maybe<BaseLedgerAccountWithChildrenFields>
) => {
  if (modalType === ModalTypeName.AccountDetailsForm) {
    return !name || name.length < 2;
  }

  if (modalType === ModalTypeName.CreateAccountRootLevel) {
    return !rootAccount;
  }

  if (modalType === ModalTypeName.AccountTreeSelector) {
    return !selectedParentAccount;
  }

  return false;
};

export const CreateAccountModal = connectModal({
  name: "CreateAccountModal",
})(_CreateAccountModal);
