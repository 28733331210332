import {
  Box,
  HStack,
  Spinner,
  StackProps,
  Text,
  TextProps,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { noop } from "lodash/fp";
import React, { CSSProperties, useState } from "react";
import { useTheme } from "src/hooks/useTheme";

export type TouchableProps = {
  iconName?: string;
  icon?: JSX.Element;
  label?: string | JSX.Element;
  padding?: any;
  type?: string;
  iconPosition?: "right" | "left";
  iconStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  labelTextProps?: TextProps;
  isLoading?: boolean;
  showSpinnerOnLoading?: boolean;
} & StackProps;

export function Touchable({
  iconName,
  label,
  padding,
  type,
  icon,
  iconStyle,
  labelStyle,
  iconPosition = "right",
  labelTextProps,
  isLoading: _isLoading,
  showSpinnerOnLoading = true,
  ...other
}: TouchableProps) {
  const { background, secondaryBackground, ternaryBackground, text } =
    useTheme();

  const isLeft = iconPosition === "left";
  const [isInternalLoading, setInternalLoading] = useState(false);

  const _onClick: React.MouseEventHandler<HTMLDivElement> = async (e) => {
    setInternalLoading(true);
    try {
      if (other.onClick) {
        await other.onClick(e);
      }
    } finally {
      setInternalLoading(false);
    }
  };

  const isLoading = isInternalLoading || _isLoading;
  const textColor = (labelStyle?.color ||
    labelTextProps?.color ||
    text) as string;
  return (
    <TouchableContainer
      {...other}
      overflow="hidden"
      borderRadius="7px"
      padding={padding || "0.35rem 0.5rem"}
      cursor="pointer"
      style={{
        display: "inline-block",
      }}
      _hover={{
        ...other._hover,
        opacity: 0.9,
        backgroundColor: ternaryBackground,
      }}
      aria-disabled={isLoading}
      _disabled={{ opacity: 0.7, cursor: "inherit" }}
      {...other}
      onClick={isLoading ? noop : _onClick}
    >
      <HStack alignItems="center">
        {isLoading && showSpinnerOnLoading && (
          <span style={{ width: 15, display: "inline-flex", marginRight: 5 }}>
            <Spinner color={labelStyle?.color || textColor} size="sm" />
          </span>
        )}
        {(isLeft ? icon : null) ||
          (iconName && isLeft && (
            <div
              style={{
                width: 25,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                className={iconName}
                style={{ fontSize: 14, color: text, ...iconStyle }}
              />
            </div>
          ))}

        {label && (
          <Box>
            <Text
              {...labelTextProps}
              fontWeight="500"
              fontSize="sm"
              color={textColor}
              paddingRight="0.25rem"
              style={{ ...labelTextProps?.style, ...labelStyle }}
            >
              {label}
            </Text>
          </Box>
        )}

        <>{other.children}</>

        {(!isLeft ? icon : null) ||
          (iconName && !isLeft && (
            <i
              className={iconName}
              style={{ fontSize: 14, color: text, ...iconStyle }}
            />
          ))}
      </HStack>
    </TouchableContainer>
  );
}

const TouchableContainer = styled(HStack)``;
