import { useSearchParams } from "react-router-dom";
import { useLocalStorage } from "./common";
import { useEffect } from "react";

export const useReferralCode = () => {
  const [search] = useSearchParams();
  const _refCode = search.get("ref");

  const [refCode, setRefCode] = useLocalStorage("first_referral_code", null);

  // if there is no ref code set, set this in local storage so it persists between page loads
  useEffect(() => {
    if (!refCode) {
      setRefCode(_refCode);
    }
  }, [_refCode, refCode]);

  // always prioritize the query string one first. and then the one in local storage
  return {
    ref: _refCode ?? refCode,
    setRefCodeLocalStorage: setRefCode,
  };
};
