import Helpers from "src/utils/helpers";

export const getProviderShort = (provider: string) => {
  if (provider?.toLowerCase() === "bsc") return "BSC";
  if (provider?.toLowerCase() === "ethereum") return "ETH";
  if (provider?.toLowerCase() === "solana") return "SOL";
  if (provider?.toLowerCase() === "polygon") return "MATIC";
  if (provider?.toLowerCase() === "avalanche") return "AVAX";
  if (provider?.toLowerCase() === "fantom") return "FTM";
  if (provider?.toLowerCase() === "base") return "BASE";

  return Helpers.capitalize(provider);
};
