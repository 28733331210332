const CRYPTO_TAX_PREP = "cryptotaxprep.io";

const WHITELISTED_DOMAINS: string[] = [CRYPTO_TAX_PREP];

export const isWhiteLabeledDomain = () => {
  const hostNameContainsWhiteList = WHITELISTED_DOMAINS.some((domain) =>
    window.location.hostname.includes(domain.toLowerCase())
  );

  return (
    process.env.REACT_APP_IS_WHITE_LABEL_MODE === "true" ||
    hostNameContainsWhiteList
  );
};

export const showPrivacyPolicy = () => {
  return !isWhiteLabeledDomain();
};

export const getMetadataInfo = () => {
  const name = window.location.hostname;
  const isWhiteLabel = isWhiteLabeledDomain();

  if (!isWhiteLabel) return null;

  if (name.includes(CRYPTO_TAX_PREP)) {
    return {
      name: "Crypto Tax Prep",
      primaryColor: "#000",
      description:
        "Blockchain Crypto Tax Prep - The No Hassle Crypto-Tax Preparation Service",
      url: "https://cryptotaxprep.io",
      logo: require("src/assets/whitelabel/cryptotaxprep.png"),
      logoUrl: "https://assets.awaken.tax/whitelabel/cryptotaxprep.png",
      favicon: require("src/assets/whitelabel/cryptotaxprep-favicon.png"),
    };
  }

  return null;
};
