import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { BalanceSnapshot } from "src/api/generated/types";
import { Touchable } from "src/components/Touchable";
import { ActiveTransactionContext } from "src/context";
import { useMe } from "src/hooks";
import { BalancesTable } from "./BalancesTable";

export const BalancesPopover = () => {
  const { me } = useMe();
  const { transaction } = useContext(ActiveTransactionContext);

  const [isOpen, setOpen] = useState(false);
  const balances = ((transaction as any)?.balances ?? []) as BalanceSnapshot[];

  const _onOpen = () => setOpen(true);

  if (!transaction || !balances.length) {
    return null;
  }

  return (
    <Popover
      trigger="hover"
      onOpen={_onOpen}
      onClose={() => setOpen(false)}
      isOpen={isOpen}
      placement="bottom"
    >
      <PopoverTrigger>
        <div style={{ display: "flex" }}>
          <Touchable
            padding="0.55rem 0.75rem"
            label={`Balances`}
            iconName="fa-sharp fa-balance-scale"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        width="auto"
        left="5px"
        maxHeight="400px"
        maxWidth="475px"
        zIndex={1000}
        overflowY="scroll"
        style={{ zIndex: 1000 }}
      >
        <PopoverArrow />

        <BalancesTable balances={balances} />
      </PopoverContent>
    </Popover>
  );
};
