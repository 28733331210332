import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Box, Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import * as colors from "src/theme/colors";
import { useLocation } from "react-router-dom";
import Logo from "src/assets/awaken/logos/logo-name.png";
import { useMe } from "src/hooks";
import { useMemo } from "react";

export const NAVBAR_HEIGHT = "4rem";

type Props = {
  position?: string;
  button?: string;
  maxWidth?: any;
};

const NavBarContainer = styled.nav<Props>`
  height: ${NAVBAR_HEIGHT};
  top: 0;
  position: ${(props) => props.position || "relative"};
  border-bottom: 1px solid ${colors.gray2};
  background-color: ${colors.white};
  z-index: 100;
  width: 100%;
`;

function NavBar(props: Props) {
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();
  const { me } = useMe();

  const button = useMemo(() => {
    if (props.button === "logout") {
      return (
        me && (
          <Link to="/logout">
            <Text _hover={{ color: colors.red50 }}>
              <i className="fa-sharp fa-sign-out" /> Logout
            </Text>
          </Link>
        )
      );
    }
  }, [props.button, me]);

  return (
    <NavBarContainer position={props.position}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
        w="100%"
        h="100%"
        maxW={props.maxWidth}
        margin="auto"
      >
        <Box
          cursor="pointer"
          onClick={
            clientId ? () => navigate("/dashboard") : () => navigate("/")
          }
          paddingLeft="2rem"
        >
          <Image src={Logo} h="2.5rem" />
        </Box>
        <Box marginRight="2rem">{button}</Box>
      </Box>
    </NavBarContainer>
  );
}

export default NavBar;
