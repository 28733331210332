import { useMutation } from "@apollo/client";
import { Button, Container, FormLabel, HStack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { MutationCreateTransactionArgs } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import {
  DatePicker,
  Input,
  Select,
  useOnInvalidForm,
} from "src/components/styled";
import { useClientById, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { FormValues, getDefaultValues, schema } from "./form";

type Props = InjectedProps;

function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d as any);
}

function _CreateTxnModal({ handleHide, show: isVisible }: Props) {
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();
  const onInvalidForm = useOnInvalidForm();
  const [createTransaction, { loading: isLoadingCreate }] = useMutation(
    api.transactions.create
  );

  const dispatch = useDispatch();
  const { accounts, client } = useClientById(clientId, {
    accountFetchPolicy: "cache-first",
    clientFetchPolicy: "cache-first",
    skipFetchAssetsOnLoad: true,
  });

  const timezone = client?.timezone || "UTC";

  const options = useMemo(
    () =>
      accounts.map((a) => ({
        label: a.description,
        value: a.id,
        account: a,
      })),
    [accounts]
  );

  const theme = useTheme();

  // Form hooks / fxns
  const values = useMemo(() => getDefaultValues(), []);
  const [search, setSearchParams] = useSearchParams();

  const formProps = useForm<FormValues>({
    defaultValues: values,
    resolver: yupResolver(schema),
  });

  async function _onSubmit(values: FormValues) {
    try {
      if (!values.accountId) {
        toast.show({
          message: "Please select a wallet/exchange",
          status: "error",
        });
        return;
      }

      const time = values.time;
      const date = values.date;

      // convert a time string and a date string to a date time ISO string
      const dateTime = _buildDate(date, time);

      if (!dateTime) {
        toast.show({
          message: "Invalid date/time",
          status: "error",
        });
        return;
      }

      const valid = isValidDate(new Date(dateTime));

      // DO NOT accept anything except ISO dates!!
      // E.g. Etherscan shows '02:50:54 PM', but one of us will probably forget to copy the 'PM'
      // so it would be treated as AM... ISO format avoids all these problems
      if (!valid) {
        toast.show({
          message:
            "Date is not valid, must be in ISO format: 2018-05-12T03:11:06.000Z",
          status: "error",
        });
        return;
      }

      // from the "timezone" to UTC
      // get offset of the timezone from UTC
      const offset = moment.tz.zone(timezone)?.utcOffset(Date.now()) || 0;
      const browserOffset = new Date().getTimezoneOffset();

      // add the offset to the date to get the UTC date

      const utcDate = moment
        .utc(dateTime, timezone)
        .add(offset, "minutes")
        .subtract(Math.abs(browserOffset), "minutes");

      // Ex. 18 July 10th -> 22 July 10th (UTC) OR 15 July 10th PST

      // console.log(utcDate.toDate());

      // return;

      const params: MutationCreateTransactionArgs = {
        clientId: clientId || "",
        createdAt: utcDate.toDate(),
        accountId: values.accountId,
        txnHash: values.txnHash,
      };

      const txn = await createTransaction({
        variables: params,
        refetchQueries: [api.clients.transactions, api.transactions.retrieve],
      });

      toast.show({
        message: `Successfully created transaction`,
        status: "success",
      });

      const id = txn.data?.createTransaction.id;

      handleHide();

      setTimeout(() => {
        search.delete("transactionId"); // delete the current txn id
        setSearchParams(search);
        search.append("transactionId", id);
        setSearchParams(search);
      }, 250);
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  }

  const _date = formProps.watch("date");
  const _time = formProps.watch("time");

  const date = useMemo(() => {
    return _buildDate(_date, _time);
  }, [_date, _time]);

  const formattedDate = useMemo(() => {
    if (!date) return "";

    // move the date to UTC, and then add the timezone to it

    const d = moment.tz(date, timezone);

    return d.toISOString();
  }, [date, timezone]);

  return (
    <Modal
      title="Create Transaction"
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          type="submit"
          variant="primary"
          isLoading={isLoadingCreate}
          onClick={formProps.handleSubmit(_onSubmit, onInvalidForm)}
        >
          Create Transaction
        </Button>
      }
    >
      <form>
        <Container padding="0" marginTop="1rem">
          <Select
            label="Wallet/Exchange"
            selectProps={{
              onChange: (e: any) => formProps.setValue("accountId", e.value),
              components: {
                Option: ({ innerRef, innerProps, label, data }) => {
                  const theme = useTheme();

                  return (
                    <div>
                      <HStack
                        padding="0.5rem 0.5rem"
                        w="100%"
                        ref={innerRef}
                        {...innerProps}
                        _hover={{
                          bg: theme.secondaryBackground,
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={(data as any).account.iconImageUrl}
                          style={{
                            borderRadius: "50%",
                            width: 20,
                            height: 20,
                            marginRight: 5,
                          }}
                        />
                        <Text
                          color={theme.text}
                          // hide if overflows flex
                          overflow="hidden"
                          flex={1}
                          fontSize="sm"
                        >
                          {label}
                        </Text>
                      </HStack>
                    </div>
                  );
                },
              },
            }}
            options={options}
            isRequired
          />

          <br />

          <FormLabel color={theme.header} fontSize="sm">
            Time ({timezone}) <span style={{ color: colors.red50 }}>*</span>
          </FormLabel>

          <HStack style={{ alignItems: "center" }}>
            <Input
              name="time"
              onChange={(e) => formProps.setValue("time", e.target.value || "")}
              value={_time}
              // UTC format
              // timeIntervals={15}
              placeholder="11:15am"
              isRequired
            />

            <span style={{ position: "relative", top: -8, color: theme.text }}>
              on
            </span>

            <DatePicker
              name="date"
              onChange={(date) =>
                formProps.setValue(
                  "date",
                  date ? moment(date).format("MM-DD-YYYY") : ""
                )
              }
              value={_date ? new Date(_date) : null}
              // UTC format
              dateFormat="MM/dd/yyyy"
              showTimeSelect={false}
              // timeIntervals={15}
              placeholder=""
            />
          </HStack>

          {formattedDate && (
            <>
              <Text fontSize="sm" color={theme.text}>
                Time: {moment(formattedDate).format("h:mma M/D/YYYY")} (
                {timezone})
              </Text>
            </>
          )}
          <br />

          <Input
            control={formProps.control}
            name="txnHash"
            label="Transaction Hash"
            placeholder="0x68d452f07d73fa4e7dc9c23cfa97aa98d1ddcb8d279a385c840caba64a452b90"
            subtitle="Optional, only useful for blockchain transactions"
          />

          <Text fontSize="sm" color={theme.text}>
            Note: in the next step you will add the "sent" and "received"
            assets.
          </Text>
        </Container>
      </form>
    </Modal>
  );
}

const _buildDate = (__date: string, __time: string): Date | null => {
  const _date = __date.trim();
  const _time = __time; // don't trim bc can add a space

  if (!_date) return null;

  if (_date && !_time) {
    const date = new Date(_date);
    // set the time to 00:00
    return new Date(date.setHours(0, 0, 0, 0));
  }

  if (_date && _time) {
    const date = new Date(_date);
    // split the time by a space (if there is one)
    const time = _time.split(" ")[0];
    // the first part is the hours + minutes. the second part is the AM or PM
    const [hours, minutes] = time.split(":");
    // get the AM or PM. it is the first a or p and then one letter after
    const ampm = _time.match(/([aApP]).{1}/)?.[0];

    // console.log(ampm);

    // set the time to the hours. set minutes if there are none, otherwise leave as null
    date.setHours(parseInt(hours), minutes ? parseInt(minutes) : 0, 0, 0);

    // if the time is PM, add 12 hours
    if (ampm && ampm.toLowerCase() === "pm") {
      const newHours = date.getHours() + 12;
      // console.log("HOURS: " + newHours);
      date.setHours(date.getHours() + 12);
    }

    // console.log(date);

    return date;
  }

  return null;
};

export const CreateTxnModal = connectModal({
  name: "CreateTxnModal",
})(_CreateTxnModal);
