import { Box, Flex, HStack, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AccountsWorkflow } from "src/components/modals/AccountModal/AccountsWorkflow";

function AddAccountScreen() {
  const onSuccess = () => {
    const RN = (window as any).ReactNativeWebView;

    if (!RN) return;

    const params = JSON.stringify({ type: "success" });
    RN.postMessage(params);
  };

  // Note: this screen is only used by the app so the negative pixel is hacky to get rid of ugly padding
  return (
    <Box top="-30px" position="relative" maxWidth={"inherit"}>
      <Box w="100%">
        <HStack alignItems="center" padding="0rem 0">
          <Flex flex={1} alignItems="center" display="flex" flexDir="row">
            <Heading marginTop="0" marginBottom="2rem" size="lg">
              Add an account or wallet
            </Heading>
          </Flex>
        </HStack>

        <AccountsWorkflow onSuccess={onSuccess} />
      </Box>
    </Box>
  );
}

export default AddAccountScreen;
