import { truncate } from "lodash";
import { PartialAssetFields } from "src/api/fragments";
import { AssetTypeEnum } from "src/api/generated/types";
import { Maybe } from "src/core";
import truncateMiddle from "truncate-middle";

export const isUnnamedAsset = (asset: PartialAssetFields) => {
  const lower = asset?.name?.toLowerCase();
  return !!asset && (lower === "nft" || lower === "token");
};

export const _getAssetSymbolOrName = (asset: PartialAssetFields) => {
  if (isUnnamedAsset(asset)) {
    return `${asset?.name} (${asset?.contractAddress || ""})`;
  }

  if (
    asset.provider === "bitcoin" &&
    asset.symbol?.toUpperCase() !== "BTC" &&
    asset.type === AssetTypeEnum.FungibleToken
  ) {
    return asset.name || asset.symbol || "";
  }

  return (
    (asset?.type === AssetTypeEnum.Nft ? asset?.name : asset?.symbol || "") ||
    ""
  );
};

export const getAssetSymbolOrName = (
  asset: Maybe<PartialAssetFields>,
  truncateLength?: number,
  shouldTruncateMiddle?: boolean
): string => {
  if (!asset) return "";
  const symbolOrName = _getAssetSymbolOrName(asset);

  if (truncateLength) {
    if (shouldTruncateMiddle) {
      return truncateMiddle(
        symbolOrName,
        truncateLength,
        truncateLength,
        "..."
      );
    }

    return truncate(symbolOrName, { length: truncateLength });
  }
  return symbolOrName;
};
