export const DEFAULT_EMOJIS = [
  // "👻",
  // "👽",
  // "🤖",
  // "👾",
  // "🐭",
  // "🦕",
  // "🦖",
  // "🐉",
  ["🔥", "Hot wallet"],
  ["🧊", "Cold wallet"],
  ["👾", "Game wallet"],
  ["🖼️", "NFTs wallet"],
  ["🏦", "Storage wallet"],
  // "🧱",
];
