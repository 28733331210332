import {
  Button,
  Text,
  VStack,
  Spinner,
  Box,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Center,
} from "@chakra-ui/react";
import { getLink, isReviewed } from "src/modules/ledger/transactions";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { compose } from "lodash/fp";
import { truncate } from "lodash";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import {
  BaseFullTransactionFields,
  BaseSimpleTransactionFields,
} from "src/api/fragments";
import { Maybe } from "src/core";
import { useLazyQuery } from "@apollo/client";
import { api } from "src/api";
import { useEffect } from "react";
import { isLoadingGQL } from "src/utils/helpers";
import { useParams } from "react-router-dom";
import { ReviewStatusTag } from "src/components/Labels";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  regularSelect: () => void;
  // handleHideLabelsModal: () => void;
  transaction?: Maybe<BaseFullTransactionFields>;
};

const Loading = () => (
  <Box
    w="100%"
    h="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Spinner />
  </Box>
);

function _UnstakingWarningModal({
  handleHide,
  show: isVisible,
  regularSelect,
  transaction,
}: Props) {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const to = JSON.parse(transaction?.constraints || "{}")?.to;
  const assetIds =
    transaction?.transfers
      ?.map((x) => x.assetId || undefined)
      .filter((x) => !!x)
      .map((x) => x!) || [];

  const [
    fetchTransactions,
    {
      data: transactionData,
      networkStatus: transactionNetworkStatus,
      error: errorGetTransactions,
    },
  ] = useLazyQuery<{
    getClientTransactions?: {
      transactions: BaseSimpleTransactionFields[];
      total: number;
    };
  }>(api.clients.transactions, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const loading = isLoadingGQL(transactionNetworkStatus);
  const transactions = transactionData?.getClientTransactions?.transactions;

  useEffect(() => {
    if (isVisible && !transactionData && to) {
      fetchTransactions({
        variables: {
          clientId,
          limit: 7,
          search: to,
          assetIds: assetIds.length > 0 ? assetIds : undefined,
          providers: transaction?.provider ? [transaction.provider] : undefined,
        },
      });
    }
  }, [isVisible, transactionData, to]);

  const _onClickDone = () => {
    regularSelect();
    handleHide();
  };

  const _onClickTxn = (e: any, to: string, t?: BaseSimpleTransactionFields) => {
    e.preventDefault();
    e.stopPropagation();

    const link = getLink(clientId || "", {
      search: to,
      transactionId: t?.id || undefined,
      assetIds: assetIds.length > 0 ? assetIds : undefined,
      providers: transaction?.provider ? [transaction.provider] : undefined,
    });
    window.open(link, "_blank");
  };

  const theme = useTheme();

  if (!transaction) return <Box />;
  // if (!loading && !to) return <Box />;

  return (
    <Modal
      marginTop="5rem"
      // title={`Connect your Wallet/Exchange`}
      isVisible={isVisible}
      handleHide={handleHide}
      h="47rem"
      minW="40rem"
      Footer={
        <VStack w="100%">
          rw
          <Button
            variant="primary"
            style={{
              width: "100%",
            }}
            onClick={_onClickDone}
          >
            Done
          </Button>
        </VStack>
      }
    >
      {loading ? <Loading /> : <div />}
      {!loading && !to ? (
        <Box>
          {" "}
          <Text color={theme.text} margin="2rem 0">
            No unstaking transaction found, so you are good to go.
          </Text>
        </Box>
      ) : (
        <div />
      )}
      {!loading && transactions && to ? (
        <Box w="100%" h="100%">
          <Text margin="2rem 0" color={theme.text}>
            You are labeling this transaction as Staking. Make sure you have
            labeled the corresponding unstaking transaction as "🥩 Unstaking
            Withdraw". Here are the possible unstaking transactions:
          </Text>

          <Table
            style={{
              borderRadius: 10,
              border: `1px solid ${theme.border}`,
            }}
          >
            <Thead>
              <Tr>
                <Th
                  style={{
                    borderBottom: `1px solid ${theme.border}`,
                  }}
                >
                  Title
                </Th>
                <Th
                  style={{
                    borderBottom: `1px solid ${theme.border}`,
                  }}
                >
                  <Center>Reviewed</Center>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {transactions.map((t) => (
                <Tr onClick={(e) => _onClickTxn(e, to, t)} cursor="pointer">
                  <Td
                    style={{
                      borderBottom: "none",
                    }}
                  >
                    <Text color={theme.text}>
                      {truncate(t.title || "", { length: 46 })}
                    </Text>
                  </Td>
                  <Td
                    style={{
                      borderBottom: "none",
                    }}
                  >
                    <Center>
                      <ReviewStatusTag
                        isReviewed={isReviewed(t)}
                        needsRecalculate={
                          transaction.needsReview?.needsRecalculate || false
                        }
                      />
                    </Center>
                  </Td>
                </Tr>
              ))}
              <Tr onClick={(e) => _onClickTxn(e, to)} cursor="pointer">
                <Td
                  style={{
                    borderTop: `1px solid ${theme.border}`,
                    borderBottom: "none",
                  }}
                >
                  <Text color={theme.text}>
                    View all possible unstaking transactions
                  </Text>
                </Td>
                <Td
                  style={{
                    borderTop: `1px solid ${theme.border}`,
                    borderBottom: "none",
                  }}
                  cursor="pointer"
                >
                  <Center>
                    <i
                      style={{
                        color: theme.text,
                      }}
                      className="far fa-external-link"
                    ></i>
                  </Center>
                </Td>
              </Tr>
            </Tbody>
          </Table>

          {/* <a>
            View all possible unstaking transactions{" "}
            <i className="far fa-external-link"></i>
          </a> */}
        </Box>
      ) : (
        <div />
      )}
    </Modal>
  );
}

export const UnstakingWarningModal = connectModal({
  name: "UnstakingWarningModal",
})(_UnstakingWarningModal);
