import { useMutation } from "@apollo/client";
import {
  Box,
  HStack,
  Link,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Progress,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { isNil, truncate } from "lodash";
import numbro from "numbro";
import { singular } from "pluralize";
import { useState } from "react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import { api } from "src/api";
import { BaseAssetFields } from "src/api/fragments";
import {
  AssetTypeEnum,
  AssetWarning,
  CurrencyCodeEnum,
  PortfolioBalanceV2,
} from "src/api/generated/types";
import SmallLogo from "src/assets/awaken/logos/awaken-black.jpeg";
import { ActionSheet, AwakenTooltip } from "src/components";
import { AssetIcon } from "src/components/styled/Assets";
import StatusTag from "src/components/styled/StatusTag";
import { Touchable } from "src/components/Touchable";
import { Maybe, hasValue } from "src/core";
import { useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { getAssetOnMarketplaceOrCoingecko } from "src/modules/getAssetUrl";
import { getLink, getPortfolioLink } from "src/modules/ledger/transactions";
import { colors } from "src/theme";
import { D, formatNum } from "src/utils/helpers";
import { NAME_FLEX, VALUE_FLEX } from "./constants";
import { getGenericTokenName } from "./utils";

const MIN_DECIMALS = 8;

const _shouldRenderBreakdown = (b: { amount: number }) =>
  new BigNumber(b.amount).dp(MIN_DECIMALS).gt(0);

export const AssetRow = ({
  portfolioBalance,
  clientId,
  currency,
  warning,
}: {
  portfolioBalance: PortfolioBalanceV2;
  clientId: string;
  currency: CurrencyCodeEnum;
  warning: Maybe<AssetWarning>;
}) => {
  const {
    background,
    text,
    border,
    medBackground,
    secondaryBackground2,
    ternaryBackground,
    header,
  } = useTheme();

  const isNotAllowedBitcoinAsset =
    portfolioBalance.provider === "bitcoin" &&
    portfolioBalance.symbol !== "BTC";

  const assetKey = portfolioBalance.assetPricingKey;
  const assetDetailLink = `${getPortfolioLink(clientId)}/${assetKey}`;

  const tokenName = getGenericTokenName(
    {
      asset: { type: portfolioBalance.type },
      amount: portfolioBalance.totalAmount,
    },
    true
  );
  const provider = portfolioBalance.provider;
  const isNeg = portfolioBalance.dailyFiatAmountCents
    ? portfolioBalance.dailyFiatAmountCents < 0
    : false;
  const color = isNeg ? colors.negative : colors.positive;
  const hasAmount = portfolioBalance.totalAmount > 0;
  const isNFT = portfolioBalance.type === AssetTypeEnum.Nft;

  const isLarge = useIsLargeScreen();

  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          padding: isLarge ? "0rem 1rem" : "0 0.25rem",
          borderRadius: 10,
        }}
        bg={background}
        _hover={{
          backgroundColor: medBackground,
        }}
      >
        <ReactRouterLink
          style={{
            flex: 1,
            cursor: isNotAllowedBitcoinAsset ? "text" : "pointer",
          }}
          to={isNotAllowedBitcoinAsset ? "#" : assetDetailLink}
          color={colors.black}
        >
          <HStack
            opacity={hasAmount ? 1 : 0.3}
            // borderRadius={7}
            // borderBottom="none !important"
            width="100%"
            padding={isLarge ? "1rem 0.25rem" : "1rem 0rem"}
          >
            <Box
              // style={{ width: isLarge ? 35 : 30 }}
              flexShrink={0}
              marginRight={isLarge ? "0.5rem !important" : "0"}
            >
              <Box
                style={{ position: "relative", display: "flex", flexShrink: 0 }}
              >
                <AssetImage balance={portfolioBalance} />
              </Box>
            </Box>

            <Box
              display="flex"
              style={{
                flex: isLarge ? NAME_FLEX : NAME_FLEX - 1,
              }}
              alignItems="center"
              position="relative"
            >
              <VStack alignItems="flex-start">
                <HStack alignItems="center" role="group">
                  <Text
                    w="100%"
                    fontSize="md"
                    color={header}
                    margin="0 !important"
                    isTruncated
                    fontWeight="semibold"
                    _groupHover={{
                      textDecor: "underline",
                    }}
                  >
                    {truncate(portfolioBalance.name ?? "", {
                      length: isLarge ? 30 : 10,
                    })}{" "}
                    {isNFT && isLarge ? " Collection" : ""}
                  </Text>
                </HStack>

                {/* <AssetDescription breakdown={breakdown} assetById={assetById} /> */}

                <Tooltip
                  label={`${numbro(portfolioBalance.totalAmount ?? 0).format(
                    "0,000.[0000000000000000]"
                  )} ${portfolioBalance.symbol || "Token"}`}
                  openDelay={500}
                >
                  <Text
                    isTruncated
                    fontSize="sm"
                    isNumeric
                    fontWeight="500"
                    color={text}
                    w="100%"
                    whiteSpace="pre-wrap"
                  >
                    {formatNum(
                      portfolioBalance.totalAmount ?? 0,
                      false,
                      "0,0.[0000]"
                    )}{" "}
                    {truncate(portfolioBalance.symbol || "", { length: 10 })}
                  </Text>
                </Tooltip>
              </VStack>
            </Box>

            {isLarge && (
              <Box
                style={{
                  flex: VALUE_FLEX,
                  maxWidth: 100,
                  marginRight: 50,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginLeft: 0,
                }}
              >
                <AwakenTooltip
                  message={`${portfolioBalance.percentOfPortfolio}% of your portfolio`}
                >
                  <div style={{ width: "100%" }}>
                    <Progress
                      width="100%"
                      value={portfolioBalance.percentOfPortfolio ?? 0}
                      max={100}
                      height={2}
                      borderRadius={2}
                      bg={secondaryBackground2}
                      colorScheme="green"
                    />
                  </div>
                </AwakenTooltip>
              </Box>
            )}

            <Box
              style={{
                flex: VALUE_FLEX,
                maxWidth: 175,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 0,
              }}
            >
              <CurrentValue
                portfolioBalance={portfolioBalance}
                currency={CurrencyCodeEnum.Usd} //FIXME:
                isNeg={isNeg}
              />
            </Box>

            {isLarge && (
              <Box
                style={{
                  alignItems: "center",
                  maxWidth: 175,
                  justifyContent: "flex-end",
                  display: "flex",
                  fontWeight: "500",
                  marginLeft: 0,
                  flex: VALUE_FLEX,
                }}
              >
                <DailyGainLoss
                  portfolioBalance={portfolioBalance}
                  currency={CurrencyCodeEnum.Usd} //FIXME:
                />
              </Box>
            )}

            <Box
              style={{
                alignItems: "center",
                maxWidth: 175,
                justifyContent: "flex-end",
                display: "flex",
                fontWeight: "500",
                marginLeft: 0,
                flex: VALUE_FLEX,
              }}
            >
              <TotalGainLoss
                portfolioBalance={portfolioBalance}
                currency={CurrencyCodeEnum.Usd} //FIXME:
              />
            </Box>
          </HStack>
        </ReactRouterLink>
        {/*<Box
          width="50px"
          marginRight="10px"
          alignItems="flex-end"
          justifyContent="flex-end"
          display="flex"
        >
           <Box
            style={
              {
                //marginLeft: 5
              }
            }
          >
            <MoreAssetInfo
              assetKey={portfolioBalance.assetKey}
              asset={portfolioBalance.asset}
            />
          </Box> 
        </Box>*/}
      </Box>
    </>
  );
};

const AssetImage = ({ balance }: { balance: PortfolioBalanceV2 }) => {
  const isLarge = useIsLargeScreen();

  return (
    <AssetIcon
      textStyle={{ fontSize: 10 }}
      size={isLarge ? 35 : 30}
      style={{ flexShrink: 0, borderRadius: 100 }}
      asset={{
        type: balance.type,
        iconImageUrl: balance.iconImageUrl,
        imageUrl: balance.iconImageUrl,
        symbol: balance.symbol,
      }}
    />
  );
};

const MoreAssetInfo = ({
  asset,
  assetKey,
}: {
  asset: BaseAssetFields;
  assetKey: string;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const [isOpen, setOpen] = useState(false);
  const contractUrl = asset.blockExplorerUrl;
  const assetInfoUrl = getAssetOnMarketplaceOrCoingecko(asset);
  const toast = useMyToast();
  const [setSpam] = useMutation(api.assets.setAssetSpam);
  // const [updateAsset] = useMutation(api.assets.update);
  const [markWorthless] = useMutation(api.assets.markWorthless);

  const _copyAssetId = () => {
    navigator.clipboard.writeText(asset?.id || "");
    toast.show({ status: "info", message: "Copied asset ID!" });
  };

  const _setAssetAsSpam = async () => {
    try {
      await setSpam({
        variables: { assetId: asset.id, isSpam: true },
        refetchQueries: [api.portfolio.getPortfolioAssets],
      });
      toast.show({
        status: "success",
        message: "Successfully marked as spam!",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message:
          (err as any)?.message ||
          "Something went wrong, please try again later!",
      });
    }
  };

  const _markWorthless = async () => {
    try {
      await markWorthless({
        variables: {
          assetKey: assetKey,
          isWorthless: !asset.isWorthless,
          clientId,
        },
        refetchQueries: [
          api.portfolio.getPortfolioAssets,
          api.portfolio.getChart,
          api.portfolio.getPortfolioValue,
          api.clients.assets,
        ],
      });
      toast.show({
        status: "success",
        message: "Successfully marked as worthless.",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message:
          (err as any)?.message ||
          "Something went wrong, please try again later!",
      });
    }
  };

  const isLarge = useIsLargeScreen();

  if (asset.type === AssetTypeEnum.FiatCurrency) {
    return null;
  }

  return (
    <ActionSheet
      popover={{
        placement: "bottom-end",
        trigger: isLarge ? "hover" : "click",
      }}
      content={{ width: 250 }}
      commands={[
        {
          label: "View in Awaken",
          iconImageSrc: SmallLogo,
          iconImageStyle: { borderRadius: 5 },
          // do the link by the name / symbol bc we group assets across chains in the UI for the asset row
          link: getLink(clientId || "", {
            assetSymbolOrName:
              asset.type === AssetTypeEnum.Nft
                ? asset.name
                : asset.symbol || "",
          }),
        },
        assetInfoUrl
          ? {
              label: "View on " + assetInfoUrl.name,
              // block font awesome icon
              iconName: "fa-sharp fa-external-link-alt",
              link: assetInfoUrl?.url || "",
              iconImageSrc: assetInfoUrl?.imageUrl || "",
            }
          : null,
        contractUrl
          ? {
              label: "Open in block explorer",
              // network of nodes font awesome incon
              iconName: "fa-sharp fa-network-wired",
              link: contractUrl,
            }
          : null,
        {
          label: "Copy asset ID",
          hasDivider: true,
          iconName: "fa-sharp fa-copy",
          onClick: _copyAssetId,
        },
        {
          label: asset.isWorthless
            ? "Mark as not worthless"
            : "Mark as worthless",
          iconName: "fa-sharp fa-empty-set",
          infoMessage:
            "This is useful for tokens that may have been migrated to a new contract. This will only affect your portfolio, and we will show this asset as worth $0 and adjust the historical value to be $0 in the chart.",
          onClick: _markWorthless,
        },
        {
          label: "Mark as spam",
          iconName: "fa-sharp fa-trash",
          iconColor: colors.red50,
          onClick: _setAssetAsSpam,
        },
      ].filter(hasValue)}
    >
      <Touchable
        // open font awesome icon
        iconName="fa-sharp fa-ellipsis-v"
        style={{
          fontSize: 14,
          cursor: "pointer",
        }}
      />
    </ActionSheet>
  );
};

const AssetWarningInfo = ({
  asset,
  warning,
}: {
  asset: BaseAssetFields;
  warning: AssetWarning;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const [isOpen, setOpen] = useState(false);
  const assetLabelLink = `/clients/${clientId}/transactions?assetIds=${asset.id}&labeled=false&includeSpam=true`;

  if (!warning) {
    return null;
  }

  return (
    <Popover
      trigger="hover"
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => setOpen(false)}
      isOpen={isOpen}
      placement="bottom"
      openDelay={0}
    >
      <PopoverTrigger>
        <div style={{ display: "inline-block", margin: "0 15px" }}>
          <StatusTag
            type="warning"
            //   label="Warning"
            onClick={(e) => {
              //   e.stopPropagation();
              //   e.preventDefault();
            }}
            iconStyle={{ color: colors.yellow40, fontSize: 12 }}
            boxProps={{
              style: {
                cursor: "pointer",
                padding: "4px 8px",
                border: "1px solid " + colors.yellow40,
              },
            }}
            iconName="fa-sharp fa-flag"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent padding="0.5rem 1rem">
        <HStack
          style={{
            padding: "0.25rem",
            width: "100%",
            whiteSpace: "normal",
          }}
        >
          {warning.type === "unlabeled" ? (
            <span>
              {warning.message}
              <hr style={{ margin: "0.5rem 0" }} />
              To get an accurate portfolio, please{" "}
              <Link
                style={{
                  textDecoration: "underline",
                  color: colors.primary,
                  fontWeight: "bold",
                }}
                href={assetLabelLink}
              >
                label the transactions
              </Link>{" "}
              🙏
            </span>
          ) : (
            <span>
              This asset balance has an issue, please contact support and we'll
              help you out!
            </span>
          )}
        </HStack>
      </PopoverContent>
    </Popover>
  );
};

const TotalGainLoss = ({
  portfolioBalance,
  currency,
}: {
  portfolioBalance: PortfolioBalanceV2;
  currency: CurrencyCodeEnum;
}) => {
  const isLarge = useIsLargeScreen();

  if (portfolioBalance.isMissingBasis) {
    return (
      <HStack alignItems="center">
        <StatusTag
          type="none"
          iconName="fa-sharp fa-question-circle"
          infoMessage="We don't have the full cost basis of this asset. This can mean you need to label more transactions or there is potentially a missing transaction you'll have to hunt down."
        />
      </HStack>
    );
  }

  if (
    !portfolioBalance.gainLossCents ||
    isNaN(portfolioBalance.gainLossCents)
  ) {
    return <HStack alignItems="center">—</HStack>;
  }

  const isNeg = portfolioBalance.gainLossCents < 0;
  const color = isNeg ? colors.negative : colors.positive;

  if (isNil(portfolioBalance.positionPercentageFormatted)) {
    return <HStack alignItems="center">—</HStack>;
  }

  return (
    <HStack
      alignItems="center"
      style={{ width: "100%", justifyContent: "flex-end" }}
    >
      {isLarge && (
        <i
          className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
          style={{
            fontSize: 16,
            color,
          }}
        />
      )}

      <VStack
        alignItems="flex-end"
        style={{
          fontWeight: "500",
          color,
          width: "100%",
          maxWidth: 100,
        }}
      >
        <Text fontWeight="700" fontSize="md" color={color} marginBottom="5px">
          {D(Math.abs(portfolioBalance.gainLossCents), currency).toFormat()}{" "}
        </Text>
        <Text fontSize="md" marginTop="0 !important" style={{ color }}>
          {/* {isNeg ? "-" : ""} */}
          {portfolioBalance.positionPercentageFormatted}
        </Text>
      </VStack>
    </HStack>
  );
};

const DailyGainLoss = ({
  portfolioBalance,
  currency,
}: {
  portfolioBalance: PortfolioBalanceV2;
  currency: CurrencyCodeEnum;
}) => {
  const isLarge = useIsLargeScreen();

  if (
    !portfolioBalance.dailyFiatAmountCents ||
    isNaN(portfolioBalance.dailyFiatAmountCents) ||
    !portfolioBalance.hasPrice
  ) {
    return <HStack alignItems="center">—</HStack>;
  }

  const isNeg = portfolioBalance.dailyFiatAmountCents < 0;
  const color = isNeg ? colors.negative : colors.positive;

  return (
    <HStack
      alignItems="center"
      style={{ width: "100%", justifyContent: "flex-end" }}
    >
      {isLarge && (
        <i
          className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
          style={{
            fontSize: 16,
            color,
          }}
        />
      )}

      <VStack
        alignItems="flex-end"
        style={{
          fontWeight: "500",
          color,
          width: "100%",
          maxWidth: 100,
        }}
      >
        <Text fontWeight="700" fontSize="md" color={color} marginBottom="5px">
          {D(
            Math.abs(portfolioBalance.dailyFiatAmountCents),
            currency
          ).toFormat()}{" "}
        </Text>
        <Text fontSize="md" marginTop="0 !important" style={{ color }}>
          {/* {isNeg ? "-" : ""} */}
          {portfolioBalance.dailyPercentageFormatted}
        </Text>
      </VStack>
    </HStack>
  );
};

const CurrentValue = ({
  portfolioBalance,
  currency,
  isNeg,
}: {
  portfolioBalance: PortfolioBalanceV2;
  currency: CurrencyCodeEnum;
  isNeg: boolean;
}) => {
  const showUnpriced = false;
  const toast = useMyToast();
  const isLarge = useIsLargeScreen();
  const { header, text } = useTheme();

  const [reportUnpricedAsset] = useMutation(api.assets.reportUnpricedAsset);

  const _reportAsset = async () => {
    // try {
    //   await reportUnpricedAsset({
    //     variables: {
    //       assetId: portfolioBalance.asset.id,
    //       chain: portfolioBalance.asset.provider,
    //       contractAddress: portfolioBalance.asset.contractAddress,
    //     },
    //   });
    //   toast.show({
    //     status: "success",
    //     message: "Successfully reported asset!",
    //   });
    // } catch (err) {
    //   toast.show({
    //     status: "error",
    //     message: "Failed to report asset!",
    //   });
    // }
  };

  const color = isNeg ? colors.negative : colors.positive;
  const tokenName = getGenericTokenName(
    {
      asset: { type: portfolioBalance.type || "" },
      amount: portfolioBalance.totalAmount,
    },
    true
  );

  const isNFT = false;
  // if less than 5, show with 6 decimals. Otherwise, show with 2
  const assetPriceCents = portfolioBalance.assetPriceCents;
  const decimals = assetPriceCents && assetPriceCents < 5 ? 8 : 2;
  const formattedPrice =
    "$" +
    numbro((assetPriceCents || 0) / 100).format({
      thousandSeparated: true,
      mantissa: decimals,
    });

  return (
    <Box>
      <Text
        fontSize="md"
        isNumeric
        style={{
          fontWeight: "500",
        }}
        marginBottom="5px"
        color={header}
      >
        {D(portfolioBalance.totalFiatAmountCents || 0, currency).toFormat()}
      </Text>
      {showUnpriced ? (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Text
              fontSize="md"
              marginTop={0}
              // fontStyle="italic"
              style={{ color: text }}
            >
              Unpriced <i className="fa-sharp fa-question" />
            </Text>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <Box
              style={{
                padding: "1rem",
              }}
            >
              <Text
                style={{
                  whiteSpace: "normal",
                  color: text,
                  fontWeight: "normal",
                }}
                fontSize="md"
              >
                This asset doesn't have a price. Think this is a mistake?{" "}
                <a
                  onClick={_reportAsset}
                  style={{
                    display: "inline",
                    fontWeight: "bold",
                    color: colors.primary,
                  }}
                >
                  Click to report
                </a>{" "}
                and we'll look into it and add pricing data if we find it!
              </Text>
            </Box>
          </PopoverContent>
        </Popover>
      ) : (
        <Text
          fontSize="sm"
          marginTop={0}
          isTruncated
          // fontStyle="italic"
          style={{ color: text }}
        >
          {formattedPrice}/
          {isNFT
            ? singular(tokenName)
            : portfolioBalance?.symbol?.toUpperCase() || singular(tokenName)}
        </Text>
      )}
    </Box>
  );
};
