import { Box, Container, Text } from "@chakra-ui/react";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useMe, useMyToast } from "src/hooks";
import { auth } from "src/utils/firebase";
import {
  MultiFactorError,
  getMultiFactorResolver,
  multiFactor,
  MultiFactorSession,
  MultiFactorInfo,
  TotpMultiFactorGenerator,
  TotpSecret,
} from "firebase/auth";
import { Button, Copy, Input } from "src/components/styled";
import { colors } from "src/theme";
import { FirebaseError } from "firebase/app";
import { useTheme } from "src/hooks/useTheme";
import QRCode from "react-qr-code";

type Props = InjectedProps & {
  flowType: "sign_in" | "enroll";
  multiFactorError?: MultiFactorError;
  session: MultiFactorSession;
  multiFactorHint: MultiFactorInfo;
  onSuccess: () => Promise<void>;
};

function _TOTPModal(props: Props) {
  const {
    handleHide,
    show: isVisible,
    flowType,
    multiFactorError,
    session: _session,
    multiFactorHint,
  } = props;

  const { me } = useMe();
  const [verificationCode, setVerificationCode] = useState("");
  const [totpSecret, setTotpSecret] = useState<TotpSecret | null>(null);

  const toast = useMyToast();

  const totpUri = useMemo(() => {
    if (!totpSecret) return null;
    if (!me) return null;
    const url = totpSecret.generateQrCodeUrl(me.email, `awaken.tax`);
    return url + "&image=https://assets.awaken.tax/logos/awaken.png";
  }, [totpSecret]);

  const _onSubmit = async function () {
    try {
      await _verifyCode();

      handleHide();

      // Note: the on success is responsible
      // for closing the modal. this is bc the on success may have a navigate()
      // and a navigate will need to close the modal before progressing
      if (props.onSuccess) {
        return await props.onSuccess();
      }

      return;
    } catch (err) {
      console.log("==== error =====");
      console.log(err);
    }
  };

  useEffect(() => {
    if (flowType === "enroll") {
      _setQRCode();
    }
  }, [multiFactorHint]);

  const _setQRCode = async () => {
    try {
      const user = auth.currentUser;

      const session =
        _session || (user ? await multiFactor(user).getSession() : null);

      if (!session) {
        console.log("No session");
        toast.show({
          message:
            "Session is missing. Please refresh and try again, and if the problem persists, contact support.",
          status: "error",
        });
        return;
      }

      const totpSecret = await TotpMultiFactorGenerator.generateSecret(session);

      setTotpSecret(totpSecret);
    } catch (err) {
      console.log(err);

      if (err instanceof FirebaseError) {
        if (err.code === "auth/too-many-requests") {
          toast.show({
            message: "Too many requests. Please try again later.",
            status: "error",
          });
          return;
        }
      }

      toast.show({
        message: (err as any)?.message || "Invalid request.",
        status: "error",
      });
    }
  };

  const _verifyCode = async () => {
    try {
      const user = auth.currentUser;

      if (!verificationCode) {
        toast.show({
          message: "Please enter your verification code.",
          status: "error",
        });
        return;
      }

      if (flowType === "enroll") {
        if (!totpSecret) return;

        if (!user) {
          toast.show({
            message:
              "User is missing. Please refresh and try again, and if the problem persists, contact support.",
            status: "error",
          });
          return;
        }

        // Ask user for the verification code. Then:
        const multiFactorAssertion =
          TotpMultiFactorGenerator.assertionForEnrollment(
            totpSecret,
            verificationCode
          );

        await multiFactor(user).enroll(multiFactorAssertion, `TOTP`);

        console.log("[enrolled 2 factor]");

        return;
      }

      if (flowType === "sign_in" && multiFactorError) {
        const multiFactorResolver = getMultiFactorResolver(
          auth,
          multiFactorError
        );

        const multiFactorAssertion =
          TotpMultiFactorGenerator.assertionForSignIn(
            multiFactorResolver.hints[0].uid,
            verificationCode
          );

        await multiFactorResolver.resolveSignIn(multiFactorAssertion);

        console.log("[signed in 2 factor]");

        return;
      }

      console.log("unhandled: ", flowType);
    } catch (err) {
      console.log(err);

      if (err instanceof FirebaseError) {
        console.log(err.message);
        console.log(err.code);
        if (err.code === "auth/code-expired") {
          toast.show({
            message: "Code expired. Please try again.",
            status: "error",
          });
          return;
        }
      }

      toast.show({
        message: (err as any)?.message || "Invalid verification code.",
        status: "error",
      });
      throw err;
    }
  };

  const theme = useTheme();

  return (
    <Modal
      isVisible={isVisible}
      handleHide={handleHide}
      minH="35vh"
      Footer={
        <Button
          width="100%"
          variant="primary"
          onClick={_onSubmit}
          style={{ marginBottom: "2rem" }}
        >
          Verify Code
        </Button>
      }
    >
      <Container
        padding="3rem 0 1rem 0"
        style={{
          alignItems: "center",
          textAlign: "center",
        }}
        marginTop="0px !important"
      >
        <Text
          color={theme.header}
          fontSize="lg"
          fontWeight="bold"
          marginBottom="0"
          style={{ textAlign: "left" }}
        >
          {flowType === "enroll"
            ? "Enroll"
            : flowType === "sign_in"
            ? "Sign in with"
            : ""}{" "}
          Two-Factor Authentication
        </Text>

        <br />

        {totpUri && (
          <QRCode
            style={{
              margin: "auto",
            }}
            value={totpUri}
          />
        )}

        {multiFactorHint ? (
          <Text
            style={{
              marginTop: 10,
              marginBottom: 10,
              textAlign: "left",
            }}
            color={theme.text}
          >
            Open up your TOTP app and paste the verification code below.
          </Text>
        ) : (
          <Box style={{ marginTop: 25 }}>
            <Text
              style={{
                marginBottom: 10,
              }}
              color={theme.header}
            >
              Open up your TOTP app and scan this QR code.
            </Text>

            <Text
              style={{
                color: theme.text,
              }}
            >
              - or -
            </Text>

            <Text>
              Copy and paste this code:
              <br />
              {totpSecret?.secretKey || ""}{" "}
              <Copy
                containerStyle={{ display: "inline" }}
                value={totpSecret?.secretKey || ""}
              />
            </Text>
          </Box>
        )}

        <br />

        <Input
          value={verificationCode}
          autoFocus
          onChange={(e) => setVerificationCode(e.target.value)}
          // label="Client Full Name"
          placeholder="Enter your verification code"
        />
      </Container>
    </Modal>
  );
}

export const TOTPModal = connectModal({
  name: "TOTPModal",
})(_TOTPModal);
