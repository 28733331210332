import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps, show } from "redux-modal";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import { useParams } from "react-router-dom";
import { useMyToast } from "src/hooks";
import {
  Mutation,
  MutationStartCheckoutArgs,
  Query,
  StartSubscriptionCheckoutLineItem,
  StartSubscriptionCheckoutResponse,
  StartUpgradeCheckoutResponse,
} from "src/api/generated/types";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { stripePromise } from "src/utils/stripe";
import { Box, Divider, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { last } from "radash";
import { colors } from "src/theme";
import { Button } from "src/components/styled";
import { D } from "src/utils/helpers";
import { useDispatch } from "react-redux";
import { setShowConfetti } from "src/redux/reducers/globalState";

type Props = InjectedProps;

function _UpgradeInfoModal({ handleHide, show: isVisible }: Props) {
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();
  const dispatch = useDispatch();

  const _onUpgradeCheckout = () => {
    dispatch(
      show("PurchaseUpgradeModal", {
        onPaymentSuccess: () => {
          setTimeout(() => dispatch(setShowConfetti(true)), 500);
        },
      })
    );

    handleHide();
  };

  return (
    <Modal isVisible={isVisible} handleHide={handleHide} w="100%" maxW="40rem">
      <Box style={{ padding: "1.5rem 0" }}>
        <Features />

        <Button
          style={{
            width: "100%",
            margin: 0,
            marginTop: "2rem",
          }}
          variant="primary"
          onClick={_onUpgradeCheckout}
        >
          Go to checkout{" "}
          <i style={{ marginLeft: 10 }} className="fa-sharp fa-arrow-right" />
        </Button>
      </Box>
    </Modal>
  );
}

const LoadingBox = () => {
  return (
    <div
      style={{
        minHeight: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text fontSize="md" style={{ alignItems: "center", display: "flex" }}>
        Loading checkout <Spinner style={{ marginLeft: "1rem" }} size="sm" />
      </Text>
    </div>
  );
};

const Features = () => {
  // We'll answer any questions you have on how to use the software, what to label specific transactions, figure out problems with your CSVs, and anything else you need. You'll get responses from a real person (no AI bots).

  const features = [
    {
      iconName: "fa-computer",
      title: "Software help",
      description:
        "We'll help you with any questions you have about how to use Awaken.",
    },
    {
      iconName: "fa-tags",
      title: "Labeling",
      description:
        "If you aren't sure which label applies to a specific transaction, we'll help point you in the right direction.",
    },
    {
      iconName: "fa-database",
      title: "Data help (ex. CSVs)",
      description:
        "If you need help with your CSVs, we'll help you figure out what's wrong and give you advice on how to fix it.",
    },
    {
      iconName: "fa-user-tie",
      title: "Connect to a CPA",
      description:
        "If you need more specific advice, we can help connect you to a top crypto CPA. You will have to pay them separately, and they run at ~$200 an hour.",
    },
  ];

  return (
    <div
      style={{
        paddingTop: 0,
      }}
    >
      <Text textAlign="center" fontWeight="semibold" fontSize="md">
        Unlimited Customer Service{" "}
        <i
          style={{ color: colors.yellow50 }}
          className="fa-sharp fa-sparkles"
        />
      </Text>

      <br />

      {features.map((feature, i) => (
        <div key={i} style={{ marginBottom: "0.5rem" }}>
          <HStack alignItems="flex-start">
            <i
              className={`fa-sharp ${feature.iconName}`}
              style={{
                fontSize: 16,
                position: "relative",
                top: 7,
                marginRight: "0.5rem",
              }}
            />
            <VStack alignItems="flex-start" padding="0.25rem 0">
              <div style={{ flex: 1, textAlign: "left" }}>
                <Text color={colors.black} fontSize="sm" fontWeight="semibold">
                  {feature.title}
                </Text>
              </div>
              <div style={{ flex: 1, textAlign: "left" }}>
                <Text marginTop="0" fontSize="sm">
                  {feature.description}
                </Text>
              </div>
            </VStack>
          </HStack>
        </div>
      ))}

      <br />

      <Box
        style={{
          borderRadius: 7,
          backgroundColor: colors.red100,
          padding: "0.5rem",
          border: `1px solid ${colors.red80}`,
        }}
      >
        <Text fontSize="sm" style={{ color: colors.black }}>
          We will help you with the above, but Awaken is not a tax advisor. None
          of what we say is tax advice, and you are responsible for reporting
          correct information to the IRS.
        </Text>
      </Box>
    </div>
  );
};

const CheckoutSummary = ({ amount }: { amount: number }) => {
  return (
    <>
      <div
        style={{
          padding: "1rem",
          marginBottom: "2rem",
          backgroundColor: colors.gray100,
          borderRadius: 10,
          border: `1px solid ${colors.gray70}`,
        }}
      >
        <HStack padding="0.25rem 0">
          <div style={{ flex: 1, textAlign: "left" }}>
            <Text
              color={amount >= 0 ? colors.black : colors.black}
              fontSize="sm"
              fontWeigh="500"
            >
              Total
            </Text>
          </div>
          <div style={{ flex: 1, textAlign: "right" }}>
            <Text
              fontSize="sm"
              fontWeight="bold"
              color={amount > 0 ? colors.black : colors.green50}
            >
              {amount === 0 ? "FREE" : D(amount).toFormat()}
            </Text>
          </div>
        </HStack>
      </div>
    </>
  );
};

export const UpgradeInfoModal = connectModal({
  name: "UpgradeInfoModal",
})(_UpgradeInfoModal);
