import BigNumber from "bignumber.js";
import { Currency } from "dinero.js";
import { isNil } from "lodash/fp";
import { useMemo } from "react";
import {
  BaseAssetFields,
  BaseFullTransactionFields,
  BaseRawTransactionAssetFields,
  PartialAssetFields,
} from "src/api/fragments";
import { AssetTypeEnum } from "src/api/generated/types";
import { DEFAULT_DECIMALS } from "src/config";
import { Maybe } from "src/core";
import { colors } from "src/theme";
import { toLuxonUTC } from "src/utils";
import { D } from "src/utils/helpers";
import { TransferType } from "./Transfers/TransferForm/utils";

export const useCreatedAtUTC = (
  transaction: Maybe<Pick<BaseFullTransactionFields, "createdAt">>,
  timezone = "UTC"
) => {
  return useMemo(
    () =>
      transaction?.createdAt
        ? toLuxonUTC(new Date(transaction?.createdAt)).setZone(timezone)
        : undefined,
    [transaction]
  );
};

export const getColor = (isInternal: boolean, type: Maybe<TransferType>) => {
  if (isInternal) {
    return {
      bg: colors.gray100,
      color: colors.gray20,
      borderColor: colors.gray80,
    };
  }
  return type === "sent"
    ? {
        bg: colors.red100,
        color: colors.red20,
        borderColor: colors.red90,
      }
    : {
        bg: colors.green100,
        color: colors.green20,
        borderColor: colors.green90,
      };
};

// if you pass the asset it appends the symbol to the name in some cases
export const formatCryptoAmount = (
  amount?: Maybe<number>,
  asset?: Maybe<PartialAssetFields>
) => {
  if (isNil(amount)) {
    return amount || "";
  }

  if (asset?.type === AssetTypeEnum.FiatCurrency) {
    const symbol = (asset.symbol || "USD") as Currency;
    // this isn't in cents so have to convert it
    return D(amount * 100, symbol).toFormat();
  }

  return new BigNumber(amount).dp(DEFAULT_DECIMALS).toFormat();
};

export const getTransformedRawAsset = (
  rawAsset?: Maybe<BaseRawTransactionAssetFields>
): Maybe<
  Pick<
    BaseAssetFields,
    "type" | "id" | "iconImageUrl" | "imageUrl" | "symbol" | "tokenId"
  >
> =>
  rawAsset
    ? {
        iconImageUrl: rawAsset.imageUri,
        imageUrl: rawAsset.imageUri,
        symbol: rawAsset.symbol,
        type: rawAsset.category as AssetTypeEnum,
        id: rawAsset.identifier,
        tokenId: rawAsset.tokenId,
      }
    : null;
