import { Button } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { Input } from "src/components/styled/Form/Input";
import SecondaryText from "src/components/styled/SecondaryText";
import { useMyToast } from "src/hooks";
import { useMutation } from "@apollo/client";
import {
  BaseClientPermissionFields,
  BaseClientFields,
} from "src/api/fragments";
import { api } from "src/api";
import { useParams } from "react-router-dom";
import { isWhiteLabeledDomain } from "src/utils/whitelabel";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps;

function _OrgMemberModal({ handleHide, show: isVisible }: Props) {
  const toast = useMyToast();
  const [email, setEmail] = useState("");
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;
  const theme = useTheme();

  const [addClientMember, { loading }] = useMutation<
    {
      addClientMember: BaseClientPermissionFields;
    },
    { clientId: string; email: string }
  >(api.clientMembers.create);

  const _inviteMemberToOrganization = async () => {
    try {
      if (!email) {
        return;
      }

      const isWhiteLabel = isWhiteLabeledDomain();

      await addClientMember({
        variables: {
          clientId: clientId || "",
          email,
          ...(isWhiteLabel
            ? {
                // current url with the https://
                whiteLabelUrl: window.location.origin,
              }
            : {}),
        },
        refetchQueries: [api.clientMembers.list],
      });

      toast.show({
        message: `${email} can now access this client`,
        status: "success",
      });

      handleHide();
    } catch (err) {
      toast.show({
        message: (err as any)?.message || "Sorry, an error occurred.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title="Invite a member"
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          onClick={_inviteMemberToOrganization}
          isLoading={loading}
          width="100%"
          variant="primary"
        >
          Share
        </Button>
      }
    >
      <SecondaryText text="You can share this client with someone, so that they can view and edit data too." />
      <br />
      <Input
        value={email}
        label="Email"
        onChange={(e) => setEmail(e.target.value)}
        placeholder="alice@gmail.com"
      />
    </Modal>
  );
}

export const OrgMemberModal = connectModal({
  name: "OrgMemberModal",
})(_OrgMemberModal);
