import {
  Text,
  Heading,
  Box,
  HStack,
  Flex,
  VStack,
  Button,
} from "@chakra-ui/react";
import WhiteBox from "src/components/styled/WhiteBox";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { useNavigate, useParams } from "react-router-dom";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { api } from "src/api";
import { colors, other } from "src/theme";
import Loading from "src/views/Loading";
import { useEffect, useMemo } from "react";
import { Query } from "src/api/generated/types";
import { Transactions } from "./Transactions";
import _ from "lodash";
import { NetWorth } from "./Networth";
import Assets from "./Assets/Assets";
import { Warning } from "src/components/Warning";
import { compose } from "lodash/fp";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { RecalculateButton } from "src/components";
import { getPortfolioLink } from "src/modules/ledger/transactions";

function Harvest() {
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);

  const { accounts, isLoadingAccounts, client } = useClientById(clientId, {
    // poll accounts every 5 seconds for updates
    pollAccountsMs: 5000,
    clientFetchPolicy: "cache-first",
  });

  const { data: meData, refetch: refetchMe } = useQuery<Pick<Query, "me">>(
    api.users.me,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => void refetchMe(), []);

  const showAccountsWarning = !isLoadingAccounts && !accounts.length;
  const isLarge = useIsLargeScreen();
  const hasAccounts = !isLoadingAccounts && accounts.length > 0;

  return (
    <Box padding="1.5rem 0">
      <div style={{ width: isLarge ? "100%" : "100%", margin: "auto" }}>
        {hasAccounts && client?.shouldRecalculate && (
          <Warning
            style={{ marginBottom: "2rem", padding: "0.5rem 0.75rem" }}
            message={
              <HStack>
                <Text fontSize="sm" flex={1}>
                  Your portfolio may be out of date due to recent transactions.
                  Click to recalculate.
                </Text>
                <RecalculateButton
                  buttonProps={{
                    backgroundColor: colors.black,
                    _hover: {
                      backgroundColor: colors.gray20,
                    },
                  }}
                  textProps={{
                    color: colors.white,
                    // _hover: {
                    //   color: colors.white,
                    // },
                  }}
                  iconStyle={{
                    color: colors.white,
                  }}
                />
              </HStack>
            }
            iconStyle={{
              marginRight: "0.25rem",
            }}
          />
        )}
        <Warning
          style={{ marginBottom: "2rem", padding: "1rem 0.75rem" }}
          message={
            <>
              We are aware of the occasional inaccuracies in this tool. For a
              perfectly accurate real-time portfolio, check out the{" "}
              <Link
                to={getPortfolioLink(clientId || "")}
                style={{
                  color: colors.blueLink,
                  cursor: "pointer",
                }}
              >
                <u>
                  <strong>portfolio</strong>
                </u>
              </Link>{" "}
              page.
            </>
          }
        />
        <NetWorth
          showAccountsWarning={showAccountsWarning}
          clientId={clientId!}
        />
      </div>
    </Box>
  );
}

export default Harvest;
