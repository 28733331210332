import { BaseUserFields } from "src/api/fragments";
import { failure, success, UnexpectedError } from "src/core";

export const trackEvent = (event: string, properties?: any) => {
  try {
    const mixpanel = (window as any)?.mixpanel;

    mixpanel.track(event, properties);

    // analytics.track(event, properties);
    return success(null);
  } catch (err) {
    return failure(new UnexpectedError(err));
  }
};

export const identify = (me: BaseUserFields) => {
  try {
    const mixpanel = (window as any)?.mixpanel;

    if (!me) return;

    mixpanel?.identify(me.id);

    mixpanel?.people?.set({
      $name: me.name,
      $email: me.email,
    });

    // window.analytics.identify(
    //   me.id,
    //   {
    //     email: me.email,
    //     name: me.name,
    //   },
    //   {
    //     integrations: {
    //       Intercom: {
    //         hideDefaultLauncher: true,
    //         hide_default_launcher: true,
    //         user_hash: me.intercomUserHash ?? null,
    //       },
    //     },
    //   }
    // );
  } catch (err) {
    console.log(err);
  }
};
