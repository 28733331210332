import { DateTime } from "luxon";
import moment from "moment";
import {
  Transaction,
  Maybe,
  LedgerTransactionReviewStatusEnum,
} from "src/api/generated/types";
import { DEFAULT_PAGE_SIZE } from "src/config";

export const formatRuleUsed = (ruleType: string) => {
  let statusTagText = "";
  if (ruleType === "v1:unstaking_with_rewards") statusTagText = "UNSTAKING";
  else if (ruleType === "v1:withdraw") statusTagText = "UNLEND";
  else if (ruleType === "v1:withdraw_swap") statusTagText = "UNLEND SWAP";
  else {
    statusTagText = ruleType
      .split(":")
      .slice(1)
      .join("_")
      .split("_")
      .map((part) => part.trim())
      .filter((part) => !!part && isNaN(Number(part)))
      .join(" ")
      .trim()
      .toUpperCase();
  }
  return statusTagText;
};

export const formatAutoReviewReason = (autoReviewReason?: Maybe<string>) => {
  if (autoReviewReason?.startsWith("v1:default_rule:")) {
    return formatRuleUsed(autoReviewReason.split("v1:default_rule:")[1]);
  }

  const reason = (autoReviewReason || "").split(":");
  // get rid of the version (0th item), and get the actual reason (1st item and beyond)
  const reasonType = reason.slice(1);
  const statusTagText = reasonType
    .join("_")
    .split("_")
    .join(" ")
    .trim()
    .toUpperCase()
    .replace("INTERNAL TRANSFER", "WALLET TRANSFER");
  return statusTagText;
};

export const isValidEVMTxn = (address: string): boolean => {
  // Ethereum address regex pattern
  const evmAddressPattern = /^(0x)?[0-9a-fA-F]{64}$/;

  // Check if the address matches the pattern
  return evmAddressPattern.test(address);
};

export const isReviewed = (
  transaction: Maybe<Pick<Transaction, "needsReview">>
): boolean => transaction?.needsReview === null;

export const isLabeled = (
  transaction: Maybe<
    Pick<Transaction, "labelUsed" | "ruleUsedId" | "autoReviewReason">
  >
): boolean =>
  !!(
    transaction?.labelUsed ||
    transaction?.ruleUsedId ||
    transaction?.autoReviewReason
  ) || false;

type URLOptions = {
  page?: string | number;
  assetSymbolOrName?: string;
  assetIds?: string[];
  transactionId?: string;
  highlightTransactionId?: string;
  includeSpam?: boolean;
  search?: string;
  providers?: string[];
  startDate?: Date;
  endDate?: Date;
};

export const getBaseLink = (clientId: string) => `/clients/${clientId}`;

export const getAccountsLink = (clientId: string) =>
  `/clients/${clientId}/accounts`;

export const getPortfolioLink = (clientId: string) =>
  `/clients/${clientId}/portfolio`;

export const getHarvestLink = (clientId: string) =>
  `/clients/${clientId}/harvest`;

export const getLink = (
  clientId: string,
  options?: URLOptions,
  withOrigin?: boolean
) => {
  const optionsArr: string[] = [];
  if (options?.search !== undefined)
    optionsArr.push(`search=${encodeURIComponent(options.search)}`);
  if (options?.page !== undefined) optionsArr.push(`page=${options.page}`); // watch out for page=0 case)
  if (options?.assetSymbolOrName !== undefined)
    optionsArr.push(`assetSymbolOrName=${options.assetSymbolOrName}`); // watch out for & sign
  if (options?.assetIds !== undefined) {
    optionsArr.push(`assetIds=${options.assetIds.join(",")}`); // watch out for & sign
    optionsArr.push(`includeSpam=true`);
  }
  if (options?.transactionId !== undefined)
    optionsArr.push(`transactionId=${options.transactionId}`); // watch out for & sign
  if (options?.highlightTransactionId !== undefined)
    optionsArr.push(`highlightTransactionId=${options.highlightTransactionId}`); // watch out for & sign

  if (options?.includeSpam !== undefined)
    optionsArr.push(`includeSpam=${options.includeSpam}`); // watch out for & sign
  if (options?.providers !== undefined)
    optionsArr.push(`providers=${options.providers.join(",")}`); // watch out for & sign
  if (options?.startDate !== undefined)
    optionsArr.push(
      `startDate=${DateTime.fromJSDate(new Date(options.startDate)).toISO()}`
    );
  if (options?.endDate !== undefined)
    optionsArr.push(
      `endDate=${DateTime.fromJSDate(new Date(options.endDate)).toISO()}`
    );

  const optionsStr = optionsArr.join("&");

  return `${
    withOrigin ? window.location.origin : ""
  }/clients/${clientId}/transactions?${optionsStr}`;
};

export const getPriorityLink = (
  clientId: string,
  ascending: boolean,
  labeled?: string
) =>
  `/clients/${clientId}/transactions?ascending=${
    ascending ? "false" : ""
  }&limit=20&page=0&sortBy=capGainsSum&labeled=${labeled || "false"}`;

export const getLinkForSortByAxis = (
  clientId: string,
  ascending: boolean,
  sortAxis?: string // "capGainsSum", "incomeSum", none is date
) =>
  `/clients/${clientId}/transactions?ascending=${
    ascending ? "true" : ""
  }&limit=20&page=0&sortBy=${sortAxis}`;

export const getAccountLink = (clientId: string, accountId: string) =>
  `/clients/${clientId}/transactions?accountIds=${accountId}`;

export const getGainsLossesLink = ({
  clientId,
  reviewed,
  labeledPriority,
  startDate,
  endDate,
}: {
  clientId: string;
  reviewed?: boolean;
  labeledPriority?: number; // 1, 2, 3 for priority
  startDate?: Date;
  endDate?: Date;
}) => {
  let labeledParam = "";
  let labeledPriorityParam = "";
  let startDateParams = "";
  let endDateParams = "";

  if (reviewed === true) labeledParam = "&reviewed=true";
  else if (reviewed === false) labeledParam = "&reviewed=false";

  if (labeledPriority)
    labeledPriorityParam = `&labeledPriority=${labeledPriority}`;

  if (startDate)
    startDateParams = `&startDate=${moment(startDate).toISOString()}`;
  if (endDate) endDateParams = `&endDate=${moment(endDate).toISOString()}`;

  return `/clients/${clientId}/transactions?sortBy=capGainsSum&page=0&limit=20&ascending=false${
    labeledParam || ""
  }${startDateParams || ""}${endDateParams || ""}${labeledPriorityParam || ""}`;
};

export const getIncomeLink = (clientId: string, start?: Date, end?: Date) => {
  let base = `/clients/${clientId}/transactions?hasIncome=true&sortBy=incomeSum&ascending=false&page=0&limit=20`;
  if (start) {
    base += `&startDate=${moment(start).toISOString()}`;
  }

  if (end) {
    base += `&endDate=${moment(end).toISOString()}`;
  }

  return base;
};

export const getTransactionsLink = (clientId: string) =>
  `/clients/${clientId}/transactions`;

export const getAssetLinkFromSymbol = (clientId: string, symbol: string) =>
  `/clients/${clientId}/transactions?assetSymbolOrName=${encodeURIComponent(
    symbol
  )}`;

export const getAssetLinkFromId = (clientId: string, assetId: string) =>
  `/clients/${clientId}/transactions?assetIds=${encodeURIComponent(
    assetId
  )}&includeSpam=true`;

export const getAssetLinkFromIdDetailPage = (
  clientId: string,
  assetId: string
) => `/clients/${clientId}/assets/${encodeURIComponent(assetId)}`;
