import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Heading,
  HStack,
  Text,
  useTimeout,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "src/api";
import { colors } from "src/theme";

function HatchfiRedirect() {
  const navigate = useNavigate();

  // location storage variables
  const redirect = localStorage.getItem("v1:account_link:redirect_url");
  const integrationName = localStorage.getItem(
    "v1:account_link:integration_name"
  );

  useTimeout(async () => {
    if (redirect) {
      navigate(redirect);
    } else {
      navigate("/dashboard");
    }
  }, 1 * 1000);

  return (
    <HStack
      height="100vh"
      bg={colors.gray90}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        padding="2rem"
        bg={colors.white}
        borderRadius="1rem"
        textAlign="center"
      >
        <Heading size="md">Successfully linked {integrationName} 🎊!</Heading>
        <br />
        <Text margin="auto">
          We will redirect back to the dashboard in a few seconds...
          <br />
          or click below 👇
        </Text>
        <br />
        <Link to={redirect || "/dashboard"}>
          <Button variant="primary">Go back to Dashboard</Button>
        </Link>
      </Box>
    </HStack>
  );
}

export default HatchfiRedirect;
