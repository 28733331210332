import {
  Button,
  Text,
  UnorderedList,
  ListItem,
  ButtonGroup,
  VStack,
} from "@chakra-ui/react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { compose } from "lodash/fp";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { BaseFullTransactionFields } from "src/api/fragments";
import { Maybe } from "src/core";

type Props = InjectedProps & {
  regularSelect: () => void;
  type: "STAKING" | "UNSTAKING";
};

function _StakingExactWarningModal({
  handleHide,
  regularSelect,
  show: isVisible,
  type,
}: Props) {
  const stakingName = type === "STAKING" ? "Staking" : "Unstaking";
  const currentLabelName = `🥩 ${stakingName} ${
    type === "STAKING" ? "Deposit" : "Withdraw"
  }`;
  const correctLabelName = "🥩 " + stakingName + " Swap";

  return (
    <Modal
      marginTop="5rem"
      title={`⚠️ This looks wrong`}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <VStack w="100%">
          <Button
            variant="primary"
            style={{
              width: "100%",
            }}
            onClick={handleHide}
          >
            Label as {correctLabelName}
          </Button>
          <Button
            variant="secondary"
            style={{
              width: "100%",
            }}
            onClick={() => {
              regularSelect();
              handleHide();
            }}
          >
            Label as {currentLabelName}
          </Button>
        </VStack>
      }
    >
      <Text>
        You are about to label this transaction as a{" "}
        <strong>{currentLabelName}</strong>. However, you{" "}
        {type === "STAKING" ? "received" : "sent"} an equal number of staked
        tokens as you {type === "STAKING" ? "sent" : "received"}. Are you sure
        you it's not a <strong>{correctLabelName}</strong>?
      </Text>
    </Modal>
  );
}

export const StakingExactWarningModal = connectModal({
  name: "StakingExactWarningModal",
})(_StakingExactWarningModal);
