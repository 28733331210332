import { BoxProps, Tooltip } from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";

type Props = BoxProps & {
  message?: string;
  children?: JSX.Element | JSX.Element[];
} & any;

const AwakenTooltip = ({ message, children, ...boxProps }: Props) => {
  const theme = useTheme();

  return (
    <Tooltip
      zIndex={1401}
      trigger={isMobile ? "click" : "hover"}
      bg={colors.black}
      placement="top"
      openDelay={100}
      borderRadius="0.25rem"
      label={message}
      padding={{ base: "0.3rem 0.5rem" }}
      border={`1px solid ${colors.gray20}`}
      {...boxProps}
    >
      {children}
    </Tooltip>
  );
};

export { AwakenTooltip };
