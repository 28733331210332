import _ from "lodash";
import { useCallback } from "react";
import { FieldErrors } from "react-hook-form";
import { useMyToast } from "src/hooks";

export const useOnInvalidForm = () => {
  const toast = useMyToast();

  const onInvalidForm = useCallback(
    async (values: FieldErrors<any>) => {
      const keys = Object.keys(values);
      for (let i = 0; i < keys.length; i++) {
        const message = _.get(values, keys[i])?.message;
        if (message) {
          toast.show({
            message,
            status: "error",
          });
        } else {
          toast.show({
            message: "Invalid form state.",
            status: "error",
          });
        }
      }
    },
    [toast]
  );

  return onInvalidForm
}