import { Maybe } from "src/core";
import { colors } from "src/theme";

export type PriceThreshold = {
  min: Maybe<number>;
  max: Maybe<number>;
  price: Maybe<number>;
  emoji: string;
  planName?: string;
  labelName: string;
  gradient: string;
  numIcons: number;
  iconName: string;
  description?: string | JSX.Element;
};

export const THRESHOLDS: PriceThreshold[] = [
  // {
  //   min: null,
  //   max: 25,
  //   emoji: "🥚",
  //   price: 0,
  //   gradient: "linear-gradient(45deg, #632706 0%, #b34509 50%, #db4f02 100%)",
  //   numIcons: 1,
  //   iconName: "fa-sharp fa-coin-blank",
  // },
  {
    min: 0,
    max: 500,
    labelName: "Up to 500 transactions",
    description: (
      <>
        Like our{" "}
        <a
          style={{
            textDecoration: "underline",
            fontWeight: "bold",
            color: colors.primary,
          }}
          target="_blank"
          href="https://x.com/AwakenTax/status/1865839261871948249"
        >
          announcement post
        </a>
        ! Limited customer support.
      </>
    ),
    price: 1,
    emoji: "🐟",
    gradient: "linear-gradient(45deg, #A770EF 0%, #CF8BF3 50%, #fdb99b 100%)",
    numIcons: 1,
    iconName: "fa-sharp fa-coin-blank",
  },
  {
    min: 151,
    max: 1_500,
    labelName: "Up to 1,500 transactions",
    price: 199,
    emoji: "🐠",
    description: "Includes customer support.",
    gradient: "linear-gradient(45deg, #c71432 0%, #fd1d1d 50%, #fcb045 100%)",
    numIcons: 2,
    iconName: "fa-sharp fa-coin-blank",
  },
  {
    min: 1_501,
    max: 3_000,
    price: 299,
    labelName: "Up to 3,000 transactions",
    emoji: "🐬",
    description: "Includes customer support.",
    gradient: "linear-gradient(45deg, #1FA2FF 0%, #12D8FA 50%, #A6FFCB 100%)",
    numIcons: 1,
    iconName: "fa-sharp fa-coins",
  },
  {
    min: 3_001,
    max: 5_000,
    price: 399,
    labelName: "Up to 5,000 transactions",
    description: "Includes customer support.",
    emoji: "🐋",
    gradient: "linear-gradient(45deg, #75F09E 0%, #00CC44 50%, #75F09E 100%)",
    numIcons: 2,
    iconName: "fa-sharp fa-coins",
  },
  // {
  //   min: 5_001,
  //   max: 10_000,
  //   price: 600,
  //   emoji: "🔱",
  //   gradient: "linear-gradient(45deg, #FABC3C 0%, #FACC6B 50%, #F0AC6B 100%)",
  //   numIcons: 3,
  //   iconName: "fa-sharp fa-coins",
  // },
  {
    min: 5_001,
    max: 1_000_000,
    price: 549,
    emoji: "🐳",
    labelName: "Unlimited transactions",
    description: "Includes customer support.",
    // black gradient
    gradient: "linear-gradient(45deg, #000000 0%, #000000 50%, #000000 100%)",
    numIcons: 4,
    iconName: "",
  },
  {
    min: 1_000_001,
    max: 10_000_000,
    price: 999,
    labelName: "Unlimited transactions (w/ customer support from the founder)",
    emoji: "🔝",
    planName: "Founder Mode (Unlimited)",
    description: (
      <>
        Includes customer support from <b>our founder</b> directly.
      </>
    ),
    // bitcoin orange colors gradient
    gradient: "linear-gradient(45deg, #f7931a 0%, #ffac33 50%, #ffac33 100%)",
    numIcons: 4,
    iconName: "",
  },
];
