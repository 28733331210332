import { Checkbox, Container, HStack, Text, useToast } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import {
  AccountTypeEnum,
  ImportTypeEnum,
  MutationCreateAccountArgs,
} from "src/api/generated/types";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { Input } from "src/components/styled/Form/Input";
import { BaseAccountFields } from "src/api/fragments";
import { Maybe } from "src/core";
import { MyToast } from "src/components/MyToast";
import { Button, Info, Option, Select } from "src/components/styled";
import { useMyToast } from "src/hooks";
import { useParams } from "react-router-dom";
import { INTEGRATIONS } from "../AccountModal/constants";
import { IntegrationProviderInfo } from "../AccountModal/types";
import { GroupBase, OptionProps } from "react-select";
import { colors, other } from "src/theme";

type Props = InjectedProps & {
  clientId?: Maybe<string>;
  onSuccess: (acct?: BaseAccountFields) => void;
};

const options = INTEGRATIONS;

function _ManualAccountModal({
  handleHide,
  onSuccess,
  show: isVisible,
}: Props) {
  const toast = useToast();
  const { clientId } = useParams<{ clientId: string }>();
  const myToast = useMyToast();
  const [label, setLabel] = useState("");
  const [address, setAddress] = useState("");
  const [provider, setProvider] = useState<Option | null>(null);
  const [createAccount, { loading }] = useMutation(api.accounts.create);

  const _onAddWallet = useCallback(async () => {
    try {
      if (!label || !provider) {
        return toast({
          position: "top",
          render: () => (
            <MyToast
              message={"Missing fields, please fill out below 👇"}
              status="error"
            />
          ),
        });
      }

      const createAccountArgs: MutationCreateAccountArgs = {
        code: null,
        provider: provider?.value as string,
        type: AccountTypeEnum.None,
        redirectUrl: null,
        address: address,
        importType: ImportTypeEnum.Manual,
        label: label || null,
        clientId: clientId || null,
      };

      await createAccount({
        variables: createAccountArgs,
        refetchQueries: [api.clients.accounts, api.portfolio.get],
      });

      myToast.show({
        message: `Account Added ✅`,
        status: "success",
      });

      handleHide();

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      if (err instanceof Error) {
        toast({
          position: "top",
          render: () => (
            <MyToast message={(err as Error).message} status="error" />
          ),
        });
      }
    }
  }, [label, address, provider, clientId]);

  return (
    <Modal
      title={
        <Text fontSize="2xl">
          Add Manual Account{" "}
          <Info
            style={{ fontSize: 16 }}
            message="A manual account doesn't import transactions."
          />
        </Text>
      }
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          isLoading={loading}
          onClick={_onAddWallet}
        >
          Add Account
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Input
          value={label}
          isRequired
          onChange={(e) => setLabel(e.target.value)}
          label="Name"
          placeholder="Staking Wallet"
        />

        <Input
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          label="Address"
          infoMessage="This is the wallet's address if there is one. Ex. LooksRare address (for staking LooksRare)"
          placeholder="0x0000000..000"
        />

        {/* PROVIDER */}

        <Select
          label="Blockchain/Exchange"
          selectProps={{
            value: provider,
            onChange: (o) => setProvider(o as Option),
            isClearable: true,
            isLoading: false,
            components: {
              Option: CustomOption as any,
            },
          }}
          options={options.map((o) => ({
            label: o.name,
            value: o.provider,
            integration: o,
          }))}
          isRequired
        />
      </Container>
    </Modal>
  );
}

type DataT = Option & { integration: IntegrationProviderInfo };

const CustomOption: React.ComponentType<
  OptionProps<DataT, boolean, GroupBase<any>>
> = ({ innerRef, innerProps, data }) => (
  <div ref={innerRef} {...innerProps}>
    <HStack
      margin="0"
      bg={colors.white}
      cursor="pointer"
      padding="0.5rem 1rem"
      _hover={{
        bg: colors.gray90,
      }}
    >
      <Text marginRight="1rem !important" flex={1}>
        {data.integration?.name}
      </Text>
      <img
        style={{
          width: "2rem",
          height: "auto",
          borderRadius: "100%",
        }}
        src={data.integration?.logoUrl || ""}
        alt={data.integration?.name}
      />
    </HStack>
  </div>
);

export const ManualAccountModal = connectModal({
  name: "ManualAccountModal",
})(_ManualAccountModal);
