import React, { useContext, useRef } from "react";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import {
  AssetTypeEnum,
  AssetWarning,
  CurrencyCodeEnum,
  DefiPositionDebt,
  DefiPositionPrincipal,
  DefiPositionReward,
  PortfolioDefiPosition,
  PortfolioResponse,
  PortfolioSortColumnEnum,
} from "src/api/generated/types";
import _, { debounce, isEmpty, isNil, keyBy, noop, truncate } from "lodash";
import { Maybe, hasValue } from "src/core";
import Helpers, { D } from "src/utils/helpers";
import { useMe } from "src/hooks";
import numbro from "numbro";
import { BaseClientFields } from "src/api/fragments";
import { colors } from "src/theme";
import { Button } from "src/components";
import { AssetIcon } from "src/components/styled/Assets";
import { Text } from "@chakra-ui/react";
import { useTheme } from "src/hooks/useTheme";

const DefiPositionRow = ({
  position,
  client,
}: {
  position: PortfolioDefiPosition;
  client: Maybe<BaseClientFields>;
}) => {
  const [submitFeedback] = useMutation(api.portfolio.submitFeedback);
  const {
    background,
    text,
    secondaryBackground,
    border,
    medBackground,
    header,
  } = useTheme();

  const _reportDefiPosition = useCallback(async () => {
    const message = [
      `Defi position report ❌\n`,
      `Protocol: ${position?.name} (${
        position.protocolContractAddress || "N/A"
      })`,
      `Client: ${client?.name} (${client?.id})`,
      `Info: ${JSON.stringify(position || {}, null, 2)}`,
    ].join("\n");

    await submitFeedback({
      variables: {
        message: message,
      },
    });
  }, [client, position]);

  const _report = () => {
    void _reportDefiPosition();

    alert("Report Submitted, thanks for your feedback!");
  };

  return (
    <div
      style={{
        backgroundColor: background,
      }}
    >
      <Button
        onClick={() => {
          // open up a website
        }}
        style={{
          flexDirection: "row",
          alignItems: "center",
          padding: "25px 15px",
          width: "100%",
          backgroundColor: medBackground,
          // borderBottomColor: colors.gray85,
          // borderBottomWidth: 1,
        }}
      >
        {position.logoUrl && (
          <img
            src={position.logoUrl}
            style={{
              objectFit: "cover",
              width: 24,
              height: 24,
              borderRadius: 100,
              marginRight: 0,
              backgroundColor: secondaryBackground,
              borderWidth: 1,
              borderColor: border,
            }}
          />
        )}

        <div
          style={{
            padding: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 0,
            flex: 1,
          }}
        >
          <h3
            style={{
              fontSize: 14,
              marginLeft: 5,
              color: text,
              textTransform: "uppercase",
            }}
          >
            {position.name} • {position.totalValue || "-"}
          </h3>
        </div>

        <Button
          onClick={_report}
          variant="unstyled"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3
            style={{
              fontSize: 14,
              color: text,
            }}
          >
            Report
          </h3>
          <i
            className="fa-sharp fa-flag"
            style={{ fontSize: 14, marginLeft: 10, color: text }}
          />
        </Button>
      </Button>

      <div
        style={{
          padding: 15,
          paddingLeft: 12,
        }}
      >
        {position.items.map((item) => {
          return (
            <div
              style={{
                // marginHorizontal: 15,
                margin: 0,
                borderRadius: 10,
                // backgroundColor: colors.gray95,
                // borderWidth: 1,
                // borderColor: border,
              }}
            >
              {item.principals.length > 0 && (
                <div>
                  {item.principals.map((item, i) => {
                    return (
                      <PrincipalRow
                        key={`${item.groupKey}:${item.assetKey}`}
                        item={item}
                      />
                    );
                  })}
                </div>
              )}

              {(item.debt || []).length > 0 && (
                <div>
                  {item.debt.map((re, i) => {
                    return (
                      <DebtRow
                        key={`${re.groupKey}:${re.assetKey}`}
                        item={re}
                      />
                    );
                  })}
                </div>
              )}

              {item.rewards.length > 0 && (
                <div>
                  {item.rewards.map((re, i) => {
                    return (
                      <RewardRow
                        key={`${re.groupKey}:${re.assetKey}`}
                        item={re}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* <div>
        <div>
          <Text
            style={{
              fontSize: IS_IPAD ? 20 : 14,
              marginLeft: 10,
              color: text,
            }}
          >
            {position.totalValue || "-"}
          </Text>
        </div>
      </div> */}
    </div>
  );
};

const PrincipalRow = ({ item: re }: { item: DefiPositionPrincipal }) => {
  const icon = _getIconForPosition(re);
  const { header } = useTheme();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "10px 0",
        marginBottom: 10,
        //   borderBottomWidth: isLastItem ? 0 : 1,
        //   borderBottomColor: colors.gray85,
      }}
    >
      <div
        style={{
          marginRight: 10,
          position: "relative",
        }}
      >
        <AssetIcon
          asset={{
            symbol: re.symbol,
            iconImageUrl: re.logoUrl,
            imageUrl: null,
            type: AssetTypeEnum.FungibleToken,
          }}
          size={35}
        />

        <div
          style={{
            position: "absolute",
            right: -10,
            bottom: -10,
            padding: 3,
            height: 24,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: 24,
            fontSize: 12,
            backgroundColor: colors.gray70,
            color: colors.gray30,
            borderWidth: 1,
            borderColor: colors.gray90,
            borderRadius: 100,
          }}
        >
          {icon}
        </div>
      </div>

      <div
        style={{
          flex: 1,
          marginLeft: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Text
          w="100%"
          fontSize="md"
          color={header}
          margin="0 !important"
          isTruncated
          fontWeight="semibold"
          _groupHover={{
            textDecor: "underline",
          }}
        >
          {numbro(re.amount).format("0,0.[00000]")} {re.symbol}
        </Text>
        <div style={{ marginTop: 5 }}>
          <Text
            fontSize="sm"
            style={{
              color: colors.gray30,
              fontWeight: "500",
            }}
          >
            {re.description || "-"}
          </Text>
        </div>
      </div>
      <div>
        <Text
          fontSize="md"
          style={{
            color: header,
            fontWeight: "500",
          }}
        >
          {!isNil(re.fiatAmountCents) ? D(re.fiatAmountCents).toFormat() : "-"}
        </Text>
      </div>
    </div>
  );
};

const RewardRow = ({ item: re }: { item: DefiPositionReward }) => {
  const { header } = useTheme();

  return (
    <div
      style={{
        marginBottom: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "10px 0",
        //   borderBottomWidth: isLastItem ? 0 : 1,
        //   borderColor: colors.gray85,
      }}
    >
      <div
        style={{
          marginRight: 10,
          position: "relative",
        }}
      >
        <AssetIcon
          asset={{
            symbol: re.symbol,
            iconImageUrl: re.logoUrl,
            imageUrl: re.logoUrl,
            type: AssetTypeEnum.FungibleToken,
          }}
          size={35}
        />

        <div
          style={{
            position: "absolute",
            right: -10,
            bottom: -10,
            padding: 3,
            height: 24,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: 24,
            fontSize: 12,
            backgroundColor: colors.positive,
            borderWidth: 1,
            borderColor: colors.white,
            color: colors.white,
            borderRadius: 100,
          }}
        >
          <i className="fa-sharp fa-sack-dollar" />
        </div>
      </div>

      <div style={{ flex: 1, marginLeft: 10 }}>
        <Text
          w="100%"
          fontSize="md"
          color={header}
          margin="0 !important"
          isTruncated
          fontWeight="semibold"
          _groupHover={{
            textDecor: "underline",
          }}
        >
          {numbro(re.amount).format("0,0.[00000]")} {re.symbol}
        </Text>
        <div
          style={{
            display: "flex",
            // paddingVertical: 3,
            marginTop: 5,
            // paddingHorizontal: 5,
            // borderRadius: 7,
            flexDirection: "row",
            alignItems: "center",
            alignSelf: "flex-start",
          }}
        >
          <Text
            fontSize="sm"
            style={{
              color: colors.positive,
              fontWeight: "500",
            }}
          >
            Rewards
          </Text>
        </div>
      </div>
      <div>
        <Text
          fontSize="md"
          style={{
            color: header,
            fontWeight: "500",
          }}
        >
          {!isNil(re.fiatAmountCents) ? D(re.fiatAmountCents).toFormat() : "-"}
        </Text>
      </div>
    </div>
  );
};

const DebtRow = ({ item: re }: { item: DefiPositionDebt }) => {
  const icon = _getIconForPosition(re);
  const { header } = useTheme();

  return (
    <div
      style={{
        marginBottom: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "10px 0",
      }}
    >
      <div
        style={{
          marginRight: 10,
          position: "relative",
        }}
      >
        <AssetIcon
          asset={{
            symbol: re.symbol,
            iconImageUrl: re.logoUrl,
            imageUrl: re.logoUrl,
            type: AssetTypeEnum.FungibleToken,
          }}
          size={35}
        />

        <div
          style={{
            position: "absolute",
            right: -10,
            bottom: -10,
            padding: 3,
            height: 24,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: 24,
            fontSize: 12,
            borderWidth: 1,
            backgroundColor: colors.gray70,
            color: colors.gray30,
            borderColor: colors.white,
            borderRadius: 100,
          }}
        >
          {icon}
        </div>
      </div>

      <div
        style={{
          flex: 1,
          marginLeft: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Text
          w="100%"
          fontSize="md"
          color={header}
          margin="0 !important"
          isTruncated
          fontWeight="semibold"
          _groupHover={{
            textDecor: "underline",
          }}
        >
          {numbro(re.amount).format("0,0.[00000]")} {re.symbol}
        </Text>
        <div style={{ marginTop: 5 }}>
          <Text
            fontSize="sm"
            style={{
              color: colors.gray30,
            }}
          >
            {re.description || "-"}
          </Text>
        </div>
      </div>
      <div>
        <Text
          fontSize="md"
          style={{
            color: header,
            fontWeight: "500",
          }}
        >
          {!isNil(re.fiatAmountCents) ? D(re.fiatAmountCents).toFormat() : "-"}
        </Text>
      </div>
    </div>
  );
};

const _getIconForPosition = (position: Pick<DefiPositionPrincipal, "type">) => {
  switch (position.type) {
    case "farming":
      return <i className="fa-sharp fa-wheat" />;
    case "staking":
      return <i className="fa-sharp fa-seedling" />;
    case "deposited":
      return <i className="fa-sharp fa-arrow-up" />;
    case "lending":
      return <i className="fa-sharp fa-handshake" />;
    case "locked":
      return <i className="fa-sharp fa-lock" />;
    case "liquidity_providing":
      return <i className="fa-sharp fa-droplet" />;
    case "borrowed":
      return <i className="fa-sharp fa-arrow-down" />;
    case "unknown":
    default:
      return <i className="fa-sharp fa-question" />;
  }
};

export default DefiPositionRow;
