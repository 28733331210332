import { useQuery } from "@apollo/client";
import { HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { isNil } from "lodash";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import { Query } from "src/api/generated/types";
import { AwakenTooltip, Button } from "src/components";
import AnimatedNumber from "src/components/styled/AnimatedNumber";
import { APP_STORE_LINK } from "src/config";
import { useMe, useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { trackEvent } from "src/utils/analytics";
import { D } from "src/utils/helpers";
import { PortfolioContext } from "../context";

export const Summary = React.memo(() => {
  const { clientId } = useParams<{ clientId: string }>();
  const { me } = useMe();
  const {
    totalBalanceCents,
    isLoadingCoins: _isLoadingCoins,
    isLoadingNFTs,
    isFresh,
  } = useContext(PortfolioContext);
  const totalValue = totalBalanceCents ?? 0;
  const hasTotalValue = !isNil(totalValue);
  const toast = useMyToast();
  const isLarge = useIsLargeScreen();
  const {
    theme,
    secondaryBackground,
    medBackground,
    border,
    text,
    header,
    background,
  } = useTheme();

  const _onClick = async () => {
    try {
      trackEvent("Portfolio Early Access Requested", {
        clientId,
        page: "portfolio_v2",
      });

      window.open(APP_STORE_LINK, "_blank");

      // await requestBeta();

      // toast.show({
      //   status: "success",
      //   message:
      //     "Successfully submitted early access request! You should have an email in your inbox soon.",
      // });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const loading = _isLoadingCoins || !isFresh;

  return (
    <>
      <div
        style={{
          marginBottom: 0,
          flexDirection: "row",
          display: "flex",
          width: "100%",
          alignItems: "center",
          background: "transparent",
        }}
      >
        <div
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            {/* <Text
            style={{
              fontSize: 24,
              color: colors.black,
              marginTop: 5,
              marginBottom: 10,
            }}
          >
            Portfolio
          </Text> */}
            {!hasTotalValue ? (
              <div
                style={{
                  height: 130,
                }}
              />
            ) : (
              <div
                style={{
                  minHeight: 130,
                  paddingTop: 15,
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {/* <FreeCreditBadge /> */}

                {!isFresh && (
                  <HStack
                    style={{
                      position: "absolute",
                      top: -15,
                      left: 0,
                      padding: "5px 10px",
                      borderRadius: 100,
                      background: medBackground,
                      border: `1px solid ${border}`,
                    }}
                  >
                    <Text
                      fontWeight={500}
                      fontSize="xs"
                      textStyle="italic"
                      color={text}
                    >
                      Data is stale, refreshing
                    </Text>
                    {loading && <Spinner color={text} size="xs" />}
                  </HStack>
                )}

                <VStack
                  style={{
                    alignItems: "flex-start",
                  }}
                >
                  <AnimatedNumber
                    style={{
                      color: header,
                      fontSize: 42,
                      fontFamily: "Mona Sans",
                      fontWeight: "800",
                      fontStretch: "extra-expanded",
                    }}
                    valueCents={totalValue ?? 0}
                  />
                </VStack>

                <OverallGainLoss />
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            textAlign: "right",
          }}
        >
          {/* <h3
          style={{
            fontFamily: "Mona Sans",
            fontSize: 16,
            color: colors.black,
            marginTop: 5,
            marginBottom: 10,
          }}
        >
          Want to check your portfolio on the go?
        </h3> */}

          {isLarge && (
            <>
              {!me?.hasTwoFactorAuth && (
                <AwakenTooltip
                  placement="top"
                  message="We strongly recommend you enable 2FA for your account to keep it secure."
                >
                  <Link to={`/clients/${clientId}/team`}>
                    <Button
                      variant="primary"
                      bgColor={colors.red50} // Use a more urgent color
                      color={colors.white} // Ensure text is readable
                      style={{
                        fontWeight: "700",
                        marginBottom: 0,
                        border: `2px solid ${colors.red70}`, // Add a prominent border
                        boxShadow: "0 0 10px rgba(255, 0, 0, 0.5)", // Add a shadow for emphasis
                        animation: "pulse 1.5s infinite", // Add a pulsing animation
                      }}
                      _hover={{
                        bgColor: colors.red60, // Slightly different hover color
                        color: colors.white,
                      }}
                    >
                      Enable 2FA{" "}
                      <i
                        style={{ marginLeft: 10 }}
                        className="fa-solid fa-shield-halved"
                      />
                    </Button>
                  </Link>
                </AwakenTooltip>
              )}

              {/* <Box
                style={{
                  backgroundColor: blueBg,
                  padding: 10,
                  maxWidth: 400,
                  border: "1px solid " + colors.primary,
                  width: "100%",
                  textAlign: "left",
                  marginTop: "1rem",
                  borderRadius: 10,
                  fontWeight: 600,
                  color: text,
                }}
              >
                <i
                  className="fa-sharp fa-lightbulb"
                  style={{ marginRight: 5, color: colors.primary }}
                />
                Tip: You can harvest tax losses to offset your gains by selling
                crypto assets that you have a loss on below{" "}
                <Info
                  style={{ color: text }}
                  message={`Note: you must harvest losses before ${CURRENT_TAX_YEAR} ends for them to offset your gains in ${CURRENT_TAX_YEAR}.`}
                />
              </Box> */}
            </>
          )}

          {isLarge && (
            <>
              {/* <Link to={`/clients/${clientId}/referrals`}>
              <Button
                variant="primary"
                bgColor={colors.positive}
                color={colors.white}
                style={{
                  fontWeight: "700",
                  border: `2px solid ${colors.positive}`,
                }}
                _hover={{
                  bgColor: colors.positive,
                  color: colors.white,
                }}
              >
                <i className="fa-sharp fa-share" style={{ marginRight: 10 }} /> Refer
                Friends
              </Button>
            </Link> */}
            </>
          )}
        </div>
      </div>

      {/* {!isLarge && (
        <Box
          style={{
            marginTop: 25,
            backgroundColor: blueBg,
            padding: 10,
            width: "100%",
            border: "1px solid " + colors.primary,
            textAlign: "left",
            borderRadius: 10,
            fontWeight: 600,
            color: text,
          }}
        >
          <i
            className="fa-sharp fa-lightbulb"
            style={{ marginRight: 5, color: colors.primary }}
          />
          Tip: You can harvest tax losses to offset your gains by selling crypto
          assets that you have a loss on below{" "}
          <Info
            style={{ color: text }}
            message={`Note: you must harvest losses before ${CURRENT_TAX_YEAR} ends for them to offset your gains in ${CURRENT_TAX_YEAR}.`}
          />
        </Box>
      )} */}
    </>
  );
});

export const FreeCreditBadge = () => {
  const { clientId } = useParams();
  const { theme, header } = useTheme();
  const { data, loading } = useQuery<Pick<Query, "getSubscriptions">>(
    api.subscriptions.list,
    {
      variables: { clientId },
      skip: !clientId,
    }
  );

  const subscriptions = data?.getSubscriptions ?? [];
  const showBadge = !!data && subscriptions.length === 0 && !loading;
  const dispatch = useDispatch();

  if (!showBadge) {
    return null;
  }

  return (
    <div
      onClick={() => dispatch(show("ReferralModal"))}
      style={{
        padding: "10px 20px",
        borderRadius: 100,
        marginBottom: 15,
        backgroundColor: theme === "light" ? colors.green100 : colors.green10,
        border: `1px solid ${colors.green50}`,
        display: "inline-block",
        color: colors.black,
        cursor: "pointer",
        fontStretch: "extra-expanded",
      }}
    >
      <Text fontSize="sm" color={header}>
        Want to earn <b>$10</b> of credit?
      </Text>
    </div>
  );
};

const OverallGainLoss = React.memo(() => {
  const { totalDailyFiatAmount, totalDailyPercentageFormatted } =
    useContext(PortfolioContext);

  const { text, theme } = useTheme();
  const dailyGainLoss = totalDailyFiatAmount ?? 0;
  const isNeg = dailyGainLoss < 0 ? true : false;
  const color = isNeg ? colors.negative : colors.positive;

  if (isNil(totalDailyFiatAmount)) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        background: "transparent",
      }}
    >
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          display: "flex",
          marginTop: 5,
          alignItems: "center",
        }}
      >
        {/*  */}
        <i
          className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
          style={{ color, marginRight: 5, fontSize: 14 }}
          color={color}
        />
        <h3
          style={{
            color,
            fontSize: 16,
            fontFamily: "Mona Sans",
            fontWeight: "700",
            marginRight: 5,
          }}
        >
          {D(Math.abs(dailyGainLoss)).toFormat()}
        </h3>
        <div
          style={{
            margin: "0 5px",
            borderRadius: 7,
          }}
        >
          <h6
            style={{
              color,
              fontSize: 16,
              fontFamily: "Mona Sans",
              fontWeight: "700",
            }}
          >
            ({totalDailyPercentageFormatted})
          </h6>
        </div>
        <h6
          style={{
            fontSize: 16,
            marginLeft: 5,
            fontFamily: "Mona Sans",
            fontWeight: "normal",
            color: text,
          }}
        >
          Today
        </h6>
      </div>
    </div>
  );
});
