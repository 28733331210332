import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { compose } from "lodash/fp";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useMe } from "src/hooks";
import { setUserAuthStateChanged } from "src/redux/reducers/user";
import { identify } from "src/utils/analytics";
import { auth } from "src/utils/firebase";

export const useAuth = () => {
  const dispatch = useDispatch();
  const [search] = useSearchParams();
  const _setAuthStatus = compose(dispatch, setUserAuthStateChanged);
  const { me } = useMe();

  useEffect(() => {
    _setAuthStatus("NOT_LOADED");

    (async () => {
      const token = search.get("authToken");

      if (token) {
        await signInWithCustomToken(auth, token);
        _setAuthStatus("LOGGED_IN");
      }

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          _setAuthStatus("LOGGED_IN");
        } else {
          _setAuthStatus("NOT_LOGGED_IN");
        }
      });
    })();
  }, []);

  useEffect(() => {
    if (me) {
      // don't pass traits. we do that backend with the me() query
      identify(me);

      // LogRocket.identify(me.id, {
      //   name: me.name || "",
      //   email: me.email,
      //   id: me.id || "",
      // });
    }
  }, [JSON.stringify(me)]);
};
