const ASSETS_ROOT_URL = "https://assets.awaken.tax";

export const getImageKitCDNUrl = (
  url: string,
  { width, height }: { width?: number; height?: number },
  params?: string
): string => {
  if (url.includes(ASSETS_ROOT_URL)) {
    const imageUrl = `https://ik.imagekit.io/awaken/${url.replace(
      ASSETS_ROOT_URL,
      ""
    )}?tr=${width ? "w-" + width : ""},${height ? "h-" + height : ""}${
      params || ""
    }`;

    return imageUrl;
  }

  return url;
};
