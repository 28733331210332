import { Box, Divider, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { colors } from "src/theme";
// import { TransactionFeeForm } from "./TransactionFeeForm";
import { useFormContext } from "react-hook-form";
import { FormValues } from "../form";
import React, { useContext } from "react";
import { ActiveTransactionContext } from "src/context";
import { Fee } from "./TransactionFee";
import { Info } from "src/components/styled";
import { useTheme } from "src/hooks/useTheme";

export const Fees = () => {
  const { transaction } = useContext(ActiveTransactionContext);
  const fees = transaction?.fees || [];
  // TODO: add an edit mode concept
  const editMode = false;

  // TODO: need to fix this in general because we hide the UI to even add a fee if there are none
  if (editMode) {
    return (
      <FeeSectionWrapper>
        <EditFees />
      </FeeSectionWrapper>
    );
  }

  if (!fees.length) {
    return null;
  }

  return (
    <FeeSectionWrapper>
      <React.Fragment>
        {fees.map((fee) => (
          <Fee key={fee.id} fee={fee} />
        ))}
      </React.Fragment>
    </FeeSectionWrapper>
  );
};

const EditFees = () => {
  const { watch } = useFormContext<FormValues>();
  const fees = watch("fees");

  return (
    <>
      {/* {fees.length ? (
        <HStack width="100%" padding="0" alignItems="start" overflowX="scroll">
          {fees.map((fee, index) => (
            <TransactionFeeForm fee={fee} index={index} key={fee.id} />
          ))}
        </HStack>
      ) : (
        <NoFees />
      )} */}
    </>
  );
};

const FeeSectionWrapper = ({ children }: { children: JSX.Element }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Divider
        style={{
          borderColor: theme.secondaryBackground,
        }}
      />
      <HStack width="100%" marginTop="1rem" padding="0" alignItems="start">
        <Flex flex={1} w="22rem">
          <VStack width="100%" alignItems="start">
            <HStack width="100%" marginTop="1rem">
              <Text
                fontWeight="bold"
                fontSize={12}
                color={colors.gray40}
                textTransform="uppercase"
              >
                Fees{" "}
                <Info
                  style={{
                    color: colors.gray40,
                  }}
                  message="Transaction/gas fees that you paid for as part of this transaction."
                />
              </Text>
            </HStack>
            {children}
          </VStack>
        </Flex>
      </HStack>
    </React.Fragment>
  );
};

const NoFees = () => (
  <Box padding="1rem" textAlign="center" width="100%">
    <Text>No fees in transaction...</Text>
  </Box>
);
