import { Button, HStack, Link, Spinner, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { CSSProperties, useCallback, useState } from "react";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { Info } from "../Info";

export type CommandProps = {
  label: string | JSX.Element;
  iconName?: string;
  hasDivider?: boolean;
  iconColor?: string;
  infoMessage?: string;
  color?: string;
  iconImageSrc?: string;
  iconImageStyle?: CSSProperties;
  component?: JSX.Element;
  link?: string;
  labelStyle?: CSSProperties;
  onClick?: (x?: any) => void | Promise<void>;
};

export const Command = ({
  label,
  iconName,
  iconColor,
  color,
  hasDivider,
  iconImageSrc,
  iconImageStyle,
  onClick,
  link,
  infoMessage,
  component,
  labelStyle,
}: CommandProps) => {
  const [isLoading, setLoading] = useState(false);
  const { background, secondaryBackground, header, border } = useTheme();

  const _onClick = useCallback(async () => {
    try {
      setLoading(true);
      if (onClick) await onClick();
    } finally {
      setLoading(false);
    }
  }, [onClick]);

  if (component) {
    return component;
  }

  const Component = (
    <CommandContainer
      onClick={(e) => {
        e.stopPropagation();
        _onClick();
      }}
      _hover={{
        backgroundColor: secondaryBackground,
      }}
      _active={{ bg: background }}
      bg={background}
      disabled={isLoading}
      textAlign="left"
      margin={hasDivider ? "0.25rem 0 0 0 !important" : undefined}
      borderTop={hasDivider ? "1px solid " + border : undefined}
    >
      <HStack padding="0 0.5rem" width="100%" alignItems="center">
        <div style={{ textAlign: "center" }}>
          {iconImageSrc ? (
            <img
              style={{
                width: 18,
                height: "auto",
                color: iconColor || colors.gray20,
                ...(iconImageStyle || {}),
              }}
              src={iconImageSrc}
            />
          ) : iconName ? (
            <i
              style={{
                width: 18,
                color: iconColor || header,
                fontSize: 14,
              }}
              className={iconName}
            />
          ) : null}
        </div>
        <Text
          margin={labelStyle?.margin || "0 0.75rem !important"}
          textAlign="left"
          flex={1}
          fontSize="sm"
          color={color || header}
          style={labelStyle}
        >
          {label}{" "}
          {infoMessage && (
            <Info
              style={{ color: color || header, fontSize: 14 }}
              message={infoMessage}
            />
          )}
        </Text>
        {isLoading && <Spinner size="sm" color={color || header} />}
      </HStack>
    </CommandContainer>
  );

  return link ? (
    <Link target="_blank" href={link}>
      {Component}
    </Link>
  ) : (
    Component
  );
};

const CommandContainer = styled(Button)`
  margin: 0;
  padding: 0.5rem;
  width: 100%;
  border-radius: 0 !important;
  background-color: inherit;
`;
