import { BaseUserFields } from "src/api/fragments";

export const getRedirectPath = (user?: BaseUserFields, path = "dashboard") => {
  const activeClientId = user?.activeClient?.id;

  if (activeClientId) {
    return `/clients/${activeClientId}/${path}`;
  }

  return "/dashboard";
};
