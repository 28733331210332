import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import React, { CSSProperties, useCallback, useMemo } from "react";
import { colors } from "src/theme";
import { Controller, Control } from "react-hook-form";
import { isArray, isEqual, isNil, omit } from "lodash";
import { Info } from "../Info";
import ReactSelect, {
  GroupBase,
  OptionsOrGroups,
  PropsValue,
  StylesConfig,
} from "react-select";
import makeAnimated from "react-select/animated";
import { isString } from "lodash/fp";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import AsyncCreatableSelect from "react-select/async-creatable";
import _ from "lodash";
import { Required } from "./Required";
import { useTheme } from "src/hooks/useTheme";

const animatedComponents = makeAnimated();

export type Option = { value: string | number; label: string };
// type Group = { value: string; label: string };

type MyInputProps = {
  label?: string;
  labelIconName?: string;
  name?: string;
  hidden?: boolean;
  infoMessage?: string;
  visible?: boolean;
  control?: Control<any, any>;
  containerStyle?: CSSProperties;
  width?: string;
  isRequired?: boolean;
  fetchOptions?: (
    input: string
  ) => Promise<OptionsOrGroups<any, GroupBase<any>>>;
  value?: PropsValue<Option>;
  placeholder?: string;
  options?: OptionsOrGroups<Option, any>;
  selectProps?: StateManagerProps;
  error?: string;
};

export const Select = (props: MyInputProps) => {
  const theme = useTheme();

  if (props.hidden) {
    return null;
  }

  if (!isNil(props.visible) && !props.visible) {
    return null;
  }

  const isAsync = !!props.fetchOptions;

  const styles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
    menu: (base) => ({
      ...base,
      backgroundColor: theme.background,
      borderColor: theme.border,
      borderWidth: 1,
    }),
    control: (base) => ({
      ...base,
      backgroundColor: theme.background,
      borderColor: theme.border,
      // border: "none",
    }),
    //
    placeholder: (base) => ({
      ...base,
      color: theme.text,
    }),
    input: (base) => ({
      ...base,
      color: theme.header,
    }),
    // color of the options
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? colors.white : theme.text,
      backgroundColor: state.isSelected ? colors.primary : theme.background,
      "&:hover": {
        backgroundColor: theme.blueBg, // Add this line
        color: theme.text, // Add this line if you want to change text color on hover
      },
      // selected option color
      "&:active": {
        backgroundColor: colors.primary,
        color: colors.white,
      },
    }),
    indicatorSeparator: (p) => ({ ...p, display: "none" }),
    singleValue: (p) => ({ ...p, color: theme.header }),
    container: (container) => ({
      ...container,
      backgroundColor: theme.background,
      borderRadius: "5px",
      border: "1px solid " + theme.border,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    multiValue: (base, state) => ({
      ...base,
      color: theme.text,
      width: "max-content",
      minWidth: "100%",
    }),
  };

  return (
    <Box style={{ marginBottom: "1rem", ...props.containerStyle }}>
      <FormControl isInvalid={!!props.error}>
        {props.label && (
          <FormLabel color={theme.header} fontSize={14} marginBottom="6px">
            {props.labelIconName && (
              <i style={{ marginRight: 5 }} className={props.labelIconName} />
            )}
            {props.label} {props.isRequired && <Required />}
            {props.infoMessage && <Info message={props.infoMessage} />}
          </FormLabel>
        )}

        {props.control && props.name ? (
          <Controller
            control={props.control}
            name={props.name}
            render={({ field }) => {
              const { value, onChange, onBlur, ref } = field;
              if (isAsync) {
                return (
                  <AsyncCreatableSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={props.fetchOptions}
                    value={value}
                    onChange={onChange}
                    isClearable
                    styles={{
                      ...styles,
                      ...props.selectProps?.styles,
                    }}
                    {...omit(props.selectProps, "styles")}
                  />
                );
              }
              return (
                <ReactSelect
                  placeholder={props.placeholder}
                  components={animatedComponents}
                  onChange={onChange}
                  onBlur={onBlur as any}
                  menuPosition="fixed"
                  value={value}
                  options={props.options}
                  ref={ref}
                  styles={{
                    ...styles,
                    ...props.selectProps?.styles,
                  }}
                  {...omit(props.selectProps, "styles")}
                />
              );
            }}
          />
        ) : isAsync ? (
          <AsyncCreatableSelect
            cacheOptions
            defaultOptions
            loadOptions={props.fetchOptions}
            value={props.value}
            isClearable
            styles={{
              ...styles,
              ...props.selectProps?.styles,
            }}
            {...omit(props.selectProps, "styles")}
          />
        ) : (
          <ReactSelect
            placeholder={props.placeholder}
            components={animatedComponents}
            defaultValue={props.value}
            value={props.value}
            options={props.options}
            menuPosition="fixed"
            styles={{
              ...styles,
              ...props.selectProps?.styles,
            }}
            {...omit(props.selectProps, "styles")}
          />
        )}

        <FormErrorMessage>{props.error}</FormErrorMessage>
      </FormControl>
    </Box>
  );
};
