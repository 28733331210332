import {
  Heading,
  TableContainer,
  Table,
  Thead,
  Box,
  Tr,
  Th,
  Center,
  Tbody,
  Text,
  Image,
  Td,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BigNumber from "bignumber.js";
import { AssetIcon } from "src/components/styled/Assets";
import WhiteBox from "src/components/styled/WhiteBox";
import { useTheme } from "src/hooks/useTheme";
import { getLink } from "src/modules/ledger/transactions";
import { colors } from "src/theme";
import { fromDollars } from "src/utils/helpers";

import TwitterIcon from "src/assets/awaken/logos/socials/twitter.png";
import EmailIcon from "src/assets/awaken/logos/socials/email.png";
import Dan from "src/assets/awaken/accountants/dan.png";
import Squeeze from "src/assets/awaken/accountants/squeeze.jpg";
import NftCollage from "src/assets/awaken/accountants/nft-collage.jpg";

const CPA_INFO = [
  {
    name: "Dan Cournoyer",
    // title: "CPA",
    photo: Dan,
    description:
      "Dan Cournoyer helped build Awaken. He taught us how to do crypto taxes.",

    socialLinks: [
      {
        icon: TwitterIcon,
        url: "https://twitter.com/The_NFT_CPA",
      },
      {
        icon: EmailIcon,
        url: "mailto:dancournoyercpa@gmail.com",
      },
    ],
  },
  {
    name: "Squeeze",
    // title: "Accountant",
    photo: Squeeze,
    description:
      "Squeeze has helped us grow the Awaken community and has provided advice for dozens of our users.",
    socialLinks: [
      {
        icon: TwitterIcon,
        url: "https://twitter.com/SqueezeTaxes",
      },
      {
        icon: EmailIcon,
        url: "mailto:squeeze@squeezecryptotaxes.com",
      },
    ],
  },
  {
    name: "20+ more",
    photo: NftCollage,
    description: "View a directory of the top CPAs in the crypto space.",
    socialLinks: [],
  },
];

export const CPAsBox = () => {
  const { border, text, header, secondaryBackground } = useTheme();

  return (
    <WhiteBox
      id="cpas"
      border={`1px solid ${border}`}
      w="100%"
      //   maxW={`${MAX_REPORTS_WIDTH_NUM / 2}rem`}
    >
      <Heading color={header} flex={1} size="md" margin="0" paddingTop="0">
        Our favorite tax professionals
      </Heading>
      <Box>
        {CPA_INFO.map((cpa, i) => {
          return (
            <Box
              key={cpa.name}
              padding="1rem"
              borderBottom={`1px solid ${border}`}
              transition="0.1s ease-in-out"
              _hover={{
                backgroundColor: secondaryBackground,
              }}
            >
              <HStack alignItems="flex-start">
                <Image
                  src={cpa.photo}
                  w="4rem"
                  h="4rem"
                  objectFit="contain"
                  marginRight="0.5rem"
                  marginTop="0.4rem"
                  borderRadius="10px"
                ></Image>
                <Box>
                  <Text color={header} fontWeight="bold">
                    {cpa.name}
                  </Text>
                  <Text color={text} fontSize="sm">
                    {cpa.description}
                  </Text>
                  <Box display="flex" marginTop="0.5rem">
                    {cpa.socialLinks.map((link) => {
                      return (
                        <a href={link.url} target="_blank">
                          <Image
                            src={link.icon}
                            w="1.25rem"
                            h="1.25rem"
                            objectFit="contain"
                            marginRight="0.75rem"
                            borderRadius="10px"
                          ></Image>
                        </a>
                      );
                    })}
                  </Box>
                  {i === CPA_INFO.length - 1 && (
                    <Link to="professionals">
                      <Text
                        fontWeight="bold"
                        textDecoration="underline"
                        fontSize="sm"
                        color={colors.primary}
                      >
                        View All
                      </Text>
                    </Link>
                  )}
                </Box>
              </HStack>
            </Box>
          );
        })}
      </Box>
    </WhiteBox>
  );
};
