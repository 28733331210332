import BigNumber from "bignumber.js";
import numbro from "numbro";
import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";

// Assuming D is a properly configured BigNumber instance for formatting
// If D(valueCents).toUnit() is meant to convert cents to dollars, ensure it does so correctly

export const getValueFormatted = (valueCents: number) => {
  // Convert to BigNumber to maintain precision
  const valueDollars = new BigNumber(valueCents).dividedBy(100);
  // Use numbro for formatting directly, as BigNumber is mainly for precision arithmetic
  return `$${numbro(valueDollars).format("0,0.00")}`;
};

export const getValue = (valueCents: number) => {
  const value = new BigNumber(valueCents).dividedBy(100);
  // Check if value is less than or equal to 1.10 (110 cents)
  const isSmallNumber = value.lte(1.1);
  // Return value for formatting and flag indicating if it's a small number
  return { value: value.toNumber(), isSmallNumber };
};

const AnimatedNumber = ({
  valueCents,
  style,
}: {
  valueCents: number;
  style: React.CSSProperties;
}) => {
  const [displayValue, setDisplayValue] = useState(valueCents);
  const props = useSpring({
    number: valueCents,
    from: { number: displayValue },
  });

  useEffect(() => {
    setDisplayValue(valueCents);
  }, [valueCents]);

  return (
    <animated.div style={style}>
      {props.number.to((n) => getValueFormatted(Math.round(n)))}
    </animated.div>
  );
};

export default AnimatedNumber;
