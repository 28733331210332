import React, { useCallback } from "react";
import {
  Container,
  Grid,
  GridItem,
  Text,
  Button,
  Box,
  HStack,
} from "@chakra-ui/react";
import { colors } from "src/theme";
import truncateMiddle from "truncate-middle";
import { BaseAccountFields } from "src/api/fragments";
import { Touchable } from "../Touchable";

export const Account = ({
  account,
  isLast,
}: {
  account: BaseAccountFields;
  isLast?: boolean;
}) => {
  const _syncAccount = useCallback(async () => {
    // TODO:
  }, [account.id]);

  return (
    <Grid
      templateColumns={{ base: "repeat(6, 1fr)" }}
      gap={1}
      alignItems="center"
      _hover={{
        backgroundColor: colors.gray100,
      }}
      style={{
        padding: "1rem",
        borderBottom: isLast ? "none" : "1px solid " + colors.gray90,
      }}
    >
      <GridItem colSpan={1}>
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          marginTop={0}
          padding={{ base: "0", md: "0" }}
        >
          <Box
            style={{
              backgroundColor: colors.white,
              width: 25,
              height: 25,
              marginRight: 10,
              borderRadius: 5,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={account.iconImageUrl}
            />
          </Box>
          <Text fontSize="sm">{account.name}</Text>
        </Container>
      </GridItem>

      <GridItem colSpan={3} textAlign="center">
        <Text textAlign="center" fontSize="sm">
          {truncateMiddle(account.description, 10, 10, "...")}
        </Text>
      </GridItem>

      <GridItem colSpan={1} textAlign="center">
        <HStack alignItems="center" justifyContent="center">
          <Text fontSize="sm">{account.isActive ? "Active" : "Inactive"}</Text>
          <Box
            style={{
              backgroundColor: account.isActive ? colors.green50 : colors.red50,
              width: 10,
              height: 10,
              borderRadius: "100%",
            }}
          />
        </HStack>
      </GridItem>

      <GridItem colSpan={1} textAlign="right">
        <HStack alignItems="center">
          <Button
            onClick={() => alert("TODO: fix this")}
            width="100px"
            marginRight="0.5rem"
            size="sm"
          >
            Edit
          </Button>

          <Button
            onClick={_syncAccount}
            width="100px"
            marginRight="0.5rem"
            size="sm"
          >
            Sync
            <i
              className="fa-sharp fa-redo-alt"
              style={{ fontSize: 12, marginLeft: 5 }}
            />
          </Button>

          <AccountActions />
        </HStack>
      </GridItem>
    </Grid>
  );
};

const AccountActions = () => {
  return <Touchable iconName="fa-sharp fa-ellipsis-v" />;
};
