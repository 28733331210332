import {
  Badge,
  Box,
  color,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { colors } from "src/theme";
import { Info } from "src/components/styled";
// import { TransferComponent } from "./Transfer";
import { Touchable } from "src/components/Touchable";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormValues, TransferFormValues } from "../../form";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useMemo } from "react";
import { useClientById } from "src/hooks";
import { useParams } from "react-router-dom";
import { objectify } from "radash";
import { CurrencyCodeEnum } from "src/api/generated/types";

export const Transfers = () => {
  const { watch } = useFormContext<FormValues>();
  const { control } = useFormContext<FormValues>();
  const { append } = useFieldArray<FormValues>({
    name: "transfers",
    control,
  });
  const transfers = watch("transfers");

  const _addTransfer = () => {
    const newTransfer: TransferFormValues = {
      id: uuidv4(),
      value: 0,
      fiatValue: 0,
      fiatCurrency: CurrencyCodeEnum.Usd,
      originalValue: 0,
      estFiatValue: 0,
      price: 0,
      originalFiatValue: 0,
      originalFrom: null,
      originalTo: null,
      originalAssetId: null,
      type: null,
      assetId: null,
      fromAccountId: null,
      fromType: null,
      toAccountId: null,
      toType: null,
    };

    append(newTransfer);
  };

  if (!transfers.length) {
    return <NoTransfers />;
  }

  return (
    <Box>
      <HStack width="100%" marginBottom="1rem">
        <Text
          fontSize={14}
          fontWeight="bold"
          color={colors.gray4}
          textTransform="uppercase"
        >
          Asset Transfers{" "}
          <Info message="Every asset transfer from/to your wallets/exchanges." />
        </Text>
        <Touchable
          style={{ marginRight: "3rem" }}
          label={`Add Transfer +`}
          onClick={_addTransfer}
        />
        {/* <AssetsPreview /> */}
      </HStack>
      {/* <HStack width="100%" padding="0" alignItems="start" overflowX="scroll">
        {transfers.map((transfer, index) => (
          <TransferComponent
            index={index}
            transfer={transfer}
            key={transfer.id}
          />
        ))}
      </HStack> */}
    </Box>
  );
};

const NoTransfers = () => (
  <Box padding="1rem" textAlign="center" width="100%">
    <Text>No transfers in transaction...</Text>
  </Box>
);
