import { Box, Button, Container, Heading, toast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { useMemo } from "react";
import { useForm, FieldErrors } from "react-hook-form";
import { colors } from "src/theme";
import { Select, Input, Checkbox } from "src/components/styled";
import { PageTemplateContainer } from "src/components";
import NavBar from "src/components/NavBar";
import SecondaryText from "src/components/styled/SecondaryText";
import { useMyToast } from "src/hooks";
import { NUM_TXN_OPTIONS } from "./form";
import { FormValues, getDefaultValues, schema } from "./formCreateCPA";
import { useMutation } from "@apollo/client";
import { api } from "src/api";


export function CreateCPA () {
    const toast = useMyToast();

    const [createFilterFormCPA, { loading: loadingCreateFilterFormCPA }] = useMutation(
        api.misc.createFilterFormCPA
      );

    // Form hooks / fxns
    const values = useMemo(
        () => getDefaultValues(),
        []
    );

    
    const formProps = useForm<FormValues>({
        defaultValues: values,
        resolver: yupResolver(schema),
    });
    
    function _onInvalidForm (values: FieldErrors<FormValues>) {
        const keys = Object.keys(values);
        for (let i = 0; i < keys.length; i++) {
          const message = _.get(values, keys[i])?.message;
          if (message) {
            toast.show({
              message,
              status: "error",
            });
          }
        }
  }

  async function _onSubmit (values: FormValues) {
    try {
        if (!values.numTxnsLowerBound || !values.numTxnsUpperBound) {
            throw Error("Please fill out all required form fields")
        }

        const id = (await createFilterFormCPA({
            variables: {
                ...values,
                isAcceptingIndividuals: values.isAcceptingIndividuals || false,
                isAcceptingBusinesses: values.isAcceptingBusinesses || false,
                isAcceptingEth: values.isAcceptingEth || false,
                isAcceptingEthL2: values.isAcceptingEthL2 || false,
                isAcceptingOtherChains: values.isAcceptingOtherChains || false,
                confirmed: values.confirmed || false,
                numTxnsLowerBound: values.numTxnsLowerBound.value,
                numTxnsUpperBound: values.numTxnsUpperBound.value,
            },
        })).data.createFilterFormCPA;

        toast.show({
            message: "Successfully created CPA: " + id,
            status: "success"
        })

        formProps.reset();

      } catch (err) {
        toast.show({
          message: (err as Error).message || "An error occurred.",
          status: "error",
        });
      }
}

    return (
        <PageTemplateContainer bgColor={colors.white}>
        <NavBar />
        <Box margin="auto" w="96%" maxW="40rem" paddingBottom="5rem">
            <Heading>Create CPA Form</Heading>
            
            <form onSubmit={formProps.handleSubmit(_onSubmit, _onInvalidForm)}>

                <Container padding="0px" marginTop="1rem">
                    <Checkbox
                        label="Accepting individuals?"
                        name="isAcceptingIndividuals"
                        control={formProps.control}
                        isRequired
                        containerStyle={{marginBottom: "1.3rem"}}
                    />
                    <Checkbox
                        label="Accepting businesses?"
                        name="isAcceptingBusinesses"
                        control={formProps.control}
                        isRequired
                        containerStyle={{marginBottom: "1.3rem"}}
                    />
                    <Checkbox
                        label="Accepting ETH?"
                        name="isAcceptingEth"
                        control={formProps.control}
                        isRequired
                        containerStyle={{marginBottom: "1.3rem"}}
                    />
                    <Checkbox
                        label="Accepting ETH L2s?"
                        name="isAcceptingEthL2"
                        control={formProps.control}
                        isRequired
                        containerStyle={{marginBottom: "1.3rem"}}
                    />
                    <Checkbox
                        label="Accepting Other chains?"
                        name="isAcceptingOtherChains"
                        control={formProps.control}
                        isRequired
                        containerStyle={{marginBottom: "1.3rem"}}
                    />
                    <Select
                        label="Transaction lower bound (inclusive)"
                        name="numTxnsLowerBound"
                        control={formProps.control}
                        options={NUM_TXN_OPTIONS}
                        isRequired
                        containerStyle={{marginBottom: "1.3rem"}}
                    />
                    <Select
                        label="Transaction upper bound (inclusive)"
                        name="numTxnsUpperBound"
                        control={formProps.control}
                        options={NUM_TXN_OPTIONS}
                        isRequired
                        containerStyle={{marginBottom: "1.3rem"}}
                    />
                    <Checkbox
                        label="Info confirmed by the CPA?"
                        name="confirmed"
                        control={formProps.control}
                        isRequired
                        containerStyle={{marginBottom: "1.3rem"}}
                    />
                    <Input
                        control={formProps.control}
                        name="name"
                        label="CPA Full Name"
                        placeholder="John Legend"
                        type="name"
                        isRequired
                    />
                    <Input
                        control={formProps.control}
                        name="email"
                        label="CPA Email"
                        placeholder="john.legend@gmail.com"
                        type="email"
                        marginBottom="0.5rem"
                        isRequired
                    />
                    <Input
                        control={formProps.control}
                        name="twitter"
                        label="Twitter handle"
                        placeholder="The_NFT_CPA"
                        marginBottom="0.5rem"
                    />
                    <Input
                        control={formProps.control}
                        name="linkedin"
                        label="LinkedIn handle"
                        placeholder="markacostello"
                        marginBottom="0.5rem"
                    />
                    <Input
                        control={formProps.control}
                        name="description"
                        label="Description (long)"
                        marginBottom="0.5rem"
                        isRequired
                    />
                    <Input
                        control={formProps.control}
                        name="headline"
                        label="Headline (short)"
                        marginBottom="0.5rem"
                        isRequired
                    />
                    <Input
                        control={formProps.control}
                        name="imgUrl"
                        label="Image URL (AWS Bucket)"
                        marginBottom="0.5rem"
                        isRequired
                    />
                </Container>
            <Button variant="primary" width="100%" type="submit" isLoading={loadingCreateFilterFormCPA}>Find CPAs</Button>
            </form>
        </Box>
    </PageTemplateContainer>
    )
}