import {
  Heading,
  Table,
  Text,
  Tr,
  Th,
  TableContainer,
  Thead,
  Tbody,
  HStack,
  Box,
  Image,
  Td,
  ButtonGroup,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Tooltip,
  Divider,
  PopoverBody,
  Link,
} from "@chakra-ui/react";
import {
  BaseAccountFields,
  BaseAccountWithCurrentJobFields,
  BaseAssetFields,
  BaseUserFields,
} from "src/api/fragments";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isNil, orderBy } from "lodash/fp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import {
  AssetAccountBreakdown,
  AssetTypeEnum,
  AssetWarning,
  CurrencyCodeEnum,
} from "src/api/generated/types";
import _, { isEmpty, keyBy, noop, truncate } from "lodash";
import { AssetIcon } from "src/components/styled/Assets";
import { colors, other } from "src/theme";
import {
  getAssetLinkFromId,
  getAssetLinkFromIdDetailPage,
  getAssetLinkFromSymbol,
  getLink,
} from "src/modules/ledger/transactions";
import { PROVIDER_TO_LOGO_URL } from "src/components/modals/AccountModal/constants";
import Fuse from "fuse.js";
import BigNumber from "bignumber.js";
import Helpers, { D, formatNum } from "src/utils/helpers";
import numeral from "numeral";
import { Maybe, hasValue } from "src/core";
import { Touchable } from "src/components/Touchable";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import {
  getAssetOnMarketplaceOrCoingecko,
  getOpenSeaUrl,
} from "src/modules/getAssetUrl";
import SmallLogo from "src/assets/awaken/logos/awaken-black.jpeg";
import RedSearch from "src/assets/awaken/icons/red-search.png";
import { useInterval } from "src/hooks/common";
import numbro from "numbro";
import ReactPaginate from "react-paginate";
import StatusTag from "src/components/styled/StatusTag";
import { NAME_FLEX, VALUE_FLEX } from "./constants";
import { ActionSheet } from "src/components";
import { useMyToast } from "src/hooks";
import { getBalanceImageInfo, getGenericTokenName } from "./utils";
import { getImageKitCDNUrl } from "src/utils/imagekit";
import truncateMiddle from "truncate-middle";
import { singular } from "pluralize";
import { getProviderShort } from "src/modules/providers";
import { useIsLargeScreen } from "src/hooks/useScreenSize";

export const AccountPosition = ({
  breakdown,
  asset,
  clientId,
  currency,
  account,
  isLast,
}: {
  breakdown: AssetAccountBreakdown;
  account: Maybe<BaseAccountWithCurrentJobFields>;
  asset: BaseAssetFields;
  clientId: string;
  currency: CurrencyCodeEnum;
  isLast: boolean;
}) => {
  const isNeg = breakdown.gainOrLossFiatAmount
    ? breakdown.gainOrLossFiatAmount < 0
    : false;
  const color = isNeg ? colors.negative : colors.positive;
  const isNFT = asset?.type === AssetTypeEnum.Nft;

  // just take to accounts page for now
  const link = `/clients/${clientId}/transactions?accountIds=${account?.id}&assetIds=${asset.id}&includeSpam=true`;
  const isSingleNft =
    breakdown.amount === 1 && asset?.type === AssetTypeEnum.Nft;
  const tokenSymbol =
    asset?.type === AssetTypeEnum.Nft
      ? isSingleNft
        ? "NFT"
        : "NFTs"
      : asset?.symbol;
  const assetLink = getAssetLinkFromId(clientId || "", breakdown.assetId);

  const currentValue = breakdown.estimatedCurrentValue;
  const toast = useMyToast();

  // if there is no amount or the amount rounded 4 places is 0 (basically none)
  // and the current value is null or 0 -> we don't even want to show the position
  const shouldHide =
    (!breakdown.amount || parseFloat(breakdown.amount.toFixed(4)) === 0) &&
    !currentValue;

  const name = (isNFT ? asset?.name : account?.description) || "";
  const isLarge = useIsLargeScreen();
  const assetInfo = getAssetOnMarketplaceOrCoingecko(asset);

  if (shouldHide) {
    return null;
  }

  return (
    <HStack
      style={{
        justifyContent: "space-between",
        // borderBottom: isLast ? "none" : `1px solid ${colors.gray85}`,
      }}
      opacity={1}
      // borderRadius={7}
      // borderBottom="none !important"
      width="100%"
      padding="0.5rem 0rem"
    >
      <Box
        display="flex"
        style={{
          flex: isLarge ? NAME_FLEX : NAME_FLEX - 1,
        }}
        marginLeft="0 !important"
        alignItems="center"
        position="relative"
        maxWidth={isLarge ? undefined : 200}
      >
        <VStack w="100%" alignItems="flex-start">
          <HStack w="100%">
            {isNFT && (
              <div
                style={{
                  width: 50,
                  position: "relative",
                  marginLeft: "0.5rem",
                }}
              >
                <AssetIcon
                  textStyle={{ fontSize: 8 }}
                  size={35}
                  style={{ objectFit: "cover" }}
                  asset={asset}
                />
              </div>
            )}

            <VStack marginLeft="1rem" alignItems="flex-start" w="100%">
              <HStack w="100%">
                <Link
                  textDecoration="none"
                  href={link}
                  marginTop="0px !important"
                >
                  <Tooltip label={name} openDelay={500}>
                    <Text
                      isTruncated
                      margin="0 !important"
                      fontSize="sm"
                      fontWeight="500"
                      color={colors.black}
                      textDecoration="none"
                      _hover={{
                        color: colors.primary,
                        textDecoration: "underline",
                      }}
                    >
                      {truncate(name, {
                        length: 10,
                        omission: "..",
                      })}
                    </Text>
                  </Tooltip>
                </Link>

                <ActionSheet
                  popover={{ trigger: "hover" }}
                  content={{ width: 250 }}
                  commands={[
                    !isNFT && account && account.blockExplorerUrl
                      ? {
                          label: "Open in block explorer", // + account.blockExplorerName,
                          iconImageSrc: account.iconImageUrl,
                          iconName: "fa-sharp fa-network-wired",
                          link: account.blockExplorerUrl || "",
                        }
                      : null,

                    !isNFT && account
                      ? {
                          label: "Copy account ID", // + account.blockExplorerName,
                          onClick: () => {
                            navigator.clipboard.writeText(account.id);
                            toast.show({
                              status: "info",
                              message: "Copied account ID!",
                            });
                          },
                          iconName: "fa-sharp fa-copy",
                        }
                      : null,

                    isNFT && assetInfo
                      ? {
                          label: "Open on " + assetInfo.name,
                          iconImageSrc: assetInfo.imageUrl,
                          iconName: "fa-sharp fa-network-wired",
                          link: assetInfo.url,
                        }
                      : null,
                    isNFT
                      ? {
                          label: "Copy asset ID",
                          onClick: () => {
                            navigator.clipboard.writeText(asset.id);
                            toast.show({
                              status: "info",
                              message: "Copied asset ID!",
                            });
                          },
                          iconName: "fa-sharp fa-copy",
                        }
                      : null,
                  ].filter(hasValue)}
                >
                  <Touchable iconName="fa-sharp fa-ellipsis-v" />
                </ActionSheet>
              </HStack>

              <HStack marginTop="0 !important" alignItems="center">
                <Tooltip
                  label={`${numbro(breakdown.amount).format("0,000.[0000]")} ${
                    tokenSymbol || "Token"
                  }`}
                  openDelay={500}
                >
                  <Text
                    margin="0"
                    isTruncated
                    fontSize="sm"
                    isNumeric
                    fontWeight="normal"
                    color={colors.gray30}
                  >
                    {isSingleNft
                      ? "1 "
                      : formatNum(breakdown.amount, false, "0,0.[0000]") + " "}
                    {tokenSymbol}
                  </Text>
                </Tooltip>
                {account && (
                  <>
                    <Text
                      marginTop="0 !important"
                      marginBottom="0 !important"
                      isTruncated
                      fontSize="sm"
                      fontWeight="normal"
                      color={colors.gray30}
                      marginRight="0"
                    >
                      {getProviderShort(account?.provider || "")}
                    </Text>
                    <Image
                      src={getImageKitCDNUrl(account.iconImageUrl, {
                        width: 160,
                        height: 160,
                      })}
                      w="0.85rem"
                      h="0.85rem"
                      borderRadius="5px"
                      flexShrink={0}
                      marginRight="0rem"
                      marginLeft="5px !important"
                    />
                  </>
                )}
              </HStack>
              {/* <Text
                isTruncated
                marginTop="5px !important"
                fontSize="sm"
                fontWeight="normal"
                color={colors.gray30}
                textDecoration="none"
              >
                {account?.walletAddress
                  ? truncate(account?.walletAddress || "", {
                      length: 15,
                    })
                  : ""}
              </Text> */}
            </VStack>
          </HStack>
        </VStack>
      </Box>

      <Box
        style={{
          flex: VALUE_FLEX,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginLeft: 0,
        }}
        flex={VALUE_FLEX}
      >
        <CurrentValue
          asset={asset}
          breakdown={breakdown}
          currency={currency}
          isNeg={isNeg}
        />
      </Box>

      <Box
        style={{
          alignItems: "center",
          justifyContent: "flex-start",
          display: "flex",
          fontWeight: "500",
          marginLeft: 0,
        }}
        flex={VALUE_FLEX}
      >
        <GainLoss breakdown={breakdown} currency={currency} />
      </Box>
    </HStack>
  );
};

const GainLoss = ({
  breakdown,
  currency,
}: {
  breakdown: AssetAccountBreakdown;
  currency: CurrencyCodeEnum;
}) => {
  const isLarge = useIsLargeScreen();

  if (
    !breakdown.gainOrLossFiatAmount ||
    isNaN(breakdown.gainOrLossFiatAmount) ||
    !breakdown.hasPrice
  ) {
    return <HStack alignItems="center">—</HStack>;
  }

  const isNeg = breakdown.gainOrLossFiatAmount < 0;
  const color = isNeg ? colors.negative : colors.positive;

  return (
    <HStack alignItems="center" style={{ width: "100%" }}>
      <VStack
        alignItems="flex-start"
        style={{
          fontWeight: "500",
          color,
          width: "100%",
          maxWidth: 100,
        }}
      >
        <Text
          fontWeight="500"
          fontSize="sm"
          color={color}
          marginBottom={isLarge ? "5px" : "0"}
        >
          {D(Math.abs(breakdown.gainOrLossFiatAmount), currency).toFormat()}{" "}
        </Text>
        {isLarge && (
          <Text fontSize="sm" marginTop="0 !important" style={{ color }}>
            {isNeg ? "(" : ""}
            {numbro(Math.abs(breakdown.percentChangeAllTime || 0)).format({
              thousandSeparated: true,
              mantissa: 2,
            })}
            %{isNeg ? ")" : ""}
          </Text>
        )}
      </VStack>

      <i
        className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
        style={{
          fontSize: 16,
          color,
        }}
      />
    </HStack>
  );
};

const CurrentValue = ({
  asset,
  breakdown,
  currency,
  isNeg,
}: {
  asset: Maybe<BaseAssetFields>;
  breakdown: AssetAccountBreakdown;
  currency: CurrencyCodeEnum;
  isNeg: boolean;
}) => {
  const { clientId } = useParams<{ clientId: string }>();

  const currentValue = breakdown.estimatedCurrentValue;

  return (
    <Box>
      <Text
        fontSize="sm"
        isNumeric
        style={{
          fontWeight: "500",
        }}
        // marginBottom="5px"
      >
        {D(currentValue || 0, currency).toFormat()}
      </Text>
      {/* <Text
        fontSize="sm"
        marginTop={0}
        isTruncated
        // fontStyle="italic"
        style={{ color: colors.gray30 }}
      >
        {D(breakdown.currentTokenPriceFiatAmount || 0, currency).toFormat()}/
        {isNFT
          ? singular(tokenName)
          : asset?.symbol?.toUpperCase() || singular(tokenName)}
      </Text> */}
    </Box>
  );
};
